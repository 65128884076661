import { LOCATION_CHANGED } from 'redux-little-router';
import UIkit from 'uikit';
import { app, visitor } from 'BoomTown';
import * as r from 'routes';
import { favoritesViewData } from 'constants/registrationForms';
/**
 * @param {{ dispatch, getState }}
 */
export default ({ getState }) => next => action => {
  const isLocationEvent = action.type === LOCATION_CHANGED;
  if (!isLocationEvent) {
    next(action);
    return;
  }

  const state = getState();

  // Hide reg modal on route change
  // It's important that this is done before transitioning through the views' lifecycle methods,
  // because they might want to show the register modal.
  // With registration modals we sometimes push #reg=1 into the url which triggers
  // a location change.
  const isNewPath = state.router.pathname !== action.payload.pathname;
  const isNotInitialLoad = state.locationChanges > 0;
  if (isNotInitialLoad && isNewPath && UIkit.modal('#user-action-modal').isActive()) {
    UIkit.modal('#user-action-modal').hide(true);
  }

  const routeID = (action.payload.result || { id: '' }).id;
  const query = action.payload.query;

  // Squeeze'em on favorite type pages
  const isDesktopFavs = [r.FAVORITES, r.COMPARE].includes(routeID);
  const isMobileFavs = routeID === r.RESULTS_GALLERY && query.favs === '1';
  if (!visitor.isRegistered() && (isDesktopFavs || isMobileFavs)) {
    app.squeezeForm(favoritesViewData.urlParam, false, false);
  }

  // Always forward it on
  next(action);
};

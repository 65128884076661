import React from 'react';
import PropTypes from 'prop-types';
import { getAddressLineOne } from 'selectors/listings';

/**
 * Render a listing's full street address.
 *
 * @note Only used when rendering on the Comparison Table view
 *
 * @param {{ location: Object, hideAddress: boolean }}
 */
export default function StreetAddress(props) {
  const { location, hideAddress } = props;

  // Either the listing's street address or "Address Not Available By Request"
  const addressText = getAddressLineOne({ Location: location, HideAddress: hideAddress });

  // The attributes to apply to the element containing the listing address.
  // The attributes are independent on the element type.
  const addressAtts = {
    className: 'mt-4',
    itemType: 'http://schema.org/PostalAddress',
    itemScope: true,
    itemProp: 'address'
  };

  return (
    <div {...addressAtts}>
      {hideAddress ? (
        <div className="font-size--14">{addressText}</div>
      ) : (
        <div className="bt-text--truncate" itemProp="streetAddress">{addressText}</div>
      )}
    </div>
  );
}

StreetAddress.propTypes = {
  location: PropTypes.shape({
    StreetNumber: PropTypes.string,
    StreetDirPrefix: PropTypes.string,
    StreetName: PropTypes.string,
    StreetDirSuffix: PropTypes.string,
    UnitNumber: PropTypes.string
  }).isRequired,
  hideAddress: PropTypes.bool
};

import { takeLatest } from 'redux-saga/effects';
import * as PaginationActions from 'screens/ResultsMap/Desktop/ResultsPagination/actions';
import fetchNewPageOfResults from './fetchNewPageOfResults';

export default [
  takeLatest([
    PaginationActions.onClickNextButton,
    PaginationActions.onClickPreviousButton
  ], fetchNewPageOfResults),
];

import { select, takeEvery, call, put, fork, cancel, all } from 'redux-saga/effects';
import { LOCATION_CHANGED } from 'redux-little-router';
import {
  openModal as openQualifyingQuestionsModal,
  QQActions,
  selectAnswer,
} from 'actions/QualifyingQuestionsActions';
import { config, api, visitor } from 'BoomTown';
import { bootOptimize } from 'actions/OptimizeActions';
import * as regForm from 'constants/registrationForms';

const conversionStringName = config.conversionQueryStringName;

const shouldOpenQQModal = ({ type, payload }) =>
  type === LOCATION_CHANGED &&
  payload &&
  payload.query &&
  payload.query[conversionStringName] &&
  payload.query[conversionStringName] !== regForm.shareListingData.urlParam &&
  payload.query[conversionStringName] !== regForm.savedSearchesData.urlParam;

function* qqWatcher() {
  yield takeEvery(shouldOpenQQModal, function* handleRegFormComplete() {
    const locationChanges = yield select(state => state.locationChanges);
    if (locationChanges === 1) {
      yield put(openQualifyingQuestionsModal());
    }
  });
}

function* featureFlagWatcher() {
  let task;
  const isQQEnabled = yield select(state => state.optimize.qualifyingQuestionsEnabled);
  if (isQQEnabled) {
    task = yield fork(qqWatcher);
  }
  yield takeEvery(bootOptimize.getType(), function* onBootOptimize() {
    const enabled = yield select(state => state.optimize.qualifyingQuestionsEnabled);
    if (enabled && !task) {
      task = yield fork(qqWatcher);
    } else if (!enabled && task) {
      yield cancel(task);
    }
  });
}

/**
 * Fetch the qualifying questions from the API.
 */
function* fetchQualifyingQuestions() {
  let result;
  try {
    result = yield call([api, api.getQualifyingQuestions]);
  } catch (e) {
    yield put(QQActions.fetch.failure(e));
    return;
  }

  yield put(QQActions.fetch.success(result));
}

/**
 * Save qual. questions answers on selection.
 *
 * @param {Action} selectAction A QualifyingQuestionsActions.selectAnswer
 * action
 */
function* saveAnswer({ payload: { id: answerID } }) {
  // Use-case here is that we test a question that we don't expect to be able to save answers for,
  // so neither the question nor the answers have IDs
  if (typeof answerID !== 'number') {
    return;
  }

  try {
    yield call([api, api.sendQQAnswers], answerID);
  } catch (e) {
    yield put(QQActions.update.failure(e));
  }

  yield put(QQActions.update.success());
}

export default [
  fork(featureFlagWatcher),
  takeEvery(selectAnswer, function* onSelectAnswer(action) {
    yield all([call(saveAnswer, action)]);
  }),

  // Directly forking this task so that we always immediately fetch the
  // questions. If they can be deferred until later, we can always implement a
  // watcher.
  fork(function* fetchQualifyingQuestionsIfRegd() {
    if (yield call([visitor, visitor.isRegistered])) {
      yield call(fetchQualifyingQuestions);
    }
  }),
];

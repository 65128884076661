import { useRef, useLayoutEffect, useCallback } from 'react';

/**
 * A hook used to prevent frequent invalidation of an event callback that depends on props or state.
 *
 * @see https://github.com/facebook/react/issues/14099#issuecomment-440013892
 *
 * @param {Function} callback - A function to use as an event callback for an element.
 * @returns {Function} A memoized callback function.
 */
const useEventCallback = (callback) => {
  const ref = useRef();
  useLayoutEffect(() => {
    ref.current = callback;
  });
  return useCallback((...args) => (0, ref.current)(...args), []);
};

export default useEventCallback;

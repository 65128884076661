import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/core/Text';
import { LISTING_CARD } from 'cypress_constants';

function CardAddress(props) {
  const { neighborhood, city, className } = props;

  if (!neighborhood && !city) {
    return null;
  }

  let title = '';
  if (neighborhood) {
    if (city) {
      title = `${neighborhood} | ${city}`;
    } else {
      title = neighborhood;
    }
  } else if (city) {
    title = city;
  }

  return (
    <Text
      truncate
      block
      itemType="http://schema.org/PostalAddress"
      itemScope
      itemProp="address"
      title={title}
      className={className}
    >
      {neighborhood && (
        <span itemProp="addressLocality">
          {neighborhood}
        </span>
      )}

      {neighborhood && city && <span className="bt-text--muted"> | </span>}

      {city && (
        <span data-cy={LISTING_CARD.CITY} itemProp="addressLocality">
          {city}
        </span>
      )}
    </Text>
  );
}

CardAddress.propTypes = {
  neighborhood: PropTypes.string,
  city: PropTypes.string,
  className: PropTypes.string,
  hideNeighborhood: PropTypes.bool,
};

export default CardAddress;

/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-act';

/**
 * Dispatched by the 'POPULATE_SAVE_SEARCH' MessageListener
 *
 * @payload {Object} payload
 * @see {MessageListener} populateSearch
 */
export const applyPendingSearchForSaveSearch = createAction(
  'Apply Pending Search from Native App for the Save Search view',
  payload => payload
);

/**
 * Dispatched by the 'POPULATE_SAVE_SEARCH' MessageListener
 *
 * @payload {Object} pendingSearch
 * @see {MessageListener} populateSearch
 */
export const submitSaveSearch = createAction(
  'Submit Save Search from Consumer App'
);

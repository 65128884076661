/* eslint-disable no-param-reassign, no-confusing-arrow */
import { createReducer } from 'redux-act';
import { combineReducers } from 'redux';
import { visitorDetails as visitor } from 'BoomTown';
import {
  receiveEmailPreferences,
  receiveCommunicationPreferences,
  updateVisitor,
  receiveSavedSearches,
  updateSavedSearchInStore,
} from 'actions/visitorActions';
import * as mobileSSActions from 'actions/MobileSaveSearchActions';
import * as desktopSSActions from 'actions/desktopSaveSearchActions';

import { successSubmitEmailPreferencesForm } from 'screens/Account/Forms/EditEmailPrefs/actions';
import { successSubmitTextPreferencesForm } from 'screens/Account/Forms/EditTextPrefs/actions';
import { deleteSavedSearchRequest, receiveDeleteSavedSearchResponse } from 'screens/Account/Forms/EditSavedSearches/actions';

const emailPreferencesInitial = null;
const emailPreferences = createReducer(
  {
    [receiveEmailPreferences]: (
      state,
      /** @type {FlagshipAPI.EmailPreferencesResponse} */ payload
    ) => ({
      optOutGlobal: payload.OptOutGlobal,
      optOutListingAgent: payload.OptOutListingAgent,
      optOutBuyerAgent: payload.OptOutBuyerAgent,
      optOutLender: payload.OptOutLender,
      optOutEAlerts: payload.OptOutEAlerts,
    }),

    /**
     * Only transition state when the commit is complete. To do optimistic
     * updates our state must have this transaction baked in (two copies
     * maintained, etc.)
     */
    [successSubmitEmailPreferencesForm]:
      /** @param {FlagshipAPI.EmailPrefsUpdateRequest} payload */
      (state, payload) => ({
        optOutGlobal: payload.OptOutGlobal,
        optOutListingAgent: payload.OptOutAgent,
        optOutBuyerAgent: payload.OptOutAgent,
        optOutLender: payload.OptOutLender,
        optOutEAlerts: payload.OptOutEAlerts,
      }),
  },
  emailPreferencesInitial
);

const communicationPreferencesInitial = null;

const communicationPreferences = createReducer(
  {
    [receiveCommunicationPreferences]: (
      state,
      /** @type {FlagshipAPI.CommunicationPreferencesResponse} */ payload
    ) => ({
      emailPreferences: {
        optOutGlobal: payload.emailPreferences.OptOutGlobal,
        optOutAgent: payload.emailPreferences.OptOutAgent,
        optOutBuyerAgent: payload.emailPreferences.OptOutBuyerAgent,
        optOutListingAgent: payload.emailPreferences.OptOutListingAgent,
        optOutLender: payload.emailPreferences.OptOutLender,
        optOutEAlerts: payload.emailPreferences.OptOutEAlerts,
      },
      textPreferences: {
        textAllowed: payload.textPreferences.TextAllowed
      }
    }),

    [successSubmitTextPreferencesForm]: (
      state, payload
    ) => ({
      ...state,
      textPreferences: {
        textAllowed: !payload.optOutText
      }
    })
  },
  communicationPreferencesInitial
);

const savedSearchInitial = null;
const savedSearches = createReducer(
  {
    [receiveSavedSearches]: (state, payload) => [...payload],
    [updateSavedSearchInStore]: (state, payload) => {
      const i = state.findIndex(x => x.searchid === payload.searchid);
      return [...state.slice(0, i), payload, ...state.slice(i + 1)];
    },
    [deleteSavedSearchRequest]: (state, payload) => {
      // Add `isDeleting` to the Saved Search in order to adjust the opacity
      // of the SavedSearchItem as it is deleted.
      const newSearches = state.map(search => {
        if (search.searchid === payload.searchid) {
          search.isDeleting = true;
        }
        return search;
      });
      return [...newSearches];
    },
    [receiveDeleteSavedSearchResponse]: (state, payload) => {
      if (payload.response === 'success') {
        return state.filter(search => search.searchid !== payload.id);
      }

      return state;
    },

    // Clear out our saved search collection when a new search is saved
    // We have to stick to this approach because the api doesn't return the entity on success
    [mobileSSActions.receiveCreateSavedSearchRes]: (state, payload, { success }) => {
      if (success) {
        return savedSearchInitial;
      }

      return state;
    },
    [desktopSSActions.receiveCreateSavedSearchRes]: (state, payload, { success }) => {
      if (success) {
        return savedSearchInitial;
      }

      return state;
    },
  },
  savedSearchInitial
);

// This reducer is concerned with the entire `visitor` node
const crossReducer = createReducer(
  {
    [updateVisitor]: (state, payload) => ({
      ...state,
      ...payload,
    }),
  },

  // When sequencing reducers, it's important that one of the reducers is
  // able to define the _entire_ initial state, because only the first one in
  // the composed chain will see previous state as being `undefined`. (This
  // means that the initial states defined in the independent reducers above are
  // not being utilized, but they're defined because it's the sane thing to do.)
  {
    ...visitor,
    emailPreferences: emailPreferencesInitial,
    communicationPreferences: communicationPreferencesInitial,
    savedSearches: savedSearchInitial,
  }
);

const independentSlices = combineReducers({
  emailPreferences,
  communicationPreferences,
  savedSearches,
});

export default (state, action) => {
  const intermediate = crossReducer(state, action);
  // eslint-disable-next-line
  const { emailPreferences, communicationPreferences, savedSearches } = intermediate;
  return {
    ...intermediate,
    ...independentSlices({ emailPreferences, communicationPreferences, savedSearches }, action),
  };
};

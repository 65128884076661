import React, { Component } from 'react';

/**
 * A default param. for the `getInitialState` parameter below to prevent an
 *  anonymous function being allocated each time.
 */
function defaultGIS() {
  return {};
}

/**
 * Get the display name of a component so that we can augment it for the
 * displayName of the wrapping component.
 * @param {React.Component|function} c
 */
function getDisplayName(c) {
  return c.displayName || c.name || 'Component';
}

/**
 * Just like react-redux's `connect()`, but for listening to state changes via
 * Backbone/Underscore events.
 *
 * @example
 * export default connectBB(
 *   MyComponent,
 *   [{
 *     // The emitter might not be defined at the time that the components bundle is
 *     // loaded, so a function that will retrieve the emitter needs to be used.
 *     getEmitter: () => favorites,
 *     events: [{
 *       name: 'add',
 *       // A function that will be called whenever the event is triggered, and is
 *       // expected to return props that the component will be re-rendered with.
 *       // Passed the current props as the first arg., and the remaining arguments
 *       // will be those that are passed to handler of the specific event.
 *       replaceProps: (props, ...eventArgs) => {
 *       }
 *     }]
 *   }],
 *   function () {
 *     // You can reach out to models and build the initial props you'd like
 *     // passed in here, or alternatively pass them in from outside as props.
 *     return { myInitial: 'state' };
 *   }
 * );
 *
 * @param {ReactClass} WrappedComponent
 * @param {Array<Object>} subscriptions
 * @param {Function} getInitialState
 * @return {Component}
 */
export default function connectBB(
  WrappedComponent,
  subscriptions,
  getInitialState = defaultGIS
) {
  class ConnectedComponent extends Component {
    constructor(props) {
      super(props);
      /**
       * The state of the component tree from the perspective of the event
       * handlers. This allows us to pass the most up-to-date state to the
       * `replaceState()` callbacks, irrespective of React's batching
       * strategy.
       * @type {Object}
       */
      this._state = {
        ...this.props,
        ...getInitialState(this.props)
      };
      this.state = {
        ...this.props,
        ...getInitialState(this.props)
      };

      /**
       * A cache of the attached event handlers for each emitter. Used to
       * detach handlers on `componentWillUnmount()`
       * @type {Array<Object>}
       * @property {Backbone.Events} emitter
       * @property {String} name
       * @property {Function} handler
       */
      this._handlers = [];
    }

    /**
     * Attach event handlers according to the provided `subscriptions`
     */
    componentDidMount() {
      subscriptions.forEach(s => {
        const emitter = s.getEmitter();
        if (emitter) {
          s.events.forEach(e => {
            const handler = this._getHandleEvent(e);
            this._handlers.push({ emitter, name: e.name, handler });
            emitter.on(e.name, handler);
          });
        }
      });
    }

    // Ensure that both our atomic `_state` and `this.state` are updating in
    // response to incoming prop changes.
    UNSAFE_componentWillReceiveProps(nextProps) {
      this._state = {
        ...this._state,
        ...nextProps
      };
      this.setState({
        ...nextProps
      });
    }

    /**
     * Detach event handlers using cached references in `this._handlers`
     */
    componentWillUnmount() {
      if (this._handlers) {
        this._handlers.forEach(h => {
          const { emitter, name, handler } = h;
          emitter.off(name, handler);
        });
      }
      this._handlers = [];
    }

    /**
     * Higher-order function that returns the handler for an event subscription
     * @param  {Object} eventObj
     * @return {Function}          The event handler that will be passed
     *     whatever args. are passed by the specific Backbone event.
     */
    _getHandleEvent = eventObj => (...args) => {
      if (eventObj.replaceProps) {
        const newProps = eventObj.replaceProps(this._state, ...args);
        if (newProps) {
          this._state = {
            ...this._state,
            ...newProps
          };
          this.setState(this._state);
        }
      }
    };

    render = () => <WrappedComponent {...this.state} />;
  }

  ConnectedComponent.displayName = `ConnectBB(${getDisplayName(WrappedComponent)})`;

  return ConnectedComponent;
}

/**
 * Exactly like connectBB with a slightly different signature for easier composability.
 *
 * connectBB returns a react Component.
 * react-redux's `connect()` returns a function which returns a Component.
 * A very slight difference but allows for easier functional composition.
 *
 * connectBBHoc simply returns a function that accepts a Component as an argument and returns connectBB.
 * @example
 * compose(
 *  connectBBHoc([{
 *    getEmitter: () => listings,
 *    events: [{ name: 'change', replaceProps: () => {} }]
 *  }]),
 *  connect(state => state.subtree)
 * )(PresentationalComponent)
 *
 * @param {Array<Object>} subscriptions
 * @param {Function} getInitialState
 * @return {Function}
 */
export const connectBBHoc = (subscriptions, getInitialState = defaultGIS) => WrappedComponent => connectBB(WrappedComponent, subscriptions, getInitialState);

import PropTypes from 'prop-types';
import React from 'react';

const Group = (props) => {
  const {
    role,
    label,
    children,
    id,
    className,
  } = props;

  return (
    <div
      className={className}
      id={id}
      role={role}
      aria-label={label}
    >
      {children !== undefined ?
        React.Children.map(children, (child, i) => (
          child && role === 'group' ?
            (<div key={i} className="btn-group__item">{child}</div>) : child
        ))
        : null }
    </div>
  );
};

Group.propTypes = {
  role: PropTypes.oneOf([
    'group',
    'toolbar'
  ]),
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ]),
  id: PropTypes.string,
  className: PropTypes.string
};

export default Group;

/**************************************************************************************************
 *                                        GENERAL UI
 **************************************************************************************************/

export const GENERAL_UI = {
  RESULTS_SPINNER: 'prop-details__listing-results-spinner',
  FOOTER: 'footer',
  FOOTER_DISCLAIMER: 'footer__disclaimer',
};

export const PARSLEY = {
  ERRORS_LIST: 'parsley__errors-list',
};

export const TOP_NAVBAR = {
  FAVORITES_LINK: 'top-navbar__favorites-link',
  SIGN_IN: 'top-navbar__sign-in',
  SIGN_OUT: 'top-navbar__sign-out',
  SAVED_SEARCHES: 'top-navbar__saved-searches',
  SAVED_SEARCHES_PILL: 'top-navbar__saved-searches-pill',
  ACCOUNT: 'top-navbar__account',
};

/********************************************************
   *            GENERAL UI - MODALS
   ********************************************************/

export const SIGN_IN = {
  EMAIL: 'sign-in__email',
  PASSWORD: 'sign-in__password',
  SIGN_IN_BUTTON: 'sign-in__sign-in-button',
};

export const REGISTRATION_FORM = {
  NOT_A_MEMBER: 'reg-form__not-a-member',
  CONTINUE_WITH_EMAIL: 'reg-form__continue-with-email',
  STEP_ONE_ERROR: 'reg-form__step-one-error',
  STEP_ONE_EMAIL: 'reg-form__step-one-email',
  STEP_TWO_FULL_NAME: 'reg-form__step-two-full-name',
  STEP_TWO_PHONE: 'reg-form__step-two-phone',
  STEP_TWO_SUBMIT: 'reg-form__step-two-submit',
  LOG_IN_BUTTON: 'reg-form__log-in-button',
  SIGN_IN_MODAL: 'sign-in-modal',
  FACEBOOK_BUTTON: 'facebook-button',

  ONE_STEP: {
    EMAIL: 'reg-form__one-step-email',
    FULL_NAME: 'reg-form__one-step-full-name',
    PHONE: 'reg-form__one-step-phone',
    SUBMIT: 'reg-form__one-step-submit',
    SIGN_IN: 'reg-form__one-step-signin',
  },
};

export const QUAL_QUESTIONS = {
  ANSWERS: 'qual-questions__answers',
};

export const OPT_IN_MODAL = {
  MODAL: 'opt-in__tab',
  OPT_IN_BUTTON: 'opt-in__button',
  NO_THANKS: 'opt-in__no-thanks',
  TEXT: 'opt-in__text',
};

/**************************************************************************************************
   *                                        HOME
**************************************************************************************************/

export const HOMEPAGE_BALLERBOX = {
  SEARCH_BUTTON: 'homepage__ballerbox__search-button',
};

export const HOMEPAGE_MODALS = {
  ONECLICK: 'homepage__modal--onclick',
  ONECLICK_CLOSE: 'homepage__modal--oneclick__close',
};

/**************************************************************************************************
   *                                        RESULTS GALLERY
**************************************************************************************************/

export const GALLERY_SELECTORS = {
  LOAD_MORE: 'gallery__load-more',
  FAVORITE_BUTTON: 'gallery__favorite-btn',
};

export const MAP = {
  CONTAINER: 'map__container',
  NEARBY_BUTTON: 'map__nearby-button',
  USER_MARKER: 'map__user-marker',
  TOAST: 'map__toast',

  LAYER_BUTTON: 'cy-map-button-layer',
  POLYGON_OPEN_BUTTON: 'cy-map-button-polygon-open',
  POLYGON_CLOSE_BUTTON: 'cy-map-button-polygon-close',

  PIN_FAVORITE: 'map-pin__favorite',
  PIN_STATUS: 'map-pin__status',
  PIN_MINIMAL: 'map-pin--minimal',
};

export const LISTING_CARD = {
  CARD: 'listing-card_card',
  FAVORITE_BUTTON: 'listing-card_favorite-button',
  DISCLAIMER: 'listing-card__disclaimer',
  BEDS_SELECTOR: 'listing-card__beds-selector',
  BATHS_SELECTOR: 'listing-card__baths-selector',
  HALF_BATHS_SELECTOR: 'listing-card__half-baths-selector',
  CITY: 'listing-card__city-selector',
  PRICE: 'listing-card__price',
  STREET: 'listing-card__street',
  IMAGE_CONTAINER: 'listing-card__photo',
  SASH: 'listing-card__sash',
};

/********************************************************
   *            RESULTS GALLERY - SEARCH MENU
   ********************************************************/

export const DESKTOP_SORT_OPTIONS = {
  HIGHEST_PRICE: 'd-sort-options__highest-price',
  HIGHEST_PRICE_LABEL: 'd-sort-options__highest-price__label',
  LOWEST_PRICE: 'd-sort-options__lowest-price',
  LOWEST_PRICE_LABEL: 'd-sort-options__lowest-price__label',
  MOST_POPULAR: 'd-sort-options__most-popular',
  MOST_POPULAR_LABEL: 'd-sort-options__most-popular__label',
  MOST_BEDROOMS: 'd-sort-options__most-bedrooms',
  MOST_BEDROOMS_LABEL: 'd-sort-options__most-bedrooms__label',
  MOST_BATHROOMS: 'd-sort-options__most-bathrooms',
  MOST_BATHROOMS_LABEL: 'd-sort-options__most-bathrooms__label',
  HIGHEST_ACREAGE: 'd-sort-options__highest-acreage',
  HIGHEST_ACREAGE_LABEL: 'd-sort-options__highest-acreage__label',
  LOWEST_ACREAGE: 'd-sort-options__lowest-acreage',
  LOWEST_ACREAGE_LABEL: 'd-sort-options__lowest-acreage__label',
  NEWEST_BUILT: 'd-sort-options__newest-built',
  NEWEST_BUILT_LABEL: 'd-sort-options__newest-built__label',
  OLDEST_BUILT: 'd-sort-options__oldest-built',
  OLDEST_BUILT_LABEL: 'd-sort-options__oldest-built__label',
  NEWEST_ON_SITE: 'd-sort-options__newest-on-site',
  NEWEST_ON_SITE_LABEL: 'd-sort-options__newest-on-site__label',
  OLDEST_ON_SITE: 'd-sort-options__oldest-on-site',
  OLDEST_ON_SITE_LABEL: 'd-sort-options__oldest-on-site__label',
};

export const SEARCH_BAR = {
  SEARCH_TAG: 'search-bar__search_tag',
  SAVE_SEARCH: 'search-bar__save-search',
  SORT: 'search-bar__sort',

  // Search Options
  BEDS_BUTTON: 'search-bar__beds-button',
  BEDS_OPTIONS: 'search-bar__beds-options',

  BATHS_BUTTON: 'search-bar__baths-button',
  BATHS_OPTIONS: 'search-bar__baths-options',

  TYPE: 'seach-bar__type',
  SOLD: 'seach-bar__sold',

  PRICE: 'search-bar__price',
  MIN_PRICE: 'search-bar__min-price',
  MAX_PRICE: 'search-bar__max-price',

  MORE: 'search-bar__more',
};

export const ADDITIONAL_FILTERS = {
  ACRES: 'additional-filters__acres',
  GARAGES: 'additional-filters__garages',
  OPEN_HOUSE: 'additional-filters__open-house',
  SQUARE_FOOTAGE: 'additional-filters__square-footage',
  STORIES: 'additional-filters__stores',
  YEAR_BUILT: 'additional-filters__year-build',
};

export const TYPE_OPTIONS = {
  SOLD: 'type-options__sold',
  SOLD_LABEL: 'type-options__sold__label',
  PENDING: 'type-options__pending',
  PENDING_LABEL: 'type-options__pending__label',
  COMING_SOON: 'type-options__comingsoon',
  COMING_SOON_LABEL: 'type-options__comingsoon__label',
};

/********************************************************
   *            RESULTS GALLERY - SAVE SEARCH MODAL
********************************************************/

export const SAVE_SEARCH = {
  CLOSE_BUTTON: 'save-search__close-button',
  FREQUENCY_MENU: 'save-search__frequency-menu',
  GO_BACK_BUTTON: 'save-search__go-back-button',
  MODAL: 'save-search__modal',
  RESULTS: 'save-search__results',
  SEARCH_EMAIL: 'save-search__search-email',
  SEARCH_NAME: 'save-search__search-name',
  SUBMIT_BUTTON: 'save-search__submit-button',
  SUCCESS_BUTTON: 'save-search__success-button',
  TAGS: 'save-search__tags',
};

/**************************************************************************************************
   *                                        ACCOUNT
**************************************************************************************************/

export const ACCOUNT_PAGE = {
  EMAIL_PREFERENCES_BUTTON: 'account-page__email-preferences-button',
  FIRST_NAME: 'account-page__first-name',
  LAST_NAME: 'account-page__last-name',
  STREET_ADDRESS: 'account-page__street-address',
  CITY: 'account-city',
  STATE: 'account-page__state',
  ZIP_CODE: 'account-page__zip-code',
  SAVE_BUTTON: 'account-page__save-button',
  SAVED_SEARCHES: 'account-page__saved-searches',
  SAVED_SEARCHES_PILL: 'account-page__saved-searches-pill',
  TEXT_MESSAGE_PREFERENCES_BUTTON: 'account-page__text-message-preferences-button',
};

/********************************************************
   *              ACCOUNT - EMAIL PREFERENCES
   ********************************************************/

export const EMAIL_PREFS = {
  CONFIRMATION_EDIT_SAVED_SEARCHES: 'email_prefs__confirmation_edit_saved_searches',
  EDIT_SAVED_SEARCHES: 'email_prefs__edit_saved_searches',
  LISTING_EALERTS: {
    SUBSCRIBED: 'email-prefs__listing-ealerts--subscribed',
    SUBSCRIBED_LABEL: 'email-prefs__listing-ealerts--subscribed__label',
    UNSUBSCRIBED: 'email-prefs__listing-ealerts--unsubscribed',
    UNSUBSCRIBED_LABEL: 'email-prefs__listing-ealerts--unsubscribed__label',
  },
  REAL_ESTATE_TEAM: {
    SUBSCRIBED: 'email-prefs__real-estate-team--subscribed',
    SUBSCRIBED_LABEL: 'email-prefs__real-estate-team--subscribed__label',
    UNSUBSCRIBED: 'email-prefs__real-estate-team--unsubscribed',
    UNSUBSCRIBED_LABEL: 'email-prefs__real-estate-team--unsubscribed__label',
  },
  SPONSORED_LOCAL_LENDER: {
    SUBSCRIBED: 'email-prefs__sponsored-local-lender--subscribed',
    SUBSCRIBED_LABEL: 'email-prefs__sponsored-local-lender--subscribed__label',
    UNSUBSCRIBED: 'email-prefs__sponsored-local-lender--unsubscribed',
    UNSUBSCRIBED_LABEL: 'email-prefs__sponsored-local-lender--unsubscribed__label',
  },
  UNSUBSCRIBE_FROM_ALL_BUTTON: 'email-prefs__unsubscribe-from-all-button',
  YES_I_AM_SURE_BUTTON: 'email-prefs__yes-i-am-sure-button',
  UNSUB_CANCEL: 'email_prefs__unsubscribe-from-all-cancel',
};

/********************************************************
   *              ACCOUNT - TEXT PREFERENCES
   ********************************************************/

export const TEXT_PREFS = {
  TEXT_ALLOWED: 'text-prefs__text-allowed',
  TEXT_ALLOWED_LABEL: 'text-prefs__text-allowed__label',
  TEXT_NOT_ALLOWED: 'text-prefs__text-not-allowed',
  TEXT_NOT_ALLOWED_LABEL: 'text-prefs__text-not-allowed__label',
  UPDATE_TEXT_PREFERENCES: 'text-prefs__update-text-preferences',
};

/********************************************************
   *              ACCOUNT - SAVED SEARCHES
   ********************************************************/

export const SAVED_SEARCH_PAGE = {
  DELETE_SAVED_SEARCH: 'saved-search-page__delete-saved-search',
  EDIT_SAVED_SEARCH: 'saved-search-page__edit-saved-search',
  FREQUENCY_MENU: 'saved-search-page__frequency-menu',
  NO_RESULTS_CONTAINER: 'saved-search-page__no-results-container',
  SAVED_SEARCHES_CONTAINER: 'saved-search-page__saved-searches-container',
  SUBSCRIBE_EALERT_RADIO_BUTTON: 'saved-search-page__subscribe-ealert-radio-button',
  SUBSCRIBE_EALERT_RADIO_BUTTON_LABEL:
    'saved-search-page__subscribe-ealert-radio-button__label',
  TITLE: 'saved-search-page__title',
  UNSUBSCRIBE_EALERT_RADIO_BUTTON: 'saved-search-page__unsubscribe-ealert-radio-button',
  UNSUBSCRIBE_EALERT_RADIO_BUTTON_LABEL:
      'saved-search-page__unsubscribe-ealert-radio-button__label',
  UNSUBSCRIBED_BADGE: 'saved-search-page__unsubscribed-badge',
};

/**************************************************************************************************
   *                                        BUY
**************************************************************************************************/

export const BUY_PAGE = {
  SUBMIT_BUTTON: 'buy__submit',
  ALERT: 'buy__alert',

  SELECTORS: {
    PROPERTY_TYPE: 'buy__property-type',
    AREA: 'buy__area',
    PRICE_RANGE: 'buy__price-range',
    MIN_BEDS: 'buy__min-beds',
    MIN_BATHS: 'buy__min-baths',
    FEATURES: 'buy__features',
    FIRST_NAME: 'buy__first-name',
    LAST_NAME: 'buy__last-name',
    EMAIL: 'buy__email',
    PHONE: 'buy__phone',
    TIME_FRAME: 'buy__timeframe',
    REASON: 'buy__reason',
  },
};

/**************************************************************************************************
   *                                        SELL
**************************************************************************************************/

export const SELL_PAGE = {
  SUBMIT_BUTTON: 'sell__submit',
  ALERT: 'sell__alert',

  SELECTORS: {
    ADDRESS: 'sell__address',
    CITY: 'sell__city',
    STATE: 'sell__state',
    ZIP: 'sell__zip',
    FIRST_NAME: 'sell__first-name',
    LAST_NAME: 'sell__last-name',
    EMAIL: 'sell__email',
    PHONE: 'sell__phone',
  },
};

/**************************************************************************************************
   *                                        FINANCE
**************************************************************************************************/

export const FINANCE_PAGE = {
  SUBMIT_BUTTON: 'finance__submit',
  ALERT: 'finance__alert',

  SELECTORS: {
    APPLY: 'finance__apply',
    APPROVAL: 'finance__approval',
    COMMENTS: 'finance__comments',
    FIRST_NAME: 'finance__first-name',
    LAST_NAME: 'finance__last-name',
    EMAIL: 'finance__email',
    PHONE: 'finance__phone',
    CONTACT_PREFERENCE: 'finance__contact-preference',
    CONTACT_TIME: 'finance__contact-time',
  },
};

/**************************************************************************************************
   *                                        CONTACT US
**************************************************************************************************/

export const CONTACT_US_PAGE = {
  SUBMIT_BUTTON: 'contact-us__submit',
  ALERT: 'contact-us__alert',

  SELECTORS: {
    FIRST_NAME: 'contact-us__first-name',
    LAST_NAME: 'contact-us__last-name',
    EMAIL: 'contact-us__email',
    PHONE: 'contact-us__phone',
    MESSAGE: 'contact-us__message',
  },
};

/**************************************************************************************************
   *                                        PROPERTY DETAILS
**************************************************************************************************/

export const PROP_DETAILS = {
  FAVORITE_BUTTON: 'prop-details__favorite-button',
  SHARE_BUTTON: 'prop-details__share-button',
  SHARE_EMAIL: 'prop-details__share-email',
  SHARE_RECIPIENT_NAME: 'prop-details__share-recipient-name',
  SHARE_RECIPIENT_EMAIL: 'prop-details__share-recipient-email',
  SHARE_EMAIL_BODY: 'prop-details__share-email-body',
  SHARE_SEND_EMAIL: 'prop-details__share-send-email',

  // Start an Offer
  START_OFFER_BUTTON: 'prop-details__start-offer-button',
  START_OFFER_FORM: 'prop-details__start-offer-form',
  SO_FULL_NAME: 'prop-details__so-full-name',
  SO_PHONE: 'prop-details__so-phone',
  SO_EMAIL: 'prop-details__so-email',
  SO_OFFER_AMT: 'prop-details__so-offer-amt',
  SO_FINANCE_HELP: 'prop-details__so-finance-help',
  SO_HAS_VIEWED: 'prop-details__so-has-viewed',
  SO_SUBMIT: 'prop-details__so-submit',

  // Schedule Showing
  SCHEDULE_SHOWING_BUTTON: 'prop-details__schedule-showing-button',
  VIDEO_TOUR_BUTTON: 'prop-details__request-video-tour-button',
  LIKE_WHAT_YOU_SEE_TITLE: 'prop-details__like-what-you-see',
  SS_FIRST_NAME: 'prop-details__ss-first-name',
  SS_LAST_NAME: 'prop-details__ss-last-name',
  SS_EMAIL: 'prop-details__ss-email',
  SS_PHONE: 'prop-details__ss-phone',
  SS_DATE: 'prop-details__ss-date',
  SS_TIME: 'prop-details__ss-time',
  SS_SUBMIT: 'prop-details__ss-submit',
  SS_ALERT: 'prop-details__alert',
  SS_SUCCESS_ALERT: 'prop-details__alert__success',

  VIRTUAL_OPEN_HOUSE_REGISTER: 'prop-details__virtual-open-house-register',
  VIRTUAL_OPEN_HOUSE_VIEW_HERE: 'prop-details__virtual-open-house-view-here',
  VIRTUAL_OPEN_HOUSE_JOIN_BUTTON: 'prop-details__virtual-open-house-join-button',
  VIRTUAL_OPEN_HOUSE_CARD: 'prop-details__virtual-open-house-card',
  VIRTUAL_OPEN_HOUSE_HEADER: 'prop-details__virtual-open-house-header',

  DESKTOP_PHOTOSLIDER: 'prop-details__desktop-photoslider',

  // Related Properties
  RELATED_PROPERTIES: 'prop-details__related-properties',
  SCHOOL_INFO_LINK: 'prop-details__school-info-link',
};

export const MORTGAGE_CALCULATOR = {
  LIST_PRICE: 'mortgageCalcForm__listPrice',
  DOWN_PAYMENT: 'mortgageCalcForm__downPayment',
  PERCENT_DOWN: 'mortgageCalcForm__percentDown',
  LOAN_TYPE: 'mortgageCalcForm__loanType',
  INTEREST_RATE: 'mortgageCalcForm__interestRate',
  TAX_AND_INS_AMOUNT: 'mortgageCalcForm__taxAndInsAmount',
  TAX_AND_INS_PERCENT: 'mortgageCalcForm__taxAndInsPercent',
  RESULTS_PRINCIPAL_AND_INTEREST: 'mortgageCalcResults__principal',
  RESULTS_TAXES: 'mortgageCalcResults__taxes',
  RESULTS_MONTHLY_PAYMENT: 'mortgageCalcResults__monthlyPayment',
  RESULTS_PRINCIPAL: 'mortgageCalcResults__loanAmount',
};

export const MOBILE_DIRECTIONS = {
  MAP: 'mobile-directions__map',
  ADDRESS_LINE_1: 'mobile-directions__address-line-1',
  ADDRESS_LINE_2: {
    CITY: 'mobile-directions__address-line-2__city',
    STATE: 'mobile-directions__address-line-2__state',
    ZIP: 'mobile-directions__address-line-2__zip',
  },
  CLOSE_BUTTON: 'mobile-directions__close-button',
  DIRECTIONS_BUTTON: 'mobile-directions__directions-button',
};

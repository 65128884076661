/* eslint-disable no-param-reassign, camelcase, wrap-iife, no-useless-escape */
import $ from 'jquery';
import _ from 'underscore';
import UIkit from 'uikit';
import bt from 'BoomTown';
import FlickityView from 'legacy/Views/Listings/flickityView';
import moment from 'moment-timezone';

class Utility {
  EMAIL_REGEX = /^([-0-9a-zA-Z](\.?[-\w\+]*[-0-9a-zA-Z_])*@([0-9a-zA-Z]*[-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
  spaces = /\+/g;

  // Optimized Resize Listener using requestAnimationFrame
  optimizedResize = (function godo() {
    const callbacks = [];
    let running = false;
    // fired on resize event

    const resize = function resize() {
      if (!running) {
        running = true;
      }
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(runCallbacks);
      } else {
        setTimeout(runCallbacks, 66);
      }
    };

    // run the actual callbacks
    var runCallbacks = function runCBs() {
      callbacks.forEach(callback => {
        callback();
      });
      running = false;
    };

    // adds callback to loop
    const addCallback = function Add(callback) {
      if (callback) {
        callbacks.push(callback);
      }
    };

    return {
      add(callback) {
        if (!callbacks.length) {
          window.addEventListener('resize', resize);
        }
        addCallback(callback);
      },

      remove(callback) {
        const i = callbacks.indexOf(callback);
        if (i === -1) {
          return;
        }
        callbacks.splice(i, 1);
        if (!callbacks.length) {
          window.removeEventListener('resize', resize);
        }
      }
    };
  })();

  /**
   * Send a log request to Graylog
   *
   * @param {'debug'|'log'|'info'|'notice'|'warn'|'error'|'critical'|'alert'|'emergency'} type
   * @param {Object} data
   * @param {String} data.label
   * @param {String} data.fullMessage
   */
  graylog(type, data) {
    // unit testing etc..
    let query;
    if (type == null) {
      type = 'info';
    }
    if (location.protocol === 'file:') {
      return;
    }

    // params need to be in querystring format - if not that way, make it so
    if (typeof data === 'string') {
      query = data;
    } else {
      query = $.param(data);
    }

    const url = `/log/${type}/?${query}`;
    $.get(url);
  }

  /**
   * validate all the form inputs
   */
  validateParsley(form) {
    // ensure we only have 1 parsley form
    let currentForm;
    const visibleForms = $(form).filter(':visible');
    if (visibleForms.length > 1) {
      currentForm = visibleForms.eq(0).parsley();
    } else {
      currentForm = visibleForms.parsley();
    }

    currentForm.on('form:validate', formInstance => {
      // Custom required messages for the inputs. Tomas
      $('[name=first_name], [name=FirstName], [name=js-txtFirstName]').attr(
        'data-parsley-required-message',
        'Enter a first name'
      );
      $('[name=last_name], [name=LastName], [name=js-txtLastName]').attr(
        'data-parsley-required-message',
        'Enter a last name'
      );
      $('[name=full_name], [name=fullname]').attr(
        'data-parsley-required-message',
        'Enter your full name'
      );
      $('[name=email], [name=EmailAddress], [name=js-txtEmail], [name=EmailAddress]').attr(
        'data-parsley-required-message',
        'Please enter a valid email address'
      );
      $('[name=username]').attr(
        'data-parsley-required-message',
        'Please enter a valid email address'
      );
      $('[name=phone], [name=BestPhone], [name=js-txtPhone]').attr(
        'data-parsley-required-message',
        'Please enter a valid phone'
      );
      $('[name=password]').attr('data-parsley-required-message', 'Please enter your password');
      $('[name=date]').attr('data-parsley-required-message', 'Enter a date');
      $('[name=comments]').attr('data-parsley-required-message', 'Please enter your comments');
      $('[name=price]').attr('data-parsley-required-message', 'Please enter a valid price');
      $('[name=downpayment]').attr(
        'data-parsley-required-message',
        'Please enter a valid down payment'
      );
      $('[name=interest]').attr(
        'data-parsley-required-message',
        'Please enter a valid interest rate'
      );
      $('[name=tax]').attr('data-parsley-required-message', 'Please enter a valid tax');
      $('[name=date]').attr('data-parsley-required-message', 'Please select a date');

      // Address Validation
      $('[name=js-txtStreetAddress]').attr(
        'data-parsley-required-message',
        'Please enter a street address'
      );
      $('[name=js-txtCity]').attr(
        'data-parsley-required-message',
        'Please enter a city'
      );
      $('[name=js-txtState]').attr(
        'data-parsley-required-message',
        'Please enter a 2 letter state abbr.'
      );
      $('[name=js-txtZipCode]').attr(
        'data-parsley-required-message',
        'Please enter a zip code'
      );
      return formInstance.submitEvent.preventDefault();
    });

    return currentForm.isValid();
  }

  /**
   * Check for if we're on iPad size or greater
   */
  MQ_Medium() {
    return window.matchMedia('(min-width: 768px)').matches;
  }

  /**
   * Check if we're on larger screens
   */
  MQ_Large() {
    return window.matchMedia('(min-width: 960px)').matches;
  }

  /**
   * Generic hide
   */
  hide(el) {
    // SVG does not handle jQuery add/remove class
    if (el.is('svg')) {
      Array.prototype.forEach.call(document.querySelectorAll(el.selector), svgEl => {
        // CNS-1536
        // jQuery does allow you to modify class attribute directly
        if (!svgEl.classList.contains('uk-hidden')) {
          $(svgEl).attr('class', (index, classNames) => `${classNames} uk-hidden`);
          return;
        }
      });
    } else {
      el.addClass('uk-hidden');
    }
  }

  /**
   * Generic show
   */
  show(el) {
    // SVG does not handle jQuery add/remove class
    if (el.is('svg')) {
      Array.prototype.forEach.call(document.querySelectorAll(el.selector), svgEl => {
        // CNS-1536
        // jQuery does allow you to modify class attribute directly
        $(svgEl).attr('class', (index, classNames) => classNames.replace('uk-hidden', ''));
      });
    } else {
      el.removeClass('uk-hidden');
    }
  }

  floatingLabel(event) {
    const $input = event.currentTarget ? $(event.currentTarget) : $(event.srcElement);

    setTimeout(() => {
      if ($input.val()) {
        $input.closest('.bt-flabels__wrapper').addClass('bt-flabel__float');
      } else {
        $input.closest('.bt-flabels__wrapper').removeClass('bt-flabel__float');
      }
    }, 1);
  }

  absoluteToRelative(url) {
    if (typeof url !== 'string') {
      return false;
    }
    if (url.substr(0, 4) !== 'http') {
      return url;
    }
    return url.slice(url.indexOf('/', 10));
  }

  /**
   * Instantiate Flickity objects for card sliders.
   * (Doing this here because we call it in the global init and on related props)
   * @param {String} selector
   * @return {jQuery}          A set of gallery elements that were bound to the Flickity instances.
   */
  cardSlider(selector) {
    // .js-card-slider:not(.js-related-properties)     -> for all widgets
    // .js-related-properties                          -> for single listing results
    const galleryElems = $(selector);

    // each gallery (<ul>) create a flickity slider
    return galleryElems.each((index, element) => new FlickityView({ el: $(element) }));
  }

  decode(str) {
    if (typeof str !== 'string') {
      return str;
    }
    return decodeURIComponent(str.replace(this.spaces, ' '));
  }

  addCommas(number) {
    if (number != null) {
      return Math.round(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return null;
  }

  currentTheme() {
    const last = bt.config.themeUrl.lastIndexOf('/') + 1;
    return bt.config.themeUrl.substr(last);
  }

  getDefaultSearchUrl(qs) {
    return `${window.location.protocol}//${window.location.host}/${bt.config
      .defaultSearchUrl}/?${qs}`;
  }

  deref(obj, s) {
    let i = 0;
    s = s.split('.');
    while (obj && i < s.length) {
      obj = obj[s[i++]];
    }
  }

  shortenDollarAmount(number, options) {
    options = options || {};
    const defaults = {
      thousandsPlaces: 2,
      millionsPlaces: 2
    };
    const settings = $.extend({}, defaults, options);
    if (number / 1000000 >= 1) {
      return '{0}M'.format(this.round(number / 1000000, settings.millionsPlaces));
    } else if (number / 1000 > 1) {
      return '{0}K'.format(this.round(number / 1000, settings.thousandsPlaces));
    }
    return `${number}`;
  }

  round(number, decimalPlaces) {
    const result = Math.round(number * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    return result;
  }

  /**
   * core/utils.php
   * strips non-word chars and spaces from a string
   * ie: this # string sucks! = this-string-sucks
   * EC-349: Update to match behavior of BoomTown.Web.UrlHelper.Slugify() in C#
   *
   * @param string $str
   *
   * @return string
   *
   * Demo -> http://codepen.io/anon/pen/ZbWwvd?editors=101
   */
  slugify(str) {
    if (str === undefined) {
      return false;
    }
    // replace spaces and dashes with a dash
    // php -> $text = preg_replace('~[-\s]+~', '-', $text);
    str = str.replace(/[-\s]+/g, '-');

    // strip anything that's not a space or word char
    // EC-349: dashes are OK, too
    // php -> $text = preg_replace('~[^\w\s\-]~', '', $text);
    str = str.replace(/[^\w\s\-]/g, '');

    // replace multiple dashes with one
    // php -> $text = preg_replace('[-+]','-',$text);
    str = str.replace(/-+/g, '-');

    // strip leading or trailing dashes
    // php -> $text = trim($text,'-');
    let lastChar = str.length - 1;
    while (str[lastChar] === '-') {
      str = str.substr(0, lastChar);
      lastChar--;
    }

    while (str[0] === '-') {
      str = str.substr(1);
    }

    // php -> return empty($text) ? '-' : $text
    if (str) {
      return str;
    }
    return '-';
  }

  oldSlugify(str) {
    // remove all newlines, spaces (including non-breaking spaces), and tabs from the beginning and end of the string
    str = $.trim(str);

    // remove any character that isn't alphanumeric, a dash or a space
    str = str.replace(/[^A-Za-z0-9-\s]/g, '');

    // replace multiple spaces with 1 space
    str = str.replace(/\s{2,}/g, ' ');

    // replace spaces with dashes
    str = str.replace(/\s/g, '-');

    // return new slug
    return str;
  }

  pluralize(text, count) {
    if (count === 0 || count > 1) {
      // don't pluralize if last letter is s
      const last = text.charAt(text.length - 1);
      const uptolast = text.substring(0, text.length - 1);
      if (last === 's') {
        return text;
      }
      if (last === 'y') {
        return `${uptolast}ies`;
      }
      return `${text}s`;
    }
    return text;
  }

  // REFACTOR: mv me to api.js that is my home
  replaceTildes(obj) {
    if (obj == null) {
      obj = {};
    }
    return _.each(obj, (val, key) => {
      if (_.isString(val)) {
        (obj[key] = val.replace(/~/g, ','));
      }
    });
  }

  stackTrace() {
    const err = new Error();
    return err.stack;
  }

  createQueryString(obj) {
    return $.param(obj);
  }

  /**
   * Take a querystring and remove certain parameters
   *
   * @param {string}  qs
   * @param {arrau} paramsToRemove
   * @returns string
   * @memberof Utility
   */
  cleanQueryString(qs, paramsToRemove) {
    return this.createQueryString(
      _.omit(
        this.parseQueryString(qs),
        paramsToRemove
      )
    );
  }

  // From querystring to JS Object
  // [aknox] We don't care if the value contains a tilde!
  // CNS-1523
  parseQueryString(qs, lowerkey) {
    try {
      if (lowerkey == null) {
        lowerkey = true;
      }
      let input = qs || location.search; // arg is optional, browser querystring otherwise

      // CNS-508 -- silently redirect to the proper url if amperstands are in &amp; format
      input = input.replace(/&amp;/g, '&');

      // in case there's anything before the ?, and strip off the leading ?
      input = input.replace(/^.*\?(.*)/, '$1');
      const urlParams = {};
      (() => {
        // replace + with space
        const re = /([^&=]+)=?([^&]*)/g;
        let e = re.exec(input); // split out var=val pairs (this caches the regex)
        return (() => {
          const result = [];
          while (e) {
            // note the lowercasing
            let key = this.decode(e[1]);
            if (lowerkey) {
              key = key.toLowerCase();
            }
            urlParams[key] = this.decode(e[2]);
            result.push((e = re.exec(input)));
          }
          return result;
        })();
      })();
      return urlParams;
    } catch {
      console.warn('Could not parse query string in utility/parseQueryString'); // eslint-disable-line
      return [];
    }
  }

  getQueryStringSegments(qs) {
    const segments = [];
    if (qs[0] === '?') {
      qs = qs.substring(1);
    }
    const qsParts = qs.split('&');
    let i = 0;

    while (i < qsParts.length) {
      const qsPart = qsParts[i];
      const field = qsPart.split('=')[0];
      const value = qsPart.substring(qsPart.indexOf('=') + 1);
      if (value.indexOf(',') > -1) {
        const valueParts = value.split(',');
        let j = 0;

        while (j < valueParts.length) {
          const valuePart = valueParts[j];
          segments.push('{0}={1}'.format(field, valuePart));
          j++;
        }
      } else {
        segments.push(qsPart);
      }
      i++;
    }
    return segments;
  }

  getNormalizedHash() {
    const historyHash = bt.global.history.getState().hash;
    if (historyHash === './') {
      return '';
    }
    return historyHash.substring(historyHash.indexOf('?'));
  }

  arrayRemove(arr, item) {
    const index = $.inArray(item, arr);
    if (index === -1) {
      return arr;
    }
    arr.splice(index, 1);
    return arr;
  }

  isCanadian() {
    return bt.tenant.isCanadian;
  }

  filterBallerboxPlaceholder(str) {
    if (bt.tenant.isCanadian) {
      str = str.replace(/zip code/gi, 'Postal Code');
      str = str.replace(/zip/gi, 'Postal Code');
      str = str.replace(/state/gi, 'Province');
    }

    return str;
  }

  ajax(context, options, success, error) {
    const defaults = {
      url: bt.config.ajaxUrl,
      data: {
        nonce: bt.config.nonce,
        action: 'ajax_submit'
      },

      success(data, status, xhr) {
        // add url to xhr so that callbacks
        // can get jiggy
        xhr.url = this.url;
        if (xhr.status === 200) {
          const nonce = xhr.getResponseHeader('X-Nonce');
          if (nonce) {
            bt.config.nonce = nonce;
          }
          if ($.isFunction(success)) {
            success.call(context, data, status, xhr);
          }
        }
      },

      error(xhr, status, errorThrown) {
        if ($.isFunction(error)) {
          error.call(context, xhr, status, errorThrown);
        }
      }
    };

    const settings = $.extend(true, defaults, options);
    return $.ajax(settings);
  }

  // heavily borrowed from http://code.google.com/p/jquery-simple-templates/
  format(template = '', vals = {}) {
    // regular expression for matching our placeholders; e.g., #{my-cLaSs_name77}
    const regex = /#\{([^\{\}]*)\}/g;

    // function to making replacements
    const repr = function repr(str, match) {
      // return the value if it exists or empty string
      if (typeof vals[match] === 'string' || typeof vals[match] === 'number') {
        return vals[match];
      }
      return '';
    };

    return template.replace(regex, repr);
  }

  disclaimerTemplate(template, data) {
    const defaults = {
      STREETFULL: bt.tenant.street,
      TENANTNAME: bt.tenant.legalName,
      OFFICENAME: bt.tenant.legalName,
      TENANTSTREET: bt.tenant.street,
      TENANTCITY: bt.tenant.city,
      TENANTSTATE: bt.tenant.state,
      TENANTZIP: bt.tenant.zip,
      TENANTPHONE: bt.tenant.phone,
      TENANTEMAIL: bt.tenant.email,
      YEAR: bt.utility.dateFormat(new Date(), 'YYYY'),
      UPDATED: bt.config.boardUpdated,
      UPDATEDTIMESTAMP: bt.config.boardUpdatedTimestamp
    };

    const replace = _.extend(defaults, data);
    _.each(
      _.keys(replace),
      key => (template = template.replace(new RegExp(`\\[${key}\\]`, 'gm'), replace[key]))
    );

    return template;
  }

  allDataPagesDisclaimer() {
    let disclaimer = '';
    const boards = _.keys(bt.config.boardHTMLs);
    _.each(boards, id => {
      const board = bt.config.boardHTMLs[id];
      if (_.has(board, 'AllDataPagesDisclaimer')) {
        const extend = {
          UPDATED: bt.config.boards[id].LastUpdatedAgo,
          UPDATEDTIMESTAMP: bt.config.boards[id].LastUpdated
        };
        disclaimer += bt.utility.disclaimerTemplate(board.AllDataPagesDisclaimer, extend);
      }
    });
    return disclaimer;
  }

  /**
   * Render one of our disclaimer HTML templates.
   * @param {string} disclaimerText A disclaimer text template
   * @param {Object} vals The data used to render the template (all uppercase value )
   * @returns {string} A rendered HTML string
   */
  getReplacedDisclaimer(disclaimerText, vals) {
    if (!disclaimerText) {
      return '';
    }

    /**
     * TODO: This adding of properties to the template data (`vals`) should be
     * done in a single place. There's more of it in `listing.js` where this
     * function is called.
     */

    // Set up basic information (ref core/boardhtml.php)
    const { tenant } = bt;
    vals.STREETFULL = tenant.street;
    vals.TENANTNAME = tenant.legalName;

    if (!_.has(vals, 'AGENTDRE')) {
      vals.AGENTDRE = '';
    }

    if ((vals.OFFICE != null ? vals.OFFICE.Name : undefined) != null) {
      vals.OFFICENAME = vals.OFFICE.Name;
    }

    // CNS-1777/CNS-1763/TAD-437: Blank out the agent phone if one does not exist.
    // Per Kristin, showing the office phone for the agent phone is a compliance violation.
    if (vals.AGENTPHONE == null) {
      vals.AGENTPHONE = '';
    }

    if (
      (vals.AGENT != null ? vals.AGENT.FirstName : undefined) != null &&
      (vals.AGENT != null ? vals.AGENT.LastName : undefined) != null
    ) {
      vals.AGENTNAME = `${vals.AGENT.FirstName} ${vals.AGENT.LastName}`;
    }

    vals.TENANTSTREET = tenant.street;
    vals.TENANTCITY = tenant.city;
    vals.TENANTSTATE = tenant.state;
    vals.TENANTZIP = tenant.zip;
    vals.TENANTPHONE = tenant.phone;
    vals.TENANTEMAIL = tenant.email;
    vals.YEAR = this.dateFormat(new Date(), 'YYYY');
    vals.UPDATED = bt.config.boardUpdated;
    vals.UPDATEDTIMESTAMP = bt.config.boardUpdatedTimestamp;

    Object.keys(vals).forEach(key => {
      if (key === 'COAGENTS') {
        const pattern = new RegExp('\\[co-agents\\][^\\/]*\\/co-agents\\]', 'gmi');
        const doesMatch = pattern.test(disclaimerText);

        if (doesMatch) {
          const match = disclaimerText.match(pattern)[0];
          let coagentReplacementString = match;
          coagentReplacementString = coagentReplacementString.replace('[CO-AGENTS]', '');
          coagentReplacementString = coagentReplacementString.replace('[/CO-AGENTS]', '');
          const replacementStrings = [];

          if (!_.isEmpty(vals[key])) {
            Object.keys(vals[key]).forEach(coagentIndex => {
              const coagent = vals[key][coagentIndex];
              let replacementString = coagentReplacementString;
              if (typeof coagent.DRE !== 'undefined') {
                replacementString = replacementString.replace(
                  new RegExp('\\[CO-AGENTDRE\\]', 'gm'),
                  coagent.DRE
                );
              }
              if (typeof coagent.Name !== 'undefined') {
                replacementString = replacementString.replace(
                  new RegExp('\\[CO-AGENTNAME\\]', 'gm'),
                  coagent.Name
                );
              }
              if (typeof coagent.OfficeName !== 'undefined') {
                replacementString = replacementString.replace(
                  new RegExp('\\[CO-OFFICENAME\\]', 'gm'),
                  coagent.OfficeName
                );
              }
              replacementStrings.push(replacementString);
              const coAgentReplacement = replacementStrings.join(' ');
              disclaimerText = disclaimerText.replace(pattern, coAgentReplacement);
            });
          } else {
            disclaimerText = disclaimerText.replace(match, '');
          }
        }
      } else {
        disclaimerText = disclaimerText.replace(new RegExp(`\\[${key}\\]`, 'gm'), vals[key]);
      }
    });

    return disclaimerText;
  }

  // turns this.long.domain.com into .domain.com
  cookieDomain(domain) {
    domain = domain || location.hostname;
    return domain.substring(domain.lastIndexOf('.', domain.lastIndexOf('.') - 1));
  }

  // Get or set a cookie.
  cookie(name, value, options) {
    if (arguments.length > 1) {
      options = options || {};
      options.domain = bt.config.cookieDomain || options.domain || this.cookieDomain();
      options.path = options.path || '/';
      if (!options.expires && name === bt.config.dataCookieName) {
        if (bt.config.dataCookieExpDate) {
          // `dataCookieExpDate` is a JS Date object, and jquery.cookie needs an interval in days.
          const expMoment = moment(bt.config.dataCookieExpDate);
          const expDiff = expMoment.diff(moment());
          const expDur = moment.duration(expDiff);
          options.expires = expDur.asDays();
        } else {
          options.expires = 1 / 24;
        }
      }
      return $.cookie(name, value, options);
    }
    return $.cookie(name);
  }

  // Date functions (thanks, Moment.js)
  dateFormat(date, format) {
    return moment(date).format(format);
  }

  /**
   * Just returning the jqXHR from `this.jsonp()` clashes with redux-saga.
   *
   * @param {string} url
   * @return {JQueryXHR}
   * @memberof Utility
   */
  jsonpPromise(url) {
    return new Promise((resolve, reject) => {
      this.jsonp(url, resolve, reject);
    });
  }

  jsonp(url, success, error) {
    let appender = '?';
    if (/.+\?/.test(url)) {
      appender = '&';
    }
    url += `${appender}access_token=${bt.config.token}&callback=?`;
    const options = {
      url,
      dataType: 'json'
    };
    return bt.global.ajax(this, options, success, error);
  }

  jsonp2(url, success, error, context) {
    if (context == null) {
      context = this;
    }
    let appender = '?';
    if (/.+\?/.test(url)) {
      appender = '&';
    }
    url += `${appender}access_token=${bt.config.token}&callback=?`;
    const options = { url, dataType: 'json' };
    return bt.global.ajax(context, options, success, error);
  }

  bind(selector, eventName, callback) {
    return this.find(selector).bind(eventName, $.proxy(callback, this));
  }

  delegate(selector, eventName, callback) {
    const root = this.el || $('body');
    return root.delegate(selector, eventName, $.proxy(callback, this));
  }

  find(selector) {
    // object of some sort - just jquerify
    if (typeof selector === 'object') {
      return $(selector);
    }

    // fall back to global selector if someone forgot to set el
    if (!this.el || this.el.length === 0) {
      return $(selector);
    }
    return this.el.find(selector);
  }

  // CNS-424
  // Shoot user up to the top of the screen when they interact with filters
  // Called in Views/Listing/(hero, gallery).js
  scrollTop() {
    $('html,body').animate({ scrollTop: 0 }, 300);
  }

  /**
   * Utility function to be used when smooth scroll to a certain position is needed
   *
   * @param {number} elOffset
   * @param {function} cb
   */
  scrollToPosition(elOffset, cb) {
    UIkit.$('html,body')
      .stop()
      .animate({ scrollTop: elOffset }, 750, UIkit.$.easing.easeOutExpo)
      .promise()
      .done(cb);
  }
}

export default new Utility();

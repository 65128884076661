/* eslint-disable no-cond-assign, no-param-reassign */

/**
 * A wrapper around Google Analytics page and event tracking
 * that handles the domain account and the master account
 *
 * The ga Command Queue -> https://developers.google.com/analytics/devguides/collection/analyticsjs/command-queue-reference
 * ga Object Methods -> https://developers.google.com/analytics/devguides/collection/analyticsjs/ga-object-methods-reference
 * Tracker Object -> https://developers.google.com/analytics/devguides/collection/analyticsjs/tracker-object-reference
 * Field Referemce -> https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference
*/
export default {
  verbose: false,

  log(value) {
    if (this.verbose && window.bt) {
      window.bt.events.trigger('log', value);
    }
  },

  init() {
    if (typeof window.bt.conversionCallback === 'function') {
      window.bt.conversionCallback(this);
      // null it so can't be called again
      window.bt.conversionCallback = null;
    }
  },

  trackClientPageview(url = null) {
    if (url != null && typeof ga !== 'undefined') {
      window.ga(() => {
        let tracker;
        const trackers = window.ga.getAll();
        while ((tracker = trackers.pop())) {
          const name = tracker.get('name');
          if (name.slice(0, 3) !== 'gtm') {
            tracker.set('page', url);
            tracker.send('pageview');
          }
        }
      });
      this.log(['pageview', url]);
    }
  },

  trackEvent(category = null, action = null, label) {
    if (label == null) {
      label = '';
    }
    if (category != null && action != null && typeof ga !== 'undefined') {
      window.ga(() => {
        let tracker;
        const trackers = window.ga.getAll();
        while ((tracker = trackers.pop())) {
          const name = tracker.get('name');
          if (name.slice(0, 3) !== 'gtm') {
            tracker.set({
              eventCategory: category,
              eventAction: action,
              eventLabel: label
            });

            tracker.send('event');
          }
        }
      });
      this.log(['event', category, action, label]);
    }
  }
};

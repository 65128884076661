import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'redux-little-router';
import cx from 'classnames';
import md5 from 'md5';

export default function UserAvatar({ visitor: v, dataCY, gravatarError, className, link }) {
  const initials = `${v.firstName.slice(0, 1)}${v.lastName.slice(0, 1)}`;

  let content = initials;
  if (v.hasGravatar && v.username) {
    // If the image doesn't load it will 404
    // we'll catch the error and fall back to initials
    const imgURL = getGravatar(v.username);
    content = (
      <img src={imgURL} alt={v.fullName} className="user-avatar__img" onError={gravatarError} />
    );
  }

  return (
    <Link href={link} data-cy={dataCY} className={cx('user-avatar', className)}>
      {content}
    </Link>
  );
}
UserAvatar.propTypes = {
  visitor: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    username: PropTypes.string, // `undefined` when the user is a bot
    hasGravatar: PropTypes.bool.isRequired,
  }).isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dataCY: PropTypes.string,
  link: PropTypes.string,

  // actions
  gravatarError: PropTypes.func.isRequired,
};

UserAvatar.defaultProps = {
  link: '/account/',
  dataCY: 'mobile-navbar__my-account',
};

/**
 * Get an avatar from gravatar via email
 *
 * @param {string} email
 * @param {number} size
 * @param {string|number} defaultImage If you provide a URL it must be publicly accessible
 * @see http://en.gravatar.com/site/implement/images/
 */
function getGravatar(email, size = 60, defaultImage = 404) {
  return `//www.gravatar.com/avatar/${md5(email.toLowerCase())}.jpg?s=${size}&d=${defaultImage}`;
}

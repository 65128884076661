import { connect } from 'react-redux';
import { getVisitorAccountInfo } from 'models/visitor/reducers/selectors';
import * as editAccountActions from './actions';
import { getEditAccountState } from './reducer';


export default connect(
  state => ({
    visitor: getVisitorAccountInfo(state),
    ...getEditAccountState(state),
  }),
  {
    onSubmit: editAccountActions.submitEditAccountForm,
    onResSuccessButtonClick: editAccountActions.closeEditAccountSuccessModal,
    onResBackButtonClick: editAccountActions.closeEditAccountFailureModal,
    onResRetryButtonClick: editAccountActions.retrySubmitEditAccount,
  }
);

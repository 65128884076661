import PropTypes from 'prop-types';
import React, { useState } from 'react';
import cx from 'classnames';
import { config, rules } from 'BoomTown';

import { PrimaryButton, SecondaryButton } from 'coreComponents';
import FavoriteButton from 'components/core/Buttons/FavoriteButton';
import StreetAddress from 'components/Card/StreetAddress';
import CardAddress from 'components/Card/CardAddress';
import CardFeatureData from 'components/Card/FeatureData';
import CardMeta from 'components/Card/CardMeta';
import CardDisclaimers from 'components/Card/CardDisclaimers';
import Sash from 'components/Common/Sash';
import Price from 'components/Common/Price';
import PhotoSlider from 'components/Common/Slider/rafFlickity';
import SPALink from 'components/Common/SPALink';

import { getPrice } from 'selectors/listings';
import { Grid, Cell } from 'components/core/Grid';

import { LISTING_CARD } from 'cypress_constants';
import SlideCountPill from 'components/Common/SlideCountPill';

export default function Card(props) {
  const {
    listing,
    showCompare,
    compareDisabled,
    selectedForCompare,
    isFavorited,
    isActive,
    isMobileMapCard,
    initialSlideIndex,
    increaseDisclaimerFontSize,
    isHomepageOrMap,
    matchDisclaimerFontStyles,
    renderPhotoCount,
    renderPhotoSlider,
    renderStreetAddress,
    useDirectRoute,
    onCompareClick,
    onRemoveClick,
    onFavoriteClick,
    onCardClick,
    onPhotoSlide,
  } = props;

  const {
    _ID,
    Location,
    SashType,
    SashText,
    Index,
    Url,
    UrlPath,
    StatusCode,
    OfficeName,
    AgentPhone,
    PropertyType,
    Photos,
    HideAddress,
    ListingTopHtml,
    IsMultiBoard,
    ThumbCardDisclaimer,
    MultiMLSThumbcardDisclaimer,
    HomePageAndMapDisclaimer,
    OnSiteText,
    ShowDaysOld,
    FeatureText,
    hideListingCardFavoriteBtn,
    hideListingCardNeighborhood,
    DisplayBtAsDataSource,
    SoldThumbCardDisclaimer,
    OnMarketDateFormatted,
  } = listing;

  /**
   * We need to keep track of the current slide index, but we only care about it on the component's
   * initial mounting. Otherwise, each time the user slides to a photo, the RafSlider component
   * will completely rerender. This problem actually stems from how we're deriving initial state
   * in the RafSlider component. However, making changes to that component would have many more
   * side effects and should just be converted to a functional component soon anyways.
   */
  const [slideIndexOnMount] = useState(initialSlideIndex);

  const listingCardComponentClasses = cx('bt-listing-teaser', {
    favorited: isFavorited,
    [`bt-listing-teaser__${SashType}`]: SashType,
    [`bt-listing-proptype__${PropertyType._ID}`]: PropertyType._ID,
    'bt-listing-teaser--selected': selectedForCompare,
    'bt-listing-teaser--active': isActive,
    'bt-listing-teaser__mobile-map': isMobileMapCard,
    'bt-listing-teaser__preview': !renderPhotoSlider,
  });

  /** @param {Event} e */
  const onListingCardClick = (e) => {
    e.preventDefault();

    if (e.target.classList.contains('flickity-button')) {
      return;
    }

    // CNS-2635 Don't follow link if property is offmarket
    if (StatusCode === 'O') {
      return;
    }

    const validStatus = ['A', 'AC'];
    if (!rules.get('HideComingSoon')) {
      validStatus.push('CS');
    }

    // CNS-4474 Need to know whether or not we can compare sold properties
    if (
      validStatus.indexOf(StatusCode) > -1 ||
      (config.allowSoldData && ['S', 'P'].indexOf(StatusCode) > -1)
    ) {
      if (useDirectRoute) {
        window.location.href = UrlPath;
      } else {
        onCardClick(UrlPath, _ID);
      }
    }
  };

  const compareAction = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!selectedForCompare) {
      onCompareClick(_ID);
    } else {
      onRemoveClick(_ID);
    }
  };

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <article
      className={listingCardComponentClasses}
      onClick={onListingCardClick}
      data-gtm="listing-card"
      data-listingid={_ID}
      data-lat={Location.Coordinates.Latitude}
      data-lng={Location.Coordinates.Longitude}
      data-address={Location.FormattedAddress}
      data-postalcode={Location.PostalCode}
      data-index={Index}
      data-url={Url}
      data-statuscode={StatusCode}
      data-officename={OfficeName}
      data-agentphone={AgentPhone}
      data-cy={LISTING_CARD.CARD}
      itemScope
      itemType={`http://schema.org/${PropertyType.Schema}`}
    >
      <div
        className="bt-position--relative bt-listing-teaser__image-container"
        data-cy={LISTING_CARD.IMAGE_CONTAINER}
      >
        {/* For now, we are going to not test the photoSlider on desktop. */}
        {renderPhotoSlider ? (
          <PhotoSlider
            sliderClassName="listing-card__photo-slider"
            imageClassName="listing-card__image"
            altText={Location.FormattedAddress}
            deferSliderRender
            initialIndex={slideIndexOnMount}
            listingId={_ID}
            onSlide={onPhotoSlide}
            photos={Photos.map((photo) => photo?.LargeUrl)}
            slideHref={Url}
            fixedHeight={isMobileMapCard}
          />
        ) : (
          <SPALink href={Url}>
            <div
              className="bt-cover__wrapper listing-card__image"
              style={{ backgroundImage: `url(${Photos[0]?.LargeUrl})` }}
              role="img"
              alt={Location.FormattedAddress}
            >
              <PrimaryButton className="bt-listing-teaser__image-cta" width="half">
                View Details
              </PrimaryButton>
            </div>
          </SPALink>
        )}

        {SashType && SashText && (
          <div className="bt-sash--listing-card">
            <Sash sashText={SashText} sashType={SashType} />
          </div>
        )}

        {!hideListingCardFavoriteBtn && (
          <FavoriteButton
            isFavorite={isFavorited}
            titleAddress={`${Location.StreetNumber} ${Location.StreetName}`}
            listingID={_ID}
            handleClick={onFavoriteClick}
            stopPropagation
            dataCY={LISTING_CARD.FAVORITE_BUTTON}
            dataGTM="listing-card__favorites-button"
          />
        )}

        <div className="listing-card__cover-overlay">
          <Grid alignItems="center" className="mt-4">
            <Cell xs={10}>
              <Price
                dataCY={LISTING_CARD.PRICE}
                className="listing-card__price"
                price={getPrice(listing)}
              />
            </Cell>
            <Cell xs={2}>
              {/* Without this conditional, the pill count is duplicated on mobile */}
              {renderPhotoCount && (
                <SlideCountPill
                  className="bt-listing__slide-count-pill--preview"
                  current={1}
                  total={Photos.length}
                />
              )}
            </Cell>
          </Grid>
        </div>
      </div>

      <div className="listing-card__info">
        {ListingTopHtml && (
          <CardDisclaimers
            className="mb-4"
            listingTopHtml={ListingTopHtml}
            matchDisclaimerFontStyles={matchDisclaimerFontStyles}
          />
        )}

        {/* Only show the StreetAddress when rendered in the Comparison Table */}
        {renderStreetAddress ? (
          <StreetAddress location={Location} hideAddress={HideAddress} />
        ) : (
          <CardAddress
            className="mt-4"
            neighborhood={!hideListingCardNeighborhood && Location?.Neighborhood?.Name}
            city={Location?.CityDetail?.Name}
          />
        )}

        <CardMeta listing={listing} />

        <CardFeatureData
          daysOnSiteText={ShowDaysOld ? OnSiteText : null}
          displayDataSource={DisplayBtAsDataSource}
          onMarketDate={!!(OnMarketDateFormatted && StatusCode === 'CS') && OnMarketDateFormatted}
          features={FeatureText}
        />
      </div>

      {!ListingTopHtml && (
        <CardDisclaimers
          thumbCardDisclaimer={ThumbCardDisclaimer}
          multiMLSDisclaimer={MultiMLSThumbcardDisclaimer}
          homeAndMapDisclaimer={HomePageAndMapDisclaimer}
          isMultiBoard={IsMultiBoard}
          soldThumbCardDisclaimer={SoldThumbCardDisclaimer}
          isHomepageOrMap={isHomepageOrMap}
          increaseDisclaimerFontSize={increaseDisclaimerFontSize}
        />
      )}

      {showCompare && (
        <div className="bt-listing-teaser__compare-button-container">
          <SecondaryButton
            className="mt-12 bt-listing-teaser__compare-button at-add-remove-listing-card-button"
            onClick={(e) => compareAction(e)}
            width="full"
            disabled={Boolean(compareDisabled && !selectedForCompare)}
            dataGTM="listing-card__compare-button"
          >
            {!selectedForCompare ? 'Add to Compare' : 'Remove from Compare'}
          </SecondaryButton>
        </div>
      )}
    </article>
  );
  /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
}

Card.displayName = 'Listing Card';
Card.propTypes = {
  listing: PropTypes.shape({
    _ID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    SashType: PropTypes.string,
    SashText: PropTypes.string,
    Index: PropTypes.number,
    Url: PropTypes.string,
    UrlPath: PropTypes.string,
    StatusCode: PropTypes.string,
    HideAddress: PropTypes.bool,
    Distance: PropTypes.number,
    Title: PropTypes.string,
    PhotoMedium: PropTypes.string,

    OfficeName: PropTypes.string,
    AgentPhone: PropTypes.string,
    Acreage: PropTypes.number,
    PricePerSqFt: PropTypes.string, // But really should be a number
    YearBuilt: PropTypes.number,
    FeatureText: PropTypes.arrayOf(PropTypes.shape({
      Name: PropTypes.string,
      Features: PropTypes.arrayOf(PropTypes.string),
    })),
    Location: PropTypes.shape({
      Neighborhood: PropTypes.shape({
        Name: PropTypes.string,
        Url: PropTypes.string,
      }),
      StreetNumber: PropTypes.string,
      StreetName: PropTypes.string,
      CityDetail: PropTypes.shape({
        Name: PropTypes.string,
      }),
      FormattedAddress: PropTypes.string,
      Coordinates: PropTypes.shape({
        Latitude: PropTypes.number,
        Longitude: PropTypes.number,
      }),
      PostalCode: PropTypes.string,
    }),
    Photos: PropTypes.arrayOf(PropTypes.shape({
      LargeUrl: PropTypes.string,
    })),
    ListingTopHtml: PropTypes.string,
    ThumbCardDisclaimer: PropTypes.string,
    PreviewDisclaimer: PropTypes.string,
    ResultsDisclaimer: PropTypes.string,
    IsMultiBoard: PropTypes.bool,
    PropertyType: PropTypes.shape({
      Name: PropTypes.string,
      _ID: PropTypes.string,
      Schema: PropTypes.string,
    }),
    MultiMLSThumbcardDisclaimer: PropTypes.string,
    HomePageAndMapDisclaimer: PropTypes.string,
    SoldThumbCardDisclaimer: PropTypes.string,
    OnSiteText: PropTypes.string,
    ShowDaysOld: PropTypes.bool,
    DisplayBtAsDataSource: PropTypes.bool,
    showPriceTypeOnListing: PropTypes.bool,

    hideListingCardFavoriteBtn: PropTypes.bool,
    hideListingCardNeighborhood: PropTypes.bool,
    OnMarketDateFormatted: PropTypes.string,
  }).isRequired,
  isFavorited: PropTypes.bool,
  showCompare: PropTypes.bool,
  compareDisabled: PropTypes.bool,
  selectedForCompare: PropTypes.bool,
  onCompareClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  onFavoriteClick: PropTypes.func,
  onCardClick: PropTypes.func,
  isMobileMapCard: PropTypes.bool,
  isActive: PropTypes.bool,
  isHomepageOrMap: PropTypes.bool,
  renderPhotoCount: PropTypes.bool,
  renderPhotoSlider: PropTypes.bool,
  renderStreetAddress: PropTypes.bool,
  increaseDisclaimerFontSize: PropTypes.bool,
  matchDisclaimerFontStyles: PropTypes.bool,

  // photo slider props
  initialSlideIndex: PropTypes.number,
  onPhotoSlide: PropTypes.func,
  useDirectRoute: PropTypes.bool,
};

Card.defaultProps = {
  isFavorited: false,
  showCompare: false,
  compareDisabled: false,
  selectedForCompare: false,
  renderPhotoSlider: false,
  renderPhotoCount: true,
  isMobileMapCard: false,
  isHomepageOrMap: false,
  isActive: false,
  renderStreetAddress: false,
  increaseDisclaimerFontSize: false,
  matchDisclaimerFontStyles: false,
  useDirectRoute: false,
  onCompareClick: function noop() {},
  onCardClick: function noop() {},
  onRemoveClick: () => {},
  onFavoriteClick: () => {},
  onPhotoSlide: () => {},
  initialSlideIndex: 0,
};

import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';
import { LOCATION_CHANGED } from 'redux-little-router';
import {
  submitPasswordChange,
  receivePasswordChangeFailure,
  receivePasswordChangeSuccess,
  autoDismissChangePasswordSuccessModal,
  clickCloseChangePasswordSuccessModal,
  clickRetryInChangePasswordFailureModal,
  clickBackInChangePasswordFailureModal,
} from './actions';

const isSubmitting = createReducer(
  {
    [submitPasswordChange]: () => true,
    [receivePasswordChangeSuccess]: () => false,
    [receivePasswordChangeFailure]: () => false,
  },
  false
);

/**
 * The `responseModal` state is passed down through the form component to the
 * ResponseModal as props.
 */
const defaultRespModalState = {
  isOpen: false,
  isSuccess: false,
  successText: null,
  errorMessage: null,
};
function closeModal(state) {
  return {
    ...state,
    isOpen: false,
  };
}
const responseModal = createReducer(
  {
    // Opening the modal
    [receivePasswordChangeFailure]: (state, { responseText }) => ({
      ...state,
      isOpen: true,
      isSuccess: false,
      errorMessage: responseText || 'Oops! Something went wrong.',
    }),
    [receivePasswordChangeSuccess]: state => ({
      ...state,
      isOpen: true,
      isSuccess: true,
      successText: 'Your password has been changed.',
      errorMessage: null,
    }),
    [LOCATION_CHANGED]: () => defaultRespModalState,

    // Closing the modal
    [autoDismissChangePasswordSuccessModal]: closeModal,
    [clickCloseChangePasswordSuccessModal]: closeModal,
    [clickRetryInChangePasswordFailureModal]: closeModal,
    [clickBackInChangePasswordFailureModal]: closeModal,
  },
  defaultRespModalState
);

export default combineReducers({
  isSubmitting,
  responseModal,
});

/* **************************** Selectors **************************** */

export const _getFormProps = state => ({
  isSubmitting: state.isSubmitting,
});

export const _isSubmittingChangePassword = x => x.isSubmitting;

/** @returns {{isOpen, isSuccess, successText, errorMessage}} */
export const _getResponseModal = x => x.responseModal;

// Still kicking this can down the road... Here we actually have two levels that
// the selector has to pass through before it reaches the top-level.
const select = fn => state => fn(state.accountView.changePassword);

export const getFormProps = select(_getFormProps);
export const isSubmittingChangePassword = select(_isSubmittingChangePassword);
export const getResponseModal = select(_getResponseModal);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import makeCancelable from 'utility/makeCancelable';
import { api } from 'BoomTown';
import ListingModel from 'legacy/Model/listing';

import WidgetListings from 'components/WidgetListings';

class WidgetListingsContainer extends Component {
  static propTypes = {
    params: PropTypes.object,
    searchURL: PropTypes.string,
    title: PropTypes.string,
    hideWhileLoading: PropTypes.bool, // Will also hide if the results is zero
  };

  static defaultProps = {
    params: {},
    searchURL: '/results-gallery/',
    title: '',
    hideWhileLoading: true,
  };

  state = {
    listings: [],
    hasFetched: false,
  };

  componentWillUnmount() {
    if (this.fetchPromise) {
      this.fetchPromise.cancel();
    }
  }

  fetchListings = () => {
    if (this.fetchPromise) {
      return;
    }
    this.fetchPromise = makeCancelable(
      api.ajaxSearchPromise(this.props.params).then(res => res.Result.Items)
    );
    this.fetchPromise.promise
      .then(listings => {
        this.setState({
          listings: listings.map(l => new ListingModel(l).beefUp()),
          hasFetched: true,
        });
      })
      .catch(reason => {
        if (reason.isCanceled) {
          return;
        }
        throw reason;
      });
  };

  render() {
    const { listings, hasFetched } = this.state;
    const { hideWhileLoading } = this.props;

    // If the ajax comes back with no results we are going to hide it
    if (hideWhileLoading && hasFetched && !listings.length) {
      return null;
    }

    return (
      <WidgetListings
        {...this.props}
        isLoading={!hasFetched}
        onContentVisible={this.fetchListings}
        listings={listings.map(l => ({
          ...l,
          NoLazyLoad: true,
        }))}
      />
    );
  }
}

export default WidgetListingsContainer;

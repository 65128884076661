/* eslint-disable no-param-reassign */
import $ from 'jquery';
import { View } from 'backbone';
import { push } from 'redux-little-router';
import { signinFormData } from 'constants/registrationForms';

export default class HubView extends View {
  get events() {
    return {
      'click .js-savedsearcheslnk': 'savedSearches',
      'click .js-signout': 'signout',
      'click .js-signin': 'signin',
      'click .js-register': 'register',
      'click .js-account': 'accountNav',
      'click .js-account-off-canvas': 'offCanvasAccountNav',
    };
  }

  initialize(options) {
    // cache 'em since they're used in a few spots
    this.name = this.$('.js-visitorname');
    this.searches = this.$('.js-savedsearchcount');
    this.favorites = this.$('.js-favoritescount');
    this.searchcount = 0;
    this.favcount = 0;
    this.store = options.store;

    if (this.model.isRegistered()) {
      this.name.text(this.model.get('FirstName'));
      this.searchcount = this.model.get('ActiveSearchCount');
      this.favcount = this.model.get('FavoriteCount');
    }

    // when these change on the model, update the UI
    this.model.on('change:FavoriteCount', () =>
      this.favorites.text(`${this.model.get('FavoriteCount')}`)
    );
    this.model.on('change:ActiveSearchCount', () =>
      this.searches.text(`${this.model.get('ActiveSearchCount')}`)
    );
    this.model.on('change:FirstName', () => {
      this.name.text(this.model.get('FirstName'));
    });

    // Client side registration
    this.model.on('change:IsRegistered', () => {
      // Desktop sign in link
      $('.js-signin')
        .text('Sign Out')
        .removeClass('js-signin')
        .addClass('js-signout');

      $('.js-account-placeholder')
        .append(
          `<a class="bt-aux__link js-account" href="/account/">Hi, ${this.model.get(
            'FirstName'
          )}</a>`
        )
        .removeClass('uk-hidden js-account-placeholder');

      // Mobile Menu Header Swap
      window.bt.utility.hide($('.js-user-instance--guest'));
      window.bt.utility.show($('.js-user-instance--registered'));
    });

    super.initialize(options);
  }

  savedSearches(e) {
    e.preventDefault();
  }

  signout(e) {
    e.preventDefault();
    window.bt.visitor.signout();
  }

  signin(e) {
    e.preventDefault();
    $('#user-action-modal').on({
      'show.uk.modal': () => {
        $('.js-regform-nav')
          .data('switcher')
          .show(1);
      },
    });

    $('#user-action-modal').on({
      'hide.uk.modal': () => {
        $('.js-regform-nav')
          .data('switcher')
          .show(0);
      },
    });
    window.bt.app.squeezeForm(signinFormData.urlParam, true, true);
  }

  register(e) {
    e.preventDefault();
    window.bt.app.squeezeForm(signinFormData.urlParam, true, false);
  }

  accountNav(e) {
    e.preventDefault();
    this.store.dispatch(push('/account/'));
  }

  offCanvasAccountNav(e) {
    e.preventDefault();
    $('html').removeClass('bt-off-canvas--open');
    $('.js-off-canvas__trigger').removeClass('bt-active');
    this.store.dispatch(push('/account/'));
  }
}

import Listing from 'legacy/Model/listing';
import { utility, config } from 'BoomTown';
import { RESULTS_GALLERY, RESULTS_MAP } from 'routes';
import { getLastSearch } from 'reducers/lastSearch';
import { getMapListings } from 'screens/ResultsMap/reducer';
import { getListingFromStore } from './mobileDetails';
import { getGalleryListings } from './mobileGallery';

/**
 * Currently there are two kinds of selectors in this file. The first few are
 * more concerned with where we store entities (Listings) in Redux, and the
 * last few are more about how to we get the data that a component expects.
 */

/** ------------------- Store architecture-type stuff ----------------------- */

/**
 * Get the listing ID for the current URL
 *
 * @param {Object} state
 * @returns {number | null}
 */
export const getCurrentListingID = state => {
  const idString = state.router.params && state.router.params.listingID;
  if (!idString) {
    return null;
  }

  const parsed = parseInt(idString, 10);
  return isNaN(parsed) ? null : parsed;
};

/**
 * Use the lastSearchRoute from our store to retrieve an array of listings from either the gallery
 * or map state trees in our store.
 *
 * @param {Object} state
 * @param {number} listingID
 *
 * @returns {import('types/flagshipAPI').ListingSnapshot|undefined}
 */
function getListingFromLastSearch(state, listingID) {
  let listing;
  let listings;
  const { lastSearchRoute } = getLastSearch(state);

  if (lastSearchRoute === RESULTS_GALLERY) {
    listings = getGalleryListings(state);
    listing = listings.find(l => l._ID === listingID);
  } else if (lastSearchRoute === RESULTS_MAP) {
    listings = getMapListings(state);
    listing = listings.find(l => l._ID === listingID);
    // Listings in the map state tree need to be `beefUp()`d before they can be
    // rendered on the details page

    if (listing) {
      listing = new Listing(listing).beefUp();
    }
  }

  return listing;
}

/**
 * Get the listing with the ID given in the URL. If a listing snapshot is
 * available, it is returned, along with a property indicating what type was
 * returned.
 */
export function getActiveListing(state) {
  const listingFromStore = getListingFromStore(state);
  const listingID = getCurrentListingID(state);

  if (listingFromStore && listingFromStore._ID === parseInt(listingID, 10)) {
    return {
      listing: listingFromStore,
      isFullDetail: true,
    };
  }

  const listingFromSearch = getListingFromLastSearch(state, listingID);
  if (listingFromSearch) {
    return {
      listing: listingFromSearch,
      isFullDetail: false,
    };
  }

  return {
    listing: null,
    isFullDetail: false,
  };
}

/** --------------------- View-requirements-type stuff ---------------------- */

/**
 * Gets a simplified Address object with street, city, state, and zip
 *
 * @param {Object} loc a Location object from a listing
 * @returns {Object}
 */
export function getAddressObj(loc) {
  let street = `${loc.StreetNumber} ${loc.StreetDirPrefix} ${loc.StreetName}`;
  if (loc.StreetDirSuffix) {
    street += ` ${loc.StreetDirSuffix}`;
  }
  if (loc.UnitNumber) {
    street += `, ${loc.UnitNumber}`;
  }
  const city = (loc.CityDetail && loc.CityDetail.Name) || loc.City || null;
  const state = (loc.CityDetail && loc.CityDetail.State) || loc.State || null;
  const zip = loc.PostalCode;
  return {
    street,
    city,
    state,
    zip,
  };
}

/**
 * Get the first line of a multi-line address.
 *
 * @param {Listing}
 * @returns {String}
 */
export function getAddressLineOne({ Location: loc, HideAddress: hideAddress }) {
  const { street } = getAddressObj(loc);
  let title = '';
  if (hideAddress) {
    title = 'Address Not Available By Request';
  } else {
    title = street;
  }
  return title;
}

/**
 * Get the second line of a multi-line address
 *
 * @param {Listing}
 * @returns {String}
 */
export function getAddressLineTwo({ Location: loc }) {
  const { city, state, zip } = getAddressObj(loc);
  return `${city}, ${state} ${zip}`;
}

/**
 * Extract the state needed by our Mobile XP listing card component.
 *
 * @param {ListingDetail} listing A `beefUp()`ed ListingDetail
 * @param {number} width The width of the window in pixels
 */
export function getXPCardProps(listing) {
  const price = getPrice(listing);

  return {
    acres: listing.AcreageText,
    address: `${listing.Location.StreetNumber} ${listing.Location.StreetName} ${
      listing.Location.CityDetail.Name
    }, ${listing.Location.State} ${listing.Location.PostalCode}`,
    agentPhone: listing.AgentPhone,
    baths: listing.FullBaths,
    beds: listing.Bedrooms,
    city: listing.Location.CityDetail.Name,
    daysOnSite: listing.OnSite,
    displayBtAsDataSource: listing.DisplayBtAsDataSource,
    distance: listing.Distance,
    disableAcreage: listing.DisableAcreage,
    onMarketDate: listing.OnMarketDateFormatted,
    featureText: listing.FeatureText,
    hideListingCardFavoriteBtn: listing.hideListingCardFavoriteBtn,
    hideListingCardNeighborhood: listing.hideListingCardNeighborhood,
    halfBaths: listing.HalfBaths,
    hideHalfBaths: listing.hideHalfBaths,
    homePageAndMapDisclaimer: listing.HomePageAndMapDisclaimer,
    id: listing._ID,
    index: listing.Index,
    isFavorite: listing.IsFavorite,
    isMultiBoard: listing.IsMultiBoard,
    latitude: listing.Location.Coordinates.Latitude,
    listingTopHtml: listing.ListingTopHtml,
    longitude: listing.Location.Coordinates.Longitude,
    multiMLSDisclaimer: listing.MultiMLSThumbcardDisclaimer,
    neighborhood: listing.Location.Neighborhood.Name,
    numUnits: listing.NumUnits,
    officeName: listing.OfficeName,
    photos: listing.Photos.map(l => l.LargeUrl),
    postalCode: listing.Location.PostalCode,
    price,
    propertyTypeSchema: listing.PropertyType.Schema,
    propertyTypeID: listing.PropertyType._ID,
    sashText: listing.SashText,
    sashType: listing.SashType,
    showDaysOld: listing.ShowDaysOld,
    showPriceType: listing.showPriceTypeOnListing,
    sqft: listing.ApproxSqFtText,
    soldThumbCardDisclaimer: listing.SoldThumbCardDisclaimer,
    statusCode: listing.StatusCode,
    thumbCardDisclaimer: listing.ThumbCardDisclaimer,
    url: listing.Url,
    urlPath: listing.UrlPath,
  };
}

/**
 * @param {ListingSnapshot|ListingDetail} listing
 * @returns {{{ min: number, max: number } | number | undefined}
 */
export function getPrice(listing) {
  const isSold = listing.StatusCode === 'S';
  if (isSold) {
    // CNS-5212: This value can be undefined for certain boards
    return listing.SoldPrice;
  }

  const hasMinMax = listing.PriceTypeID === 1;
  if (hasMinMax) {
    return { min: listing.MinPrice, max: listing.MaxPrice };
  }

  // Sometimes we don't have this... if you know when leave a note
  if (listing.DisplayPrice) {
    return listing.DisplayPrice;
  }

  if (!listing.ListPrice) {
    utility.graylog('warn', {
      label: `No appropriate display price available (lid: ${listing._ID})`,
    });
  }

  return listing.ListPrice;
}


/**
 * Expecting the redux copy, or a BB.toJSON() copy of the listing
 * @param {object} listing
 */
export function showStartAnOffer(listing) {
  return config.showStartAnOffer && listing && ['A', 'AC'].includes(listing.StatusCode);
}

import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { config } from 'BoomTown';
import rootReducer from 'reducers';

import { openModal as openQualifyingQuestionsModal } from 'actions/QualifyingQuestionsActions';
import bodyClass from './middleware/bodyClass';
import compare from './middleware/compare';
import documentTitle from './middleware/documentTitle';
import favorites from './middleware/favorites';
import legacyMiddleware from './middleware/legacyMiddleware';
import modals from './middleware/modals';

import { middleware as staticHtmlCacheMiddleware } from '../htmlCache';
import { routerComponents } from '../getRouterComponents';
import { middleware as scrollPosition } from './middleware/scrollPosition';
import convertBBEventsToActions from './convertBBEventsToActions';
import initActors from './initActors';
import initOptimize from './initOptimize';
import actors, { mapSyncActors } from './actors';

/** @see https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const { enhancer: routerEnhancer, middleware: routerMiddleware } = routerComponents;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeEnhancers(
    routerEnhancer,
    applyMiddleware(
      // ---- order matters, tread lightly ----
      // Core
      thunk,
      scrollPosition,
      routerMiddleware,
      sagaMiddleware,
      staticHtmlCacheMiddleware,
      documentTitle,

      // Legacy
      legacyMiddleware,
      bodyClass,
      modals,

      // Screens
      favorites,
      compare,
    )
  )
);

// Init methods
convertBBEventsToActions(store);
initActors(store, actors);

// Only intiialize lastMapBounds and lastMapZoom cookie subscriptions if the feature is active.
if (config.useListMapResultsSync) {
  initActors(store, mapSyncActors);
}

// Expose the `getState()` function so that external scripts can read app state
// if they need to. (**But don't you dare mutate anything!**)
window.bt.getState = store.getState;

initOptimize(store);

// I believe this can be removed. Seems to be an artifact from the initial CRO test for Qualifying
// Questions but just never got removed.
window.bt.qualifyingQuestions = () => store.dispatch(openQualifyingQuestionsModal());

export default store;
export const runSaga = sagaMiddleware.run;

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Cell } from 'components/core/Grid';
import AccountMenu from './AccountMenu';

const DesktopAccountLayout = ({ children, rightSlot }) => (
  <section className="bt-content-wrapper px--safe">
    <div className="container">
      <h1 className="sr-only">Account Page</h1>
      <Grid gutters>
        <Cell md={4} lg={3}>
          <AccountMenu />
        </Cell>
        <Cell sm="fit">{children}</Cell>
        {rightSlot && (
          <Cell md={4} lg={3}>
            {rightSlot}
          </Cell>
        )}
      </Grid>
    </div>
  </section>
);

DesktopAccountLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.func,
  ]).isRequired,
  rightSlot: PropTypes.element,
};

export default DesktopAccountLayout;

import { LOCATION_CHANGED } from 'redux-little-router';
import { receiveHtml } from 'htmlCache/reducer';
import * as routes from 'routes';

// This is bound pretty tightly to /wp-base-theme/inc/dom.php
/** @type {Array<ServerRenderedHTML>} */
const reusedHTML = [
  {
    id: routes.HOME,
    selector: determineHomePageSelector(),
    htmlRequired: () => true,
  },
  {
    id: routes.DETAILS,
    selector: '#js-search-layout--main',
    htmlRequired: () => false,
  },
  {
    id: routes.RESULTS_GALLERY,
    selector: '#js-search-layout--main',
    htmlRequired: () => false,
  },
];

/**
 * @todo After the addition of the UIkit modal side-effects, this middleware
 * is no longer just for caching of server-rendered pages. It needs to be
 * refactored to be a central place for _all_ routing related side effects.
 * (Hopefully in the end there won't be much need for them)
 *
 * @description This middleware handles forcing a reload of the page before a
 * route change is propagated to the app whenever it cannot be rendered on the
 * client. This also dispatches an action containing the #inner-viewport HTML
 * rendered by the server, on the initial LOCATION_CHANGED action, for the home
 * page only. (We could do this for all routes whose markup should remain static
 * for at least the time a user is in the app, but the home page is all we do it
 * for now.)
 */
export default function staticHtmlCacheMiddleware({ dispatch, getState }) {
  /**
   * This middleware's behavior for the first LOCATION_CHANGED action. If it
   * is for the home route, dispatch an action containing the
   * #inner-viewport's DOM.
   * @param {Action} action
   */
  function onInitialLocationChanged(action) {
    reusedHTML.forEach(x => {
      if (action.payload.result && action.payload.result.id === x.id) {
        const element = document.querySelector(x.selector);
        if (element) {
          dispatch(receiveHtml(x.id, element.innerHTML));
        }
      }
    });

    // CatchAll - Absence of a result is a miss
    if (!action.payload.result) {
      const el = document.getElementById('js-search-layout--main');
      if (el) dispatch(receiveHtml('catchAll', el.innerHTML));
    }
  }

  /**
   * Reload the current location and return false if this route cannot be
   * rendered on the client.
   * @param {Action} action
   * @returns {boolean} Whether or not to pass the action on to the next middleware
   */
  function onSubsequentLocationChanged(action) {
    const state = getState();
    const cache = state.cachedHtml;
    const result = action.payload.result || {};
    const id = result.id || '';

    // We need to reload the page if there is no route registered for this URL,
    // or there is at least one reused element that is required for its route,
    // and we don't have it in the cache.

    // CNS-4615 - the first part of this if statement addresses and issue where
    // client-added anchor links were refreshing the page. We added a check to see if
    // the pathname is the same as the updated path name (i.e. not including hash)
    // and if so, we do not reload the page
    if (
      (!action.payload.result && action.payload.pathname !== state.router.pathname) ||
      reusedHTML
        .filter(x => x.id === id)
        .filter(x => x.htmlRequired(state))
        .filter(x => !cache[x.id]).length
    ) {
      window.location.reload();
      return false;
    }

    return true;
  }

  return next => action => {
    if (action.type === LOCATION_CHANGED) {
      if (getState().locationChanges === 0) {
        onInitialLocationChanged(action);
        next(action);
      } else if (onSubsequentLocationChanged(action)) {
        next(action);
      }
    } else {
      next(action);
    }
  };
}

/**
 * Use the `#inner-viewport` selector if the route is a preview
 * @returns {string} selector
 */
function determineHomePageSelector() {
  return window.location.toString().includes('&preview=true') ? '#inner-viewport' : '#page-home';
}

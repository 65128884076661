/* eslint-disable arrow-body-style */
import $ from 'jquery';
import _ from 'underscore';

import * as NameParse from 'utility/parse-names';
import * as routerUtils from 'legacy/Routing/routerUtils';
import BaseModalView from 'legacy/Base/view.modal';
import bt from 'BoomTown';
import { getCurrentListingID } from 'selectors/listings';

import template from 'templates/modals/completeRegister/steptwobasic.hbs';
import completeRegisterTemplate from 'templates/modals/completeRegister/base.hbs';

/**
 * This is the second step of the registration process.
 * - email is passed in, rendered to the dom, and read from the dom
 */
export default class CompleteRegisterView extends BaseModalView {
  get id() {
    return 'complete-register-modal';
  }

  get events() {
    return {
      'click .js-updatePhone-button': 'register',
      'click .js-modal-register__back': 'fireStepOne',
    };
  }

  // NOTE: this is not a Backbone.Model, so this defaults prop is just a prop.
  defaults = {
    isFacebookRegistration: false,
    isGoogleRegistration: false,
    inProgress: false,
  };

  initialize() {
    this.template = template;
    this.data = $.extend(bt.visitorDetails, {
      showLenderCheckbox: bt.config.overrideHideLender,
      lenderChecked: bt.tenant.lenderChecked,
      showAgents: bt.tenant.showAgentDDL === '1' && !bt.agent,
      /**
       * CNS-5480: Added `tenantInBC` flag to support implementation
       * of new disclosures in British Columbia
       */
      tenantInBC: bt.tenant.state === 'BC',
      contactFormDisclaimer: false,
    });

    // Set State
    try {
      if (window.bt.config.disclaimer) {
        this.data.contactFormDisclaimer = window.bt.config.disclaimer;
      }
    } catch (e) {
      this.data.contactFormDisclaimer = false;
    }

    super.initialize();

    if (!bt.tenant.isCanadian) {
      this.data.isTwoStep = true;
    }

    if (this.id === 'complete-register-modal') {
      $(`#${this.id}`)
        .find('.uk-modal-dialog')
        .addClass('bt-squeeze');
      this.delegateEvents();
      this.listenTo(bt.events, 'completeRegModal', (params = {}) => {
        // We are passed the state of the first step, so it is known what to
        // pass when navigating back to step one
        this.stepOneShowCloseBtn = Boolean(params.stepOneShowCloseBtn);

        // calls fire!
        this.isEmailUser();
      });

      this.listenTo(bt.events, 'isFacebookUserModal', this.isFacebookUser);
      this.listenTo(bt.events, 'isGoogleUserModal', this.isGoogleRegistration);
    }
  }

  reloadTemplate() {
    this.template = template;
    this.reload();
    this.bindAgentDropdown();
  }

  /**
   * @return {void}
   */
  isEmailUser() {
    this.defaults.isFacebookRegistration = false;
    this.reloadTemplate();
    this.fire();

    $('.js-regform__full-name').focus();
    bt.events.trigger('completeRegModalHook');
  }

  isFacebookUser() {
    this.defaults.isFacebookRegistration = true;
    this._setSocialRegistration();
  }

  isGoogleRegistration() {
    this.defaults.isGoogleRegistration = true;
    this._setSocialRegistration();
  }

  _setSocialRegistration() {
    this.template = completeRegisterTemplate;
    this.reload();
    this.bindAgentDropdown();
    this.fire();

    $('.js-fb-phone').focus();
    bt.events.trigger('completeRegModalHook');
  }

  fireStepOne(error = false) {
    const firststep = $(`#${this.id}`).data('firststep');
    const showClose = this.stepOneShowCloseBtn != null ? this.stepOneShowCloseBtn : true;
    if (firststep) {
      bt.app.squeezeForm(firststep, showClose, false);
      if (error) {
        this.stepOneIntervalRegister();
      }
    }
  }

  stepOneIntervalRegister() {
    let attempts = 0;
    let interval = null;
    interval = setInterval(() => {
      const element = document.querySelector('.js-user-register');
      if (element) {
        element.click();
        clearInterval(interval);
      } else {
        attempts += 1;
        if (attempts > 10) {
          clearInterval(interval);
        }
      }
    }, 50);
  }

  bindAgentDropdown() {
    if (bt.tenant.showAgentDDL === '1') {
      const agentsObj = {};
      const agentDll = $('.preferred-agent');

      bt.utility.jsonp(`${bt.config.apiUrl}/lc/1/agents/`, (agents) => {
        // filter to only ShowOnSite agents
        agentsObj.Agents = _.filter(agents.Result, (agent) => {
          return agent.ShowOnSite;
        });

        $.each(agentsObj.Agents, (index, value) => {
          return $('<option />', {
            value: value._ID,
            text: `${value.FirstName} ${value.LastName}`,
          }).appendTo(agentDll);
        });

        agentsObj.showAgents = true;
      });
    }
  }

  register(e) {
    let formdata;
    $('.js-updatePhone-button').attr('disabled', 'true');

    const form = $(e.currentTarget).parents('form');
    const parent = this.$('.js-complete-register-form');

    this.model.set({
      FirstName: '',
      LastName: '',
      Email: '',
      Phone: '',
      Password: '',
    });

    if (this.defaults.isFacebookRegistration || this.defaults.isGoogleRegistration) {
      formdata = {
        FirstName: form.find('input[name=first_name]').val(),
        LastName: form.find('input[name=last_name]').val(),
        Email: form.find('input[name=email]').val(),
        Phone: form.find('.js-fb-phone').val(),
        Password: form.find('.js-fb-phone').val(),
        MortgageOK: false,
      };

      // app.js:239
      const fbuserID = this.$el.data('fbuserid');
      if (fbuserID) {
        formdata.FBUserID = fbuserID;
        formdata.FormVersion = 'Facebook Phone Valid';
        formdata.ActionID = 54;
      }

      if (this.defaults.isGoogleRegistration) {
        formdata.FormVersion = 'Google Signup';
        formdata.ActionID = bt.utility.visitorActions.actions.GoogleRegistrationComplete;
      }
    } else {
      let firstName;
      let lastName;
      const fullName = parent.find('input[name="fullname"]').val();

      // skip NameParse if single char, Parsley will detect single char.
      if (fullName.trim().length > 1) {
        const tempName = NameParse.parse(fullName);
        ({ firstName } = tempName);
        ({ lastName } = tempName);

        /**
         * edge case, when a visitor enters 1 char into the firstname,
         * we need to swap initial <=> firstname
         */
        if (firstName === '' && tempName.initials) {
          firstName = tempName.initials;
        }
      }

      formdata = {
        FirstName: firstName,
        LastName: lastName,
        Email: form.find('input[name="email"]').val(),
        Phone: form.find('input[name="phone"]').val(),
        Password: form.find('input[name="phone"]').val(),
        FormVersion: 'Phone Valid', // we should probably change it
        ActionID: 52,
      };
    }

    // TODO: First part of this conditional left over from when we were
    // unnecessarily passing `window.bt.agent` in the request, just to be
    // absolutely sure of no change in behavior. This is handled by the server.
    if (!bt.agent && bt.tenant.showAgentDDL === '1') {
      formdata.Agent = form.find('.preferred-agent').val();
    }

    // auto assign lender for FB leads
    if (bt.tenant.lenderChecked) {
      formdata.MortgageOK = true;
    }

    if (routerUtils.isDetailsPath()) {
      // eslint-disable-next-line
      const state = require('store').default.getState();
      formdata.ListingID = getCurrentListingID(state);
    }

    const success = (data, status, xhr) => {
      // BTGA4: CNS-8653
      if (typeof window.BoomTownGA4 !== 'undefined') {
        window.BoomTownGA4.eventFormLogin(true);
      }

      // CNS-1284: Seller doesn't simply reload, so check for the postRegAction hook
      if (typeof (bt.register != null ? bt.register.postRegAction : undefined) === 'function') {
        bt.register.postRegAction.call(bt.register, data, status, xhr);
        return;
      }

      bt.events.trigger('count', 'Successfully registered Facebook user');

      let formtype = 'email';
      if (this.defaults.isFacebookRegistration) {
        formtype = 'facebook';
      }

      if (this.defaults.isGoogleRegistration) {
        bt.utility.visitorActions.write(bt.utility.visitorActions.actions.GoogleSuccess);
      }

      // GA global tracking
      const trackingParams = {
        form: $('#complete-register-modal').data('urlparam'),
        contacttype: bt.visitor.updateContactType('lead'),
        formtype,
        step: null,
      };

      // GTM support
      if (window.dataLayer) {
        trackingParams.event = 'registration-form-completed';
        window.dataLayer.push(trackingParams);
      }

      if (bt.tenant.doubleOptIn) {
        this.done(parent);
      } else {
        const conversionCookie =
          bt.config.conversionCookieName != null
            ? bt.config.conversionCookieName
            : 'ConversionType';
        bt.events.trigger('count', conversionCookie);

        // TODO: Convert to using new `cleanQueryString()` util fn
        let queryString = bt.utility.parseQueryString(location.search);

        // remove regformdisplay and any social login params after registering
        queryString = _.omit(queryString, [
          bt.config.registrationQueryStringName,
          ...bt.search.socialLoginParams,
        ]);

        // add custom url params depending on the form
        const urlParam = $('#user-action-modal').data('urlparam');
        const conversionParam = bt.config.conversionQueryStringName;
        if (urlParam) {
          queryString[conversionParam] = urlParam;
        }

        // Send them to the new url with the conversion param
        location.search = `?${bt.utility.createQueryString(queryString)}`;
      }
    };

    // eslint-disable-next-line
    const error = (xhr, status, error) => {

      // CNS-8475 Move Phone Numbers to Backend Validation
      if (error && error.responseText === 'INVALID_PHONE') {
        $('#steptwobasic-backend-phone-error').show();
        this.done(parent);
        return;
      }

      this.done(parent);
      this.fireStepOne(true);

      if (!error || typeof error !== 'string' || error === '') {
        error = 'Something went wrong. Please contact support.';
      }

      setTimeout(() => {
        return this.message($('.js-register-form'), error, 'error');
      }, 500);
      $('.js-updatePhone-button').removeAttr('disabled');
    };

    if (bt.utility.validateParsley(parent[0])) {
      this.working(parent, 'Registering...');
      if (this.defaults.isFacebookRegistration) {
        bt.events.trigger('count', 'Register Facebook User');
      } else {
        bt.events.trigger('count', 'Successfully registered user');
      }
      if (!this.defaults.inProgress) {
        this.model.register(formdata, success, error);
        this.defaults.inProgress = true;
      }
    } else {
      $('.js-updatePhone-button').removeAttr('disabled');
    }
  }

  /**
   * @deprecated [aknox] looks like it
   */
  clearErrors() {
    return this.$('.parsley-errors-list').removeClass('filled');
  }

  /**
   * Renders an alert message to the form
   *
   * @param {Object} parent jQuery Element Object
   * @param {string} text The text to render in the alert message
   * @param {'success'|'warning'|'danger'} type The type of alert to render
   */
  message(parent, text, type) {
    const block = parent.find('.js-alert-messages-container');
    if (block.length === 0) {
      return;
    }
    block
      .removeClass('uk-hidden alert-error alert-success alert-warning')
      .addClass(`alert-${type}`)
      .html(text);
  }
  /* eslint-enable no-unused-vars */

  working(parent, text) {
    const button = $('.js-updatePhone-button');
    const original = button.text();
    button
      .attr('disabled', 'disabled')
      .data('original', original)
      .html(text);
  }

  done(parent, text) {
    this.defaults.inProgress = false;
    const button = $('.js-updatePhone-button');
    const original = button.data('original');
    /* eslint-disable no-param-reassign */
    if (!text) {
      text = original;
    }
    /* eslint-enable no-param-reassign */
    return button
      .removeAttr('disabled')
      .removeData('original')
      .html(text);
  }
}

/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'redux-little-router';
import {
  pick,
  omit,
  mapObject,
  isEmpty,
} from 'underscore';
import {
  config,
  search as searchModel,
  utility,
} from 'BoomTown';
import {
  Fieldset,
  FieldsetLegend,
  Radio,
  SecondaryButton,
  Select,
  TextButton,
} from 'coreComponents';
import createForm from 'hoc/createForm';
import addCommas from 'utility/addCommas';
import Pill from 'components/core/Pill';
import { Grid, Cell } from 'components/core/Grid';
import SettingsCard from 'screens/Account/SettingsCard';
import FAIcon from 'components/core/FAIcon';
import { SAVED_SEARCH_PAGE } from 'cypress_constants';

@createForm([
  {
    name: 'frequency',
    type: 'manual',
    initialState: (props) => props.frequency,
  },
])

class SaveSearchItem extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
    };
  }

  frequencyChange = (data) => {
    const request = {
      searchid: this.props.searchid,
      frequency: data.frequency,
    };
    this.props.onChange(request);
  };

  isOpen = () => {
    if (this.props.isCollapsible) {
      return this.state.isOpen ? 'block' : 'none';
    }
    return 'block';
  };

  /**
   * Prep the object to be passed as the payload for the action assigned to the onLinkClick prop
   *
   * @return {{ title: string, querystring: string, pathname: string, mapBounds: Object|null, mapZoom: number, searchQuery: Object }}
   */
  getLinkData = () => {
    const { querystring, searchName } = this.props;
    // Parse the search query to a search obj so we can better check for coordinate filters
    const searchObj = utility.parseQueryString(querystring);
    let linkData = {
      title: searchName,
      searchQuery: searchObj,
      pathname: '/results-gallery/',
    };

    if (!config.useListMapResultsSync) {
      return linkData;
    }

    // Separate main search and mapBounds into separate objs.
    // We want zoom separate so it doesn't get included in the bounds.
    const searchQuery = omit(searchObj, searchModel.coordinateFilters);
    const { zoom, ...mapBounds } = mapObject(pick(searchObj, searchModel.coordinateFilters),
      (param) => parseFloat(param));

    const hasCoords = !isEmpty(mapBounds);
    linkData = {
      ...linkData,
      searchQuery,
      mapBounds: hasCoords ? mapBounds : null,
      mapZoom: zoom,

      // It may not be abundantly clear to the user that map boundaries are included in a
      // saved search on the Edit Saved Searches view, so if the saved search has map coords,
      // then redirect the user to the map view.
      pathname: hasCoords ? '/results-map/' : linkData.pathname,
    };

    return linkData;
  };

  /**
   * Only called if inside the Mobile App or if the useListMapResultsSync flag is enabled
   *
   * @param {Event} e
   */
  handleLinkClick = (e) => {
    e.preventDefault();
    this.props.onLinkClick(this.getLinkData());
  };

  /**
   * Determines whether to render a Link component from `redux-little-router` or a simple anchor tag
   * with an `onClick` handler for the Native App.
   */
  renderLink = () => {
    const { querystring, searchName } = this.props;

    if (config.isMobileApp || config.useListMapResultsSync) {
      return (
        <a href="#" onClick={this.handleLinkClick}>
          {searchName}
        </a>
      );
    }

    return <Link href={`/results-gallery/?${querystring}`}>{searchName}</Link>;
  };

  render() {
    const {
      searchid,
      count,
      isDeleting,
      formHandlers,
      isCollapsible,
      className,
    } = this.props;

    const { frequency } = this.props.formData;
    // Used to determine status of Subscription Radios as well as toggle some UI state
    const isSubscribed = frequency !== 0;

    // The final option is not viewable nor selectable when subscribed and
    // only shows up when Unsubscribed
    const frequencyOptions = [
      { label: 'Instantly', value: -1 },
      { label: 'Daily', value: 1 },
      { label: 'Weekly', value: 7 },
      { label: '--', value: 0, hidden: isSubscribed },
    ];

    return (
      <SettingsCard muted={isDeleting} className={className}>
        <Grid gutters>
          <Cell>
            <div className="mb-0 bt-heading__h4" data-cy={SAVED_SEARCH_PAGE.TITLE}>
              {this.renderLink()}
            </div>
          </Cell>
          {isCollapsible && (
            <Cell noGrow>
              <TextButton
                onClick={() => this.setState((s) => ({ isOpen: !s.isOpen }))}
                className="px-0"
                width="auto"
                data-cy={SAVED_SEARCH_PAGE.EDIT_SAVED_SEARCH}
              >
                <FAIcon icon="edit" type="solid" />
              </TextButton>
            </Cell>
          )}
        </Grid>

        <Grid>
          <Cell className="uk-text-small uk-text-muted">
            {`${count !== undefined ? addCommas(count) : '~'} Properties`}
          </Cell>
          {!isSubscribed && (
            <Cell noGrow>
              <Pill disabled dataCY={SAVED_SEARCH_PAGE.UNSUBSCRIBED_BADGE}>
                Unsubscribed
              </Pill>
            </Cell>
          )}
        </Grid>
        <form style={{ display: this.isOpen() }}>
          <Grid gutters justifyContent="center" xs="full" className="mt-12">
            <Cell>
              <Select
                id={`${searchid}-frequency`}
                label="Frequency"
                value={frequency}
                options={frequencyOptions}
                dataCY={SAVED_SEARCH_PAGE.FREQUENCY_MENU}
                onChange={(e) => {
                  formHandlers.frequency(parseInt(e.target.value, 10), this.frequencyChange);
                }}
                disabled={!isSubscribed}
              />
            </Cell>

            <Cell>
              <Fieldset className="mb-4">
                <FieldsetLegend className="mb-12">New Listing e-Alerts</FieldsetLegend>
                <Grid gutters xs="full">
                  <Cell>
                    <Radio
                      id={`${searchid}-sub`}
                      label="Subscribed"
                      checked={isSubscribed}
                      name={searchid}
                      dataCY={SAVED_SEARCH_PAGE.SUBSCRIBE_EALERT_RADIO_BUTTON}
                      labelProps={{ 'data-cy': SAVED_SEARCH_PAGE.SUBSCRIBE_EALERT_RADIO_BUTTON_LABEL }}
                      onChange={() => formHandlers.frequency(1, this.frequencyChange)}
                    />
                  </Cell>
                  <Cell>
                    <Radio
                      id={`${searchid}-unsub`}
                      label="Unsubscribed"
                      checked={!isSubscribed}
                      name={searchid}
                      dataCY={SAVED_SEARCH_PAGE.UNSUBSCRIBE_EALERT_RADIO_BUTTON}
                      labelProps={{ 'data-cy': SAVED_SEARCH_PAGE.UNSUBSCRIBE_EALERT_RADIO_BUTTON_LABEL }}
                      onChange={() => formHandlers.frequency(0, this.frequencyChange)}
                    />
                  </Cell>
                </Grid>
              </Fieldset>
            </Cell>
            <Cell xs={10} className="py-16">
              <SecondaryButton
                className="at-delete-saved-search"
                width="full"
                size="small"
                onClick={() => this.props.onDelete({ searchid })}
                dataCY={SAVED_SEARCH_PAGE.DELETE_SAVED_SEARCH}
              >
                Delete Saved Search
              </SecondaryButton>
            </Cell>
          </Grid>
        </form>
      </SettingsCard>
    );
  }
}

SaveSearchItem.propTypes = {
  // Search details
  searchid: PropTypes.number.isRequired,
  searchName: PropTypes.string.isRequired,
  frequency: PropTypes.number,
  querystring: PropTypes.string.isRequired,
  count: PropTypes.number,

  // More state
  isDeleting: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  className: PropTypes.string,

  // Actions
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onLinkClick: PropTypes.func,

  // From hoc/createForm
  formData: PropTypes.shape({
    frequency: PropTypes.number,
  }).isRequired,
  formHandlers: PropTypes.shape({
    frequency: PropTypes.func.isRequired,
  }).isRequired,
};

SaveSearchItem.defaultProps = {
  frequency: 0,
  count: 0,
  isDeleting: false,
  isCollapsible: false,
  className: null,
  onLinkClick: () => {},
};

export default SaveSearchItem;

import PropTypes from 'prop-types';
import React from 'react';
import { PrimaryButton } from 'coreComponents';
import FAIcon from 'components/core/FAIcon';
import { SEARCH_BAR } from 'cypress_constants';
import cx from 'classnames';

/**
 * The dropdown trigger for the More/Less menu on the far right of the SearchMenu.
 *
 * REFACTOR:
 * - DropdownTrigger has a bug where if you want the isOpen state you have to have an onClick handler
 * - js-search-more is for dead gaEvents
 *
 * @param {Object} props
 */
export default function MoreLessTrigger(props) {
  const {
    className,
    isOpen,
    onClick,
  } = props;

  return (
    <PrimaryButton
      onClick={onClick}
      className={cx('bt-filter__button bt-more__trigger', className)}
      dataCY={SEARCH_BAR.MORE}
    >
      More <FAIcon icon={isOpen ? 'minus' : 'plus'} />
    </PrimaryButton>
  );
}

MoreLessTrigger.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

MoreLessTrigger.defaultProps = {
  className: '',
  isOpen: false,
  onClick() {
  }
};

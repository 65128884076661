import * as r from 'routes';
/**
 * Determine if a given URL pathname corresponds to one of our results
 * routes.
 * @param {string} path
 */
export function isResultsPath(path) { // eslint-disable-line
  return ['results', 'results-map', 'results-gallery'].includes(cleanPath(path));
}

/**
 * Determine if a given URL pathname corresponds to the `/filter-menu` route function
 *
 * @export function
 * @param {string} path
 * @returns boolean
 */
export function isFilterMenuPath(path) {
  return cleanPath(path) === r.FILTER_MENU;
}

/**
 * Determine if a given URL pathname corresponds to our results-map route
 * @param {string} path
 */
export function isMapPath(path) {
  return cleanPath(path) === 'results-map';
}

/**
 * Trim leading and trailing slashes from a given URL pathname
 * @param {string} pathname
 */
export function cleanPath(pathname = window.location.pathname) {
  return String(pathname).replace(/^\//, '').replace(/\/$/, '');
}

export function isDetailsPath(path) {
  const cleaned = cleanPath(path);
  return ['listing', 'homes'].filter(p => cleaned.startsWith(p)).length > 0;
}

/** @type {Actor[]} */
const actors = [];

/**
 * A subscription to a Redux store that is only called with derived state when
 * it changes.
 *
 * @todo Unit tests!!!
 */
class Actor {
  constructor(store, selector, actor, comparer) {
    this.store = store;
    this.selector = selector;
    this.actor = actor;
    this.state = selector(store.getState());
    this.compareStates = typeof comparer === 'function' ? comparer : () => false;
    store.subscribe(this.onStoreChange);
  }

  onStoreChange = () => {
    const nextState = this.selector(this.store.getState());
    if (!this.compareStates(this.state, nextState)) {
      this.state = nextState;
      this.actor(this.state, this.store.dispatch);
    }
  };
}

// Currently not allowing for these to be unsubscribed, but it makes sense to
// surface that capability.

export default function defineActor(store, selector, actor, comparer) {
  actors.push(new Actor(store, selector, actor, comparer));
}

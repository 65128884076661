import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { emailPreferences } from 'models/visitor/reducers/selectors';
import { MobileScreen, CloseButton } from 'components/Common/MobileScreen';
import { MOBILE_EMAIL_PREFS } from 'cypress_constants';
import { PrimaryButton } from 'coreComponents';
import { isSubmittingEmailPrefs, getEmailPrefsFormState } from '../../Forms/EditEmailPrefs/reducer';
import * as emailPrefsActions from '../../Forms/EditEmailPrefs/actions';
import ConnectedEditEmailPrefs from '../../Forms/EditEmailPrefs';

const MobileEmailPreferences = ({ onClickClose, subscriptions, isSubmitting = false }) => (
  <MobileScreen
    title="Email Preferences"
    dataCY={MOBILE_EMAIL_PREFS.SCREEN_HEADER}
    headerLeft={<CloseButton onClose={onClickClose} />}
    footer={
      <PrimaryButton
        type="submit"
        form="email-prefs__form"
        className="at-email-prefs-submit-btn"
        width="full"
        disabled={subscriptions === undefined}
        showSpinner={isSubmitting}
      >
        Update Email Preferences
      </PrimaryButton>
    }
  >
    <ConnectedEditEmailPrefs isMobileScreen />
  </MobileScreen>
);

MobileEmailPreferences.propTypes = {
  onClickClose: PropTypes.func,
  isSubmitting: PropTypes.bool,
  subscriptions: PropTypes.shape({
    agent: PropTypes.bool.isRequired,
    lender: PropTypes.bool.isRequired,
    eAlerts: PropTypes.bool.isRequired,
  }), // or undefined if not yet loaded
};

/**
 * Select the current state of the form.
 * - The user's saved preferences can not be loaded yet
 * - The user could have not yet interacted with the form
 * - Our default state is the empty object here
 * @returns {undefined | { agent: boolean, lender: boolean, eAlerts: boolean }}
 */
const selector = state => {
  const saved = emailPreferences(state);

  // Not yet loaded
  if (saved === undefined) {
    return undefined;
  }

  // Allow form state (dynamic) to shadow saved
  return {
    agent: !saved.optOutAgent,
    lender: !saved.optOutLender,
    eAlerts: !saved.optOutEAlerts,
    ...getEmailPrefsFormState(state),
  };
};

export default connect(
  state => ({
    subscriptions: selector(state),
    isSubmitting: isSubmittingEmailPrefs(state),
  }),
  { onClickClose: emailPrefsActions.clickCloseEmailPrefs }
)(MobileEmailPreferences);

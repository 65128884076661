import React from 'react';
import IsMobile from 'components/IsMobile';

import MobileAccount from './Mobile';
import DesktopAccount from './Desktop';

export default () => (
  <IsMobile>
    {isMobile => (isMobile ? <MobileAccount /> : <DesktopAccount />)}
  </IsMobile>
);


export function getTitle(listings, search) {
  return createTitle(listings, search, getCount(listings));
}

export function getTitleWithCount(listings, search) {
  const count = getCount(listings);
  return count > 0 ? `${count.toLocaleString('en-US')} ${createTitle(listings, search, count)}` : '';
}

/** * @returns {number} */
function getCount(listings) {
  return listings.TotalItems || listings.length;
}

/**
 * Create a results title string using state on the listings and search.
 *
 * @param {Collection} listings
 * @param {Model} search
 * @param {number} count
 * @returns {string}
 */
export function createTitle(listings, search, count) {
  const propertyText = pluralProperty(count);
  if (search.has('favs')) {
    return `Favorite ${propertyText.toLowerCase()}`;
  }

  if (search.has('latlonrad')) {
    return `${propertyText.toLowerCase()} Nearby`;
  }

  let title = `${propertyText} for Sale`;
  if (count !== 1 && listings.Meta && listings.Meta.H1Title) {
    title = listings.Meta.H1Title;
  }

  // On e-alerts we don't show "for sale" because the status doesn't necessarily reflect that
  if (search.has('rid')) {
    title = title.replace(' for Sale', '');
  }

  // Check the statuses array for the Sold status and replace 'for sale' with nothing if present
  if (search.get('status')) {
    const statuses = search.get('status');
    if (statuses.includes('S') || statuses.includes('P')) {
      title = title.replace(' for Sale', '');
    }
  }

  // CNS-1124: If we are using a hoodlike or citylike query, append the value to the end of the title
  if (search.has('hoodlike')) {
    title = `${title} in ${search.get('hoodlike')}`;
  } else if (search.has('citylike')) {
    title = `${title} in ${search.get('citylike')}`;
  }

  return title;

  /**
   * @param {number} c
   * @return {string}
   */
  function pluralProperty(c) {
    return c === 1 ? 'Property' : 'Properties';
  }
}

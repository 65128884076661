import { connect } from 'react-redux';
import { getSpecialRules } from 'models/specialRules/reducers/selectors';
import getAccount from 'models/account/reducers/selectors';
import InitialOptionsPane from './component';

export default connect(
  (state) => {
    const account = getAccount(state);
    const specialRules = getSpecialRules(state);

    const {
      ShowAcresSearch,
      ShowDaysListed,
      ShowGarages,
      ShowPriceReduced,
      ShowSQFT,
      ShowStories,
      ShowYearBuilt,
    } = specialRules;

    return {
      showAcresSearch: ShowAcresSearch,
      showDaysListed: ShowDaysListed,
      showGarages: ShowGarages,
      showPriceReduced: ShowPriceReduced,
      showSQFT: ShowSQFT,
      showStories: ShowStories,
      showYearBuilt: ShowYearBuilt,
      showOpenHouse: account.ShowOpenHouse,
    };
  },
  null
)(InitialOptionsPane);

/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRouteID } from 'selectors/router';

/**
 * If a code split component has html from the server we want to keep that html
 * around and use it as a LoadingComponent
 *
 * @param {string} html Server HTML that we scraped off the dom and cached
 * @param {bool} isLoading When we are loading an import
 * @param {bool|Error} error When the loader fails
 * @param {bool} pastDelay import has taken longer than the set delay (200ms) to download
 */
function CachedHTML({ html, isLoading, error, pastDelay }) {
  if (!isLoading) {
    return null;
  }

  if (html) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  // TODO: We could implement some very light skeletons here
  // gallery -> screen level skeleton
  // details -> skeleton with snapshot data
  return null;
}
CachedHTML.propTypes = {
  html: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  pastDelay: PropTypes.bool,
};

const CachedHTMLContainer = connect(state => {
  const routeID = getRouteID(state);
  const html = state.cachedHtml[routeID] || '';

  return {
    html,
  };
})(CachedHTML);
CachedHTMLContainer.displayName = 'CachedHTMLContainer';

export default CachedHTMLContainer;

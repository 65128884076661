import { takeEvery, select } from 'redux-saga/effects';

import * as modalActions from 'services/bbModal/bbModalActions';
import * as routes from 'routes';

import { getRouteID } from 'selectors/router';
import { isMobile as isMobileSelector } from 'selectors/browser';

const bodyAttrEffects = [
  takeEvery(modalActions.open, modalOpen),
  takeEvery(modalActions.close, modalClose),
];

/**
 * If a modal is dismissed we try to restore the users scroll position
 * @type {null | number}
 */
let scrollY = null;

/**
 * When a modal opens we fix the body to prevent background scrolling
 * this also solves a bug with ios safari rendering the cursor in the
 * wrong position (related to forms in fixed divs)
 *
 * This doesn't matter on some pages because we only squeeze you at the
 * top of a page, and React Virtualized requires a little more nuance to work properly
 */
function* modalOpen(action) {
  // This issue is currently only on ios, so we target mobile for the solution
  const isMobile = yield select(isMobileSelector);
  if (!isMobile) {
    return;
  }

  // This modal is often fired before the page has fully rendered, and we've read the scrollY
  // from the previous screen. So this could result in a "fixed" background that is below the
  // actual page. In practice we only squeeze at the top of the page on details.
  const routeID = yield select(getRouteID);
  if (routeID !== routes.DETAILS) {
    scrollY = action.payload.offset;
    document.body.style.top = `-${scrollY}px`;
  }
  document.body.style.position = 'fixed';

  // in cases where the contents of the body are removed from the flow
  // this becomes important
  document.body.style.minHeight = '100vh';
}

function modalClose() {
  document.body.style.removeProperty('top');
  document.body.style.removeProperty('position');
  document.body.style.removeProperty('minHeight');

  // ios has trouble recognizing that a resize event just occurred
  // which leaves RV with some oddball measurements
  window.dispatchEvent(createNewEvent('resize'));

  if (scrollY !== null) {
    window.scroll(0, scrollY);
    scrollY = null;
  }
}

/**
 * Event constructor is the new default and initEvent is
 * deprecated, but ie11
 *
 * @param {string} eventName
 */
function createNewEvent(eventName) {
  let event;
  if (typeof Event === 'function') {
    event = new Event(eventName);
  } else {
    event = document.createEvent('Event');
    event.initEvent(eventName, true, true);
  }
  return event;
}

export default bodyAttrEffects;

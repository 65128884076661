import { createReducer } from 'redux-act';
import { initializeState } from 'actions/bootstrapActions';

export default createReducer(
  {
    [initializeState]: (prevState, { specialRules }) => ({
      ...specialRules,
    }),
  },
  {}
);

import { takeEvery, all, put } from 'redux-saga/effects';
import * as routes from 'routes';
import { push } from 'redux-little-router';
import goBackIfNotFirstRoute from 'sagas/routerTasks/goBackIfNotFirstRoute';

import * as actions from './MobileAccountActions';
import { clickCloseChangePassword } from '../Forms/ChangePassword/actions';
import { clickCloseEditAccount } from '../Forms/EditAccount/actions';
import { clickCloseSavedSearches } from '../Forms/EditSavedSearches/actions';
import { clickCloseEmailPrefs } from '../Forms/EditEmailPrefs/actions';
import { clickCloseTextPrefs } from '../Forms/EditTextPrefs/actions';

export default function* mobileAccountSaga() {
  yield all([
    takeEvery(
      [
        clickCloseChangePassword,
        clickCloseEditAccount,
        clickCloseSavedSearches,
        clickCloseEmailPrefs,
        clickCloseTextPrefs,
        actions.clickClose,
      ],
      goBackIfNotFirstRoute
    ),

    takeEvery(actions.clickEditInfoLink, handleEditAccountClick),
  ]);
}

function* handleEditAccountClick() {
  yield put(push(`/${routes.EDIT_ACCOUNT}`));
}

import React from 'react';
import PropTypes from 'prop-types';
import { shouldUpdate, compose } from 'recompose';
import isPath from 'utility/isPath';
import { BackButton } from 'components/Common/MobileScreen';
import MenuLayout from 'components/MobileMenu/MenuLayout';
import { LinkList, LinkListItem } from 'components/MobileMenu/LinkList';
import { MOBILE_SITE_MENU } from 'cypress_constants';
import withState from './connectSiteMenu';

export const MobileSiteMenu = ({ title, items, onBackButtonClick, onMenuItemClick }) => (
  <MenuLayout
    title={title || 'Site Menu'}
    topLeftButton={<BackButton onClick={onBackButtonClick} color="primary" dataCY={MOBILE_SITE_MENU.BACK_BUTTON} />}
    children={
      <LinkList>
        {items.map(i => (
          <LinkListItem
            key={i.id}
            // Href for leaves, click action for non-leaves
            {...(i.hasChildren ? { onClick: () => onMenuItemClick(i.id) } : { href: i.href })}
            isRoute={!i.hasChildren && isPath(i.href)}
            icon={i.hasChildren ? 'angle-right' : undefined}
            dataCY={'mobile-site-menu'.concat("__", i.title.toLowerCase().replace(/ /g,"-"))}
          >
            {i.title}
          </LinkListItem>
        ))}
      </LinkList>
    }
  />
);

MobileSiteMenu.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      href: PropTypes.string,
      title: PropTypes.string.isRequired,
      hasChildren: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  // Only non-leaf items
  onMenuItemClick: PropTypes.func.isRequired,
};

export default compose(
  withState,
  shouldUpdate(() => false),
)(MobileSiteMenu);

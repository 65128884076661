import { useMemo } from 'react';
import { PROPERTY_TYPES } from 'models/search/constants';
import { LISTING_CARD } from 'cypress_constants';

/**
 * Generate the proper pairing of listing meta data (ie beds, baths, sqft, etc) for the
 * listing type.
 *
 * @param {import('types/flagshipAPI').ListingSnapshot} [listing]
 * @return {import('./types').MetaDataItem[]}
 */
export default function useListingMetaData(listing) {
  const {
    ApproxSqFtText,
    Bedrooms,
    FullBaths,
    HalfBaths,
    hideHalfBaths,
    HideBedsBaths,
    ShowSQFT,
    SqFtSource,
    NumUnits,
    DisableAcreage,
    AcreageText,
    Acreage,
    PropertyType: { _ID: propertyTypeID },
  } = listing;

  return useMemo(() => {
    /**
     * The default filter to run our meta data values through. Can be overridden by each item's
     * config object by providing a `valueFilter` property.
     *
     * @param {string|number}
     * @returns {string|number}
     */
    const _defaultValueFilter = (val) => {
      if (val == null || val === '') {
        return 'N/A';
      }

      return val;
    };

    /**
     * @type {import('./types').MetaDataConfig}
     */
    const dataConfig = {
      units: {
        value: NumUnits,
        label: 'units'
      },
      beds: {
        value: Bedrooms,
        label: 'beds',
        dataCY: LISTING_CARD.BEDS_SELECTOR,
      },
      baths: {
        value: FullBaths,
        label: 'baths',
        dataCY: LISTING_CARD.BATHS_SELECTOR,
      },
      sqft: {
        valueFilter: (val) => (val === '0' ? 'N/A' : val),
        value: ApproxSqFtText,
        label: 'sqft',
      },
      halfBaths: {
        value: HalfBaths,
        label: '½ baths',
        dataCY: LISTING_CARD.HALF_BATHS_SELECTOR,
      },
      acres: {
        valueFilter: (val) => (
          DisableAcreage || ['0', ''].includes(val) || val == null ? 'N/A' : val
        ),
        value: AcreageText || Acreage,
        label: 'acres',
      },
    };

    /**
     * Render different sets of meta data depending on the PropertyType
     *
     * @type {{[string]: string[]}}
     */
    const dataSets = {
      default: ['beds', 'baths', 'halfBaths', 'sqft'],
      [PROPERTY_TYPES.LOTS_AND_LAND]: ['acres'],
      [PROPERTY_TYPES.BOAT_SLIP]: ['sqft'],
      [PROPERTY_TYPES.MULTI_FAMILY_HOME]: ['units', 'sqft'],
    };

    return (dataSets[propertyTypeID] || dataSets.default)
      .filter((attr) => {
        switch (attr) {
          case 'halfBaths':
            if (hideHalfBaths || HideBedsBaths) {
              return false;
            }

            return true;

          case 'beds':
          case 'baths':
            if (HideBedsBaths) {
              return false;
            }

            return true;

          case 'sqft':
            if (ShowSQFT && !SqFtSource) {
              return true;
            }

            return false;

          default:
            return true;
        }
      })
      .map((id) => {
        const { value, label, valueFilter = _defaultValueFilter, dataCY } = dataConfig[id];

        return {
          key: id,
          value: valueFilter(value),
          label,
          dataCY,
        };
      });
  }, []);
}

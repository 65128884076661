import { LOCATION_CHANGED } from 'redux-little-router';

/**
 * Persist the number of route changes that have occurred, including the initial page load.
 */
export default (state = 0, { type }) => (type === LOCATION_CHANGED ? state + 1 : state);

/**
 * Selector for the `locationChanges` value in our global state tree
 *
 * @returns {Number}
 */
export const getLocationChanges = (state) => state.locationChanges;

import { Model } from 'backbone';

export default class BaseModel extends Model {
  initialize() {
    this.on('change', (model, options) => window.bt.events.trigger('change', model, options));
  }

  log(msg) {
    window.bt.events.trigger('log', msg);
  }

  slog(msg, css) {
    window.bt.events.trigger('chalk', msg, css);
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Text = (props) => {
  const {
    bold,
    semibold,
    regular,
    uppercase,
    lowercase,
    capitalize,
    italic,
    block,
    truncate,
    underline,
    overline,
    strikethrough,
    muted,
    small,
    large,
    style,
    dataCY,
    className,
    children,
    ...rest
  } = props;

  const classes = cx(
    'bt-text',
    {
      'bt-text--bold': bold,
      'bt-text--semibold': semibold,
      'bt-text--regular': regular,
      'bt-text--uppercase': uppercase,
      'bt-text--lowercase': lowercase,
      'bt-text--capitalize': capitalize,
      'bt-text--italic': italic,
      'bt-text--block': block,
      'bt-text--truncate': truncate && block,
      'bt-text--underline': underline,
      'bt-text--overline': overline,
      'bt-text--strikethrough': strikethrough,
      'bt-text--muted': muted,
      'font-size--14': small,
      'font-size--18': large,
    },
    className,
  );

  return <span className={classes} data-cy={dataCY} style={style} {...rest}>{children}</span>;
};

Text.propTypes = {
  /** Font Weights */
  bold: PropTypes.bool,
  semibold: PropTypes.bool,
  regular: PropTypes.bool,

  /** Text Transforms */
  uppercase: PropTypes.bool,
  lowercase: PropTypes.bool,
  capitalize: PropTypes.bool,

  /** Font Styles */
  italic: PropTypes.bool,

  /** Blocks and Truncation */
  block: PropTypes.bool,
  truncate: PropTypes.bool,

  /** Decorations */
  underline: PropTypes.bool,
  overline: PropTypes.bool,
  strikethrough: PropTypes.bool,

  /** States */
  muted: PropTypes.bool,

  /** Sizes (Deprecated) */
  small: PropTypes.bool,
  large: PropTypes.bool,

  /** Standard Props */
  style: PropTypes.object,
  dataCY: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default Text;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Modal as Mo } from 'react-overlays';
import { config } from 'BoomTown';

export const ModalContent = (props) => {
  const { children, ...rest } = props;
  return (
    <ContentWrapper {...rest}>
      <Content>{children}</Content>
    </ContentWrapper>
  );
};

ModalContent.propTypes = {
  children: PropTypes.any,
};

export const ContentWrapper = (props) => {
  const { className, children, ...rest } = props;
  return (
    <div className={cx('modalxp__content-wrapper', className)} {...rest}>
      {children}
    </div>
  );
};

ContentWrapper.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
};

export const Content = (props) => {
  const { className, children, ...rest } = props;
  return (
    <div className={cx('modalxp__content', className)} {...rest}>
      {children}
    </div>
  );
};
Content.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
};

export const Modal = (props) => {
  const { className, children, ...rest } = props;
  return (
    <Mo className={cx('modalxp__modal', className)} {...rest}>
      {children}
    </Mo>
  );
};

Modal.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
};

export const Backdrop = (props) => {
  const { className, children, ...rest } = props;
  const mobileAppClassName = config.isMobileApp ? 'modalxp__backdrop_mobileapp' : '';
  return (
    <div className={cx('modalxp__backdrop', mobileAppClassName, className)} {...rest}>
      {children}
    </div>
  );
};
Backdrop.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
};

/* eslint-disable no-unused-vars */
import React, { Component, Children, createElement } from 'react';
import PropTypes from 'prop-types';
import Swipeable from 'react-swipeable';
import { shallowEqual } from 'recompose';
import Flickity from 'flickity-bg-lazyload';
import cx from 'classnames';

/**
 * Determine if every value in two arrays are equal.
 *
 * @param {Array} arr1
 * @param {Array} arr2
 */
function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((x1, i) => x1 === arr2[i]);
}

/**
 * Slider Component
 *
 * @param  {Object} props
 * @return {ReactElement}
 */
export default class Slider extends Component {
  constructor(props) {
    super(props);

    /** List of defined Flickity events */
    this.eventHandlers = [
      'cellSelect',
      'settle',
      'dragStart',
      'dragMove',
      'dragEnd',
      'pointerDown',
      'pointerMove',
      'pointerUp',
      'staticClick',
      'lazyLoad',
    ];
  }

  componentDidMount() {
    this.initialize();
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.shouldReinit(this.props, nextProps)) {
      this.deinitialize();
      return;
    }

    // Remove any event listeners that have changed since the last update
    Object.keys(this.props.events)
      .map(eventName => ({
        eventName,
        handler: this.props.events[eventName],
      }))
      .forEach(({ eventName, handler }) => {
        if (nextProps.events[eventName] !== handler) {
          this.flickity.off(eventName, handler);
        }
      });
  }

  componentDidUpdate(prevProps) {
    if (this.shouldReinit(prevProps, this.props)) {
      this.initialize();
      return;
    }

    // Add any event listeners that are new since the last update
    Object.keys(this.props.events)
      .map(eventName => ({
        eventName,
        handler: this.props.events[eventName],
      }))
      .forEach(({ eventName, handler }) => {
        if (prevProps.events[eventName] !== handler) {
          this.flickity.on(eventName, handler);
        }
      });
  }

  componentWillUnmount() {
    this.deinitialize();
  }

  /**
   * Get the current selected index.
   * @return {integer} The selected index.
   */
  getSelectedIndex = () => this.flickity.selectedIndex;

  /**
   * Get the currently selected element.
   * @return {Object} The selected cell element.
   */
  getSelectedElement = () => this.flickity.selectedElement;

  /**
   * Get the current cells.
   * The array of cells. Use cells.length for the total number of cells.
   * @return {Array} Array of cells.
   */
  getCells = () => this.flickity.cells;

  /**
   * Get the elements of the cells.
   * http://flickity.metafizzy.co/api.html#getcellelements
   * @return {cellElements} Array of Elements
   */
  getCellElements = () => this.flickity.getCellElements();

  /**
   * Add all Flickity event listeners specified via `this.props.events`.
   */
  addEventListeners = () => {
    Object.keys(this.props.events)
      .map(eventName => ({
        eventName,
        handler: this.props.events[eventName],
      }))
      .filter(({ eventName, handler }) =>
        this.eventHandlers.includes(eventName) && typeof handler === 'function'
      )
      .forEach(({ eventName, handler }) => {
        this.flickity.on(eventName, handler);
      });
  };

  /**
   * Remove all Flickity event listeners specified via `this.props.events`.
   */
  removeEventListeners = () => {
    Object.keys(this.props.events)
      .map(eventName => ({
        eventName,
        handler: this.props.events[eventName],
      }))
      .filter(({ eventName, handler }) =>
        this.eventHandlers.includes(eventName) && typeof handler === 'function'
      )
      .forEach(({ eventName, handler }) => {
        this.flickity.off(eventName, handler);
      });
  };

  /**
   * Save a reference to the underlying HTMLElement that is rendered.
   *
   * @param {HTMLElement} el
   */
  _saveRef = el => {
    this.el = el;
  };

  /**
   * Select a flickity cell.
   * http://flickity.metafizzy.co/api.html#select
   * @param  {Integer}  index     Zero-based index of the cell to select.
   * @param  {Boolean} isWrapped Optional. If true, the last cell will be selected if at the first cell.
   * @param  {Boolean} isInstant If true, immediately view the selected cell without animation.
   * @return {undefined}
   */
  select = (index, isWrapped, isInstant) => {
    this.flickity.select(index, isWrapped, isInstant);
  };

  /**
   * Select the previous cell.
   * http://flickity.metafizzy.co/api.html#previous
   * @param  {Boolean} isWrapped Optional. If true, the last cell will be selected if at the first cell.
   * @return {undefined}
   */
  previous = isWrapped => {
    this.flickity.previous(isWrapped);
  };

  /**
   * Select the next cell.
   * http://flickity.metafizzy.co/api.html#next
   * @param  {Boolean} isWrapped Optional. If true, the first cell will be selected if at the last cell.
   * @return {undefined}
   */
  next = isWrapped => {
    this.flickity.next(isWrapped);
  };

  /**
   * Resize the carousel and re-position cells.
   * http://flickity.metafizzy.co/api.html#resize
   * @return {undefined}
   */
  resize = () => {
    this.flickity.resize();
  };

  /**
   * Position cells at selected position.
   * Trigger reposition after the size of a cell has been changed.
   * http://flickity.metafizzy.co/api.html#reposition
   * @return {undefined}
   */
  reposition = () => {
    this.flickity.reposition();
  };

  /**
   * Prepend elements and create cells to the beginning of the carousel.
   * http://flickity.metafizzy.co/api.html#prepend
   * @param  {object|array} elements jQuery object, Array of Elements, Element, or NodeList
   * @return {undefined}
   */
  prepend = elements => {
    this.flickity.prepend(elements);
  };

  /**
   * Append elements and create cells to the end of the carousel.
   * http://flickity.metafizzy.co/api.html#append
   * @param  {object|array} elements jQuery object, Array of Elements, Element, or NodeList
   * @return {undefined}
   */
  append = elements => {
    this.flickity.append(elements);
  };

  /**
   * Insert elements into the carousel and create cells.
   * http://flickity.metafizzy.co/api.html#insert
   * @param  {object|array} elements jQuery object, Array of Elements, Element, or NodeList
   * @param {integer} index Zero-based index to insert elements.
   * @return {undefined}
   */
  insert = (elements, index) => {
    this.flickity.insert(elements, index);
  };

  /**
   * Remove cells from carousel and remove elements from DOM.
   * http://flickity.metafizzy.co/api.html#remove
   * @param  {object|array} elements jQuery object, Array of Elements, Element, or NodeList
   * @return {undefined}
   */
  remove = elements => {
    this.flickity.remove(elements);
  };

  /**
   * Starts auto-play
   * Setting autoPlay will automatically start auto-play on initialization. You do not need to start auto-play with playPlayer.
   * http://flickity.metafizzy.co/api.html#playplayer
   * @return {undefined}
   */
  play = () => {
    this.flickity.playPlayer();
  };

  /**
   * Stops auto-play and cancels pause.
   * http://flickity.metafizzy.co/api.html#stopPlayer
   * @return {undefined}
   */
  stop = () => {
    this.flickity.stopPlayer();
  };

  /**
   * Pauses auto-play.
   * http://flickity.metafizzy.co/api.html#pausePlayer
   * @return {undefined}
   */
  pause = () => {
    this.flickity.pausePlayer();
  };

  /**
   * Resumes auto-play if paused.
   * http://flickity.metafizzy.co/api.html#unpausePlayer
   * @return {undefined}
   */
  unpause = () => {
    this.flickity.unpausePlayer();
  };

  /**
   * Remove Flickity functionality completely. destroy will return the element back to its pre-initialized state.
   * http://flickity.metafizzy.co/api.html#destroy
   * @return {undefined}
   */
  destroy = () => {
    this.flickity.destroy();
  };

  /**
   * Re-collect all cell elements in flickity-slider.
   * http://flickity.metafizzy.co/api.html#reloadcells
   * @return {undefined}
   */
  reloadCells = () => {
    this.flickity.reloadCells();
  };

  deinitialize() {
    this.removeEventListeners();
    this.destroy();
  }

  initialize() {
    if (this.el) {
      this.flickity = new Flickity(this.el, {
        // Defaults
        dragThreshold: 12,
        pageDots: false,
        wrapAround: true,

        // User options
        ...this.props.options
      });

      this.addEventListeners();
    }
  }

  /**
   * Determine if Flickity should be re-initialized.
   * @param {Object} nextProps
   * @returns {boolean}
   */
  shouldReinit(prevProps, nextProps) {
    const childrenEqual = arraysEqual(
      Children.map(prevProps.children, c => c && c.key),
      Children.map(nextProps.children, c => c && c.key)
    );
    return (
      !childrenEqual ||
      !shallowEqual(prevProps.options, nextProps.options) ||
      prevProps.el !== nextProps.el ||
      prevProps.classes !== nextProps.classes
    );
  }

  /**
   * Finally render our flickity slider
   */
  render() {
    const el = this.props.el ? this.props.el : 'ul';
    const props = {
      className: cx(this.props.classes),
      ref: this._saveRef,
    };

    return (
      // CNS-5999: Fixes issue with involuntary vertical scroll related to two different issues on
      //  iOS 11.3 and 12. Should hopefully be able to remove at some point.
      // @see https://github.com/metafizzy/flickity/issues/740
      // @see https://bugs.webkit.org/show_bug.cgi?id=185656
      <Swipeable
        preventDefaultTouchmoveEvent
        onSwipingRight={() => {}}
        onSwipingLeft={() => {}}
      >
        {createElement(el, props, this.props.children)}
      </Swipeable>
    );
  }
}

Slider.displayName = 'Slider';

// http://flickity.metafizzy.co/options.html
Slider.propTypes = {
  el: PropTypes.string,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  events: PropTypes.shape({
    cellSelect: PropTypes.func,
    settle: PropTypes.func,
    dragStart: PropTypes.func,
    dragMove: PropTypes.func,
    dragEnd: PropTypes.func,
    pointerDown: PropTypes.func,
    pointerMove: PropTypes.func,
    pointerUp: PropTypes.func,
    staticClick: PropTypes.func,
    lazyLoad: PropTypes.func,
    bgLazyLoad: PropTypes.func,
  }),
  options: PropTypes.shape({
    // Setup
    cellSelector: PropTypes.string,
    initialIndex: PropTypes.number,
    accessibility: PropTypes.bool,
    setGallerySize: PropTypes.bool,
    resize: PropTypes.bool,

    // Cell Position
    cellAlign: PropTypes.string,
    contain: PropTypes.bool,
    imagesLoaded: PropTypes.bool,
    percentPosition: PropTypes.bool,
    rightToLeft: PropTypes.bool,

    // Behavior
    draggable: PropTypes.bool,
    freeScroll: PropTypes.bool,
    wrapAround: PropTypes.bool,
    groupCells: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
    lazyLoad: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    bgLazyLoad: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    autoPlay: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    pauseAutoPlayOnHover: PropTypes.bool,
    adaptiveHeight: PropTypes.bool,
    watchCSS: PropTypes.bool,
    asNavFor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dragThreshold: PropTypes.number,
    selectedAttraction: PropTypes.number,
    friction: PropTypes.number,
    freeScrollFriction: PropTypes.number,

    // UI
    prevNextButtons: PropTypes.bool,
    pageDots: PropTypes.bool,
    arrowShape: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  children: PropTypes.arrayOf(PropTypes.node),
};

Slider.defaultProps = {
  events: {},
  options: {},
};

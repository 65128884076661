import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { connectBBHoc } from 'hoc/connectBB';
import { tags } from 'BoomTown';

import * as a from './actions';
import TagList from './TagList';

class DesktopOverflowTagList extends Component {
  handleTagClick = (e, { prop, value }) => this.props.onTagClick({ prop, value });

  render() {
    return (
      <TagList
        className={this.props.className}
        tags={this.props.tags.filter(tag => tag.overflow)}
        onTagClick={this.handleTagClick}
      />
    );
  }
}

DesktopOverflowTagList.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.array,
  onTagClick: PropTypes.func.isRequired,
};

export default compose(
  connectBBHoc([
    {
      getEmitter: () => tags,
      events: [
        {
          name: 'reset',
          replaceProps: props => ({
            ...props,
            tags: tags.toJSON(),
          }),
        },
      ],
    },
  ]),
  connect(
    null,
    { onTagClick: a.tagClick }
  )
)(DesktopOverflowTagList);

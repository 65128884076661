import { takeEvery, fork, take, race, put, call, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { api as Api, visitor as visitorModel } from 'BoomTown';
import { isMobile as isMobileSelector } from 'selectors/browser';
import { getTextPreferences } from 'models/visitor/reducers/selectors';
import * as Routes from 'routes';
import isRoute from 'utility/isRoute';

import { receiveCommunicationPreferences, errorFetchingCommunicationPreferences } from 'actions/visitorActions';
import {
  submitTextPreferencesForm,
  successSubmitTextPreferencesForm,
  clickRetryInTextPrefsFailureModal,
  failedSubmitTextPreferencesForm,
  clickCloseTextPrefsSuccessModal,
  autoDismissTextPrefsSuccessModal
} from './actions';

import { getTextPrefsFormState } from './reducer';

export default [
  takeEvery(
    [isRoute(Routes.TEXT_PREFERENCES), isRoute(Routes.ACCOUNT)],
    fetchTextPrefs
  ),
  fork(saveTextPreferencesWatcher)
];

function* fetchTextPrefs(action) {
  // Don't fetch email prefs for the account route when on mobile
  if (isRoute(Routes.ACCOUNT)(action)) {
    const isMobile = yield select(isMobileSelector);
    if (isMobile) {
      return;
    }
  }

  // check to see if textPrefs exist in state before making API call
  const prefsFromState = yield select(getTextPreferences);
  if (!prefsFromState) {
    /** @type {FlagshipAPI.CommunicationPreferencesResponse} */
    let communicationPrefs;
    try {
      communicationPrefs = yield call([Api, Api.getCommunicationPreferences]);
    } catch (e) {
      yield put(errorFetchingCommunicationPreferences(e));
      return;
    }
    const { TextPreferences, EmailPreferences } = communicationPrefs;

    yield put(receiveCommunicationPreferences({
      emailPreferences: EmailPreferences,
      textPreferences: TextPreferences
    }));
  }
}


export function* saveTextPreferencesWatcher() {
  while (true) {
    const { clickedSubmit, clickedRetry } = yield race({
      clickedSubmit: take(submitTextPreferencesForm),
      clickedRetry: take(clickRetryInTextPrefsFailureModal),
    });

    if (clickedSubmit || clickedRetry) {
      yield call(updateTextPreferences);
    }
  }
}


function* saveTextPreferences(request) {
  const textOptIn = yield select(getTextPrefsFormState);
  if (window.dataLayer) {
    yield call([window.dataLayer, window.dataLayer.push], {
      form: 'updatetextpreferences',
      formtype: 'updateCommunicationPreferences',
      event: 'text-prefs-submit',
      textOptIn: textOptIn.textAllowed
    });
  }
  try {
    yield call([visitorModel, visitorModel.updateCommunicationPreferencesPromise], request);
  } catch (error) {
    yield put(failedSubmitTextPreferencesForm({ error }));
    return;
  }

  yield put(successSubmitTextPreferencesForm(request));
  yield race([
    call(dismissAfterTimeout),
    take(clickCloseTextPrefsSuccessModal)
  ]);
}

function* dismissAfterTimeout() {
  yield call(delay, 2000);
  yield put(autoDismissTextPrefsSuccessModal());
}

function* updateTextPreferences() {
  const textData = yield select(getTextPrefsFormState);

  // Trigger our "Retry" modal if we don't have saved or form textPreference state.
  if (!textData) {
    yield put(failedSubmitTextPreferencesForm());
  }

  yield call(saveTextPreferences, { optOutText: !textData.textAllowed });
}

import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'redux-little-router';
import { add, remove } from 'sagas/routerTasks/search';
import * as r from 'routes';

import * as a from './actions';

export default [
  takeEvery(a.savePolygonCoordinates, function* savePolyToURL({ payload }) {
    yield add({ polygon: payload.polygon });
  }),

  takeEvery(
    [
      a.recenterAfterRID,
      a.recenterAfterNearby,
      a.recenterAfterLatLonRad,
      a.recenterAfterFavs,
      a.mapIdle,
    ],
    function* saveMapParamsToURL({ payload: mapParams }) {
      // scenario: favs=1 should remain in the url when we write to the url
      yield put(push({ pathname: `/${r.RESULTS_MAP}/`, query: mapParams }, { persistQuery: true }));
    }
  ),
  takeEvery(a.mapDragWhileNearby, function* removeNearby() {
    yield remove({ latlonrad: null });
  })
];

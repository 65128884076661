import $ from 'jquery';
import UIkit from 'uikit';

$.ccompCloseAllDropdowns = function ccompCloseAllDropdowns() {
  $('[data-bt-ccomp="dropdown"] [data-bt-ccomp-trigger]').removeClass('bt-active');
  $('[data-bt-ccomp="dropdown"]').removeClass('bt-ccomp--active');
  return true;
};

$.ccompCloseAllToggles = function ccompCloseAllToggles() {
  $('[data-bt-ccomp="toggle"] [data-bt-ccomp-trigger]').removeClass('bt-active');
  $('[data-bt-ccomp="toggle"]').removeClass('bt-ccomp--active');
  return true;
};

// Ensure clicks that land within a dropdown menu stop propagating the event up to the
// $(document).one('click') listener that is applied when the dropdown is opened.
//
// This allows you to interact with a dropdown menu without the menu closing on you
$(
  document
).on(
  'click',
  '[data-bt-ccomp="dropdown"] .bt-ccomp__content, [data-bt-ccomp="toggle"] .bt-ccomp__content',
  e => e.stopPropagation()
);

// Combo Component Triggers
$(document).on('click', '[data-bt-ccomp-trigger]', e => {
  e.preventDefault();

  const $this = $(e.currentTarget);
  if ($this.hasClass('disabled')) {
    return;
  }
  const $thisLabel = $this.data('btCcompLabel');
  const $thisParents = $this.parents('[data-bt-ccomp]');

  if ($thisParents.attr('data-bt-ccomp') === 'dropdown') {
    $('[data-bt-ccomp="dropdown"] [data-bt-ccomp-trigger]')
      .not($this)
      .parents('[data-bt-ccomp]')
      .removeClass('bt-ccomp--active');
    $('[data-bt-ccomp="dropdown"] [data-bt-ccomp-trigger]').not($this).removeClass('bt-active');
  }

  // No reason to fire anything here if the dropdown menu is active
  // because the $(document).one('click') will still fire and close the menu
  if (!$this.hasClass('bt-active')) {
    // We stop progating the click event to $(document).one('click') when
    // displaying the menu to prevent an immediate close of the menu
    e.stopPropagation();

    $this.addClass('bt-active');
    $thisParents.addClass('bt-ccomp--active');
    UIkit.Utils.checkDisplay($thisParents);

    if ($this.next().attr('data-bt-ccomp-justify')) {
      if ($this.parent('.bt-ccomp').attr('data-bt-ccomp') === 'dropdown') {
        const panelWidth = $(`.${$this.next().attr('data-bt-ccomp-justify')}`).outerWidth();
        $this.next().css({
          width: panelWidth,
          'max-width': panelWidth
        });
      } else {
        $this.next().css({
          width: '',
          'max-width': ''
        });
      }
    }
    if ($thisLabel !== undefined) {
      window.bt.events.trigger(`ccomp_${$thisLabel}_open`);
    }

    // Attach a one time event listener to the document click
    // that closes the currently open dropdown menu
    $(document).one('click', () => {
      $this.removeClass('bt-active');
      // Added a blur event so the button would lose focus
      // and correctly display button styles
      $this.blur();
      return $thisParents.removeClass('bt-ccomp--active');
    });
  }
});

$(document).on('click', '.js-ccomp__close-dropdowns', event => {
  event.preventDefault();
  $.ccompCloseAllDropdowns();
});

// Close Sort menu when new sort item is selected
// Do not prevent default since we need the checked input to update
$(document).on('click', '.bt-listing__sort--mobile input', () => {
  $.ccompCloseAllDropdowns();
});

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getMenuStack } from 'selectors/siteMenu';
import { MENU } from 'routes';
import Transition from 'components/MobileMenu/Transition';
import MainMenu from './MainMenu';
import SiteMenu from './SiteMenu';

export class TransitionStackManager extends React.PureComponent {
  static propTypes = {
    stack: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  state = {
    direction: '',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.stack.length === this.props.stack.length) {
      return;
    }
    const direction = nextProps.stack.length > this.props.stack.length ? 'right' : 'left';

    this.setState({ direction });
  }

  render() {
    // Generate a UID for the menu
    const { stack } = this.props;
    const key = stack[stack.length - 1];

    return (
      <Transition direction={this.state.direction}>
        <div key={key} className="site-nav">
          {key === MENU ? <MainMenu /> : <SiteMenu />}
        </div>
      </Transition>
    );
  }
}

export default connect(state => ({ stack: getMenuStack(state) }))(TransitionStackManager);

import Listing from 'legacy/Model/listing';
import { select, } from 'redux-saga/effects';
import { getListingsFromMap } from '../reducer';
import { filterMapListings } from './syncMapResults';

/**
 * Beef up our listings
 * @param {FlagshipAPI.ListingSnapshot[]} listings
 */
export default function* beefUpListings(listings) {
  const currentListings = yield select(getListingsFromMap);

  return listings?.reduce((filteredListings, listing) => {
    // Only compare against currentListings if they're there. No need to beefUp
    if (currentListings.length !== 0) {
      const currentListing = currentListings.find(prevListing => prevListing._ID === listing._ID);

      if (currentListing) {
        filteredListings.push(currentListing);
        return filteredListings;
      }
    }

    // Only beefUp listings if they're new to our listing set
    if (filterMapListings(listing)) {
      filteredListings.push({
        ...new Listing(listing).beefUp(),
        NoLazyLoad: true,
      });

      return filteredListings;
    }
    return filteredListings;
  }, []);
}

/* eslint-disable import/prefer-default-export */

/**
 * Return the `modals` branch of our state tree
 * @param {any} state
 * @returns {import("../types").ModalState}
 */
export const getModalState = (state) => state.modals;

/* eslint-enable import/prefer-default-export */

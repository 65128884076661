import { createAction } from 'redux-act';

export const listingsReset = createAction('LISTINGS_RESET');
export const searchChange = createAction('SEARCH_CHANGE');

export const addFavorite = createAction('ADD_FAVORITE');
export const removeFavorite = createAction('REMOVE_FAVORITE');
export const tapFavorite = createAction('FAVORITE_BUTTON_TAP');

export const getResponseFromIframe = createAction('GET_RESPONSE_FROM_IFRAME');
export const loginUsingSocial = createAction('LOGIN_USING_SOCIAL');

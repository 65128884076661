import { createAction } from 'redux-act';

const action = (desc, ...args) => createAction(`Desktop Account > ${desc}`, ...args);

export const submitContactForm = action('Submit the contact agent form');
export const receiveContactFormRes = action(
  'Receive response from contact agent request',
  response => ({ success: response === 'success' })
);

export const autoCloseContactFormSuccessModal = action('Response Modal > Toast timeout');
export const closeContactFormSuccessModal = action('Response Modal > Tap confirm button');
export const clickRetryInFailureModal = action('Response Modal (Failure) > Tap retry');
export const clickBackInFailureModal = action('Response Modal (Failure) > Tap back');

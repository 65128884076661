import { LOCATION_CHANGED } from 'redux-little-router';
import { loadingFavorites, loadFavorites, loadSelected } from 'actions/ComparisonTableActions';
import { api, favorites as favs } from 'BoomTown';
import Listing from 'legacy/Model/listing';
import allowListingForCompare from 'utility/allowListingForCompare';

export default ({ dispatch, getState }) => (next) => (action) => {
  const { type, payload } = action;
  // Useful for testing.
  let promise;

  if (type === LOCATION_CHANGED && payload.result && payload.result.id === 'favorites') {
    const { favorites } = getState();

    const selectedIDs = (payload.query.id || '')
      .split(',')
      .map(id => parseInt(id, 10))
      .filter(id => !Number.isNaN(id))
      // Only dispatch IDs that are one of the user's favorites
      .filter(id => favs.findWhere({ id }));

    dispatch(loadSelected(selectedIDs));

    // Refresh the redux colleciton if we have a different count than the BB list of ids
    if (favs.length > 0 && favs.length !== favorites.favorites.length) {
      dispatch(loadingFavorites());

      promise = api.ajaxSearchPromise({ favs: 1, pageCount: 100 }).then(results => {
        const listings = results.Result.Items.map(listing => new Listing(listing).beefUp());
        dispatch(loadFavorites(listings));

        // Adjust the selected IDs now that we have more info
        const notAllowedIDs = listings.filter(l => !allowListingForCompare(l)).map(l => l._ID);
        dispatch(loadSelected(selectedIDs.filter(id => !notAllowedIDs.includes(id))));
      });
    }
  }

  next(action);

  return promise || Promise.resolve();
};

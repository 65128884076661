import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MobileScreen, CloseButton } from 'components/Common/MobileScreen';
import { MOBILE_CHANGE_PASSWORD } from 'cypress_constants';
import { PrimaryButton } from 'coreComponents';
import { isSubmittingChangePassword } from '../../Forms/ChangePassword/reducer';
import * as changePasswordActions from '../../Forms/ChangePassword/actions';
import ConnectedChangePasswordForm from '../../Forms/ChangePassword';

const MobileChangePassword = ({ onClickClose, isSubmitting }) => (
  <MobileScreen
    title="Change Password"
    dataCY={MOBILE_CHANGE_PASSWORD.SCREEN_HEADER}
    headerLeft={<CloseButton onClose={onClickClose} />}
    footer={
      <PrimaryButton
        type="submit"
        width="full"
        className="at-change-password-submit-btn"
        showSpinner={isSubmitting}
        form="change-password__form"
        dataCY={MOBILE_CHANGE_PASSWORD.SUBMIT_BUTTON}
      >
        Save Password
      </PrimaryButton>
    }
  >
    <ConnectedChangePasswordForm isMobileScreen />
  </MobileScreen>
);

MobileChangePassword.propTypes = {
  onClickClose: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default connect(
  state => ({
    isSubmitting: isSubmittingChangePassword(state),
  }),
  { onClickClose: changePasswordActions.clickCloseChangePassword }
)(MobileChangePassword);

import { createAction } from 'redux-act';

// eslint-disable-next-line import/prefer-default-export
export const openModal = createAction(
  'Open Modal',
  /**
   * Payload should be the modal ID to open. Available IDs can be found in
   * `/models/modals/constants.js`
   *
   * @param {string} id
   * @returns {string}
   */
  (id) => id
);

export const closeModal = createAction('Close Modal');

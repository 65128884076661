const INVALID_PROPS = [
  'helper',
  'helperId',
  'helperPos',
  'large',
  'icon',
  'iconFlip',
  'label',
  'returnRef'
];

/**
 * Borrowed from BluePrint's props.ts:
 * Typically applied to HTMLElements to filter out blacklisted props. When applied to a Component,
 * can filter props from being passed down to the children. Can also filter by a combined list of
 * supplied prop keys and the blacklist (only appropriate for HTMLElements).
 * @param {object} props        The original props object to filter down
 * @param {string} invalidProps If supplied, overwrites the default blacklist
 * @param {boolean} shouldMerge If true, will merge supplied invalidProps and blacklist together
 */
export function removeNonHTMLProps(props, invalidProps = INVALID_PROPS, shouldMerge = false) {
  let newProps = invalidProps;

  if (shouldMerge) {
    newProps = invalidProps.concat(INVALID_PROPS);
  }

  return newProps.reduce((prev, curr) => {
    if ({}.hasOwnProperty.call(prev, curr)) {
      delete prev[curr]; // eslint-disable-line
    }
    return prev;
  }, { ...props });
}

/**
 * Used to consistently format the id associated with a input's helper
 * text element
 * @param  {string} id The `id` attribute of the input element
 * @return {string}    The value to be associated with the `aria-describedby`
 *                     and `id` attributes of the input and helper elements, respectively
 */
export const makeHelperID = id => `${id}Helper`;

/* eslint-disable no-unused-vars */
import { createReducer } from 'redux-act';
import * as a from './bbModalActions';

const initialState = {
  isOpen: false,
  offset: 0,
};

const bbModalReducer = createReducer({
  [a.close]: (state, { id }) => ({
    isOpen: false,
    offset: 0,
  }),
  [a.open]: (state, { id, offset }) => ({
    isOpen: true,
    offset,
  }),
}, initialState);

export default bbModalReducer;

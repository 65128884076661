/**
 * Run any localStorage get/set commands through me. Doesn't accept
 * setting `null` values.
 *
 * @param  {string} namespace
 * @param  {string} type
 * @param  {number | string | object} val Anything except null
 * @return {any}
 */
export default function btLocalStorage(namespace = '', id = '', val = null) {
  // Separate namespace from id with an underscore
  const cleanNamespace = namespace ? `${namespace}_` : '';
  const key = `${cleanNamespace}${id}`.toLowerCase();

  try {
    // getter
    if (val === null) {
      return window.localStorage.getItem(key);
    }

    // setter
    window.localStorage.setItem(key, val);
  } catch (error) {
    // if a tree falls in the woods
  }

  return val;
}

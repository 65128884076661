import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import moment from 'moment-timezone';

const Day = ({
  day,
  active,
  disabled,
  dayNextMonth,
  dayPrevMonth,
  selectDay,
}) => {
  const handleOnClick = () => {
    if (disabled) return;
    selectDay(day);
  };

  const classes = cx(
    'cell',
    'calendar__day',
    {
      'calendar__day--active': day.isSame(active, 'day'),
      'calendar__day--disabled': disabled,
      'calendar__day--otherMonth': dayPrevMonth || dayNextMonth
    }
  );

  return (
    <div
      className={classes}
      tabIndex="0"
      onClick={handleOnClick}
      aria-selected={day.isSame(active, 'day')}
    >
      <div className="p-12">
        { day.date() }
      </div>
    </div>
  );
};

Day.propTypes = {
  disabled: PropTypes.bool,
  dayNextMonth: PropTypes.bool,
  dayPrevMonth: PropTypes.bool,
  selectDay: PropTypes.func,
  day: PropTypes.instanceOf(moment),
  active: PropTypes.instanceOf(moment)
};

export default Day;

import { LOCATION_CHANGED } from 'redux-little-router';

/**
 * A Redux middleware that handles propagation of Redux actions to our
 * legacy Backbone models and collections. If something in Backbone-land
 * needs to respond to a certain action, it can be handled here.
 */
export default () => (next) => (action) => {
  // Let redux hear about the change
  next(action);

  // Continue to proxy 'route' events (adhering to a same API as before)
  // through `bt.events`.
  // Really just for model/search at this point
  if (action.type === LOCATION_CHANGED) {
    const { params, search, result } = action.payload;

    window.bt.events.trigger('route', {
      route: result ? result.id : 'miss',
      args: [params || null, search.replace(/^\?/, '') || null],
    });
  }
};

import { connectBBHoc } from 'hoc/connectBB';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { search, tags } from 'BoomTown';

import * as a from './actions';

import SearchBar from './SearchBar';

function getInitialState() {
  return {
    ...applySearchProps({}),
    ...applyTagsProps({}),
  };
}

const applyTagsProps = props => ({
  ...props,
  tags: tags.toJSONWithCid(),
});

const applySearchProps = props => ({
  ...props,
  search: {
    hasFavs: search.has('favs'),
    hasOfficeID: search.has('OfficeID'),
    showDistance: search.has('latlonrad'),
    hasRID: search.has('rid'),
    // when we have favs, we don't have sort which is weird
    sort: search.get('sort') || 'importdate',
  },
});

const withRedux = connect(
  state => ({
    routeName: state.router.result && state.router.result.id,
  }),
  {
    onClickSaveSearch: a.clickSaveSearchInSearchBar,
    onSortClick: a.clickSortOption,
    onResultsViewTypeClick: a.clickResultsViewType,
  }
);

const withBB = connectBBHoc(
  [
    {
      getEmitter: () => search,
      events: [{ name: 'change', replaceProps: applySearchProps }],
    },
    {
      getEmitter: () => tags,
      events: [{ name: 'reset change', replaceProps: applyTagsProps }],
    },
  ],
  getInitialState
);

export default compose(
  withBB,
  withRedux
)(SearchBar);

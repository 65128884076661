import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ITags from 'btPropTypes/tags';
import emptyFunction from 'utility/emptyFunction';
import cx from 'classnames';

import SearchTag from './SearchTag';

/**
 * A list of <SearchTag>s with no filtering or DOM measuring logic involved. Exposes a `ref` prop
 * to facilitate the measuring done by the <DesktopTagList>
 */
export default class TagList extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    listRef: PropTypes.func,
    tags: PropTypes.arrayOf(PropTypes.shape(ITags)),
    onTagClick: PropTypes.func,
  };

  static defaultProps = {
    listRef: emptyFunction,
  };

  render() {
    const { tags, className, listRef, onTagClick } = this.props;

    return (
      <ul className={cx('bt-search-tags', className)} ref={listRef}>
        {tags.map(tag => (
          <SearchTag key={tag.prop + tag.value} onClick={onTagClick} {...tag} />
        ))}
      </ul>
    );
  }
}

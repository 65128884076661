import { createAction } from 'redux-act';

const action = (title, ...args) => createAction(`Results View > ${title}`, ...args);

export const onMouseEnterListingCard = action(
  'Listing Card > Mouse Enter',
  /** @param {ListingID} */
  id => id
);

export const onMouseLeaveListingCard = action(
  'Listing Card > Mouse Leave',
  () => {}
);

import { app, api, visitor, config } from 'BoomTown';
import { delay } from 'redux-saga';
import { take, takeEvery, takeLatest, call, select, all, put, race } from 'redux-saga/effects';
import { takeOnce } from 'sagas/utils';
import { replace } from 'redux-little-router';
import { stringify } from 'query-string';

import { RESULTS_GALLERY, RESULTS_MAP } from 'routes';
import { savedSearchesData } from 'constants/registrationForms';
import { StoredPendingSearch } from 'models/search/services';

// Actions
import * as ssActions from 'actions/MobileSaveSearchActions';
import * as mgActions from 'actions/MobileGalleryActions';
import * as mSearchMenuActions from 'screens/MobileSearchMenu/actions';

// Selectors
import { getStateWithoutDisabledTags } from 'selectors/mobileSaveSearch';
import { isSearchMenuOpen, getTotalItems as getTotalItemsForGallery } from 'selectors/mobileGallery';
import { getTotalItems as getTotalItemsForMap } from 'screens/ResultsMap/Map/reducer';
import { getPendingSearch } from 'models/search/reducers/pending/selectors';
import { getSearchCount } from 'reducers/OffCanvasSearch/menu';
import { getRouteID } from 'selectors/router';

export default function* mobileSaveSearchSaga() {
  yield all([
    takeLatest(ssActions.saveSearch, handleSaveSearch),
    takeLatest(ssActions.clickTag, handleSearchTagClick),
    takeEvery(
      [
        mgActions.clickSaveSearchInResultsHeader,
        mgActions.clickSaveSearchAd,
        mSearchMenuActions.clickSaveOnSearchMenu
      ],
      handleOpenSaveSearchModal
    ),
    takeEvery(
      [
        mSearchMenuActions.clickSaveOnSearchMenu,
        mgActions.clickSaveSearchInResultsHeader,
        mgActions.clickSaveSearchAd,
      ],
      squeezeUnregisteredUsers
    ),
    takeEvery(ssActions.receiveCreateSavedSearchRes, raceSuccessDismissal),
    takeOnce(
      [ssActions.clickClose, ssActions.clickSaveSearchSuccessButton, ssActions.autoDismiss],
      removePostRegQueryParam
    ),
  ]);
}

/**
 * Add a new saved search to the visitor and dispatch the correct action to update state accordingly
 *
 * @param {ReduxAction} action { payload }
 * @param {{ name: string, email: string, frequency: number }} action.payload
 */
export function* handleSaveSearch({ payload }) {
  try {
    const { name, email, frequency } = payload;
    const searchWithoutDisabledTags = yield select(getStateWithoutDisabledTags);
    const querystring = stringify(searchWithoutDisabledTags);

    const response = yield call(
      [visitor, visitor.saveSearchPromise],
      { searchName: name, email, frequency, querystring }
    );

    yield put(ssActions.receiveCreateSavedSearchRes({ response }));
    yield call([visitor, visitor.updateSearchCount], 'up');
  } catch (e) {
    yield put(ssActions.receiveCreateSavedSearchRes({ response: 'failure' }));
  }
}

/**
 * Handle updating the results count when a Search Tag is temporarily disabled
 *
 * @todo Figure out a good way to cache results here so we don't have to make repeated search count
 *       requests over and over (might have to do this further up where the API request is made)
 */
export function* handleSearchTagClick() {
  const searchWithoutDisabledTags = yield select(getStateWithoutDisabledTags);

  try {
    const { Result } = yield call([api, api.searchcountPromise], searchWithoutDisabledTags);
    yield put(ssActions.receiveCountFromAPI(Result));
  } catch (e) {
    // Update our state to show the count is not valid and that an error occurred.
    yield put(ssActions.receiveCountError(e));
  }
}

/**
 * Dispatch the receive count action synchronously using either the committed search's result count
 * or the pending search's results count depending on the current view.
 */
function* handleOpenSaveSearchModal() {
  let resultsCount;

  const isFilterMenu = yield select(isSearchMenuOpen);
  if (isFilterMenu) {
    resultsCount = yield select(getSearchCount);
  } else {
    // Checking the current route since each route has their own search and listings objects, and thus
    // they're own totalItems count. Once the List/Map Sync feature is completely implemented and
    // here to stay, we can move towards a centralized `search` branch of our state tree for both
    // List and Map views, then we'll only need one selector here.
    const currentRoute = yield select(getRouteID);
    switch (currentRoute) {
      case RESULTS_MAP:
        resultsCount = yield select(getTotalItemsForMap);
        break;
      case RESULTS_GALLERY:
      default:
        resultsCount = yield select(getTotalItemsForGallery);
        break;
    }
  }


  yield put(ssActions.receiveCountFromState(resultsCount));
}

/**
 * Use the `squeezeForm()` method to decide whether or not to show the reg.
 * modal.
 */
function* squeezeUnregisteredUsers() {
  if (visitor.isRegistered()) {
    return;
  }

  // If the user is on a pending search we should stash it for postregistration
  const isSearching = yield select(isSearchMenuOpen);
  if (isSearching) {
    const search = yield select(getPendingSearch);
    yield call([StoredPendingSearch, 'set'], search);
  } else {
    // Otherwise we want to ensure that it is removed now
    yield call([StoredPendingSearch, 'clear']);
  }

  // Proceed to squeeze them
  const formType = savedSearchesData.urlParam;
  const showCloseBtn = true;
  const switcherOnSignIn = false;
  yield call([app, app.squeezeForm], formType, showCloseBtn, switcherOnSignIn);
}

/**
 * Removes the post registration query param from the url
 */
function* removePostRegQueryParam() {
  const { [config.conversionQueryStringName]: _removed, ...restQuery } = yield select(
    state => state.router.query
  );

  if (_removed) {
    // It is important to replace state because the quick search close is calling goBack
    yield put(replace({ query: restQuery }));
  }
}

/**
 * We are either going to autoDismiss the toast or the user will click the
 * close button
 * @param {{payload: object}} param0
 */
function* raceSuccessDismissal({ payload }) {
  if (payload.response === 'success') {
    yield race([
      take(ssActions.clickSaveSearchSuccessButton),
      call(function* autoDismissToast() {
        yield call(delay, 3000);
        yield put(ssActions.autoDismiss());
      }),
    ]);
  }
}

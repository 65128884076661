import Listing from 'legacy/Model/listing';
import { getMap } from 'screens/ResultsMap/reducer';
import {
  getCurrentListingID,
  getActiveListing,
} from 'selectors/listings';
import { getGalleryListings } from 'selectors/mobileGallery';

export const isMapModalOpen = state => state.mobileDetailsPage.isMapModalOpen;

/**
 * Get the next N listings following the one with the given listing ID in the
 * mobileGallery state tree.
 * @param {obj} state
 */
export const getListingsForGallery = state => {
  const listingID = getCurrentListingID(state);
  const numberOfResults = 5;
  const listings = getGalleryListings(state);
  const currentListingIndex = listings.map(i => i._ID).indexOf(listingID);

  if (currentListingIndex === -1) {
    // Should be invariant, but just return []
    return [];
  }

  return listings.slice(currentListingIndex + 1, currentListingIndex + 1 + numberOfResults);
};

/**
 * Get the next N listings in closest proximity (on an (x, y) coordinate plane)
 * to the one with the given listing ID in the resultsMap state
 * tree.
 * @param {*} state
 */
export const getListingsForMap = state => {
  const listingID = getCurrentListingID(state);
  const map = getMap(state);
  const { pins } = map;
  const activeListing = pins.find(p => p.listingID === listingID);

  const coordPairs = pins.map(p => p.coords); // [{ x: 1, y: 2 }, {...}, ...]
  // use the pythagorean theorem to solve for the distance between points
  // d = √((x1 - x2)^2 + (y1 - y2)^2)
  const distanceVals = coordPairs.map(p =>
    Math.sqrt(Math.pow(activeListing.coords.x - p.x, 2) + Math.pow(activeListing.coords.y - p.y, 2))
  );

  const nearestListingIndexes = [...distanceVals]
    .sort((a, b) => a - b) // sort from smallest to largest
    .slice(1, 6) // the first index will always be 0 (the active listing), so slice the next 5 listings
    .map(d => distanceVals.indexOf(d));

  // swap out index values for the corresponding ids
  const nearestListingIDs = nearestListingIndexes.map(i => pins.map(p => p.listingID)[i]);
  // filter through the available set of listings and return those with matching ids
  const listings = nearestListingIDs.map(_ID => map.listings.filter(l => l._ID === _ID)[0]);
  // beef up the results
  return listings.map(l => new Listing(l).beefUp());
};

export const getContinuedResults = state => state.mobileDetailsPage.continuedSearchResults;
export const getRelatedListings = state => state.mobileDetailsPage.relatedListings;

// CNS-5564 Checks to see if the Sash and Favorite Button are hidden
export const isSliderUIHidden = state => state.mobileDetailsPage.hideSliderUI;

/**
 * Get the current listing from the `mobileDetailsPage` branch of the global state tree
 *
 * @param {Object} state The global Redux State object
 * @returns {Object} A full Listing object
 */
export const getListingFromStore = state => state.mobileDetailsPage.listing;

/**
 * State as required by the MobileDetails screen
 *
 * @param {Object} state Root of the state tree
 * @param {Backbone.Model} agentModel
 * @param {Number[]} favoriteIDs
 */
export const getMobileDetailsState = (state, agentModel, favoriteIDs) => {
  // Note that `listing` can be null if we haven't fetched it yet.
  const { listing } = getActiveListing(state);
  // Fetched using the current search parameters
  const continuedSearchResults = getContinuedResults(state);
  // Fetched when a user navigates directly to a property details page
  const relatedListings = getRelatedListings(state);
  const remainingSearchResults =
    continuedSearchResults.length > 0 ? continuedSearchResults : relatedListings;

  // We need to set a prop at this higher level so we can render the LIVE tag beside the Sash.
  const hasLiveVirtualOpenHouse = (
    listing && listing.OpenHouses && (listing.OpenHouses.findIndex(oh => oh.isLive === true) !== -1)
  );

  return {
    listing: listing && {
      ...listing,
      // Note the uppercase `IsFavorite` to match the Listing interface
      IsFavorite: favoriteIDs.includes(listing._ID),
    },
    remainingSearchResults,
    isMapModalOpen: isMapModalOpen(state),
    isStartAnOfferModalOpen: state.startAnOffer.isOpen,
    isPaymentSectionOpen: state.mobileDetailsPage.isPaymentSectionOpen,
    lastScrollY: state.mobileDetailsPage.scrollY,
    isContactAgentSectionOpen: state.mobileDetailsPage.isContactAgentSectionOpen,
    hideSliderUI: state.mobileDetailsPage.hideSliderUI,
    hasLiveVirtualOpenHouse
  };
};

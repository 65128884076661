import { take, takeEvery, call, put, select, race } from 'redux-saga/effects';
import { push } from 'redux-little-router';
import { search as searchModel } from 'BoomTown';
import { RESULTS_GALLERY, RESULTS_MAP } from 'routes';

// Actions
import { receiveError } from 'services/geolocation/actions';
import {
  selectNearbySearch as offCanvasSelectNearbySearch,
  applyAndClose,
} from 'actions/OffCanvasActions';
import { selectNearbySearch as ballerboxSelectNearbySearch } from 'components/BallerBox/actions';
import { applySearch } from 'screens/MobileSearchMenu/actions';

// Selectors
import { isMobile } from 'selectors/browser';
import { getRouterState } from 'selectors/router';
import { getIsOpen } from 'reducers/OffCanvasSearch/menu';

// Tasks
import { beginWatchingLocation } from 'services/geolocation/effects';
import { getCoords } from 'screens/ResultsMap/Map/mapSaga';

// Keeping these 2 actions separate for now until we consolidate our search menu/ballerbox
// components and actions.
export default [
  takeEvery([
    offCanvasSelectNearbySearch,
    ballerboxSelectNearbySearch
  ], handleSelectNearbySearch),
];

/**
 * Task used to trigger the "Nearby" experience on the Map view.
 */
export function* handleSelectNearbySearch() {
  // Start location watcher. It won't do anything if it's already running.
  yield call(beginWatchingLocation);

  // Need to handle the error state
  const { success, error } = yield race({
    success: call(getCoords),
    error: take(receiveError),
  });

  if (success) {
    const routerState = yield select(getRouterState);

    if (routerState && routerState.result) {
      // If a results route, include the existing query on the map route change. If not, use the
      // default search parameters.
      const query = [
        RESULTS_GALLERY,
        RESULTS_MAP
      ].includes(routerState.result.id) ? routerState.query : searchModel.defaults;

      if (routerState.result.id !== RESULTS_MAP) {
        yield put(push({ pathname: `/${RESULTS_MAP}/`, query }));
      }
    }

    // On Mobile, the ballerbox is on the FilterMenu view where there may be a `pending` search obj
    // that also needs to be applied. We know the filter menu is open if the current `query`
    // includes the `search` key. Dispatching the `applySearch` action also handles closing the
    // Filter Menu UI.
    const isMobileSite = yield select(isMobile);
    if (isMobileSite && Object.keys(routerState.query).includes('search')) {
      yield put(applySearch());
    } else {
      // :sigh: legacy OffCanvas Search is still a thing. Handle applying pending search and closing
      // the menu when we initially get the user's coordinates.
      const isOffCanvasOpen = yield select(getIsOpen);
      if (isOffCanvasOpen) {
        yield put(applyAndClose());
      }
    }
  } else {
    // Temp: Log an error to the console if location request failed
    // eslint-disable-next-line
    console.error('Error fetching location', error.payload);
  }
}

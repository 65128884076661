import PropTypes from 'prop-types';
import React from 'react';
import { isMapPath, isResultsPath } from 'legacy/Routing/routerUtils';
import MobileResultsNavBar from './MobileResultsNavBar';
import MobileResultsTitle from './MobileResultsTitle';

/**
 * This component contains:
 * - The button row containing the "Search" button that triggers the off
 *   canvas search, the search results view toggle, and the sort button
 *   dropdown (for list view)
 * - A row displaying the current search count. Since the contents of
 *   this row will probably be view-dependent, it might make sense to include
 *   it as part of the individual mobile results view component.
 */
export default function MobileResultsHeader(props) {
  // Only render the header when the user is on a map route.
  if (!isResultsPath(props.route)) {
    return null;
  }

  const resultsView = props.route === 'results-map' ? 'map' : 'list';
  const {
    actions,
    currentSort,
    showDistanceSort,
    showingFavorites,
    title
  } = props;

  return (
    <div>
      <MobileResultsNavBar
        resultsView={resultsView}
        currentSort={currentSort}
        showingFavorites={showingFavorites}
        showDistanceSort={showDistanceSort}
        {...actions}
      />
      {isMapPath(props.route) && <MobileResultsTitle title={title} />}
    </div>
  );
}

MobileResultsHeader.displayName = 'MobileResultsHeader';
MobileResultsHeader.propTypes = {
  actions: PropTypes.object,
  currentSort: PropTypes.string,
  route: PropTypes.string,
  showDistanceSort: PropTypes.bool,
  showingFavorites: PropTypes.bool,
  title: PropTypes.string
};

/* eslint-disable no-console */
/**
 * Event Bus for sending messages and data from the Consumer JS Site and the iOS/Android Native
 * Apps. Call this function from any redux-saga task that needs to communicate with the native
 * application.
 *
 * @todo We may have to wrap this in a Promise at some point to ensure the message is received and
 * handled correctly by the native app before resuming execution of the generator fn that called
 * this.
 *
 * @param {AppMessage} msgObj
 */
export default function postAppMessage(msgObj) {
  // Don't attempt to send message if our data is bad
  if (typeof msgObj === 'undefined' || typeof msgObj.type !== 'string') {
    throw new TypeError('Incorrect parameter type passed to `postAppMessage`');
  }

  // TODO: Remove before prod release
  console.log('Event: ', msgObj);

  try {
    // Stringify our msgObj so it plays nicely between the two OS's API's
    const msg = JSON.stringify(msgObj);

    // iOS
    if (window.webkit !== undefined) {
      if (window.webkit.messageHandlers.iOSInterface !== undefined) {
        /* JavascriptInterface defined by the the iOS App */
        window.webkit.messageHandlers.iOSInterface.postMessage(msg);
        return;
      }
    }

    // Android
    if (window.androidInterface !== undefined) {
      /* JavascriptInterface defined by the the Android App */
      window.androidInterface.messageFromWeb(msg);
      return;
    }
  } catch (e) {
    console.error(e);
  }
}

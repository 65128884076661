import { compose } from 'recompose';
import { connect } from 'react-redux';
import { connectBBHoc } from 'hoc/connectBB';
import { visitor as visitorModel, agent as agentModel, listings as listingsColl } from 'BoomTown';
import { getContactAgentState } from './reducer';

const visitorGetNotDefault = name => {
  if (name !== ' ' && name !== 'Guest Visitor') {
    return name;
  }
  return '';
};

/**
 * Map the visitor model to props to be injected
 * @param {Backbone.Model} model
 */
const mapVisitorModelToProps = model => ({
  visitorEmail: model.get('Username'),
  visitorName: visitorGetNotDefault(model.fullName()),
  visitorPhone: model.get('BestPhone'),
  isRegistered: model.isRegistered(),
});

// in usage `withData({click: a.click })(Component)`
const withData = actions => compose(
  connectBBHoc(
    // listners
    [
      {
        getEmitter: () => visitorModel,
        events: [
          {
            name: 'change',
            replaceProps: (prevProps, model) => mapVisitorModelToProps(model),
          },
        ],
      },
    ],
    // initial state
    () => ({ ...mapVisitorModelToProps(visitorModel) })
  ),

  /**
   * Note that this component tree is not updated when either the agent model
   * or listings collection update on their own. This is just a place to
   * inject ref's to these stores.
   */
  connect(
    state => getContactAgentState(state, agentModel, listingsColl),
    actions
  )
);

export default withData;

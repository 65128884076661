import React from 'react';
import PropTypes from 'prop-types';
import { config } from 'BoomTown';
import tryParseInt from 'utility/tryParseInt';
import { MIN_BATHS_OPTIONS } from 'models/search/constants';
import { getMinBaths } from 'selectors/searchSelectors';
import { valuesToArrays, getSaleTypeCheckboxes } from 'reducers/saleTypes';

import BallerBox from 'components/BallerBox';
import { Grid, Cell } from 'components/core/Grid';
import ButtonGroup from 'components/core/Buttons/ButtonGroup';
import SubMenu from 'components/SearchMenu/SubMenu';
import TypeMenu from 'components/SearchMenu/TypeMenu';
import { SEARCH_BAR } from 'cypress_constants';
import PriceRange from './PriceRange';
import RadioDropdown from './RadioDropdown';

const SearchMenu = (props) => {
  const {
    accountRules,
    search,
    searchFields,
    specialRules,
    onPriceChange,
    onSelectMenuChange,
    onSelectMinBaths,
    onSelectMinBeds,
    onToggleFeature,
    onTogglePhoto,
    onToggleProptype,
    onToggleSaleType,
    onToggleVirtualTours,
  } = props;

  return (
    <Grid
      guttersSmall
      justifyContent="end"
      className="bt-filter-bar__components"
      label="Properties Search Toolbar"
    >
      <Cell md={12} xl="fit">
        <BallerBox />
      </Cell>

      <Cell xs="none">
        <ButtonGroup className="ml-0 bt-search__options-wrapper" label="Properties Search Filters">
          <PriceRange
            min={tryParseInt(search.minprice, 0)}
            max={tryParseInt(search.maxprice, Infinity)}
            buttonClasses="btn-group__item-btn btn-group__item-btn--first"
            onChange={onPriceChange}
          />

          {/**
           * Note that here we are passing the same state in two different ways: in
           * the URL form (arrays of IDs) as well as an object of checkbox states
           * (`saleTypes`).
           */}
          <TypeMenu
            buttonClasses="btn-group__item-btn"
            searchFields={searchFields}
            search={searchFields}
            selectedProptypes={(search.proptype || '').split(',').filter((x) => x !== '')}
            selectedStatuses={(search.status || '').split(',').filter((x) => x !== '')}
            saleTypes={getSaleTypeCheckboxes(
              valuesToArrays(search, ['status', 'featureor', 'featurenot']),
              specialRules,
              config.allowSoldData
            )}
            specialRules={specialRules}
            allowSoldData={config.allowSoldData}
            onToggleSaleType={onToggleSaleType}
            onTogglePropertyType={onToggleProptype}
          />

          <RadioDropdown
            className="btn-group__item-btn"
            id="minbeds"
            title="Beds"
            dataCYButton={SEARCH_BAR.BEDS_BUTTON}
            dataCYOptions={SEARCH_BAR.BEDS_OPTIONS}
            value={search.minbeds}
            onSelect={onSelectMinBeds}
            options={[0, 1, 2, 3, 4, 5]}
          />

          <RadioDropdown
            className="btn-group__item-btn"
            id="minbaths"
            title="Baths"
            dataCYButton={SEARCH_BAR.BATHS_BUTTON}
            dataCYOptions={SEARCH_BAR.BATHS_OPTIONS}
            value={getMinBaths(search)}
            onSelect={onSelectMinBaths}
            options={
              specialRules.ShowHalfBaths
                ? MIN_BATHS_OPTIONS.W_HALF_BATHS
                : MIN_BATHS_OPTIONS.WO_HALF_BATHS
            }
          />

          <SubMenu
            searchFields={searchFields}
            search={search}
            {...specialRules}
            ShowOpenHouse={accountRules.showOpenHouse}
            onTogglePhoto={onTogglePhoto}
            onToggleVirtualTours={onToggleVirtualTours}
            onToggleFeature={onToggleFeature}
            onSelectMenuChange={onSelectMenuChange}
          />
        </ButtonGroup>
      </Cell>
    </Grid>
  );
};

SearchMenu.displayName = 'SearchMenu';

SearchMenu.propTypes = {
  // Passed in from `account` branch in our global state
  accountRules: PropTypes.shape({
    isCanadian: PropTypes.bool,
    showNeighborhoodSearch: PropTypes.bool,
    showSchoolSearch: PropTypes.bool,
    showSchoolDistrictSearch: PropTypes.bool,
    showListingAreaOnPS: PropTypes.bool,
    showCountyOnPS: PropTypes.bool,
    showOpenHouse: PropTypes.bool,
  }).isRequired,

  // The attributes of the search model
  search: PropTypes.object.isRequired,

  searchFields: PropTypes.object.isRequired,

  specialRules: PropTypes.shape({
    HideForeclosure: PropTypes.bool.isRequired,
    HideShortsale: PropTypes.bool.isRequired,
    ReplaceUnderContract: PropTypes.string.isRequired,
    ShowActiveContingent: PropTypes.bool.isRequired,
    ShowAcresSearch: PropTypes.bool.isRequired,
    ShowCounty: PropTypes.bool.isRequired,
    ShowDaysListed: PropTypes.bool.isRequired,
    ShowGarages: PropTypes.bool.isRequired,
    ShowHalfBaths: PropTypes.bool.isRequired,
    ShowPriceReduced: PropTypes.bool.isRequired,
    ShowSQFT: PropTypes.bool.isRequired,
    ShowStories: PropTypes.bool.isRequired,
    ShowYearBuilt: PropTypes.bool.isRequired,
    ShowSchoolInfo: PropTypes.bool.isRequired,
  }),

  onPriceChange: PropTypes.func.isRequired,
  onSelectMenuChange: PropTypes.func.isRequired,
  onSelectMinBaths: PropTypes.func.isRequired,
  onSelectMinBeds: PropTypes.func.isRequired,
  onToggleFeature: PropTypes.func.isRequired,
  onTogglePhoto: PropTypes.func.isRequired,
  onToggleProptype: PropTypes.func.isRequired,
  onToggleSaleType: PropTypes.func.isRequired,
  onToggleVirtualTours: PropTypes.func.isRequired,
};

export default SearchMenu;

/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-act';

import { visitor } from 'BoomTown';

export const clickSaveSearchCard = createAction(
  'Desktop Gallery > Click save search card',
  () => {},
  () => ({ visitor })
);
export const clickSaveSearchInNoResults = createAction(
  'Desktop Gallery > Click save search text in no results',
  () => {},
  () => ({ visitor })
);

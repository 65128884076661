/* eslint-disable no-confusing-arrow, camelcase, indent */
import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';
import { receiveSavedSearches } from 'actions/visitorActions';
import { receiveCreateSavedSearchRes } from 'actions/MobileSaveSearchActions';
import * as a from './actions';
import { toastText } from './constants';

// TODO: This must be in sync with `state.visitor.savedSearches`. Might be best
// to make this computed on availability of data.
const isLoading = createReducer(
  {
    [a.waitForSavedSearches]: () => true,
    [receiveSavedSearches]: () => false,
    [receiveCreateSavedSearchRes]: () => true,
  },

  // I'd argue that this should be `true`, since we're depending on the
  // `waitForSavedSearches` action to be dispatched before any data is attempted
  // to be read.
  false
);

const defaultToastState = {
  status: '',
  isVisible: false,
  message: '',
};
const toast = createReducer(
  {
    [a.changeSavedSearchFrequencyRequest]: state => ({
      ...state,
      status: 'processing',
      isVisible: true,
      message: toastText.inProgress,
    }),
    [a.deleteSavedSearchRequest]: state => ({
      ...state,
      status: 'processing',
      isVisible: true,
      message: toastText.inProgress,
    }),
    [a.receiveAPIChangeResp]: showMessageOnResponse,
    [a.receiveDeleteSavedSearchResponse]: showMessageOnResponse,
    [a.closeToast]: () => defaultToastState,
  },
  defaultToastState
);

function showMessageOnResponse(state, { response }) {
  return {
    ...state,
    status: response,
    isVisible: true,
    message: response === 'success' ? toastText.success : toastText.failure,
  };
}

export default combineReducers({
  isLoading,
  toast,
});

export const _getResponseToastState = state => state.toast;
export const _isSavedSearchesLoading = state => state.isLoading;

const select = selector => state => selector(state.accountView.savedSearches);

export const getResponseToastState = select(_getResponseToastState);
export const isSavedSearchesLoading = select(_isSavedSearchesLoading);

/* eslint-disable wrap-iife */
// TODO: Remove all code that just exposes stuff on namespaced objects.
// This should be required in `run`
import Cookies from 'js-cookie';
import $ from 'jquery';

// [aknox] i double dog dare you to understand this
// Determine browser and version
navigator.whoami = (function doit() {
  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return `IE ${tem[1] || ''}`;
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem !== null) {
      return `Opera ${tem[1]}`;
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  /* eslint-disable no-cond-assign */
  if ((tem = ua.match(/version\/(\d+)/i)) !== null) {
    M.splice(1, 1, tem[1]);
  }
  return M.join('/');
})();

// Cookies.set === Cookies.get; the fn inspects the `arguments` object.
$.cookie = Cookies.set;

window.bt.mapstyle = {};
window.bt.mapstyle.default = [
  { featureType: 'water', stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: '#0088ff' }] },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [{ hue: '#ff0000' }, { saturation: -100 }, { lightness: 99 }]
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#808080' }, { lightness: 54 }]
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [{ color: '#ece2d9' }]
  },
  { featureType: 'poi.park', elementType: 'geometry.fill', stylers: [{ color: '#ccdca1' }] },
  { featureType: 'road', elementType: 'labels.text.fill', stylers: [{ color: '#767676' }] },
  { featureType: 'road', elementType: 'labels.text.stroke', stylers: [{ color: '#ffffff' }] },
  { featureType: 'poi', stylers: [{ visibility: 'off' }] },
  {
    featureType: 'landscape.natural',
    elementType: 'geometry.fill',
    stylers: [{ visibility: 'on' }, { color: '#b8cb93' }]
  },
  { featureType: 'poi.park', stylers: [{ visibility: 'on' }] },
  { featureType: 'poi.sports_complex', stylers: [{ visibility: 'on' }] },
  { featureType: 'poi.medical', stylers: [{ visibility: 'on' }] },
  { featureType: 'poi.business', stylers: [{ visibility: 'simplified' }] }
];
window.bt.mapstyle.noStreetNames = [
  { featureType: 'water', stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: '#0088ff' }] },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [{ hue: '#ff0000' }, { saturation: -100 }, { lightness: 99 }]
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#808080' }, { lightness: 54 }]
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [{ color: '#ece2d9' }]
  },
  { featureType: 'poi.park', elementType: 'geometry.fill', stylers: [{ color: '#ccdca1' }] },
  { featureType: 'road', elementType: 'labels.text.fill', stylers: [{ color: '#767676' }] },
  { elementType: 'labels', stylers: [{ visibility: 'off' }] },
  { featureType: 'road', elementType: 'labels.text.stroke', stylers: [{ color: '#ffffff' }] },
  { featureType: 'poi', stylers: [{ visibility: 'off' }] },
  {
    featureType: 'landscape.natural',
    elementType: 'geometry.fill',
    stylers: [{ visibility: 'on' }, { color: '#b8cb93' }]
  },
  { featureType: 'poi.park', stylers: [{ visibility: 'on' }] },
  { featureType: 'poi.sports_complex', stylers: [{ visibility: 'on' }] },
  { featureType: 'poi.medical', stylers: [{ visibility: 'on' }] },
  { featureType: 'poi.business', stylers: [{ visibility: 'simplified' }] }
];
window.bt.mapstyle.primaryPinColor = '#ef8027';
window.bt.mapstyle.hoverPinColor = '#00a1dc';

// [aknox] Don't feel comfortable es6ifying this one
/* eslint-disable no-extend-native, prefer-rest-params, prefer-arrow-callback */
String.prototype.format = function format() {
  const args = arguments;
  return this.replace(/{(\d+)}/g, function matcher(match, number) {
    if (typeof args[number] !== 'undefined') {
      return args[number];
    }
    return match;
  });
};

/**
 * Parse a string as an integer. If the parse fails, return the default
 * value instead.
 *
 * @param {String} s
 * @param {Number} def A fallback value
 */
export default function tryParseInt(s, def) {
  if (s) {
    const n = parseInt(s, 10);
    if (!Number.isNaN(n)) {
      return n;
    }
  }
  return def;
}

import React, { useState, useEffect } from 'react';
import bt from 'BoomTown';
import {
  Input,
  PrimaryButton,
  RequiredText,
  LabelGroup,
  InputLabel,
} from 'coreComponents';

const ResetPasswordForm = () => {
  const [passwd, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [matchesConfirmation, setMatchesConfirmation] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const visitor = bt.visitor.attributes;

  useEffect(() => {
    handleErrors();
  }, [isValid, matchesConfirmation, passwd, confirmation]);

  /**
  * Validate Password
  * @param {string} unvalidatedPassword
  * @return bool
  */
  const validatePassword = (unvalidatedPassword) => {
    const alpha = new RegExp(/[A-Za-z]/);
    const numeric = new RegExp(/[0-9]/);
    const whitespace = new RegExp(/\s/);

    if (unvalidatedPassword.length < 8) {
      return false;
    }

    if (unvalidatedPassword.length > 20) {
      return false;
    }

    if (!alpha.test(unvalidatedPassword)) {
      return false;
    }

    if (!numeric.test(unvalidatedPassword)) {
      return false;
    }

    if (whitespace.test(unvalidatedPassword)) {
      return false;
    }

    return true;
  };

  /**
  * Update error state of form
  * @return object
  */
  const handleErrors = () => {
    const errorCollection = {};
    if (!isValid && passwd) {
      errorCollection.password = { message: 'Your new password must contain letters and numbers and be at least 8 characters in length' };
    }
    if (!matchesConfirmation && confirmation) {
      errorCollection.confirmation = { message: 'Passwords do not match' };
    }
    setErrors(errorCollection);
  };

  /**
  * Update state based on form input
  * @param {object} e - event
  * @param {string} name - name, used to handle different input changes within single function
  */
  const handleOnChange = (e, name) => {
    if (name === 'password') {
      setPassword(e.target.value);
      setIsValid(validatePassword(e.target.value));
      setMatchesConfirmation(e.target.value === confirmation);
    } else if (name === 'confirmation') {
      setConfirmation(e.target.value);
      setMatchesConfirmation(e.target.value === passwd);
    }
  };

  /**
  * Handle form submission
  * @param {object} e - event
  * @return object
  */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid && matchesConfirmation) {
      setIsSubmitting(true);
      submitPassword();
    }
  };

  /**
  * Handle submitting new password to the API
  * @return array
  */
  const submitPassword = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const formData = new FormData();

    formData.append('passwd', passwd);
    formData.append('token', token);

    const response = await fetch('/index.php?action=password&method=reset', {
      method: 'POST',
      body: formData,
    });

    setIsSubmitting(false);

    if (response.status !== 200) {
      return [];
    }

    const data = await response.json();

    if (!data.success) {
      setIsValid(false);
    } else {
      window.location = '/'; // password has been updated
    }

    return data.result;
  };

  return (
    <div>
      <div className="bt-page-header">
        <div className="grid grid--justifyCenter">
          <div className="cell cell-xs-12 cell-md-6">
            <div className="card card__body">
              <h1 className="bt-heading__h1 text-xs--center mb-4">Reset Password</h1>
              <div className="text-xs--center mb-16">
                Please enter a new password for {visitor.Username}
              </div>
              <form id="bt-password-reset-form" onSubmit={handleSubmit}>
                <LabelGroup>
                  <InputLabel id="passwdLabel">
                    New Password
                  </InputLabel>
                  <RequiredText requiredText="*" />
                </LabelGroup>
                <Input
                  type="password"
                  name="passwd"
                  value={passwd}
                  onChange={(e) => handleOnChange(e, 'password')}
                  error={errors.password}
                  maxLength={20}
                  required
                />
                <div className="bt-text--italic gray-base font-size--12 mb-16">Your new password must contain letters and numbers and be at least 8 characters in length</div>
                <LabelGroup>
                  <InputLabel id="passwdConfirmationLabel">
                    Confirm New Password
                  </InputLabel>
                  <RequiredText requiredText="*" />
                </LabelGroup>
                <Input
                  name="passwdConfirmation"
                  value={confirmation}
                  onChange={(e) => handleOnChange(e, 'confirmation')}
                  type="password"
                  error={errors.confirmation}
                  required
                />
                <div className="grid grid--gutters grid--gutters--reset-vertical grid-xs--full grid-md--halves grid--justifyCenter">
                  <div className="cell my-8">
                    <PrimaryButton
                      name="submit"
                      type="submit"
                      showSpinner={isSubmitting}
                      width="full"
                      color="red"
                      disabled={!isValid || !matchesConfirmation}
                    >
                      Submit
                    </PrimaryButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;

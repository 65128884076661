/* eslint-disable no-new */
import $ from 'jquery';
import _ from 'underscore';
import UIkit from 'uikit';
import bt from 'BoomTown';
import BaseModel from 'legacy/Base/model';
import EulaModalView from 'legacy/Views/Modals/eula';

export default class SpecialRules extends BaseModel {
  initialize() {
    super.initialize();
    this.set(window.bt_data.specialRules);
    this.addMaxGalleryResults();

    document.addEventListener('DOMContentLoaded', this.eula);
  }

  /**
   * We are only allowed to show 100 results at one time
   * This needs to be added to our API as a new rule
   * But will be injected here for the time being until the API can be updated
   * MLS violation from HHIML (bid:16)
   * @see CNS-936
   */
  addMaxGalleryResults() {
    const boardIds = _.keys(bt.config.boards);
    if (_.indexOf(boardIds, '16') > -1) {
      this.set({ MaxGalleryResults: 100 });
    }
  }

  /**
   * The site must obtain the consumer’s assent by means of the consumer’s affirmative act
   * before the consumer views any portion of the Broker Reciprocity Database
   * Compliance violation notification from Northstar MLS (bid=36)
   * Site visitors must accept the Northstar End-User License Agreement before being able to see any IDX data
   * @see CNS-966
   */
  eula() {
    if (bt.visitor.needsToRegister() && $.cookie('NorthstarEULA') !== '1') {
      const boardIds = _.keys(bt.config.boards);
      if (_.indexOf(boardIds, '36') > -1) {
        new EulaModalView();
        bt.utility.hide($('#eula .uk-close'));

        const modal = UIkit.modal('#eula');
        modal.options.bgclose = false;
        modal.options.keyboard = false;

        bt.events.trigger('eula');
      }
    }
  }
}

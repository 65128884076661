import { createAction } from 'redux-act';

export const onListingCardClick = createAction(
  'Listing Card > Click',
  /**
   * Uses the UrlPath from the listing to route to the property page. Grabs the `window.scrollY`
   * value from the window that is set by our `scrollPositionMiddleware()`. The scroll position is
   * used to maintain the correct sroll position on the gallery when returning from client-side
   * navigations. The sourcePath is used by our reducers to determine whether or not scrollY should
   * be stored for that route.
   *
   * @param {string}
   * @returns {{urlPath: string, id: number, scrollY: number, sourcePath: string}}
   */
  (urlPath, id) => ({ urlPath, id, scrollY: window.scrollY, sourcePath: window.location.pathname })
);

export const onListingCardFavoriteClick = createAction(
  'Listing Card > Favorite Button Click',
  /**
   * @param {number} id The _ID of the listing to add or remove from the visitor's Favorites
   * @returns {number}
   */
  (id) => id
);

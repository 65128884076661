/* eslint-disable import/prefer-default-export */
import { push } from 'redux-little-router';
import { select, call, put } from 'redux-saga/effects';
import { getLastSearch, getPreviousRouteID } from 'reducers/lastSearch';
import { utility, search } from 'BoomTown';
import { COMPARE, FAVORITES, HOME } from 'routes';
import { go } from 'sagas/routerTasks/search';

/**
 * Navigate the user to the last results view in context.
 *
 * @param {Number} listingID
 */
export function* goToLastResults(listingID = 0) {
  const { lastSearchRoute, lastSearchState } = yield select(getLastSearch);
  const previousRouteID = yield select(getPreviousRouteID);
  const theme = utility.currentTheme();
  const favsOrCompare = [FAVORITES, COMPARE].includes(lastSearchRoute);

  // 'home' isn't a canonical search route, so we can't rely on lastSearchRoute to
  // as a source for condition for pushing back to the homepage or results map.
  // We can, however, observe that the previous route ID matches `home` and that we're
  // on the scout theme, so we can use those properties to determine whether or
  // not to return to the results map.
  if (previousRouteID === HOME && theme === 'wp-scout-theme') {
    yield put(
      push({
        pathname: '/results-map/',
      })
    );
  } else if (favsOrCompare) {
    yield put(
      push({
        pathname: `/${lastSearchRoute}/`,
        // We don't update the search model's state on these route changes, they
        // should just be different things.
        query: lastSearchState || {},
      })
    );
  } else {
    const pathname = lastSearchRoute != null ? `/${lastSearchRoute}/` : '/results-gallery/';
    const query = yield call([search, search.toJSON]);
    yield go({
      pathname,
      query,
      hash: listingID !== 0 ? `listings-card-${listingID}` : '',
    });
  }
}

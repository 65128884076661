import connectBB from 'hoc/connectBB';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { search, listings } from 'BoomTown';

import * as GalleryActions from 'actions/GalleryActions';
import { getTitleWithCount } from 'selectors/resultsTitle';
import MobileResultsHeader from './MobileResultsHeader';

const getTitle = () => getTitleWithCount(listings, search);

const backboneDecorator = Component =>
  connectBB(
    Component,
    [
      {
        getEmitter: () => search,
        events: [
          {
            name: 'change',
            replaceProps: (props, searchModel) => ({
              currentSort: searchModel.get('sort'),
              showingFavorites: searchModel.has('favs'),
              showDistanceSort: searchModel.has('latlonrad'),
              title: getTitle(),
            }),
          },
        ],
      },
      {
        getEmitter: () => listings,
        events: [
          {
            name: 'reset',
            replaceProps: () => ({
              title: getTitle(),
            }),
          },
          {
            name: 'isLoading',
            replaceProps: (props, isLoading) => (isLoading ? { title: '' } : {}),
          },
        ],
      },
    ],
    () => ({
      title: getTitle(),
      showingFavorites: search.has('favs'),
      currentSort: search.get('sort'),
      showDistanceSort: search.has('latlonrad'),
    })
  );

export default compose(
  backboneDecorator,
  connect(
    state => ({
      route: state.router.result ? state.router.result.id : null,
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          onClickSearch: GalleryActions.clickSearch,
          onClickNewSearch: GalleryActions.clickNewSearch,
          onSelectSort: GalleryActions.selectSort,
        },
        dispatch
      ),
    })
  )
)(MobileResultsHeader);

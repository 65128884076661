import { createAction } from 'redux-act';

const action = (title, ...args) => createAction(`Home Val > ${title}`, ...args);

export const onHomeValSearchSubmit = action('Search Submit');
export const onHomeValRequest = action('Send Request');

export const onSuccessfulHomeValRequest = action('Request Successful');
export const onFailedHomeValRequest = action('Request Failed');
export const onGeocodeError = action('Geocode Failed');

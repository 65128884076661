import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'redux-little-router';
import { favorites } from 'BoomTown';
import { onListingCardClick, onListingCardFavoriteClick } from '../actions';

export default [
  takeEvery(onListingCardClick, handleListingCardClick),
  takeEvery(onListingCardFavoriteClick, handleFavoriteClick)
];

/**
 * When a listing card is clicked, route the user to the details page.
 *
 * @param {Object} action
 * @param {{ urlPath: string }} action.payload
 */
export function* handleListingCardClick(action) {
  const { payload: { urlPath } } = action;

  if (urlPath && typeof urlPath === 'string') {
    const url = urlPath.startsWith('//') ? urlPath.substring(1) : urlPath;
    yield put(push(url));
  } else {
    // eslint-disable-next-line no-console
    console.error('`onListingCardClick`: Invalid payload value or type: ', action.payload);
  }
}

/**
 * Handles toggling the IsFavorite state of a listing map card
 *
 * @todo Deprecate when favorites collection is in redux, this is only a saga because
 * we are causing an effect on a different "store"
 *
 * @param {Object} action
 * @param {number} action.payload id - A listing ID
 */
export function handleFavoriteClick({ payload: id }) {
  favorites.toggle(id);
}

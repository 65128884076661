import { createAction, createReducer } from 'redux-act';

export const receiveHtml = createAction('Receive cached HTML', (id, html) => ({ id, html }));

export default createReducer({
  [receiveHtml]: (state, { html, id }) => ({
    ...state,
    [id]: html
  })
}, {});

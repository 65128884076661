export const registerData = {
  title: 'Personalize Your Home Search',
  subtitle: '',
  urlParam: 'propform',
};

export const favoritesData = {
  title: 'Do you love this property?',
  subtitle: 'Register to easily save & compare your favorite properties',
  urlParam: 'favform',
};

export const favoritesViewData = {
  title: 'Sign in to view your favorites.',
  subtitle: 'Register to easily save & compare your favorite properties',
  urlParam: 'favviewform',
};

export const savedSearchesData = {
  title: 'Free Account Activation',
  subtitle: 'Free and complete access in 20 seconds',
  urlParam: 'ssform',
};

export const photoSliderData = {
  title: 'Personalize Your Home Search',
  subtitle: '',
  urlParam: 'photoform',
};

export const signinFormData = {
  title: 'Want to Compare Homes?',
  subtitle: '',
  urlParam: 'signinform',
};

export const regFormDisplayData = {
  title: 'Want to Compare Homes?',
  subtitle: '',
  urlParam: 'displayform',
};

export const shareListingData = {
  title: 'Sharing with a friend?',
  subtitle: 'Create an account for unlimited sharing',
  urlParam: 'shareform',
};

export const openHouseVirtualData = {
  title: 'Attend this Virtual Open House',
  subtitle: 'Free and complete access in 20 seconds',
  urlParam: 'virtualopenhouseform',
};

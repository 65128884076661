import $ from 'jquery';
import bt from 'BoomTown';

export default class DepManager {
  // Local state to allow for loaded state to be synchronously checked
  isLoaded = false;
  promise = false;

  /**
   * Ask that the Google Maps lib. be fetched and executed.
   * @returns {Promise} - A promise that will be resolved when Google Maps has loaded.
   */
  loadGoogleMaps() {
    if (!this.promise) {
      this.promise = this._fetchGoogleMapsLib();
    }
    return this.promise;
  }

  /**
   * A globally accessible function that will be invoked after the google lib.
   * JS has been executed.
   */
  gmapLoaded = () => {
    this.loaded = true;
    this.resolvePromise();
  };

  /**
   * Request the Google Maps lib. with `getScript()`, specifying `this.gmapLoaded` to
   * be called after it's been executed.
   * @returns {Promise}
   */
  _fetchGoogleMapsLib() {
    // CNS-2644: Use a Google Maps key if configured.
    // Note: "gmapkey" is the default value in the database. Treat that as null.
    let gmapsKey = bt.account.get('GMapKey');
    if (gmapsKey === 'gmapkey') {
      gmapsKey = null;
    }
    let url = 'https://maps.googleapis.com/maps/api/js?sensor=false&libraries=places,drawing&async=2&callback=bt.deps.gmapLoaded';
    // CNS-5245: https://developers.google.com/maps/documentation/javascript/versions
    url += '&v=3.31';
    if (gmapsKey) {
      url += `&key=${gmapsKey}`;
    }
    $.getScript(url);
    return new Promise(resolve => {
      // This assignment allows for the promise to be resolved in an outer scope.
      this.resolvePromise = resolve;
    });
  }
}

import { firebase, config } from 'BoomTown';

export default class Firebase {
  /**
   * Creates a firebase URL based upon external params.
   * @param {param}
   * @return {String}
   */
  static createUrl(params) {
    const qs = [
      `apn=${encodeURIComponent(firebase.apn)}`,
      `ibi=${encodeURIComponent(firebase.ibi)}`,
      `isi=${encodeURIComponent(firebase.isi)}`,
      `ofl=${encodeURIComponent(window.location.href)}`,
      `si=${config.inner_logo_uri}`,
    ];
    for (const key in params) {
      if (params[key]) {
        qs.unshift(`${key}=${encodeURIComponent(params[key])}`);
      }
    }
    return `${firebase.url}?${qs.join('&')}`;
  }
}

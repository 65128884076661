/* eslint-disable no-param-reassign, no-mixed-operators, indent, global-require */
import _ from 'underscore';
import bt from 'BoomTown';
import { specialRules } from 'bt_data';
import BaseModel from 'legacy/Base/model';
import * as routerUtils from 'legacy/Routing/routerUtils';
import * as r from 'routes';
import { compose } from 'redux';
import { push } from 'redux-little-router';
import { isDesktopMapEnabled } from 'selectors/optimize';
import { getLastSearch } from 'reducers/lastSearch';
import { receiveMapParamsFromUrl } from 'screens/ResultsMap/Map/actions';

export default class Search extends BaseModel {
  get defaults() {
    return {
      sort: 'importdate',
      status: (specialRules.HideComingSoon) ? 'A' : 'A,CS',
      photo: '1',
    };
  }

  /**
   * Note: not enforced
   */
  whitelist = [
    'area',
    'boundingbox',
    'city',
    'county',
    'custom',
    'feature',
    'featurenot',
    'featureor',
    'hood',
    'latlonrad',
    'maxacres',
    // Represents half and full added together
    'maxbaths',
    'maxbeds',
    'maxdayslisted',
    'maxfullbaths',
    'maxgarages',
    'maxhalfbaths',
    'maxprice',
    'maxsqft',
    'maxstories',
    'maxyear',
    'minacres',
    'minbaths',
    'minbeds',
    'minfullbaths',
    'mingarages',
    'minhalfbaths',
    'minprice',
    'minsqft',
    'minstories',
    'minyear',
    'photo',
    'polygon',
    'postalcode',
    'pricereduced',
    'proptype',
    'school',
    'schooldistrict',
    'sort',
    'status',
    'tours',
  ];
  locationParams = [
    'area',
    'city',
    'citylike',
    'county',
    'custom',
    'hood',
    'keyword',
    'neighborhood',
    'OfficeID',
    'OfficeMLS',
    'postalcode',
    'rid',
    'school',
    'street',
    'streetname',
    'UserID',
  ];
  allowedMultipleFields = [
    'area',
    'city',
    'county',
    'custom',
    'feature',
    'featurenot',
    'featureor',
    'hood',
    'postalcode',
    'proptype',
    'school',
    'schooldistrict',
    'status',
  ];
  socialLoginParams = [
    'email',
    'first_name',
    'last_name',
    'fb_register',
    'error_reason',
  ];
  utmParams = [
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source'
  ];


  coordinateFilters = ['swlat', 'swlng', 'nelat', 'nelng', 'midlat', 'midlng', 'zoom'];

  type = 'all';

  /**
   * Since this model is instantiated in `run.js` but doesn't get truly
   * initialized until the first 'route' event, it needs to have a
   * uninitialized state.
   */
  initialized = false;

  initialize() {
    super.initialize();

    // Ensure that any event driven functions are bound to this context
    this.listenTo(bt.events, 'nearbySearch', this.setProperNearby);
    this.listenTo(bt.events, 'route', this.onRoute);
  }

  onRoute(e) {
    const qs = e.args[1];
    const { route } = e;

    const { getState } = require('store').default;

    const { lastSearchState } = getLastSearch(getState());

    // Rehydrate the current state based on the route
    const isHome = route === r.HOME;
    let wantedState = this.defaults;
    if (routerUtils.isResultsPath(route) || routerUtils.isFilterMenuPath(route)) {
      if (qs) {
        wantedState = bt.utility.parseQueryString(qs, true);
      }
    } else if (!isHome && lastSearchState) {
      // We only continue to rehydrate if you are on a split layout
      // home is the only page that isn't a split layout
      wantedState = lastSearchState;
    }

    wantedState = compose(
      searchIDsToUpper,
      filterPageIndex,
      filterByOmitList,
      filterCoordsParams,
    )(wantedState, this, route);

    if (!this.initialized || !_.isEqual(this.attributes, wantedState)) {
      this.initialized = true;
      this.clear({ silent: true });
      this.set(wantedState);
    }
  }

  /**
   *
   * @param {*} latlonrad
   * @deprecated
   */
  setProperNearby(latlonrad) {
    // eslint-disable-next-line
    console.warn('deprecated search@setProperNearby, should not use latlonrad any longer.');
    this.clear({ silent: true });

    bt.tags.create({
      displayName: 'Properties Near Me',
      prop: 'latlonrad',
      val: 'nearby',
      showOnDesktop: true,
    });

    this.set({ latlonrad, sort: 'popularity' });

    /* eslint-disable global-require */
    require('store').default.dispatch(
      push(
        {
          pathname: '/results-map/',
          query: { latlonrad },
        },
        { persistQuery: true }
      )
    );
  }

  /**
   * Given new search terms, compute the next state of the search model.
   *
   * @param {{[prop:string]: string}} state Previous attributes of the search model
   * @param {{[prop: string]: string}} newParams Search terms that will be added
   */
  addReducer(state, newParams) {
    const nextState = { ...state };

    for (const [prop, value] of Object.entries(newParams)) {
      if (this.allowedMultipleFields.includes(prop)) {
        const currentList = state[prop] ? state[prop].split(',') : [];
        nextState[prop] = _.uniq([...currentList, value]).join(',');
      } else {
        nextState[prop] = value;
      }
    }

    return ensureValidMinMaxPrices(nextState);
  }

  /**
   * Compute the next state of the search model when removing search terms.
   * (Note that this passes non-search properties/query parameters through, but
   * they will be whitelisted soon!)
   *
   * @param {Object} state Attributes of this model
   * @param {Object} paramsToRemove
   * - null value removes the property
   */
  removeReducer(state, paramsToRemove) {
    let nextState = { ...state };
    for (const [prop, value] of Object.entries(paramsToRemove)) {
      if (state[prop] === undefined) {
        // eslint-disable-next-line
        // console.warn("Remove called with a param that wasn't on the search object", {
        //   state,
        //   paramsToRemove,
        //   prop,
        //   value,
        // });
        // eslint-disable-next-line
        continue;
      }

      // Remove the whole prop, and we don't trust our current values to be string apparently
      if (value === null || value === nextState[prop].toString()) {
        nextState = _.omit(nextState, prop);
      } else if (this.allowedMultipleFields.includes(prop)) {
        // Otherwise we are updating the value of the prop
        const currentList = state[prop].split(',');
        const nextVal = _.without(currentList, value).join(',');
        nextState[prop] = nextVal;
      }
    }

    return nextState;
  }

  /**
   * Get the next state when a user selects a single number (can be fractional) for min beds.
   * Comprises our logic for mapping from a single ambiguous number to the three
   * ways of specifying minbeds to our API.
   *
   * @param {Object} state Search model attributes
   * @param {number} n The desired beds lower bound
   */
  minBathsReducer(state, n) {
    if (n === 0) {
      return _.omit(state, 'minbaths', 'minfullbaths', 'minhalfbaths');
    }

    // n is fractional
    if (n % 1 !== 0) {
      return {
        ..._.omit(state, 'minfullbaths', 'minhalfbaths'),
        minbaths: String(n),
      };
    }

    // n is a whole number
    return {
      ..._.omit(state, 'minbaths', 'minhalfbaths'),
      minfullbaths: String(n),
    };
  }
}

/**
 * Ensure these properties have capitalized values
 *
 * @param {Object} state
 */
export function searchIDsToUpper(state) {
  const alphaIDParams = ['proptype', 'status', 'feature', 'featureor', 'featurenot'];

  return Object.keys(state).reduce((prev, key) => {
    const value = state[key];
    return {
      ...prev,
      [key]: alphaIDParams.includes(key) && value.toUpperCase ? value.toUpperCase() : value,
    };
  }, {});
}

/**
 * If the URL contains 'pageindex' and our MaxListingResults would be exceeded,
 * interpret `searchObj` as having pageindex 0.
 *
 * This leaves the URL out of sync with whatever model uses this.
 *
 * @param {Object} searchObj
 */
export function filterPageIndex(searchObj) {
  if (_.has(searchObj, 'pageindex')) {
    const count = _.has(searchObj, 'pagecount') ? searchObj.pagecount : 10;
    const total = (parseInt(searchObj.pageindex, 10) + 1) * count;

    if (bt.compliance.MaxListingResults > 0 && bt.compliance.MaxListingResults < total) {
      searchObj.pageindex = 0;
    }
  }

  return searchObj;
}

function filterByOmitList({ searchObj, searchModel }) {
  return _.omit(searchObj, [
    // PRM-359
    bt.config.maxphotoParam,
    bt.config.maxviewsParam,
    'reg',
    'regformcomplete',
    bt.config.blTrackingOrigUrlQSName,
    bt.config.blTrackingReferrerQSName,
    'search', // Param representing the search menu
    ...searchModel.socialLoginParams,
    ...searchModel.utmParams
  ]);
}

/**
 * Strip map search params from legacy map urls to prevent issues with our new map api. Here we
 * filter out those map params so they don't get set in our search model.
 *
 * @param {Object} searchObj
 * @param {Object} model
 * @param {string} route
 * @returns Object
 */
export function filterCoordsParams(searchObj, searchModel, route) {
  if (!routerUtils.isMapPath(route) && !bt.config.useListMapResultsSync) {
    return { searchObj, searchModel };
  }

  const coordParams = _.pick(searchObj, searchModel.coordinateFilters);
  const { getState, dispatch } = require('store').default;

  if (_.isEmpty(coordParams) || !isDesktopMapEnabled(getState())) {
    return { searchObj, searchModel };
  }

  dispatch(receiveMapParamsFromUrl(_.mapObject(coordParams, (param) => parseFloat(param))));
  return { searchObj: _.omit(searchObj, searchModel.coordinateFilters), searchModel };
}

/**
 * If the minprice and maxprice are at odds the query would return 0 results,
 * so we correct this for the user
 *
 * @param {Object} currentState
 */
function ensureValidMinMaxPrices(currentState) {
  if (
    currentState.minprice !== undefined &&
    currentState.maxprice !== undefined &&
    parseInt(currentState.minprice, 10) > parseInt(currentState.maxprice, 10)
  ) {
    // Swap the values
    const nextState = { ...currentState };
    [nextState.minprice, nextState.maxprice] = [nextState.maxprice, nextState.minprice];
    return nextState;
  }

  return currentState;
}

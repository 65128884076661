import { connectBBHoc } from 'hoc/connectBB';
import { search } from 'BoomTown';

import AgentSearch from './index';

const Container = connectBBHoc(
  [
    {
      getEmitter: () => search,
      events: [
        {
          name: 'change',
          replaceProps: (props, searchModel) => ({
            ...props,
            search: { ...searchModel.toJSON() },
          }),
        },
      ],
    },
  ],
  () => ({ search: search.toJSON() })
)(AgentSearch);

export default Container;

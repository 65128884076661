/* eslint-disable no-unused-vars */
import { LOCATION_CHANGED } from 'redux-little-router';
import * as routes from 'routes';
// import { bootOptimize, timeout } from 'actions/OptimizeActions';
// import * as optimizeSelectors from 'selectors/optimize';

/**
 * A set of route IDs that have associated <body> classes.
 */
const clientSideRouteIDs = [
  'home',
  routes.DETAILS,
  routes.RESULTS_GALLERY,
  routes.RESULTS_MAP,
  routes.FAVORITES,
  routes.COMPARE,
  routes.ACCOUNT,
  routes.EDIT_ACCOUNT,
  routes.EMAIL_PREFERENCES,
  routes.TEXT_PREFERENCES,
  routes.EDIT_SAVED_SEARCHES,
  routes.CHANGE_PASSWORD
];

/**
 * Update the body classname for client side routes
 */
export default function bodyClass({ dispatch, getState }) {
  // const timeoutDuration = 2000;
  // const optimizeTimerID = setTimeout(() => dispatch(timeout()), timeoutDuration);

  return (next) => (action) => {
    if (action.type === LOCATION_CHANGED) {
      const id = action.payload && action.payload.result && action.payload.result.id;
      const newClasses = [];

      // if (!optimizeSelectors.isTestStateFinalized(getState())) {
      //   newClasses.push('bt-async-hide');
      // }

      if (id && clientSideRouteIDs.includes(id)) {
        newClasses.push(id);
        if (id.startsWith('results-')) {
          newClasses.push('results');
        }

        // We only wipe the old set of classnames if you are on a clientSideRoute
        document.body.className = '';
      }

      // IE Edge only accepts one arg at a time on add()
      newClasses.forEach(c => document.body.classList.add(c));
    }

    // // We gave optimize enough time
    // if (action.type === timeout.getType()) {
    //   document.body.classList.remove('bt-async-hide');
    // }

    // if (action.type === bootOptimize.getType()) {
    //   document.body.classList.remove('bt-async-hide');
    //   clearTimeout(optimizeTimerID);
    // }

    next(action);
  };
}

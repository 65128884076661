import { visitor } from 'BoomTown';
import { getPostRegistrationParam } from 'selectors/router';
import { SEARCH_MENU_QUERY } from 'models/search/constants';
import { savedSearchesData as saveSearchRegForm } from 'constants/registrationForms';
import { isMobile } from './browser';

export function isSearchMenuOpen(state) {
  return state.router.query[SEARCH_MENU_QUERY] !== undefined && state.locationChanges > 1;
}

export function isSearchParamInURLonFirstLoad(state) {
  return state.router.query[SEARCH_MENU_QUERY] !== undefined && state.locationChanges === 1;
}

/**
 * The save search menu can either be explicitly toggled open
 * or it can be implicitly opened if it was the reason you
 * registered.
 */
export function isSaveSearchMenuOpen(state) {
  if (!isMobile(state)) {
    return false;
  }

  const isOpenFromPostReg = getPostRegistrationParam(state) === saveSearchRegForm.urlParam
                            && state.locationChanges === 1;

  return state.mobileGallery.isSaveSearchMenuOpen || isOpenFromPostReg;
}

/**
 * State as required by the MobileGallery screen
 *
 * @param {Object} state Top-level node of the state tree
 * @param {Number[]} favoriteIDs
 */
export function getMobileGalleryState(state) {
  const { mobileGallery } = state;
  return {
    search: mobileGallery.search,
    isSortMenuOpen: mobileGallery.isSortMenuOpen,
    isSaveSearchMenuOpen: mobileGallery.isSaveSearchMenuOpen,
    isPowerSearchActive: mobileGallery.isPowerSearchActive,
    isLoadingFirstBatch: mobileGallery.isLoadingFirstBatch,
    totalItems: mobileGallery.totalItems,
    lastScrollY: mobileGallery.scrollY,
    initialSliderIndices: mobileGallery.slideIndices,
  };
}

/**
 * Returns the total number of listings that result from the current search
 *
 * @param {Object} state Global Redux state object
 * @returns {Number}
 */
export const getTotalItems = (state) => state.mobileGallery.totalItems;

export function getIsPowerSearchActive(state) {
  return state.mobileGallery.isPowerSearchActive;
}

export function getIsSortMenuOpen(state) {
  return state.mobileGallery.isSortMenuOpen;
}

export function getLastScrollY(mobileGallery) {
  return mobileGallery.scrollY;
}

/**
 * Disable Card Slider for Unregistered Visitors
 * @param {Boolean} visitor
 */
export function isPhotoSliderEnabled() {
  return !visitor.isRegistered();
}

export function getListing(state, id) {
  return state.mobileGallery.listings.filter(l => l._ID === id)[0];
}

export function getLastGallerySearch(state) {
  return state.mobileGallery.search;
}

export const getGalleryListings = state => state.mobileGallery.listings;

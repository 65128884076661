import { createAction } from 'redux-act';

const action = (desc, ...args) => createAction(`Text Prefs > ${desc}`, ...args);

// Mobile Only
export const clickCloseTextPrefs = action('Click close in header');

// Form Interactions
export const toggleTextAllowed = action(
  'Toggle subscription to agent text messages',
  x => x
);

// Form Submission
export const submitTextPreferencesForm = action(
  'Submit text preferences form',
  () => {}
);
export const failedSubmitTextPreferencesForm = action(
  "Failure when submitting the text pref's form",
  ({ error }) => ({ error })
);
export const successSubmitTextPreferencesForm = action(
  "Successful resp. when submitting the text pref's form",
  /** @param {FlagshipAPI.TextPrefsUpdateRequest} x */
  x => x
);

// Response Modal
const respModalAction = (desc, ...args) => action(`Response modal > ${desc}`, ...args);

export const clickCloseTextPrefsSuccessModal = respModalAction('Tap Confirm button');
export const autoDismissTextPrefsSuccessModal = respModalAction('Toast timeout');
export const clickBackInTextPrefsFailureModal = respModalAction('Tap Back button');
export const clickRetryInTextPrefsFailureModal = respModalAction('Tap Retry button');

import { createAction } from 'redux-act';

const prefix = 'Mobile Details > Contact Agent >';

export const submitContactForm = createAction(`${prefix} Submit the contact agent form`);

export const toggleRequestShowing = createAction('Toggle Request Showing');
export const toggleRequestVideoTour = createAction('Toggle Request Video Tour');

export const receiveContactFormRes = createAction(
  `${prefix} Receive response from contact agent request`,
  response => ({ success: response === 'success' }),
);

export const closeContactFormSuccessModal = createAction(`${prefix} Response Modal > Tap Confirm button`);
export const autoCloseContactFormSuccessModal = createAction(`${prefix} Response Modal > Toast timeout`);
export const closeContactFormFailureModal = createAction(`${prefix} Response Modal > Tap Back button`);
export const retrySubmitContactForm = createAction(`${prefix} Response Modal > Resubmit after failure`);

/**
 * Block helper that renders the block if **both** of the given values
 * are truthy. If an inverse block is specified it will be rendered
 * when falsy.
 *
 * @param {any} `a`
 * @param {any} `b`
 * @param {Object} `options` Handlebars provided options object
 * @return {String}
 * @block
 * @api public
 */

module.exports = function and(a, b, options) {
  // Compare the two arguments and return block
  // content if true
  if (a && b) {
    return options.fn(this);
  }
  return options.inverse(this);
};

import { Model as BackboneModel } from 'backbone';
import { stringify } from 'query-string';
import BaseCollection from 'legacy/Base/collection';
import Tag from 'legacy/Model/tag';
import _ from 'underscore';

export default class Tags extends BaseCollection {
  constructor(options) {
    super(options);
    this.update = this.update.bind(this);
  }

  get model() {
    return Tag;
  }

  static allowedTags = [
    'Area',
    'OfficeID',
    'area',
    'city',
    'citylike',
    'county',
    'custom',
    'favs',
    'feature',
    'featurenot',
    'featureor',
    'guide',
    'hood',
    'hoodlike',
    'keyword',
    'latlonrad',
    'listingid',
    'mapbounds',
    'maxacres',
    'maxbeds',
    'maxdayslisted',
    'maxbaths',
    'maxfullbaths',
    'maxgarages',
    'maxhalfbaths',
    'maxprice',
    'maxsqft',
    'maxstories',
    'maxyear',
    'minacres',
    'minbeds',
    'minbaths',
    'minfullbaths',
    'mingarages',
    'minhalfbaths',
    'minprice',
    'minsqft',
    'minstories',
    'minyear',
    'officeid',
    'ourlistings',
    'photo',
    'polygon',
    'postalcode',
    'pricereduced',
    'proptype',
    'rid',
    'school',
    'schooldistrict',
    'status',
    'street',
    'streetname',
    'tours',
    'userid',
  ];

  static allowedMultipleFields = [
    'schooldistrict',
    'county',
    'proptype',
    'hood',
    'area',
    'city',
    'feature',
    'featureor',
    'featurenot',
    'status',
    'custom',
    'postalcode',
    'school',
  ];

  initialize() {
    super.initialize();
    this.listenTo(window.bt.search, 'change', this.update);

    // [aknox] This is probably *not* necessary, the search model
    // is updating once the router starts up, so this @update is called
    // twice. I measured perf and it was only 5ms so i'd rather not
    // introduce errors by trying to improve this.
    this.update(window.bt.search);

    return window.addEventListener(
      'resize',
      _.debounce(() => {
        const tagsWithOverflowCleared = this.toJSON();

        /* eslint-disable no-param-reassign */
        _.each(tagsWithOverflowCleared, tag => (tag.overflow = false));
        /* eslint-enable */

        return this.reset(tagsWithOverflowCleared);
      }, 50)
    );
  }

  /**
   * Update the state of the tags collection based on the state of the search
   * model.
   *
   * @param {Search} searchModel
   */
  update(searchModel) {
    const obj = searchModel instanceof BackboneModel ? searchModel.toJSON() : searchModel;
    return this.reset(Tags.createTags(obj));
  }

  /**
   * Convert an Object with the search model schema to an array of Objects
   * with the tag schema.
   *
   * @param  {searchObj} searchObj
   * @return {Array<Object>}
   * @public
   * @static
   */
  static createTags(searchObj) {
    const tags = [];

    /**
     * Reusable abstraction layer to push new tag objects to our array of tags
     *
     * @param {string} name
     * @param {any} value
     */
    function pushTag(name, value) {
      if (name !== 'status' || (value !== 'A' && value !== 'CS')) {
        tags.push({
          prop: name,
          value,
        });
      }
    }

    // Create a specific, single tag for "Map Bounds" if coordinateFilters exist in query
    if (window.bt.config.useListMapResultsSync) {
      const mapBounds = _.pick(searchObj, window.bt.search.coordinateFilters);

      if (!_.isEmpty(mapBounds)) {
        pushTag('mapbounds', stringify(mapBounds));
      }
    }

    const clean = _.pick(searchObj, Tags.allowedTags);
    const pairs = _.pairs(clean);

    _.each(pairs, pair => {
      if (_.isString(pair[1])) {
        if (pair[1].indexOf(',') > 0 && Tags.allowedMultipleFields.includes(pair[0])) {
          const multiples = pair[1].split(',');
          _.each(multiples, val => pushTag(pair[0], val));
        } else {
          pushTag(pair[0], pair[1]);
        }
      } else {
        pushTag(pair[0], pair[1]);
      }
    });

    // Filter out sales types that shouldn't be included in site
    return tags.filter(tag => {
      if (tag.prop === 'featureor') {
        if (window.bt.rules.attributes.HideShortsale && tag.value === 'SS') {
          return false;
        }
        if (window.bt.rules.attributes.HideForeclosure && tag.value === 'BO') {
          return false;
        }
      }
      if (tag.prop === 'featurenot') {
        if (window.bt.rules.attributes.HideShortsale && tag.value === 'NSS') {
          return false;
        }
        if (window.bt.rules.attributes.HideForeclosure && tag.value === 'NBO') {
          return false;
        }
      }
      return true;
    });
  }

  /**
   * Get an array of the attributes properties like `toJSON()`, but with the Backbone-generated
   * `cid` added on.
   * @return {[type]} [description]
   */
  toJSONWithCid() {
    return _.map(this.models, model => {
      const obj = model.toJSON();
      obj.cid = model.cid;
      return obj;
    });
  }
}

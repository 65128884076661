import { SearchConverter } from 'models/search/services';

/**
 * Post registration we want to restore a saved search experience.
 * If the user is on mobile using filters in quick search when they registered
 * then we didn't persist the search in the URL, but we still want to restore
 * it for them.
 * So we stash it off to the side in storage
 */
export default class StoredPendingSearch {
  static KEY = 'pendingSearch';

  /**
   * Gets the pending search from localStorage then clears it from localStorage
   *
   * @param {Object} [options={}]
   * @param {boolean} [options.remove=true]
   * @return {object|null}
   */
  static get = ({ remove = true } = {}) => {
    const val = localStorage.getItem(StoredPendingSearch.KEY);
    if (!val) return null;

    if (remove) StoredPendingSearch.clear();

    return SearchConverter.mapCommittedToPending(JSON.parse(val));
  }

  /**
   * Stores the pending search in localStorage
   *
   * Opting to convert our internal schema to the URL schema, since that interface
   * is less likely to change over time
   *
   * @param {object} search
   */
  static set(search) {
    const searchInURLInterface = SearchConverter.mapPendingToCommitted(
      search,
      { removeDefaults: true }
    );
    localStorage.setItem(StoredPendingSearch.KEY, JSON.stringify(searchInURLInterface));
  }

  /**
   * @todo Move this to new search model
   */
  static clear() {
    localStorage.removeItem(StoredPendingSearch.KEY);
  }
}

import { lifecycle } from 'recompose';

/**
 * Hides the header and footer and makes sure we are scrolled to the top on load
 * Usefull for screens that we want to look like modals.
 */
const scrollToTop = lifecycle({
  componentDidMount() {
    window.scrollTo(0, 0);
  },
});

export default scrollToTop;

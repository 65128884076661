import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from 'coreComponents';
import { CCompToggle } from 'components/Common/Dropdown';

/**
 * TODO: Let's start moving towards the style of saleType where the checked
 * status is computed for us and we have aas dumb a component as possible.
 * That way if the logic for isChecked becomes more complex it doesn't get
 * jammed in our render area
 */
const PropertyTypeAccordion = (props) => {
  const { onPropertyTypeToggle, onSaleTypeToggle, pendingSearch, propertyTypes, saleTypes } = props;

  return (
    <CCompToggle heading="Property Type">
      <div className="grid pb-16 px-16">
        <ul className="cell grid grid--gutters grid-xs--full grid-sm--halves">
          {[
            ...propertyTypes.map((pt) => (
              <li key={pt.id} className="cell">
                <Checkbox
                  id={`offcanvas-proptype-${pt.id}`}
                  name="propertyType"
                  value={pt.id}
                  label={pt.name}
                  checked={pendingSearch.proptypes.includes(pt.id)}
                  onChange={() => onPropertyTypeToggle(pt.id)}
                />
              </li>
            )),
            ...saleTypes.map((x) => (
              <li key={x.id} className="cell">
                <Checkbox
                  label={x.label}
                  id={`offcanvas-sale-type-${x.id}`}
                  name="saleStatus"
                  value={x.id}
                  checked={x.checked}
                  onChange={() => onSaleTypeToggle(x.id)}
                />
              </li>
            )),
          ]}
        </ul>
      </div>
    </CCompToggle>
  );
};

PropertyTypeAccordion.propTypes = {
  propertyTypes: PropTypes.array,
  pendingSearch: PropTypes.object,
  saleTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onPropertyTypeToggle: PropTypes.func,
  onSaleTypeToggle: PropTypes.func,
  onToggleStatus: PropTypes.func,
};

export default PropertyTypeAccordion;

/* eslint-disable react/prop-types */
import React from 'react';
import ConfirmationModal from 'components/Common/ConfirmationModal';
import { EMAIL_PREFS } from 'cypress_constants';

export const ConfirmEAlerts = ({ isOpen, onClickConfirm, onClickCancel, onClickEditEAlerts }) => (
  <ConfirmationModal
    heading="Are you sure you want to unsubscribe from all new listing e-Alerts?"
    isOpen={isOpen}
    buttons={[
      {
        onClick: onClickConfirm,
        text: "Yes, I'm Sure",
      },
      {
        onClick: onClickCancel,
        text: 'Cancel',
      },
      {
        onClick: onClickEditEAlerts,
        text: 'Edit Saved Searches',
        dataCY: EMAIL_PREFS.CONFIRMATION_EDIT_SAVED_SEARCHES,
      },
    ]}
  />
);

export const ConfirmUnsubscribeAll = ({ isOpen, onClickConfirm, onClickCancel }) => (
  <ConfirmationModal
    heading="Are you sure you want to unsubscribe from all communication including new listing e-Alerts?"
    isOpen={isOpen}
    buttons={[
      {
        onClick: onClickConfirm,
        text: "Yes, I'm Sure",
        dataCY: EMAIL_PREFS.YES_I_AM_SURE_BUTTON,
      },
      {
        onClick: onClickCancel,
        text: 'Cancel',
        dataCY: EMAIL_PREFS.UNSUB_CANCEL,
      },
    ]}
  />
);

/**
 * Block helper that compares the length of the given array to
 * the number passed as the second argument. If the array length
 * is greater than the given `length`, the block is returned,
 * otherwise an inverse block may optionally be returned.
 *
 * @name .lengthGT
 * @param {Array} `array`
 * @param {Number} `length`
 * @param {Object} `options`
 * @return {String}
 * @block
 * @api public
 */

module.exports = function lengthGT(array, length, options) {
  if (array.length > length) {
    return options.fn(this);
  }
  return options.inverse(this);
};

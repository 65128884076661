import React from 'react';
import { config, tenant } from 'BoomTown';
import { Grid, Cell } from 'components/core/Grid';
import EnterpriseBallerBox from 'components/EnterpriseBallerBox';

const SearchBarEnterprise = () => {
  // Branding
  const logoURL = config.inner_logo_uri;
  const logoHREF = window.location.origin;
  const legalName = tenant.legalName;

  return (
    <div className="bt-listing-search-menu js-listing-search-menu js-results__page-header">
      <div className="container bt-listing-search__wrapper js-filter-bar">
        <Grid gutters alignItems="center">
          <Cell md={3} className="bt-listing-search__logo">
            <a className="js-inner-logo" href={logoHREF} title={legalName}>
              <img className="bt-inner-logo" alt={legalName} src={logoURL} />
              <span className="sr-only" itemProp="legalName">
                {legalName}
              </span>
            </a>
          </Cell>
          <Cell>
            <EnterpriseBallerBox />
          </Cell>
        </Grid>
      </div>
    </div>
  );
};

export default SearchBarEnterprise;

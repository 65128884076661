/**
 * @deprecated
 */
import $ from 'jquery';
import bt from 'BoomTown';

export default class Location {
  /**
   * Cookie key used for persisting the last location in a nearby search
   * @type {String}
   */
  static cookieName = 'lastLocation';

  /**
   * Latitude of the visitor when they last performed a nearby search
   * @type {Number}
   */
  lat = null;

  /**
   * Longitude of the visitor when they last performed a nearby search
   * @type {Number}
   */
  lng = null;

  /**
   * Restore `@lat` and `@lng` from a cookie if exists and hide the nearby
   * button for browsers that don't support geolocation.
   */
  constructor() {
    const lastLocationStr = bt.utility.cookie(Location.cookieName);
    if (lastLocationStr) {
      Object.assign(this, JSON.parse(lastLocationStr));
    }

    $(document).ready(() => {
      if (!navigator.geolocation) {
        $('.js-nearby-button').hide();
      }
    });
  }

  showButton() {
    return Boolean(navigator.geolocation);
  }

  enableSearchInput() {
    $('#query, #js-inner-query')
      .removeAttr('disabled')
      .prop(
        'placeholder',
        bt.utility.filterBallerboxPlaceholder(
          'Type any Area, Address, Zip, School, etc.'
        )
      );
    $('.js-search-type').removeClass('bt-active');
  }

  /**
   * Use the Geolocation API to fetch the device's location. On success or
   * error, first one of `set()` or `get()` will be called, but we also return
   * a Promise so that callers can be notified when this module's work is
   * done.
   */
  get = () => {
    if (navigator.geolocation) {
      // TODO: Deprecated code right here
      $('#query, #js-inner-query')
        .prop('disabled', true)
        .prop('placeholder', 'Grabbing Your Location...');

      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
      ).then(this.set, this.error);
    }

    return Promise.reject();
  };

  set = position => {
    this.lat = position.coords.latitude;
    this.lng = position.coords.longitude;
    bt.utility.cookie(
      Location.cookieName,
      JSON.stringify({ lat: this.lat, lng: this.lng }),
      { expires: 1 / 24 }
    );

    // for testing
    // @lat = 32.7990746350905
    // @lng = -79.95194233956903
    const radius = 3;
    const latlonrad = `${this.lat}|${this.lng}|${radius}`;

    $('#query, #js-inner-query')
      .prop('placeholder', 'Type any Address')
      .removeAttr('disabled');
    $('.js-nearby-button').removeClass('loading');
    $('.bt-nearby-link.js-nearby-button').addClass('bt-nearby-link--active');
    bt.search.once('change:latlonrad', this.enableSearchInput);

    return latlonrad;
  };

  error = error => {
    const alertMessage = this.getErrorMessage(error);
    throw alertMessage;
  };

  /*
    * Get the error text to display to the user depending on the specific PositionError encountered.
    * @param {PositionError} error
  */
  getErrorMessage(error) {
    const alertBase =
      'Unfortunately, we are unable to retrieve your current position.\n\n';

    switch (error.code) {
      case error.PERMISSION_DENIED:
        return `${alertBase}Please enable geolocation in order to perform location based searches.`;
      case error.POSITION_UNAVAILABLE:
        bt.events.trigger('error', 'could not detect current position');
        return alertBase;
      case error.TIMEOUT:
        bt.events.trigger('error', 'retrieving position timed out');
        return `${alertBase}The geolocation service timed out.`;
      default:
        bt.events.trigger('error', 'unknown error');
        const searchText = bt.utility.filterBallerboxPlaceholder(
          'Please try searching by City, Neighborhood, Address, or Zip.'
        );
        return `${alertBase + searchText}\n\nThere was an error detecting your position.`;
    }
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import MenuList, { MenuListLink } from 'components/Common/MenuList';

const SearchTypeContent = (props) => {
  const { activeType, searchTypes, handleClick } = props;

  return (
    <MenuList>
      {[...searchTypes.keys()].map((key) => {
        const searchType = searchTypes.get(key);

        return (
          <MenuListLink
            key={searchType.type}
            className="at-search-type"
            id={searchType.type}
            isActive={activeType === searchType.type}
            onClick={handleClick}
          >
            {searchType.content}
          </MenuListLink>
        );
      })}
    </MenuList>
  );
};

SearchTypeContent.propTypes = {
  activeType: PropTypes.string,
  searchTypes: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
};

SearchTypeContent.defaultProps = {
  activeType: 'all',
  handleClick() {},
};

export default SearchTypeContent;

import { createAction } from 'redux-act';

// UI Actions
export const clickClose = createAction('Click close button');
export const clickSignInButton = createAction('Click sign in button', () => ({}));
export const clickSignInLink = createAction('Click sign in link');
export const clickSearchMenuLink = createAction('Click search menu link', () => ({}));
export const clickSignOutLink = createAction('Click sign out link');
export const gravatarError = createAction('Gravatar image 404d', () => ({}));
export const clickSiteMenuLink = createAction('Click the site menu link', () => {});
export const clickNonLeafMenuItem = createAction('Click a non-leaf menu item', id => id);
export const clickBackOnSubMenu = createAction('Click the back icon on a sub menu', () => ({}));

/**
 *
 * @param {{}} c bt.config
 */
export default function isRestrictedAgentSub(c) {
  return (
    c.restrictAgentSubListings != null &&
    c.restrictAgentSubListings === true &&
    c.agentSubAlias != null
  );
}

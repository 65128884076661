import React from 'react';
import PropTypes from 'prop-types';
import { MOBILE_CONTACT_AGENT } from 'cypress_constants';
import * as MobileContactAgentActions from 'actions/MobileContactAgentActions';

import { PrimaryButton } from 'coreComponents';
import { CloseButton, MobileScreen } from 'components/Common/MobileScreen';
import ContactAgent from './ContactAgent';
import withData from './withData';

// This is the full screen contact agent
const propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};
function ContactAgentModal(props) {
  return (
    <MobileScreen
      headerLeft={<CloseButton onClose={props.onCloseClick} />}
      dataCY={MOBILE_CONTACT_AGENT.SCREEN_HEADER}
      title="Ask A Question"
      footer={
        <PrimaryButton
          type="submit"
          className="at-submit-btn"
          width="full"
          form="contact-agent"
          disabled={props.isSubmitting}
          showSpinner={props.isSubmitting}
        >
         Submit
        </PrimaryButton>
      }
    >
      <ContactAgent {...props} hideSubmit />
    </MobileScreen>
  );
}

ContactAgentModal.propTypes = propTypes;

const actions = {
  onCloseClick: MobileContactAgentActions.clickClose,
  onSubmit: MobileContactAgentActions.submitContactForm,
  onResSuccessButtonClick: MobileContactAgentActions.closeContactFormSuccessModal,
  onResBackButtonClick: MobileContactAgentActions.closeContactFormFailureModal,
  onResRetryButtonClick: MobileContactAgentActions.retrySubmitContactForm,
};
export default withData(actions)(ContactAgentModal);

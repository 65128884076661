import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import FAIcon from 'components/core/FAIcon';
import PriceInput from 'components/Common/PriceInput';

/**
 * Two, side-by-side price inputs for the off canvas search. Controlled inputs
 * to handle masking, calls onChange prop on blur.
 */
export default class OffCanvasPriceRange extends PureComponent {
  handlePriceChange = key => value => {
    this.props.onChange({ [key]: value });
  };

  render() {
    return (
      <div className="p-12 bt-off-canvas__filter--fixed-open">
        <div className="bt-ccomp__content__inner">
          <span className="uk-display-block mb-4">Price Range</span>
          <div className="bt-position--relative">
            <div className="grid grid--gutters grid-xs--halves">
              <div className="cell">
                <div className="mr-4">
                  <PriceInput
                    id="minprice--off-canvas"
                    placeholder="Min Price"
                    value={this.props.min}
                    onPriceChange={this.handlePriceChange('minprice')}
                  />
                </div>
              </div>
              <div className="cell">
                <div className="ml-4">
                  <PriceInput
                    id="maxprice--off-canvas"
                    placeholder="Max Price"
                    value={this.props.max}
                    onPriceChange={this.handlePriceChange('maxprice')}
                  />
                </div>
              </div>
            </div>
            <div className="bt-filter__price-range__arrow-bg">
              <FAIcon icon="angle-right" type="regular" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OffCanvasPriceRange.displayName = 'OffCanvasPriceRange';
OffCanvasPriceRange.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func
};

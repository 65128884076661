import { delay } from 'redux-saga';
import { take, call, put, race } from 'redux-saga/effects';

import * as OptimizeActions from 'actions/OptimizeActions';

const WAIT_FOR_OPTIMIZE = 300; // ms
const effects = [
  race([
    take(OptimizeActions.bootOptimize),
    call(function* imaLetYouFinishButFirst() {
      yield call(delay, WAIT_FOR_OPTIMIZE);
      yield put(OptimizeActions.timeout());
    }),
  ]),
];

export default effects;

import { config as appConfig } from 'BoomTown';

/**
 * A "selector" to determine the amount of time since the last MLS Listing Set update.
 *
 * @param {AppConfig|object} [config=window.bt.config]
 *
 * @returns {number} The number of minutes since the last MLS update
 */
export const getMLSUpdateTime = (config = appConfig) => {
  if (config?.boards) {
    const boardsArray = Object.values(config.boards);

    if (boardsArray?.length) {
      const lastUpdate = boardsArray[0]?.LastUpdatedAgo;

      return lastUpdate || -1;
    }
  }

  return -1;
};

/**
 * Get the positional index where the seller ad should render in the results
 *
 * @param {AppConfig|object} [config=window.bt.config]
 *
 * @returns {number}
 */
export const getSellerAdIndex = (config = appConfig) => {
  if (typeof config?.sellerAds === 'undefined') {
    return null;
  }

  return config.sellerAds;
};

/**
 * Get the query string param used to identify when a user is on their next route post regsitration.
 *
 * @param {AppConfig|object} [config=window.bt.config]
 *
 * @returns {string}
 */
export const getConversionQueryStringName = (config = appConfig) =>
  config?.conversionQueryStringName;


/**
 * Get the default `pagecount` value to use in searches.
 *
 * @param {AppConfig|object} [config=window.bt.config]
 *
 * @returns {number}
 */
export const getPageCountFromConfig = (config = appConfig) => config?.pageCount;

/**
 * Get a specific value from the global `bt.config` object
 *
 * @param {string} key
 * @param {AppConfig|object} config
 * @returns {any}
 */
export const getValueFromConfig = (key, config = appConfig) => (config ? config[key] : null);

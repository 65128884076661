/**
 * This file is the equivalent of `run.js` as legacy js files are
 * migrated to the Webpack build. It should import functions that have global
 * side effects (e.g. modify prototypes, set properties on globally accessible
 * objects) and then call them.
 *
 * (_I'm still not sure if it's worth it to have the modules imported here
 * export functions that when called have side- effects, or to just create the
 * side-effects as a consequence of the import._)
 */
import $ from 'jquery';

String.prototype.format = function format(...args) { // eslint-disable-line no-extend-native
  this.replace(/{(\d+)}/g, (match, number) => {
    if (args[number] !== 'undefined') {
      return args[number];
    }
    return match;
  });
};

window.__BOOMTOWN_ERRORS__ = window.__BOOMTOWN_ERRORS__ || [];
window.onerror = function onerror(message, file, line, col, error) {
  window.__BOOMTOWN_ERRORS__.push({ message, file, line, col, error });
  if (!file.match(/bt\.js/)) {
    return;
  }

  // bail if generic script error message
  // http://blog.errorception.com/2012/04/script-error-on-line-0.html
  if (message.match(/script error/i)) {
    return;
  }

  let logUrl = `/log/error/?label=JS+ERROR:
   ${message}&Url=${location.href}&File=${file}&Line=${line}&UserAgent=${navigator.userAgent}`;

  // Good browsers have this, IE no dice
  /* eslint-disable */
  // (Compiled CoffeeScript null-coalescing operator)
  if ((typeof error !== 'undefined' && error !== null ? error.stack : void 0) != null) {
    logUrl += "&StackTrace=" + error.stack;
  }
  /* eslint-enable */

  $.get(logUrl);
};

import addCommas from './addCommas';
import { shortenDollarAmount } from '.';

/**
 *
 * @param {string} count
 * @return {{isPlural: boolean, displayCount: number}}
 */
export default function formatResultCount(count) {
  const countI = parseInt(count, 10);

  let displayCount = addCommas(countI);
  if (countI > 9999) {
    displayCount = shortenDollarAmount(countI, {
      thousandsPlaces: 1,
      millionsPlaces: 1
    });
  }

  return {
    isPlural: countI !== 1,
    displayCount
  };
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import * as T from 'prop-types';
import Searcher from 'fuse.js';
import FAIcon from 'components/core/FAIcon';
import { Input, TextButton } from 'coreComponents';

const ITEM_SELECTOR = '.js-agent-list__item';
const ITEM_ID_PREFIX = 'agent-';
class AgentFilter extends React.Component {
  static propTypes = {
    agents: T.arrayOf(
      T.shape({
        id: T.number.isRequired,
        fullName: T.string.isRequired,
      })
    ).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };

    this.inputRef = React.createRef();

    this.searcher = new Searcher(this.props.agents, {
      id: 'id',
      keys: ['fullName'],
      threshold: 0.3,
    });
  }

  componentWillUnmount() {
    this.showAgents(this.getAllAgentIDs());
  }

  onChange = (e) => {
    this.setState({ value: e.target.value });
  };

  getAllAgentIDs() {
    return this.props.agents.map((a) => a.id.toString());
  }

  showAgents(agentIDsToShow) {
    [...document.querySelectorAll(ITEM_SELECTOR)].forEach((el) => {
      const showEl = agentIDsToShow.includes(el.id.substr(ITEM_ID_PREFIX.length));
      el.classList[showEl ? 'remove' : 'add']('uk-hidden');
    });
  }

  render() {
    const { value } = this.state;
    const agentIDsToShow = value.trim() ? this.searcher.search(value) : this.getAllAgentIDs();

    this.showAgents(agentIDsToShow);

    const renderSubmitButton = () => {
      if (value) {
        return (
          <TextButton
            className="form__input-suffix"
            onClick={() => {
              this.setState({ value: '' });
              this.inputRef.current.focus();
            }}
          >
            <FAIcon icon="times" type="solid" />
          </TextButton>
        );
      }

      return null;
    };

    return (
      <form onSubmit={(e) => e.preventDefault()} className="mb-12">
        <Input
          label="Search Agents by Name"
          grouped
          prefix={<FAIcon className="form__input-prefix" icon="search" type="solid" />}
          suffix={renderSubmitButton()}
          name="agentSearch"
          id="agentSearch"
          type="text"
          placeholder="Search Agents by Name"
          value={value}
          onChange={this.onChange}
          ref={this.inputRef}
        />
      </form>
    );
  }
}

export default AgentFilter;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from 'components/ListingResultsSpinner';
import { MOBILE_SAVED_SEARCHES_PAGE } from 'cypress_constants';
import { MobileScreen, CloseButton } from 'components/Common/MobileScreen';
import { clickCloseSavedSearches } from '../../Forms/EditSavedSearches/actions';
import ConnectedEditSavedSearchesForm from '../../Forms/EditSavedSearches';

const MobileEditSavedSearches = ({ onCloseClick, isLoading }) => (
  <MobileScreen
    title="Saved Searches"
    dataCY={MOBILE_SAVED_SEARCHES_PAGE.SCREEN_HEADER}
    headerLeft={<CloseButton onClose={onCloseClick} />}
  >
    {isLoading ? <Spinner /> : <ConnectedEditSavedSearchesForm isMobile />}
  </MobileScreen>
);
MobileEditSavedSearches.propTypes = {
  onCloseClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default connect(
  state => ({
    isLoading: state.accountView.savedSearches.isLoading,
  }),
  {
    onCloseClick: clickCloseSavedSearches,
  }
)(MobileEditSavedSearches);

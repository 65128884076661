import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from 'coreComponents';
import { CCompToggle } from 'components/Common/Dropdown';

function CheckboxGroup(props) {
  const { title, options, flushSides } = props;
  const optionKeys = Object.keys(options);
  return (
    <CCompToggle className={`at-${props.name}`} heading={title} flushSides={flushSides}>
      <div className={`pb-16 ${flushSides || 'px-16'}`}>
        <ul className="grid grid--gutters grid-xs--full grid-sm--halves">
          {optionKeys.map((id) => (
            <li key={id} className="cell">
              <Checkbox
                className={`at-${props.name}-${id}`}
                label={options[id].name}
                id={`${props.name}-${id}`}
                value={id}
                name={props.name}
                checked={props.checkedOptions.includes(id)}
                onChange={() => props.onToggleOption(id)}
              />
            </li>
          ))}
        </ul>
      </div>
    </CCompToggle>
  );
}

CheckboxGroup.propTypes = {
  flushSides: PropTypes.bool,
  title: PropTypes.string.isRequired,
  options: PropTypes.object,
  checkedOptions: PropTypes.arrayOf(PropTypes.string),
  onToggleOption: PropTypes.func,
  name: PropTypes.string.isRequired,
};

export default CheckboxGroup;

import React from 'react';
import PropTypes from 'prop-types';
import useListingMetaData from 'hooks/useListingMetaData';
import { Grid, Cell } from 'components/core/Grid';
import Text from 'components/core/Text';

/**
 * @typedef CardMetaProps
 *
 * @property {import('types/flagshipAPI').ListingSnapshot} listing
 */

/**
 * A list of at most four attributes pertaining to a listing, rendered in a
 * single row.
 *
 * @param {CardMetaProps} props
 * @returns {JSX.Element}
 */
const CardMeta = (props) => {
  /**
   * @type {import('hooks/types').MetaDataItem}
   */
  const metaData = useListingMetaData(props.listing);

  return (
    <Grid guttersSmall resetVertical className="bt-listing-teaser__card-meta mt-4">
      {metaData.map((itemProps) => {
        const { key, value, label, dataCY } = itemProps;
        return (
          <Cell key={key} xs="none" className={`listing-card__meta--${key}`}>
            <Text bold dataCY={dataCY}>{value}</Text>
            <span className="listing-card__meta-label">&nbsp;{label}</span>
          </Cell>
        );
      })}
    </Grid>
  );
};

CardMeta.propTypes = {
  listing: PropTypes.shape({
    Acreage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    AcreageText: PropTypes.string,
    FullBaths: PropTypes.number,
    Bedrooms: PropTypes.number,
    HalfBaths: PropTypes.number,
    HideBedsBaths: PropTypes.bool,
    hideHalfBaths: PropTypes.bool,
    NumUnits: PropTypes.number,
    DisableAcreage: PropTypes.bool,
    PropertyType: PropTypes.shape({
      _ID: PropTypes.string.isRequired,
    }),
    ApproxSqFtText: PropTypes.string,
    ShowSQFT: PropTypes.bool,
    SqFtSource: PropTypes.string,
  }),
};

export default CardMeta;

import React from 'react';
import * as routes from 'routes';
import { ForRoute } from 'components/ForRoute';

import MobileAccount from './MobileAccount';
import MobileEditSavedSearches from './EditSavedSearches';
import MobileEditAccount from './EditAccount';
import MobileEmailPreferences from './EditEmailPrefs';
import MobileChangePassword from './ChangePassword';
import MobileTextPreferences from './EditTextPrefs';

export default () => (
  <div>
    <ForRoute id={routes.ACCOUNT}>
      <MobileAccount />
    </ForRoute>
    <ForRoute id={routes.EDIT_ACCOUNT}>
      <MobileEditAccount />
    </ForRoute>
    <ForRoute id={routes.EMAIL_PREFERENCES}>
      <MobileEmailPreferences />
    </ForRoute>
    <ForRoute id={routes.TEXT_PREFERENCES}>
      <MobileTextPreferences />
    </ForRoute>
    <ForRoute id={routes.CHANGE_PASSWORD}>
      <MobileChangePassword />
    </ForRoute>
    <ForRoute id={routes.EDIT_SAVED_SEARCHES}>
      <MobileEditSavedSearches />
    </ForRoute>
  </div>
);

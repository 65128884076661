import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox, Select } from 'coreComponents';
import { ADDITIONAL_FILTERS } from 'cypress_constants';
import Grid from 'components/core/Grid/Grid';
import Cell from 'components/core/Grid/Cell';
import SelectRange from 'components/Common/FormElements/SelectRange';
import useFormattedSearchFieldOptions from 'hooks/useFormattedSearchFieldOptions';

const InitialOptionsPane = (props) => {
  const {
    showAcresSearch,
    showDaysListed,
    showGarages,
    showPriceReduced,
    showSQFT,
    showStories,
    showYearBuilt,
    showOpenHouse,
    search,
    searchFields: {
      minsqft,
      maxsqft,
      minyear,
      maxyear,
      minacres,
      maxacres,
      mingarages,
      maxgarages,
      minstories,
      maxstories,
      maxdayslisted,
      pricereduced,
    },
    selectedFeatures,
    onSelectMenuChange,
    onTogglePhoto,
    onToggleFeature,
    onToggleVirtualTours,
  } = props;

  const searchFieldOptions = useFormattedSearchFieldOptions({
    minsqft,
    maxsqft,
    minyear,
    maxyear,
    minacres,
    maxacres,
    mingarages,
    maxgarages,
    minstories,
    maxstories,
    maxdayslisted,
    pricereduced,
  });

  /**
   * Handles dispatching the action to update a specific search field.
   *
   * @param {Event} e
   * @returns
   */
  const handleSearchFieldChange = (e) => {
    if (e.target) {
      onSelectMenuChange({
        field: e.target.name,
        value: e.currentTarget.value,
      });
    }
  };

  /** TODO: Stop doing this inline and put it in a selector function. */
  const parseRangeValue = (attribute, type) => {
    if (attribute == null) {
      return 'any';
    }

    let parsedValue;
    switch (type) {
      case 'int':
        parsedValue = parseInt(attribute, 10);
        break;
      case 'float':
        parsedValue = parseFloat(attribute, 10);
        break;
      default:
        return attribute;
    }

    return isNaN(parsedValue) ? 'any' : parsedValue;
  };

  return (
    <div>
      <Grid gutters resetVertical xs="thirds">
        {showSQFT && (
          <Cell>
            <SelectRange
              id="searchSqft"
              dataCY={ADDITIONAL_FILTERS.SQUARE_FOOTAGE}
              minClassName="at-min-square-footage"
              maxClassName="at-max-square-footage"
              label="Square Footage"
              field="sqft"
              minValue={parseRangeValue(search.minsqft, 'int')}
              maxValue={parseRangeValue(search.maxsqft, 'int')}
              onMinChange={handleSearchFieldChange}
              onMaxChange={handleSearchFieldChange}
              minOptions={searchFieldOptions.minsqft}
              maxOptions={searchFieldOptions.maxsqft}
            />
          </Cell>
        )}
        {showYearBuilt && (
          <Cell>
            <SelectRange
              id="searchYearBuilt"
              dataCY={ADDITIONAL_FILTERS.YEAR_BUILT}
              minClassName="at-min-year"
              maxClassName="at-max-year"
              label="Year Built"
              field="year"
              minValue={parseRangeValue(search.minyear, 'int')}
              maxValue={parseRangeValue(search.maxyear, 'int')}
              onMinChange={handleSearchFieldChange}
              onMaxChange={handleSearchFieldChange}
              minOptions={searchFieldOptions.minyear}
              maxOptions={searchFieldOptions.maxyear}
            />
          </Cell>
        )}
        {showDaysListed && (
          <Cell>
            <Select
              label="# Days On Site"
              id="search-menu__maxdayslisted"
              name="maxdayslisted"
              value={parseRangeValue(search.maxdayslisted, 'int')}
              onChange={handleSearchFieldChange}
              options={searchFieldOptions.maxdayslisted}
            />
          </Cell>
        )}
        {showAcresSearch && (
          <Cell>
            <SelectRange
              id="searchAcres"
              dataCY={ADDITIONAL_FILTERS.ACRES}
              minClassName="at-min-acres"
              maxClassName="at-max-acres"
              label="Acres"
              field="acres"
              minValue={parseRangeValue(search.minacres, 'float')}
              maxValue={parseRangeValue(search.maxacres, 'float')}
              onMinChange={handleSearchFieldChange}
              onMaxChange={handleSearchFieldChange}
              minOptions={searchFieldOptions.minacres}
              maxOptions={searchFieldOptions.maxacres}
            />
          </Cell>
        )}
        {showGarages && (
          <Cell>
            <SelectRange
              id="searchGarages"
              dataCY={ADDITIONAL_FILTERS.GARAGES}
              minClassName="at-min-garage-spaces"
              maxClassName="at-max-garage-spaces"
              label="Garage Spaces"
              field="garages"
              minValue={parseRangeValue(search.mingarages, 'int')}
              maxValue={parseRangeValue(search.maxgarages, 'int')}
              onMinChange={handleSearchFieldChange}
              onMaxChange={handleSearchFieldChange}
              minOptions={searchFieldOptions.mingarages}
              maxOptions={searchFieldOptions.maxgarages}
            />
          </Cell>
        )}
        {showPriceReduced && (
          <Cell>
            <Select
              label="Price Reduced"
              id="search-menu__pricereduced"
              name="pricereduced"
              value={search.pricereduced}
              onChange={handleSearchFieldChange}
              options={searchFieldOptions.pricereduced}
            />
          </Cell>
        )}
        {showStories && (
          <Cell>
            <SelectRange
              id="searchStories"
              dataCY={ADDITIONAL_FILTERS.STORIES}
              minClassName="at-min-stories"
              maxClassName="at-max-stories"
              label="Stories"
              field="stories"
              minValue={parseRangeValue(search.minstories, 'int')}
              maxValue={parseRangeValue(search.maxstories, 'int')}
              onMinChange={handleSearchFieldChange}
              onMaxChange={handleSearchFieldChange}
              minOptions={searchFieldOptions.minstories}
              maxOptions={searchFieldOptions.maxstories}
            />
          </Cell>
        )}
      </Grid>
      <hr />
      <h4 className="my-12">Popular Features</h4>
      <ul className="grid grid--gutters grid-md--fourths">
        <li className="cell">
          <Checkbox
            label="Has Photos"
            id="has-photos"
            name="hasPhotos"
            value="has-photos"
            checked={Boolean(search.photo) && search.photo !== '0'}
            onChange={onTogglePhoto}
          />
        </li>
        {showOpenHouse && (
          <li className="cell">
            <Checkbox
              label="Open House"
              id="OH"
              name="openHouse"
              dataCY={ADDITIONAL_FILTERS.OPEN_HOUSE}
              checked={selectedFeatures.includes('OH')}
              onChange={() => onToggleFeature('OH')}
            />
          </li>
        )}
        <li className="cell">
          <Checkbox
            label="Virtual Tours"
            id="virtual-tours"
            name="virtualTours"
            value="virtual-tours"
            checked={Boolean(search.tours) && search.tours !== '0'}
            onChange={onToggleVirtualTours}
          />
        </li>
      </ul>
    </div>
  );
};

InitialOptionsPane.propTypes = {
  showAcresSearch: PropTypes.bool,
  showDaysListed: PropTypes.bool,
  showGarages: PropTypes.bool,
  showPriceReduced: PropTypes.bool,
  showSQFT: PropTypes.bool,
  showStories: PropTypes.bool,
  showYearBuilt: PropTypes.bool,
  showOpenHouse: PropTypes.bool,
  searchFields: PropTypes.shape({
    minsqft: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.number,
        })
      ),
    }),
    maxsqft: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.number,
        })
      ),
    }),
    minyear: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.number,
        })
      ),
    }),
    maxyear: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.number,
        })
      ),
    }),
    minacres: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.number,
        })
      ),
    }),
    maxacres: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.number,
        })
      ),
    }),
    mingarages: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.number,
        })
      ),
    }),
    maxgarages: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.number,
        })
      ),
    }),
    minstories: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.number,
        })
      ),
    }),
    maxstories: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.number,
        })
      ),
    }),
    maxdayslisted: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.number,
        })
      ),
    }),
    pricereduced: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
  selectedFeatures: PropTypes.array,
  search: PropTypes.shape({
    minsqft: PropTypes.string,
    maxsqft: PropTypes.string,
    minacres: PropTypes.string,
    maxacres: PropTypes.string,
    minstories: PropTypes.string,
    maxstories: PropTypes.string,
    minyear: PropTypes.string,
    maxyear: PropTypes.string,
    mingarages: PropTypes.string,
    maxgarages: PropTypes.string,
    maxdayslisted: PropTypes.string,
    pricereduced: PropTypes.string,
    photo: PropTypes.string,
    tours: PropTypes.string,
    feature: PropTypes.string,
  }),
  onSelectMenuChange: PropTypes.func.isRequired,
  onTogglePhoto: PropTypes.func.isRequired,
  onToggleVirtualTours: PropTypes.func.isRequired,
  onToggleFeature: PropTypes.func.isRequired,
};

export default InitialOptionsPane;

import PropTypes from 'prop-types';
import React from 'react';
import SortRadio from './MobileResultsSortRadio';
import SortRadioListWrapper from './MobileResultsSortRadioListWrapper';

export default function MobileResultsSortRadioList(props) {
  return (
    <SortRadioListWrapper
      handleClick={props.handleClick}
      currentSort={props.currentSort}
      showDistanceSort={props.showDistanceSort}
    >
      <SortRadio sortKey="listprice">Price (Highest)</SortRadio>
      <SortRadio sortKey="listprice_asc">Price (Lowest)</SortRadio>
      <SortRadio sortKey="importdate">Days on Site (Newest)</SortRadio>
      <SortRadio sortKey="importdate_asc">Days on Site (Oldest)</SortRadio>
      <SortRadio sortKey="distance">Distance</SortRadio>
      <SortRadio sortKey="popularity">Most Popular</SortRadio>
      <SortRadio sortKey="acreage">Acreage (Highest)</SortRadio>
      <SortRadio sortKey="acreage_asc">Acreage (Lowest)</SortRadio>
    </SortRadioListWrapper>
  );
}

MobileResultsSortRadioList.propTypes = {
  handleClick: PropTypes.func,
  currentSort: PropTypes.string,
  showDistanceSort: PropTypes.bool
};

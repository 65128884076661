import React from 'react';
import PropTypes from 'prop-types';
import { Fieldset, FieldsetLegend, Checkbox } from 'coreComponents';
import { Grid, Cell } from 'components/core/Grid';

export default function MoreFiltersPane(props) {
  const { features, selectedFeatures, onToggleFeature } = props;

  // Creating a list of groups, and making sure popular is at the front
  const groups = Object.keys(features).filter((f) => f !== 'popular');
  groups.unshift('popular');

  return (
    <div>
      {groups.map((key) => {
        const groupName = key.replace(/\s+/g, '');
        const title = key === 'popular' ? 'Popular Features' : key;
        const options = features[key];

        return (
          <Fieldset key={key}>
            <FieldsetLegend>{title}</FieldsetLegend>
            <Grid md="halves" lg="thirds">
              {Object.keys(options).map((id, i) => (
                <Cell key={i} className="mb-8">
                  <Checkbox
                    label={options[id].name}
                    id={`${groupName}-${id}`}
                    value={id}
                    name={groupName}
                    checked={selectedFeatures.includes(id)}
                    onChange={() => onToggleFeature(id)}
                  />
                </Cell>
              ))}
            </Grid>
          </Fieldset>
        );
      })}
    </div>
  );
}

MoreFiltersPane.propTypes = {
  features: PropTypes.object,
  selectedFeatures: PropTypes.arrayOf(PropTypes.string),
  onToggleFeature: PropTypes.func.isRequired,
};

export { default as addCommas } from './addCommas';
export { default as getSashLabel } from './getSashLabel';
export { default as isSold } from './isSold';
export { default as phonevalidator } from './phonevalidator';
export { default as formatResultCount } from './formatResultCount';
export { default as isIOS } from './isIOS';
export { default as scrollToElement } from './scrollToElement';
export {
  calculateMortgage,
  calcDownPaymentAmount,
  calcDownPaymentPercent,
  calcTaxAndInsAmount,
  calcTaxAndInsPercent
} from './mortgageCalculator';
export { toDollarString, toPercentage, toPercentageString } from './string';

export function round(number, decimalPlaces) {
  return Math.round(number * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
}

export function shortenDollarAmount(number, options = {}) {
  const defaults = {
    thousandsPlaces: 2,
    millionsPlaces: 2
  };
  const settings = Object.assign({}, defaults, options);

  if (number / 1000000 >= 1) {
    return `${round(number / 1000000, settings.millionsPlaces)}M`;
  } else if (number / 1000 > 1) {
    return `${round(number / 1000, settings.thousandsPlaces)}K`;
  }
  return `${number}`;
}

import { createAction as _createAction } from 'redux-act';

const createAction = (desc, ...args) => _createAction(`Desktop Details Page > ${desc}`, ...args);

export const showPhotoSliderCTA = createAction('Show Photo Slider CTA');
export const clickBackToSearch = createAction('Click "Back to Search" button', () => {});
export const createDataLayer = createAction('Create dataLayer');

export const clickListingAttribute = createAction(
  'Click listing attribute link',
  /**
   * @param {Object} o
   * @param {string} o.name The display text for the attribute
   * @param {string} o.id The value of the corresponding search term
   * @param {string} o.type The key of the corresponding search term
   */
  ({ name, id, type }) => ({ name, id, type })
);

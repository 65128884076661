import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { SecondaryButton } from 'coreComponents';
import FAIcon from 'components/core/FAIcon';
import { CCompDropdown } from 'components/Common/Dropdown';
import SortRadioList from './MobileResultsSortRadioList';

export default class MobileResultsSortDropdown extends Component {
  static propTypes = {
    currentSort: PropTypes.string,
    showDistanceSort: PropTypes.bool,
    onSelectSort: PropTypes.func.isRequired,
  };

  /**
   * Click handler for the sort radio buttons.
   * @param  {ClickEvent} e
   */
  _handleRadioClick = sortKey => {
    if (this._close) {
      this._close();
    }
    this.props.onSelectSort(sortKey);
  };

  _setClose = fn => {
    this._close = fn;
  };

  render() {
    return (
      <CCompDropdown
        align="right"
        className="width-1-1"
        getClose={this._setClose}
        trigger={
          <SecondaryButton width="full">
            Sort <FAIcon icon="angle-down" type="regular" />
          </SecondaryButton>
        }
        content={
          <SortRadioList
            currentSort={this.props.currentSort}
            handleClick={this._handleRadioClick}
            showDistanceSort={this.props.showDistanceSort}
          />
        }
      />
    );
  }
}

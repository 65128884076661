/* eslint-disable import/prefer-default-export */
import { MENU } from 'routes';
import { getStack, getCurrentMenuID } from 'screens/MobileMenu/reducer';
import { getRouteID } from './router';

/**
 * @typedef {Object} MenuItem
 * @prop {Number} id
 * @prop {Number} parent
 * @prop {String} title
 * @prop {String} url
 * @prop {String} slug
 */

/**
 * @param {Object} state
 * @returns {MenuItem[]}
 */
const getMenuItems = state => state.menuItems;

/**
 *
 * @param {Object} state Root Redux state
 * @returns {{ id: Number, title: String, hasChildren: boolean, href: String? }[]}
 */
export const getSiteMenuItems = state => {
  const currentID = getCurrentMenuID(state.siteMenu);
  const items = getMenuItems(state);

  return items.filter(x => x.parent === currentID).map(({ id, title, url }) => {
    const hasChildren = items.some(x => x.parent === id);

    let href = null;
    if (!hasChildren) {
      // Clean the hostname off of local urls
      href = url
        .replace(`http://${location.hostname}`, '')
        .replace(`https://${location.hostname}`, '');
    }

    return {
      id,
      title,
      hasChildren,
      href,
    };
  });
};

/**
 *
 * @param {object} state
 * @returns {string}
 */
export const getTitle = state => {
  const currentID = getCurrentMenuID(state.siteMenu);
  if (currentID === 0 || currentID === undefined) {
    return '';
  }

  const items = getMenuItems(state);
  return items.find(it => it.id === currentID).title;
};

/**
 * @param {Object} state Root Redux state
 * @returns {String[]} An array of unique path elements, _including_ the top level menu
 */
export const getMenuStack = state => {
  const id = getRouteID(state);
  // Using route ID here
  if (id !== MENU) {
    return [];
  }

  return [MENU, ...getStack(state.siteMenu).map(x => String(x))];
};

import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { Motion, spring } from 'react-motion';
import FAIcon from 'components/core/FAIcon';

export const SLIDER_DOTS_HEIGHT = 16;
const DOT_WIDTH = 14; // Important: width property of .carousel__dot + 4px (2px + 2px) x-axis margin
const DOTS_IN_FRAME = 5;
const FRAME_WIDTH = DOT_WIDTH * DOTS_IN_FRAME;

function Dot(props) {
  const { isActive, radius } = props;
  const dotClasses = cx('carousel__dot', {
    'carousel__dot--active': isActive,
    'carousel__dot--small': radius === 2.5,
  });

  return <FAIcon icon="circle" type="solid" className={dotClasses} />;
}

Dot.propTypes = {
  isActive: PropTypes.bool.isRequired,
  radius: PropTypes.number.isRequired,
};

export default function SliderDots(props) {
  const { activeIndex, photoCount } = props;
  const trackWidth = DOT_WIDTH * photoCount;
  let trackOffsetLeft = 0;

  // If our track can fit in frame just center it
  if (trackWidth <= FRAME_WIDTH) {
    trackOffsetLeft = (FRAME_WIDTH - trackWidth) / 2;
  } else if (activeIndex > 2) {
    // We don't want to slide the dots left until the active index has reached the middle of the
    // visible dots. We only want to slide the container 1 dot position over. So we remove 2
    // positions from the active index, we multiply the index by the width of each dot, and
    // since we are moving left, we want a negative number.
    trackOffsetLeft = (activeIndex - 2) * DOT_WIDTH * -1;

    // Never slide more than our max
    const maxTrackOffset = FRAME_WIDTH - trackWidth;
    if (trackOffsetLeft <= maxTrackOffset) {
      trackOffsetLeft = maxTrackOffset;
    }
  }

  // Dots we will eventually render
  const dots = [];
  for (let i = 0; i < photoCount; i++) {
    const radius = getRadius({ i, activeIndex, photoCount });
    dots.push(<Dot key={i} radius={radius} isActive={i === activeIndex} />);
  }

  return (
    <div
      className="carousel__dot-frame"
      style={{ width: `${FRAME_WIDTH}px`, height: `${SLIDER_DOTS_HEIGHT}px` }}
    >
      <Motion style={{ x: spring(trackOffsetLeft, { stiffness: 320, damping: 28 }) }}>
        {({ x }) => (
          <div
            className="carousel__dot-track"
            style={{
              width: `${trackWidth}px`,
              lineHeight: `${DOT_WIDTH / 2}px`,
              WebkitTransform: `translate3d(${x}px, 0, 0)`,
              transform: `translate3d(${x}px, 0, 0)`,
            }}
          >
            {dots}
          </div>
        )}
      </Motion>
    </div>
  );
}

SliderDots.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  photoCount: PropTypes.number.isRequired,
};

/**
 * Determine which size dots to render
 *
 * @param {{ i: number, activeIndex: number, photoCount: number }} params
 * @returns {number}
 */
export function getRadius({ i, activeIndex, photoCount }) {
  // These numbers are mainly symbolic at this point as their values no longer directly determine
  // the width of the dots. However, they're left here to reflect the font-sizes used for small
  // and large dots since we're using font-awesome.
  const LARGE = 3.5; // 7px font-size
  const SMALL = 2.5; // 5px font-size

  // indexes are zero based but length is one based @_@
  const lastIndex = photoCount - 1;

  // Edge dots are always full
  if (i === 0 || i === lastIndex) {
    return LARGE;
  }

  const atEdge = activeIndex === 0 || activeIndex === lastIndex;
  const nearEdge = activeIndex === 1 || activeIndex === lastIndex - 1;

  let spread;
  if (atEdge) {
    spread = 4;
  } else if (nearEdge) {
    spread = 3;
  } else {
    spread = 2;
  }

  if (activeIndex - spread < i && i < activeIndex + spread) {
    return LARGE;
  }

  return SMALL;
}

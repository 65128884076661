import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Cell } from 'components/core/Grid';
import { Modal, Backdrop } from 'components/Common/MobileXPModal';
import { BaseButton, SecondaryButton } from 'coreComponents';
import { MOBILE_GALLERY } from 'cypress_constants';

const BottomAppPrompt = (props) => {
  const { url, onCloseBanner } = props;

  return (
    <Modal
      className="mobile-app-prompt__backdrop"
      dataGTM="hsn-banner-prompt"
      show
      renderBackdrop={() => <Backdrop />}
    >
      <Grid
        className="mobile-app-bottom-prompt py-16"
        justifyContent="center"
        alignItems="center"
        dataCY={MOBILE_GALLERY.HSN_MODAL}
      >
        <Cell xs={10} className="text-xs--center pt-12">
          <div className="mobile-app-bottom-prompt__title mb-4">
            Download our HomeSearchNOW App
          </div>
          <div className="mb-16">
            Customizable experience with quick filters, favorites, and saved searches
          </div>
        </Cell>
        <Cell xs={10} className="mobile-app-bottom-prompt__buttons">
          <BaseButton
            component="a"
            href={url}
            rel="noopener noreferrer"
            target="_blank"
            width="full"
            data-gtm="hsn-banner-prompt-download"
            className="mobile-app-download__btn"
          >
            Download the App
          </BaseButton>
          <SecondaryButton
            className="mt-16"
            width="full"
            onClick={onCloseBanner}
            dataCY={MOBILE_GALLERY.HSN_MODAL_DISMISS_BUTTON}
            dataGTM="hsn-banner-prompt-dismiss"
          >
            No thanks, I don't like using apps
          </SecondaryButton>
        </Cell>
      </Grid>
    </Modal>
  );
};

BottomAppPrompt.propTypes = {
  url: PropTypes.string.isRequired,
  onCloseBanner: PropTypes.func,
};

export default BottomAppPrompt;

import { createAction } from 'redux-act';
import { push } from 'redux-little-router';
import { favorites } from 'BoomTown';

/**
 * removeFromFavorites
 *
 * A redux action that fires when a user unfavorites a listing.
 * This action is dispatched from the `toggleFavorite` thunk defined below.
 * It's executed from a callback provided to `window.bt.favorites.toggle`.
 * The `toggleFavorite` thunk is used on <FavoritesGallery> and called when a user
 * clicks the heart icon on a listing card.
 * Once the server has informed us that it's successfully handled unfavoriting the listing,
 * we dispatch `removeFromFavorites` to reduce our current <FavoritesGallery> view
 * state to remove the listing from the current gallery.
 *
 * @param {int} listingId - The ID of the listing unfavorited
 * @return {object} Standard flux action { type: 'REMOVE_LISTING_FROM_FAVORITES', payload: 12345 }
 */
export const removeFromFavorites = createAction('REMOVE_LISTING_FROM_FAVORITES');

/**
 * loadingFavorites
 *
 * A redux action that fires before an API request is made.
 * This action is dispatched from favorites middleware before an API request.
 * It turns `isLoading` state to true so that our UI can inform the user
 * that the site is currently loading more data.
 *
 * @return {object} Standard flux action { type: 'LOADING_FAVORITES', payload: undefined }
 */
export const loadingFavorites = createAction('LOADING_FAVORITES');

/**
 * loadFavorites
 *
 * A redux action that updates the current favorites in our store with new ones.
 * This action is dispatched from favorites middleware after a successful API response.
 * It updates the current store with a new array of listing objects.
 *
 * @param {array} listings - An array of gallery listing objects
 * @return {object} Standard flux action { type: 'LOAD_FAVORITES', payload: [{ _ID: ... }] }
 */
export const loadFavorites = createAction('LOAD_FAVORITES');

/**
 * loadSelected
 *
 * `loadSelected` is a redux action that fires on every /favorites/ route in order
 * to update our store with the current listing ID's the user would like to compare.
 * This action is dispatched from favorites middleware on every route change.
 * The middleware consumes the url state and produces an array of listing IDs.
 * This state is directly derived from a url query parameter; `?id=12345,54321`.
 * If a query parameter is not included in the request, an empty array is given.
 *
 * @param {array} listingIds - An array of listing IDs
 * @return {object} Standard flux action { type: 'LOAD_FAVORITES', payload: [12345, 12354] }
 */
export const loadSelected = createAction('FAVORITES_SELECTED_FOR_COMPARE');

/**
 * loadingComparisonTable
 *
 * A redux action that fires before an API request is made.
 * This action is dispatched from compare middleware before an API request.
 * It turns `isLoading` state to true so that our UI can inform the user
 * that the site is currently loading more data.
 *
 * @return {object} Standard flux action { type: 'LOADING_COMPARISON_TABLE', payload: undefined }
 */
export const loadingComparisonTable = createAction('LOADING_COMPARISON_TABLE');

/**
 * loadComparisonTable
 *
 * A redux action that updates the current compared listing details in our store with new ones.
 * This action is dispatched from compare middleware after a successful API response.
 * It updates the current store with a new array of listing objects.
 *
 * @param {array} listings - An array of single details listing objects beefed up
 * @return {object} Standard flux action { type: 'LOAD_COMPARISON_TABLE', payload: [{ _ID: ... }] }
 */
export const loadComparisonTable = createAction('LOAD_COMPARISON_TABLE');

/**
 * loadFeatures
 *
 * A redux action that updates the current feature set with new features a user would like to compare.
 * This action is dispatched from compare middleware on every route.
 * The `features` state is directly derived from a URL query parameter; `?features=base64arrayoffeatures`
 *
 * @param {array} features - An array of strings
 * @return {object} Standard flux action { type: 'LOAD_COMPARISON_TABLE_FEATURES', payload: ['Pool', 'Spa'] }
 */
export const loadFeatures = createAction('LOAD_COMPARISON_TABLE_FEATURES');

/**
 * cacheListing
 *
 * A redux action that updates the store (`listingCache`) with a single listing details object.
 * To reduce API requests and ensure the UI is snappy in between favorites and compare routes,
 * we stash a mapping of single detail listing objects in the store to create a pseudo key/value cache.
 * This cache is a simple in-memory cache and is not persisted in the browser between page loads.
 *
 * @param {object} listing - A beefed up single details object
 * @return {object} Standard flux action { type: 'CACHE_LISTING', payload: { _ID: ... } }
 */
export const cacheListing = createAction('CACHE_LISTING');

/**
 * toggleFavorite
 *
 * `toggleFavorite` is a thunk which accepts a listingID and calls `window.bt.favorites.toggle`
 * to either favorite or unfavorite a listing. This Comparison Table feature doesn't have any UI
 * for adding a listing to a user's favorites, so we only need to be able to remove a listing.
 * On successfully unfavoriting a listing, `toggleFavorite` will check against the URL to see if
 * the listing that was unfavorited, was currently being used in <SelectionGrid>. If the ID of
 * the listing that was unfavorited is in the URL, `toggleFavorite` will also dispatch a removeListing
 * thunk which is defined below.
 *
 * @todo Revisit this and possibly update to either use or override the onFavoriteClick that the
 * Card component provides for itself.
 *
 * @param {int} listingId - ID of listing being unfavorited
 * @return {func} - A Thunk
 */
export const toggleFavorite = listingId => (dispatch, getState) => {
  favorites.toggle(
    listingId,
    () => {},
    () => {
      dispatch(removeFromFavorites(listingId));
      const { id } = getState().router.query;
      if (id && id.includes(listingId)) {
        dispatch(removeListing(listingId));
      }
    }
  );
};

/**
 * addListing
 *
 * A thunk that pushes another route containing the passed listing id.
 * All UI state around both favorites and comparison table for displaying listings is
 * derived from the URL. This thunk accepts a listing id, looks to see if we currently
 * have that listing id in the search url, and finally pushes a new route with the listing
 * id embedded in it.
 *
 * @param {int} listingId - ID of listing being added
 * @return {func} - A Thunk
 */
export const addListing = listingId => (dispatch, getState) => {
  // New query object
  const query = {};

  // Current url parameters for id and features
  const { id, features } = getState().router.query;

  // Pass through features to keep feature state for multiple compares
  if (features) {
    query.features = features;
  }

  // Do we already have id's in the url?
  if (id) {
    // Is listingId already in the url?
    if (id.includes(listingId)) {
      // Nothing to do...
      query.id = id;
      // do we already have 4 listing ids in the url?
    } else if (id.split(',').length < 4) {
      // Tack on the new id
      query.id = `${id},${listingId}`;
    } else {
      // Nothing to do...
      query.id = id;
    }
  } else {
    // If not, add one!
    query.id = listingId.toString();
  }

  // Dispatch a new route
  dispatch(
    push({
      pathname: '/favorites/',
      query,
    })
  );
};

/**
 * removeListing
 *
 * A thunk that pushes another route removing the passed listing id from the url.
 * All UI state around both favorites and comparison table for displaying listings is
 * derived from the URL. This thunk accepts a listing id, looks to see if we currently
 * have that listing id in the search url, and finally pushes a new route removing the listing id.
 *
 * @param {int} listingId - ID of listing being removed
 * @return {func} - A Thunk
 */
export const removeListing = listingId => (dispatch, getState) => {
  // New query object
  const newQuery = {};

  // Current path and query object from store
  const { pathname, query } = getState().router;

  // Current url parameters for id and features
  const { id, features } = query;

  // Pass through features to keep feature state for multiple compares
  if (features) {
    newQuery.features = features;
  }

  // Default path to current path
  let path = pathname;

  // Do we already have id's in the url?
  if (id) {
    // Split and filter out listing id we want to remove
    const listingIds = id.split(',').filter(i => parseInt(i, 10) !== parseInt(listingId, 10));

    // Ensure we have at least 2 listings to compare, if not set path back to favorites
    if (listingIds.length < 2 && path === '/compare/') {
      path = '/favorites/';
    }

    // Finally add remaining listing id's back to the url
    if (listingIds.length > 0) {
      newQuery.id = listingIds.join(',');
    }
  }

  dispatch(
    push({
      pathname: path,
      query: newQuery,
    })
  );
};

/**
 * addFeatures
 *
 * A thunk that pushes another route adding the array of features string to the url.
 * All UI state around for the comparison table for displaying features is derived from the URL.
 * This thunk accepts an array of feature names, ensures it's unique, join's them together in to
 * a string with '%%' and finally base 64 encodes it.
 *
 * @param {array} featuresToAdd - Array of features
 * @return {func} - A Thunk
 */
export const addFeatures = featuresToAdd => (dispatch, getState) => {
  // New query object
  const query = {};

  // Current url parameters for id
  const { id } = getState().router.query;

  // Pass through id to keep listings state for multiple compares
  if (id) {
    query.id = id;
  }

  // Gotta be a better way to check for array in js?
  if (featuresToAdd.length > 0) {
    // Ensure featuresToAdd is unique, join in to a string with '%%' and base 64 encode it
    query.features = window.btoa([...new Set(featuresToAdd)].join('%%'));
  }

  dispatch(
    push({
      pathname: '/compare/',
      query,
    })
  );
};

/**
 * removeFeature
 *
 * A thunk that pushes another route removing the feature from the query string.
 * All UI state around for the comparison table for displaying features is derived from the URL.
 * This thunk accepts a feature name and removes it from current feature array.
 *
 * @param {string} featureToRemove - Name of feature
 * @return {func} - A Thunk
 */
export const removeFeature = featureToRemove => (dispatch, getState) => {
  // Get id's and features from url
  const { id, features } = getState().router.query;
  // If we have features
  if (features) {
    // Decode the base64 encoding
    const decoded = window.atob(features);
    // Split feature string in to array
    const currentFeatures = decoded.split('%%');
    // Filter out feature froma array
    const updatedFeatures = currentFeatures.filter(f => f !== featureToRemove);
    // New query object with id's
    const newQuery = { id };

    // Add features to query object
    if (updatedFeatures.length > 0) {
      newQuery.features = window.btoa(updatedFeatures.join('%%'));
    }

    dispatch(
      push({
        pathname: '/compare/',
        query: newQuery,
      })
    );
  }
};


/**
 * backToFavorites
 *
 * @return {func} - A Thunk
 */
export const backToFavorites = () => (dispatch, getState) => {
  const { id, features } = getState().router.query;

  // Only include IDs that are in the user's favorites.
  const ids = (id || '').split(',')
    .map(x => parseInt(x, 10))
    .filter(x => !Number.isNaN(x) && favorites.findWhere({ id: x }));

  dispatch(
    push({
      pathname: '/favorites/',
      query: {
        ...ids.length && { id: ids.join(',') },
        features
      }
    })
  );
};

/**
 * startNewSearch
 *
 * Probably not completely necessary, but to keep with the other routing
 * actions such as `backToFavorites` for the CardPlaceholder component, here
 * we are. Pushes the user to a new search results view.
 *
 * @return {func} - A Thunk
 */
export const startNewSearch = () => (dispatch) => {
  dispatch(
    push({
      pathname: '/results-gallery/',
      query: {
        photo: 1,
        sort: 'importdate',
        status: 'A',
      },
    })
  );
};

/**
 * compareProperties
 *
 * I guess there is an issue with RLR persistQuery feature.
 * Because of that, we have these actions here for simple routing to
 * maintain url query parameters.
 *
 * @return {func} - A Thunk
 */
export const compareProperties = () => (dispatch, getState) => {
  const { id, features } = getState().router.query;
  dispatch(
    push({
      pathname: '/compare/',
      query: {
        id,
        features,
      },
    })
  );
};

/**
 * onCardClick
 *
 * Function given as a prop to <Card> component to push state to single deets.
 *
 * @return {func} - RLR push
 */
export const onCardClick = push;

/**
 * A copy of `utility.js@addCommas()`.
 * @todo import this places
 * @param  {Number} number
 * @return {String}        A comma-formatted string
 */
export default function addCommas(number) {
  if (!number) {
    return '0';
  }
  return number.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

import { createAction } from 'redux-act';

const action = (desc, ...args) => createAction(`Saved Searches > ${desc}`, ...args);

// Only used on Mobile
export const clickCloseSavedSearches = action('Click close in header');

/**
 * Dispatched when a user selects a Saved Search Item from the Edit Saved Searches view
 */
export const selectSavedSearch = createAction(
  'Select Saved Search',
  /** @param {{ title: string, querystring: string, pathname: string, mapBounds?: Object|null, mapZoom?: number, searchQuery?: Object }} */
  payload => payload
);

export const waitForSavedSearches = action('Waiting for Saved Searches', () => ({}));
export const changeSavedSearchFrequencyRequest = action(
  'Send request to change Saved Search Frequency',
  x => x
);
export const deleteSavedSearchRequest = action('Send request to delete Saved Search', x => x);
export const receiveAPIChangeResp = action('Received response from API change request', x => x);
export const receiveDeleteSavedSearchResponse = action(
  'Receive a response to deleting a saved search',
  ({ response, id }) => ({ response, id })
);
export const closeToast = action('Toast closed');

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * A Cell component that renders a cell within a `Grid`. Must be wrapped in a `Grid` component.
 */
const Cell = props => {
  const classes = classNames('cell', props.className, {
    'cell--noGrow': props.noGrow,
    'cell--noShrink': props.noShrink,
    'cell--autoSize': props.autoSize,
    [`cell--${props.alignSelf}`]: props.alignSelf,
    [`cell-xs-${props.xs}`]: props.xs,
    [`cell-sm-${props.sm}`]: props.sm,
    [`cell-md-${props.md}`]: props.md,
    [`cell-lg-${props.lg}`]: props.lg,
    [`cell-xl-${props.xl}`]: props.xl,
    [`order-xs-${props.xsOrder}`]: props.xsOrder,
    [`order-sm-${props.smOrder}`]: props.smOrder,
    [`order-md-${props.mdOrder}`]: props.mdOrder,
    [`order-lg-${props.lgOrder}`]: props.lgOrder,
    [`order-xl-${props.xlOrder}`]: props.xlOrder
  });

  return (
    <div className={classes} style={props.styles} data-cy={props.dataCY}>
      {props.children}
    </div>
  );
};

Cell.propTypes = {
  /** Anything that can be rendered within the `Cell`: numbers, strings, elements, or an array */
  children: PropTypes.node,
  /** Any extra inline styles that need to be applied to the `cell` div */
  styles: PropTypes.object,
  /** String of additional CSS classes to render on the `Cell` element */
  className: PropTypes.string,
  /**
   * Adds the `cell--noGrow` class to the `Cell` element, preventing the cell from
   * filling the available space (ie `flex-grow: 0;`)
   */
  dataCY: PropTypes.string,
  noGrow: PropTypes.bool,
  /**
   * Adds the `cell--noShrink` class to the `Cell` element, preventing the cell from shrinking
   * relative to the rest of the flex items in the flex container (ie `flex-shrink: 0;`)
   */
  noShrink: PropTypes.bool,
  /**
   * Adds the `cell--autoSize` class to the `Cell` element, sizing the item according to its
   * **width** / **height** properties, but makes it fully inflexible (ie `flex: none | flex: 0 0 auto`)
   */
  autoSize: PropTypes.bool,
  alignSelf: PropTypes.oneOf(['top', 'bottom', 'center', 'baseline', 'stretch']),
  xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'fit', 'auto', 'none']),
  sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'fit', 'auto', 'none']),
  md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'fit', 'auto', 'none']),
  lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'fit', 'auto', 'none']),
  xl: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'fit', 'auto', 'none']),
  xsOrder: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  smOrder: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  mdOrder: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lgOrder: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xlOrder: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  style: PropTypes.object,
};

export default Cell;

import React from 'react';
import PropTypes from 'prop-types';

import Item from './Item';
import Header from './Header';

/**
 * Results Manager
 * Handles rendering of Headers and Item Sets to be used in AutocompleteResults
 * @return {ReactElement}
 */
const ResultsManager = React.forwardRef((props, ref) => {
  const { activeIndex, suggestions, ...rest } = props;

  return suggestions.map((suggestion) => (
    <React.Fragment key={suggestion.name}>
      <Header name={suggestion.name} />
      <ItemSet items={suggestion.items} activeIndex={activeIndex} ref={ref} {...rest} />
    </React.Fragment>
  ));
});

ResultsManager.propTypes = {
  activeIndex: PropTypes.number,
  suggestions: PropTypes.array.isRequired,
};

/**
 * Item Set
 * Handles rendering of multiple items within each chunk
 * @return {ReactElement}
 */
const ItemSet = React.forwardRef((props, ref) => {
  const { items, activeIndex, ...rest } = props;

  return items.map((item) => (
    <Item
      key={`${item.FullName}_${item.index}`}
      ref={ref}
      item={item}
      active={activeIndex === item.index}
      {...rest}
    />
  ));
});

ItemSet.propTypes = {
  activeIndex: PropTypes.number,
  items: PropTypes.array,
};

export default ResultsManager;

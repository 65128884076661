import { createAction } from 'redux-act';

const action = (desc, ...args) => createAction(`Edit Account > ${desc}`, ...args);

// Only used on Mobile
export const clickCloseEditAccount = action('Click close in header');

export const submitEditAccountForm = action('Submit Edit Account Info form');

export const receiveEditAccountSuccess = action(
  'Receive response from edit account request',
  ({ isPhoneUpdated = false }) => ({ isPhoneUpdated })
);
export const receiveEditAccountFailure = action('Receive response from edit account request');

export const closeEditAccountSuccessModal = action('Response Modal > Tap Confirm button');
export const autoCloseEditAccountSuccessModal = action('Response Modal > Toast timeout');
export const closeEditAccountFailureModal = action('Response Modal > Tap Back button');
export const retrySubmitEditAccount = action('Response Modal > Resubmit after failure');

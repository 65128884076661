import React, { useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import _, { sortBy, flatten } from 'underscore';
import cx from 'classnames';
import { config, compliance, utility } from 'BoomTown';
import Tag from 'legacy/Model/tag';

import { InputLabel, InputGroup, PrimaryButton, BaseButton } from 'coreComponents';
import { Grid, Cell } from 'components/core/Grid';
import { CCompDropdown } from 'components/Common/Dropdown';
import FAIcon from 'components/core/FAIcon';
import Autocomplete from 'components/Autocomplete';

import btLocalStorage from 'utility/btLocalStorage';
import SearchTypeContent from './SearchTypeContent';

const BallerBox = (props) => {
  const {
    children,
    account,
    specialRules,
    visitorID,
    isFetchingLocation,
    inputProps = {},
    inputGroupProps = {},
    searchByProps = {},
    searchButtonProps = {},
    renderSearchButton,
    onEmptySubmit,
    onSelectNearbyMe,
    onSelectSuggestion,
    buttonText,
  } = props;

  /**
   * @type {import('react').Ref}
   */
  const inputRef = useRef(null);

  const [
    /** @type {SearchType} TODO: Define type with possible values */
    activeSearchType,
    setActiveSearchType,
  ] = useState('all');

  const [
    /** @type {Error|string} */
    errorState,
    setErrorState,
  ] = useState(null);

  const [
    /** @type {number} */
    activeIndex,
    setActiveIndex,
  ] = useState(null);

  const [
    /** @type {SearchSuggestion[]} */
    suggestionsState,
    setSuggestionsState,
  ] = useState([]);

  /**
   * Passed to the Search By dropdown and used to determine the results returned by the
   * SuggestAPI. Also used to determine the placeholder text for the Autocomplete input.
   *
   * @type {Map<string, SearchTypeObject>}
   */
  const searchTypes = useMemo(() => {
    const isSSL = !config.powerfulFeaturesDeprecated;

    const {
      IsCanadian,
      ShowNeighborhoodSearch,
      ShowSchoolSearch,
      ShowSchoolDistrictSearch,
      ShowListingAreaOnPS,
      ShowCountyOnPS,
    } = account;

    const { ShowSchoolInfo, ShowCounty } = specialRules;

    let postalCodeType = 'ZIP';
    let neighborhoodEntry = {
      type: 'neighborhood',
      placeholder: 'Type Any Neighborhood',
      content: 'Neighborhoods',
    };
    let areanameContent = 'MLS Area';

    // Canadians spell things differently, eh?
    if (IsCanadian) {
      postalCodeType = 'Postal';
      neighborhoodEntry = {
        type: 'neighborhood',
        placeholder: 'Type Any Neighbourhood',
        content: 'Neighbourhood',
      };
      areanameContent = 'MLS® Area';
    }

    const types = new Map([
      [
        'nearby',
        {
          type: 'nearby',
          placeholder: 'Grabbing your location...',
          content: [<FAIcon icon="location-arrow" type="solid" key="icon" />, ' Nearby Me'],
        },
      ],
      [
        'all',
        {
          type: 'all',
          placeholder: `Type any Area, Address, ${postalCodeType}, School, etc.`,
          content: 'SEARCH: All',
        },
      ],
      ['city', { type: 'city', placeholder: 'Type any City', content: 'Cities' }],
      ['neighborhood', neighborhoodEntry],
      ['address', { type: 'address', placeholder: 'Type any Address', content: 'Address' }],
      [
        'listingmls',
        { type: 'listingmls', placeholder: 'Type any MLS#', content: IsCanadian ? 'MLS®' : 'MLS#' },
      ],
      ['school', { type: 'school', placeholder: 'Type any School', content: 'Schools' }],
      [
        'schooldistrict',
        {
          type: 'schooldistrict',
          placeholder: 'Type any School District',
          content: 'School Districts',
        },
      ],
      [
        'postalcode',
        {
          type: 'postalcode',
          placeholder: `Type any ${postalCodeType} Code`,
          content: `${postalCodeType} Code`,
        },
      ],
      [
        'areaname',
        {
          type: 'areaname',
          placeholder: 'Type any Area',
          content: compliance.RestrictMLS ? 'Area' : areanameContent,
        },
      ],
      ['county', { type: 'county', placeholder: 'Type any County', content: 'County' }],
      ['keyword', { type: 'keyword', placeholder: 'Type any Keyword', content: 'Keyword' }],
      ['feature', { type: 'feature', placeholder: 'Type any Feature', content: 'Feature' }],
    ]);

    if (!isSSL) {
      types.delete('nearby');
    }

    if (!ShowNeighborhoodSearch) {
      types.delete('neighborhood');
    }

    if (!ShowSchoolSearch) {
      types.delete('school');
    }

    if (!ShowSchoolDistrictSearch) {
      types.delete('schooldistrict');
    }

    if (!ShowListingAreaOnPS) {
      types.delete('areaname');
    }

    if (!ShowCountyOnPS || !ShowCounty) {
      types.delete('county');
    }

    // If you can't search by school you shouldn't see school
    if (!ShowSchoolInfo) {
      // Remove the school types
      types.delete('school');
      types.delete('schooldistrict');

      // Returns a mutable reference to the object value aassociated with the `all` key. Changing
      // the `placeholder` value here changes the actual Object in `types`.
      const allSearchType = types.get('all');
      if (allSearchType) {
        // Remove text related to schools
        allSearchType.placeholder = `Type any Area, Address, ${postalCodeType}, etc.`;
      }
    }

    return types;
  }, [account, config]);

  /**
   * Used to get the formatted group name for results from the suggestAPI.
   *
   * Used by `formatSuggestions()`
   *
   * @param {string} group
   * @returns {string}
   */
  const getGroupName = (group) => {
    const name = group.toLowerCase();
    const lang = document.documentElement.lang;
    switch (name) {
      case 'postalcode':
        if (lang === 'en-US') {
          return 'zip';
        } else if (lang === 'en-CA' || account.IsCanadian) {
          return 'postal code';
        }
        return 'postal code';
      case 'neighborhood':
        if (lang === 'en-CA' || account.IsCanadian) {
          return 'neighbourhood';
        }
        return 'neighborhood';
      case 'StreetName':
        return 'Street Name';
      case 'listingmls':
        return 'MLS #';
      case 'areaname':
        return 'MLS Area';
      case 'customarea':
        return 'Location';
      default:
        return name;
    }
  };

  /**
   * Reformats the suggest api response for the Autocomplete ResultsManager
   *
   * - Used by `fetchSuggestions()`
   *
   * @param {array} results the list of results returned by the current search value
   * @return {[{ name: string, items: [] }]}
   */
  const formatSuggestions = (results) => {
    // REFACTOR: We shouldn't be making an API call if we are going to throw out the results
    if (activeSearchType === 'keyword') {
      return [];
    }

    results.forEach((s, i) => {
      // Clean the api response before we do anything we treat any `,` delimited list from
      // the api as a single entity, so we string it together with a `~`
      // eslint-disable-next-line no-param-reassign
      s.QSModifier = s.QSModifier.replace(/,/g, '~');

      // Assign an index value to the results to be keyed through
      // eslint-disable-next-line no-param-reassign
      s.index = i;
    });

    // Group the results by type, then reformat into [{items: [], name: ''}]
    let i = 0;
    const suggestions = _.chain(results)
      .groupBy((obj) => obj.Type)
      .map((items, group) => ({
        // We want to ensure they are sorted by index
        items: sortBy(items, (o) => o.index),
        name: getGroupName(group),
      }))
      .each((g) =>
        g.items.forEach((s) => {
          // But we really want the down key to move through the list
          // top to bottom, not skip around
          // eslint-disable-next-line no-param-reassign
          s.index = i;
          i++;
        })
      )
      .value();

    return suggestions;
  };

  /**
   * Fetches autocomplete results from the suggestAPI.
   *
   * - Used by the Autocomplete component when the Input field is clicked or changed.
   *
   * @param {string} value
   */
  const fetchSuggestions = (value) => {
    // Format the request
    const request = {
      tenantID: account.TenantID,
      q: value,
      maxResults: 15,
      type: activeSearchType,
      visitorid: visitorID,
    };

    // JSONp because the suggest api doesn't have cors headers
    // TODO: Refactor to not use `jQuery` here.
    $.getJSON(`${config.suggestApiUrl}/1/SuggestListings?callback=?`, request, (data) => {
      const {
        Status: { Code },
        Result,
      } = data;

      if (Code === 200) {
        // Create the suggestions array
        const suggestions = formatSuggestions(Result);

        // Append keyword suggestions to the array
        const normalizedValue = value === undefined ? '' : value;

        if (
          normalizedValue.length > 0 &&
          (activeSearchType === 'all' || activeSearchType === 'keyword')
        ) {
          const chunk = {
            items: [
              {
                Name: '',
                QSModifier: `keyword=${normalizedValue}`,
                PhoneticName: '',
                FullName: normalizedValue,
                Type: 'Keyword',
                index: Result.length,
              },
            ],
            name: 'Keyword',
          };

          suggestions.push(chunk);
        }

        setSuggestionsState(suggestions);
        setActiveIndex(0);
      }
    });
  };

  /**
   * Submit handler for the `form` element wrapping the ballerbox.
   *
   * @param {Event} event
   */
  const handleSubmit = (event) => {
    event.preventDefault();

    try {
      const value = inputRef?.current.value;

      const flattenedSuggestions = flatten(
        Object.keys(suggestionsState).map((item) => suggestionsState[item].items)
      );
      const filteredSuggestionsArr = flattenedSuggestions.filter(
        (item) => item.index === activeIndex
      );
      const suggestion = filteredSuggestionsArr[0];

      if (value?.length === 0 && onEmptySubmit) {
        onEmptySubmit();
      } else {
        handleSuggestionSelect(suggestion);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setErrorState(error);
    }
  };

  /**
   * @param {SearchSuggestion}
   */
  const handleSuggestionSelect = (selectedItem) => {
    let terms = {};
    const data = selectedItem;

    if (data && selectedItem.index >= 0) {
      terms = utility.parseQueryString(data.QSModifier);
      const payloadKeys = Object.keys(terms);

      // Store the display name of the selected suggestion in local storage
      if (payloadKeys.length) {
        const searchTermProp = payloadKeys[0];
        const searchTermValue = terms[searchTermProp];

        btLocalStorage(
          Tag.localStorageNamespace,
          Tag.getLocalStorageKey(searchTermProp, searchTermValue),
          data.FullName
        );
      }
    } else if (data?.FullName) {
      terms = { keyword: data.FullName };
    }

    onSelectSuggestion(terms);
  };

  /**
   * Gets replaced with a function to close the search type dropdown. Using a ref here so we don't
   * lose the instance that actually contains the close() fn from the Dropdown component.
   *
   * Passed to CCompDropdown -> Dropdown
   *
   * This is awful, but refactoring the CCompDropdown tree of components might be out of scope
   * for the Ballerbox refactor unfortunately.
   *
   * @type {import('react').Ref}
   */
  const _closeSearchTypeDrop = useRef(() => null);

  /**
   * Sets the active search type.
   *
   * @param {SearchType} type
   */
  const handleSearchTypeClick = (type) => {
    _closeSearchTypeDrop.current();

    const isNearbySelection = type === 'nearby';
    const appliedType = isNearbySelection ? 'all' : type;

    // If you come back and click nearby again we want a no op.
    if (isFetchingLocation) {
      return;
    }

    // TODO: Is there anyway I can update this to use the new Search model from Redux?
    // If not, then we'll have to add the search model via connectBB
    window.bt.search.type = appliedType;

    setActiveSearchType(appliedType);

    if (isNearbySelection) {
      onSelectNearbyMe();
      return; // Return early, no need to focus the input field.
    }

    inputRef.current.focus();
  };

  /**
   * Get the placeholder text to display in the Autocomplete input field
   *
   * @returns {string}
   */
  const getPlaceholderText = () => {
    if (isFetchingLocation) {
      return 'Grabbing Your Location...';
    }

    const activeTypeInfo = searchTypes.get(activeSearchType);
    return activeTypeInfo.placeholder;
  };

  /**
   * ID used to associate the InputLabel with the Autocomplete input field.
   * @type {string}
   */
  const autoCompleteID = 'bt-ballerbox__autocomplete-input--id';
  const getAutoCompleteProps = () => {
    const { className, ...rest } = inputProps;
    return {
      id: autoCompleteID,
      name: autoCompleteID,
      grouped: true,
      className: cx('ballerbox__autocomplete-input', { [className]: className }),
      ...rest,
    };
  };

  /**
   * Function passed to the CCompDropdown component as the dropdown trigger.
   *
   * @returns {import('react').ReactElement}
   */
  const renderSearchByTrigger = () => (
    <BaseButton
      className="at-searchby-trigger form__input-prefix ballerbox__searchby-button"
      width="auto"
      name="ballerbox__searchby-button"
    >
      Search By <FAIcon icon="angle-down" type="regular" />
    </BaseButton>
  );

  /**
   * Function passed to the CCompDropdown component as the dropdown content.
   *
   * @returns {import('react').ReactElement}
   */
  const renderSearchByContent = () => (
    <SearchTypeContent
      searchTypes={searchTypes}
      activeType={activeSearchType}
      handleClick={handleSearchTypeClick}
    />
  );

  return (
    <form onSubmit={handleSubmit}>
      <InputLabel hidden htmlFor={autoCompleteID}>
        Search Properties
      </InputLabel>

      <Grid className={inputGroupProps.className}>
        <Cell>
          <InputGroup>
            <CCompDropdown
              className="height-1-1"
              getClose={(close) => (_closeSearchTypeDrop.current = close)}
              size="small"
              trigger={renderSearchByTrigger()}
              content={renderSearchByContent()}
              {...searchByProps}
            />
            <Autocomplete
              ref={inputRef}
              inputProps={getAutoCompleteProps()}
              placeholderText={getPlaceholderText()}
              fetchSuggestions={fetchSuggestions}
              handleItemSelect={handleSuggestionSelect}
              handleSetActiveIndex={setActiveIndex}
              suggestions={suggestionsState}
              activeSearchType={activeSearchType}
              activeIndex={activeIndex}
              hasError={errorState}
            />
          </InputGroup>
        </Cell>
        {renderSearchButton && (
          <Cell autoSize>
            <PrimaryButton type="submit" className="ml-8" color="override" width="auto" {...searchButtonProps}>
              <FAIcon
                icon="search"
                type="solid"
                title="Find Your Home"
                className="ballerbox__search-button__icon"
              />
              <span className="ballerbox__search-button__text">{buttonText}</span>
            </PrimaryButton>
          </Cell>
        )}
      </Grid>
      {children}
    </form>
  );
};

BallerBox.propTypes = {
  buttonText: PropTypes.string,
  children: PropTypes.node,
  account: PropTypes.object,
  specialRules: PropTypes.object,
  visitorID: PropTypes.number,
  isFetchingLocation: PropTypes.bool,
  renderSearchButton: PropTypes.bool,
  inputProps: PropTypes.object,
  inputGroupProps: PropTypes.object,
  searchButtonProps: PropTypes.object,

  // See CCompDropdown for available props and how they're used
  searchByProps: PropTypes.object,
  onSelectNearbyMe: PropTypes.func,
  onSelectSuggestion: PropTypes.func,
  onEmptySubmit: PropTypes.func,
};

BallerBox.defaultProps = {
  buttonText: 'Find Your Home',
};

export default BallerBox;

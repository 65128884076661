import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import * as r from 'routes';
import * as MobileGalleryActions from 'actions/MobileGalleryActions';
import isRoute from 'utility/isRoute';
import { handleSortClick, maybeFetchSearchResults } from './effects';

export default function* mobileGallerySaga() {
  yield all([
    takeEvery(MobileGalleryActions.clickSortOption, handleSortClick),
    takeLatest(isRoute(r.RESULTS_GALLERY), maybeFetchSearchResults),
  ]);
}

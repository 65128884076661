import { compose } from 'recompose';
import { connect } from 'react-redux';
import createForm from 'hoc/createForm';
import { isSubmittingChangePassword, getResponseModal } from './reducer';
import {
  submitPasswordChange,
  focusFormField,
  clickCloseChangePasswordSuccessModal,
  clickBackInChangePasswordFailureModal,
  clickRetryInChangePasswordFailureModal,
} from './actions';

// Export for use in ChangePassword
export const FieldIDs = {
  Current: 'current',
  New: 'new',
  NewRepeated: 'newRepeated',
};

const requiredFieldValidation = {
  isValid: value => value.length > 0,
  message: 'Required field',
};

/**
 * Validate Password
 * @param {string} passwd
 * @return bool
 */
const validatePassword  = (passwd) => {
  const alpha = new RegExp(/[A-Za-z]/);
  const numeric = new RegExp(/[0-9]/);
  const whitespace = new RegExp(/\s/);

  if (passwd.length < 8) {
    return false;
  }

  if (passwd.length > 20) {
    return false;
  }

  if (!alpha.test(passwd)) {
    return false;
  }

  if (!numeric.test(passwd)) {
    return false;
  }

  if (whitespace.test(passwd)) {
    return false;
  }

  return true;
}

export default compose(
  connect(
    state => ({
      isSubmitting: isSubmittingChangePassword(state),
      ...getResponseModal(state),
    }),
    {
      onSubmit: submitPasswordChange,
      onFieldFocus: focusFormField,
      onClickCloseInRespModal: clickCloseChangePasswordSuccessModal,
      onClickBackInRespModal: clickBackInChangePasswordFailureModal,
      onClickRetryInRespModal: clickRetryInChangePasswordFailureModal,
    }
  ),
  createForm([
    {
      name: FieldIDs.Current,
      type: 'text',
      initialState: '',
      validation: requiredFieldValidation,
    },
    {
      name: FieldIDs.New,
      type: 'text',
      initialState: '',
      validation: [
        requiredFieldValidation,
        {
          isValid: (value) => validatePassword(value),
          message: 'Your password must contain letters and numbers, be 8 characters in length, and contain no spaces.'
        }
      ],
    },
    {
      name: FieldIDs.NewRepeated,
      type: 'text',
      initialState: '',
      validation: [
        {
          isValid: (value, formData) => value === formData[FieldIDs.New],
          message: 'Passwords do not match',
        },
        requiredFieldValidation,
      ],
    },
  ])
);

import PropTypes from 'prop-types';
import React from 'react';
import connectBB from 'hoc/connectBB';
import { tags } from 'BoomTown';
import { SecondaryButton } from 'coreComponents';
import { CCompDropdown } from 'components/Common/Dropdown';
import DesktopOverflowTagList from 'components/Common/Tags/DesktopOverflowTagList';

const OverflowTagListDropdown = (props) => {
  if (props.tags && props.tags.length) {
    return (
      <CCompDropdown
        align="right"
        trigger={(
          <SecondaryButton
            size="small"
            className="bt-search-overflow__trigger"
          >
            {`+ ${props.tags.length} More`}
          </SecondaryButton>
        )}
        content={(
          <DesktopOverflowTagList
            className="bt-overflow__more-list"
            tags={props.tags}
          />
        )}
      />
    );
  }

  return null;
};

OverflowTagListDropdown.displayName = 'OverflowTagListDropdown';
OverflowTagListDropdown.propTypes = {
  // The tags that should be rendered inside this dropdown. The filtering
  // logic will be split out from this component.
  tags: PropTypes.array
};

const applyTagsProps = props => ({
  ...props,
  tags: tags.toJSON().filter(tag => tag.overflow)
});

export default connectBB(
  OverflowTagListDropdown,
  [
    {
      getEmitter: () => tags,
      events: [
        { name: 'reset', replaceProps: applyTagsProps },
        { name: 'change', replaceProps: applyTagsProps }
      ]
    }
  ],
);

/**
 * Block helper that renders the block if a == b is truthy.
 * If an inverse block is specified it will be rendered
 * when a == b is falsy.
 *
 * @param {any} `a`
 * @param {any} `b`
 * @param {Object} `options` Handlebars provided options object
 * @return {String}
 * @block
 * @api public
 */

module.exports = function ifEq(a, b, options) {
  if (a === b) {
    return options.fn(this);
  }
  return options.inverse(this);
};

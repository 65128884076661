import { isEmpty, pick } from 'underscore';
import { call, select, put } from 'redux-saga/effects';
import { LOCATION_CHANGED } from 'redux-little-router';
import { search as searchModel } from 'BoomTown';

import { getSearchQuery } from 'selectors/searchSelectors';
import { isMobile } from 'selectors/browser';
import { MOBILE_MAP_PAGE_COUNT, DESKTOP_MAP_PAGE_COUNT } from 'screens/ResultsMap/constants';
import getPaginationState from 'screens/ResultsMap/Desktop/ResultsPagination/getPaginationState';
import { getRouteID } from 'selectors/router';
import { getLocationChanges } from 'reducers/locationChanges';
import * as r from 'routes';

import fetchMapResults from './fetchMapResults';
import * as a from '../actions';
import { getSearchAndBounds } from '../reducer';
import { getActiveListingID } from '../../reducer';
import beefUpListings from './beefUpListings';

/**
 * Ensure that results are available for the current search and mapBounds.
 */
export default function* syncMapResults(action) {
  const { payload } = action;

  // CNS-6165: syncMapResults should not be executed on non-map routes.
  // For routes other than /results-map/ we have component-specific effect patterns
  // that should be run in its place
  const currentRoute = yield select(getRouteID);
  if (currentRoute !== r.RESULTS_MAP) {
    return;
  }

  // Don't make an API request for the initial LOCATION_CHANGED action (server-side load).
  // Instead wait for the onMapIdle action to occur, verifying we have the correct map boundaries
  // for our search. Fixes a race condition that frequently led to multiple API requests on page
  // load.
  if (action.type === LOCATION_CHANGED) {
    const locationChanges = yield select(getLocationChanges);

    if (locationChanges === 1) {
      return;
    }
  }

  // There's an issue when we remove map parmas from the url where the new LOCATION_CHANGE action
  // w/o the map params gets handled first, then the initial LOCATION_CHANGE action that included
  // the map params is handled. I was hoping to be able to just cancel this initial action, but
  // couldn't reliably figure out how to do so, so this guard will prevent an extra API request from
  // going out in that situation (really just for legacy map urls)
  if (payload && payload.query && !isEmpty(pick(payload.query, searchModel.coordinateFilters))) {
    return;
  }

  const previewListingID = yield select(getActiveListingID);
  if (previewListingID !== null) {
    return;
  }

  const { search: localSearch, mapBounds } = yield select(getSearchAndBounds);
  const searchFilters = yield call([searchModel, searchModel.toJSON]);
  const nextResultsQuery = getSearchQuery(localSearch, searchFilters, mapBounds);

  if (!nextResultsQuery) {
    return;
  }

  /** @type {PaginationState} */
  let paginationState = { pageIndex: 0 };
  const isMobileXP = yield select(isMobile);
  const pageCount = isMobileXP ? MOBILE_MAP_PAGE_COUNT : DESKTOP_MAP_PAGE_COUNT;

  const request = {
    ...nextResultsQuery,
    sort: 'popularity',
    pageindex: paginationState.pageIndex,
    pagecount: pageCount,
  };

  /** @type {{result: Object, totalItemsWithoutCoords: Number}|boolean} */
  const response = yield call(fetchMapResults, request, searchFilters);

  if (response.error) {
    return;
  }

  const { Items, TotalItems } = response.result;
  let newListings = Items;

  if (!isMobileXP) {
    // To avoid all sorts of var scope issues and to keep our code DRY, we use `getPaginationState()`
    // to manage getting the correct indexes for pagination, as well as the logic around the
    // MaxListingResults special rule
    ({
      paginationState,
      newListings,
    } = yield getPaginationState(paginationState, newListings, TotalItems));

    // Filter listings to remove items without coordinates
    // If we're not on mobile, apply this filter while running `beefUpListings()`
    newListings = yield call(beefUpListings, newListings);
  } else {
    // Otherwise, run the filter by itself
    newListings = newListings.filter(filterMapListings);
  }

  yield put(
    a.receiveResults({
      listings: newListings,
      search: nextResultsQuery,
      totalItems: TotalItems,
      totalItemsWithoutCoords: response.totalItemsWithoutCoords,

      mapBounds,
      ...paginationState,
    })
  );
}

/**
 * Test for the existence and/or quality of a listing's Latitude value.
 *
 * Note: We may want to check for Longitude here as well, but I figured if one of these isn't set
 * correctly then it should probably rule the listing out. Plus, not checking for Longitude saves
 * us the extra overhead of performing a 2nd check for each listing.
 * @param {FlagshipAPI.ListingSnapshot} listing
 */
export function hasCoords(listing) {
  return listing?.Location?.Coordinates?.Latitude;
}

/**
 * Listings should only render for map boundary based searches if they have coordinates and
 * `HideAddress` is false.
 *
 * @param {FlagshipAPI.ListingSnapshot} listing
 * @returns {boolean}
 */
export function filterMapListings(listing) {
  return hasCoords(listing) && !listing.HideAddress;
}

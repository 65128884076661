import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { config } from 'BoomTown';

/**
 * @param {function} View - The Backbone view class that will be wrapped
 * @returns {class}
 */
export default function backboneToReactView(View) {
  class ReactView extends Component {
    static propTypes = {
      initialMount: PropTypes.bool,
      html: PropTypes.string,
      router: PropTypes.object
    }

    componentDidMount() {
      this.viewInstance = new View({ el: this.el });
      if (this.props.initialMount && this.props.html) {
        this.el.innerHTML = this.props.html;
        this.viewInstance.bindToDOM({ router: this.props.router });
      } else {
        this.viewInstance.mount({ router: this.props.router });
      }
    }

    componentDidUpdate() {
      this.viewInstance.update(this.props);
    }

    componentWillUnmount() {
      this.viewInstance.stopListening();
      this.viewInstance.undelegateEvents();
      this.viewInstance.viewWillUnmount();
    }

    render() {
      return <div ref={c => (this.el = c)} />;
    }
  }

  return connect(state => {
    const routeID = (state.router.result && state.router.result.id) || '';
    const html = state.cachedHtml[routeID] || '';

    if (config.isCustomHomepage) {
      window.location.href = state.router.pathname + state.router.search;
      return;
    }

    return {
      initialMount: state.locationChanges <= 1,
      html,
      router: state.router,
    };
  })(ReactView);
}

import { app, config, visitor } from 'BoomTown';
import { call } from 'redux-saga/effects';
import { photoSliderData } from 'constants/registrationForms';

/**
 * Main slider slidin'
 * @param {*} action
 */
export default function* shouldSqueezePhotoSlider(action) {
  const {
    payload: { slideIndex },
  } = action;

  // GTM slide event tracking
  if (window.dataLayer) {
    yield call([window.dataLayer, window.dataLayer.push], {
      event: 'mainPhotoSliderTriggered',
    });
  }

  const { photoSqueeze } = config;
  if (slideIndex >= photoSqueeze && !visitor.isRegistered()) {
    yield call([app, app.squeezeForm], photoSliderData.urlParam, false, false);
  }
}

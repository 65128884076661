import PropTypes from 'prop-types';
import React, { Component } from 'react';

import $ from 'jquery';
import { tags } from 'BoomTown';
import TagList from './TagList';

// REFACTOR: There is some sneaky stuff going on here
// 1. setState is being used to cause a re-render instead of
// storing the state that is needed to render
// 2. cids are an internal representation of BB and aren't reused
// when the collection is reset
// 3. overflow=true is view state specific to this component that
// is being tossed onto a collection and model that is more global

class DesktopTagList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.update();
      this.setState({ isMounted: true });
    }, 100);
  }

  componentDidUpdate() {
    this.update();
  }

  /**
   * Set a reference to the rendered <ul>
   * @param  {HTMLElement} c
   */
  _setListRef = c => {
    this._list = c;
  };

  handleTagClick = (e, { prop, value }) => this.props.onTagClick({ prop, value });

  update() {
    const jqList = $(this._list);
    const jqTags = jqList.children().toArray();

    if (jqTags.length) {
      const firstTop = $(jqTags[0]).position().top;
      const overflowCids = jqTags
        .map((tag, i) => ($(tag).position().top !== firstTop ? this.props.tags[i].cid : null))
        .filter(prop => prop);

      // Reset the tags coll. with all overflowing SearchTags marked as overflow
      if (overflowCids.length) {
        const newTags = tags.toJSONWithCid();
        newTags.forEach(tag => {
          if (~overflowCids.indexOf(tag.cid)) {
            tag.overflow = true; // eslint-disable-line no-param-reassign
          }
        });
        tags.reset(newTags);
      }
    }
  }

  render() {
    if (!this.state.isMounted) return null;

    return (
      <TagList
        tags={this.props.tags.filter(tag => !tag.overflow)}
        listRef={this._setListRef}
        onTagClick={this.handleTagClick}
      />
    );
  }
}

DesktopTagList.displayName = 'DesktopTagList';
DesktopTagList.propTypes = {
  tags: PropTypes.array,
  onTagClick: PropTypes.func.isRequired,
};

export default DesktopTagList;

import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { BaseButton, Radio } from 'coreComponents';
import emptyFunction from 'utility/emptyFunction';
import FAIcon from 'components/core/FAIcon';
import { CCompDropdown } from 'components/Common/Dropdown';
import tryParseFloat from 'utility/tryParseFloat';

const RadioDropdown = (props) => {
  const {
    id,
    className,
    value,
    options,
    isActive,
    onSelect,
    variation = 'primary',
    title,
    dataCYButton,
    dataCYOptions,
    fullWidth,
    dropdownAlignment = 'right',
  } = props;

  const handleChange = (e) => {
    const parsedValue = parseFloat(e.target.value);
    if (!isNaN(parsedValue)) {
      onSelect(parsedValue);
    }
  };

  const currentValue = tryParseFloat(value, 0);

  return (
    <CCompDropdown
      size="small"
      fullWidth
      align={dropdownAlignment}
      trigger={
        <BaseButton
          variation={variation}
          className={cx('bt-filter__button js-search-beds', className)}
          onClick={(e) => e.preventDefault()}
          isActive={isActive}
          dataCY={dataCYButton}
          width={fullWidth && 'full'}
        >
          {title} <FAIcon icon="angle-down" type="regular" />
        </BaseButton>
      }
      triggerClassName={`at-${id}-trigger`}
      content={
        <RadioDropContent
          id={id}
          currentValue={currentValue}
          onChange={handleChange}
          options={options}
          dataCY={dataCYOptions}
        />
      }
    />
  );
};

RadioDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array.isRequired,
  isActive: PropTypes.bool,
  onSelect: PropTypes.func,
  btStyle: PropTypes.string,
  title: PropTypes.string.isRequired,
  dataCYButton: PropTypes.string,
  dataCYOptions: PropTypes.string,
  fullWidth: PropTypes.bool,
  variation: PropTypes.oneOf([
    'primary',
    'secondary',
  ]),
  dropdownAlignment: PropTypes.oneOf([
    'right',
    'left',
    'center',
  ]),
};

RadioDropdown.defaultProps = {
  value: '0',
  onSelect: emptyFunction,
};

export const RadioDropContent = (props) => {
  const { currentValue, onChange, id, options, dataCY, } = props;

  return (
    <div className="bt-ccomp__content__inner">
      <ul className="uk-list uk-list-space m-0">
        {options.map((v) => (
          <li key={v}>
            <Radio
              checked={v === currentValue}
              className={`at-${id}`}
              dataCY={dataCY ? `${dataCY}--${v}` : ''}
              id={`${id}-${v}`}
              label={v === 0 ? 'Any' : `${v}+`}
              labelProps={{ 'data-cy': dataCY ? `${dataCY}--${v}__label` : '' }}
              name={id}
              onChange={onChange}
              value={v}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

RadioDropContent.propTypes = {
  id: PropTypes.string,
  currentValue: PropTypes.number,
  onChange: PropTypes.func,
  options: PropTypes.array,
  dataCY: PropTypes.string,
};

export default RadioDropdown;

import * as r from 'routes';
import { config } from 'BoomTown';
import { isSearchMenuOpen, isSaveSearchMenuOpen } from 'selectors/mobileGallery';
import { isStartAnOfferOpen } from 'selectors/startAnOffer';
import { isMobile } from 'selectors/browser';

/**
 * @param {State} state
 * @return {string} Empty or one of the strings from /routes.js
 */
export function getRouteID(state) {
  return (state.router.result && state.router.result.id) || '';
}

/**
 * @param {State} state
 * @return {Boolean}
 */
export function isFavoritesRoute(state) {
  return state.router.query.favs === '1';
}

/**
 * Determine if the current route is the `/results-map/` route.
 * @param {State} state
 * @return {Boolean}
 */
export const isMapRoute = (state) => {
  if (!state.router.result) {
    return false;
  }

  return state.router.result.id === r.RESULTS_MAP;
};

/**
 * On mobile we hide the header nav and the footer on full screen routes
 * @param {*} state
 */
export function isFullScreenMobileRoute(state) {
  const isMobileViewPort = isMobile(state);
  if (!isMobileViewPort) return false;

  // Modals & Full-screen almost routes
  if (isStartAnOfferOpen(state) || isSearchMenuOpen(state) || isSaveSearchMenuOpen(state)) {
    return true;
  }

  const id = getRouteID(state);

  // The new map is full screen.
  if (id === r.RESULTS_MAP) {
    return true;
  }

  // Full screen routes
  return [
    r.MENU,
    r.CONTACT_AGENT,
    r.EDIT_ACCOUNT,
    r.EMAIL_PREFERENCES,
    r.TEXT_PREFERENCES,
    r.EDIT_SAVED_SEARCHES,
    r.CHANGE_PASSWORD,
    r.ACCOUNT,
  ].includes(id);
}

/**
 * @param {{router: object}} state
 * @return {string?}
 */
export function getPostRegistrationParam(state) {
  return state.router.query[config.conversionQueryStringName];
}

/**
 *
 * @param {State} state
 * @return {boolean}
 */
export function isEAlertResults(state) {
  const isGallery = getRouteID(state) === r.RESULTS_GALLERY;
  return state.router.query.rid && isGallery;
}

/**
 * Get the raw `state.router` object.
 *
 * @param {Object} state
 */
export function getRouterState(state) {
  return state.router;
}

/**
 * Retrieve the `search` property off the `router` branch of our global state tree
 *
 * @export
 * @param {Object} state
 * @returns string - The current search query string
 */
export function getSearchQueryString(state) {
  return state.router.search || '';
}

export function getPolygon(state) {
  return state.router.query.polygon || '';
}

export function isFBBrowserRedirect(state) {
  return state.router.query.fb_register || '';
}

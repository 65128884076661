import { search as searchModel, listings } from 'BoomTown';
import * as BackboneActions from 'actions/BackboneActions';
import { logNoResults } from 'components/NoResults/actions';
import { isResultsPath } from 'legacy/Routing/routerUtils';


/**
 * Convert events on Backbone models and collections to actions.
 * Used when initializing our store object.
 *
 * @param {Object} store The global Redux store
 */
export default function convertBBEventsToActions(store) {
  searchModel.on('change', (model, options) => {
    store.dispatch(BackboneActions.searchChange({ model, options }));
  });

  listings.on('reset', collection => {
    store.dispatch(BackboneActions.listingsReset({ totalItems: collection.TotalItems }));

    // log No Results in GTM
    if (isResultsPath() && collection.TotalItems === 0) {
      store.dispatch(logNoResults());
    }
  });
}

import { connect } from 'react-redux';
import { getFavoriteIDs, getIsRegistered } from 'models/visitor/reducers/selectors';
import {
  getIncreaseDisclaimerFontSize,
  getMatchDisclaimerFontStyles,
} from 'models/specialRules/reducers/selectors';
import { isMobile } from 'selectors/browser';
import { onListingCardClick, onListingCardFavoriteClick } from './actions';

import Card from './component';

/**
 * Check to see if our listing ID exists in the visitor's Favorites array.
 *
 * @param {object} state
 * @param {number} id
 * @returns {boolean}
 */
const isFavorited = (state, id) => {
  const ids = getFavoriteIDs(state);

  return ids?.includes(id);
};

export default connect(
  (state, ownProps) => {
    const { listing } = ownProps;

    // CNS-7889: For now, we should only show the photo slider for registered users on mobile
    const renderPhotoSlider =
      isMobile(state) && getIsRegistered(state) && listing?.Photos?.length > 1;

    return {
      isFavorited: isFavorited(state, listing?._ID),
      increaseDisclaimerFontSize: getIncreaseDisclaimerFontSize(state),
      matchDisclaimerFontStyles: getMatchDisclaimerFontStyles(state),

      // Hopefully we can remove the need for this prop entirely by rendering the photo count for
      // listing cards regardless of mobile and whether or not the use is registered.
      renderPhotoCount: Boolean(!renderPhotoSlider && listing.Photos.length > 0),
      renderPhotoSlider,
    };
  },
  {
    onCardClick: onListingCardClick,
    onFavoriteClick: onListingCardFavoriteClick,
  }
)(Card);

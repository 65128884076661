import { createReducer } from 'redux-act';
import { LOCATION_CHANGED } from 'redux-little-router';
import { savedSearchesData as saveSearchRegForm } from 'constants/registrationForms';
import { getConversionQueryStringName } from 'models/appConfig/selectors';
import { openModal, closeModal } from '../actions';
import { MODAL_STATUS, MODAL_IDS } from '../constants';

/**
 * @typedef ModalState
 * @type {import('../types').ModalState}
 */

/**
 * @type {ModalState}
 */
const initialState = {
  status: MODAL_STATUS.CLOSED,
  id: null,
};

/**
 * Reducer for handling the location change after a user registers from a saved search
 *
 * @param {ModalState} state
 * @param {import('redux-little-router').Location} payload
 * @param {{ locationChanges: number }} meta
 *
 * @returns {ModalState}
 */
const locationSaveSearch = (state, payload, meta) => {
  const { query } = payload;

  if (!query) {
    return null;
  }

  const postRegistrationParam = getConversionQueryStringName();
  const postRegistrationValue = query[postRegistrationParam];
  if (!postRegistrationValue && meta?.locationChanges === 1) {
    return null;
  }

  // Add additonal cases here for the various modals
  switch (postRegistrationValue) {
    case saveSearchRegForm.urlParam:
      return {
        status: MODAL_STATUS.OPEN,
        id: MODAL_IDS.SAVE_SEARCH,
      };

    default:
      return null;
  }
};

/**
 * Reducer for handling the location change with the `oneClickModal` query param
 *
 * @param {import('redux-little-router').Location} payload
 * @returns {ModalState}
 */
const locationOneClick = (payload) => {
  const { query } = payload;

  if (window.location.pathname === '/') {
    if (query && query.oneClickModal) {
      return {
        status: MODAL_STATUS.OPEN,
        id: MODAL_IDS.ONE_CLICK,
      };
    }

    return initialState;
  }

  return null;
};

const modalReducer = {
  [LOCATION_CHANGED]: (state, payload, meta) => {
    let nextState = null;

    nextState = locationOneClick(payload);
    if (nextState) {
      return nextState;
    }

    nextState = locationSaveSearch(state, payload, meta);
    if (nextState) {
      return nextState;
    }

    return state;
  },

  [openModal]: (state, id) => ({
    status: MODAL_STATUS.OPEN,
    id,
  }),

  [closeModal]: () => initialState,
};

export default createReducer(modalReducer, initialState);

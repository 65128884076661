import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

/**
 * Inner Footer component used by the Footer component and various other components
 *
 * @export
 * @component FooterInner
 */
export const FooterInner = (props) => {
  const { children, className, dataCY } = props;

  const classes = cx(
    'container pt-16 pb-16--safe px-16--safe shadow-up mobile-screen__footerinner',
    className
  );

  return (
    <div className={classes} data-cy={dataCY}>
      {children}
    </div>
  );
};

FooterInner.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
  className: PropTypes.any,
  dataCY: PropTypes.string,
};

/**
 * Footer component used by MobileScreen and various other components
 *
 * @export
 * @component Footer
 */
const Footer = (props) => {
  const { isVisible, ...rest } = props;

  return (
    <CSSTransitionGroup
      transitionName="footer-"
      transitionEnterTimeout={500}
      transitionLeaveTimeout={100}
    >
      {isVisible && <FooterInner {...rest} />}
    </CSSTransitionGroup>
  );
};

Footer.propTypes = {
  isVisible: PropTypes.bool,
};

Footer.defaultProps = {
  isVisible: true,
};

export default Footer;

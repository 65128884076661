import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { config, tenant, rules } from 'BoomTown';

import Sticky from 'react-stickynode';
import { PrimaryButton } from 'coreComponents';
import ButtonToolbar from 'components/core/Buttons/ButtonToolbar';
import DesktopTagList from 'components/Common/Tags/DesktopTagListContainer';
import OverflowTagListDropdown from 'components/SearchMenu/OverflowTagListDropdown';
import SortResults from 'components/SearchBar/SortResults';
import ResultsViewToggle from 'components/SearchBar/ResultsViewToggle';
import { SEARCH_BAR, DESKTOP_SORT_OPTIONS } from 'cypress_constants';

class SearchBar extends Component {
  static propTypes = {
    routeName: PropTypes.string,
    onClickSaveSearch: PropTypes.func,
    onSortClick: PropTypes.func.isRequired,
    onResultsViewTypeClick: PropTypes.func.isRequired,

    search: PropTypes.shape({
      hasFavs: PropTypes.bool.isRequired,
      hasOfficeID: PropTypes.bool.isRequired,
      hasRID: PropTypes.bool.isRequired,
      showDistance: PropTypes.bool.isRequired,
      sort: PropTypes.string,
    }),
    tags: PropTypes.array,
    children: PropTypes.element.isRequired,
  };

  static displayName = 'SearchBar';

  getSortOptions({ showDistance = false, activeValue = '', disableAcreage = false }) {
    // Spaces intentionally left in front of the label
    let sortOptions = [
      {
        value: 'listprice',
        label: ' Price (Highest)',
        cySelector: DESKTOP_SORT_OPTIONS.HIGHEST_PRICE,
        cyLabelSelector: DESKTOP_SORT_OPTIONS.HIGHEST_PRICE_LABEL,
      },
      {
        value: 'listprice_asc',
        label: ' Price (Lowest)',
        cySelector: DESKTOP_SORT_OPTIONS.LOWEST_PRICE,
        cyLabelSelector: DESKTOP_SORT_OPTIONS.LOWEST_PRICE_LABEL,
      },
      {
        value: 'popularity',
        label: ' Most Popular',
        cySelector: DESKTOP_SORT_OPTIONS.MOST_POPULAR,
        cyLabelSelector: DESKTOP_SORT_OPTIONS.MOST_POPULAR_LABEL,
      },
      {
        value: 'bedrooms',
        label: ' Bedrooms (Most)',
        cySelector: DESKTOP_SORT_OPTIONS.MOST_BEDROOMS,
        cyLabelSelector: DESKTOP_SORT_OPTIONS.MOST_BEDROOMS_LABEL,
      },
      {
        value: 'fullbaths',
        label: ' Bathrooms (Most)',
        cySelector: DESKTOP_SORT_OPTIONS.MOST_BATHROOMS,
        cyLabelSelector: DESKTOP_SORT_OPTIONS.MOST_BATHROOMS_LABEL,
      },
      {
        value: 'acreage',
        label: ' Acreage (Highest)',
        cySelector: DESKTOP_SORT_OPTIONS.HIGHEST_ACREAGE,
        cyLabelSelector: DESKTOP_SORT_OPTIONS.HIGHEST_ACREAGE_LABEL,
      },
      {
        value: 'acreage_asc',
        label: ' Acreage (Lowest)',
        cySelector: DESKTOP_SORT_OPTIONS.LOWEST_ACREAGE,
        cyLabelSelector: DESKTOP_SORT_OPTIONS.LOWEST_ACREAGE_LABEL,
      },
      {
        value: 'yearbuilt',
        label: ' Year Built (Newest)',
        cySelector: DESKTOP_SORT_OPTIONS.NEWEST_BUILT,
        cyLabelSelector: DESKTOP_SORT_OPTIONS.NEWEST_BUILT_LABEL,
      },
      {
        value: 'yearbuilt_asc',
        label: ' Year Built (Oldest)',
        cySelector: DESKTOP_SORT_OPTIONS.OLDEST_BUILT,
        cyLabelSelector: DESKTOP_SORT_OPTIONS.OLDEST_BUILT_LABEL,
      },
      {
        value: 'importdate',
        label: ' Days on Site (Newest)',
        cySelector: DESKTOP_SORT_OPTIONS.NEWEST_ON_SITE,
        cyLabelSelector: DESKTOP_SORT_OPTIONS.NEWEST_ON_SITE_LABEL,
      },
      {
        value: 'importdate_asc',
        label: ' Days on Site (Oldest)',
        cySelector: DESKTOP_SORT_OPTIONS.OLDEST_ON_SITE,
        cyLabelSelector: DESKTOP_SORT_OPTIONS.OLDEST_ON_SITE_LABEL,
      },
    ];

    if (showDistance) {
      sortOptions.push({ value: 'distance', label: ' Distance' });
    }

    if (disableAcreage) {
      sortOptions = sortOptions.filter(obj => !obj.value.includes('acreage'));
    }

    // Add checked state
    return sortOptions.map(s => ({ ...s, checked: s.value === activeValue }));
  }

  render() {
    // Branding
    const logoURL = config.inner_logo_uri;
    const logoHREF = window.location.origin;
    const legalName = tenant.legalName;

    // Sorting
    const sortOptions = this.getSortOptions({
      showDistance: this.props.search.showDistance,
      activeValue: this.props.search.sort,
      disableAcreage: rules.attributes.DisableAcreage,
    });

    // Result type toggle
    const { routeName } = this.props;
    const isListingRoute = routeName === 'listing';
    const isMapRoute = routeName === 'results-map';
    const isPhotoRoute = routeName === 'results';
    const isResultsGalleryRoute = routeName === 'results-gallery';
    const isFavoritesRoute = routeName === 'favorites';
    const isCompareRoute = routeName === 'compare';

    const showButtonToolbar = !isListingRoute;
    const showSortButton =
      !isMapRoute &&
      !this.props.search.hasRID &&
      !this.props.search.hasFavs &&
      !isFavoritesRoute &&
      !isCompareRoute;

    // Taglist: Save search button
    const showSaveSearch = this.props.tags.length || this.props.search.hasOfficeID;

    // Sticky header is based on route
    const isSticky = isPhotoRoute || isResultsGalleryRoute;

    // NOTE: [aknox] If you modify the DOM structure of this file make sure that the
    // server side templates stay in sync. listing/search.base.hbs and search/menu.hbs
    return (
      <Sticky enabled={isSticky} innerZ={30}>
        <div className="bt-listing-search-menu js-listing-search-menu js-results__page-header">
          <div className="container bt-listing-search__wrapper js-filter-bar">
            <div className="grid grid--gutters grid--center">
              <div className="cell cell-md-3 bt-listing-search__logo">
                <a className="js-inner-logo" href={logoHREF}>
                  <img className="bt-inner-logo" alt={legalName} src={logoURL} />
                </a>
              </div>
              <div className="cell">
                {this.props.children}

                <div className="bt-search-filter-area mt-16 js-search-filter-area">
                  <div className="grid grid--gutters grid--noWrap grid--justifyBetween grid--center">
                    <div className="cell pr-4 bt-save-search__wrapper">
                      {showSaveSearch && !isFavoritesRoute && (
                        <div className="pr-16">
                          <PrimaryButton
                            size="small"
                            className="uk-text-nowrap at-save-search-btn"
                            onClick={this.props.onClickSaveSearch}
                            dataCY={SEARCH_BAR.SAVE_SEARCH}
                          >
                            Save This Search
                          </PrimaryButton>
                        </div>
                      )}

                      <div className="bt-search-tags__container">
                        <DesktopTagList />
                      </div>
                      <OverflowTagListDropdown />
                    </div>
                    {/* this is used as a placeholder for alignment even when empty */}
                    <div
                      id="results-set-interactions"
                      className="cell pl-12 bt-view-options__wrapper"
                    >
                      {showButtonToolbar && (
                        <ButtonToolbar
                          label="Sort results options and results view switcher"
                          className="grid--justifyEnd"
                        >
                          {showSortButton && (
                            <SortResults
                              sortOptions={sortOptions}
                              onChange={this.props.onSortClick}
                            />
                          )}
                          <ResultsViewToggle
                            routeName={routeName}
                            onClick={this.props.onResultsViewTypeClick}
                            searchHasFavs={
                              this.props.search.hasFavs ||
                              routeName === 'favorites' ||
                              routeName === 'compare'
                            }
                          />
                        </ButtonToolbar>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sticky>
    );
  }
}

export default SearchBar;

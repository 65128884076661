import { push } from 'redux-little-router';
import { takeEvery, takeLatest, all, put, select, call } from 'redux-saga/effects';

/* INTERNAL DEPS */
import { DETAILS, RESULTS_GALLERY, RESULTS_MAP } from 'routes';
import { favorites } from 'BoomTown';
import isRoute from 'utility/isRoute';
import * as actions from 'actions/MobileDetailsActions';

/* SELECTORS */
import { getLastSearchRoute } from 'reducers/lastSearch';
import { getRouteID } from 'selectors/router';
import { getListingsForMap, getListingsForGallery } from 'selectors/mobileDetails';

/* EFFECTS */
import { goToLastResults } from 'sagas/routerTasks/goToLastResults';
import { fetchDataForDetailsPage, squeezeAndAddView } from './routeHandlers';
import handleDirectionsBtnClick from './mapHandler';
import { searchForNeighborhood, searchForCounty, searchForArea } from './newSearches';
import detailsContactAgentFormEffects from './detailsContactAgentForm';
import reusableEffects from './effects';
import shouldSqueezePhotoSlider from './squeezeHandler';

/**
 * The main event
 */
export default function* detailsPageSaga() {
  // TODO: When we release to GA this can be removed.
  // Sometimes we enable sagas after location_change and therefor don't get a chance
  // to run a data fetch, this function is really "maybe call api"
  const routeID = yield select(getRouteID);
  if (routeID === DETAILS) {
    yield fetchDataForDetailsPage();
    yield squeezeAndAddView();
    yield fetchContinuedSearchResults();
  }

  yield all([
    // Main Content
    // Using this effect creator means that we'll cancel any previous instances
    // of this saga if they aren't completed, e.g. if the API is bogging
    takeLatest(isRoute(DETAILS), fetchDataForDetailsPage),
    takeEvery(isRoute(DETAILS), squeezeAndAddView),
    takeEvery(isRoute(DETAILS), fetchContinuedSearchResults),

    // Not forwarding on the listing ID here, because the prior code didn't. We
    // probably just rely on cached scroll position.
    takeEvery(actions.clickBackToResults, function* onClickBackToResults() {
      yield call(goToLastResults);
    }),

    takeEvery(actions.clickMapModalDirectionsBtn, handleDirectionsBtnClick),
    takeEvery(actions.clickNeighborhoodLink, searchForNeighborhood),
    takeEvery(actions.clickCountyLink, searchForCounty),
    takeEvery(actions.clickAreaLink, searchForArea),
    takeEvery(actions.clickContinuedSearchCard, handleContinuedSearchCardClick),
    takeEvery(actions.clickContinuedSearchFavoriteButton, handleContinuedSearchFavClick),
    takeEvery(actions.onPhotoSlide, shouldSqueezePhotoSlider),

    // Side-effects that are used in both the main CNS Site and the CNS App
    ...reusableEffects,

    // Contact area
    ...detailsContactAgentFormEffects,
  ]);
}

// REFACTOR: consolidate favorite handling in one saga
function handleContinuedSearchFavClick(action) {
  const id = action.payload;
  favorites.toggle(id);
}

function* handleContinuedSearchCardClick({ payload: { urlPath } }) {
  const url = urlPath.startsWith('//') ? urlPath.substring(1) : urlPath;
  yield put(push(url));
}

function* fetchContinuedSearchResults() {
  const lastSearchRoute = yield select(getLastSearchRoute);
  let listings = [];

  if (lastSearchRoute === RESULTS_GALLERY) {
    listings = yield select(getListingsForGallery);
  } else if (lastSearchRoute === RESULTS_MAP) {
    listings = yield select(getListingsForMap);
  }

  if (listings.length > 0) {
    yield put(actions.receivedContinuedSearchResults(listings));
  }
}

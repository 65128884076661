import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

const Sash = (props) => {
  const {
    className,
    sashType,
    sashText,
    inline,
    card,
    style,
  } = props;

  const sashStyles = cx(
    'bt-sash',
    `bt-sash--${sashType}`,
    className,
    {
      'bt-sash--inline': inline,
      'py-4 px-12': !card,
      'p-4': card
    }
  );

  return (
    <i className={sashStyles} style={style}>
      <span className="bt-sash__text">
        {sashText}
      </span>
    </i>
  );
};

Sash.displayName = 'Sash';
Sash.propTypes = {
  sashType: PropTypes.string.isRequired,
  sashText: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  className: PropTypes.string,
  card: PropTypes.bool,
  style: PropTypes.object
};
Sash.defaultProps = {
  card: false,
  inline: false,
};

export default Sash;

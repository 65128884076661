import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import moment from 'moment-timezone';
import createForm from 'hoc/createForm';
import validator from 'utility/validator';
import { range } from 'underscore';
import { account, config, rules as specialRules } from 'BoomTown';
import { PrimaryButton } from 'coreComponents';

import RecaptchaV2 from 'components/Common/FormElements/RecaptchaV2';
import ContactFormDisclaimer from 'components/Common/ContactFormDisclaimer';
import AgentInfo from 'components/Common/Agent/Mobile/MobileAgentInfo';
import { MOBILE_PROP_DETAILS } from 'cypress_constants';
import ContactAgentAlert from './ContactAgentAlert';
import UserInfoFields from './UserInfoFields';
import RequestShowingFields from './RequestShowingFields';

/** A set of formatting functions for Moments. */
const formatMomentAs = {
  full(m) {
    return m.format('MM.DD.YYYY');
  },
  month(m) {
    return m.format('MMM');
  },
  calendarDay(m) {
    return m.format('D');
  },
  weekDay(m) {
    return m.format('ddd');
  },
};

const timeOptions = ['Anytime', 'Morning', 'Afternoon', 'Evening'];

@createForm([
  {
    name: 'email',
    type: 'email',
    initialState: (props) => props.visitorEmail,
    validation: [
      {
        isValid: validator.isEmail,
        message: 'Invalid email address',
      },
      {
        isValid: (x) => Boolean(x),
        message: 'Please enter an email address.',
      },
    ],
  },
  {
    name: 'comments',
    type: 'text',
    initialState: (props) => props.bodyInitialText,
    validation: {
      isValid: validator.isNotWhitespace,
      message: 'Please enter a question.',
    },
  },
  {
    name: 'selectDate',
    type: 'manual',
    initialState: moment(),
  },
  {
    name: 'selectTime',
    type: 'radio',
    initialState: timeOptions[0], // 'Anytime'
    validation: {
      isValid: (val, formData) => {
        if (!formData.requestShowing) {
          return true;
        }
        return timeOptions.includes(val);
      },
      message: 'Please select a time.',
    },
  },
  {
    name: 'fullname',
    type: 'text',
    initialState: (props) => props.visitorName,
    validation: {
      isValid: validator.isFullName,
      message: 'Enter a valid full name.',
    },
  },
  {
    // Sacred naming convention passed all the way to the api
    name: 'phone',
    type: 'tel',
    initialState: (props) => props.visitorPhone,
    validation: {
      isValid: validator.isPhone,
      message: 'Enter a valid phone.',
    },
  },
  // Recaptcha Token
  {
    name: 'token',
    type: 'manual',
    initialState: '',
    validation: {
      isValid: (token) => {
        if (config.recaptchaEnabled && !token) {
          return false;
        }
        return true;
      },
      message: 'Invalid Recaptcha Response',
    },
  },
])
class ContactAgent extends PureComponent {
  static propTypes = {
    // From connectBB
    visitorEmail: PropTypes.string,
    visitorName: PropTypes.string,
    visitorPhone: PropTypes.string,
    isRegistered: PropTypes.bool,

    // From actions
    onSubmit: PropTypes.func,
    onResSuccessButtonClick: PropTypes.func.isRequired,
    onResBackButtonClick: PropTypes.func.isRequired,
    onResRetryButtonClick: PropTypes.func.isRequired,
    toggleRequestShowing: PropTypes.func,
    toggleRequestVideoTour: PropTypes.func,

    // From state
    isResModalOpen: PropTypes.bool,
    isSuccess: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    isVideoTour: PropTypes.bool,
    isInPersonTour: PropTypes.bool,

    bodyInitialText: PropTypes.string.isRequired,
    agentInfo: PropTypes.shape({
      agent: PropTypes.object,
      state: PropTypes.oneOf(['valid', 'invalid', 'failed']),
    }).isRequired,
    showMobile: PropTypes.bool,
    isAgentSubdomain: PropTypes.bool,
    // From createForm()
    formData: PropTypes.object,
    errors: PropTypes.object,
    formHandlers: PropTypes.object,
    validateForm: PropTypes.func,

    // Custom
    hideRequestShowing: PropTypes.bool,
    hideNameInput: PropTypes.bool,
    hidePhoneInput: PropTypes.bool,
    hideEmailInput: PropTypes.bool,
    hideBorderBottom: PropTypes.bool,
    hideSubmit: PropTypes.bool,
    commentsLabel: PropTypes.string,
    widget: PropTypes.bool,
  };

  static defaultProps = {
    hideNameInput: false,
    hidePhoneInput: false,
    hideEmailInput: false,
    hideBorderBottom: false,
    hideSubmit: false,
    widget: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      // Dates for the four quick select radio buttons
      dateOptions: this.getDateRange(),
    };

    /** @type {HTMLFormElement} */
    this._formEl = null;
  }

  /**
   * @param {FormEvent} e
   * @memberof ContactAgent
   */
  onSubmit = (e) => {
    e.preventDefault();

    /** @type {Object.<string, string>} */
    const errors = this.props.validateForm();
    if (Object.keys(errors).length) {
      if (this._formEl) {
        // We have to wait for this update to flush to the DOM before the new
        // error is there.
        setTimeout(() => {
          const firstError = this._formEl.querySelector('.parsley-errors-list');
          if (firstError) {
            firstError.scrollIntoView({ block: 'center', behavior: 'smooth' });
          }
        });
      }
      return;
    }

    const { formData, isVideoTour, isInPersonTour } = this.props;

    if (isVideoTour) {
      formData.comments = `Video Tour Request! Lead Comment: ${formData.comments}`;
    } else if (isInPersonTour) {
      formData.comments = `In Person Tour Request! Lead Comment: ${formData.comments}`;
    }

    this.props.onSubmit({
      requestShowing: isInPersonTour,
      requestVideoTour: isVideoTour,
      name: formData.fullname,
      phone: formData.phone,
      email: formData.email,
      comments: formData.comments,
      token: formData.token,
      ...((isInPersonTour || isVideoTour) && {
        showingDate: formatMomentAs.full(formData.selectDate),
        showingTime: formData.selectTime,
      }),
    });
  };

  /**
   * Generate an array of Moments for the today and the next three days.
   * (Remember, _`range()` is inclusive on the lower bound and exclusive on the
   * upper bound._)
   *
   * @returns {Moment[]}
   */
  getDateRange = () => range(0, 4).map((x) => moment().add(x, 'days'));

  getErrors = (fieldName) => {
    const error = this.props.errors[fieldName];
    if (!error) {
      return null;
    }

    return { type: fieldName, message: error };
  };

  isMobileDisplayed = () => {
    const isDisplayed =
      this.props.isRegistered || this.props.showMobile || this.props.isAgentSubdomain;
    return isDisplayed;
  };

  render() {
    const {
      agentInfo,
      formData,
      formHandlers,
      errors,
      isVideoTour,
      isInPersonTour,
      toggleRequestShowing,
      toggleRequestVideoTour,
      hideRequestShowing,
      hideNameInput,
      hidePhoneInput,
      hideEmailInput,
      hideBorderBottom,
      hideSubmit,
      commentsLabel,
      isResModalOpen,
      isSuccess,
      onResSuccessButtonClick,
      onResBackButtonClick,
      onResRetryButtonClick,
      widget,
    } = this.props;

    // Values and handlers for tour fields (person vs video)
    const tourFields = {
      isVideoTour: {
        value: isVideoTour,
        handler: toggleRequestVideoTour,
      },
      isInPersonTour: {
        value: isInPersonTour,
        handler: toggleRequestShowing,
      },
    };

    return (
      <div>
        {/* Success/Error Toast */}
        <ContactAgentAlert
          agentInfo={agentInfo}
          isOpen={isResModalOpen}
          isSuccess={isSuccess}
          onSuccessClick={onResSuccessButtonClick}
          onBackClick={onResBackButtonClick}
          onRetryClick={onResRetryButtonClick}
          onSubmit={this.onSubmit}
        />

        {(!specialRules.attributes.HideAgentFromRequest || !account.attributes.GuestUnbranded) && (
          <div className="text-xs--center">
            <AgentInfo
              agentInfo={agentInfo}
              isMobileDisplayed={this.isMobileDisplayed()}
              hideBorderBottom={hideBorderBottom}
            />
          </div>
        )}

        <form
          id="contact-agent"
          onSubmit={this.onSubmit}
          ref={(r) => {
            this._formEl = r;
          }}
        >
          <UserInfoFields
            formData={formData}
            formHandlers={formHandlers}
            errors={errors}
            hideNameInput={hideNameInput}
            hidePhoneInput={hidePhoneInput}
            hideEmailInput={hideEmailInput}
            commentsLabel={commentsLabel}
          />

          {!hideRequestShowing && (
            <RequestShowingFields
              formData={formData}
              formHandlers={formHandlers}
              errors={errors}
              momentFormat={formatMomentAs}
              timeOptions={timeOptions}
              dateOptions={this.state.dateOptions}
              tourFields={tourFields}
            />
          )}

          {/* Recaptcha - Only renders when the Recaptcha Plugin is enabled */}
          {config.recaptchaEnabled && (
            <div className="mb-12">
              <RecaptchaV2
                onChange={formHandlers.token}
                widget={widget}
                error={this.getErrors('token')}
              />
            </div>
          )}

          {!hideSubmit && (
            <PrimaryButton
              type="submit"
              className="at-submit-btn"
              dataCY={MOBILE_PROP_DETAILS.CONTACT_SUBMIT}
              width="full"
              form="contact-agent"
              disabled={this.props.isSubmitting}
              showSpinner={this.props.isSubmitting}
            >
              Submit
            </PrimaryButton>
          )}
          <ContactFormDisclaimer />
        </form>
      </div>
    );
  }
}

export default ContactAgent;

import _ from 'underscore';
import { stringify } from 'query-string';
import { config, utility } from 'BoomTown';
import committedSearchChange from 'models/search/actions';
import {
  getLastSearchState,
  getLastSearchRoute,
  getLastMapBounds,
  getLastMapZoom
} from 'reducers/lastSearch';
import { isMapModalOpen } from 'selectors/mobileDetails';
import { isFullScreenMobileRoute } from 'selectors/router';
import { getCommittedSearch } from 'models/search/reducers/selectors';

/**
 * Store change listener to add/remove the scroll-disabling class on the body
 * whenever an overlay is open. React-overlays adds `overflow: hidden;` on the
 * style attribute, but this doesn't work for WebKit (iOS Safari in
 * particular).
 *
 * @type {ActorInit}
 */
export const toggleScrollActor = {
  selector: isMapModalOpen,
  actor: isOpen => {
    const fnName = isOpen ? 'add' : 'remove';
    document.body.classList[fnName]('bt--no-scroll');
  },
  comparer: (prev, next) => prev === next,
};

/**
 * The footer is not currently in react. Some screens feel a lot like modals,
 * in which case we want to hide the header-menu and footer.
 *
 * @type {ActorInit}
 */
export const toggleFullScreenMobileUI = {
  selector: isFullScreenMobileRoute,
  actor: isFullScreen => {
    // Valuation pages don't render a footer so we have to check
    const f = document.getElementById('footer');
    if (f === null) return;

    f.style.display = isFullScreen ? 'none' : '';
  },
};

/**
 * Update the `LastSearch` cookie with the most recent search query values
 * from the global store.
 *
 * @type {ActorInit}
 */
export const lastSearchStateCookie = {
  selector: getLastSearchState,
  actor: (lastSearchState) => utility.cookie(config.searchCookieName, stringify(lastSearchState)),
  comparer: _.isEqual,
};

/**
 * Update the `lastSearchRoute` cookie with the last search route
 * from the global store.
 *
 * @type {ActorInit}
 */
export const lastSearchRouteCookie = {
  selector: getLastSearchRoute,
  actor: (lastSearchRoute) => utility.cookie('lastSearchRoute', lastSearchRoute),
  comparer: _.isEqual,
};

/**
 * Update the `lastMapBounds` cookie with the lastMapBounds state
 * from the global store.
 *
 * @type {ActorInit}
 */
export const lastMapBoundsCookie = {
  selector: getLastMapBounds,
  actor: (lastMapBounds) => utility.cookie('lastMapBounds', stringify(lastMapBounds)),
  comparer: _.isEqual,
};

/**
 * Update the `lastMapZoom` cookie with the lastMapZoom value from state
 * from the global store.
 *
 * @type {ActorInit}
 */
export const lastMapZoomCookie = {
  selector: getLastMapZoom,
  actor: (lastMapZoom) => utility.cookie('lastMapZoom', lastMapZoom),
  comparer: _.isEqual,
};

/**
 * Dispatch action when the `search.committed` branch of the state tree changes
 *
 * @type {ActorInit}
 */
export const searchChangeSubscription = {
  selector: getCommittedSearch,
  actor: (search, dispatch) => dispatch(committedSearchChange(search)),
  comparer: _.isEqual,
};

// Exported separately so we can conditionally initialize based on the feature flag
export const mapSyncActors = [lastMapBoundsCookie, lastMapZoomCookie];

export default [
  searchChangeSubscription,
  toggleScrollActor,
  toggleFullScreenMobileUI,
  lastSearchStateCookie,
  lastSearchRouteCookie,
];

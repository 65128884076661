import { sliceFactory } from 'reducers/util';
import * as OffCanvasActions from 'actions/OffCanvasActions';
import * as DeviceLocationActions from 'actions/DeviceLocationActions';
import { PENDING_BRANCH } from '../../../constants';

/**
 * LOCATION SEARCH REDUCERS
 *
 * A set of Reducers for actions dispatched by initiating a location search
 */
export default {
  /**
   * RECEIVE LOCATION DATA SUCCESSFULLY
   *
   * Note that this state is being stored here temporarily. We currently
   * don't need the user's location anywhere else, but when we do, this state
   * should be in some common part of the state tree.
   */
  [DeviceLocationActions.receiveLocationDataSuccess]: sliceFactory(
    PENDING_BRANCH,
    (state, payload) => ({
      ...state,
      nearby: {
        ...state.nearby,
        latitude: payload.coords.latitude,
        longitude: payload.coords.longitude,
      },
    })
  ),

  /* SELECT NEARBY SEARCH */
  [OffCanvasActions.selectNearbySearchLegacy]: sliceFactory(
    PENDING_BRANCH,
    state => {
      // If not already selected, set to the default of 3 mi.
      if (!state.nearby || !state.nearby.radiusInMiles) {
        return {
          ...state,
          nearby: {
            ...state.nearby,
            radiusInMiles: 3,
          },
        };
      }

      return state;
    }
  ),

  /**
   * REMOVE NEARBY SEARCH
   *
   * This action is currently dispatched via a redux-thunk and should be updated/refactored
   */
  [OffCanvasActions.removeNearbySearch]: sliceFactory(
    PENDING_BRANCH,
    state => ({
      ...state,
      nearby: null,
    })
  ),
};

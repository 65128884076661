import React from 'react';
import T from 'prop-types';
import { Grid, Cell } from 'components/core/Grid';
import { Container } from 'components/Common/MobileScreen';
import { H3 } from 'components/core/Headings';

const MenuLayout = ({ title, topLeftButton, topRightButton, children, ...props }) => (
  <Container hasFooter={false} {...props}>
    <div className="container px-16--safe pt-12">
      <Grid justifyContent="between">
        <Cell noGrow>{topLeftButton}</Cell>
        <Cell noGrow>{topRightButton}</Cell>
      </Grid>
      {title && <H3 className="text-xs--center">{title}</H3>}
      {children}
    </div>
  </Container>
);

MenuLayout.propTypes = {
  title: T.string,
  topLeftButton: T.element,
  topRightButton: T.element,
  children: T.node,
};

export default MenuLayout;

import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import Disclaimer from 'components/Common/Disclaimer';
import { LISTING_CARD } from 'cypress_constants';

const CardDisclaimers = (props) => {
  const {
    listingTopHtml,
    thumbCardDisclaimer,
    multiMLSDisclaimer,
    homePageAndMapDisclaimer,
    soldThumbCardDisclaimer,
    isMultiBoard,
    isHomepageOrMap = false,
    className,
    removeClass = false,
    increaseDisclaimerFontSize,
    matchDisclaimerFontStyles,
  } = props;

  const teaserDisclaimerContainerClasses = cx({
    'listing-card__disclaimer-wrapper': !removeClass,
    [`${className}`]: className,
  });

  const disclaimerClasses = cx('listing-card__disclaimer bt-clearfix', {
    'listing-card__disclaimer--large':
      (increaseDisclaimerFontSize && thumbCardDisclaimer) ||
      (increaseDisclaimerFontSize && soldThumbCardDisclaimer) ||
      (matchDisclaimerFontStyles && listingTopHtml),
  });

  return (
    <div className={teaserDisclaimerContainerClasses}>
      {listingTopHtml && (
        <Disclaimer className={disclaimerClasses} disclaimerHTML={listingTopHtml} />
      )}
      {thumbCardDisclaimer && (
        <Disclaimer
          className={disclaimerClasses}
          disclaimerHTML={thumbCardDisclaimer}
          dataCY={LISTING_CARD.DISCLAIMER}
        />
      )}
      {isMultiBoard && multiMLSDisclaimer && (
        <Disclaimer className={disclaimerClasses} disclaimerHTML={multiMLSDisclaimer} />
      )}
      {homePageAndMapDisclaimer && isHomepageOrMap && (
        <Disclaimer className={disclaimerClasses} disclaimerHTML={homePageAndMapDisclaimer} />
      )}
      {soldThumbCardDisclaimer && (
        <Disclaimer className={disclaimerClasses} disclaimerHTML={soldThumbCardDisclaimer} />
      )}
    </div>
  );
};

CardDisclaimers.defaultProps = {
  className: null,
  listingTopHtml: null,
  thumbCardDisclaimer: null,
  multiMLSDisclaimer: null,
  homePageAndMapDisclaimer: null,
  soldThumbCardDisclaimer: null,
  isMultiBoard: false,
  isHomepageOrMap: false,
  removeClass: false,
  increaseDisclaimerFontSize: false,
  matchDisclaimerFontStyles: false,
};

CardDisclaimers.propTypes = {
  listingTopHtml: PropTypes.string,
  thumbCardDisclaimer: PropTypes.string,
  multiMLSDisclaimer: PropTypes.string,
  homePageAndMapDisclaimer: PropTypes.string,
  soldThumbCardDisclaimer: PropTypes.string,
  isMultiBoard: PropTypes.bool,
  isHomepageOrMap: PropTypes.bool,
  className: PropTypes.string,
  removeClass: PropTypes.bool,
  increaseDisclaimerFontSize: PropTypes.bool,
  matchDisclaimerFontStyles: PropTypes.bool,
};

export default CardDisclaimers;

import { createAction } from 'redux-act';

const action = (title, ...args) => createAction(`Map Action Bar > ${title}`, ...args);

export const onNearbyClick = action('Nearby button click', () => {});
export const onPolygonClick = action('Polygon button click', () => {});
export const onClearPolygonClick = action('Clear Polygon button click', () => {});
export const onMapTypeClick = action('Map type button click', () => {});
export const onZoomInClick = action('Zoom in button click', () => {});
export const onZoomOutClick = action('Zoom out button click', () => {});

import { createReducer } from 'redux-act';
import getByPath from 'lodash/get';
import * as a from './actions';

/**
 * This represents that coordinates that were last used on the desktop map route
 *
 * Use case:
 * 1. pan/zoom on map route
 * 1. navigate to listings
 * 1. navigate back to map, we shouldn't reset your coordinates to something that
 *    bound your search, but prefer your last coords.
 */

// type MyState = {
//   swlat?: string;
//   swlng?: string;
//   nelat?: string;
//   nelng?: string;
//   midlat?: string;
//   midlng?: string;
//   zoom?: string;
// }
const initialState = {};
export default createReducer(
  {
    [a.clear]: () => ({}),
    [a.set]: (state, payload) => ({ ...payload }),
  },
  initialState
);

export const INSTALL_PATH = 'desktop.map';
export const get = (state, path = INSTALL_PATH) => getByPath(state, path);

import { omit } from 'underscore';
import { call, select, put, all } from 'redux-saga/effects';
import { api } from 'BoomTown';
import { coordinateFilters } from 'models/search/constants';
import { getIsSearchWithoutCoordsTheSame, getTotalItemsWithoutCoords } from '../reducer';
import { fetchListings, errorResults } from '../actions';

/**
 * Fetches the next set of results for the map and the total result count for the given search
 * minus any map bounds.
 *
 * @generator
 * @exports generator
 *
 * @param {{Object}} nextQuery The next search query obj. Can include mapBounds.
 * @param {{Object}} nextQuery The previous search query obj. Can include mapBounds.
 *
 * @return {{result: Object, totalItemsWithoutCoords: Number}|boolean}
 */
export default function* fetchMapResults(nextQuery, previousQuery) {
  try {
    // Action to update the Map UI to denote that we're fetching listings
    yield put(fetchListings());

    // Compares the previous search without coords to the new search without coords
    const isSearchWithoutCoordsTheSame = yield select(
      getIsSearchWithoutCoordsTheSame,
      previousQuery,
      coordinateFilters
    );

    // Initialize an array of requests that will get executed asynchronously
    const asyncRequest = [call([api, api.ajaxSearchPromise], nextQuery)];
    let cachedSearchCount;

    // If nothing has changed in the search aside from mapBounds, then the total items without
    // coords is the same. Therefore, don't make an unnecessary `searchcount` API request.
    if (isSearchWithoutCoordsTheSame) {
      // Return the value from the store
      cachedSearchCount = yield select(getTotalItemsWithoutCoords);
    } else {
      // Make a new api request
      const searchCountQuery = omit(nextQuery, coordinateFilters);
      asyncRequest.push(call([api, api.searchcountPromise], searchCountQuery));
    }

    // Run both of these api requests in parallel
    const [searchResponse, searchCountResponse] = yield all(asyncRequest);

    if (typeof searchResponse === 'undefined' || searchResponse === null) {
      throw new Error('Search response is undefined');
    }

    return {
      result: searchResponse.Result,
      totalItemsWithoutCoords:
        typeof cachedSearchCount !== 'undefined' ? cachedSearchCount : searchCountResponse.Result,
    };
  } catch (e) {
    let error = e;

    // In extremely rare circumstances, the search endpoint can throw `ERR_EMPTY_RESPONSE`. The
    // catch block handles this error, but the exception variable is undefined. In that case,
    // create our own Error obj for logging.
    if (typeof error === 'undefined') {
      error = new Error('Error is undefined');
    }

    yield put(errorResults(error));
    return { error };
  }
}

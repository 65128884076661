import { compose } from 'redux';
import { createReducer } from 'redux-act';
import reduceReducers from 'reduce-reducers';
import { LOCATION_CHANGED } from 'redux-little-router';
import { isEqual, isEmpty } from 'underscore';
import { config } from 'BoomTown';
import { HOME } from 'routes';
import { multiCase, sliceFactory } from 'reducers/util';
import { isResultsPath, isFilterMenuPath } from 'backbone/Routing/routerUtils';
import propsToLower from 'utility/propsToLower';

// Actions
import { initializeState } from 'actions/bootstrapActions';
import * as HomepageBallerBoxActions from 'components/HomepageSearch/Consumer/actions';
import {
  clickSearchInResultsHeader,
  clickNewSearchInResultsHeader,
  clickSaveSearchInResultsHeader,
  clickSaveSearchAd,
} from 'actions/MobileGalleryActions';
import {
  onMapBoundsChanged,
  onMapZoom,
  receiveMapParamsFromUrl,
} from 'screens/ResultsMap/Map/actions';
import { clickSearchMenuLink } from 'screens/MobileMenu/actions';
import { applySearch, clickSaveOnSearchMenu } from 'screens/MobileSearchMenu/actions';
import { selectSavedSearch } from 'screens/Account/Forms/EditSavedSearches/actions';

import { defaultSearch, BASE_SEARCH, PENDING_BRANCH, SEARCH_MENU_QUERY } from '../constants';
import {
  filterByOmitList,
  formatSearchParams,
  filterPageIndex,
  filterCoordsParams,
  getMapFilters,
} from '../util';
import pendingSearchReducer, { populateWithDefaultSearch } from './pending';


export const defaultState = {
  committed: defaultSearch,
  previous: {},
  [`${PENDING_BRANCH}`]: populateWithDefaultSearch(), // Changes to this branch should be handled in the imported `pendingSearchReducer`
  baseSearch: BASE_SEARCH.COMMITTED,
  mapBounds: null,
  zoom: config && config.defaultZoom ? config.defaultZoom : null,
};

/**
 * Reducers for handling changes to the user's search criteria on the
 * mobile, off-canvas search menu. (In the form of a reducer map that is
 * passed to redux-act's `createReducer()`.)
 */
export const searchReducer = createReducer({
  /**
   * SERVER-SIDE RENDER
   *
   * The `initializeState` action is dispatched from our `bootstrapState` fn in the root index
   * file of our app as our JS app initializes itself. We use values from the server and
   * from the user's cookies to populate a default search state.
   */
  [initializeState]: (state, { pendingSearch, lastSearchState, lastMapBounds, lastMapZoom }) => {
    const nextState = {
      ...state,
      committed: {
        ...(!isEmpty(lastSearchState) ? filterByOmitList(lastSearchState) : state.committed)
      },
    };

    if (config.useListMapResultsSync) {
      nextState.mapBounds = lastMapBounds ? { ...lastMapBounds } : state.mapBounds;
      nextState.zoom = lastMapZoom || state.zoom;
    }

    // We have a stored pending search, so we declare that as the base
    if (pendingSearch != null) {
      nextState.baseSearch = BASE_SEARCH.PENDING;
    }

    return nextState;
  },

  /**
   * Dispatched on route change, meaning new search params have been added to the url, or the user
   * has been routed to a results view.
   *
   * @todo Break up into smaller chunks, either smaller fns or slice reducers.
   * @todo Short-circuit to reset state to default if HOME route
   */
  [LOCATION_CHANGED]: (state, { pathname, result, query }) => {
    // Rehydrate the current state based on the route
    const isHome = result && result.id === HOME;
    let nextSearch = defaultSearch;
    const lastSearchState = state.committed; // Our most recent commmited search

    // If the route query is empty aside from possibly the `search` param for the mobile filter
    // menu, the just move forward with the default search params.
    const hasSearchQuery = !isEmpty(query) && query[SEARCH_MENU_QUERY] === undefined;
    const isSearchPath = isResultsPath(pathname) || isFilterMenuPath(pathname);

    if (isSearchPath && hasSearchQuery) {
      // Ensure all properties of the search object are lowercased
      nextSearch = propsToLower(query);
    } else if (!isHome && !isEmpty(lastSearchState)) {
      // For desktop, we only continue to rehydrate if you are on a split layout.
      // Home is the only page that isn't a split layout. We essentially do the same on mobile,
      // where we basically just persist the previous state on route changes unless it's the
      // home page.
      nextSearch = lastSearchState;
    }

    // Important that this goes before the series of filters below, otherwise they'll be filtered
    // out of the nextSearch obj. This is mainly here to handle coord and zoom values that could
    // come through in the url (for a legacy map search or a saved search).
    const { zoom, mapBounds } = getMapFilters(nextSearch);

    // Run the search obj through a series of filters to ensure the shape is compatible
    nextSearch = compose(
      formatSearchParams,
      filterPageIndex,
      filterByOmitList,
      filterCoordsParams
    )(nextSearch);

    // Temporary. Will cleanup/extract.
    if (config.isMobileApp) {
      const previousSearch = {
        ...lastSearchState,
        ...(!isEmpty(state.mapBounds) ? state.mapBounds : {}),
      };

      if (isEqual(previousSearch, { ...nextSearch, ...mapBounds })) {
        return ({
          ...state,
          ...(isEmpty(state.previous) ? { previous: previousSearch } : {}),
        });
      }

      return ({
        ...state,

        mapBounds: !isEmpty(mapBounds) ? { ...mapBounds } : defaultState.mapBounds,
        zoom: zoom || defaultState.zoom,
        previous: previousSearch,
        committed: nextSearch,
      });
    }

    if (isEqual(lastSearchState, nextSearch)) {
      return ({
        ...state,

        // Still populate the previous search obj if it hasn't been instantiated yet. We essentially
        // use the `previous` search state as a means of confirming that the search state has been
        // instantiated. There shouldn't be a time in which `previous` should be empty (it'll always
        // at least have `sort`) after the intiial page load.
        ...(isEmpty(state.previous) ? { previous: { ...lastSearchState } } : {}),
      });
    }

    return ({
      ...state,

      // TODO: Isolate the change to mapBounds and zoom to their own reducer
      ...(!isEmpty(mapBounds) ? { mapBounds } : {}),
      ...(typeof zoom !== 'undefined' ? { zoom } : {}),

      previous: { ...lastSearchState },
      committed: nextSearch,
    });
  },


  /**
   * Opening the Filter Menu, set the baseSearch to PENDING.
   */
  ...multiCase(
    [clickSearchInResultsHeader, clickNewSearchInResultsHeader, clickSearchMenuLink],
    state => ({
      ...state,
      baseSearch: BASE_SEARCH.PENDING,
    })
  ),

  /**
   * When the # Properties button is tapped on the Filter Menu
   */
  [applySearch]: state => ({
    ...state,
    // Mapbounds can't change in the Filter Menu aside from being removed entirely. Meaning if
    // they're still in the pending search, they haven't changed. If they're gone, reset the
    // committed `mapBounds` and zoom.
    ...(
      !isEmpty(state.pending.mapbounds)
        ? {}
        : { mapBounds: defaultState.mapBounds, zoom: defaultState.zoom }
    ),
    baseSearch: BASE_SEARCH.COMMITTED,
  }),
});


/**
 * Mobile Gallery
 */
export const mobileGallerySearch = createReducer({
  ...multiCase(
    [clickSaveSearchInResultsHeader, clickSaveSearchAd],
    /**
     * If the user is not registered, they'll be squeezed by the appropriate saga before they
     * are able to reach the Save Search view, so there's no need to update state here. Afer the
     * user registers, the view will reload with the appropriate state.
     */
    (state, payload, { visitor }) => {
      if (!visitor.isRegistered()) {
        return state;
      }

      return ({
        ...state,
        baseSearch: BASE_SEARCH.COMMITTED,
      });
    }
  ),
});

/**
 * Mobile Map
 */
export const mobileMapSearch = createReducer({
  [onMapBoundsChanged]: sliceFactory('mapBounds', (state, payload) => {
    if (typeof payload === 'undefined') {
      return state;
    }

    return ({
      nelat: payload.nelat,
      nelng: payload.nelng,
      swlat: payload.swlat,
      swlng: payload.swlng,
    });
  }),
  [onMapZoom]: (state, zoom) => ({
    ...state,
    zoom,
  }),
  [receiveMapParamsFromUrl]: (state, { zoom, ...bounds }) => ({
    ...state,
    mapBounds: { ...bounds },
    zoom: zoom || defaultState.zoom,
  }),
});

/**
 * Mobile Filter Menu (aka Search Menu)
 */
export const mobileFilterMenuSearch = createReducer({
  [clickSaveOnSearchMenu]: (state, payload, { visitor }) => {
    if (!visitor.isRegistered()) {
      return state;
    }

    return {
      ...state,
      baseSearch: BASE_SEARCH.PENDING,
    };
  },
});

/**
 * Clear mapBounds when a Saved Search is selected from the Edit Saved Searches view.
 * We don't want the mapBounds being applied to a saved search that either doesn't have
 * map bounds or has its own map bounds included.
 */
export const editSavedSearches = createReducer({
  [selectSavedSearch]: (state, { mapBounds, mapZoom }) => ({
    ...state,
    // `mapBounds` will either be an object or null, so the 2nd half of this conditional isn't
    // really necessary, but I'm leaving it as an extra guard.
    mapBounds: mapBounds || defaultState.mapBounds,
    zoom: mapZoom || defaultState.zoom,
  })
});

/**
 * Homepage Search
 */
export const homepageSearch = createReducer({
  ...multiCase(
    [HomepageBallerBoxActions.emptySubmitClick, HomepageBallerBoxActions.selectSuggestion],
    (state) => ({
      ...state,
      mapBounds: defaultState.mapBounds,
      zoom: defaultState.zoom,
    })
  )
});

export default reduceReducers(
  defaultState,
  searchReducer,
  pendingSearchReducer,
  mobileGallerySearch,
  mobileMapSearch,
  mobileFilterMenuSearch,
  editSavedSearches,
  homepageSearch
);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * The Grid component renders a div with class `.grid` and accepts props to apply variant styles.
 * The Boomtown grid is a flexbox-based grid system built on a **twelve-column** layout with a maximum
 * container `width` of `1220px`.
 * + Content is broken into individual rows - or Grids
 * + Each Grid is broken into individual Cells that functions of columns of content
 * + Content should always be wrapped in a Cell
 *
 * @param {object}    props
 * @param {string?}   props.alignContent
 * @param {string?}   props.alignItems
 * @param {string?}   props.justifyContent
 * @param {boolean?}  props.column
 * @param {boolean?}  props.dividers
 * @param {boolean?}  props.flexCells
 * @param {boolean?}  props.gutters
 * @param {boolean?}  props.noWrap
 * @param {string?}   props.xs
 * @param {string?}   props.sm
 * @param {string?}   props.md
 * @param {string?}   props.lg
 * @param {string?}   props.xl
 * @param {any}       props.children
 * @param {any}       props.className
 * @param {object}    props.style
 * @param {string}    props.dataCY
 */

const Grid = props => {
  const {
    alignContent,
    alignItems,
    children,
    className,
    column,
    dividers,
    flexCells,
    gutters,
    resetVertical,
    guttersSmall,
    justifyContent,
    lg,
    md,
    noWrap,
    sm,
    xl,
    xs,
    style,
    dataCY,
    ...rest
  } = props;
  const classes = classNames('grid', className, {
    'grid--gutters--reset-vertical': resetVertical,
    'grid--gutters-small': guttersSmall,
    'grid--gutters': gutters,
    'grid--dirColumn': column,
    'grid--noWrap': noWrap,
    'grid--flexCells': flexCells,
    [`grid--${alignItems}`]: alignItems,
    [`grid--align${alignContent}`]: alignContent,
    [`grid--justify${justifyContent}`]: justifyContent,
    [`grid-${dividers}--dividers`]: dividers,
    [`grid-xs--${xs}`]: xs,
    [`grid-sm--${sm}`]: sm,
    [`grid-md--${md}`]: md,
    [`grid-lg--${lg}`]: lg,
    [`grid-xl--${xl}`]: xl
  });

  return (
    <div style={style} className={classes} data-cy={dataCY} {...rest}>
      {children}
    </div>
  );
};

Grid.propTypes = {
  /** @ignore */
  style: PropTypes.object,
  /** @ignore  */
  children: PropTypes.any,
  /** String of additional CSS classes to render on the Grid element. */
  className: PropTypes.string,
  /** Adds string for Cypress identifier. */
  dataCY: PropTypes.string,
  /** Adds gutters to the Grid between the inner Cells. */
  gutters: PropTypes.bool,
  /**
   * Adds only horizontal gutters to the Grid between the inner Cells. No vertical gutter is added.
   */
  resetVertical: PropTypes.bool,
  /** Adds small gutters to the Grid between the inner Cells. */
  guttersSmall: PropTypes.bool,
  /** Sets `flex-direction: column`, establishes the main-axis as top to bottom instead of ltr. */
  column: PropTypes.bool,
  /** Prevents the Grid from wrapping. All Cells will remain in one row. */
  noWrap: PropTypes.bool,
  /**
   * Sets the contents of a Cell to equal heights of the content in other Cells in the
   * same Grid by making the Cells, themselves, flex containers.
   */
  flexCells: PropTypes.bool,
  /** Sets the vertical alignment of the Cell components within a Grid component. */
  alignItems: PropTypes.oneOf(['top', 'bottom', 'center', 'baseline']),
  /**
   * Aligns a Grid's Cells when there is extra space in the cross-axis, similar
   * to how `justifyContent` aligns individual items within the main-axis. **Note:** this property
   * has no effect when there is only one line of Cells.
   */
  alignContent: PropTypes.oneOf(['start', 'end', 'center', 'between', 'around']),
  /** Sets the horizontal alignment of the Cell components within a Grid component. */
  justifyContent: PropTypes.oneOf(['end', 'center', 'between', 'around', 'evenly']),
  /** Renders dividing lines between Cells at certain screen sizes. */
  dividers: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  /** Supports viewport size less than **480px**. */
  xs: PropTypes.oneOf(['full', 'halves', 'thirds', 'fourths', 'fit']),
  /** Supports viewport sizes **480px** and greater. */
  sm: PropTypes.oneOf(['full', 'halves', 'thirds', 'fourths', 'fit']),
  /** Sets all Cell components to thirds, wrapping to new lines. */
  md: PropTypes.oneOf(['full', 'halves', 'thirds', 'fourths', 'fit']),
  /** Sets all Cell components to fourths, wrapping to new lines. */
  lg: PropTypes.oneOf(['full', 'halves', 'thirds', 'fourths', 'fit']),
  /** Sets all Cell components to equally fill the available space. */
  xl: PropTypes.oneOf(['full', 'halves', 'thirds', 'fourths', 'fit']),
};
Grid.defaultProps = {
  style: {},
};

export default Grid;

import { decorateClean, strip } from 'utility/string';
/**
 * We need to keep track of the current cursor position.
 *
 * @param {string} newVal Poorly decorated string. This string was initially decorated, but then
 *   the user touched it and ruined everything
 * @param {string} oldVal Clean string
 * @param {number} selectionStart Cursor position in newVal
 * @param {number} prefixLength A string that is prefixed to the final string
 */
export default function getCursorPos(newVal, oldVal, selectionStart, prefixLength = 0) {
  const dOldVal = decorateClean(oldVal);
  const dNewVal = decorateClean(strip(newVal));

  // If the new val is empty we just reset, there is no decoration on empty
  if (dNewVal === '') {
    return 0;
  }

  // The first insertion we add the prefix if there is one
  if (prefixLength && newVal.length === 1) {
    return selectionStart + prefixLength;
  }

  // Did decorating result in adding a comma
  if (dNewVal.length > dOldVal.length + 1) {
    return selectionStart + 1;
  }

  // Did decorating result in removing a comma? (Detecting with a change of
  // exactly 2--1 deleted character plus a lost comma) Also ensure that we
  // aren't shifting the cursor into the prefix.
  if (dNewVal.length === dOldVal.length - 2 && selectionStart - prefixLength >= 1) {
    return selectionStart - 1;
  }

  return selectionStart;
}

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./bcdisclaimer.hbs"),depth0,{"name":"modals/register/bcdisclaimer","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label class='bt-disclaimer-contact-form'><input type=\"hidden\" id=\"leadid_tcpa_disclosure\"/><strong>Disclaimer:</strong> <span>"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"contactFormDisclaimer") : depth0), depth0)) != null ? stack1 : "")
    + "</span></label>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid grid--dirColumn grid-md--full cell\">\n    <div class=\"cell bt-modal__top\">\n        <div class=\"text-xs--center mb-16\">\n            <div class=\"js-squeeze__logo bt-modal__squeeze-logo\">\n                <img src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"logoUrl") : depth0), depth0))
    + "\" />\n            </div>\n            <div class=\"bt-heading__h2 uk-text-bold js-squeeze__title\">Free Account Activation</div>\n            <div class=\"js-squeeze__subtext\">Free and complete access in 20 seconds...</div>\n        </div>\n        <div class=\"grid grid--justifyCenter\">\n            <div class=\"cell cell-md-10\">\n"
    + ((stack1 = container.invokePartial(require("./social.hbs"),depth0,{"name":"modals/register/social","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                <form class=\"js-register-form\" data-parsley-validate>\n                  <div class=\"alert alert--small uk-hidden js-alert-messages-container\"></div>\n                  <div class=\"form__group\">\n                    <div class=\"form__label-group\">\n                      <label class=\"form__label bt-text--regular font-size--14\" for=\"registerEmailStep1\">Email Address (Must be valid)</label>\n                    </div>\n                    <input\n                      type=\"email\"\n                      name=\"email\"\n                      id=\"registerEmailStep1\"\n                      autocomplete=\"email\"\n                      data-cy="
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cySelectors") : depth0)) != null ? lookupProperty(stack1,"stepOneEmail") : stack1), depth0))
    + "\n                      class=\"form__input form__input--square at-email-txt at-squeeze-email-txt\"\n                      data-parsley-emailsanitizer\n                      required\n                    />\n                  </div>\n\n                  <div class=\"grid grid--gutters grid-xs--full\">\n                      <div class=\"cell text-xs--center\">\n                          <button class=\"btn btn--primary btn--primary-red btn--width-full btn--square btn--size-large bt-text--regular mt-4 js-next-step-two at-submit-btn\" data-cy="
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cySelectors") : depth0)) != null ? lookupProperty(stack1,"continueWithEmail") : stack1), depth0))
    + ">Continue With Email</button>\n                          <div class=\"uk-text-bold uk-text-muted mt-12\">Already signed up? <a href=\"#\" class=\"js-user-signin\" data-cy="
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"cySelectors") : depth0)) != null ? lookupProperty(stack1,"logInButton") : stack1), depth0))
    + ">Log in</a></div>\n                      </div>\n                  </div>\n                </form>\n            </div>\n        </div>\n    </div>\n    <div class=\"cell cell--noGrow bt-modal__bottom\">\n        <p class=\"uk-text-small uk-text-muted uk-text-bold text-xs--center mt-16 mb-0 bt-disclaimer-terms bt-disclaimer-terms-stepone\">In agreement with our <a href=\"/terms\" target=\"_blank\">Terms of Use</a> and <a href=\"/privacy\" target=\"_blank\">Privacy Policy</a>.</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"tenantInBC") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":8},"end":{"line":46,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"contactFormDisclaimer") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":8},"end":{"line":50,"column":15}}})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});
import PropTypes from 'prop-types';
import React from 'react';
import { Input, TextArea } from 'coreComponents';
import { MOBILE_PROP_DETAILS, PARSLEY } from 'cypress_constants';

/* eslint-enable react/prop-types */
const UserInfoFields = (props) => {
  const {
    formData,
    formHandlers,
    errors,
    commentsLabel,
    hideNameInput,
    hidePhoneInput,
    hideEmailInput,
  } = props;

  /**
   *
   * @param {string} field
   * @returns {{type:string, message: string}}
   */
  const getError = (field) => {
    if (errors && errors[field]) {
      return { type: 'field', message: errors[field] };
    }

    return null;
  };

  return (
    <div>
      <Input
        value={formData.fullname}
        onChange={formHandlers.fullname}
        error={getError('fullname')}
        errorProps={{ dataCY: PARSLEY.ERRORS_LIST }}
        label={hideNameInput ? '' : 'Full Name'}
        id="contactAgent__UserFullName"
        dataCY={MOBILE_PROP_DETAILS.CONTACT_FULL_NAME}
        type={hideNameInput ? 'hidden' : 'text'}
        name="fullname"
        autoComplete="given-name"
      />

      <Input
        value={formData.phone}
        onChange={formHandlers.phone}
        error={getError('phone')}
        errorProps={{ dataCY: PARSLEY.ERRORS_LIST }}
        label={hidePhoneInput ? '' : 'Phone Number'}
        id="contactAgent__UserPhone"
        dataCY={MOBILE_PROP_DETAILS.CONTACT_PHONE}
        type={hidePhoneInput ? 'hidden' : 'tel'}
        name="phone"
        autoComplete="tel"
      />

      <Input
        value={formData.email}
        onChange={formHandlers.email}
        error={getError('email')}
        errorProps={{ dataCY: PARSLEY.ERRORS_LIST }}
        label={hideEmailInput ? '' : 'Email Address'}
        id="contactAgent__UserEmail"
        dataCY={MOBILE_PROP_DETAILS.CONTACT_EMAIL}
        name="email"
        type={hideEmailInput ? 'hidden' : 'email'}
        autoComplete="email"
      />

      <TextArea
        value={formData.comments}
        onChange={formHandlers.comments}
        error={getError('comments')}
        errorProps={{ dataCY: PARSLEY.ERRORS_LIST }}
        label={commentsLabel}
        id="contactAgent__UserComments"
        dataCY={MOBILE_PROP_DETAILS.CONTACT_COMMENTS}
        name="comments"
      />
    </div>
  );
};

UserInfoFields.propTypes = {
  // From createForm()
  formData: PropTypes.object,
  errors: PropTypes.object,
  formHandlers: PropTypes.object,
  hideNameInput: PropTypes.bool,
  hidePhoneInput: PropTypes.bool,
  hideEmailInput: PropTypes.bool,
  commentsLabel: PropTypes.string,
  dataCY: PropTypes.string,
};

UserInfoFields.defaultProps = {
  commentsLabel: 'What would you like to know?',
  hideNameInput: false,
  hidePhoneInput: false,
  hideEmailInput: false,
};

export default UserInfoFields;

/* eslint-disable import/prefer-default-export */
import { routerForBrowser } from 'redux-little-router';
import routes from './routes';

/**
 * A singleton function that configures the redux-little-router components
 * if they haven't been already, and then returns them.
 *
 * @typedef RouterComponents
 * @property {function} reducer
 * @property {function} middleware
 * @property {function} enhancer
 *
 * @param {function} [getLocation] A function that returns the location object
 *  that the router will use.
 * @returns {RouterComponents}
 */
export const routerComponents = routerForBrowser({ routes });

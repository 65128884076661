import { takeLatest, call, put } from 'redux-saga/effects';
import { push } from 'redux-little-router';
import $ from 'jquery';
import { agent, config, utility, visitor } from 'BoomTown';
import getSellerPath from 'utility/getSellerPath';
import * as homeValActions from './actions';

export default [
  takeLatest(homeValActions.onHomeValRequest, handleHomeValSearchSubmit),
  takeLatest(homeValActions.onGeocodeError, handleError),
];

/**
 * Take an geocoded address object, makes a valuation request and either routes the user to a
 * valuation report or handles sending an error message for the UI to handle.
 *
 * @param {Object} action
 * @param {Object} action.payload An address object
 */
export function* handleHomeValSearchSubmit({ payload }) {
  const requestUrl = computeUrl(payload);
  try {
    // `call` needs to return a promise for redux-saga to suspend the generator and resolve it
    const response = yield call([utility, utility.jsonpPromise], requestUrl);

    if (response.Status.Code !== 200) {
      yield handleError();
      return;
    } else if (response.Result.Valuation.EstimatedValue === 0) {
      yield handleError();
      return;
    }

    yield put(push(`/${getSellerPath()}/userReport/${visitor.id}/${response.Result.Valuation.ValuationID}/`));
  } catch (e) {
    yield handleError();
  }
}

/**
 * Routes the user to the home valuation error route which will auto trigger the error modal
 */
export function* handleError() {
  yield put(push(`/${getSellerPath()}/unique/`));
}

/**
 * Receives an address object and returns a formatted valuation request url
 *
 * @param {Object} address
 * @param {string} address.city
 * @param {string} address.state
 * @param {string} address.zip
 * @param {string} address.streetAddress
 * @param {string} address.unit
 */
function computeUrl({ city, state, zip, streetAddress, unit }) {
  const qs = $.param({
    city,
    state,
    zip,
    streetaddress: streetAddress,
    unit,
  });

  let uriString = `${config.apiUrl}/avm/1/valuations/computefull/${visitor.id}?${qs}`;

  if (agent) {
    uriString = `${uriString}&agentID=${agent.id}`;
  }

  return encodeURI(uriString);
}

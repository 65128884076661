/* eslint-disable indent */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import { EDIT_SAVED_SEARCHES } from 'routes';
import { getSavedSearches } from 'models/visitor/reducers/selectors';
import FAIcon from 'components/core/FAIcon';
import MenuList, { MenuListRouterLink } from 'components/Common/MenuList';
import { isMobile as isMobileSelector } from 'selectors/browser';

const closeDropdowns = () => {
  $.ccompCloseAllDropdowns();
  $.ccompCloseAllToggles();
  $.closeOffCanvas();
};

const ManageSavedSearchesLink = () => (
  <MenuListRouterLink
    itemClassName="mt-16"
    href={`/${EDIT_SAVED_SEARCHES}/`}
    onClick={closeDropdowns}
    rel="nofollow"
  >
    Manage Saved Searches <FAIcon icon="angle-right" type="regular" />
  </MenuListRouterLink>
);

const SavedSearchMenu = ({ searches = [] }) => (
  <MenuList className="bt-saved-searches__list">
    {searches.length !== 0 ? (
      [
        searches.map(search => (
          <MenuListRouterLink
            key={search.searchid}
            id={search.searchid}
            href={`/results-gallery/?${search.querystring}`}
            title={`See results for: ${search.searchName}`}
            onClick={closeDropdowns}
            rel="nofollow"
          >
            {search.searchName}
          </MenuListRouterLink>
        )),
        <ManageSavedSearchesLink key="manage-saved-searches" />,
      ]
    ) : (
      <li className="bt-saved-searches__none">You currently have no saved searches</li>
    )}
  </MenuList>
);

SavedSearchMenu.propTypes = {
  searches: PropTypes.arrayOf(
    PropTypes.shape({
      searchid: PropTypes.number,
      searchName: PropTypes.string,
      querystring: PropTypes.string,
      email: PropTypes.string,
      frequency: PropTypes.number,
      count: PropTypes.number,
    })
  ),
  className: PropTypes.string,
};

const ConnectedSavedSearchMenu = connect(state => ({
  searches: getSavedSearches(state),
}))(SavedSearchMenu);

export default connect(state => ({ isMobile: isMobileSelector(state) }))(({ isMobile }) => {
  if (isMobile) {
    return null;
  }

  // TODO: We're rendering BOTH of these components on desktop, and they're the exact same thing except
  // for what DOM element they mount do and an additional className passed in. Need to see about
  // consolidating down to one mounting point, or at the least wrap them in one of our Breakpoint
  // components so we only ever render one at a time.
  const desktopContainer = document.querySelector('.jsx-saved-search-menu');
  const offCanvasContainer = document.querySelector('.jsx-off-canvas-saved-search-menu');

  return (
    <React.Fragment>
      {desktopContainer && ReactDOM.createPortal(<ConnectedSavedSearchMenu />, desktopContainer)}
      {offCanvasContainer &&
        ReactDOM.createPortal(
          <ConnectedSavedSearchMenu className="bt-off-canvas__sub-menu" />,
          offCanvasContainer
        )}
    </React.Fragment>
  );
});

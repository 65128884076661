/* eslint-disable import/prefer-default-export */

/**
 * The two types of confirmation modals that can be shown on the email
 * preferences screen.
 */
export const ConfirmationModal = {
  UnsubscribeEAlerts: 'UNSUBSCRIBE_EALERTS',
  UnsubscribeAll: 'UNSUBSCRIBE_ALL',
};

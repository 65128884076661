/* eslint-disable quote-props */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/**
 * Font Awesome Icon Component
 * Example: <FAIcon icon="spinner" type="solid" animation="spin" dataCY="cy-fa-icon-spinner" className="Modal_IconSpinner" fixedWidth />
 * Documentation: See https://fontawesome.com/icons for possible icons. Icon property values are minus the `fa-` prefix.
 */
const FAIcon = (props) => {
  const {
    className,
    dataCY,
    icon,
    type,
    animation,
    rotate,
    flip,
    fixedWidth,
    ...otherProps
  } = props;

  const iconClass = cx(
    // FA Icon Class
    [`fa-${icon}`],
    // FA Icon Style Type
    {
      far: type === 'regular',
      fas: type === 'solid',
      fal: type === 'light',
      fad: type === 'duotone',
      fab: type === 'brand',
    },
    // FA Icon Animation
    {
      'fa-spin': animation === 'spin',
      'fa-pulse': animation === 'pulse',
    },
    // FA Icon Rotation
    {
      'fa-rotate-90': rotate === '90',
      'fa-rotate-180': rotate === '180',
      'fa-rotate-270': rotate === '270',
    },
    // FA Icon Flip
    {
      'fa-flip-horizontal': flip === 'horizontal',
      'fa-flip-vertical': flip === 'vertical',
      'fa-flip-both': flip === 'both',
    },
    // FA Fixed Width Icon
    {
      'fa-fw': fixedWidth,
    },
    // BoomTown Class and Extra Classes
    'bt-fa-icon',
    className,
  );

  return (
    <i
      className={iconClass}
      data-cy={dataCY}
      {...otherProps}
    />
  );
};

FAIcon.propTypes = {
  className: PropTypes.string,
  dataCY: PropTypes.string,
  icon: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['regular', 'solid', 'light', 'duotone', 'brand']),
  animation: PropTypes.oneOf(['spin', 'pulse']),
  rotate: PropTypes.oneOf(['90', '180', '270']),
  flip: PropTypes.oneOf(['horizontal', 'vertical', 'both']),
  fixedWidth: PropTypes.bool,
};

FAIcon.defaultProps = {
  type: 'regular',
  fixedWidth: true,
};

export default FAIcon;

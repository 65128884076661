/* eslint-disable max-len */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import cx from 'classnames';

/** The image we use if an Agent image does not exist */
const noImageSrc =
  'data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjAgMTUwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2RjZGNkY30uY2xzLTJ7ZmlsbDojZmZmfTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5ub19hZ2VudF9hdmF0YXI8L3RpdGxlPjxwYXRoIGlkPSJSZWN0YW5nbGUtMiIgY2xhc3M9ImNscy0xIiBkPSJNMCAwaDEyMHYxNTBIMHoiLz48cGF0aCBjbGFzcz0iY2xzLTIiIGQ9Ik00MC4xOCA3NWgzOS42NEEyMi4xOCAyMi4xOCAwIDAgMSAxMDIgOTcuMThWMTUwSDE4Vjk3LjE4QTIyLjE4IDIyLjE4IDAgMCAxIDQwLjE4IDc1eiIvPjxnIGlkPSJwYXRoLTEiPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTYwIDc2LjVBMjkuNSAyOS41IDAgMSAxIDg5LjUgNDcgMjkuNTMgMjkuNTMgMCAwIDEgNjAgNzYuNXoiLz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik02MCAyMmEyNSAyNSAwIDEgMS0yNSAyNSAyNSAyNSAwIDAgMSAyNS0yNW0wLTlhMzQgMzQgMCAxIDAgMzQgMzQgMzQgMzQgMCAwIDAtMzQtMzR6Ii8+PC9nPjwvc3ZnPg==';

const AvatarSkeleton = (
  { className } // eslint-disable-line
) => (
  <div className={cx('agent-avatar', className)}>
    <img src={noImageSrc} alt="Agent" title="Agent" />
  </div>
);

export default class AgentAvatar extends Component {
  static propTypes = {
    className: PropTypes.string,

    // If passed null or undefined, we will show an indeterminate AgentAvatar
    agent: PropTypes.shape({
      FullName: PropTypes.string.isRequired,
      PhotoUrl: PropTypes.string,
      PhotoLink: PropTypes.string,
      Slug: PropTypes.string,
    }),
    tenantAlias: PropTypes.string,
    isLender: PropTypes.bool,
  };

  static defaultProps = {
    isLender: false,
  };

  static displayName = 'AgentAvatar';

  constructor(props) {
    super(props);
    this.state = {
      hasErrored: false,
    };
  }

  /**
   * Maintain error state to render `noImage` on error.
   */
  onError = () => {
    this.setState({ hasErrored: true });
  };

  render() {
    if (typeof this.props.agent !== 'object') {
      return <AvatarSkeleton className={this.props.className} />;
    }

    const { agent, isLender } = this.props;
    const { PhotoUrl, PhotoLink } = agent;
    let src;

    if (this.state.hasErrored) {
      src = noImageSrc;
    } else if (PhotoUrl) {
      src = PhotoUrl;
    } else if (PhotoLink && this.props.tenantAlias) {
      src = `//boomstatic.com/content/tenant/${this.props.tenantAlias}/agent/${PhotoLink}`;
    } else {
      src = noImageSrc;
    }

    // CNS-5201: If the agent is a lender, redirect the user to the finance page
    const Uri = isLender ? '/finance/' : `/agents/${agent.Slug}`;

    const agentImg = (
      <img
        className="agent-avatar__img width-1-1"
        itemProp="image"
        onError={this.onError}
        alt={agent.FullName}
        src={src}
        title={agent.FullName}
      />
    );

    // If the slug is undefined, we don't want to render the agent avatar as a link
    if (!isLender && !agent.Slug) {
      return agentImg;
    }

    return (
      <a className={cx(this.props.className, 'agent-avatar')} title="See My Profile" href={Uri}>
        {agentImg}
      </a>
    );
  }
}

import $ from 'jquery';
import bt from 'BoomTown';
import phoneValidator from 'utility/phonevalidator';
import { isFullName, isValidName } from 'utility/parse-names';

/**
 * Adds custom validators to `window.ParsleyValidator`.
*/
const addCustomParsleyValidators = () => {
  // Add data-parsley-phone to the phone input
  // Validate using Google Phone Validator, must run before other Parsley validators
  window.ParsleyValidator
    .addValidator(
      'phone',
      value => {
        const $fbWarning = $('.js-fb-phone-warning');
        const $fbSuccess = $('.js-fb-phone-success');
        const results = phoneValidator(value);

        // Facebook complete form valid phone icon toggle
        if (results.valid) {
          bt.utility.hide($fbWarning);
          bt.utility.show($fbSuccess);
          window.ParsleyUI.removeError('phone');
        } else {
          bt.utility.hide($fbSuccess);
          bt.utility.show($fbWarning);
        }
        return results.valid === true;
      },
      32
    )
    .addMessage('en', 'phone', 'Please enter a valid phone');

  // Full name validation
  // Validates whether or not there is more than one word entered
  window.ParsleyValidator
    .addValidator('fullname', isFullName, 32)
    .addMessage('en', 'fullname', 'Enter a valid full name');

  window.ParsleyValidator
    .addValidator('isvalid', isValidName, 32)
    .addMessage('en', 'isvalid', 'Enter a valid name');

  // Email sanitizer
  window.ParsleyValidator
    .addValidator(
      'emailsanitizer',
      value => {
        // Exclude the blacklist
        const blacklist = [
          'steve@apple.com',
          'ebay-beta+help@mozilla.com',
          'stevejobs@apple.com',
          'sjobs@apple.com',
          'mickey@disney.com',
          'donald@duck.com',
          'bill@microsoft.com'
        ];
        const valid = !blacklist.includes(value.toLowerCase());
        if (!valid) {
          return false;
        }
        try {
          /* eslint-disable new-cap */
          return window.ParsleyValidator.Validator.Assert.prototype.Email().validate(value);
        } catch (e) {
          return false;
        }
      },
      32
    )
    .addMessage('en', 'email', 'Enter a valid email address');
};

export default addCustomParsleyValidators;

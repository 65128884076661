/* eslint-disable no-param-reassign */
import { createAction } from 'redux-act';
import { location } from 'BoomTown';
import Tag from 'backbone/Model/tag';
import * as DeviceLocationActions from 'actions/DeviceLocationActions';
import { getPendingSearch } from 'models/search/reducers/pending/selectors';

/**
 * `createAction()` for all actions that invalidate the search count/results.
 * @param {String} type
 * @returns {ReduxThunk}
 */
export function createUpdateCountAction(type, payloadReducer = x => x) {
  // Receives `dispatch` as param from `bindActionCreators()` used in the `mapDispatchToProps` call
  // in a component's `connect()` call.
  const actionCreator = payload => dispatch => {
    dispatch({ type, payload: payloadReducer(payload) });
    // Invalidate the Search Count which lets the `updateCountTask` in our offCanvas saga take over
    // fetching an updated search count value.
    dispatch(updatePendingSearchFilters());
    dispatch(invalidateCount());
  };
  actionCreator.toString = () => type;
  return actionCreator;
}

export const open = createAction('OPEN', ({ initialSearchCount = 0 } = {}) => ({
  initialSearchCount,
}));
export const close = createAction('CLOSE', () => {});

export const updateCountSuccess = createAction('UPDATE_COUNT_SUCCESS');
export const updateCountFailure = createAction('UPDATE_COUNT_FAILURE');
export const invalidateCount = createAction('INVALIDATE_COUNT');
export const updatePendingSearchFilters = createAction('UPDATE_PENDING_SEARCH_FILTERS');

export const removeSearchTerm = createUpdateCountAction('REMOVE_SEARCH_TERM');
export const updateSearchTerm = createUpdateCountAction('UPDATE_SEARCH_TERM');

export const updatePrice = createUpdateCountAction('UPDATE_PRICE');
export const updateMinBeds = createUpdateCountAction('UPDATE_MIN_BEDS');
export const updateMinBaths = createUpdateCountAction('UPDATE_MIN_BATHS');
export const togglePropertyType = createUpdateCountAction('TOGGLE_PROPERTY_TYPE');
export const toggleSaleType = createUpdateCountAction('TOGGLE_SALE_TYPE');
export const toggleHasPhotos = createUpdateCountAction('TOGGLE_HAS_PHOTOS');
export const toggleVirtualTours = createUpdateCountAction('TOGGLE_VIRTUAL_TOURS');
export const updateMinAcres = createUpdateCountAction('UPDATE_MIN_ACRES');
export const updateMaxAcres = createUpdateCountAction('UPDATE_MAX_ACRES');
export const updateMinSqft = createUpdateCountAction('UPDATE_MIN_SQFT');
export const updateMaxSqft = createUpdateCountAction('UPDATE_MAX_SQFT');
export const updateMinStories = createUpdateCountAction('UPDATE_MIN_STORIES');
export const updateMaxStories = createUpdateCountAction('UPDATE_MAX_STORIES');
export const updateMinYear = createUpdateCountAction('UPDATE_MIN_YEAR');
export const updateMaxYear = createUpdateCountAction('UPDATE_MAX_YEAR');
export const updateMinGarages = createUpdateCountAction('UPDATE_MIN_GARAGES');
export const updateMaxGarages = createUpdateCountAction('UPDATE_MAX_GARAGES');
export const updateMaxDaysListed = createUpdateCountAction('UPDATE_MAX_DAYS_LISTED');
export const updatePriceReducedDate = createUpdateCountAction('UPDATE_PRICE_REDUCED_DATE');
export const toggleHasOpenHouse = createUpdateCountAction('TOGGLE_HAS_OPEN_HOUSE');
export const toggleFeature = createUpdateCountAction('TOGGLE_FEATURE');
export const toggleStatus = createUpdateCountAction('TOGGLE_STATUS');

export const saveSearchClick = createAction('Save search click');

export function applyAndClose() {
  return dispatch => {
    dispatch(applySearch());
    dispatch(close());
  };
}

export const applySearch = createAction('Apply search');

// REFACTOR: This was added so that if a user hits
// save search they have a working flow. The other option would be to
// migrate TagsCollection and update the save search modal to be react.
// This does introduce a bug where pending search is applied early;
// filter, save btn, back, cancel, *err* my search was applied
export const saveSearch = () => dispatch => {
  dispatch(applySearch());
  dispatch(saveSearchClick());
};

// When the location data is available and we are ready
// to invalidate the previous count and fetch another number.
export const selectNearbySearchLegacy = createUpdateCountAction('SELECT_NEARBY_SEARCH');
export const removeNearbySearch = createUpdateCountAction('REMOVE_NEARBY_SEARCH');

// When the user taps the location search button
export const selectNearbyThunk = () => (dispatch, getState) => {
  const pendingSearch = getPendingSearch(getState());

  if (pendingSearch.nearby) {
    return Promise.resolve(dispatch(removeNearbySearch()));
  }
  return dispatch(DeviceLocationActions.requestLocation()).then(
    () => {
      dispatch(selectNearbySearchLegacy());
    },
    error => {
      const message = location.getErrorMessage(error);
      window.alert(message); // eslint-disable-line no-alert
      // Note that the PositionError is swallowed here by not throwing or
      // returning a rejected Promise.
    }
  );
};

export const ballerboxOnClose = createAction('Mobile Search Menu > Ballerbox > Click close icon');
export const ballerboxReceiveSuggestions = createAction(
  'Mobile Search Menu > Ballerbox > Receive suggestions from API'
);
export const ballerboxReceiveSuggestionsFailure = createAction(
  'Mobile Search Menu > Ballerbox > Receive suggestions from API FAILED'
);
export const ballerboxOnSelection = createAction(
  'Mobile Search Menu > Ballerbox > Select suggestion'
);

/**
 * Async Action Creator that handles storing the displayName of the selected search suggestion in
 * localStorage.
 *
 * @description Since we update the Search Tags for the UI when the pending search state updates,
 * attempting to set the displayName for a search term in a saga is too late and causes a race
 * condition. Now, before `ballerboxOnSelection` is dispatched (the action used to update the
 * pending search state with the search value), `selectSearchSuggestion` sets the value in
 * localStorage first THEN dispatches `ballerboxOnSelection`.
 *
 * @see https://redux.js.org/advanced/async-actions#async-action-creators
 *
 * @param {{displayName: string, searchTerm: string, type: string}} payload
 *
 * @returns {ReduxThunk}
 */
export function selectSearchSuggestion(payload) {
  const { displayName, searchTerm } = payload;
  if (!searchTerm.includes('=')) {
    throw new Error(
      `Ballerbox Search Term should be in name=value format, but instead we received ${searchTerm}`
    );
  }

  const [name, value] = searchTerm.split('=');
  Tag.setLocalStorage(name.toLowerCase(), value, displayName);

  return function (dispatch) {
    dispatch(ballerboxOnSelection(payload));
  };
}

// New "Nearby Search" action that _doesn't_ use `latlonrad` but rather the Map's user location
// marker instead.
export const selectNearbySearch = createAction(
  'Search Menu > Ballerbox > Select Nearby Search'
);
export const ballerboxOnFocus = createAction('Mobile Search Menu > Ballerbox > Input focus');
export const ballerboxOnChange = createAction('Mobile Search Menu > Ballerbox > Input change');

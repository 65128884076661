/* eslint-disable import/prefer-default-export  */
import { createAction } from 'redux-act';

const action = (title, ...args) => createAction(`Map > ${title}`, ...args);

export const onMapZoom = action('Zoom level changed');
export const onMapDragged = action('Position has changed via drag');
export const onMapIdle = action('Is Idle');
export const onMapBoundsChanged = action(
  'Boundary has changed',
  /** @param {MapBounds} */
  x => x
);

export const receiveMapParamsFromUrl = action('Receive Map search params from Url', x => x);
export const fetchListings = action('Fetch listings for map search', () => {});
export const receiveResults = action(
  'Receive map search results',
  /**
   * @param {{totalItems: number, listings: Array, totalItemsWithoutCoords: number, search: {}, pageIndex: number}}
   */
  x => x
);
export const receiveNewResultsPage = action(
  'Receive new page of map search results',
  /**
   * @param {{ listings: Array,  pageIndex: number, totalItems: number, totalItemsWithoutCoords: number}}
   */
  x => x
);
export const errorResults = action('ERROR fetching map results', () => {});

export const receivePinsForStore = action('Received pins',
  /**
   * @param {{coords: Array, listingID: number}}
   */
  x => x
);

export const onPinClicked = action(
  'Pin Clicked',
  /** @param {MapPin} pin The clicked pin object */
  pin => pin
);

/**
 * Only dispatched on desktop, triggers route to details page
 */
export const onActivePinClick = action(
  'Pin Clicked Twice',
  /** @param {string} urlPath The clicked listing's UrlPath */
  urlPath => urlPath
);

// Triggered when interacting with the map whenever
// `state.resultsMap.activeListing !== null`
export const dismissShelfOnTouch = action('Dismissed card shelf on touch');
export const dismissShelfOnDrag = action('Dismissed card shelf on drag');

export const hasPannedToCurrentLocation = action('Panned to current location');

import loadable from 'react-loadable';
import backboneToReactView from 'legacy/Routing/backboneToReactView';

export const LazyMobileResultsMap = loadable({
  loader: () => import(
    /* webpackChunkName: 'js/build/results-map--xp' */
    'screens/ResultsMap/Mobile'
  ),
  loading: () => null,
});

export const LazyDesktopResultsMap = loadable({
  loader: () => import(
    /* webpackChunkName: 'js/build/results-map--xp' */
    'screens/ResultsMap/Desktop'
  ),
  loading: () => null,
});

export const LazyLegacyDesktopResultsMap = loadable({
  loader: () => import(
    /* webpackChunkName: 'js/build/results-map' */
    'legacy/Views/Listings/map/map'
  ).then(mod => backboneToReactView(mod.default)),
  loading: () => null,
});

import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import Modal from 'components/core/Modal';
import { SecondaryButton, PrimaryButton } from 'coreComponents';
import { H2, H3 } from 'components/core/Headings';
import { Grid, Cell } from 'components/core/Grid';
import { MOBILE_ACCOUNT } from 'cypress_constants';

/**
 * Response Modal
 * @description Used as a success or failure response for forms that submit data to the API
 *
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {boolean} props.isSuccess
 * @param {string} props.successText
 * @param {string | null} props.errorMessage
 * @param {Function} props.onClickSuccess
 * @param {Function} props.onClickBack
 * @param {Function} props.onClickRetry
 */
const ResponseModal = props => {
  const { isOpen, isSuccess } = props;
  return (
    <Modal show={isOpen} animate>
      <Grid justifyContent="center" alignItems="center">
        <Cell md={10} className="text-xs--center">
          {isSuccess ? <SuccessResponse {...props} /> : <FailureResponse {...props} />}
        </Cell>
      </Grid>
    </Modal>
  );
};
ResponseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool,
  onClickSuccess: PropTypes.func,
  onClickBack: PropTypes.func,
  onClickRetry: PropTypes.func,
  successText: PropTypes.string,
  errorMessage: PropTypes.string,
};
export default pure(ResponseModal);

/**
 * Success Response Modal Content
 *
 * @param {Object} props
 * @param {string} props.successText
 * @param {Function} props.onClickSuccess
 */
const SuccessResponse = props => {
  const { successText, onClickSuccess } = props;
  return (
    <div>
      <H2>{successText}</H2>
      <PrimaryButton
        className="px-16 at-thankyou-btn"
        dataCY={MOBILE_ACCOUNT.MODAL_THANK_YOU}
        onClick={() => onClickSuccess()}
      >
        Thank You!
      </PrimaryButton>
    </div>
  );
};
SuccessResponse.propTypes = {
  successText: PropTypes.string,
  onClickSuccess: PropTypes.func,
};

/**
 * Failure Response Modal Content
 *
 * @param {Object} props
 * @param {string | null} props.errorMessage
 * @param {Function} props.onClickRetry
 * @param {Function} props.onClickBack
 */
const FailureResponse = props => {
  const { errorMessage, onClickRetry, onClickBack } = props;

  return (
    <div>
      {!errorMessage ? (
        <div>
          <H2 className="mb-0">Whoops!</H2>
          <H3>Something went wrong.</H3>
        </div>
      ) : (
        <H2>{errorMessage}</H2>
      )}

      <Grid gutters>
        <Cell>
          <SecondaryButton className="px-16" width="full" onClick={onClickBack}>
            Back
          </SecondaryButton>
        </Cell>
        <Cell>
          <PrimaryButton className="px-16" width="full" onClick={onClickRetry}>
            Retry Submit
          </PrimaryButton>
        </Cell>
      </Grid>
    </div>
  );
};
FailureResponse.propTypes = {
  errorMessage: PropTypes.string,
  onClickRetry: PropTypes.func,
  onClickBack: PropTypes.func,
};

import { createAction } from 'redux-act';

const action = (desc, ...args) => createAction(`Email Prefs > ${desc}`, ...args);

// Navigation

// Only used on Mobile
export const clickCloseEmailPrefs = action('Click close in header');

export const clickEditSavedSearchesButton = action(
  'Click Edit Saved Searches button',
  () => {}
);

// Form Submission
export const submitEmailPreferencesForm = action(
  'Submit email preferences form',
  () => {}
);
export const failedSubmitEmailPreferencesForm = action(
  "Failure when submitting the email pref's form",
  ({ error }) => ({ error })
);
export const successSubmitEmailPreferencesForm = action(
  "Successful resp. when submitting the email pref's form",
  /** @param {FlagshipAPI.EmailPrefsUpdateRequest} x */
  x => x
);

// Form Interactions
export const toggleAgentSubscription = action(
  'Toggle subscription to agent emails',
  isSubscribed => isSubscribed
);
export const toggleLenderSubscription = action(
  'Toggle subscription to lender emails',
  isSubscribed => isSubscribed
);
export const toggleEAlertsSubscription = action(
  'Toggle subscription to e-Alert emails',
  isSubscribed => isSubscribed
);
export const clickUnsubscribeAll = action('Click the "Unsubscribe all" link', () => {});

// Unsubscribe All Confirmation Modal
const unsubscribeAllConfAction = (desc, ...args) =>
  action(`Unsubscribe all conf. modal > ${desc}`, ...args);

export const clickConfirmUnsubscribeAllModal = unsubscribeAllConfAction('Click confirm', () => {});
export const clickCancelUnsubscribeAllModal = unsubscribeAllConfAction('Click cancel', () => {});


// Unsubscribe Listing e-Alerts Confirmation Modal
const unsubscribeEAlertsConfAction = (desc, ...args) =>
  action(`Unsubscribe eAlerts conf. modal > ${desc}`, ...args);

export const clickConfirmUnsubscribeEAlertsModal = unsubscribeEAlertsConfAction(
  'Click confirm',
  () => {}
);
export const clickCancelUnsubscribeEAlertsModal = unsubscribeEAlertsConfAction(
  'Click cancel',
  () => {}
);
export const clickEditSavedSearchesUnsubscribeEAlertsModal = unsubscribeEAlertsConfAction(
  'Click edit saved searches',
  () => {}
);

// Response Modal
const respModalAction = (desc, ...args) => action(`Response modal > ${desc}`, ...args);

export const clickCloseEmailPrefsSuccessModal = respModalAction('Tap Confirm button');
export const autoDismissEmailPrefsSuccessModal = respModalAction('Toast timeout');
export const clickBackInEmailPrefsFailureModal = respModalAction('Tap Back button');
export const clickRetryInEmailPrefsFailureModal = respModalAction('Tap Retry button');

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  bt-listing-teaser__"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"SashType") : depth0), depth0))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    bt-listing-teaser__offmarket\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    data-index=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"Index") : depth0), depth0))
    + "\"\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <button type=\"button\" aria-label=\"Favorite\" class=\"bt-listing__favorite-container js-card-fav\" data-cy=\"listing-card_favorite-button\" data-gtm=\"listing-card__favorites-button\">\n        <div class=\"favorite-icon bt-listing__favorite-button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsFavorite") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":62},"end":{"line":42,"column":130}}})) != null ? stack1 : "")
    + "\">\n          <i class=\"fa-heart"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IsFavorite") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":43,"column":28},"end":{"line":43,"column":71}}})) != null ? stack1 : "")
    + "fa-fw bt-fa-icon favorite-icon__heart\"></i>\n        </div>\n      </button>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "favorite-icon--active favorite-icon--paused";
},"10":function(container,depth0,helpers,partials,data) {
    return " fas ";
},"12":function(container,depth0,helpers,partials,data) {
    return " far ";
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"bt-sash--listing-card\" data-cy=\"listing-card__sash\">\n        <i class=\"bt-sash bt-sash--"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"SashType") : depth0), depth0))
    + " py-4 px-12\">\n          <span class=\"bt-sash__text\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"SashText") : depth0), depth0))
    + "</span>\n        </i>\n        <span>\n        </span>\n      </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"SoldPrice") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":63,"column":14},"end":{"line":67,"column":21}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                $"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"SoldPriceFormatted") : depth0), depth0))
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "                Price Not Available\n";
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              $"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"ListPriceFormatted") : depth0), depth0))
    + "\n";
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              1 of "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"PhotoCount") : depth0), depth0))
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "              1 of 1\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n      <div class=\"listing-card__disclaimer-wrapper"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"increaseDisclaimerFontSize") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":50},"end":{"line":92,"column":105}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"listing-card__disclaimer bt-clearfix\">\n          "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"ListingTopHtml") : depth0), depth0))
    + "\n        </div>\n      </div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return " font-size--14";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"Neighborhood") : stack1)) != null ? lookupProperty(stack1,"Name") : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":8},"end":{"line":103,"column":15}}})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <span data-cy=\"listing-card__city-selector\" itemprop=\"addressLocality\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"Neighborhood") : stack1)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + "</span>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hideListingCardNeighborhood") : depth0),{"name":"unless","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":8},"end":{"line":110,"column":19}}})) != null ? stack1 : "")
    + "        <span data-cy=\"listing-card__city-selector\" itemprop=\"addressLocality\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"CityDetail") : stack1)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + "</span>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"Neighborhood") : stack1)) != null ? lookupProperty(stack1,"Name") : stack1),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":12},"end":{"line":109,"column":19}}})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    return "              <span class=\"bt-text--muted\"> | </span>\n";
},"37":function(container,depth0,helpers,partials,data) {
    return " mt-4";
},"39":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<div class=\"cell cell-xs-none listing-card__meta--units\">\n					<span class=\"bt-text--bold\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"NumUnits") : depth0), depth0))
    + "</span>\n					<span class=\"listing-card__meta-label\">units</span>\n				</div>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"Bedrooms") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":123,"column":4},"end":{"line":128,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"FullBaths") : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":129,"column":4},"end":{"line":134,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideHalfBaths") : depth0),{"name":"unless","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":135,"column":4},"end":{"line":142,"column":15}}})) != null ? stack1 : "");
},"42":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"cell cell-xs-none listing-card__meta--beds\">\n            <span class=\"bt-text--bold\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"Bedrooms") : depth0), depth0))
    + "</span>\n            <span class=\"listing-card__meta-label\">beds</span>\n          </div>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"cell cell-xs-none listing-card__meta--baths\">\n            <span class=\"bt-text--bold\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"FullBaths") : depth0), depth0))
    + "</span>\n            <span class=\"listing-card__meta-label\">baths</span>\n          </div>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"HalfBaths") : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":136,"column":5},"end":{"line":141,"column":12}}})) != null ? stack1 : "");
},"47":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<div class=\"cell cell-xs-none listing-card__meta--halfBaths\">\n							<span class=\"bt-text--bold\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"HalfBaths") : depth0), depth0))
    + "</span>\n              <span class=\"listing-card__meta-label\">&frac12; baths</span>\n						</div>\n";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"SqFtSource") : depth0),{"name":"unless","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":145,"column":4},"end":{"line":154,"column":15}}})) != null ? stack1 : "");
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"cell cell-xs-none listing-card__meta--sqft\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ApproxSqFtText") : depth0),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":6},"end":{"line":152,"column":13}}})) != null ? stack1 : "")
    + "					</div>\n";
},"51":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<span class=\"bt-text--bold\">\n								"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"ApproxSqFtText") : depth0), depth0))
    + "\n							</span>\n							<span class=\"listing-card__meta-label\">sqft</span>\n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"cell cell-xs-none listing-card__meta--acres\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"HideBedsBaths") : depth0),{"name":"unless","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":157,"column":65},"end":{"line":157,"column":107}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"AcreageText") : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":5},"end":{"line":167,"column":12}}})) != null ? stack1 : "")
    + "        </div>\n";
},"54":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<span class=\"bt-text--bold\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"DisableAcreage") : depth0),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.program(59, data, 0),"data":data,"loc":{"start":{"line":160,"column":14},"end":{"line":164,"column":21}}})) != null ? stack1 : "")
    + "						</span>\n						<span class=\"listing-card__meta-label\">acres</span>\n";
},"57":function(container,depth0,helpers,partials,data) {
    return "                  N/A\n";
},"59":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"AcreageText") : depth0), depth0))
    + "\n";
},"61":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"cell cell-xs-6 cell-md-12\">\n          <div class=\"listing-card__onsite mt-4 bt-text--truncate\" data-alt-text=\""
    + ((stack1 = __default(require("../../../assets/js/helpers/ifEq.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"OnSiteText") : depth0),"Today",{"name":"ifEq","hash":{},"fn":container.program(62, data, 0),"inverse":container.program(64, data, 0),"data":data,"loc":{"start":{"line":175,"column":82},"end":{"line":175,"column":164}}})) != null ? stack1 : "")
    + "\">\n            <span class=\"listing-card__onsite-label bt-text--bold\">On Site: </span>\n            <span class=\"listing-card__onsite-text\">\n              "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"OnSiteText") : depth0), depth0))
    + "\n            </span>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DisplayBtAsDataSource") : depth0),{"name":"if","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":181,"column":12},"end":{"line":183,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n        </div>\n";
},"62":function(container,depth0,helpers,partials,data) {
    return "Listed Today";
},"64":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Listed "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"OnSiteText") : depth0), depth0))
    + " Ago";
},"66":function(container,depth0,helpers,partials,data) {
    return "              <em>(via BoomTown)</em>\n";
},"68":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"cell cell-xs-6 cell-md-12\">\n          <div class=\"listing-card__onmarketdate mt-4 bt-text--truncate\">\n            <span class=\"listing-card__onmarketdate-label\">On Market: </span>\n            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"OnMarketDateFormatted") : depth0), depth0))
    + "\n          </div>\n        </div>\n";
},"70":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"cell cell-xs-12\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"FeatureText") : depth0),{"name":"each","hash":{},"fn":container.program(71, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":200,"column":10},"end":{"line":209,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n";
},"71":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"mt-4 bt-text--truncate\">\n              <span class=\"bt-text--bold\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"Name") : depth0), depth0))
    + ": </span>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"Features") : depth0),{"name":"each","hash":{},"fn":container.program(72, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":205,"column":16},"end":{"line":207,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n";
},"72":function(container,depth0,helpers,partials,data) {
    return "                  "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\n";
},"74":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"listing-card__disclaimer-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ThumbCardDisclaimer") : depth0),{"name":"if","hash":{},"fn":container.program(75, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":216,"column":6},"end":{"line":218,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../assets/js/helpers/and.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"IsMultiBoard") : depth0),(depth0 != null ? lookupProperty(depth0,"MultiMLSThumbcardDisclaimer") : depth0),{"name":"and","hash":{},"fn":container.program(78, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":219,"column":6},"end":{"line":221,"column":14}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"HomePageAndMapDisclaimer") : depth0),{"name":"if","hash":{},"fn":container.program(80, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":222,"column":6},"end":{"line":224,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"SoldThumbCardDisclaimer") : depth0),{"name":"if","hash":{},"fn":container.program(82, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":225,"column":6},"end":{"line":227,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n";
},"75":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div data-cy=\"listing-card__disclaimer\" class=\"bt-clearfix listing-card__disclaimer"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"increaseDisclaimerFontSize") : depth0),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":217,"column":91},"end":{"line":217,"column":164}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"ThumbCardDisclaimer") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n";
},"76":function(container,depth0,helpers,partials,data) {
    return " listing-card__disclaimer--large";
},"78":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"bt-clearfix listing-card__disclaimer"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"increaseDisclaimerFontSize") : depth0),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":220,"column":56},"end":{"line":220,"column":129}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"MultiMLSThumbcardDisclaimer") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n";
},"80":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"bt-clearfix listing-card__disclaimer"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"increaseDisclaimerFontSize") : depth0),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":223,"column":56},"end":{"line":223,"column":129}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"HomePageAndMapDisclaimer") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n";
},"82":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"bt-clearfix listing-card__disclaimer"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"increaseDisclaimerFontSize") : depth0),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":226,"column":56},"end":{"line":226,"column":129}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"SoldThumbCardDisclaimer") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<article class=\"bt-listing-teaser bt-listing-teaser__preview bt-listing-proptype__"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"PropertyType") : depth0)) != null ? lookupProperty(stack1,"_ID") : stack1), depth0))
    + " js-card"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"SashType") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":4,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"shouldDisplayAsOffMarket") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":7,"column":7}}})) != null ? stack1 : "")
    + "\"\n  id=\"listings-card-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"_ID") : depth0), depth0))
    + "\"\n  data-cy=\"listing-card_card\"\n  data-listingid=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"_ID") : depth0), depth0))
    + "\"\n  data-lat=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"Coordinates") : stack1)) != null ? lookupProperty(stack1,"Latitude") : stack1), depth0))
    + "\"\n  data-lng=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"Coordinates") : stack1)) != null ? lookupProperty(stack1,"Longitude") : stack1), depth0))
    + "\"\n  data-address=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"StreetNumber") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"StreetName") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"CityDetail") : stack1)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + ", "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"State") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"PostalCode") : stack1), depth0))
    + "\"\n  data-postalcode=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"PostalCode") : stack1), depth0))
    + "\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"Index") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":2},"end":{"line":17,"column":9}}})) != null ? stack1 : "")
    + "  data-url=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"Url") : depth0), depth0))
    + "\"\n  data-statuscode=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"StatusCode") : depth0), depth0))
    + "\"\n  data-officename=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Office") : depth0)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + "\"\n  data-agentphone=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"AgentPhone") : depth0), depth0))
    + "\"\n  data-gtm=\"listing-card\"\n  itemscope=\"\"\n  itemtype=\"http://schema.org/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"PropertyType") : depth0)) != null ? lookupProperty(stack1,"Schema") : stack1), depth0))
    + "\"\n>\n\n  <div class=\"bt-position--relative bt-listing-teaser__image-container\">\n    <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"Url") : depth0), depth0))
    + "\" class=\"bt-listing-teaser__link js-full-deets\">\n      <div\n        class=\"bt-cover__wrapper\"\n        style=\"background-image:url("
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Photos") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"LargeUrl") : stack1), depth0))
    + ")\"\n        role=\"img\"\n        alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"FormattedAddress") : stack1), depth0))
    + "\"\n      >\n        <button class=\"btn bt-listing-teaser__image-cta btn--primary btn--width-half\">View Details</button>\n      </div>\n\n    </a>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"hideListingCardFavoriteBtn") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":4},"end":{"line":46,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../assets/js/helpers/and.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"SashType") : depth0),(depth0 != null ? lookupProperty(depth0,"SashText") : depth0),{"name":"and","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":4},"end":{"line":56,"column":12}}})) != null ? stack1 : "")
    + "\n    <div class=\"listing-card__cover-overlay\">\n      <div class=\"grid grid--center mt-4\">\n        <div class=\"cell cell-xs-10\">\n          <div class=\"listing-card__price pb-4\" data-cy=\"listing-card__price\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"IsSold") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":62,"column":12},"end":{"line":70,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n        </div>\n\n        <div class=\"cell cell-xs-2\">\n          <span class=\"pill bg-black--transparent-50 bt-listing__slide-count-pill bt-listing__slide-count-pill--preview\">\n"
    + ((stack1 = __default(require("../../../assets/js/helpers/lengthGT.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"Photos") : depth0),1,{"name":"lengthGT","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":76,"column":12},"end":{"line":80,"column":25}}})) != null ? stack1 : "")
    + "          </span>\n        </div>\n\n      </div>\n    </div>\n\n  </div>\n\n  <div class=\"listing-card__info\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"ListingTopHtml") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":4},"end":{"line":97,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"bt-text--truncate mt-4\" itemtype=\"http://schema.org/PostalAddress\" itemscope itemprop=\"address\" title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"Neighborhood") : stack1)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + " | "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"CityDetail") : stack1)) != null ? lookupProperty(stack1,"Name") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"hideListingCardNeighborhood") : depth0),{"name":"unless","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":6},"end":{"line":104,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Location") : depth0)) != null ? lookupProperty(stack1,"CityDetail") : stack1)) != null ? lookupProperty(stack1,"Name") : stack1),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":6},"end":{"line":112,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"bt-listing-teaser__card-meta grid grid--gutters-small grid--gutters--reset-vertical"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"IsMapView") : depth0),{"name":"unless","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":99},"end":{"line":115,"column":136}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"NumUnits") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":6},"end":{"line":121,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"HideBedsBaths") : depth0),{"name":"unless","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":6},"end":{"line":143,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"ShowSQFT") : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":6},"end":{"line":155,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"NumUnits") : depth0),{"name":"unless","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":156,"column":6},"end":{"line":169,"column":17}}})) != null ? stack1 : "")
    + "\n    </div>\n    <div class=\"grid font-size--12\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"ShowDaysOld") : depth0),{"name":"if","hash":{},"fn":container.program(61, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":173,"column":6},"end":{"line":186,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"OnMarketDateFormatted") : depth0),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":188,"column":6},"end":{"line":195,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"FeatureText") : depth0),{"name":"if","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":198,"column":6},"end":{"line":211,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"ListingTopHtml") : depth0),{"name":"unless","hash":{},"fn":container.program(74, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":214,"column":2},"end":{"line":229,"column":13}}})) != null ? stack1 : "")
    + "</article>\n";
},"useData":true});
import { createReducer } from 'redux-act';
import { combineReducers } from 'redux';
import {
  startLocationRequest,
  receiveCurrentLocation,
  receiveError,
} from 'services/geolocation/actions';

import * as BallerBoxActions from './actions';

export default combineReducers({
  isFetchingLocation: createReducer(
    {
      [startLocationRequest]: () => true,
      [receiveCurrentLocation]: () => false,
      [receiveError]: () => false,

      [BallerBoxActions.selectNearbySearch]: () => true,
    },
    false
  ),
});

// localize selector state
const createSelector = selector => (state, ...args) => selector(state.ballerbox, ...args);

export const getIsFetchingLocation = createSelector(x => x.isFetchingLocation);

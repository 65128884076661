import UIkit from 'uikit';

const messageTemplate = (icon, text) => (
  `<div class="bt-cell-align bt-cell-align--middle bt-cell-align--small">
    <div class="pr-16">
      <i class="${icon}"></i>
    </div>
    <div>${text}</div>
  </div>
  `
);

/**
 * Show a notification at the bottom of the screen. Should be used for all (?)
 * desktop form submissions.
 *
 * @param {String} text
 * @param {'primary' | 'success'| 'warning' | 'danger'} type
 */
export function notify(text, type = 'danger') {
  let icon;
  if (type === 'danger') {
    icon = 'fas fa-exclamation-triangle';
    // eslint-disable-next-line
    // type = 'danger'; // For UIkit classes
  } else {
    icon = 'fas fa-check';
  }

  UIkit.notify({
    message: messageTemplate(icon, text),
    status: type,
    timeout: 3000,
    pos: 'bottom-center',
  });
}

export function primary(t) {
  notify(t, 'primary');
}

export function success(t) {
  notify(t, 'success');
}

export function warning(t) {
  notify(t, 'warning');
}

export function danger(t) {
  notify(t, 'danger');
}

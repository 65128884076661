import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { SEARCH_HEADER_HEIGHT } from 'constants/mobileScreen';
import { Grid, Cell } from 'components/core/Grid';

/**
 * Inner Header component used by the MobileScreenHeader component and various other components
 *
 * @export
 * @component Header
 */
export const Header = (props) => {
  const { className, children, dataCY } = props;

  const styles = {
    height: `${SEARCH_HEADER_HEIGHT}px`,
  };

  return (
    <nav
      className={cx('py-12 px-16--safe mobile-screen__header', className)}
      style={styles}
      data-cy={dataCY}
    >
      {children}
    </nav>
  );
};

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
  className: PropTypes.any,
  dataCY: PropTypes.string,
};

/**
 * Renders the view title for a Mobile Screen
 *
 * @export
 * @component Title
 */
export const Title = (props) => {
  const { children, className, dataCY } = props;

  return (
    <h1 className={cx('m-0 p-0 mobile-screen__title', className)} data-cy={dataCY}>
      {children}
    </h1>
  );
};

Title.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
  className: PropTypes.any,
  dataCY: PropTypes.string,
};

/**
 * A full Header for a Mobile Screen. Includes a left and right slot for various actions and a
 * centered view Title.
 *
 * @export
 * @component MobileScreenHeader
 */
export const MobileScreenHeader = (props) => {
  const { dataCY, leftSlot, rightSlot, title } = props;

  return (
    <Header>
      <Grid>
        <Cell xs={2}>{leftSlot}</Cell>
        <Cell xs={8}>
          <Title dataCY={dataCY}>{title}</Title>
        </Cell>
        <Cell xs={2}>{rightSlot}</Cell>
      </Grid>
    </Header>
  );
};

MobileScreenHeader.propTypes = {
  dataCY: PropTypes.string,
  leftSlot: PropTypes.element,
  rightSlot: PropTypes.element,
  title: PropTypes.string.isRequired,
};

MobileScreenHeader.defaultProps = {
  leftSlot: null,
  rightSlot: null,
};

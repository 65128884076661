import { createReducer } from 'redux-act';
import * as QualifyingQuestionsActions from 'actions/QualifyingQuestionsActions';

/**
 * I initially wanted to split this reducer into two: one for the qualifying
 * questions that the app should use, as well as the user's answers (possibly
 * normalized apart), and the state used to drive this modal. However, this
 * isn't currently possible because we want the view reducer to be able to
 * iterate over all loaded questions, and it doesn't have access to a sibling
 * reducer's state when joined with `combineReducers()`.
 *
 * Started to split the UI and Model-level handlers below into something
 * combined, where the next state of the model level is forwarded on to the UI,
 * but there are some incompatibilities with redux-act's `createReducer()` not
 * passing on additional arguments.
 */

const initialState = {
  // Model-level
  /** @type {Array<QualifyingQuestion>} */
  questions: [],

  // UI-level
  isModalOpen: false,
  currentQuestion: 0,
};

export default createReducer(
  {
    // Model-level
    [QualifyingQuestionsActions.QQActions.fetch.success]:
      /** @param {FlagshipAPI.GetQualifyingQuestionsResponse} payload */
      (state, payload) => ({
        ...state,
        questions: payload.map(convertApiQuestion),
      }),

    // UI-level
    [QualifyingQuestionsActions.dismissQualifyingQuestionsModal]: state => ({
      ...state,
      isModalOpen: initialState.isModalOpen,
    }),
    [QualifyingQuestionsActions.selectAnswer]: state => {
      // Assumes that the questions have been loaded
      const hasFinished = state.currentQuestion >= state.questions.length - 1;
      return {
        questions: state.questions,
        isModalOpen: hasFinished ? initialState.isModalOpen : state.isModalOpen,
        currentQuestion: hasFinished ? state.currentQuestion : state.currentQuestion + 1,
      };
    },
    [QualifyingQuestionsActions.openModal]: state => ({
      ...state,
      currentQuestion: 0,
      isModalOpen: true,
    }),
  },
  initialState
);

/**
 * Convert a QualifyingQuestion received from the API to type that we have in our store.
 *
 * @param {FlagshipAPI.QualifyingQuestion} apiQuestion
 * @returns {QualifyingQuestion}
 */
function convertApiQuestion(apiQuestion) {
  return {
    id: apiQuestion.QuestionId,
    question: apiQuestion.Question,
    choices: apiQuestion.Answers.map(({ Answer, AnswerId }) => ({
      id: AnswerId,
      text: Answer,
    })),
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { getTextPreferences } from 'models/visitor/reducers/selectors';
import { MobileScreen, CloseButton } from 'components/Common/MobileScreen';
// import { MOBILE_EMAIL_PREFS } from 'cypress_constants';
import { PrimaryButton } from 'coreComponents';
import { MOBILE_TEXT_PREFS } from 'cypress_constants';
import { getTextPrefsFormState, isSubmittingTextPrefs } from '../../Forms/EditTextPrefs/reducer';
import * as textActions from '../../Forms/EditTextPrefs/actions';
import ConnectedEditTextPrefs from '../../Forms/EditTextPrefs';

const MobileTextPreferences = ({ onClickClose, isSubmitting = false }) => (
  <MobileScreen
    title="Text Preferences"
    headerLeft={<CloseButton onClose={onClickClose} />}
    footer={
      <PrimaryButton
        type="submit"
        form="text-prefs__form"
        className="at-text-prefs-submit-btn"
        dataCY={MOBILE_TEXT_PREFS.UPDATE_TEXT_PREFERENCES}
        width="full"
        showSpinner={isSubmitting}
      >
        Update Text Preferences
      </PrimaryButton>
    }
  >
    <ConnectedEditTextPrefs isMobileScreen />
  </MobileScreen>
);

MobileTextPreferences.propTypes = {
  onClickClose: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default connect(
  state => {
    const textPrefState = getTextPrefsFormState(state);
    return ({
      textAllowed: Boolean(textPrefState) && textPrefState.textAllowed,
      isSubmitting: isSubmittingTextPrefs(state),
    });
  },
  { onClickClose: textActions.clickCloseTextPrefs }
)(MobileTextPreferences);

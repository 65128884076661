import { createAction as _createAction } from 'redux-act';

const createAction = (title, ...args) => _createAction(`Geolocation > ${title}`, ...args);

export const startLocationRequest = createAction('Start location request');
export const receiveCurrentLocation = createAction(
  'Received location information',
  /**
   * @function
   *
   * @param {{lat: number, lng: number}} coords
   * @param {boolean} [userAction] Optional param for use with the HSN App
   */
  (coords, userAction) => ({ ...coords, userAction })
);
export const cancelLocationRequest = createAction('Canceled location request');
export const receiveError = createAction('Received an error on location');

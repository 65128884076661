import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import FAIcon from 'components/core/FAIcon';
import { Dropdown, DropdownTrigger, DropdownContent } from 'components/Common/Dropdown';

/**
 * An accordion dropdown.
 *
 * @description A React implementation of the jQuery CComp component with
 * `data-bt-ccomp="toggle"`.
 * @param {{heading: string, children: ReactElement | ReactElement[]}} props
 */
export default function CCompToggle(props) {
  const { flushSides, heading, disabled, children, className, ...otherProps } = props;
  return (
    <Dropdown
      {...otherProps}
      className="bt-ccomp bt-ccomp__toggle"
      activeClass="bt-ccomp--active"
      disabled={disabled}
    >
      <DropdownTrigger>
        <div
          className={cx(
            'bt-filter__button bt-ccomp__trigger',
            {
              [`${className}-dd`]: className,
              'p-16': !flushSides,
              'py-16': flushSides
            }
          )}
        >
          {heading} {!disabled ? <FAIcon icon="angle-down" type="regular" /> : null}
        </div>
      </DropdownTrigger>
      <DropdownContent>
        <div className="bt-ccomp__content__inner">{children}</div>
      </DropdownContent>
    </Dropdown>
  );
}

CCompToggle.propTypes = {
  flushSides: PropTypes.bool,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string
};

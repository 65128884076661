import { select } from 'redux-saga/effects';
import { DESKTOP_MAP_PAGE_COUNT } from 'screens/ResultsMap/constants';
import { getMaxListingResults } from 'models/specialRules/reducers/selectors';

/**
 * Get the correct indexes and bool vals for pagination and handle any logic around the
 * MaxListingResults special rule.
 *
 * @param {PaginationState} [paginationState={}]
 * @param {array} newListings
 * @param {number} totalItems
 * @returns Object { paginationState: PaginationState, newListings: array }
 */
export default function* getPaginationState(paginationState = {}, newListings, totalItems) {
  // Check the MaxListingResults special rule
  const maxResults = yield select(getMaxListingResults);

  // Set our first and last index values for use with pagination
  paginationState.firstIndex = (DESKTOP_MAP_PAGE_COUNT * paginationState.pageIndex);
  paginationState.lastIndex = Math.min(DESKTOP_MAP_PAGE_COUNT * (paginationState.pageIndex + 1), totalItems);

  // If we have a maxResults value, check to see if we're past it
  if (maxResults) {
    paginationState.pastMaxResults = paginationState.lastIndex > maxResults;

    /**
     * Set our last index to the cap of possible results to display and only worry about beefing
     * up the remaining listings that will actually get displayed.
     */
    if (paginationState.pastMaxResults) {
      paginationState.lastIndex = maxResults;
      newListings = newListings.slice(0, paginationState.lastIndex - paginationState.firstIndex);
    }
  }

  paginationState.firstIndex += 1;
  paginationState.showPagination = !!(newListings.length < totalItems);

  return { paginationState, newListings };
}

import { createReducer } from 'redux-act';
import { DESKTOP_MAP_PAGE_COUNT } from 'screens/ResultsMap/constants';
import { addCommas } from 'utility';
import * as mapActions from 'screens/ResultsMap/Map/actions';

const initialState = {
  pageIndex: 0,
  firstIndex: 1,
  lastIndex: DESKTOP_MAP_PAGE_COUNT,
  showPagination: true,
  pastMaxResults: false,
  message: '',
};

export default createReducer(
  {
    [mapActions.receiveResults]: (
      state,
      {
        pageIndex,
        lastIndex,
        firstIndex,
        showPagination,
        pastMaxResults,
        totalItems,
      }
    ) => ({
      ...initialState,
      pageIndex,
      lastIndex,
      firstIndex,
      showPagination,
      pastMaxResults,
      message: `Viewing ${initialState.firstIndex} - ${lastIndex} of ${addCommas(totalItems)}`
    }),
    [mapActions.receiveNewResultsPage]: (
      state,
      {
        pageIndex,
        lastIndex,
        firstIndex,
        showPagination,
        pastMaxResults,
        totalItems,
      }
    ) => ({
      ...state,
      pageIndex,
      lastIndex,
      firstIndex,
      showPagination,
      pastMaxResults,
      message: `Viewing ${firstIndex} - ${lastIndex} of ${addCommas(totalItems)}`
    })
  },
  initialState
);

// Localize our selectors
const createSelector = selector => (state, ...args) =>
  selector(state.resultsMap.pagination, ...args);

export const getPageIndex = createSelector(state => state.pageIndex);
export const getFirstIndex = createSelector(state => state.firstIndex);
export const getLastIndex = createSelector(state => state.lastIndex);
export const getShowPagination = createSelector(state => state.showPagination);
export const getPastMaxResults = createSelector(state => state.pastMaxResults);
export const getPaginationMessage = createSelector(state => state.message);

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import FAIcon from 'components/core/FAIcon';

/**
 * A reusable CircleButton that accepts an svg icon. Useful for "close" and "back" buttons.
 *
 * @export
 * @component CircleButton
 */
export const CircleButton = (props) => {
  const { className, color, dataCY, iconComponent, ...rest } = props;

  const classes = cx('mobile-screen__circlebutton', className, {
    'mobile-screen__circle-button--primary': color === 'primary',
  });

  return (
    <a className={classes} rel="nofollow" data-cy={dataCY} {...rest}>
      {iconComponent}
    </a>
  );
};

CircleButton.propTypes = {
  className: PropTypes.any,
  color: PropTypes.oneOf(['white', 'primary']),
  dataCY: PropTypes.string,
  iconComponent: PropTypes.any,
};

CircleButton.defaultProps = {
  color: 'white',
};

/**
 * Reusable CloseButton component. Mainly used with the MobileScreen component, but is useful for
 * various other UIs.
 *
 * @export
 * @component CloseButton
 */
export const CloseButton = (props) => {
  const { className, dataCY, onClose, ...rest } = props;

  const icon = <FAIcon icon="times" type="solid" />;

  return (
    <CircleButton
      className={cx('at-close-btn', className)}
      iconComponent={icon}
      onClick={onClose}
      dataCY={dataCY}
      {...rest}
    />
  );
};

CloseButton.propTypes = {
  className: PropTypes.any,
  dataCY: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

CloseButton.defaultProps = {
  className: '',
};

/**
 * Reusable BackButton component. Mainly used with the MobileScreen component, but is useful for
 * various other UIs.
 *
 * @export
 * @component BackButton
 */
export const BackButton = (props) => {
  const { onClick, ...rest } = props;

  const icon = <FAIcon icon="angle-left" type="regular" />;

  return <CircleButton onClick={onClick} iconComponent={icon} {...rest} />;
};

BackButton.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

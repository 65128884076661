import BaseModalView from 'legacy/Base/view.modal';
import template from 'templates/modals/eula/base.hbs';

export default class EulaModalView extends BaseModalView {
  get id() {
    return 'eula';
  }

  get events() {
    return { 'click .js-eula-agree': 'agreed' };
  }

  initialize() {
    this.template = template;
    this.data = {};
    super.initialize();
  }

  agreed() {
    window.bt.utility.cookie('NorthstarEULA', 1, { expires: 365 });
  }
}

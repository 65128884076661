import BaseModel from 'legacy/Base/model';

export default class Account extends BaseModel {
  initialize() {
    super.initialize();

    if (typeof window.bt_data.account === 'undefined') {
      this.set({});
      return;
    }

    // Bootstrap data
    this.set({
      ...window.bt_data.account,
      CustomConversionScript: window.atob(window.bt_data.account.CustomConversionScript)
    });
    this.replaceGMapKey();
  }

  /**
   * Replace hardcoded gmapkey or null with an empty string
   */
  replaceGMapKey() {
    const gMapKey = this.get('GMapKey');
    if (gMapKey === null || gMapKey === 'gmapkey') {
      this.set('GMapKey', '');
    }
  }
}

import { LOCATION_CHANGED } from 'redux-little-router';
import { createReducer } from 'redux-act';
import * as a from 'actions/desktopSaveSearchActions';
import * as galleryActions from 'backbone/Views/Listings/gallery/galleryActions';
import * as noResultsActions from 'components/NoResults/actions';
import { clickSaveSearchInSearchBar } from 'components/SearchBar/actions';
import { saveSearchClick as tabletSaveSearchClick } from 'actions/OffCanvasActions';

const defaultState = {
  isSaveSearchModalOpen: false,
  isSubmitting: false,
  isSuccess: false,
};

function open(state, payload, { visitor }) {
  return { ...state, isSaveSearchModalOpen: visitor.isRegistered() };
}

function reset() {
  return { ...defaultState };
}

const handlers = {
  // Close it
  [LOCATION_CHANGED]: reset,
  [a.clickClose]: reset,
  [a.receiveCreateSavedSearchRes]: reset,

  // Open it
  [clickSaveSearchInSearchBar]: open,
  [galleryActions.clickSaveSearchCard]: open,
  [galleryActions.clickSaveSearchInNoResults]: open,
  [noResultsActions.clickSaveSearch]: open,

  // this is that weird inbetween media size
  [tabletSaveSearchClick]: open,

  // Bop it
  [a.clickSave]: state => ({
    ...state,
    isSubmitting: true,
  }),
};

export default createReducer(handlers, defaultState);

import PropTypes from 'prop-types';
import React from 'react';
import LazyLoad from 'react-lazy-load';
import cx from 'classnames';
import * as btPropTypes from 'btPropTypes';

import Card from 'components/Card';
import Loader from 'components/Common/Loader';
import NoResults from 'components/NoResults';
import Slider from 'components/Common/Slider';
import FAIcon from 'components/core/FAIcon';
import { Mobile, Tablet, Laptop } from 'components/core/BreakPoints';

/**
 *
 * - The skeleton minHeight is the height of the cards on mobile
 * - The slider doesn't like rebinding when it has new images, so try
 *   to only bind up once.
 * - We want to re-use the same LazyLoad component while if we are hidingWhileLoading
 *   so that we don't retrigger the onContentVisible.
 */
export default function WidgetListings(props) {
  const {
    title = '',
    searchURL = '/results-gallery/',
    listings = [],
    isLoading = false,
    onContentVisible = () => {},
    display = 'slider',
    hideWhileLoading = false,
  } = props;

  // Safe-guard against bad inputs
  const isDisplayGrid = display !== 'slider';

  const skeletonHeight = hideWhileLoading ? 0 : 268;

  return (
    <div className="bt-widget bt-featured-listings at-featured-listings">
      <div>
        {(!isLoading || !hideWhileLoading) && <Header title={title} searchURL={searchURL} />}

        <div style={{ minHeight: skeletonHeight }}>
          <LazyLoad offsetVertical={200} onContentVisible={onContentVisible}>
            {isLoading ? (
              <span />
            ) : (
              <WidgetContent
                listings={listings}
                isDisplayGrid={isDisplayGrid}
              />
            )}
          </LazyLoad>
          {isLoading && !hideWhileLoading && <Loader />}
        </div>
      </div>
    </div>
  );
}
WidgetListings.propTypes = {
  title: PropTypes.string.isRequired,
  searchURL: PropTypes.string,
  listings: PropTypes.arrayOf(btPropTypes.listing),
  isLoading: PropTypes.bool,
  onContentVisible: PropTypes.func,
  display: PropTypes.oneOf(['slider', 'grid']),
  hideWhileLoading: PropTypes.bool,
};

WidgetListings.defaultProps = {
  searchURL: '',
  listings: [],
  isLoading: false,
  onContentVisible: () => {},
  display: null,
  hideWhileLoading: false,
};

function Header(props) {
  const { title, searchURL } = props;

  return (
    <div className="grid grid--center mb-16">
      <div className="cell cell-xs-7">
        {title && (
          <h3 className="bt-widget__title m-0">{title}</h3>
        )}
      </div>
      <div className="cell uk-text-right">
        <a role="button" href={searchURL} className="btn btn--secondary at-more-bounce btn--size-small--mobile">
          See More
          <FAIcon icon="angle-right" type="regular" />
        </a>
      </div>
    </div>
  );
}
Header.propTypes = {
  title: PropTypes.string,
  searchURL: PropTypes.string,
};

Header.defaultProps = {
  title: '',
  searchURL: '',
};

function WidgetContent(props) {
  const { isDisplayGrid, listings } = props;

  if (listings.length === 0) {
    return <NoResults isSaveSearchVisible={false} />;
  }

  return isDisplayGrid ? <CardGrid listings={listings} /> : <CardSlider listings={listings} />;
}
WidgetContent.propTypes = {
  listings: PropTypes.arrayOf(btPropTypes.listing),
  isDisplayGrid: PropTypes.bool,
};

WidgetContent.defaultProps = {
  listings: null,
  isDisplayGrid: false,
};

/**
 * A slider for displaying cards
 * - We expect a container around this
 */
function CardSlider(props) {
  const { listings } = props;

  // The number of columns determines if we hide/show the prev/next buttons
  function options({ cols = 1 }) {
    const draggable = listings.length > cols;
    return {
      cellAlign: 'left',
      contain: true,
      pageDots: false,
      imagesLoaded: true,
      draggable,
      prevNextButtons: draggable,
    };
  }

  const sliderClassNames = [
    'bt-card-slider',
    'bt-card-slider__prev-next--below',
    'bt-listing-teaser--vertical-wrapper',
  ];

  const slides = listings.map((l) => (
    <div className="bt-card-wrapper" key={l._ID}>
      <Card listing={l} />
    </div>
  ));

  return (
    <div>
      <Mobile smaller>
        <Slider
          classes={sliderClassNames}
          el="div"
          options={options({ cols: 1 })}
        >
          {slides}
        </Slider>
      </Mobile>
      <Mobile range>
        <Slider
          classes={sliderClassNames}
          el="div"
          options={options({ cols: 3 })}
        >
          {slides}
        </Slider>
      </Mobile>
      <Tablet range>
        <Slider
          classes={sliderClassNames}
          el="div"
          options={options({ cols: 3 })}
        >
          {slides}
        </Slider>
      </Tablet>
      <Laptop larger>
        <Slider
          classes={sliderClassNames}
          el="div"
          options={options({ cols: 4 })}
        >
          {slides}
        </Slider>
      </Laptop>
    </div>
  );
}
CardSlider.propTypes = {
  listings: PropTypes.arrayOf(btPropTypes.listing),
};

CardSlider.defaultProps = {
  listings: [],
};

/**
 * A grid of cards, sized for when there is a sidebar on the page
 * - used on /agents/:id
 * - expects to be wrapped in a container at some point
 */
function CardGrid(props) {
  const { listings = [] } = props;

  // We typically have a sidebar on the page, so let's design for that
  const wrapperClassnames = cx(
    'grid',
    'grid--gutters-small',
    'grid--flexCells',
    'grid-xs--full grid-sm--halves grid-md--thirds',
  );

  return (
    <div className={wrapperClassnames}>
      {listings.map((l) => (
        <div key={l._ID} className="cell">
          <Card listing={l} />
        </div>
      ))}
    </div>
  );
}
CardGrid.propTypes = {
  listings: PropTypes.arrayOf(btPropTypes.listing),
};

CardGrid.defaultProps = {
  listings: [],
};

/* eslint-disable no-constant-condition, import/prefer-default-export */
import { eventChannel, END } from 'redux-saga';
import { take, call, spawn, put, cancelled } from 'redux-saga/effects';
import * as a from './actions';

/** @type {Task?} */
let locationTask;

/**
 * Create a channel where geolocation position messages can be passed to
 */
const createPositionChannel = () =>
  eventChannel(emit => {
    const id = window.navigator.geolocation.watchPosition(
      position => {
        const { latitude: lat, longitude: lng } = position.coords;
        emit({ coords: { lat, lng } });
      },
      error => {
        emit({ error });
        emit(END);
      }
    );

    // onClose
    return () => {
      window.navigator.geolocation.clearWatch(id);
    };
  });

/**
 * Open a channel communicating up to date geolocation data, and map those
 * updates to actions.
 */
function* _beginWatchingLocation() {
  // Let redux know we are starting monitoring geolocation
  yield put(a.startLocationRequest());

  const channel = yield call(createPositionChannel);
  try {
    // Keep reading from the channel
    while (true) {
      const message = yield take(channel);
      if (message.error) {
        yield put(a.receiveError(message.error));
        return;
      }

      // Stash the new coords in redux
      yield put(a.receiveCurrentLocation(message.coords));
    }
  } finally {
    // If the channel has stopped we want to give it a chance to cleanup
    if (yield cancelled()) {
      channel.close();
    }
  }
}

/**
 * Begin watching the user's location. We only want to keep one task up at a time
 *
 * @param {Boolean} watchLocation Whether or not the watcher should start (or persist) or be cancelled
 */
export function* beginWatchingLocation(watchLocation = true) {
  if (watchLocation && (!locationTask || !locationTask.isRunning())) {
    locationTask = yield spawn(_beginWatchingLocation);
  } else if (!watchLocation) {
    locationTask.cancel();
  }
}

/* eslint-disable no-confusing-arrow */
import { config, rules as rulesCollection } from 'BoomTown';
import { SALE_TYPES, NEGATED_SALE_TYPES } from 'models/search/constants';
import { TYPE_OPTIONS } from 'cypress_constants';

/**
 * A set of objects that define some behavior of our checkboxes. This is a
 * little OO, and there's probably duplication for both of these concepts, but
 * it's at least correct.
 *
 * Note that order in this object is what dictates order in the UI
 *
 * @todo Figure out how best to refactor these things into Redux.
 * @todo Add IDs as state of some sort and utilize it in the isSelected methods
 * @type {SaleTypeCheckboxesModel}
 */

const checkboxObjects = {
  standard: {
    allowForSearch: () => true,
    defaultValue: true,
    getLabel: () => 'Standard Sale',
    id: SALE_TYPES.STANDARD,
    isSelected: ({ status, featureor }) =>
      status.includes(SALE_TYPES.ACTIVE) && // array difference
      !featureor.some((x) => [SALE_TYPES.FORECLOSURE, SALE_TYPES.SHORT_SALE].includes(x)),
  },
  foreclosure: {
    allowForSearch: (rules) => !rules.HideForeclosure,
    defaultValue: true,
    getLabel: () => 'Foreclosure',
    id: SALE_TYPES.FORECLOSURE,
    isSelected: ({ status, featureor, featurenot }) =>
      status.includes(SALE_TYPES.ACTIVE) &&
      !featurenot.includes(NEGATED_SALE_TYPES.FORECLOSURE) &&
      (featureor.length === 0 || featureor.includes(SALE_TYPES.FORECLOSURE)),
  },
  shortSale: {
    allowForSearch: (rules) => !rules.HideShortsale,
    defaultValue: true,
    getLabel: () => 'Short Sale',
    id: SALE_TYPES.SHORT_SALE,
    isSelected: ({ status, featureor, featurenot }) =>
      status.includes(SALE_TYPES.ACTIVE) &&
      !featurenot.includes(NEGATED_SALE_TYPES.SHORT_SALE) &&
      (featureor.length === 0 || featureor.includes(SALE_TYPES.SHORT_SALE)),
  },
  comingSoon: {
    allowForSearch: () => Boolean(SALE_TYPES.COMING_SOON),
    dataCY: TYPE_OPTIONS.COMING_SOON,
    defaultValue: false,
    getLabel: () => 'Coming Soon',
    id: SALE_TYPES.COMING_SOON,
    isSelected: ({ status }) => status.includes(SALE_TYPES.COMING_SOON),
    labelProps: {
      'data-cy': TYPE_OPTIONS.COMING_SOON_LABEL,
    },
  },
  underContract: {
    allowForSearch: (rules) => rules.ShowActiveContingent,
    defaultValue: false,
    // The `ReplaceUnderContract` rule is an optional string, which can contain the replacement label.
    getLabel: (rules) =>
      rules.ReplaceUnderContract ? rules.ReplaceUnderContract : 'Under Contract',
    id: SALE_TYPES.UNDER_CONTRACT,
    isSelected: ({ status }) => status.includes(SALE_TYPES.UNDER_CONTRACT),
  },
  sold: {
    allowForSearch: (rules, allowSoldData) => allowSoldData,
    dataCY: TYPE_OPTIONS.SOLD,
    defaultValue: false,
    getLabel: () => 'Sold',
    id: SALE_TYPES.SOLD,
    isSelected: ({ status }) => status.includes(SALE_TYPES.SOLD),
    labelProps: {
      'data-cy': TYPE_OPTIONS.SOLD_LABEL,
    },
  },
  pending: {
    allowForSearch: (rules, allowSoldData) => allowSoldData,
    dataCY: TYPE_OPTIONS.PENDING,
    defaultValue: false,
    getLabel: () => 'Pending',
    id: SALE_TYPES.PENDING,
    isSelected: ({ status }) => status.includes(SALE_TYPES.PENDING),
    labelProps: {
      'data-cy': TYPE_OPTIONS.PENDING_LABEL,
    },
  },
};

/**
 * Map our search state (really a subset of, but could take a search object
 * since it's a supertype) to the state of our "sale type" checkboxes
 *
 * @param {SaleTypeState} obj
 * @returns {SaleTypeCheckboxes}
 */
function searchToCheckboxState(obj) {
  return Object.keys(checkboxObjects)
    .map((key) => ({ key, value: checkboxObjects[key] }))
    .filter(({ value }) => value.allowForSearch(rulesCollection.toJSON(), config.allowSoldData))
    .reduce(
      (prev, { key, value }) => ({
        ...prev,
        [key]: value.isSelected(obj),
      }),
      {}
    );
}

/**
 * A selector that returns an array of sale type checkbox objects that drives our UI.
 *
 * @todo This will be very similar to `searchToCheckboxState()` above, so
 * consider deriving them from some common concept... or not.
 */
export function getSaleTypeCheckboxes(search, rules, allowSoldData) {
  return Object.keys(checkboxObjects)
    .map((key) => checkboxObjects[key])
    .filter((x) => x.allowForSearch(rules, allowSoldData))
    .map((x) => ({
      id: x.id,
      dataCY: x.dataCY,
      label: x.getLabel(rules),
      checked: x.isSelected(search),
      labelProps: x.labelProps,
    }));
}

/**
 * Get a next search state given a new set of checkbox values. Previous
 * `SaleTypeState` is passed in here so that IDs not covered by this logic will
 * be preserved.
 *
 * @param {SaleTypeState} state
 * @param {SaleTypeCheckboxes} checkboxes Dynamic, so that it reflects what the UI shows
 * @returns {SaleTypeState}
 */
function checkboxToSearchState(state, checkboxes) {
  // CNS-6998: Add handling for CS as we would for A
  const getActiveStatus = () => {
    const activeStatuses = [SALE_TYPES.ACTIVE];
    if ([checkboxes.standard, checkboxes.foreclosure, checkboxes.shortSale].some((x) => x)) {
      return activeStatuses;
    }
    return [];
  };

  // Coming Soon Next Status Conditional
  const comingSoonArray = [];
  if (SALE_TYPES.COMING_SOON) {
    comingSoonArray.push(checkboxes.comingSoon ? SALE_TYPES.COMING_SOON : null);
  }

  // Figure out what sale types we are actually working with
  const nextStatusArray = [
    ...getActiveStatus(),
    ...comingSoonArray,
    checkboxes.underContract ? SALE_TYPES.UNDER_CONTRACT : null,
    checkboxes.pending ? SALE_TYPES.PENDING : null,
    checkboxes.sold ? SALE_TYPES.SOLD : null,
  ].filter((x) => x !== null);

  // Determine next featureor/featurenot arrays (within domain of our whitelist)
  let nextFeatureNotArray = [];
  let nextFeatureOrArray = [];

  if (checkboxes.standard) {
    nextFeatureNotArray = [
      // id, isSelected, name
      [NEGATED_SALE_TYPES.FORECLOSURE, checkboxes.foreclosure, 'foreclosure'],
      [NEGATED_SALE_TYPES.SHORT_SALE, checkboxes.shortSale, 'shortSale'],
    ]
      // Filter based on our configuration rules
      .filter(([, , checkboxName]) =>
        checkboxObjects[checkboxName].allowForSearch(rulesCollection.toJSON(), config.allowSoldData)
      )
      .filter(([, isSelected]) => !isSelected)
      .map(([id]) => id);
  } else {
    nextFeatureOrArray = [
      // id, isSelected, name
      [SALE_TYPES.FORECLOSURE, checkboxes.foreclosure, 'foreclosure'],
      [SALE_TYPES.SHORT_SALE, checkboxes.shortSale, 'shortSale'],
    ]
      // Filter based on our configuration rules
      .filter(([, , checkboxName]) =>
        checkboxObjects[checkboxName].allowForSearch(rulesCollection.toJSON(), config.allowSoldData)
      )
      .filter(([, isSelected]) => isSelected)
      .map(([id]) => id);
  }

  const saleTypeArray = [
    SALE_TYPES.ACTIVE,
    SALE_TYPES.UNDER_CONTRACT,
    SALE_TYPES.SOLD,
    SALE_TYPES.PENDING,
  ];

  if (SALE_TYPES.COMING_SOON) {
    saleTypeArray.push(SALE_TYPES.COMING_SOON);
  }

  return {
    status: [...state.status.filter((x) => !saleTypeArray.includes(x)), ...nextStatusArray],
    featureor: [
      ...state.featureor.filter(
        (x) => ![SALE_TYPES.FORECLOSURE, SALE_TYPES.SHORT_SALE].includes(x)
      ),
      ...nextFeatureOrArray,
    ],
    featurenot: [
      ...state.featurenot.filter(
        (x) => ![NEGATED_SALE_TYPES.FORECLOSURE, NEGATED_SALE_TYPES.SHORT_SALE].includes(x)
      ),
      ...nextFeatureNotArray,
    ],
  };
}

/**
 * "Toggle" one of our three sale type and sold checkboxes, ensuring that at
 * least one of the checkboxes is selected. (This is the actual reducer,
 * everything else is really just a mapping.)
 *
 * @param {SaleTypeCheckboxes} prevCheckboxState
 * @param {SaleTypeID} key A string representing one of our sale types,
 *   either 'FS', 'BO', 'SS', 'S' for sold or 'P' for pending
 * @return {SaleTypeCheckboxes} The new `saleTypeCheckboxes` state
 */
function saleTypeCheckboxReducer(prevCheckboxState, key) {
  const checkboxKey = Object.keys(checkboxObjects).find((name) => checkboxObjects[name].id === key);

  if (checkboxKey === undefined) {
    throw new Error(`Unexpected sale type ID: ${key}`);
  }

  // If we were to naively toggle the state
  const afterToggle = {
    ...prevCheckboxState,
    [checkboxKey]: !prevCheckboxState[checkboxKey],
  };

  // If nothing is toggled on
  if (Object.keys(afterToggle).filter((x) => afterToggle[x]).length === 0) {
    // We need to reset the checkboxes to their default state
    return Object.keys(checkboxObjects)
      .map((checkboxName) => ({
        checkboxName,
        allowForSearch: checkboxObjects[checkboxName].allowForSearch,
        defaultValue: checkboxObjects[checkboxName].defaultValue,
      }))
      .filter(({ allowForSearch }) =>
        allowForSearch(rulesCollection.toJSON(), config.allowSoldData)
      )
      .reduce(
        (acc, { checkboxName, defaultValue }) => ({
          ...acc,
          [checkboxName]: defaultValue,
        }),
        {}
      );
  }

  return afterToggle;
}

/**
 * The state transition for a sale type checkbox being clicked, defined in the
 * domain of our search model. Simply maps to the `SaleTypeCheckboxes`
 * interface, does the transition there, and then maps back.
 *
 * This is one of those places where a reducer depends on the current state of
 * a different part of our state tree, it's just not obvious since
 * `searchToCheckboxState()` is impure.
 *
 * @param {SaleTypeState} state
 * @param {SaleTypeID} id
 * @returns {SaleTypeState}
 */
export function saleTypeReducer(state, id) {
  // This returns a subset of the checkbox based on permissions (exactly what
  // the UI should display). `checkboxToSearchState()` expects this and will do
  // the right thing.
  const nextStateAsCheckboxes = saleTypeCheckboxReducer(
    searchToCheckboxState({
      featureor: state.featureor,
      featurenot: state.featurenot,
      status: state.status,
    }),
    id
  );

  return {
    ...state,
    ...checkboxToSearchState(state, nextStateAsCheckboxes),
  };
}

/**
 * Given an object with values that are comma-delimited strings, pluck off
 * those spec'd in the second arg, and return a new obj with only those keys
 * converted to arrays of IDs.
 *
 * @param {Object} obj
 * @param {string[]} arrayKeys Keys of the object that will be converted to arrays
 */
export const valuesToArrays = (obj = {}, arrayKeys) =>
  arrayKeys.reduce((acc, key) => {
    const value = typeof obj[key] !== 'string' ? [] : obj[key].split(',').map((x) => x.trim());
    return { ...acc, [key]: value };
  }, {});

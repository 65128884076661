import React from 'react';
import { Tablet } from 'components/core/BreakPoints';
import IsMobile from 'components/IsMobile';
import CurrentResultHeader from './Current';

export default function Header() {
  return (
    <Tablet smaller>
      <IsMobile>
        {isMobile => (isMobile ? null : <CurrentResultHeader />)}
      </IsMobile>
    </Tablet>
  );
}

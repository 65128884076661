import { visitor as visitorModel } from 'BoomTown';
import { delay } from 'redux-saga';
import { takeLatest, take, race, put, call } from 'redux-saga/effects';
import {
  submitEditAccountForm,
  receiveEditAccountSuccess,
  receiveEditAccountFailure,
  closeEditAccountSuccessModal,
  autoCloseEditAccountSuccessModal,
} from './actions';

export default [
  // Edit Account Info
  takeLatest(submitEditAccountForm, handleSubmitEditAccountForm),
];

function* handleSubmitEditAccountForm({ payload }) {
  const fields = payload;
  try {
    const visitor = yield call([visitorModel, visitorModel.updateContact], fields);
    // We display a different a different success text if the phone number has
    // been updated
    const isPhoneUpdated = visitorModel.get('BestPhone') !== fields.BestPhone;
    visitorModel.set(visitor);
    yield put(receiveEditAccountSuccess({ isPhoneUpdated }));
    yield race([
      call(dismissAfterTimeout),
      take(closeEditAccountSuccessModal)
    ]);
  } catch (e) {
    yield put(receiveEditAccountFailure());
  }
}

function* dismissAfterTimeout() {
  yield call(delay, 3000);
  yield put(autoCloseEditAccountSuccessModal());
}

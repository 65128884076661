import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import scrollIntoView from 'dom-scroll-into-view';

import ResultsManager from './ResultsManager';

/**
 * Autocomplete Results
 * formats data and displays returns the Results Manager
 * @return {ReactElement}
 */
const AutocompleteResults = (props) => {
  const { activeIndex, renderHelpText, ...rest } = props;

  const menuRef = useRef(null);
  const itemRef = useRef(null);

  React.useEffect(() => {
    if (activeIndex !== null && itemRef.current != null) {
      const itemRect = itemRef.current.getBoundingClientRect();
      const menuRect = menuRef.current.getBoundingClientRect();

      if (menuRect.top <= itemRect.bottom && menuRect.bottom >= itemRect.top) {
        scrollItemIntoView();
      }
    }
  }, [activeIndex]);

  const scrollItemIntoView = () => {
    scrollIntoView(findDOMNode(itemRef.current), findDOMNode(itemRef.current), {
      onlyScrollIfNeeded: true,
    });
  };

  return (
    <div className="ballerbox__autocomplete-results" ref={menuRef}>
      <ul className="uk-nav nav-autocomplete">
        {!renderHelpText ? (
          <ResultsManager activeIndex={activeIndex} ref={itemRef} {...rest} />
        ) : (
          <li className="px-16 pb-12 pt-0">Begin typing and your results will appear below</li>
        )}
      </ul>
    </div>
  );
};

AutocompleteResults.propTypes = {
  activeIndex: PropTypes.number,
  renderHelpText: PropTypes.bool,
};

export default AutocompleteResults;

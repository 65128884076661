/* eslint-disable import/prefer-default-export */

/** @type {google.maps.MapTypeId} */
export const MAP_TYPES = {
  ROADMAP: 'roadmap',
  SATELLITE: 'satellite',
};

export const MOBILE_MAP_PAGE_COUNT = 50;
export const DESKTOP_MAP_PAGE_COUNT = 80;

/**
 * Height is calculated based on the height set in the CSS class `.results-map__pagination for heights`
 * plus 15 extra pixels for padding
 * @see /scss/flagship/pages/_results-map.scss@.results-map__pagination for heights
 */
export const MAP_PAGINATION_HEIGHT_1COL = 120;
export const MAP_PAGINATION_HEIGHT_2COL = 90;

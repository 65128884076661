import { omit, pick, isEmpty, isEqual } from 'underscore';
import { createReducer } from 'redux-act';
import { config, utility } from 'BoomTown';
import { getPrice } from 'selectors/listings';
import { multiCase } from 'reducers/util';

// Actions
import { initializeState } from 'actions/bootstrapActions';
import * as HomepageBallerBoxActions from 'components/HomepageSearch/Consumer/actions';
import { selectSavedSearch } from 'screens/Account/Forms/EditSavedSearches/actions';
import { applyPendingSearch, applySavedSearch } from 'consumerApp/views/Results/actions';
import {
  receiveResults,
  receiveNewResultsPage,
  onMapBoundsChanged,
  onMapZoom,
  onMapIdle,
  receivePinsForStore,
  receiveMapParamsFromUrl,
} from './actions';

export const initialState = {
  listings: [],
  pins: [],

  /** The "address" of the results stored in `listings` */
  search: {},

  /** @type {MapBounds} */
  mapBounds: null,

  /** @type {Number?} */
  zoom: config && config.defaultZoom ? config.defaultZoom : null,

  totalItems: 0,
  totalItemsWithoutCoords: 0,
  shouldPanToMapBounds: false
};

export const mapReducer = {
  [initializeState]: (state, { lastMapBounds, lastMapZoom }) => ({
    ...state,
    mapBounds: lastMapBounds || state.mapBounds,
    zoom: lastMapZoom || state.zoom,
  }),
  [receiveResults]: (
    state,
    {
      listings,
      search,
      totalItems,
      totalItemsWithoutCoords,
    }
  ) => (
    {
      ...state,
      listings,
      search,
      totalItems,
      totalItemsWithoutCoords,
    }
  ),

  [receiveNewResultsPage]: (state, { listings }) => ({
    ...state,
    listings,
  }),

  [onMapBoundsChanged]: (state, mapBounds) => ({
    ...state,
    mapBounds,
  }),

  [onMapZoom]: (state, zoom) => ({
    ...state,
    zoom,
  }),

  [onMapIdle]: state => ({
    ...state,
    shouldPanToMapBounds: false
  }),

  [receivePinsForStore]: (state, pins) => ({
    ...state,
    pins,
  }),

  [receiveMapParamsFromUrl]: (state, { zoom, ...bounds }) => ({
    ...state,
    mapBounds: {
      ...bounds
    },
    zoom,
  }),

  // Clear mapBounds and zoom when a Saved Search is selected from the
  // Edit Saved Searches view. We don't want the mapBounds being applied to a saved search
  // that either doesn't have map bounds or has its own map bounds included.
  [selectSavedSearch]: (state, { mapBounds, mapZoom }) => ({
    ...state,
    // `mapBounds` will either be an object or null, so the 2nd half of this conditional isn't
    // really necessary, but I'm leaving it as an extra guard.
    mapBounds: mapBounds || initialState.mapBounds,
    zoom: mapZoom || initialState.zoom,
  }),

  /**
   * Used by HomeSearchNOW App.
   *
   * Can be removed once the App no longer relies on webviews.
   */
  ...multiCase(
    [
      // This action is dispatched from the MessageListener for the `UPDATE_CURRENT_SEARCH` AppMessage
      // and applies any mapBounds/zoom sent from the app.We also needed logic in place to determine
      // if/when to pan to those MapBounds and run the `panTo` and `setZoom` functions.
      applyPendingSearch,

      // Sent by the `APPLY_SAVED_SEARCH` message, if the saved search does not have mapbounds or
      // zoom, just set them to their default values.
      applySavedSearch
    ],
    (state, { mapBounds }) => {
      if (!config.useListMapResultsSync) {
        return state;
      }

      return {
        ...state,
        // TODO: Possibly move this into the case reducer for LOCATION_CHANGED so everything stays
        // synced
        shouldPanToMapBounds: !isEqual(state.mapBounds, mapBounds),
      };
    }
  ),

  /**
   * Homepage Search
   */
  ...multiCase(
    [HomepageBallerBoxActions.emptySubmitClick, HomepageBallerBoxActions.selectSuggestion],
    (state) => ({
      ...state,
      mapBounds: initialState.mapBounds,
      zoom: initialState.zoom,
    })
  ),
};

// Export the fully `map` reducer (found in `resultsMap.map` state)
export default createReducer(mapReducer, initialState);

const getPinText = listing => {
  const price = getPrice(listing);
  if (price === undefined) {
    return '';
  }

  const n = typeof price === 'number' ? price : price.max;
  return `$${utility.shortenDollarAmount(n, { thousandsPlaces: 0, millionsPlaces: 2 })}`;
};

/**
 * Map a ListingSnapshot to a pin
 *
 * @param {FlagshipAPI.ListingSnapshot} l
 * @returns {{ id: number, lat: number, lng: number, text: string, sashType?: string, isFavorite: boolean }}
 */
export const mapListingToPin = (l, favoriteIDs) => {
  const {
    _ID,
    Location: {
      Coordinates: { Latitude, Longitude },
    },
    SashType,
    UrlPath,
  } = l;

  return {
    id: _ID, // Not req'd for display, but used as a React child `key`, since some listings have identical coordinates
    lat: Latitude,
    lng: Longitude,
    text: getPinText(l),
    sashType: SashType === '' ? undefined : SashType,
    isFavorite: favoriteIDs ? favoriteIDs.includes(_ID) : false,
    urlPath: UrlPath,
  };
};


export const _getIsSearchWithoutCoordsTheSame = (localState, search, coordinateFilters) => {
  const searchWithoutCoords = omit(localState.search, coordinateFilters);

  return isEqual(search, searchWithoutCoords);
};

/**
 * Selectors
 */

// Localize our selectors
const createSelector = selector => (state, ...args) => selector(state.resultsMap.map, ...args);

export const getIsSearchWithoutCoordsTheSame = createSelector(_getIsSearchWithoutCoordsTheSame);

export const _getMapBounds = state => state.mapBounds;
export const getMapBounds = createSelector(_getMapBounds);

export const _getTotalItemsWithoutCoords = state => state.totalItemsWithoutCoords;
export const getTotalItemsWithoutCoords = createSelector(_getTotalItemsWithoutCoords);

export const _getTotalItems = state => state.totalItems;

export const _getShouldPanToMapBounds = state => state.shouldPanToMapBounds;
export const getShouldPanToMapBounds = createSelector(_getShouldPanToMapBounds);
/**
 * Returns the total number of listings that result from the current search
 *
 * @param {Object} state The localized state `state.resultsMap.map`
 * @returns {Number}
 */
export const getTotalItems = createSelector(_getTotalItems);

export const getPins = createSelector((state, favoriteIDs) =>
  state.listings.map(l => mapListingToPin(l, favoriteIDs))
);

/**
 * @function getLocationSearch
 *
 * @todo Switch to returning a boolean instead of an object. We don't use the object returned aside
 *       from re-checking to see if the obj is empty...to give us a boolean.
 */
export const getLocationSearch = createSelector((localState, locationParams) => {
  const locationSearch = pick(localState.search, locationParams);
  return localState.listings.length && !isEmpty(locationSearch) ? locationSearch : {};
});

export const getZoom = createSelector(state => state.zoom);

export const getListingsFromMap = createSelector(state => state.listings);

export const getLocalSearch = createSelector(state => state.search);

export const getSearchAndBounds = createSelector(({ mapBounds, search }) => ({ mapBounds, search }));

export const getMapBoundsAndZoom = createSelector(({ mapBounds, zoom }) => ({ mapBounds, zoom }));

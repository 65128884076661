/* eslint-disable import/prefer-default-export, no-confusing-arrow */

/**
 * Intermediary layer between backbone and what we are expecting redux to contain
 * @deprecated
 * @param {Backbone.Model} visitorModel
 */
export function getVisitorData(model) {
  const isRegistered = model.isRegistered();
  return {
    bestPhone: model.get('BestPhone'),
    firstName: model.get('FirstName'),
    fullName: isRegistered ? model.fullName() : '',
    id: model.get('_ID'),
    isRegistered,
    lastName: model.get('LastName'),
    username: model.get('Username'), // usually email
    hasGravatar: model.get('hasGravatar'),
    address: model.get('Address'),
    city: model.get('City'),
    state: model.get('State'),
    zipcode: model.get('ZipCode'),

    favoriteCount: parseInt(model.get('FavoriteCount'), 10),
  };
}

/**
 * Selects the `IsRegistered` value from the visitor model in our redux store.
 *
 * @param {{ visitor: { IsRegistered: boolean } }} state
 * @returns {boolean}
 */
export const getIsRegistered = (state) => state.visitor.IsRegistered;

export const getVisitorAccountInfo = state => state.visitor;

export const getEmail = state => getVisitorAccountInfo(state).Username || '';

export const getVisitorID = (state) => state.visitor._ID;

/**
 * Get the visitor's email preferences, providing a derived `optOutAgent`
 * property.
 *
 * @param {Object} state Root state
 * @returns {EmailPreferences | undefined}
 */
export const emailPreferences = state => {
  if (!state.visitor.emailPreferences) {
    return undefined;
  }
  const prefs = state.visitor.emailPreferences;
  const { optOutBuyerAgent, optOutListingAgent } = prefs;
  return {
    ...prefs,
    optOutAgent: optOutBuyerAgent || optOutListingAgent,
  };
};

/**
 * Get the visitors text preferences from the communicationPreferences object
 *
 * @param {Object} state
 * @returns {{ textAllowed: boolean }|undefined}
 */
export const getTextPreferences = state => {
  const communicationPrefs = state.visitor.communicationPreferences;
  if (!communicationPrefs) {
    return undefined;
  }
  return communicationPrefs.textPreferences;
};

export const getSavedSearches = state => {
  if (!state.visitor.savedSearches) {
    return undefined;
  }
  return state.visitor.savedSearches;
};

export const getSavedSearch = (state, searchid) => {
  const savedSearch = state.visitor.savedSearches.find(x => x.searchid === searchid);
  if (!savedSearch) {
    return undefined;
  }
  return savedSearch;
};

/**
 * Gets the number of saved searches that the visitor has. Prefers the length
 * of the Redux saved search collection, but will consult the initial value on
 * the Lead that the API gives us as a backup.
 */
export const getSavedSearchCount = state => {
  const searches = getSavedSearches(state);
  if (searches !== undefined) {
    return searches.length;
  }

  return state.visitor.ActiveSearchCount;
};

/**
 * Returns the array of favorited listing IDs associated with the visitor.
 *
 * @param {object} state
 * @returns {number[]}
 */
export const getFavoriteIDs = state => state.visitor.Favorites;

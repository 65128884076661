/* eslint-disable quote-props */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const HEADING_1 = 1;
const HEADING_2 = 2;
const HEADING_3 = 3;
const HEADING_4 = 4;
const HEADING_5 = 5;
const HEADING_6 = 6;

const Heading = (props) => {
  const {
    children,
    className,
    dataCY,
    style,
    type,
  } = props;

  const classes = cx([
    {
      'bt-heading__h1': type === HEADING_1,
      'bt-heading__h2': type === HEADING_2,
      'bt-heading__h3': type === HEADING_3,
      'bt-heading__h4': type === HEADING_4,
      'bt-heading__h5': type === HEADING_5,
      'bt-heading__h6': type === HEADING_6,
    },
    className,
  ]);

  return React.createElement(
    `h${type}`,
    { className: classes, 'data-cy': dataCY, style },
    children
  );
};

Heading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dataCY: PropTypes.string,
  style: PropTypes.any,
  type: PropTypes.oneOf([
    HEADING_1,
    HEADING_2,
    HEADING_3,
    HEADING_4,
    HEADING_5,
    HEADING_6
  ]).isRequired,
};

export const H1 = (props) => Heading({ type: HEADING_1, ...props });
export const H2 = (props) => Heading({ type: HEADING_2, ...props });
export const H3 = (props) => Heading({ type: HEADING_3, ...props });
export const H4 = (props) => Heading({ type: HEADING_4, ...props });
export const H5 = (props) => Heading({ type: HEADING_5, ...props });
export const H6 = (props) => Heading({ type: HEADING_6, ...props });

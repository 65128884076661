// Should remain consistent with `$breakpoint-` variables in `_variables.scss`
export const breakpoints = {
  mini:                   360,
  miniMax:                479,
  small:                  480,
  smallMax:               767,
  medium:                 768,
  mediumMax:              959,
  large:                  960,
  largeMax:               1219,
  xlarge:                 1220,
  mobileLandscape:        823,
  mobileLandscapeLarger:  824,
};

// Aspect Ratio used to check for landscape or portrait orientation
// Portrait - max-aspect-ratio: 13/9
// Landscape - min-aspect-ratio: 13/9
export const aspectRatio = '13/9';

// eslint-disable-next-line import/prefer-default-export
export const isNonMobile =
  `(min-width: ${breakpoints.small}px) and (max-aspect-ratio: 13/9), ` +
  `(min-width: ${breakpoints.mobileLandscapeLarger}px) and (min-aspect-ratio: 13/9)`;


export const deviceXLargeMQ = `(min-width: ${breakpoints.xlarge}px)`;

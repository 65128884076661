/* eslint-disable react/prop-types */
import React from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

// If slightly longer than the CSS transition, this looks a little smoother. At least in Chrome.
const timeout = 700;

/**
 * This is coupled to wp-base-theme/assets/scss/src/base/...
 */
export default ({ children, direction = 'left' }) => (
  <CSSTransitionGroup
    component="div"
    className="site-nav-container"
    transitionAppear
    transitionAppearTimeout={timeout}
    transitionEnterTimeout={timeout}
    transitionLeaveTimeout={timeout}
    transitionName={`site-nav-${direction}`}
  >
    {children}
  </CSSTransitionGroup>
);

import { call, select, takeEvery } from 'redux-saga/effects';
import * as MortgageCalculatorActions from 'actions/MortgageCalculatorActions';
import { getCurrentListingID } from 'selectors/listings';
import $ from 'jquery';
import { config } from 'BoomTown';

function logAction(url, data) {
  return new Promise((resolve, reject) => {
    $.post(url, data, resolve).fail((...args) => reject({ ...args }));
  });
}

const listingCalculatorMap = {};
function* recordMortgageCalculatorInteraction() {
  const listingId = yield select(getCurrentListingID);

  if (listingId === null) {
    // eslint-disable-next-line no-console
    console.warn('Attempted to handle mortgage calc. change on non-listing details route');
    return;
  }

  // We only log this once per listing id
  if (listingCalculatorMap[listingId]) {
    return;
  }

  // REFACTOR: Select these values from state when visitor gets in the store
  const visitId = window.bt.visitDetails._ID;
  const visitorId = window.bt.visitorDetails._ID;

  const url = `${config.apiUrl}/lc/1/leads/${visitorId}/contactform`;
  const data = {
    listingId,
    type: 'loancalculated',
    visitID: visitId,
    visitorID: visitorId,
    action: 'ajax_submit',
    access_token: config.token,
  };

  // Do some ajax
  try {
    yield call(logAction, url, data);
    listingCalculatorMap[listingId] = true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error encountered while logging mortgage calculator interaction', error);
    return;
  }
}

const logActionEffects = [
  takeEvery(MortgageCalculatorActions.onChange, recordMortgageCalculatorInteraction),
];
export default logActionEffects;

/* eslint no-console: "off" */
import { visitor as visitorModel, utility, agent } from 'BoomTown';
import { takeLatest, takeEvery, call, put, select } from 'redux-saga/effects';
import { getCurrentListingID } from 'selectors/listings';
// Mobile contact agent
import {
  autoCloseContactFormSuccessModal,
  closeContactFormSuccessModal,
  receiveContactFormRes,
  submitContactForm,
} from 'actions/MobileDetailsContactAgentActions';
import {
  handleAPIResponse,
} from 'components/MobileContactAgent/effects';

export default [
  takeLatest(submitContactForm, handleSubmitContactForm),
  takeEvery(
    // action
    receiveContactFormRes,
    // side-effect fn
    handleAPIResponse,
    // args passed to side-effect fn
    {
      clickCloseModalAction: closeContactFormSuccessModal,
      autoCloseModalAction: autoCloseContactFormSuccessModal
    }
  ),
  takeEvery([autoCloseContactFormSuccessModal, closeContactFormSuccessModal], afterToastClose),
];

/**
 * CNS-7726 :This helps get the correct again for the contact form based upon subdomain context or assignment.
 * @return {string}
 */
const getAgentId = () => {
  try {
    return agent.attributes._ID;
  } catch (e) {
    return '';
  }
};

function* handleSubmitContactForm({ payload }) {
  const { requestShowing, requestVideoTour, name, phone, email, comments, showingDate, showingTime, token } = payload;
  const [firstName, lastName] = name.split(' ');
  const listingID = yield select(getCurrentListingID);

  // Build the request. Note that the API is case-insensitive when deserializing
  // the request and binding to controllers. (Not sure about PHP though...)
  const request = {
    type: (requestShowing || requestVideoTour) ? 'ScheduleShowing' : 'AskExpert',
    listingID,
    firstName,
    lastName,
    phone,
    email,
    comments,
    token,
    agentID: getAgentId(),
    visitorID: visitorModel.id,
    visitID: visitorModel.get('VisitID'),
    ...((requestShowing || requestVideoTour) && {
      showDate: showingDate,
      showTime: showingTime,
    }),
  };

  // CNS-6572 updated to handle JSON response.
  let response;
  try {
    // dispatch GTM event before we update visitorModel, so we could track contact form registrations
    if (window.dataLayer) {
      window.dataLayer.push({
        form: requestShowing ? 'reqashowingform' : 'contactusform',
        contacttype: visitorModel.updateContactType('lead'),
        formtype: 'email',
        event: 'contact-form-submit',
        isVideoTourRequest: requestVideoTour,
        isTourInPerson: requestShowing
      });
    }

    response = yield call([visitorModel, visitorModel.contactFormPromise], request);
    response = JSON.parse(response.trim());

    if (response.error) {
      throw response;
    }

    response = 'success';
  } catch (e) {
    console.error(e);
    response = 'failure';
  }

  yield put(receiveContactFormRes(response));
  visitorModel.updateAfterRegistrableAction({ phone });
}

function afterToastClose() {
  utility.scrollTop();
}

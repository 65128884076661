import React from 'react';
import PropTypes from 'prop-types';
import { H4 } from 'components/core/Headings';
import Card, { CardBody } from 'components/core/Card';

// @todo This can be renamed to something better
const SettingsCard = ({ className, title, children, muted }) => (
  <Card className={className} muted={muted}>
    <CardBody>
      {title && <H4>{title}</H4>}
      {children}
    </CardBody>
  </Card>
);

SettingsCard.propTypes = {
  className: PropTypes.any,
  title: PropTypes.string,
  children: PropTypes.node,
  muted: PropTypes.bool,
};

SettingsCard.defaultProps = {
  title: '',
};

export default SettingsCard;

import defineActor from './Actor';

/**
 * Define a series of Actors that subscribe to the redux store to perform certain side-effects
 * when derived state changes. Actors are stored in a global `actors` array.active
 *
 * @see /src/store/Actor.js
 *
 * @param {Object} store A Redux store object
 * @param {ActorInit[]} actorInits An array of ActorInit objects used to define new Actor instances
 */
export default function initActors(store, actorInits) {
  actorInits.forEach(({ selector, actor, comparer }) => {
    defineActor(store, selector, actor, comparer);
  });
}

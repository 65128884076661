import { takeEvery, call } from 'redux-saga/effects';
import { searchBarSelect } from 'sagas/routerTasks/commonViewSagas';
import { selectSuggestion } from './actions';

export default [
  takeEvery(selectSuggestion, handleSuggestionSelect),
];

/**
 * When an item is selected from the Autocomplete suggestions, call our common `searchBarSelect`
 * side effect to effectively handle the correct routing to update the search.
 *
 * @param {{payload: SearchSuggestion}} action
 */
function* handleSuggestionSelect({ payload: suggestion }) {
  yield call(searchBarSelect, suggestion);
}

import { searchFields as defaultState } from 'store/initialState';

/**
 * Reducer for the possible search terms in the app. There are no `case`s here
 * because this state is static during the lifetime of the app. Currently,
 * this object contains two types of state:
 *
 * - Options for search terms that are not dynamic, meaning they are
 *   accessible in all installations of the app, regardless of tenant or other
 *   context. It could be argued that this state should be hardcoded in the
 *   components, but if options are shared across multiple components, then it
 *   makes sense to keep them here.
 *
 * - The presence of search terms that are dynamic, like features, where
 *   different contexts--tenants, boards--have different sets of terms. Search
 *   terms that are always present but hidden by special rules are a close
 *   cousin to this type of state, but the business logic that determines what
 *   tenant has what term (open house, etc.) is pushed down into the app,
 *   whereas features are handled in the API.
 *
 *   (_When you think about it, you could also say that the _options_ are
 *   dynamic, and the search term, 'feature', is static. It just depends on
 *   what you define as a term and option._)
 *
 * The motivation behind normalizing the search terms, (PK, display name,
 * options) apart from the current state of the user's search was the need to
 * persist multiple copies of a search, and to have each copy shaped the same.
 * This way we can (hopefully) reuse things like reducers, where all that
 * differs are the actions that transform the search.
 *
 * @param  {Object} state
 * @return {Object}
 */
export default function searchFields(state = defaultState) {
  return state;
}

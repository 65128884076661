import { createSelectorCreator, defaultMemoize } from 'reselect';
import { get as getByPath, isEqual } from 'lodash';

/**
 * Run a single handler for multiple actions. Use by spreading the returned object into a
 * reducer object.
 *
 * @export
 * @param {array} [actions=[]] An array of Redux Actions
 * @param {Function} handler Case function
 * @returns {Object}
 */
export const multiCase = (actions = [], handler) => actions.reduce(
  (prev, a) => ({ ...prev, [a]: handler }),
  {}
);

/**
 * @function sliceFactory
 * @param {String} slice The name of the slice/branch of state
 * @param {Function} handler The case function to handle updating the state for the slice
 * @return {(state: Object, payload: any) => Object}
 */
export const sliceFactory = (slice, handler) => (state, payload) => {
  if (typeof handler !== 'function') {
    return state;
  }

  if (typeof state[slice] === 'undefined') {
    return state;
  }

  return ({
    ...state,
    [slice]: handler(state[slice], payload, state)
  });
};

/**
 * Factory function used to localize selectors to a particular branch of the state tree.
 * @function localizeSelector
 * @throws Will throw an error if `path` is undefined
 *
 * @example
 * const localSelector = localizeSelector('nested.branch');
 * // returns the value from `nested.branch.value`
 * const mySelector = localSelector(state => state.value);
 *
 * @param {Array|String} path The object path to a branch in a state tree
 * @returns {(selector: Function) => (state: Object, ...[]?) => any}
 */
export const localizeSelector = path => {
  if (typeof path === 'undefined') {
    throw new Error('`path` provided to `localizeSelector` is undefined.');
  }

  return selector => (state, ...args) => {
    const localState = getByPath(state, path);

    if (typeof localState === 'undefined') {
      throw new Error(`${path} could not be found in state`);
    }

    return selector(localState, ...args);
  };
};

/**
 * @function createDeepEqualSelector
 * Create a "selector creator" that uses lodash's isEqual instead of ===
 */
export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

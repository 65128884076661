import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Group from 'components/core/Buttons/Group';

const ButtonGroup = (props) => {
  const {
    width,
    className,
    label,
    ...other
  } = props;

  const classes = classNames(
    'btn-group',
    {
      'width-1-1': width === 'full',
      [`${className}`]: className
    }
  );

  return (
    <Group
      className={classes}
      role="group"
      label={label || 'A group of buttons'}
      {...other}
    />
  );
};

ButtonGroup.propTypes = {
  width: PropTypes.string,
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ]).isRequired,
  id: PropTypes.string,
  className: PropTypes.string
};

export default ButtonGroup;

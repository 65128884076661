import { createAction } from 'redux-act';
import { visitor } from 'BoomTown';

/**
 * Mobile Results Header
 ********************************************************
 */

export const clickSearchInResultsHeader = createAction(
  'Mobile Gallery > Header > Click search button',
  () => ({ scrollY: window.scrollY })
);
export const clickNewSearchInResultsHeader = createAction(
  'Mobile Gallery > Header > Click new-search button',
  () => ({ scrollY: window.scrollY })
);
export const clickSaveSearchInResultsHeader = createAction(
  'Mobile Gallery > Header > Click save-search button',
  () => ({ scrollY: window.scrollY }),
  () => ({ visitor })
);
export const clickMapButtonInResultsHeader = createAction(
  'Mobile Gallery > Header > Click view toggle button',
  () => ({ scrollY: window.scrollY })
);
export const clickListButtonInResultsHeader = createAction(
  'Mobile Gallery > Header > Click view toggle button',
  () => {}
);

export const clickToTop = () => () => window.scrollTo(0, 0);

/**
 * Gallery Header
 ********************************************************
 */
export const clickSortByMenu = createAction('Mobile Gallery > Click sort-by', () => {});
export const clickSortOption = createAction('Mobile Gallery > Click a sort-by option', id => id);

/**
 * Gallery
 ********************************************************
 */
export const receiveSearchResults = createAction('Receive new search results', x => x);
export const errorWithResults = createAction('Error with listings request');
export const receiveAdditionalSearchResults = createAction(
  'Mobile Gallery > Receive additional search results',
  x => x
);
export const startFetchingResults = createAction('Mobile Gallery > Start fetching search results');
export const cancelledFetchingResults = createAction('Mobile Gallery > Cancelled results fetch');

/**
 * Listing card actions
 ********************************************************
 */

export const onPhotoSlide = createAction(
  'Mobile Gallery > Photo slider slide',
  /** @param {{ listingId: number, slideIndex: number }} */
  ({ listingId, slideIndex }) => ({ listingId, slideIndex })
);

/**
 * Save Search Ad actions
 ********************************************************
 */

export const clickSaveSearchAd = createAction(
  'Mobile Gallery > Click save search card',
  () => ({
    scrollY: window.scrollY,
  }),
  () => ({ visitor })
);

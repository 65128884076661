/* eslint-disable space-before-function-paren, func-names */
import _ from 'underscore';
import { Events } from 'backbone';

import DepManager from 'legacy/Mapping/dependencies';
import API from 'legacy/Network/api';
import Visitor from 'legacy/Model/visitor';
import Agent from 'legacy/Model/agent';
import Account from 'legacy/Model/account';
import Search from 'legacy/Model/search';
import SpecialRules from 'legacy/Model/special_rules';
import SearchFields from 'legacy/Collections/search_fields';
import Location from 'legacy/Mapping/location';
import Listings from 'legacy/Collections/listings';
import Favorites from 'legacy/Collections/favorites';
import Agents from 'legacy/Collections/agents';
import Tags from 'legacy/Collections/tags';
import utility from 'legacy/Utility/utility';
import LazyLoad from 'legacy/Utility/lazyload';
import addCustomParsleyValidators from 'legacy/Utility/parsley-custom-validators';
import tracking from 'legacy/Utility/tracking';

/**
 * A function that instantiates all our singleton models and collections, and
 * attaches them to the `window.bt` namespace.
 */
export default function() {
  window.bt.lazyload = new LazyLoad();
  addCustomParsleyValidators();
  tracking.init();
  window.bt.tracking = tracking;

  // [aknox] global is a terrible name and who ever wrote that
  // should be ashamed
  window.bt.utility = window.bt.global = utility;

  // Core
  window.bt.events = _.clone(Events);
  window.bt.deps = new DepManager();
  window.bt.api = new API({
    tenantID: window.bt.tenant.id,
    visitorID: window.bt.visitorDetails._ID,
    visitID: window.bt.visitDetails._ID,
    host: window.bt.config.apiUrl,
    token: window.bt.config.token,
  });

  // Models
  window.bt.visitor = new Visitor();
  window.bt.account = new Account();
  window.bt.search = new Search();
  window.bt.rules = new SpecialRules();
  window.bt.searchFields = new SearchFields(window.bt_data.searchFields || []);
  window.bt.location = new Location();

  // Collections
  const hasBootstrapListings = Boolean(
    window.bt_data && window.bt_data.listings && window.bt_data.listings.Result
  );
  window.bt.listings = new Listings(
    hasBootstrapListings ? window.bt_data.listings.Result.Items : []
  );
  if (hasBootstrapListings) {
    window.bt.listings.applyMeta(window.bt_data.listings);
  }

  window.bt.favorites = new Favorites(_.map(window.bt.visitorDetails.Favorites, id => ({ id })));

  // This global agent is a Backbone model representing the agent that is on the PHP $context.
  // See `main.php:641` for details
  window.bt.agent = window.bt.agentDetails != null ? new Agent(window.bt.agentDetails) : null;
  window.bt.agents = new Agents();
  window.bt.tags = new Tags();

  if (location.href.indexOf('perf=1') >= 0) {
    window.metrics = {};

    /**
     * This reports a custom metric for consumption by webpagetest. It reports when the site has been over 25fps for 5
     * frames or more, allowing us to track when the site is "usable". In other words, this lets us know when the user can
     * comfortably scroll and click.
     *
     * The reason I chose 25fps ... It's the threshold of appearing smooth and not choppy, though 15 fps would be a measure
     * of being able to "control" the scene.
     *
     * An improvement for the future, would be to get an average fps over several frames instead of instantaneous.
     */
    const measure = function() {
      let lastTime = new Date();
      let counter = 0;
      var rq = function() {
        const thisTime = new Date();
        const fps = 1000 / (thisTime - lastTime);
        lastTime = thisTime;

        // console.log('Current FPS:', fps);

        if (fps > 25) {
          counter++;
        } else {
          counter = 0;
        }

        if (counter >= 5) {
          return (window.metrics.usable = thisTime - window.performance.timing.navigationStart);
        }
        return requestAnimationFrame(rq);
      };
      return requestAnimationFrame(rq);
    };

    measure();
  }
}

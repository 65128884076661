import { take, takeLatest, call } from 'redux-saga/effects';
import isRoute from 'utility/isRoute';
import * as r from 'routes';
import { search as searchModel } from 'BoomTown';
import { searchChange } from 'actions/BackboneActions';
import * as search from 'sagas/routerTasks/search';
import syncMapResults from './syncMapResults';
import * as a from '../actions';

export default [
  takeLatest([a.onMapIdle, isRoute(r.RESULTS_MAP)], syncMapResults),
  // TODO: Update to LOCATION_CHANGE and merge with functionality of original filterCoordsParams
  takeLatest(a.receiveMapParamsFromUrl, function* handleClearMapParamsFromUrl() {
    // Wait for the SEARCH_CHANGE action before attempting to change our route
    yield take(searchChange);

    const nextQuery = yield call([searchModel, searchModel.toJSON]);
    // Remove map coordinate and zoom params from the URL
    yield call([search, search.go], { query: nextQuery }, 'replace');
  })
];

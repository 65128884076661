import { api as Api, utility, visitor as visitorModel } from 'BoomTown';
import { delay } from 'redux-saga';
import { takeEvery, all, put, select, call } from 'redux-saga/effects';
import { push, LOCATION_CHANGED } from 'redux-little-router';
import * as Routes from 'routes';
import isRoute from 'utility/isRoute';
import { getSavedSearches, getSavedSearch } from 'models/visitor/reducers/selectors';

// Actions
import {
  errorFetchingSavedSearches,
  receiveSavedSearches,
  updateSavedSearchInStore,
} from 'actions/visitorActions';
import * as dssActions from 'actions/desktopSaveSearchActions';
import * as mobileSSActions from 'actions/MobileSaveSearchActions';
import {
  clickEditSavedSearchesButton,
  clickEditSavedSearchesUnsubscribeEAlertsModal,
} from '../EditEmailPrefs/actions';
import {
  selectSavedSearch,
  waitForSavedSearches,
  changeSavedSearchFrequencyRequest,
  deleteSavedSearchRequest,
  receiveAPIChangeResp,
  receiveDeleteSavedSearchResponse,
  closeToast,
} from './actions';

/**
 * Exported for reuse within the CNS App
 */
export const reusableEffects = [
  takeEvery(
    [dssActions.receiveCreateSavedSearchRes, mobileSSActions.receiveCreateSavedSearchRes],
    refreshSavedSearchData
  ),
  takeEvery(LOCATION_CHANGED, initializeSavedSearchData),
  takeEvery(changeSavedSearchFrequencyRequest, updateSavedSearch),
  takeEvery(deleteSavedSearchRequest, deleteSavedSearch),
  takeEvery([receiveAPIChangeResp, receiveDeleteSavedSearchResponse], handleAPIChangeResp),
];

export default [
  takeEvery(
    [clickEditSavedSearchesButton, clickEditSavedSearchesUnsubscribeEAlertsModal],
    handleEditSaveSearchesClick
  ),
  takeEvery(selectSavedSearch, handleSelectSavedSearch),
  ...reusableEffects,
];


/**
 * Only used to trigger the route change when a saved search is selected from the Edit Saved
 * Searches view. As of right now, this task should only run when the `useListMapResultsSync`
 * feature flag is active since that's the only time the `selectSavedSearch` action will be
 * dispatched when a Saved Search Item is selected.
 *
 * @param {*} { payload: { pathname: string, searchQuery: Object } }
 */
function* handleSelectSavedSearch({ payload: { pathname, searchQuery } }) {
  yield put(push({ pathname, query: searchQuery }));
}

function* handleEditSaveSearchesClick() {
  yield put(push(`/${Routes.EDIT_SAVED_SEARCHES}`));
}

function* fetchSavedSearches() {
  let searches;
  try {
    searches = yield call([Api, 'getSavedSearches']);
  } catch (e) {
    yield put(errorFetchingSavedSearches(e));
    return;
  }
  // Get search counts and set proper naming conventions in each SS object
  searches = yield all(searches.map(s => call(buildSearches, s)));
  yield put(receiveSavedSearches(searches));
}

// The initial fetching of the user's Saved Search data. Fired on LOCATION_CHANGED action,
// but only calls `fetchSavedSearches` if Saved Search data does not currently exist in the
// store for the registered user to prevent extra api requests.
// Dispatches the `waitForSavedSearches` on the `/saved-searches` route in order to render
// the Loading icon while the data is fetched.
function* initializeSavedSearchData(action) {
  if (!visitorModel.isRegistered()) {
    return;
  }
  if (!(yield select(getSavedSearches))) {
    if (isRoute(Routes.EDIT_SAVED_SEARCHES)(action)) {
      yield put(waitForSavedSearches());
    }
    yield call(fetchSavedSearches);
  }
}

// Re-Fetch our Saved Searches when our data is invalidated (a new Saved Search is added).
// There's no dependence on existence of Saved Search data in the store for this one since
// we always want to refresh that state.
function* refreshSavedSearchData(action) {
  if (action.payload.response === 'failure') {
    return;
  }
  yield call(fetchSavedSearches);
}

function* updateSavedSearch(action) {
  const { payload } = action;
  const data = yield select(getSavedSearch, payload.searchid);

  // Don't include `count` in our request obj since the api request doesn't need it.
  // Technically we could probably leave it since the `updateSavedSearch` visitor
  // action will only pull in the qs params it actually needs and ignore the rest,
  // but technically I guess it's cleaner not to send qs params that aren't needed.
  const request = {
    searchid: payload.searchid,
    searchName: data.searchName,
    email: data.email,
    frequency: payload.frequency,
    querystring: data.querystring,
  };

  let response;

  try {
    response = yield call([visitorModel, visitorModel.updateSaveSearchPromise], request);
    yield put(receiveAPIChangeResp({ response }));

    yield put(
      updateSavedSearchInStore({
        ...request,
        // Add `count` back in
        count: data.count,
      })
    );
  } catch (e) {
    yield put(receiveAPIChangeResp({ response: 'failure' }));
  }
}

function* deleteSavedSearch(action) {
  const { searchid } = action.payload;
  const request = { id: searchid };

  let response;

  try {
    response = yield call([visitorModel, visitorModel.deleteSavedSearchPromise], request);
    yield put(receiveDeleteSavedSearchResponse({ response, id: searchid }));
    window.bt.visitor.updateSearchCount('down');
  } catch (e) {
    yield put(receiveDeleteSavedSearchResponse({ response: 'failure', id: searchid }));
  }
}

function* handleAPIChangeResp() {
  yield call(delay, 3000);
  yield put(closeToast());
}

function* buildSearches(s) {
  const params = utility.parseQueryString(s.QueryString);
  const count = yield call([Api, Api.searchcountPromise], params);

  // Change casing of Saved Search props to how the API update request wants them
  return {
    searchid: s.SearchID,
    searchName: s.SearchName,
    querystring: s.QueryString,
    email: s.Email || visitorModel.attributes.Username,
    frequency: parseInt(s.EmailFrequency, 10),
    count: count.Result,
  };
}

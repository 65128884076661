import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import Listing from 'legacy/Model/listing';
import ContactView from 'legacy/Views/Layouts/contact';
import FlickityView from 'legacy/Views/Listings/flickityView';
import WidgetListings from 'components/WidgetListings/Container';
import ListingCardView from 'legacy/Views/Listings/card';
import AgentSearch from 'components/AgentSearch/Container';
import AgentFilter from 'components/AgentFilter';

class CatchAll extends Component {
  static contextTypes = {
    store: PropTypes.object,
  };
  static propTypes = {
    html: PropTypes.any,
  };

  componentDidMount() {
    const contactViews = [...this.el.querySelectorAll('.js-contact-form')].map(
      el =>
        new ContactView({
          el,
          model: window.bt.visitor,
        })
    );

    const flickityViews = [...this.el.querySelectorAll('.js-card-slider')].map(
      el => new FlickityView({ el })
    );

    const widgetListingViews = [...this.el.querySelectorAll('.js-widget-listings')].map(el => {
      // Render the widget
      const props = JSON.parse(el.dataset.props);
      ReactDOM.render(
        <Provider store={this.context.store}>
          <WidgetListings {...props} />
        </Provider>,
        el
      );

      // Adhere to the child view api
      return {
        remove() {
          ReactDOM.unmountComponentAtNode(el);
        },
      };
    });

    const cardViews = [...this.el.querySelectorAll('.js-card')].map(el => {
      const model = new Listing({ _ID: el.dataset.listingid });
      return new ListingCardView({
        model,
        inCollection: false,
        el,
        renderUI: false,
      });
    });

    const agentViews = [...this.el.querySelectorAll('.js-agent-office-search')].map(el => {
      ReactDOM.render(
        <Provider store={this.context.store}>
          <AgentSearch />
        </Provider>,
        el
      );

      return {
        remove() {
          ReactDOM.unmountComponentAtNode(el);
        },
      };
    });

    const agentsPageFilter = [...this.el.querySelectorAll('.js-agent-filter')].map(el => {
      const agentString = el.dataset.agentlist;
      if (!agentString) {
        return { remove() {} };
      }

      const agents = JSON.parse(agentString);
      ReactDOM.render(
        <Provider store={this.context.store}>
          <AgentFilter agents={agents} />
        </Provider>,
        el
      );

      return {
        remove() {
          ReactDOM.unmountComponentAtNode(el);
        },
      };
    });

    this.childViews = [
      ...contactViews,
      ...flickityViews,
      ...widgetListingViews,
      ...cardViews,
      ...agentViews,
      ...agentsPageFilter,
    ];
  }

  componentWillUnmount() {
    this.childViews.forEach(v => v.remove());
  }

  render() {
    return <div ref={c => (this.el = c)} dangerouslySetInnerHTML={{ __html: this.props.html }} />;
  }
}

export default connect(state => ({
  html: state.cachedHtml.catchAll || '',
}))(CatchAll);

/**
 * Be super careful changing the names of the comments for webpackChunkName
 * details, home, and results-gallery are magic strings used by php
 * for script loading / prefetching
 * @see /wp-content/themes/wp-base-theme/inc/assets.php
 */
import React from 'react';
import loadable from 'react-loadable';
import CachedHTML from 'screens/util/CachedHTMLContainer';
import backboneToReactView from 'legacy/Routing/backboneToReactView';
import IsMobile from 'components/IsMobile';
import LazyMobileGallery from './Mobile';
import { outage } from 'BoomTown';

const LazyResultsGallery = loadable({
  loader: () =>
    import(/* webpackChunkName: "js/build/results-gallery" */ 'legacy/Views/Listings/gallery').then(mod =>
      backboneToReactView(mod.default)
    ),
  loading: CachedHTML
});

/**
 * A component for the gallery results view, which only determines which
 * concrete gallery component to render.
 */
export default function Gallery() {
  if (outage) {
    window.location.href = '/contactus/?outage=true';
  }

  return (
    <IsMobile>
      {isMobile => {
        if (isMobile) {
          window.bt.lazyload.layzr.handlers(false);
          return <LazyMobileGallery />;
        }

        window.bt.lazyload.layzr.handlers(true);
        return <LazyResultsGallery />;
      }}
    </IsMobile>
  );
}

import PropTypes from 'prop-types';
import React from 'react';
import Sticky from 'react-stickynode';
import { PrimaryButton } from 'coreComponents';
import FAIcon from 'components/core/FAIcon';
import SortDropdown from './MobileResultsSortDropdown';
import ViewToggleButton from './MobileResultsViewToggle';

/**
 * The horizontal button group at the top of mobile search results, containing:
 * - A "Search" button to open the off-canvas search menu
 * - A button to toggle the results view <"Map" | "List">
 * - Optionally a sort dropdown
 */
const MobileResultsNavBar = ({
  currentSort,
  resultsView,
  showingFavorites,
  showDistanceSort,
  onClickSearch,
  onClickNewSearch,
  onSelectSort,
}) => (
  <Sticky enabled innerZ={900}>
    <div className="bt-results-header__mobile">
      <nav className="bt-results__view-switch">
        <div className="grid grid--gutters">
          <div className="cell bt-results-header__mobile__search">
            <PrimaryButton width="full" onClick={showingFavorites ? onClickNewSearch : onClickSearch}>
              <FAIcon icon="sliders-h" type="solid" />{' '}
              {showingFavorites ? 'Start a New Search' : 'Filter'}
            </PrimaryButton>
          </div>

          {!showingFavorites ? (
            <div className="cell bt-results-header__mobile__toggle">
              <ViewToggleButton resultsView={resultsView} />
            </div>
          ) : null}

          {resultsView === 'list' && !showingFavorites ? (
            <div className="cell bt-results-header__mobile__sort">
              <div className="uk-text-right bt-listing__sort--mobile">
                <SortDropdown
                  currentSort={currentSort}
                  showDistanceSort={showDistanceSort}
                  onSelectSort={onSelectSort}
                />
              </div>
            </div>
          ) : null}
        </div>
      </nav>
    </div>
  </Sticky>
);

MobileResultsNavBar.displayName = 'MobileResultsNavBar';
MobileResultsNavBar.propTypes = {
  // Let's see how favorites comes into play
  currentSort: PropTypes.string,
  onClickNewSearch: PropTypes.func,
  onClickSearch: PropTypes.func.isRequired,
  onSelectSort: PropTypes.func.isRequired,
  resultsView: PropTypes.oneOf(['list', 'map']),
  showDistanceSort: PropTypes.bool,
  showingFavorites: PropTypes.bool,
};

export default MobileResultsNavBar;

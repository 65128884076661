import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment-timezone';
import c_ from 'classnames';

import FAIcon from 'components/core/FAIcon';
import Day from '../Day';

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayedMonth: (moment.isMoment(props.date) ? props.date.clone() : moment()).startOf(
        'month'
      ),
    };
  }

  /**
   * Creates an array of all days to be displayed given the first day of a month
   *
   * @param {Array<Moment>} displayedMonth
   */
  daysOfMonth(displayedMonth) {
    let _i, d; // eslint-disable-line
    const results = [];

    // note: using non-locale version https://momentjs.com/docs/#/get-set/day/
    // returns the date's numerical representations; 0: Sunday, 1: Monday ...
    const startAt = -displayedMonth.day() % 7;
    const endAt = Math.ceil((displayedMonth.daysInMonth() - startAt) / 7) * 7 + startAt - 1; // eslint-disable-line

    for (
      d = _i = startAt;
      startAt <= endAt ? _i <= endAt : _i >= endAt;
      d = startAt <= endAt ? ++_i : --_i
    ) {
      results.push(displayedMonth.clone().add(d, 'days'));
    }

    return results;
  }

  weekEnum(daysOfMonth) {
    const results = [];
    const dayRef = daysOfMonth.length / 7;

    for (let _i = 0; dayRef >= 0 ? _i < dayRef : _i > dayRef; dayRef >= 0 ? _i++ : _i--) {
      results.push(_i);
    }
    return results;
  }

  isDateFromNextMonth(date, displayedMonth) {
    return (
      date.year() > displayedMonth.year() ||
      (date.year() === displayedMonth.year() && date.month() > displayedMonth.month())
    );
  }

  isDateFromPrevMonth(date, displayedMonth) {
    return (
      date.year() < displayedMonth.year() ||
      (date.year() === displayedMonth.year() && date.month() < displayedMonth.month())
    );
  }

  moveDisplayedMonth(delta) {
    this.setState((prevState) => {
      const dm = prevState.displayedMonth;
      return ({
        displayedMonth: dm.clone().add(delta, 'months'),
      });
    });
  }

  renderWeeks() {
    const _daysOfMonth = this.daysOfMonth(this.state.displayedMonth);
    const _weekEnum = this.weekEnum(_daysOfMonth);

    return _weekEnum.map((week, key) => (
      <div key={key} className="grid grid--noWrap mb-4">
        {this.renderDay(_daysOfMonth, week)}
      </div>
    ));
  }

  renderDay(daysOfMonth, week) {
    const days = [0, 1, 2, 3, 4, 5, 6];
    const { minDate, maxDate } = this.props;

    return days.map((day, key) => {
      const date = daysOfMonth[week * 7 + day]; // eslint-disable-line
      let disabled;

      if (minDate && maxDate) {
        disabled = date.isBefore(minDate, 'day') || date.isAfter(maxDate, 'day');
      } else if (minDate) {
        disabled = date.isBefore(minDate, 'day');
      } else if (maxDate) {
        disabled = date.isAfter(maxDate, 'day');
      }

      const dayNextMonth = this.isDateFromNextMonth(date, this.state.displayedMonth);
      const dayPrevMonth = this.isDateFromPrevMonth(date, this.state.displayedMonth);

      return (
        <Day
          key={key}
          day={date}
          disabled={disabled}
          dayPrevMonth={dayPrevMonth}
          dayNextMonth={dayNextMonth}
          active={this.props.date}
          selectDay={this.props.selectDay}
        />
      );
    });
  }

  renderNameOfDays() {
    // If we want this to support locale specific calendars we should update the day
    // and then generate these with moment
    const nameOfDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return (
      <div className="grid grid--noWrap calendar__dayNames py-4 mb-4 text-bold">
        {nameOfDays.map((day, i) => (
          <div key={i} className="cell">
            {day}{' '}
          </div>
        ))}
      </div>
    );
  }

  render() {
    return (
      <div className={c_('text-xs--center', { calendar: !this.props.block })}>
        <div className="grid grid--noWrap text-bold">
          <div
            className="cell calendar__prevMonth py-12"
            tabIndex="0"
            onClick={() => this.moveDisplayedMonth(-1)}
          >
            <FAIcon icon="angle-left" type="regular" />
          </div>
          <div className="cell cell-xs-8 calendar__month py-12" colSpan="5">
            {this.state.displayedMonth.format('MMMM YYYY')}
          </div>
          <div
            className="cell calendar__nextMonth py-12"
            tabIndex="0"
            onClick={() => this.moveDisplayedMonth(1)}
          >
            <FAIcon icon="angle-right" type="regular" />
          </div>
        </div>
        {this.renderNameOfDays()}
        {this.renderWeeks()}
      </div>
    );
  }
}

Calendar.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)]),
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)]),
  selectDay: PropTypes.func.isRequired,
  block: PropTypes.bool,
};

Calendar.defaultProps = {
  minDate: moment(),
  block: false,
};

export default Calendar;

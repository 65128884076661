/* eslint-disable no-confusing-arrow */
import { search } from 'BoomTown';
import { omit } from 'underscore';
import { call, takeEvery } from 'redux-saga/effects';

import { remove, add, go } from 'sagas/routerTasks/search';
import { saleTypeReducer, valuesToArrays } from 'reducers/saleTypes';
import * as a from './actions';

export default [
  takeEvery(
    a.selectMinBeds,
    function* onSelect({ payload: minbeds }) {
      if (minbeds === 0) {
        yield call(remove, { minbeds: null });
      } else {
        yield call(add, { minbeds });
      }
    }
  ),

  takeEvery(
    a.selectMinBaths,
    function* onSelect({ payload: minbaths }) {
      const currentSearch = yield call([search, search.toJSON]);
      const nextSearch = yield call([search, search.minBathsReducer], currentSearch, minbaths);
      yield call(go, { query: nextSearch });
    }
  ),

  takeEvery(
    a.selectPriceRange,
    function* onSelect({ payload: { minprice, maxprice } }) {
      const currentSearch = yield call([search, search.toJSON]);
      const nextSearch = omit(currentSearch, 'minprice', 'maxprice');

      if (minprice !== 0) {
        nextSearch.minprice = String(minprice);
      }
      if (maxprice !== Infinity) {
        nextSearch.maxprice = String(maxprice);
      }

      yield call(go, { query: nextSearch });
    }
  ),

  takeEvery(
    a.selectSelectOption,
    function* onUpdate({ payload: { field, value } }) {
      if (value !== 'any') {
        yield call(add, { [field]: value });
      } else {
        yield call(remove, { [field]: null });
      }
    }
  ),

  takeEvery(
    a.clickHasPhotos,
    function* onClick() {
      const { photo } = yield call([search, search.toJSON]);
      if (Boolean(photo) && photo !== '0') {
        yield call(remove, { photo: null });
      } else {
        yield call(add, { photo: '1' });
      }
    }
  ),

  takeEvery(
    a.clickVirtualTours,
    function* onClick() {
      const { tours } = yield call([search, search.toJSON]);
      if (Boolean(tours) && tours !== '0') {
        yield call(remove, { tours: null });
      } else {
        yield call(add, { tours: '1' });
      }
    }
  ),

  takeEvery(
    a.clickFeature,
    function* onClick({ payload: id }) {
      yield* toggleListItem('feature', id);
    }
  ),

  takeEvery(
    a.clickPropertyType,
    function* onClick({ payload: id }) {
      yield* toggleListItem('proptype', id);
    }
  ),

  takeEvery(
    a.clickStatus,
    function* onClick({ payload: id }) {
      yield* toggleListItem('status', id);
    }
  ),

  takeEvery(
    a.clickSaleType,
    /**
     * Determine the next URL when clicking sale type checkboxes. Sorry for all
     * the code, most of it is moving between:
     *
     * - Dynamic vs. static objects/defined keys
     * - Comma-delimited strings vs. arrays
     *
     * @param {{ payload: SaleTypeID }} action
     */
    function* onClick({ payload: id }) {
      const currentSearch = yield call([search, search.toJSON]);
      /** @type {SaleTypeState} */
      // @ts-ignore
      const prevSaleTypeState = valuesToArrays(currentSearch, [
        'featureor',
        'featurenot',
        'status',
      ]);
      const nextSaleTypeState = saleTypeReducer(prevSaleTypeState, id);

      // Remove empty arrays and convert the arrays to comma-delimited strings
      const nextSaleTypesOnSearch = Object.keys(nextSaleTypeState)
        .filter(key => nextSaleTypeState[key].length)
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: nextSaleTypeState[key].join(','),
          }),
          {}
        );

      const nextSearchState = {
        ...omit(currentSearch, ['featureor', 'featurenot', 'status']),
        ...nextSaleTypesOnSearch,
      };

      yield call(go, { query: nextSearchState });
    }
  ),
];

/**
 * Initiate a route change required to toggle one of our IN queries.
 *
 * @param {String} key
 * @param {String} value
 */
function* toggleListItem(key, value) {
  const currentSearch = yield call([search, search.toJSON]);
  const list = currentSearch[key] ? currentSearch[key].split(',') : [];
  if (list.includes(value)) {
    yield call(remove, { [key]: value });
  } else {
    yield call(add, { [key]: value });
  }
}

import { createAction } from 'redux-act';

/**
 * bootOptimize
 *
 * A redux action which updates our store with the current Optimize A/B variant settings.
 *
 * @param {object} optimize - Optimize config. Most importantly { variant: string }
 * @return {object} Standard flux action { type: 'Initiating Optimize Test', payload: { variant: 'B' } }
 */
export const bootOptimize = createAction('Initiating Optimize Test');
export const timeout = createAction('System: Timed out waiting for Optimize');

import { connectBBHoc } from 'hoc/connectBB';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { search as searchModel } from 'BoomTown';
import * as a from './actions';
import SearchMenu from './component';

export default compose(
  connectBBHoc(
    [
      {
        getEmitter: () => searchModel,
        events: [
          {
            name: 'change',
            replaceProps: (props, model) => ({ search: model.toJSON() }),
          },
        ],
      },
    ],
    () => ({ search: searchModel.toJSON() })
  ),
  connect(
    (state) => {
      // Todo: Might need to move this to a selector or memoize
      const accountRules = {
        isCanadian: state.account.IsCanadian,
        showNeighborhoodSearch: state.account.ShowNeighborhoodSearch,
        showSchoolSearch: state.account.ShowSchoolSearch,
        showSchoolDistrictSearch: state.account.ShowSchoolDistrictSearch,
        showListingAreaOnPS: state.account.ShowListingAreaOnPS,
        showCountyOnPS: state.account.ShowCountyOnPS,
        showOpenHouse: state.account.ShowOpenHouse,
      };

      // Todo: Move away from using the entire `specialRules`. Instead, only pull off what we need.
      // Any other components that need props from specialRules should subscribe to the specialRules
      // branch in our gloabl state and pluck off only what they need. This is more efficient and
      // can save costly re-renders.
      return {
        accountRules,
        specialRules: state.specialRules,
        searchFields: state.searchFields,
      };
    },
    {
      onPriceChange: a.selectPriceRange,
      onSelectMenuChange: a.selectSelectOption,
      onSelectMinBeds: a.selectMinBeds,
      onSelectMinBaths: a.selectMinBaths,
      onToggleFeature: a.clickFeature,
      onTogglePhoto: a.clickHasPhotos,
      onToggleProptype: a.clickPropertyType,
      onToggleSaleType: a.clickSaleType,
      onToggleVirtualTours: a.clickVirtualTours,
    }
  )
)(SearchMenu);

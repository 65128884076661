import { takeEvery, put, select } from 'redux-saga/effects';
import { scrollToElement } from 'utility';
import { favorites } from 'BoomTown';
import { ID_SECTION_PAYMENT, ID_SECTION_AGENT_FORM } from 'components/MobileDetailsPage/constants';

import { isSliderUIHidden } from 'selectors/mobileDetails';
import { getCurrentListingID } from 'selectors/listings';

import * as MobileDetailsActions from 'actions/MobileDetailsActions';

// Side-effects that are used in both the main CNS Site and the CNS App
export default [
  takeEvery(MobileDetailsActions.clickFavoriteButton, handleFavoriteClick),
  takeEvery(MobileDetailsActions.clickEstimatedMortgageText, scrollToEstimatedMortgageSection),
  takeEvery(
    [
      MobileDetailsActions.clickContactAgent,
      MobileDetailsActions.clickTourInPerson,
      MobileDetailsActions.clickTourViaVideo,
    ],
    scrollToContactAgentForm
  ),
  takeEvery(MobileDetailsActions.onPhotoSlide, shouldHideSliderUI),
];

/**
 * Get the ID of the listing from the store, and toggle the favorites using our
 * Backbone collection.
 *
 * @todo deprecate when favorites collection is in redux, this is only a saga because
 * we are causing an effect on a different "store"
 */
export function* handleFavoriteClick() {
  const id = yield select(getCurrentListingID);
  favorites.toggle(id);
}

/**
 * Scroll to the Mortgage Calculator section when the Estimated Mortgage link on the Property
 * Details page is selected
 */
export function scrollToEstimatedMortgageSection() {
  const el = document.getElementById(ID_SECTION_PAYMENT);
  if (el == null) {
    return;
  }
  el.scrollIntoView({
    block: 'start',
    inline: 'nearest',
    behavior: 'smooth',
  });
}

/**
 * Scroll to the Contact Agent Form when the Contact Agent Button is selected
 */
export function scrollToContactAgentForm() {
  const el = document.getElementById(ID_SECTION_AGENT_FORM);
  if (el == null) {
    return;
  }
  scrollToElement(el);
}

/**
 * Hide the Sash and Favorite button from the Details Page Slider when the CTA
 * Slide is active. The CTA Slide is visible at the end of the viewable photos.
 *
 * @see CNS-5564
 * @param {ReduxAction} action
 * @param {Object} action.payload
 * @param {Number} payload.slideIndex The current photo slider index
 * @param {Number} payload.photoCount The total number of photos in the photo slider
 *
 */
export function* shouldHideSliderUI(action) {
  const {
    payload: { slideIndex, photoCount },
  } = action;

  if (slideIndex === photoCount) {
    yield put(MobileDetailsActions.hideSliderUI());
    return;
  }

  if (yield select(isSliderUIHidden)) {
    yield put(MobileDetailsActions.showSliderUI());
  }
}

/**
 * Based loosely on https://github.com/contra/react-responsive
 * maybe one day we'll port all of their functionality
 */
import PropTypes from 'prop-types';

import { Component } from 'react';

/**
 * Break Point Component
 *
 * Only renders children if viewport matches the provided media query.
 *
 * @param  {Object} props
 * @return {ReactElement}
 */
class BreakPoint extends Component {
  constructor(props) {
    super(props);
    this.mediaList = window.matchMedia(this.props.mediaQuery);
    this.state = {
      matches: this.mediaList.matches
    };
  }

  /**
   * Attaches the event listener to the MediaQueryList object
   * once the component has mounted on the dom.
   * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
   */
  componentDidMount() {
    this.mediaList.addListener(this._listener);
  }

  /**
   * Removes the event listener on the MediaQueryList object
   * before the component unmounts from the dom.
   * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
   */
  componentWillUnmount() {
    this.mediaList.removeListener(this._listener);
  }

  /**
   * MediaQueryList listener
   * @param  {Boolean} {matches} true is the document currently matches the media query list
   * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
   */
  _listener = ({ matches }) => {
    this.setState({ matches });
  };

  /**
   * Only render children if the document matches the media query
   */
  render() {
    if (typeof this.props.children === 'function') {
      return this.props.children(this.state.matches);
    }
    return this.state.matches && this.props.children;
  }
}

BreakPoint.displayName = 'BreakPoint';
BreakPoint.propTypes = {
  mediaQuery: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]),
};

export default BreakPoint;

import { createReducer } from 'redux-act';

import { clickTourInPerson, clickTourViaVideo, clickContactAgent } from 'actions/MobileDetailsActions';
import * as modalActions from 'actions/MobileContactAgentActions';
import * as detailsActions from 'actions/MobileDetailsContactAgentActions';

const defaultState = {
  isSubmitting: false,
  isResModalOpen: false,
  isSuccess: false,
  isVideoTour: false,
  isInPersonTour: false,
};

export default createReducer(
  {
    [modalActions.autoCloseContactFormSuccessModal]: () => ({ ...defaultState }),
    [detailsActions.autoCloseContactFormSuccessModal]: () => ({ ...defaultState }),

    [modalActions.clickClose]: () => ({ ...defaultState }),

    [modalActions.closeContactFormFailureModal]: state => ({ ...state, isResModalOpen: false }),
    [detailsActions.closeContactFormFailureModal]: state => ({ ...state, isResModalOpen: false }),

    [modalActions.closeContactFormSuccessModal]: () => ({ ...defaultState }),
    [detailsActions.closeContactFormSuccessModal]: () => ({ ...defaultState }),

    [modalActions.receiveContactFormRes]: (state, { success }) => ({
      ...state,
      isResModalOpen: true,
      isSuccess: success,
      isSubmitting: false,
    }),
    // TODO: Refactor Contact Agent redux flow for mobile details page to reuse logic for mobile
    // contact agent and desktop account contact agent
    [detailsActions.receiveContactFormRes]: (state, { success }) => ({
      ...state,
      isResModalOpen: true,
      isSuccess: success,
      isSubmitting: false,
    }),

    [modalActions.retrySubmitContactForm]: state => ({ ...state, isResModalOpen: false }),
    [detailsActions.retrySubmitContactForm]: state => ({ ...state, isResModalOpen: false }),

    [modalActions.submitContactForm]: state => ({ ...state, isSubmitting: true }),
    [detailsActions.submitContactForm]: state => ({ ...state, isSubmitting: true }),

    [clickTourInPerson]: state => ({
      ...state,
      isVideoTour: false,
      isInPersonTour: true,
    }),
    [detailsActions.toggleRequestShowing]: state => ({
      ...state,
      isVideoTour: false,
      isInPersonTour: !state.isInPersonTour,
    }),
    [detailsActions.toggleRequestVideoTour]: state => ({
      ...state,
      isVideoTour: !state.isVideoTour,
      isInPersonTour: false,
    }),
    [clickTourViaVideo]: state => ({
      ...state,
      isVideoTour: true,
      isInPersonTour: false,
    }),

    // We want to make sure that if "Ask a Question" or "Contact Agent" is clicked
    // tour checkboxes are reset
    [clickContactAgent]: state => ({
      ...state,
      isVideoTour: false,
      isInPersonTour: false
    })
  },
  defaultState
);


/**
 * Selectors
 */
const getState = state => state.mobileContactAgent;

const select = fn => state => fn(getState(state));

export const isResModalOpen = select(x => x.isResModalOpen);
export const isSubmitInProgress = select(x => x.isSubmitting);
export const isSubmitSuccess = select(x => x.isSuccess);
export const isVideoTour = select(x => x.isVideoTour);
export const isInPersonTour = select(x => x.isInPersonTour);

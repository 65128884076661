import { createReducer } from 'redux-act';
import { isEmpty } from 'underscore';
import { LOCATION_CHANGED } from 'redux-little-router';
import { utility, config } from 'BoomTown';
import { RESULTS_GALLERY, RESULTS_MAP, FAVORITES, COMPARE, HOME } from 'routes';
import { multiCase } from 'reducers/util';
import { filterCoordsParams } from 'models/search/util';

// Actions
import { initializeState } from 'actions/bootstrapActions';
import * as HomepageBallerBoxActions from 'components/HomepageSearch/Consumer/actions';
import { selectSavedSearch } from 'screens/Account/Forms/EditSavedSearches/actions';
import { applyPendingSearchForSaveSearch } from 'consumerApp/views/SaveSearch/actions';
import { applyPendingSearch, applySavedSearch } from 'consumerApp/views/Results/actions';
import {
  onMapBoundsChanged,
  onMapZoom,
  receiveMapParamsFromUrl
} from 'screens/ResultsMap/Map/actions';

const initialState = {
  lastSearchRoute: null,
  lastSearchState: null,
  lastMapBounds: null,
  lastMapZoom: null,
};

// When the search model's state is in Redux, this should live alongside it. Or
// possibly, just _be_ it, if the binding between search changing and fetching
// listings is broken.
export default createReducer(
  {
    [initializeState]: (
      state,
      { lastSearchRoute, lastSearchState, lastMapBounds, lastMapZoom }
    ) => ({
      lastSearchRoute: lastSearchRoute || initialState.lastSearchRoute,
      lastSearchState: lastSearchState || initialState.lastSearchState,
      lastMapBounds: lastMapBounds || initialState.lastMapBounds,
      lastMapZoom: lastMapZoom || initialState.lastMapZoom,
    }),

    [LOCATION_CHANGED]: (state, { result, query }) => {
      const routeID = result ? result.id : undefined;

      if (config.isCustomHomepage && query && Object.keys(query).length > 0) {
        const qs = Object.keys(query).map(key => key + '=' + query[key]).join('&');
        window.location.href = `/results-gallery/?${qs}`;
      }

      if ([RESULTS_GALLERY, RESULTS_MAP, FAVORITES, COMPARE].includes(routeID)) {
        return {
          ...state,
          lastSearchRoute: routeID,
          // This currently caches the entire query string, not just
          // search-related params.
          lastSearchState: filterCoordsParams(query) || {},
        };
      }

      return {
        ...state,
        lastMapBounds: initialState.lastMapBounds,
        lastMapZoom: initialState.lastMapZoom,
      };
    },

    // Set `lastMapBounds` state and subsequently through actors, we update the `lastMapBounds`
    // cookie as well.
    [onMapBoundsChanged]: (state, mapBounds) => ({
      ...state,
      lastMapBounds: mapBounds,
    }),
    [receiveMapParamsFromUrl]: (state, { zoom, ...bounds }) => ({
      ...state,
      lastMapBounds: {
        ...bounds,
      },
      lastMapZoom: zoom,
    }),
    [onMapZoom]: (state, zoom) => ({
      ...state,
      lastMapZoom: zoom,
    }),

    // Clear lastMapBounds and lastMapZoom when a Saved Search is selected from the
    // Edit Saved Searches view. We don't want the lastMapBounds being applied to a saved search
    // that either doesn't have map bounds or has its own map bounds included.
    [selectSavedSearch]: (state, { mapBounds, mapZoom }) => ({
      ...state,
      // `mapBounds` will either be an object or null, so the 2nd half of this conditional isn't
      // really necessary, but I'm leaving it as an extra guard.
      lastMapBounds: mapBounds || initialState.lastMapBounds,
      lastMapZoom: mapZoom || initialState.lastMapZoom,
    }),

    // Native App - adding the lastMapBounds and lastMapZoom to the state tree when calling `POPULATE_SAVED_SEARCH`
    [applyPendingSearchForSaveSearch]: (state, { mapBounds, zoom }) => {
      if (!config.useListMapResultsSync) {
        return state;
      }
      return {
        ...state,
        lastMapBounds: mapBounds || initialState.lastMapBounds,
        lastMapZoom: zoom || initialState.lastMapZoom
      };
    },

    // Sent by the `APPLY_SAVED_SEARCH` message, if the saved search does not have mapbounds or
    // zoom, just set them to their default values
    [applySavedSearch]: (state, { mapBounds, zoom }) => {
      if (!config.useListMapResultsSync) {
        return state;
      }

      const hasMapBounds = !isEmpty(mapBounds);
      return {
        ...state,
        lastMapBounds: hasMapBounds ? mapBounds : initialState.lastMapBounds,
        lastMapZoom: zoom || initialState.lastMapZoom,
      };
    },

    // TODO: Update this and `applySavedSearch` case reducers by possibly removing them completely
    // now that any map bounds or zoom should be set from the URL.
    [applyPendingSearch]: (state, { mapBounds, zoom }) => {
      if (!config.useListMapResultsSync) {
        return state;
      }

      const hasMapBounds = !isEmpty(mapBounds);
      return {
        ...state,
        lastMapBounds: hasMapBounds ? mapBounds : initialState.lastMapBounds,
        lastMapZoom: zoom || initialState.lastMapZoom,
      };
    },

    /**
     * Homepage Search
     */
    ...multiCase(
      [HomepageBallerBoxActions.emptySubmitClick, HomepageBallerBoxActions.selectSuggestion],
      (state) => ({
        ...state,
        lastMapBounds: initialState.lastMapBounds,
        lastMapZoom: initialState.lastMapZoom,
      })
    ),
  },
  initialState,
);

/**
 * Get information maintained about the last search the user was viewing.
 *
 * (Co-locating the selector with the reducer, but not composing it in the root
 * reducer with knowledge of where it's "installed", just yet. Doing that here
 * for now.)
 *
 * @param {Object} state The root state of the store
 * @returns {{ lastSearchRoute: string?, lastSearchState: Object? }}
 */
export const getLastSearch = state => state.lastSearch;
export const getLastSearchRoute = state => state.lastSearch.lastSearchRoute;

/**
 * Return the lastSearchState object from the `lastSearch` branch in our global state tree
 *
 * @param {Object} state The root state of the store
 */
export const getLastSearchState = state => (
  state.lastSearch ? state.lastSearch.lastSearchState : {}
);

export const getLastMapBounds = state => (state.lastSearch ? state.lastSearch.lastMapBounds : {});
export const getLastMapZoom = state => (state.lastSearch ? state.lastSearch.lastMapZoom : {});

// CNS-6165: Get previous routeID for route comparison in goToLastResults
export const getPreviousRouteID = state => {
  const { previous } = state.router;
  // When a user registers through the squeeze form on a property details page after coming from the scout
  // coming from the scout homepage map, the page will experience a server-side render that wipes the
  // `previous` attribute from the router's state tree in our store.
  if (previous) {
    return previous.result.id;
  }
  // On scout, we want to ensue that we get returned to a map results page when a user registers, so we
  // should assume that they were coming from a home route.
  if (utility.currentTheme() === 'wp-scout-theme') {
    return HOME;
  }

  return null;
};

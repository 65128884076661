import React from 'react';
import FAIcon from 'components/core/FAIcon';

export const WORKING_WITH_AN_AGENT_ID = 4;

/**
 * @returns {Array<QualifyingQuestion>}
 */
function getQualifyingQuestions(state) {
  return state.qualifyingQuestions.questions;
}

/**
 * @returns {Number} The 0-based index of what question the user is currently viewing
 */
function getCurrentQuestionIndex(state) {
  return state.qualifyingQuestions.currentQuestion;
}

/**
 * Get the current question being displayed in the QQ modal.
 *
 * Note that when we are testing a different question, `id`s for the question
 * and the choices will be missing.
 */
export function getCurrentQuestion(state) {
  /** @type {boolean} */
  const homeToSellVariant = state.optimize.homeToSellQuestionVariant;
  const index = getCurrentQuestionIndex(state);

  if (homeToSellVariant && index === 2) {
    return {
      icon: <FAIcon icon="home" type="solid" />,
      question: 'Do you own a home?',
      choices: [{ text: 'Yes' }, { text: 'No' }],
    };
  }

  // Ensure that we don't show "Are you currently working with an Agent?"
  return getQualifyingQuestions(state).filter(q => q.id !== WORKING_WITH_AN_AGENT_ID)[index];
}

export function getQuestionByChoiceID(state, choiceID) {
  const questions = getQualifyingQuestions(state);
  return questions.find(q => q.choices.find(c => c.id === choiceID) !== undefined);
}

/**
 * Get the state needed for the qualifying questions modal.
 */
export default function getQualifyingQuestionsModalProps(state) {
  const currentQuestion = getCurrentQuestion(state);
  const questionProps = currentQuestion || {};

  return {
    ...questionProps,
    // Don't open the modal until the data that it requires has been fetched
    show: state.qualifyingQuestions.isModalOpen && currentQuestion !== undefined,
  };
}

import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import scrollToTop from 'hoc/scrollToTop';
import * as OffCanvasActions from 'actions/OffCanvasActions';

// Selectors
import { getSearchCount, getIsFetchingCount } from 'reducers/OffCanvasSearch/menu';
import { getPendingSearch, getPendingTags } from 'models/search/reducers/pending/selectors';

import OffCanvas from './OffCanvas';

/**
 * The footer is hidden on some pages, if this component gets its own route
 * we can address this with css / bodyClasses like we do for other routes
 *
 * @see /css/src/flagship-framework/listings.less
 */

export default compose(
  connect(
    state => {
      const { searchFields, account, specialRules } = state;
      return {
        searchFields,
        account,
        offCanvasSearch: {
          menu: {
            searchcount: getSearchCount(state),
            fetchingCount: getIsFetchingCount(state),
          },
          pendingSearch: getPendingSearch(state),
        },
        searchTags: getPendingTags(state),
        specialRules,
      };
    },
    dispatch => ({ OffCanvasActions: bindActionCreators(OffCanvasActions, dispatch) })
  ),
  scrollToTop
)(OffCanvas);

import { createReducer } from 'redux-act';
import { loadSelected, removeFromFavorites, loadingFavorites, loadFavorites } from 'actions/ComparisonTableActions';

export default createReducer({
  /**
   * loadingFavorites
   *
   * About to make an API request for a user's favorite listings.
   * Set `isLoading` property to true so any components subscribing
   * to this store can render UI related to loading state.
   *
   * @param {object} state - The current state
   * @return {object} The new state with isLoading true
   */
  [loadingFavorites]: state => ({
    ...state,
    isLoading: true,
  }),

  /**
   * loadFavorites
   *
   * Is fired after receiving an API response for a user's favorite listings.
   * This function receives an array of gallery listing objects beefed up.
   * It is also tasked with flipping the loading state to false.
   *
   * @param {object} state - The current state
   * @param {array} favorites - Array of listing objects
   * @return {object} The new state with favorites and isLoading false
   */
  [loadFavorites]: (state, favorites) => ({
    ...state,
    favorites,
    isLoading: false,
  }),

  /**
   * loadSelected
   *
   * Array of listing ID's pulled from URL; `?id=12345,54321`
   * These ID's represent the listings a user would like to compare.
   * The state is consumed by <SelectionGrid> component in <FavoritesGallery>
   *
   * @param {object} state - The current state
   * @param {array} listingId - Array of listing ID's, could also be an empty array
   * @return {object} The new state with updated selected array
   */
  [loadSelected]: (state, selected) => ({
    ...state,
    selected,
  }),

  /**
   * removeFromFavorites
   *
   * User has toggled a listing to unfavorite it, so we are
   * now tasked with removing that listing from the current state.
   *
   * @param {object} state - The current state
   * @param {int} listingId - The action payload, in this case a listing id
   * @return {object} The new state
   */
  [removeFromFavorites]: ({ favorites, selected }, listingId) => ({
    favorites: favorites.filter(listing => listing._ID !== parseInt(listingId, 10)),
    selected: selected.filter(id => id !== parseInt(listingId, 10)),
  }),
}, {
  /**
   * Default Favorites Gallery State
   *
   * favorites - An array of gallery listing objects beefed up to populate <FavoritesGallery>
   * selected - An array of listing ID's to populate <SelectionGrid>
   * isLoading - Boolean for UI updates around API requests
   */
  favorites: [],
  selected: [],
  isLoading: false,
});

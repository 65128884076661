import { createSelector } from 'reselect';
import { createDeepEqualSelector, localizeSelector } from 'reducers/util';
import { SearchConverter } from 'models/search/services';
import Tags from 'backbone/Collections/tags';
import Tag from 'backbone/Model/tag';

/**
 * Selectors for `search.pending` branch of state
 */
const localSelector = localizeSelector('search.pending');

/**
 * @function getPendingSearch
 * `state` in the fn passed to `localSelector()` will be the equivalent of `state.search.pending`
 *
 * @param {Object} state The global state object
 * @returns {Object} The pending search obj
 */
export const getPendingSearch = createDeepEqualSelector(
  localSelector(state => state),
  pendingSearch => pendingSearch
);

/**
 * @function getPendingSearch
 *
 * Get the tag count for a committed search.
 *
 * @uses createDeepEqualSelector
 * @note Still using a static instance of the `Tags` collection here as opposed to mirgrating the
 * `createTags` method elsewhere. Once we land on a final solution for tags, then we use an updated
 * method for creating tags.
 *
 * @param {Object} state The global state object
 * @returns {Number} The length of the `tags` array created from the committed search obj
 */
export const getPendingTags = createSelector(
  getPendingSearch,
  pendingSearch => {
    const pending = SearchConverter.mapPendingToCommitted(
      pendingSearch,
      { removeDefaults: true, removeSort: true }
    );
    const tags = Tags.createTags(pending);

    if (typeof tags === 'undefined') {
      return [];
    }

    return tags.map(tag => {
      const { prop, value, displayName } = new Tag(tag).toJSON();
      return { prop, value, displayName };
    });
  }
);

/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-act';

/**
 * Used to the pan to the user's location on the map when the `latlonrad` search filter is
 * applied via the Search bar's "Nearby Properties" in the Native App.
 *
 * @param {{lat: Number, lng: Number}} coords
 */
export const panToUserForNearbySearch = createAction(
  'Pan To User For Nearby Search',
  coords => coords
);

export const selectNativeNearbySearch = createAction(
  'Native View > Search Nearby Properties > Select Nearby Search'
);

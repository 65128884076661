import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'redux-little-router';
import { config } from 'BoomTown';
import FAIcon from 'components/core/FAIcon';
import { DEFAULT_SEARCH_STRING } from 'models/search/constants';
import { MOBILE_SAVED_SEARCHES_PAGE } from 'cypress_constants';

const NoResults = (props) => {
  const {
    isFavorites,
    isSaveSearchVisible,
    isNearbyActive,
    shouldStartNewSearch,
    onStartNewSearchClick,
    onSaveSearchClick,
    dataCY,
  } = props;

  const handleSaveSearchClick = (e) => {
    e.preventDefault();
    onSaveSearchClick();
  };

  return (
    <div className="uk-text-muted text-xs--center bt-results__none js-results__none px-12 py-16 mx-auto" data-cy={dataCY}>
      <FAIcon icon="meh" type="solid" className="bt-results__none__icon" />
      {isFavorites ? (
        <div>
          <div className="bt-heading__h3 mb-4">
            Oops! You haven&apos;t added any properties to your favorites.
          </div>
          <div className="bt-heading__h4">Go ahead and start a new search to find your dream home!</div>
        </div>
      ) : (
        <div className="bt-heading__h3">Oops! No Results Have Been Found.</div>
      )}

      {isNearbyActive && (
        <>
          <div className="bt-heading__h4">
            Try adjusting the position of the map to broaden your search.
          </div>

          {isSaveSearchVisible && (
            <div className="bt-heading__h3 mt-4">OR</div>
          )}
        </>
      )}

      {!isSaveSearchVisible && (
        <div className="bt-heading__h4">
          <a onClick={handleSaveSearchClick} role="button" tabIndex={0}>Save this search</a>
          {' to get alerts when properties matching your criteria become available.'}
        </div>
      )}

      {shouldStartNewSearch && <StartNewSearch onLinkClick={onStartNewSearchClick} />}
    </div>
  );
};

NoResults.propTypes = {
  isSaveSearchVisible: PropTypes.bool,
  shouldStartNewSearch: PropTypes.bool,
  isFavorites: PropTypes.bool,
  isNearbyActive: PropTypes.bool,
  onStartNewSearchClick: PropTypes.func,
  onSaveSearchClick: PropTypes.func,
  dataCY: PropTypes.string,
};

NoResults.defaultProps = {
  isSaveSearchVisible: true,
  shouldStartNewSearch: false,
  isFavorites: false,
  isNearbyActive: false,
  onStartNewSearchClick: () => {},
  onSaveSearchClick: () => {},
  dataCY: null,
};

/**
 * Start New Search UI for the NoResults view. Renders some text and a link directing the user
 * to either the gallery or search menu in order to reset the search experience.
 */
const StartNewSearch = ({ onLinkClick }) => {
  /**
   * Determines whether to render a Link component from `redux-little-router` or a simple anchor tag
   * with an `onClick` handler for the Native App.
   */
  const renderLink = () => {
    const linkText = 'start a new search';

    if (config.isMobileApp) {
      const onClick = (e) => {
        e.preventDefault();
        onLinkClick();
      };

      return (
        <a href="#" data-cy={MOBILE_SAVED_SEARCHES_PAGE.START_A_NEW_SEARCH} onClick={onClick}>
          {linkText}
        </a>
      );
    }

    return <Link href={`/results-gallery/${DEFAULT_SEARCH_STRING}`}>{linkText}</Link>;
  };

  // Can't use a template string for the whole message here,
  // as renderLink() returns an object, which gets stringified
  // to "[object Object]" when rendered.
  return (
    <div className="bt-heading__h4 pt-12">
      {'Go ahead and '}
      {renderLink()}
      {' to find your dream home!'}
    </div>
  );
};

StartNewSearch.propTypes = {
  onLinkClick: PropTypes.func,
};

StartNewSearch.defaultProps = {
  onLinkClick: () => {},
};

export default NoResults;

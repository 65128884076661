import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';
import FAIcon from 'components/core/FAIcon';

class Collapsible extends Component {
  constructor(props) {
    super(props);
    this.innerRef = React.createRef();

    // Defaults the dropdown to be closed
    if (props.open) {
      this.state = {
        isOpen: true,
        shouldSwitchAutoOnNextCycle: false,
        height: 'auto',
        transition: 'none',
        hasBeenOpened: true,
        overflow: props.overflowWhenOpen,
        inTransition: false,
      };
    } else {
      this.state = {
        isOpen: false,
        shouldSwitchAutoOnNextCycle: false,
        height: 0,
        transition: `height ${props.transitionTime}ms ${props.easing}`,
        hasBeenOpened: false,
        overflow: 'hidden',
        inTransition: false
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.shouldOpenOnNextCycle) {
      this.continueOpenCollapsible();
    }

    if (prevState.height === 'auto' && this.state.shouldSwitchAutoOnNextCycle === true) {
      window.setTimeout(() => { // Set small timeout to ensure a true re-render
        this.setState({
          height: 0,
          overflow: 'hidden',
          isOpen: false,
          shouldSwitchAutoOnNextCycle: false
        });
      }, 50);
    }

    // If there has been a change in the open prop (controlled by accordion)
    if (prevProps.open !== this.props.open) {
      if (this.props.open === true) {
        this.openCollapsible();
      } else {
        this.closeCollapsible();
      }
    }
  }

  closeCollapsible = () => {
    this.setState({
      shouldSwitchAutoOnNextCycle: true,
      height: this.innerRef.current.offsetHeight,
      transition: `height ${this.props.transitionTime}ms ${this.props.easing}`,
      inTransition: true
    });
  }

  openCollapsible = () => {
    this.setState({
      inTransition: true,
      shouldOpenOnNextCycle: true
    });
  }

  continueOpenCollapsible = () => {
    this.setState({
      height: this.innerRef.current.offsetHeight,
      transition: `height ${this.props.transitionTime}ms ${this.props.easing}`,
      isOpen: true,
      hasBeenOpened: true,
      inTransition: true,
      shouldOpenOnNextCycle: false,
    });
  }

  handleTriggerClick = (event) => {
    event.preventDefault();

    if (this.props.triggerDisabled) {
      return;
    }

    if (this.props.handleTriggerClick) {
      this.props.handleTriggerClick(this.props.accordionPosition);
    } else if (this.state.isOpen === false) {
      this.openCollapsible();
      this.props.onOpening();
    } else {
      this.closeCollapsible();
      this.props.onClosing();
    }
  }

  handleTransitionEnd = () => {
    // Switch to height `auto` to make the container responsive
    if (this.state.isOpen) {
      this.setState({
        height: 'auto',
        overflow: this.props.overflowWhenOpen,
        inTransition: false
      });
      this.props.onOpen();
    } else {
      this.setState({ inTransition: false });
      this.props.onClose();
    }
  }

  render() {
    const dropdownStyle = {
      height: this.state.height,
      WebkitTransition: this.state.transition,
      msTransition: this.state.transition,
      transition: this.state.transition,
      overflow: this.state.overflow
    };

    // If user wants different text when tray is open
    const trigger = (
      (this.state.isOpen === true) && (this.props.triggerWhenOpen !== undefined) ?
        this.props.triggerWhenOpen : this.props.trigger
    );

    // Don't render children until the first opening of the Collapsible if lazy rendering is enabled
    const children = this.props.lazyRender
      && !this.state.hasBeenOpened
      && !this.state.isOpen
      && !this.state.inTransition ? null : this.props.children;

    return (
      <CollapsibleWrapper
        id={this.props.id}
        dataCY={this.props.dataCY}
        className={this.props.className}
        closedClassName={this.props.closedClassName}
        openedClassName={this.props.openedClassName}
        isOpen={this.state.isOpen}
        borderless={this.props.borderless}
        hidden={this.props.hidden}
      >
        <CollapsibleTrigger
          className={this.props.triggerClassName}
          flushSides={this.props.flushSides}
          isOpen={this.state.isOpen}
          isDisabled={this.props.triggerDisabled}
          onClick={this.handleTriggerClick}
          trigger={trigger}
          noIcon={this.props.noIcon}
          dataCY={this.props.dataCY}
        />
        <CollapsibleContent
          outerClassName={this.props.contentOuterClassName}
          innerClassName={this.props.contentInnerClassName}
          dropdownStyle={dropdownStyle}
          handleTransitionEnd={this.handleTransitionEnd}
          flushSides={this.props.flushSides}
          ref={this.innerRef}
        >
          {children}
        </CollapsibleContent>
      </CollapsibleWrapper>
    );
  }
}

// If no transition time or easing is passed then default to this
Collapsible.defaultProps = {
  transitionTime: 300,
  easing: 'ease',
  open: false,
  triggerDisabled: false,
  lazyRender: false,
  overflowWhenOpen: 'hidden',
  onOpen: () => {},
  onClose: () => {},
  onOpening: () => {},
  onClosing: () => {},
  flushSides: false,
  noIcon: false,
  hidden: false,
};

Collapsible.propTypes = {
  open: PropTypes.bool,
  transitionTime: PropTypes.number,
  easing: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onOpening: PropTypes.func,
  onClosing: PropTypes.func,
  lazyRender: PropTypes.bool,
  dataCY: PropTypes.string,
  overflowWhenOpen: PropTypes.oneOf([
    'hidden',
    'visible',
    'auto',
    'scroll',
    'inherit',
    'initial',
    'unset',
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),

  // Wrapper
  id: PropTypes.string,
  openedClassName: PropTypes.string,
  closedClassName: PropTypes.string,
  className: PropTypes.string,
  borderless: PropTypes.bool,
  hidden: PropTypes.bool,

  // Trigger
  triggerClassName: PropTypes.string,
  noIcon: PropTypes.bool,
  flushSides: PropTypes.bool,
  handleTriggerClick: PropTypes.func,
  trigger: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.element
  ]),
  triggerWhenOpen: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  triggerDisabled: PropTypes.bool,
  accordionPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  // Content
  contentOuterClassName: PropTypes.string,
  contentInnerClassName: PropTypes.string,
};
export default Collapsible;

/**
 * Collapsible Wrapper
 *
 * Used to render the main wrapping `div` surrounding the Collapsible element. Applies
 * the `collapsible` CSS class.
 */
const CollapsibleWrapper = props => (
  <div
    id={props.id}
    data-cy={props.dataCY}
    className={cx(
      'collapsible',
      props.className,
      {
        'collapsible--borderless': props.borderless,
        [`${props.closedClassName}`]: !props.isOpen && props.closedClassName,
        [`${props.openedClassName}`]: props.isOpen && props.openedClassName,
      }
    )}
    hidden={props.hidden}
  >
    {props.children}
  </div>
);
CollapsibleWrapper.propTypes = {
  id: PropTypes.string,
  dataCY: PropTypes.string,
  className: PropTypes.string,
  closedClassName: PropTypes.string,
  openedClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  borderless: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
  hidden: PropTypes.bool,
};

/**
 * Collapsible Trigger
 *
 * Used to render the clickable element that triggers the opening and closing of a Collapsible
 * component. Applies the `collapsible__trigger` CSS class.
 */
const CollapsibleTrigger = props => (
  <div
    onClick={props.onClick}
    data-cy={props.dataCY}
    className={cx(
      'collapsible__trigger',
      props.className,
      {
        'collapsible__trigger--flush-sides': props.flushSides,
        'is-disabled': props.isDisabled,
        'is-open': props.isOpen,
        'is-closed': !props.isOpen,
      }
    )}
  >
    {typeof props.trigger === 'function' ? props.trigger(props) : props.trigger}
    {props.noIcon || (props.isOpen && <FAIcon icon="angle-up" type="regular" />) || (!props.isOpen && <FAIcon icon="angle-down" type="regular" />)}
  </div>
);
CollapsibleTrigger.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  dataCY: PropTypes.string,
  isOpen: PropTypes.bool,
  isDisabled: PropTypes.bool,
  flushSides: PropTypes.bool,
  noIcon: PropTypes.bool,
  trigger: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.string,
  ]),
};

/**
 * Collapsible Content
 * Uses ref forwarding to provide the parent component access to the `collapsible__inner` element
 * for setting the correct height in state.
 * @see https://reactjs.org/docs/forwarding-refs.html#forwarding-refs-to-dom-components
 */
const CollapsibleContent = React.forwardRef((props, ref) => (
  <div
    className={props.outerClassName}
    style={props.dropdownStyle}
    onTransitionEnd={props.handleTransitionEnd}
  >
    <div
      ref={ref}
      className={cx(
        'collapsible__inner',
        props.innerClassName,
        { container: !props.flushSides },
      )}
    >
      {props.children}
    </div>
  </div>
));
CollapsibleContent.displayName = 'CollapsibleContent';
CollapsibleContent.propTypes = {
  outerClassName: PropTypes.string,
  innerClassName: PropTypes.string,
  dropdownStyle: PropTypes.object,
  handleTransitionEnd: PropTypes.func,
  flushSides: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
};

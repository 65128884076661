import BaseModel from 'legacy/Base/model';
import bt from 'BoomTown';
import _ from 'underscore';

export default class Agent extends BaseModel {
  get idAttribute() {
    return '_ID';
  }

  initialize() {
    this.beefUp(this.attributes);
  }

  /**
   * Override Backbone.Model.prototype.set so that we can evaluate computed properties.
   *
   * NOTE: the options override seems unsafe, maintained from decafination
   *
   * @param {String|Object} key
   * @param {*} val
   * @param {Object} options
  */
  set(key, val, options) {
    // Handle both `"key", value` and `{key: value}` -style arguments.
    let attrs = {};
    if (typeof key === 'object') {
      attrs = key;
      /* eslint-disable no-param-reassign */
      options = val;
      /* eslint-enable */
    } else {
      attrs[key] = val;
    }

    this.beefUp(_.extend({}, this.attributes, attrs));
    return super.set(attrs, options);
  }

  beefUp(attrs) {
    if (attrs.FirstName != null) {
      this.attributes.FullName = `${attrs.FirstName} ${attrs.LastName}`;

      const staticHost = bt.config.staticHost;
      const tenantAlias = bt.account.get('Alias');
      /* eslint-disable max-len */
      const noImage = '/wp-content/themes/wp-base-theme/assets/media/build/agent.svg';
      /* eslint-enable */

      this.attributes.PhotoUrl = (attrs.PhotoLink != null) ? `//${staticHost}/content/tenant/${tenantAlias}/agent/${attrs.PhotoLink}` : noImage;

      this.attributes.Slug = bt.utility.slugify(`${attrs._ID} ${attrs.FirstName} ${attrs.LastName}`);
    }
  }
}

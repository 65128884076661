/* eslint react/jsx-quotes: [0] */
import React from 'react';
import { GENERAL_UI } from 'cypress_constants';

const ListingResultsSpinner = () => (
  <div
    className="cell bt-results__loading"
    data-cy={GENERAL_UI.RESULTS_SPINNER}
  />
);
ListingResultsSpinner.displayName = 'ListingResultsSpinner';

export default ListingResultsSpinner;

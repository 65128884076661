import PropTypes from 'prop-types';
import React from 'react';
import { PrimaryButton } from 'coreComponents';
import { HOMEPAGE_MODALS } from 'cypress_constants';

const OneClickModal = ({ onClose }) => (
  <div className="text-xs--center" data-cy={HOMEPAGE_MODALS.ONECLICK}>
    <div className="bt-heading__h3 mb-16">
      In agreement with our{' '}
      <a href="/terms" target="_blank">Terms of Service</a> and{' '}
      <a href="/privacy" target="_blank">Privacy Policy</a>.
    </div>
    <PrimaryButton
      className="px-16"
      width="full"
      onClick={onClose}
      dataCY={HOMEPAGE_MODALS.ONECLICK_CLOSE}
    >
      Accept & Continue
    </PrimaryButton>
    <p className="font-size--12">
      <strong>Disclaimer: </strong>
      By clicking "Accept & Continue" you agree to our <a href="/terms" target="_blank">Terms of Service</a>{' '}
      and <a href="/privacy" target="_blank">Privacy Policy</a> and that you may be contacted by phone,{' '}
      text message, and email about your inquiry.
    </p>
  </div>
);

OneClickModal.propTypes = {
  onClose: PropTypes.func,
};

export default OneClickModal;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FiltersAppliedBadge from 'components/OffCanvas/FiltersAppliedBadge';
import TagList from 'components/Common/Tags/TagList';

import emptyFunction from 'utility/emptyFunction';
import { CCompToggle } from 'components/Common/Dropdown';

/**
 * Displays the current search selected on the Off Canvas search menu using
 * search tags. Comprised of a ccomp dropdown showing the number of applied
 * filters, with a list of tag badges inside.
 */
export default class TagBar extends Component {
  /**
   * When the number of tags displayed goes from positive to zero, collapse
   * the dropdown.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.searchTags.length > 0 && nextProps.searchTags.length === 0) {
      this.closeCComp();
    }
  }

  closeRef = fn => (this.closeCComp = fn);

  handleTagClick = (e, data) => this.props.onTagClick(data);

  render() {
    const { searchTags } = this.props;

    return (
      <div className="bt-filter-bar__components">
        <div>
          <CCompToggle
            disabled={searchTags.length === 0}
            getClose={this.closeRef}
            heading={
              <span>
                Filters Applied:&nbsp;
                <FiltersAppliedBadge count={searchTags.length} />
              </span>
            }
          >
            <div className="bt-ccomp__content__inner">
              <div className="pb-16 px-16">
                <TagList
                  tags={searchTags}
                  className="mt-0 bt-search-tags js-search-tags"
                  onTagClick={this.handleTagClick}
                />
              </div>
            </div>
          </CCompToggle>
        </div>
      </div>
    );
  }
}

TagBar.displayName = 'TagBar';
TagBar.defaultProps = {
  onTagClick: emptyFunction
};
TagBar.propTypes = {
  searchTags: PropTypes.arrayOf(
    PropTypes.shape({
      prop: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      displayName: PropTypes.string,
    }),
  ),
  onTagClick: PropTypes.func
};

import { createSelector } from 'reselect';
import { config } from 'BoomTown';
import Tags from 'backbone/Collections/tags';
import Tag from 'backbone/Model/tag';
import { createDeepEqualSelector, localizeSelector } from 'reducers/util';

/**
 * @function localSelector
 * Selectors for `search` branch of state
 */
const localSelector = localizeSelector('search');

/**
 * @function _getCommittedSearch
 * `localState` param in the fn passed to `localSelector()` will be the equivalent of
 * `state.search`
 *
 * @protected
 * @param {Object} state The global state object
 * @returns {Object} The committed search obj
 */
const _getCommittedSearch = localSelector(localState => localState.committed);

/**
 * @function getCommittedSearch
 *
 * @param {Object} state The global state object
 * @returns {Object} The committed search obj
 */
export const getCommittedSearch = createDeepEqualSelector(
  _getCommittedSearch,
  committedSearch => committedSearch
);

/**
 * @function getPreviousSearch
 * `localState` param in the fn passed to `localSelector()` will be the equivalent of
 * `state.search`
 *
 * @param {Object} state The global state object
 * @returns {Object} The previous search obj
 */
export const getPreviousSearch = createDeepEqualSelector(
  localSelector(localState => localState.previous),
  previousSearch => previousSearch
);

/**
 * @function getFavsValueFromSearch
 * `localState` param in the fn passed to `localSelector()` will be the equivalent of
 * `state.search`
 *
 * @param {Object} state The global state object
 * @returns {Object} The previous search obj
 */
export const getFavsValueFromSearch = createSelector(
  _getCommittedSearch,
  (committedSearch) => Boolean(committedSearch.favs)
);

/**
 * @function getMapBounds
 * `localState` param in the fn passed to `localSelector()` will be the equivalent of
 * `state.search`
 *
 * @param {Object} state The global state object
 * @returns {MapBounds} The current mapBounds
 */
export const getMapBounds = createDeepEqualSelector(
  localSelector(localState => localState.mapBounds),
  mapBounds => mapBounds
);

/**
 * @function getZoom
 * `localState` param in the fn passed to `localSelector()` will be the equivalent of
 * `state.search`
 *
 * @param {Object} state The global state object
 * @returns {Number} The current zoom value
 */
export const getZoom = localSelector(localState => localState.zoom);

/**
 * @function getBoundsAndZoom
 *
 * @param {Object} state The global state object
 * @returns {{mapBounds: MapBounds, zoom: Number}}
 */
export const getBoundsAndZoom = createSelector(
  [getMapBounds, getZoom],
  (mapBounds, zoom) => ({ mapBounds, zoom })
);

/**
 * @function getSearchWithBounds
 *
 * @param {Object} state The global state object
 * @param {boolean} [includeZoom=true] Whether to include zoom in the returned results
 * @returns {Object} Object containing search params, map bounds, and optionally the zoom value
 */
export const getSearchWithBounds = localSelector((localState, includeZoom = true) => {
  let searchObj = { ...localState.committed };

  if (config.useListMapResultsSync) {
    searchObj = {
      ...searchObj,
      ...(localState.mapBounds ? localState.mapBounds : {}),
      ...(includeZoom ? { zoom: localState.zoom } : {}),
    };
  }

  return searchObj;
});


/**
 * TAGS
 */

/**
  * @function _createTagArray
  * @protected
  *
  * @param {Object} search
  * @param {MapBounds} mapBounds
  * @param {nnumber} zoom
  *
  * @returns {SearchTag[]}
  */
const _createTagArray = (search, mapBounds, zoom) => {
  let queryObj = search;

  // Combine search obj with mapBounds obj. If mapBound params are in search, then create a single
  // "Map Boundaries" tag instead of multiple tags for each coord
  if (config.useListMapResultsSync && mapBounds) {
    queryObj = {
      ...queryObj,
      ...mapBounds,
      zoom, // TODO: Verify if zoom is needed for map bounds tag
    };
  }

  return Tags.createTags(queryObj);
};

/**
 * @function getCommittedTags
 *
 * Get the tag count for a committed search.
 *
 * @uses createDeepEqualSelector
 * @note Still using a static instance of the `Tags` collection here as opposed to mirgrating the
 * `createTags` method elsewhere. Once we land on a final solution for tags, then we use an updated
 * method for creating tags.
 *
 * @param {Object} state The global state object
 * @returns {Number} The length of the `tags` array created from the committed search obj
 */
export const getCommittedTags = createSelector(
  getCommittedSearch,
  getMapBounds,
  getZoom,
  (committedSearch, mapBounds, zoom) => {
    const tags = _createTagArray(committedSearch, mapBounds, zoom);

    if (typeof tags === 'undefined') {
      return [];
    }

    return tags.map(tag => {
      const { prop, value, displayName } = new Tag(tag).toJSON();
      return { prop, value, displayName };
    });
  }
);

/**
 * @function getCommittedTagCount
 *
 * Get the tag count for a committed search.
 *
 * @uses createDeepEqualSelector
 * @note Still using a static instance of the `Tags` collection here as opposed to mirgrating the
 * `createTags` method elsewhere. Once we land on a final solution for tags, then we use an updated
 * method for creating tags.
 *
 * @param {Object} state The global state object
 * @returns {Number} The length of the `tags` array created from the committed search obj
 */
export const getCommittedTagCount = createSelector(
  getCommittedSearch,
  getMapBounds,
  getZoom,
  (committedSearch, mapBounds, zoom) => {
    const tags = _createTagArray(committedSearch, mapBounds, zoom);

    if (typeof tags === 'undefined') {
      return 0;
    }

    return tags.length;
  }
);

/**
 * @function getBaseSearchConstant
 *
 * Get the baseSearch constant to determine if the committed or pending search obj should be used.
 *
 * @param {Object} state
 */
export const getBaseSearchConstant = localSelector(state => state.baseSearch);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { H2 } from 'components/core/Headings';
import Spinner from 'components/ListingResultsSpinner';
import ConnectedEditSavedSearches from '../../Forms/EditSavedSearches';
import Layout from '../Layout';

const EditSavedSearches = ({ isLoading }) => (
  <Layout>
    <React.Fragment>
      <H2>Saved Searches</H2>
      <p>
        Manage all your previous saved searches here. You can click on the title of the saved search
        to view your results, change the frequency of emails you would like to receive, unsubscribe
        or resubscribe to each saved search as well as permanently delete a saved search.
      </p>
      {isLoading ? <Spinner /> : <ConnectedEditSavedSearches isMobile={false} />}
    </React.Fragment>
  </Layout>
);
EditSavedSearches.propTypes = {
  isLoading: PropTypes.bool,
};

export default connect(state => ({
  isLoading: state.accountView.savedSearches.isLoading,
}))(EditSavedSearches);

/* eslint-disable global-require */
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'redux-little-router';
import withModal from 'hoc/withModal';
import { getModalState } from 'models/modals/reducers/selectors';
import { MODAL_IDS, MODAL_STATUS } from 'models/modals/constants';
import OneClickModal from './OneClickModal';

export default compose(
  connect(
    (state) => {
      const { id, status } = getModalState(state);
      return {
        show: status === MODAL_STATUS.OPEN && id === MODAL_IDS.ONE_CLICK
      };
    },
    {
      onClose: () => (dispatch) => dispatch(push({ pathname: '/' })),
    }
  ),
  withModal({ maxWidth: 400 })
)(OneClickModal);

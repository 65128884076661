import { Model } from 'backbone';
import bt from 'BoomTown';
import BaseCollection from 'legacy/Base/collection';
import _ from 'underscore';
import Listing from 'legacy/Model/listing';
import Search from 'legacy/Model/search';
import * as routerUtils from 'legacy/Routing/routerUtils';
import { getTitle, getTitleWithCount } from 'selectors/resultsTitle';
import { isMobile } from 'selectors/browser';

export default class Listings extends BaseCollection {
  constructor(options) {
    super(options);
    this.searchChange = this.searchChange.bind(this);
    this.applyNewResults = this.applyNewResults.bind(this);
    this.applyMeta = this.applyMeta.bind(this);
    this.getTitle = this.getTitle.bind(this);
    this.getTitleWithCount = this.getTitleWithCount.bind(this);
  }

  get model() {
    return Listing;
  }

  PageIndex = 0;
  PageCount = window.bt.listings ? window.bt.listings.PageCount : window.bt.config.pageCount;
  TotalItems = 0;

  /**
   *
   * @type {JQueryXHR?}
   */
  _inflightNewSearch = null;

  /**
   * Additional evented state about this collection and how it fetches data
   * from the API
   */
  state = new Model({
    isLoading: false,
  });

  initialize() {
    super.initialize();
    this.listenTo(window.bt.events, 'change', model => {
      if (model instanceof Search) {
        this.searchChange(model);
      }
    });
  }

  searchChange(model) {
    // eslint-disable-next-line global-require
    const { getState } = require('store').default;

    if (routerUtils.isMapPath() || isMobile(getState())) {
      return;
    }

    const props = model.toJSON();
    // We need to update the title manually for a favorite search
    if (props.favs) {
      this.Meta = { H1Title: 'favorite properties' };
    }

    // We're now initializing the listing coll. with server-side data at
    // instantiation, but this `listings` property is left to indicate the
    // initial route event -> search change chain.
    if (window.bt_data.listings != null) {
      // Used to dispatch `listingsReset` on SSR so we can trigger fbSearch event in GTM
      this.reset(this.models);
      delete window.bt_data.listings;
      return;
    }

    if (this._inflightNewSearch !== null) {
      this._inflightNewSearch.abort();
    }

    this.state.set('isLoading', true);
    this._inflightNewSearch = bt.api
      .ajaxsearch(this, props, this.applyNewResults)
      .always(() => (this._inflightNewSearch = null));
  }

  filterFavorites() {
    const filtered = this.filter(listing => listing.get('IsFavorite'));

    return this.reset(filtered);
  }

  /*
   * Used to replace the result set with a new set
   * the view is listening to the reset event and calling render
  */
  applyNewResults(results) {
    this.applyMeta(results);
    this.reset(
      results.Result.Items.map(obj => {
        const l = new Listing(obj);
        l.beefUp();
        return l;
      })
    );
    this.state.set('isLoading', false); // [aknox] lordy you better trigger this event *after* the reset event
  }

  applyMeta(results) {
    this.PageIndex = results.Result.PageIndex;
    this.PageCount = results.Result.PageCount;

    if (window.bt.search.has('favs')) {
      this.TotalItems = window.bt.visitorDetails.FavoriteCount;
    } else {
      this.TotalItems = results.Result.TotalItems;
    }

    this.Meta = results.Result.MetaInfo;
    this.Status = results.Status;

    if (_.isEmpty(this.Meta) && _.has(window.bt.search.attributes, 'favs')) {
      this.Meta = { H1Title: 'favorite properties' };
    }

    if (_.isEmpty(this.Meta) && _.has(window.bt.search.attributes, 'rid')) {
      this.Meta = { H1Title: 'Properties' };
    }
  }

  /**
   * Get a title string that corresponds to the current result set and the
   * state of the search model. Only consists of the text that follows a number.
   * (_Note: Sets `window.bt.listings.TotalItems` if not set as a side effect._)
   * @return {String}
   */
  getTitle() {
    return getTitle(this, window.bt.search);
  }

  /**
   * Concise way to get the title with a preceding count, without having to
   * build the string yourself.
   *
   * @return {String}
   */
  getTitleWithCount() {
    return getTitleWithCount(this, window.bt.search);
  }
}

import { createReducer } from 'redux-act';
import { cancelLocationRequest, receiveCurrentLocation } from './actions';

/**
 * @type {{ lat: number, lng: number }?}
 */
const initialState = null;

export default createReducer(
  {
    [receiveCurrentLocation]: (state, { lat, lng }) => ({
      lat,
      lng,
    }),
    [cancelLocationRequest]: () => initialState,
  },
  initialState
);

export const getGeolocation = state => state.geolocation;

import { getActiveListing } from 'selectors/listings';
import { select } from 'redux-saga/effects';
import { add } from 'sagas/routerTasks/search';

// We don't have a list of constants anywhere for url / search params
const HOOD = 'hood';
const COUNTY = 'county';
const AREA = 'area';

export function* searchForNeighborhood() {
  yield searchFor(HOOD, l => l.Location.Neighborhood._ID);
}

export function* searchForCounty() {
  yield searchFor(COUNTY, l => l.Location.CountyDetail._ID);
}

export function* searchForArea() {
  yield searchFor(AREA, l => l.Location.Area._ID);
}

/**
 * Select a property off of the active listing, append it to the search in
 * context, and go to the proper results page.
 *
 * @param {string} searchKey Search model/query string key for this attribute
 * @param {Function} getListingAttribute Get this attribute's value on the active listing
 */
function* searchFor(searchKey, getListingAttribute) {
  const { listing } = yield select(getActiveListing);

  yield add({
    [searchKey]: getListingAttribute(listing),
  });
}

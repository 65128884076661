import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

const Loader = ({ className, style }) => <div style={style} className={cx('bt-results__loading', className)} />;

Loader.displayName = 'Loading Icon';
Loader.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Loader;

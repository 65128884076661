import { push } from 'redux-little-router';
import $ from 'jquery';
import UIkit from 'uikit';
import bt from 'BoomTown';
import * as formData from 'constants/registrationForms';
import Listing from 'legacy/Model/listing';
import ListingCardView from 'legacy/Views/Listings/card';
import FlickityView from 'legacy/Views/Listings/flickityView';
import AppRoot from './appRoot';

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
}

export default class AppView extends AppRoot {
  initialize() {
    super.initialize();

    // moved Jan 2015
    this.iOS7Support();
    this.checkForSmallScreens();
    this.makeIframesResponsive();
    this.uiEvents();

    this.initAgentsPage();
    this.initSlidersAndCards();

    setTimeout(() => { return this.initZopim(); }, 1000);
  }

  initQueuedDOMOperations() {
    if (this.squeezeFormQueue.enabled) {
      this.squeezeForm(...this.squeezeFormQueue.args);
    }
  }

  /**
   * Should we circle back around to squeezing them?
   * only execute me once
   */
  squeezeFormQueue = {
    enabled: false,
    args: [],
  };

  /**
   *
   * @param {string} action Relates to a cro action, and then is set as a data attribute on a modal
   * 😮 keep digging to see if this is just for GTM at some point
   * one of; registration, favorites, savedsearch,
   * photoslider, signinform, regformdisplay, shareform
   * @param {bool} showCloseBtn
   * @param {bool} signin
   */
  squeezeForm(action, showCloseBtn, signin) {
    if (!bt.visitor.needsToRegister()) {
      return;
    }

    // Since the squeeze form is technically a login no matter the state (because of social)
    bt.utility.visitorActions.write(bt.utility.visitorActions.actions.LoginViewed);

    // If this squeeze has been kicked off by middleware / sagas then the dom might not be ready
    if (document.getElementById('user-action-modal') === null) {
      // First call to this method wins
      if (!this.squeezeFormQueue.enabled) {
        this.squeezeFormQueue.enabled = true;
        this.squeezeFormQueue.args = [action, showCloseBtn, signin];
      }
      return;
    }

    bt.events.trigger('registerModal', { showCloseBtn });

    $('#user-action-modal .js-sign-in-form').parsley().reset();
    $('#user-action-modal .js-register-form').parsley().reset();

    // TODO: needs comment
    if (signin) {
      // $(".js-regform-nav").data("switcher")?.show 1
      __guard__($('.js-regform-nav').data('switcher'), (x) => { return x.show(1); });
    } else {
      // $(".js-regform-nav").data("switcher")?.show 0
      __guard__($('.js-regform-nav').data('switcher'), (x1) => { return x1.show(0); });
    }

    // if landing page, show x button
    const $closeButton = $('#user-action-modal .uk-modal-close');
    if (showCloseBtn) {
      bt.utility.show($closeButton);
    } else {
      bt.utility.hide($closeButton);
    }

    // set defaults to propform
    const {
      title = 'Want to Compare Homes?',
      subtitle = '',
      urlParam = 'propform',
    } = Object.values(formData).find((type) => { return type.urlParam === action; });

    $('.js-squeeze__title').text(title);
    $('.js-squeeze__subtext').text(subtitle);
    $('.js-squeeze__logo').hide();

    if (action === 'propform' || action === 'photoform') {
      $('.js-squeeze__logo').show();
    }

    if (urlParam) {
      $('#user-action-modal').data('urlparam', urlParam);

      // ignore tracking on signin view
      if (
        $('.js-regform-nav').length > 0 &&
        __guard__($('.js-regform-nav').data('tab'), (x2) => { return x2.switcher.index; }) !== 1
      ) {
        // GTM support
        if (window.dataLayer) {
          const trackingParams = {
            form: urlParam,
            contacttype: bt.visitor.updateContactType('visitor'),
            formtype: null,
            step: 'step1',
          };
          trackingParams.event = 'registration-form-impression';
          window.dataLayer.push(trackingParams);
        }
      }
    }

    // attach action to the second form
    const completeForm = $('#complete-register-modal');
    completeForm.data('firststep', action);
    completeForm.data('urlparam', urlParam);

    $('#user-action-modal').on({
      'hide.uk.modal': function hideUKModal() {
        if (window.location.href.indexOf(bt.config.registrationQueryStringName) > -1) {
          const url =
            location.pathname + location.search.replace(bt.config.registrationQueryStringName, '');

          /* eslint-disable global-require */
          require('store').default.dispatch(push(url));
        }
      },
    });
    const modal = UIkit.modal('#user-action-modal');

    // Add-in .bt-squeeze class for proper generic styling across themes
    modal.find('.uk-modal-dialog').addClass('bt-squeeze');
    modal.options.bgclose = showCloseBtn;
    modal.options.keyboard = showCloseBtn;
    return;
  }

  /**
   * @typedef {object} SocialResponse
   * @property {string} key
   * @property {string} id
   * @property {string} first_name
   * @property {string} last_name
   * @property {string} email
   * @property {string?} error
   * @property {'google' | 'fb'} src
   */

  /**
   * display 2step
   *
   * @param {boolean} showCloseBtn
   * @param {SocialResponse & {visitorID: string}} personObj
   * @param {boolean} isSocialLogin
   * @param {*} stepOneShowCloseBtn
   */
  completeRegForm(showCloseBtn, personObj, isSocialLogin, stepOneShowCloseBtn) {
    // CNS-1284: do not redisplay seller login after reg form is hidden
    // and hide close btn (mandatory two-step)
    const sellerVal = bt.SellerValuation;
    if (sellerVal) {
      sellerVal.modal.toggleModalListeners(sellerVal.modal.loginModal, false);
      sellerVal.modal.toggleModalListeners(sellerVal.modal.modal, false);
    }

    setTimeout(() => {
      let formtype = 'email';

      if (isSocialLogin) {
        if (personObj.src === 'google') {
          // eslint-disable-next-line max-len
          bt.utility.visitorActions.write(bt.utility.visitorActions.actions.GoogleRegistrationViewed);
          bt.events.trigger('isGoogleUserModal');
          formtype = 'google';
        } else {
          // Older facebook flow doesn't have the personObj.src set
          bt.events.trigger('isFacebookUserModal');
          formtype = 'facebook';
          bt.visitor.logAction(null, 'FacebookIncomplete');
        }
      } else {
        bt.events.trigger('completeRegModal', { stepOneShowCloseBtn });
      }

      // GA global tracking
      const trackingParams = {
        form: $('#user-action-modal').data('urlparam'),
        contacttype: bt.visitor.updateContactType('registrant'),
        formtype,
        step: 'step2',
      };

      // GTM hit
      if (window.dataLayer) {
        trackingParams.event = 'registration-form-step2-impression';
        window.dataLayer.push(trackingParams);
      }

      // WP logging
      $.get(`/visitor/step1/?email=${personObj.email}`);

      // if landing page, show x button
      const $closeButton = $('#complete-register-modal .uk-close');
      if (showCloseBtn) {
        bt.utility.show($closeButton);
      } else {
        bt.utility.hide($closeButton);
      }

      const completeForm = $('#complete-register-modal');

      // Social sign in
      if (personObj.id) {
        // Tenant specific fb app doesn't have `.src` set
        const key = personObj.src === 'google' ? 'googleUserID' : 'fbuserid';
        completeForm.data(key, personObj.id);

        // TODO: DOES THIS DO ANYTHING
        $('#user-action-modal').data('isfacebookuser', true);
      } else {
        $('#user-action-modal').data('isfacebookuser', false);
      }

      // fill in fields
      completeForm.find('[name=first_name]').val(personObj.first_name);
      completeForm.find('[name=last_name]').val(personObj.last_name);

      const emailField = completeForm.find('[name=email]');

      const $fbWarning = $('.js-fb-email-warning');
      const $fbSuccess = $('.js-fb-email-success');

      if (personObj.email) {
        emailField.val(personObj.email);
        bt.utility.hide($fbWarning);
        bt.utility.show($fbSuccess);
      } else {
        emailField.removeAttr('disabled').focus();
        bt.utility.hide($fbSuccess);
        bt.utility.show($fbWarning);
      }

      completeForm.find('.js-alert-messages-container').html('').addClass('uk-hidden');
      const modal = UIkit.modal('#complete-register-modal');
      modal.options.bgclose = false;
      modal.options.keyboard = false;

      // a hack to init parsley for the phone real time validation
      const parent = $('.js-complete-register-form');
      bt.utility.validateParsley(parent[0]);
      $('.js-fb-phone').parsley().validate();
      parent.parsley().reset();

      // email real time validation
      emailField.parsley().validate();
      emailField.parsley().on('field:error', () => {
        bt.utility.hide($fbSuccess);
        bt.utility.show($fbWarning);
      });

      return emailField.parsley().on('field:success', () => {
        bt.utility.hide($fbWarning);
        bt.utility.show($fbSuccess);
      });
    }, 400);
  }

  /* eslint-disable no-undef, prefer-template */
  initZopim() {
    if (typeof $zopim !== 'undefined' && $zopim.livechat && bt.visitor.isRegistered()) {
      try {
        $zopim(() => {
          $zopim.livechat.setName(bt.visitorDetails.FirstName + ' ' + bt.visitorDetails.LastName);
          $zopim.livechat.setEmail(bt.visitorDetails.Username);
          $zopim.livechat.setNotes(`Phone number to contact:${bt.visitorDetails.BestPhone}`);
        });
      } catch (err) {
        bt.global.log('Zopim error');
      }
    }
  }
  /* eslint-enable */

  /**
   * CNS-622: iOS7 Support
   * html class is added to revert -webkit-overflow-scrolling to "auto" on iOS7
   */
  iOS7Support() {
    if (navigator.whoami === 'Safari/7') {
      $('html').addClass('device__iOS7');
    }
  }

  // @todo Might be able to remove this completely. I don't see where we're using `is-small-screen`
  // class anywhere
  checkForSmallScreens() {
    // Determine if we're on a smaller screen < 768
    function btCheckScreenSize() {
      if (bt.utility.MQ_Medium()) {
        $('html').removeClass('is-small-screen');
      } else {
        $('html').addClass('is-small-screen');
      }
    }

    window.addEventListener('load', btCheckScreenSize);
    bt.utility.optimizedResize.add(() => { return btCheckScreenSize(); });
  }

  makeIframesResponsive() {
    if (!bt.utility.MQ_Medium()) {
      $('#inner-viewport').fitVids({ ignore: '.wp-block-embed__wrapper' });
    }
  }

  uiEvents() {
    // CNS-815: Make sure we fire datepicker on touch devices, nconsistent without this
    // @todo We no longer use `data-uk-datepicker`, so this can be removed
    $(document).on('touchstart', '[data-uk-datepicker]', () => { return $(this).trigger('focus'); });

    // Full-card clicks for teasers like posts and teasers
    $(document).on('click', '[data-link]', function onDataLinkClick() {
      const link = $(this).data('link');
      window.location.href = link;
    });

    // Make sure internal links still go their respective destinations
    $(document).on('click', '[data-link] a', (e) => { return e.stopPropagation(); });

    // DEPRECATED: just call refresh on your own
    bt.events.on('viewRendered', bt.lazyload.refresh);

    // Add keypress event on every link where role="button"
    $(document).on('keypress', 'a[role="button"]', null, this.onKeypressButton, false);

    // Select all anchors that are acting as buttons
    // Loop through them and add tabindex to keep them from focusing
    const anchorButtons = document.querySelectorAll("a[role='button'].disabled");
    let i = 0;
    while (i < anchorButtons.length) {
      anchorButtons[i].setAttribute('tabindex', '-1');
      // eslint-disable-next-line no-plusplus
      ++i;
    }
  }

  /**
   * Keypress event for space bar
   * @param {Event} e
   */
  onKeypressButton(e) {
    // Need both, 'keyCode' and 'which' to work in all browsers.
    const code = e.keyCode || e.which;
    const spaceKey = 32;

    // If user press space key:
    if (code === spaceKey) {
      e.preventDefault();
      window.location = e.target.href;
      e.stopImmediatePropagation();
    }
  }

  /**
   * Agents
   * Hide alpha index items that do not have agents
   */
  initAgentsPage() {
    $('.js-agents-alpha-index li > a').each(() => {
      const $this = $(this);
      const alphaIndexTarget = $this.attr('href').substring(1);
      if (!$(`#${alphaIndexTarget}`).length) {
        bt.utility.hide($this.parent('li'));
      }
    });
  }

  /**
   * Instantiate `FlickityView`s and `ListingCardView`s for all elements
   * outside of the scope of our client-side app. Those should be handled by
   * views/components.
   */
  initSlidersAndCards() {
    $('.js-card-slider:not(#inner-viewport *)').each((index, element) => { return new FlickityView({ el: $(element) }); });

    $('.js-card:not(#inner-viewport *)').each((i, card) => {
      const model = new Listing({ _ID: $(card).data('listingid') });
      /* eslint-disable no-new */
      new ListingCardView({
        model,
        inCollection: false,
        el: $(card),
        renderUI: false,
      });
    });
  }
}

import { createReducer } from 'redux-act';
import { LOCATION_CHANGED } from 'redux-little-router';
import { ACCOUNT, CONTACT_AGENT } from 'routes';
import {
  isResModalOpen,
  isSubmitSuccess,
  isSubmitInProgress,
  isVideoTour,
  isInPersonTour,
} from 'reducers/Mobile/contactAgent';
import { getActiveListing, getAddressLineTwo } from 'selectors/listings';
import { getRouteID } from 'selectors/router';
import * as ContactAgentActions from './actions';

/**
 * @typedef AgentForContact {Object}
 * @prop {'invalid'|'valid'|'failed'} state The state of the fetch to get the agent for display.
 * @prop {Object|null} agent The agent for display. Should be `null` whenever the agent is invalidated.
 */

/** @type {AgentForContact} */
const initialState = {
  state: 'invalid',
  agent: null
};

const handlers = {
  [ContactAgentActions.receiveAgentForContactFromAPI]: (state, agent) => ({
    ...state,
    agent,
    state: 'valid',
  }),
  [ContactAgentActions.receiveAgentForContactFromAPIFailure]: state => ({
    ...state,
    agent: null,
    state: 'failed'
  }),

  // Nullify the agent for display on all route changes, except ones that reload
  // this route
  [LOCATION_CHANGED]: (state, payload) => {
    const isRouteChangeToSameRoute = Boolean(
      payload.result && (payload.result.id === CONTACT_AGENT || payload.result.id === ACCOUNT)
    );

    if (isRouteChangeToSameRoute) {
      return { ...state };
    }

    return initialState;
  },
};

export default createReducer(handlers, initialState);

/**
 * Selectors
 */
export const getAgentForContact = (state, agentModel) => {
  if (agentModel) {
    return {
      agent: agentModel.toJSON(),
      state: 'valid'
    };
  }

  return state.agentForContact;
};

/**
 * Get the state for the Mobile XP contact agent modal
 *
 * @param {Object} state
 * @param {Backbone.Model} agentModel
 * @param {Backbone.Collection} listingsColl
 */
export const getContactAgentState = (state, agentModel, listingsColl) => ({
  isResModalOpen: isResModalOpen(state),
  isSuccess: isSubmitSuccess(state),
  isSubmitting: isSubmitInProgress(state),
  isVideoTour: isVideoTour(state),
  isInPersonTour: isInPersonTour(state),

  // No need for initial body text in the comments input field if we are in the Contact Agent Modal
  bodyInitialText:
    getRouteID(state) === CONTACT_AGENT
      ? ''
      : getBodyInitialText(state, listingsColl),
  agentInfo: getAgentForContact(state, agentModel)
});

/**
 * Get the placeholder text to be used in the body field of the contact agent form.
 *
 * @param {Object} state
 * @param {Backbone.Collection} listingsColl
 */
const getBodyInitialText = state => {
  const { listing } = getActiveListing(state);
  if (!listing) {
    return '';
  }
  if (listing.HideAddress) {
    return `I am interested in ${listing.Location.FormattedAddress}.`;
  }

  const { Location: location } = listing;
  if (!location) {
    return '';
  }

  let title = [
    location.StreetNumber,
    location.StreetDirPrefix,
    location.StreetName,
    location.StreetDirSuffix,
  ]
    .filter(x => typeof x === 'string' && x.trim() !== '')
    .join(' ');

  if (location.UnitNumber) {
    title += `, ${location.UnitNumber}`;
  }

  const address = getAddressLineTwo({ Location: location });
  title += `, ${address}`;

  return `I am interested in ${title}.`;
};

import { connect } from 'react-redux';
import { getSiteMenuItems, getTitle } from 'selectors/siteMenu';
import * as actions from './actions';

export default connect(
  state => ({
    items: getSiteMenuItems(state),
    title: getTitle(state),
  }),
  {
    onBackButtonClick: actions.clickBackOnSubMenu,
    onMenuItemClick: actions.clickNonLeafMenuItem,
  }
);

import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/core/Card';
import Collapsible from 'components/Common/Collapsible';

/**
 * Toggle Card
 * Uses the Card and Collapsible components to create a collapsible card that can be toggled
 * opened or closed
 * @param {Object} props
 * @param {string} props.className
 * @param {string} props.title The title to display on the clickable Collapsible Trigger
 * @param {boolean} props.muted Prevents clicks on the card and sets opacity to half
 * @param {boolean} props.defaultOpen Set the ToggleCard to default to the open state
 * @param {any} props.children
 */
const ToggleCard = ({ className, title, children, dataCY, muted, defaultOpen }) => (
  <Card className={className} muted={muted} data-cy={dataCY}>
    <Collapsible
      trigger={<h2 className="bt-heading__h4 mb-0">{title}</h2>}
      open={defaultOpen}
      borderless
      data-cy={dataCY}
    >
      {children}
    </Collapsible>
  </Card>
);

ToggleCard.propTypes = {
  className: PropTypes.any,
  title: PropTypes.string,
  children: PropTypes.node,
  muted: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  dataCY: PropTypes.string,
};

ToggleCard.defaultProps = {
  title: '',
};

export default ToggleCard;

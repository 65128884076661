/* eslint-disable indent */
import { connect } from 'react-redux';
import { getTextPrefsFormState, isSubmittingTextPrefs, getTextResponseModal } from './reducer';
import * as textPrefsActions from './actions';

export default connect(
  state => {
    const textPrefState = getTextPrefsFormState(state);
    return {
      textAllowed: Boolean(textPrefState) && textPrefState.textAllowed,
      isSubmitting: isSubmittingTextPrefs(state),
      ...getTextResponseModal(state)
    };
  },
  {
    onSubmit: textPrefsActions.submitTextPreferencesForm,
    onToggleText: textPrefsActions.toggleTextAllowed,
    onClickBackInRespModal: textPrefsActions.clickBackInTextPrefsFailureModal,
    onClickRetryInRespModal: textPrefsActions.clickRetryInTextPrefsFailureModal,
    onClickCloseInRespModal: textPrefsActions.clickCloseTextPrefsSuccessModal,
  }
);

import { createAction as createReduxAction } from 'redux-act';

const createAction = (desc, ...args) =>
  createReduxAction(`Qualifying Questions > ${desc}`, ...args);

export const openModal = createAction('Open Modal');
export const dismissQualifyingQuestionsModal = createAction(
  'Hide Qualifying Questions Modal',
  () => {}
);
export const selectAnswer = createAction(
  'Select an answer',
  /** @param {{ id: number, question: string, choice: string }} */
  ({ id, question, choice }) => ({ id, question, choice })
);

/** Entity/Model-based actions, as opposed to view-based ones */

// Just trying this out, will poll the group at PR-time to see how people like it.
export const QQActions = createEntityActions('QualifyingQuestions');

/**
 * Create CRUD actions for interacting with a persisted entity over a network.
 * @param {String} entityName
 */
function createEntityActions(entityName) {
  return {
    fetch: createOperationActions(`${entityName} > Fetch`),
    create: createOperationActions(`${entityName} > Create`),
    update: createOperationActions(`${entityName} > Update`),
    delete: createOperationActions(`${entityName} > Delete`),
  };
}

function createOperationActions(desc, ...args) {
  return {
    success: createReduxAction(`${desc} > Success`, ...args),
    failure: createReduxAction(`${desc} > FAILURE`, ...args),
  };
}

/* eslint-disable func-names */
import PropTypes from 'prop-types';

import React, { Children } from 'react';
import MobileResultsSortRadio from './MobileResultsSortRadio';

/**
 * A component used to pass common props to each of the child radio buttons.
 */
export default function MobileResultsSortRadioListWrapper(props) {
  return (
    <form>
      <ul className="uk-list uk-list-space m-0">
        {Children.map(props.children, (child) => React.cloneElement(child, {
          onClick: props.handleClick,
          currentSort: props.currentSort
        })).filter(child => child.props.sortKey !== 'distance' || props.showDistanceSort)}
      </ul>
    </form>
  );
}

MobileResultsSortRadioListWrapper.propTypes = {
  handleClick: PropTypes.func,
  currentSort: PropTypes.string,
  showDistanceSort: PropTypes.bool,
  children(props, propName, componentName) {
    const children = props[propName];
    Children.forEach(children, (child) => {
      if (child.type !== MobileResultsSortRadio) {
        throw new Error(`Children of ${componentName} should all be of type
\`MobileResultsSortRadio\`.`);
      }
    });
  }
};

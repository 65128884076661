import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';
import { config } from 'BoomTown';
import { H2 } from 'components/core/Headings';
import { PrimaryButton } from 'coreComponents';
import { Grid, Cell } from 'components/core/Grid';
import Slider from 'components/Common/Slider';
import SliderDots, { SLIDER_DOTS_HEIGHT } from 'components/Common/Slider/SliderDots';
import SPALink from 'components/Common/SPALink';
import SlideCountPill from 'components/Common/SlideCountPill';
import { PROP_DETAILS } from 'cypress_constants';

const isMobile = config && config.isMobile;
const ImgSlide = ({ className, src, index, altText }) => (
  <div
    className={className}
    role="img"
    aria-label={`${altText} - ${index}`}
    {...(index === 0
      ? { style: { backgroundImage: `url('${src}')` } }
      : { 'data-flickity-bg-lazyload': src })}
  />
);
ImgSlide.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  index: PropTypes.number,
  altText: PropTypes.string,
};

class RafSlider extends Component {
  static propTypes = {
    altText: PropTypes.string.isRequired,
    listingId: PropTypes.number.isRequired,
    photos: PropTypes.array.isRequired,
    cta: PropTypes.bool,
    deferSliderRender: PropTypes.bool,
    initialIndex: PropTypes.number,
    onCTAInPersonTourClick: PropTypes.func,
    onCTAVideoTourClick: PropTypes.func,
    onSlide: PropTypes.func,
    onSlideClick: PropTypes.func,
    slideHref: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    fixedHeight: PropTypes.bool,
    dataCY: PropTypes.string,
    sliderClassName: PropTypes.string,
    imageClassName: PropTypes.string,
  };

  static defaultProps = {
    deferSliderRender: false,
    cta: false,
    initialIndex: 0,
  };

  state = {
    activeIndex: this.props.initialIndex,
    isInitialRender: this.props.deferSliderRender,
  };

  /** @type number[] */
  rafs = [];

  componentDidMount() {
    if (this.props.deferSliderRender) {
      // We actually want the *next* tick, so double up
      const raf1 = window.requestAnimationFrame(() => {
        const raf2 = window.requestAnimationFrame(() => {
          this.setState({ isInitialRender: false });
        });
        this.rafs.push(raf2);
      });
      this.rafs.push(raf1);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Only reset the activeIndex if we've actually swapped the slider here
    if (nextProps.listingId !== this.props.listingId) {
      this.setState({
        activeIndex: nextProps.initialIndex,
      });
    }
  }

  componentWillUnmount() {
    this.rafs.forEach((rafID) => window.cancelAnimationFrame(rafID));
  }

  handleStaticClick = () => {
    const { activeIndex } = this.state;
    if (activeIndex < this.props.photos.length) {
      this.props.onSlideClick(activeIndex);
    }
  };

  onCellSelect = () => {
    const activeIndex = this.slider.getSelectedIndex();

    // Since 'cellSelect' fires on more than actual slides, this is
    // [recommended by the maintainers](https://github.com/metafizzy/flickity/issues/179)
    if (activeIndex !== this.state.activeIndex) {
      this.setState({ activeIndex });

      if (typeof this.props.onSlide === 'function') {
        window.requestAnimationFrame(() => {
          this.props.onSlide({
            slideIndex: activeIndex,
            listingId: this.props.listingId,
            photoCount: this.props.photos.length,
          });
        });
      }
    }
  };

  render() {
    const {
      dataCY,
      photos,
      altText,
      slideHref,
      cta,
      onCTAInPersonTourClick,
      onCTAVideoTourClick,
      onSlideClick,
      fixedHeight,
      sliderClassName,
      imageClassName,
    } = this.props;

    let photoCount = this.props.photos.length;
    if (cta) {
      photoCount += 1;
    }

    const imageClasses = cx('bt-cover__wrapper', imageClassName);

    // For the initial render we just stick the first image in there
    // in the next tick we'll enable all of these sliders
    if (this.state.isInitialRender) {
      if (photos.length === 0) {
        return null;
      }

      // Adding in the background image here actually causes a flicker
      // and doesn't seem to download the image any faster in practice
      // extra div is for dots
      return (
        <div>
          <div className="bt-listing-gallery__cell">
            <div className={imageClasses} />
          </div>
          <div style={{ height: SLIDER_DOTS_HEIGHT }} />
        </div>
      );
    }

    const sliderOptions = {
      bgLazyLoad: 1,
      prevNextButtons: !isMobile,
      initialIndex: this.props.initialIndex,
      setGallerySize: !fixedHeight,
    };

    // Flickity events passed to the underlying flickity instance
    // @see https://flickity.metafizzy.co/events.html#flickity-events
    const sliderEvents = {
      cellSelect: this.onCellSelect,
      ...(onSlideClick ? { staticClick: this.handleStaticClick } : {}),
    };

    const sliderWrapperClasses = cx('bt-position--relative', {
      'flickity-slider--fixed-height': fixedHeight,
    });

    const sliderClasses = cx('listing-gallery', sliderClassName);

    return (
      <div className={sliderWrapperClasses}>
        <Slider
          events={sliderEvents}
          options={sliderOptions}
          ref={(ref) => (this.slider = ref)}
          classes={sliderClasses}
          el="div"
        >
          {photos.map((src, i) => (
            <SPALink
              {...(slideHref && {
                href: typeof slideHref === 'function' ? slideHref(i) : slideHref,
                role: 'row',
              })}
              className={`bt-listing-gallery__cell at-mobile-card-slide__${i}`}
              dataCY={cx({ [`${dataCY}__${i}`]: dataCY })}
              key={`${src}__${i}`} // In case the same photo happens to occur twice
            >
              <ImgSlide className={imageClasses} index={i} src={src} altText={altText} />
            </SPALink>
          ))}
          {cta && (
            <div className="bt-listing-gallery__cell uk-overlay">
              <ImgSlide className={imageClasses} index={photoCount} src={photos[0]} altText={altText} />
              <div className="uk-overlay-panel uk-overlay-background text-xs--center">
                <Grid column alignItems="center" justifyContent="center" className="height-1-1">
                  <Cell xs={12} dataCY={PROP_DETAILS.LIKE_WHAT_YOU_SEE_TITLE}>
                    <H2>Like What You See?</H2>
                  </Cell>
                  <Cell xs={8}>
                    <PrimaryButton
                      className="at-slide-cta flickity-request-showing-cta"
                      onClick={onCTAInPersonTourClick}
                      width="full"
                    >
                      {config.disableVideoTour ? 'Request A Showing' : 'Tour in Person'}
                    </PrimaryButton>
                  </Cell>
                  {!config.disableVideoTour && (
                    <Cell xs={8}>
                      <PrimaryButton
                        className="at-slide-video-tour-cta flickity-request-showing-video-tour-cta mt-12"
                        onClick={onCTAVideoTourClick}
                        width="full"
                      >
                        Tour via Video Chat
                      </PrimaryButton>
                    </Cell>
                  )}
                </Grid>
              </div>
            </div>
          )}
        </Slider>
        <SlideCountPill current={this.state.activeIndex + 1} total={photoCount} />
        <SliderDots activeIndex={this.state.activeIndex} photoCount={photoCount} />
      </div>
    );
  }
}

export default RafSlider;

/* eslint-disable import/prefer-default-export */

/**
 * Uses `window.navigator` to retrieve the user's current location.
 */
export function get() {
  return new Promise((resolve, reject) => {
    window.navigator.geolocation.getCurrentPosition(resolve, reject);
  });
}

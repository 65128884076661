/* eslint-disable import/prefer-default-export */

/**
 * Mobile portrait or Mobile landscape
 *
 * Currently has a mirrored one-off in collections/listings@searchChange
 * for an abbey consistency issue
 */
export function isMobile(state) {
  return state.browser.isMobile;
}

/**
 * Devices larger than 1220px
 */
export function isXLargeDevice(state) {
  return state.browser.isXLargeDevice;
}

import { combineReducers } from 'redux';
import menu from './menu';

/**
 * Reducer for the state of the off-canvas search menu.
 *
 * @todo Refactor to remove the extra `menu` layer. Extract state within this layer to the root of
 * the `offCanvasSearch` branch in the state tree.
 */
export default combineReducers({ menu });

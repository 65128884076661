import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Cell } from 'components/core/Grid';

/**
 * @typedef CardFeatureDataProps
 *
 * @type {Object}
 * @prop {string|null} [daysOnSiteText=null]
 * @prop {boolean} displayDataSource
 * @prop {string|false} onMarketDate
 * @prop {FlagshipAPI.FeatureTextArray} features
 */

/**
 * Renders the OnSite, OnMarketDate, and FeatureText properties for the Listing Card.
 *
 * @param {CardFeatureDataProps} props
 * @returns {JSX.Element}
 */
export default function CardFeatureData(props) {
  const {
    daysOnSiteText,
    displayDataSource,
    onMarketDate,
    features,
  } = props;

  /**
   * Used to render the legacy "Listed x Days Ago" text for mobile cards in the `data-alt-text`
   * data attribute to be rendered via CSS>
   *
   * @see CNS-7935
   *
   * @type {string}
   */
  let daysOnSiteAttr;
  if (daysOnSiteText) {
    daysOnSiteAttr = daysOnSiteText === 'Today' ? 'Listed Today' : `Listed ${daysOnSiteText} Ago`;
  }

  return (
    <Grid className="font-size--12">
      {daysOnSiteText && (
        <Cell xs={6} md={12}>
          <div
            className="listing-card__onsite mt-4 bt-text--truncate"
            data-alt-text={daysOnSiteAttr}
          >
            <span className="listing-card__onsite-label bt-text--bold">On Site: </span>
            <span className="listing-card__onsite-text">{daysOnSiteText}</span>
            {displayDataSource && <em> (via BoomTown)</em>}
          </div>
        </Cell>
      )}

      {onMarketDate && (
        <Cell xs={6} md={12}>
          <div className="listing-card__onmarketdate mt-4 bt-text--truncate">
            <span className="listing-card__onmarketdate-label">On Market: </span>
            {onMarketDate}
          </div>
        </Cell>
      )}

      {Array.isArray(features) && (
        <Cell xs={12}>
          {features.map((f) => (
            <div key={f.Name} className="mt-4 bt-text--truncate">
              <span className="bt-text--bold">{`${f.Name}:`}</span>
              {f.Features.join(' ')}
            </div>
          ))}
        </Cell>
      )}
    </Grid>
  );
}

CardFeatureData.defaultProps = {
  daysOnSiteText: null,
  displayDataSource: false,
  onMarketDate: false,
  features: null,
};

CardFeatureData.propTypes = {
  daysOnSiteText: PropTypes.string,
  displayDataSource: PropTypes.bool,
  onMarketDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  features: PropTypes.arrayOf(PropTypes.shape({
    Name: PropTypes.string,
    Features: PropTypes.arrayOf(PropTypes.string),
  })),
};

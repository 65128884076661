import PropTypes from 'prop-types';
import React from 'react';
import PriceOption from './PriceOption';

/**
 * A list of price options.
 * @param {Object} props
 */
export default function PriceOptions(props) {
  return (
    <ul className={props.className}>
      {props.prices.map((p, i) =>
        <PriceOption
          key={`price-options-${p}-${i}`}
          price={p}
          onSelect={props.onSelect}
          active={i === props.activeIndex}
        />
      )}
    </ul>
  );
}

PriceOptions.propTypes = {
  activeIndex: PropTypes.number,
  onSelect: PropTypes.func,
  prices: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string
};

import { createAction } from 'redux-act';

const action = (desc, ...rest) => createAction(`Change Password > ${desc}`, ...rest);

// Only used on Mobile
export const clickCloseChangePassword = action('Click close in header');

export const submitPasswordChange = action('Submit the password change form');
export const receivePasswordChangeSuccess = action('Recv. password change success');
export const receivePasswordChangeFailure = action('Recv. password change failure');
export const focusFormField = action('Focus a form field', () => {});

// Response Modal
const respModalAction = (desc, ...args) => action(`Response modal > ${desc}`, ...args);

export const clickCloseChangePasswordSuccessModal = respModalAction('Tap Confirm button');
export const autoDismissChangePasswordSuccessModal = respModalAction('Toast timeout');
export const clickBackInChangePasswordFailureModal = respModalAction('Tap Back button');
export const clickRetryInChangePasswordFailureModal = respModalAction('Tap Retry button');

/**
 * Shares a lot in common with ./StartAnOfferActions
 */
import { createAction as ca } from 'redux-act';
import { config, visitor } from 'BoomTown';
import validator from 'utility/validator';
import { toDollarString } from 'utility/string';
import * as NameParse from 'utility/parse-names';

function createAction(title, ...rest) {
  return ca(`Mobile SAO > ${title}`, ...rest);
}

// not called from the ui
export const handleErrors = createAction('Validation errors in form');
export const onSubmit = createAction('User submitted form');
export const onSuccess = createAction('API contact form success');
export const onSubmitError = createAction('API contact form error');
export const toastTimeout = createAction('Time has passed clear the toast');

// Only used in the Native App for now
export const formValidated = createAction('Form is validated');

// called from the ui
export const handleButtonClick = createAction('User clicked SAO button');
export const handleModalClose = createAction('User closed modal', () => {});
export const handleFieldChange = createAction('Form field change');

export const logGTM = createAction('Start An Offer > logGTM');

export const handleSubmit = () => (dispatch, getState) => {
  dispatch(onSubmit());
  const { startAnOffer } = getState();

  const errors = [];
  if (!NameParse.isFullName(startAnOffer.fullName.value)) {
    errors.push({
      field: 'fullName',
      message: 'Please enter a valid full name.',
    });
  }

  if (!validator.isEmail(startAnOffer.email.value)) {
    errors.push({
      field: 'email',
      message: 'Please enter a valid email address.',
    });
  }

  if (!validator.isPhone(startAnOffer.phone.value)) {
    errors.push({
      field: 'phone',
      message: 'Please enter a valid phone.',
    });
  }

  if (!validator.isNotWhitespace(startAnOffer.comments.value)) {
    errors.push({
      field: 'comments',
      message: 'Please leave your comment.',
    });
  }

  if (config.recaptchaEnabled && !startAnOffer.token) {
    errors.push({
      field: 'token',
      message: 'Invalid Recaptcha Response',
    });
  }

  if (errors.length > 0) {
    dispatch(handleErrors(errors));
    return false;
  }

  /*
    Signal that the form has been validated. Used to send a message to the Native App for UI
    to make adjustments.
  */
  if (config.isMobileApp) {
    dispatch(formValidated());
  }

  let recaptchaToken = null;
  if (startAnOffer.token) {
    recaptchaToken = startAnOffer.token.value;
  }

  const { firstName, lastName } = NameParse.parse(startAnOffer.fullName.value);

  const params = {
    type: 'makeanoffer',
    ListingID: startAnOffer.listingId,
    firstName,
    lastName,
    phone: startAnOffer.phone.value,
    email: startAnOffer.email.value,
    visitorID: visitor.id,
    visitID: visitor.get('VisitID'),
    comments: startAnOffer.comments.value,
    Price: toDollarString(startAnOffer.offerAmount.value),
    SeenProperty: startAnOffer.hasViewedProperty.value,
    HelpWithFinancing: startAnOffer.helpWithFinancing.value,
    token: recaptchaToken,
  };

  const trackingParams = {
    form: 'startofferform',
    contacttype: visitor.updateContactType('lead'),
    formtype: 'email',
    event: visitor.get('IsRegistered') ? 'offer-form-submit' : 'offer-form-registration',
  };

  dispatch(logGTM(trackingParams));

  const fnSendContactForm = () => {
    visitor.contactForm(
      params,
      () => {
        // `emailInUse` is returned to indicate that the user has _not_ been
        // logged in. (No creds given on contact form)
        visitor.updateAfterRegistrableAction({ phone: params.phone });
        dispatch(onSuccess());
        setTimeout(() => dispatch(toastTimeout()), 3000);
      },
      () => {
        dispatch(onSubmitError());
      }
    );
  };

  fnSendContactForm();

  return true;
};

import React, { useState } from 'react';
import { each, filter, groupBy, sortBy } from 'underscore';
import $ from 'jquery';

import Autocomplete from 'components/Autocomplete';
import FAIcon from 'components/core/FAIcon';
import { InputLabel, InputGroup } from 'coreComponents';

const AgentSearch = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [suggestionsState, setSuggestionsState] = useState([]);

  /**
   * Fetch a list of Agents based on the search value.
   *
   * @param {string} value
   */
  const fetchSuggestions = (value) => {
    const request = {
      query: value,
      networkid: window.bt.network.id,
      maxResults: 50,
    };

    // JSONp because the suggest api doesn't have cors headers
    $.getJSON(`${window.bt.config.suggestApiUrl}/1/SuggestDomains?callback=?`, request, (data) => {
      if (data.Status.Code === 200) {
        setSuggestionsState(filterSuggestions(data.Result));
        setActiveIndex(0);
      }
    });
  };

  /**
   * filterSuggestions
   * A method that takes the result of `fetchSuggestions()` and reformats
   * it in a way that it is readable by the Autocomplete ResultsManager
   *
   * @param {array} data the list of results returned by the current search value
   * @return {array} suggestions
   */
  const filterSuggestions = (data) => {
    const suggestions = [];
    let results = data;
    const maxResults = 50;

    results = filter(results, (item) => ['Agent', 'Office', 'Domain'].includes(item.Type));

    // Assign an index value to the results to be keyed through
    for (var i = 0; i < results.length; i++) {
      results[i].index = parseInt(i, 10);
    }

    // cpa results to 50
    if (results.length > maxResults) {
      results = data.Result.slice(0, maxResults);
    }

    // Group results based on their type
    const groups = groupBy(results, (item) => item.Type);

    // Create a title entry for each group
    each(groups, (items, group) => {
      let currentGroup = group;
      if (group === 'Domain') {
        currentGroup = 'Website';
      }
      // The formatted data to be pushed into the suggestions array
      const chunk = {
        items: sortBy(items, (o) => o.index),
        name: currentGroup,
      };
      suggestions.push(chunk);
    });

    return suggestions;
  };

  /**
   * Navigate to the correct URL for the item selected.
   *
   * @param {Object} suggestion
   * @param {string} suggestion.value
   * @param {string} suggestion.domain
   * @param {string} suggestion.searchstring
   * @param {string} suggestion.type
   */
  const handleItemSelect = (suggestion) => {
    if (suggestion) {
      const { FullName: name, Domain: domain, QSModifier: searchstring, Type: type } = suggestion;
      let url = `http://${domain}/contactus/`;

      // go to agent profile if agent result
      if (type === 'Agent') {
        const slug = `${searchstring}-${name.replace(/\s/g, '-')}`;
        url = `http://${domain}/agents/${slug}`;
      }

      window.location = url;
    }
  };

  return (
    <form action="/" className="widget at-agent-office__search bt-agent-office__wrapper width-1-1">
      <InputLabel hidden htmlFor="at-inner-query--off-canvas">
        Search Properties
      </InputLabel>
      <InputGroup>
        <div className="form__input-prefix">
          <FAIcon icon="search" type="solid" />
        </div>
        <Autocomplete
          activeIndex={activeIndex}
          suggestions={suggestionsState}
          fetchSuggestions={fetchSuggestions}
          handleItemSelect={handleItemSelect}
          handleSetActiveIndex={setActiveIndex}
          placeholderText="Search for an agent or office"
          inputProps={{
            className: 'autocomplete bt-search__query',
            name: 'at-inner-query--off-canvas',
            id: 'at-inner-query--off-canvas',
            grouped: true,
            maxLength: '255',
          }}
        />
      </InputGroup>

      {/*  CNS-1859: Fake submit button to show "Go" on iOS keyboard */}
      <button type="submit" className="uk-hidden">
        Search
      </button>
    </form>
  );
};

export default AgentSearch;

import Layzr from 'layzr.js';

/**
 * Any part of the code base that attempts to `refresh()` the lazy loader
 * before it's instantiated will result in a no-op.
 */
export default class LazyLoad {
  layzr = null;

  constructor() {
    this.layzr = new Layzr({ normal: 'data-layzr' }).update().handlers(true).check();
  }

  /**
   * Call this to search through the dom for more nodes
   * to lazy load
   */
  refresh = () => {
    if (this.layzr != null) {
      this.layzr.update().check();
    }
  }

  /**
   * Manually trigger a "check if visible"
   */
  check = () => {
    if (this.layzr != null) {
      this.layzr.check();
    }
  }
}

import { createReducer } from 'redux-act';
import { LOCATION_CHANGED } from 'redux-little-router';
import { config } from 'BoomTown';
import { RESULTS_GALLERY } from 'routes';

// Actions
import * as MobileGalleryActions from 'actions/MobileGalleryActions';
import * as MobileSearchMenuActions from 'screens/MobileSearchMenu/actions';
import * as MobileSaveSearchActions from 'actions/MobileSaveSearchActions';
import { initializeState } from 'actions/bootstrapActions';
import { onListingCardClick } from 'components/Card/actions';

export const initialState = {
  isSortMenuOpen: false,

  // Currently implemented as two independent bits, but could be (isOpen,
  // whichUIIsActive).
  isSaveSearchMenuOpen: false,
  isPowerSearchActive: false,

  // The last scroll position recorded when a user tapped a listing card,
  scrollY: null,

  isLoadingFirstBatch: false,

  /**
   * A map from listingID to last viewed slide index.
   *
   * @type {{ [number]: number }}
   */
  slideIndices: {},

  search: {},
  listings: [],
  totalItems: 0,
};

export const mobileGalleryReducer = {
  /** Global Actions */
  [initializeState]: (
    state,
    { listings, lastSearchState, lastMapBounds, isSaveSearchMenuOpen }
  ) => {
    let nextState = { ...state };

    // Grab listings from the server on a SSR and grab the search from the payload (gets set
    // from the cookie when the `initializeState` action is dispatched)
    if (listings && listings.Result) {
      const search = {
        ...(lastSearchState || state.search),
        ...(config.useListMapResultsSync ? lastMapBounds : {})
      };

      nextState = {
        ...nextState,
        // Only update the search if we have listings, or else we won't make an api request
        // when we navigate to the gallery
        search,
        listings: listings.Result.Items || state.listings,
        totalItems: listings.Result.TotalItems || state.totalItems,
      };
    }

    if (isSaveSearchMenuOpen) {
      nextState.isSaveSearchMenuOpen = isSaveSearchMenuOpen;
    }
    return nextState;
  },
  [LOCATION_CHANGED]: closeSortMenu,
  [MobileGalleryActions.startFetchingResults]: state => ({
    ...state,
    scrollY: null,
    slideIndices: {},
    isLoadingFirstBatch: true,
  }),

  [MobileGalleryActions.cancelledFetchingResults]: state => ({
    ...state,
    isLoadingFirstBatch: false,
  }),

  [MobileGalleryActions.receiveSearchResults]: (state, payload) => ({
    ...state,
    search: payload.search,
    listings: payload.listings,
    totalItems: payload.totalItems,
    isLoadingFirstBatch: false,
  }),

  [MobileGalleryActions.receiveAdditionalSearchResults]: (state, payload) => ({
    ...state,
    listings: [...state.listings, ...payload],
  }),

  /** Local Actions */
  [MobileGalleryActions.clickSortByMenu]: state => ({
    ...state,
    isSortMenuOpen: !state.isSortMenuOpen,
  }),
  [MobileGalleryActions.clickSortOption]: closeSortMenu,

  [onListingCardClick]: (state, payload) => {
    const { sourcePath } = payload;

    if (sourcePath.includes(RESULTS_GALLERY)) {
      return stashScroll(state, payload);
    }

    return state;
  },

  [MobileGalleryActions.clickSearchInResultsHeader]: stashScroll,
  [MobileGalleryActions.clickNewSearchInResultsHeader]: stashScroll,
  [MobileGalleryActions.clickMapButtonInResultsHeader]: stashScroll,

  // Maintain an array of listing ID => last slide index
  [MobileGalleryActions.onPhotoSlide]: (state, { listingId, slideIndex }) => ({
    ...state,
    slideIndices: {
      ...state.slideIndices,
      [listingId]: slideIndex,
    },
  }),

  // Toggling the save search screen
  [MobileGalleryActions.clickSaveSearchInResultsHeader]: (state, { scrollY }, { visitor }) => {
    if (!visitor.isRegistered()) {
      return state;
    }
    return {
      ...state,
      isSaveSearchMenuOpen: true,
      scrollY,
    };
  },
  [MobileSearchMenuActions.clickSaveOnSearchMenu]: (state, payload, { visitor }) => {
    if (!visitor.isRegistered()) {
      return state;
    }
    return {
      ...state,
      isSaveSearchMenuOpen: true,
    };
  },
  [MobileGalleryActions.clickSaveSearchAd]: (state, { scrollY }, { visitor }) => {
    if (!visitor.isRegistered()) {
      return state;
    }
    return {
      ...state,
      isSaveSearchMenuOpen: true,
      scrollY,
    };
  },

  // REFACTOR: move to SearchMenu
  [MobileSearchMenuActions.togglePowerSearch]: state => ({
    ...state,
    isPowerSearchActive: !state.isPowerSearchActive,
  }),

  // REFACTOR: move to new save search
  [MobileSaveSearchActions.clickClose]: closeSaveSearch,
  [MobileSaveSearchActions.clickSaveSearchSuccessButton]: closeSaveSearch,
  [MobileSaveSearchActions.autoDismiss]: closeSaveSearch,
};

export default createReducer(mobileGalleryReducer, initialState);

function closeSaveSearch(state) {
  return {
    ...state,
    isSaveSearchMenuOpen: false,
  };
}

function stashScroll(state, { scrollY }) {
  return {
    ...state,
    scrollY,
  };
}

function closeSortMenu(state) {
  return {
    ...state,
    isSortMenuOpen: false,
  };
}

import { createReducer } from 'redux-act';
import { loadingComparisonTable, loadComparisonTable, cacheListing, loadFeatures } from 'actions/ComparisonTableActions';

export default createReducer({
  /**
   * loadingComparisonTable
   *
   * About to make an API request for up to 4 listing objects.
   * Set `isLoading` property to true so any components subscribing
   * to this store can render UI related to loading state.
   *
   * @param {object} state - The current state
   * @return {object} The new state with isLoading true
   */
  [loadingComparisonTable]: state => ({
    ...state,
    isLoading: true,
  }),

  /**
   * loadComparisonTable
   *
   * Is fired after receiving an API response for up to 4 single listing details.
   * This function receives an array of single detail listing objects beefed up.
   * It is also tasked with flipping the loading state to false.
   *
   * @param {object} state - The current state
   * @param {object} payload - Contains array of listing objects and number of the user's favorites
   * @return {object} The new state with favorites and isLoading false
   */
  [loadComparisonTable]: (state, payload) => ({
    ...state,
    isLoading: false,
    listings: payload.listings,
    availableFavorites: payload.availableFavorites,
  }),

  /**
   * loadFeatures
   *
   * Compare middleware pulls these features from a query parameter.
   * This reducer adds them to our store.
   *
   * @param {object} state - The current state
   * @param {array} selectedFeatures - Array of strings
   * @return {object} The new state with selectedFeatures
   */
  [loadFeatures]: (state, selectedFeatures) => ({
    ...state,
    selectedFeatures,
  }),

  /**
   * cacheListing
   *
   * Simple in-memory key-value listing cache.
   *
   * @param {object} state - The current state
   * @param {object} listing - Beefed up single details
   * @return {object} The new state with updated listing map
   */
  [cacheListing]: (state, listing) => ({
    ...state,
    listingCache: {
      ...state.listingCache,
      [listing._ID]: listing,
    }
  })
}, {
  /**
   * Default Comparison Table State
   *
   * listings - An array of listing details objects beefed up to populate <ComparisonTable>
   * listingCache - A map of cache listing detail objects
   * selectedFeatures - An array of feature strings which populates extra <FeatureRow>s
   * availableFavorites -
   * isLoading - Boolean for UI updates around API requests
   */
  listings: [],
  listingCache: {},
  selectedFeatures: [],
  availableFavorites: 0,
  isLoading: false,
});

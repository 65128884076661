import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { Checkbox, PrimaryButton } from 'coreComponents';
import TSaleTypes from 'btPropTypes/saleTypes';
import { CCompDropdown } from 'components/Common/Dropdown';
import FAIcon from 'components/core/FAIcon';
import { SEARCH_BAR } from 'cypress_constants';

/**
 * The "Type" menu on the SearchBar component
 */
export default function TypeMenu(props) {
  const { buttonClasses } = props;

  const renderButton = () => {
    return (
      <PrimaryButton
        className={cx('bt-filter__button', buttonClasses)}
        onClick={(e) => {
          return e.preventDefault();
        }}
        dataCY={SEARCH_BAR.TYPE}
      >
        Type <FAIcon icon="angle-down" type="regular" />
      </PrimaryButton>
    );
  };

  return (
    <CCompDropdown
      size="large"
      align="right"
      trigger={renderButton()}
      triggerClassName="at-type-menu-trigger"
      content={<TypeMenuContent {...props} />}
    />
  );
}

TypeMenu.propTypes = {
  buttonClasses: PropTypes.string,
};

TypeMenu.displayName = 'TypeMenu';

export function TypeMenuContent(props) {
  const {
    searchFields,
    saleTypes,
    selectedProptypes,
    onTogglePropertyType,
    onToggleSaleType,
  } = props;

  const titleStatusIds = ['BO', 'SS'];

  const titleStatuses = saleTypes.filter((saleType) => {
    return titleStatusIds.includes(saleType.id);
  });
  const showTitleStatuses = !!titleStatuses.length;

  const listingStatuses = saleTypes.filter((saleType) => {
    return !titleStatusIds.includes(saleType.id);
  });

  return (
    <div className="grid grid--gutters">
      <div className="cell">
        <p className="uk-text-bold bt-filter__type-subheader ml-4 mb-12">Property Type</p>
        {/* TODO: Convert this to a selector and move this join out of the view */}
        {searchFields.proptypes.map((pt, i) => {
          return (
            <Checkbox
              key={pt.id}
              id={`search-proptype-${pt.id}`}
              name="propertyType"
              value={pt.id}
              label={pt.name}
              checked={selectedProptypes.includes(pt.id)}
              onChange={() => {
                return onTogglePropertyType(pt.id);
              }}
              className={cx('at-search-proptypes', { 'mt-4': i > 0 })}
            />
          );
        })}
      </div>

      <div className="cell">
        <p className="uk-text-bold bt-filter__type-subheader ml-4 mb-12">Listing Status</p>
        {listingStatuses.map((x) => {
          return (
            <Checkbox
              key={x.id}
              label={x.label}
              id={`sale-type-checkbox-${x.id.toLowerCase()}`}
              name="saleStatus"
              value={x.id}
              dataCY={x.dataCY}
              labelProps={x.labelProps}
              checked={x.checked}
              onChange={() => {
                return onToggleSaleType(x.id);
              }}
              className={`at-sale-type-checkbox at-sale-type-checkbox-${x.id}`}
            />
          );
        })}

        {showTitleStatuses && (
        <>
          <p className="uk-text-bold bt-filter__type-subheader ml-4 mb-12">Title Status</p>
          {titleStatuses.map((x) => {
            return (
              <Checkbox
                key={x.id}
                label={x.label}
                id={`sale-type-checkbox-${x.id.toLowerCase()}`}
                name="saleStatus"
                value={x.id}
                dataCY={x.dataCY}
                labelProps={x.labelProps}
                checked={x.checked}
                onChange={() => {
                  return onToggleSaleType(x.id);
                }}
                className={`at-sale-type-checkbox at-sale-type-checkbox-${x.id}`}
              />
            );
          })}
        </>
        )}

      </div>
    </div>
  );
}

TypeMenuContent.propTypes = {
  searchFields: PropTypes.shape({
    proptypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  selectedProptypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  saleTypes: TSaleTypes,
  onTogglePropertyType: PropTypes.func.isRequired,
  onToggleSaleType: PropTypes.func.isRequired,
};

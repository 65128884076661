/* eslint-disable no-mixed-operators */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';
import { SEARCH_BAR } from 'cypress_constants';
import { PrimaryButton } from 'coreComponents';
import { Grid, Cell } from 'components/core/Grid';
import FAIcon from 'components/core/FAIcon';
import { CCompDropdown } from 'components/Common/Dropdown';
import PriceInput from 'components/Common/PriceInput';
import PriceOptions from './PriceOptions';

/**
 * A desktop price range component that displays lists of suggested prices
 * depending on the current value of the inputs.
 *
 * @todo Implement cycling through the suggested prices with the up and down
 * arrow keys.
 */
class PriceRange extends Component {
  /**
   * The static price options for when the min input is active.
   * @type {Array}
   */
  _minOptions = [0, 50000, 75000, 100000, 150000, 200000, 250000, 300000, 350000, 400000];

  /**
   * Creates a price range component
   * @param props {Object}
   */
  constructor(props) {
    super(props);
    this.state = {
      // Which of the two dropdowns is currently active.
      minActive: true,
      // To facilitate cycling through the price options. -1 is "no option selected".
      activeIndex: -1,
      minprice: props.min,
      maxprice: props.max,
    };

    // To ensure it exists and is a fn.
    this._close = function noop() {};
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.min !== newProps.min || this.props.max !== newProps.max) {
      this.setState({
        minprice: newProps.min,
        maxprice: newProps.max,
      });
    }
  }

  /**
   * Obtain references to the min and max inputs, for focusing. (Min is not
   * used at the moment, but it might be needed.)
   */
  _minRef = c => {
    this._minInput = c;
  };
  _maxRef = c => {
    this._maxInput = c;
  };

  /**
   * Get an array of prices to display in the max price dropdown depending on
   * the value of `this.props.min`.
   * @return {Array<Number>}
   */
  _getMaxOptions = () => {
    let interval;
    const min = this.props.min || 0;
    if (min === 0) {
      interval = 100000;
    } else if (min < 200000) {
      interval = 25000;
    } else if (min <= 1000000) {
      interval = 50000;
    } else if (min <= 2000000) {
      interval = 100000;
    } else {
      interval = 250000;
    }
    const first = min - (min % interval) + interval;
    const a = [];
    for (let i = 0; i < 9; i++) {
      a.push(first + i * interval);
    }
    a.push(Infinity); // Any Price
    return a;
  };

  /**
   * Get price options depending on the active field.
   * @return {Array<Number>}
   */
  _getOptions = () => (this.state.minActive ? this._minOptions : this._getMaxOptions());

  /**
   * Handler for selecting a price in the dropdowns.
   * @param {Number} price
   */
  _handlePriceSelect = price => {
    const { minActive } = this.state;

    this.setState({ [minActive ? 'minprice' : 'maxprice']: price }, this._priceShouldUpdate);
  };

  _onMinPriceChange = minprice => {
    this.setState({ minprice }, this._priceShouldUpdate);
  };

  _onMaxPriceChange = maxprice => {
    this.setState({ maxprice }, this._priceShouldUpdate);
  };

  /**
   * Inform Redux via an action that we are ready to update
   * @private
   */
  _priceShouldUpdate = () => {
    // If the component hasn't submitted any changes, then continue
    if (this.state.maxprice === this.props.max && this.state.minprice === this.props.min) {
      return;
    }

    const { minActive } = this.state;

    const newPrice = {
      minprice: this.state.minprice,
      maxprice: this.state.maxprice,
    };

    let swapped = false;

    // Swap the two if they are out of order.
    if (newPrice.minprice > newPrice.maxprice) {
      [newPrice.maxprice, newPrice.minprice] = [newPrice.minprice, newPrice.maxprice];
      swapped = true;
    }

    if (this.props.onChange) {
      this.props.onChange(newPrice);
    }

    if (swapped) {
      if (minActive) {
        this._minInput.focus();
      } else {
        this._maxInput.focus();
      }
    } else if (minActive) {
      this._maxInput.focus();
    } else {
      this._close();
    }
  };

  /**
   * Called when focus moves to the minprice input
   * This will **not** set the focus to the minprice input.
   * @private
   */
  _minFocus = () => {
    this.setState({
      minActive: true,
      activeIndex: -1,
    });
  };

  /**
   * Called when focus moves to the maxprice input
   * This will **not** set the focus to the maxprice input
   * @private
   */
  _maxFocus = () => {
    this.setState({
      minActive: false,
      activeIndex: -1,
    });
  };

  /**
   * Called whenever a keydown occurs
   * @type {KeyboardEvent}
   * @private
   */
  _onInputKeyDown = e => {
    const priceKey = this.state.minActive ? 'minprice' : 'maxprice';
    const defaultValue = this.state.minActive ? 0 : Infinity;

    if (e.key === 'Escape') {
      this._close();
    } else if (e.key === 'ArrowDown') {
      this.setState(({ activeIndex: i }) => {
        const nextIndex = Math.min(i + 1, this._getOptions().length - 1);
        return {
          activeIndex: nextIndex,
          [priceKey]: nextIndex === -1 ? defaultValue : this._getOptions()[nextIndex],
        };
      });
    } else if (e.key === 'ArrowUp') {
      this.setState(({ activeIndex: i }) => {
        const nextIndex = Math.max(i - 1, -1);
        return {
          activeIndex: nextIndex,
          [priceKey]: nextIndex === -1 ? defaultValue : this._getOptions()[nextIndex],
        };
      });
    }
  };

  /**
   * Called when the form closes
   * @private
   */
  _onClose = () => {
    this.setState({ minActive: false }, this._priceShouldUpdate);
  };

  /**
   * Get's the close function
   * @param fn {function()}
   * @private
   */
  _getClose = fn => {
    this._close = fn;
  };

  /**
   * Sugar for the MinPrice input
   * @returns {ReactElement}
   * @private
   */
  _getMinPriceDOM = () => (
    <PriceInput
      style={{ width: '100px' }} // Temp fix for cypress while we do form work. Remove
      id="minprice--ballerbox"
      name="minprice"
      placeholder="Min Price"
      inputRef={this._minRef}
      onFocus={this._minFocus}
      onKeyDown={this._onInputKeyDown}
      onPriceChange={this._onMinPriceChange}
      value={this.state.minprice}
      defaultValue={0}
      tabIndex={2}
      dataCY={SEARCH_BAR.MIN_PRICE}
    />
  );

  /**
   * Sugar for the MaxPrice input
   * @returns {ReactElement}
   * @private
   */
  _getMaxPriceDOM = () => (
    <PriceInput
      style={{ width: '100px' }} // Temp fix for cypress while we do form work. Remove
      id="maxprice--ballerbox"
      name="maxprice"
      placeholder="Max Price"
      inputRef={this._maxRef}
      onFocus={this._maxFocus}
      onKeyDown={this._onInputKeyDown}
      onPriceChange={this._onMaxPriceChange}
      value={this.state.maxprice}
      defaultValue={Infinity}
      tabIndex={3}
      dataCY={SEARCH_BAR.MAX_PRICE}
    />
  );

  render() {
    const { buttonClasses } = this.props;

    return (
      <CCompDropdown
        align="right"
        trigger={(
          <PrimaryButton
            className={cx(
              'bt-filter__button js-search-price',
              buttonClasses,
            )}
            onClick={e => e.preventDefault()}
            dataCY={SEARCH_BAR.PRICE}
          >
            Price&nbsp;
            <FAIcon icon="angle-down" type="regular" />
          </PrimaryButton>
        )}
        triggerClassName="at-price-trigger"
        content={(
          <div className="bt-ccomp__content__inner">
            <Grid gutters resetVertical xs="halves">
              <Cell>{this._getMinPriceDOM()}</Cell>
              <Cell>{this._getMaxPriceDOM()}</Cell>
            </Grid>
            <PriceOptions
              className={cx(
                'uk-list bt-search__price-list mt-0',
                { 'uk-text-right': !this.state.minActive }
              )}
              prices={this._getOptions()}
              onSelect={this._handlePriceSelect}
              activeIndex={this.state.activeIndex}
            />
            <div className="font-size--14 text-xs--center">
              <a className="js-needhelpfinancing" href="/finance/">
                Need help with financing?
              </a>
            </div>
          </div>
        )}
      />
    );
  }
}

PriceRange.displayName = 'PriceRange';
PriceRange.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  buttonClasses: PropTypes.string,
};

export default PriceRange;

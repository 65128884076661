/* eslint-disable no-confusing-arrow */
import React from 'react';
import { connect } from 'react-redux';
import { isMobile as isMobileSelector } from 'selectors/browser';
import { isDesktopMapEnabled } from 'selectors/optimize';
import { outage } from 'BoomTown';

import {
  LazyLegacyDesktopResultsMap,
  LazyDesktopResultsMap,
  LazyMobileResultsMap,
} from 'screens/ResultsMap/Lazy';

export default connect(state => ({
  isMobile: isMobileSelector(state),
  desktopMapEnabled: isDesktopMapEnabled(state),
}))(({ isMobile, desktopMapEnabled }) => {
  if (outage) {
    window.location.href = '/contactus/?outage=true';
  }
  if (isMobile) { return <LazyMobileResultsMap />; }
  if (!isMobile && desktopMapEnabled) { return <LazyDesktopResultsMap />; }
  return <LazyLegacyDesktopResultsMap />;
});

import React from 'react';
import PropTypes from 'prop-types';
import { Radio, PrimaryButton } from 'coreComponents';
import { Grid, Cell } from 'components/core/Grid';
import ResponseModal from 'components/Common/ResponseModal';
import { TEXT_PREFS } from 'cypress_constants';
import withData from './withData';

const TextPreferences = (props) => {
  const {
    textAllowed,
    onSubmit,
    onToggleText,
    isMobileScreen,
    isSubmitting = false,
    isOpen,
    isSuccess,
    onClickCloseInRespModal,
    onClickBackInRespModal,
    onClickRetryInRespModal,
    successText,
  } = props;

  return (
    <div>
      <form
        id="text-prefs__form"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Radio
          id="text-prefs__allow-TextAllowed"
          name="textAllowed"
          value="textAllowed"
          label="Send me text messages"
          checked={textAllowed === true}
          dataCY={TEXT_PREFS.TEXT_ALLOWED}
          labelProps={{ 'data-cy': TEXT_PREFS.TEXT_ALLOWED_LABEL }}
          disabled={false}
          onChange={() => {
            if (textAllowed === false) {
              onToggleText(true);
            }
          }}
        />
        <Radio
          id="text-prefs__notallowed-TextAllowed"
          name="textAllowed"
          value="textNotAllowed"
          label="Do not send me text messages"
          checked={textAllowed === false}
          dataCY={TEXT_PREFS.TEXT_NOT_ALLOWED}
          labelProps={{ 'data-cy': TEXT_PREFS.TEXT_NOT_ALLOWED_LABEL }}
          disabled={false}
          onChange={() => {
            if (textAllowed === true) {
              onToggleText(false);
            }
          }}
        />
        {!isMobileScreen && (
          <Grid gutters justifyContent="center" className="mt-12">
            <Cell md={6}>
              <PrimaryButton
                type="submit"
                form="text-prefs__form"
                className="at-text-prefs-submit-btn"
                dataCY={TEXT_PREFS.UPDATE_TEXT_PREFERENCES}
                width="full"
                showSpinner={isSubmitting}
              >
                Update Text Preferences
              </PrimaryButton>
            </Cell>
          </Grid>
        )}
      </form>
      <ResponseModal
        isOpen={isOpen}
        isSuccess={isSuccess}
        onClickSuccess={onClickCloseInRespModal}
        onClickBack={onClickBackInRespModal}
        onClickRetry={onClickRetryInRespModal}
        successText={successText}
      />
    </div>
  );
};

TextPreferences.propTypes = {
  isMobileScreen: PropTypes.bool,
  textAllowed: PropTypes.bool,

  // submission
  onSubmit: PropTypes.func,
  onToggleText: PropTypes.func,
  isSubmitting: PropTypes.bool,
  // Response Modal
  isOpen: PropTypes.bool,
  isSuccess: PropTypes.bool,
  successText: PropTypes.string,
  onClickCloseInRespModal: PropTypes.func,
  onClickBackInRespModal: PropTypes.func,
  onClickRetryInRespModal: PropTypes.func,
};

export default withData(TextPreferences);

export const COMPARE = 'compare';
export const FAVORITES = 'favorites';
export const HOME = 'home';
export const LOGIN = 'login';
export const NOTIFICATIONS = 'notifications';
export const RESULTS_GALLERY = 'results-gallery';
export const RESULTS_MAP = 'results-map';
export const MENU = 'menu';
export const CONTACT_AGENT = 'contact-agent';
export const FILTER_MENU = 'filter-menu';
export const SAVE_SEARCH = 'save-search';

// Property Details
export const DETAILS = 'listing';
export const DIRECTIONS = 'directions';
export const START_AN_OFFER = 'start-an-offer';

// Account
export const ACCOUNT = 'account';
export const EDIT_ACCOUNT = 'account/edit';
export const EMAIL_PREFERENCES = 'account/email-preferences';
export const TEXT_PREFERENCES = 'account/text-preferences';
export const EDIT_SAVED_SEARCHES = 'account/saved-searches';
export const CHANGE_PASSWORD = 'account/change-password';

/**
 * The router configuration. Note that <Fragment>s must reference these
 * url-patterns exactly in order to match.
 */
export default {
  ...synonymousRoutes(HOME, [
    '/',
    '/bt-custom-homepage/:id(/)',
  ]),
  '/results-gallery(/)': { id: RESULTS_GALLERY },
  '/results-map(/)': { id: RESULTS_MAP },
  '/save-search(/)': { id: SAVE_SEARCH },
  '/favorites(/)': { id: FAVORITES },
  '/compare(/)': { id: COMPARE },
  ...synonymousRoutes(DETAILS, [
    '/homes/:address/:city/:state/:zip/:listingID(/)',
    '/listing/:listingID(/)',
  ]),
  '/account(/)': {
    id: ACCOUNT,
    '/edit(/)': {
      id: EDIT_ACCOUNT,
    },
    '/email-preferences(/)': {
      id: EMAIL_PREFERENCES,
    },
    '/text-preferences(/)': {
      id: TEXT_PREFERENCES,
    },
    '/saved-searches(/)': {
      id: EDIT_SAVED_SEARCHES,
    },
    '/change-password(/)': {
      id: CHANGE_PASSWORD,
    },
  },
  '/notifications(/)': { id: NOTIFICATIONS },
  '/login(/)': { id: LOGIN },
  '/menu(/)': { id: MENU },
  '/contact-agent(/)': { id: CONTACT_AGENT },
  '/filter-menu(/)': { id: FILTER_MENU },
  '/start-an-offer(/)': { id: START_AN_OFFER },
  '/directions(/)': { id: DIRECTIONS },
};
/**
 * Create an object filled with routing configs that all map to the same id.
 * @param {string} id The ID of the route
 * @param {Array<string>} patterns url-patterns that should all map to this logical route
 */
function synonymousRoutes(id, patterns = []) {
  // Dynamically define url-patterns that all have id 'listing
  return patterns.reduce((prev, p) => {
    // eslint-disable-next-line
    prev[p] = { id };
    return prev;
  }, {});
}

/* eslint-disable indent, no-mixed-operators */
import { createSelector } from 'reselect';
import { pick, isEmpty, isEqual, omit } from 'underscore';

// @ts-ignore
import { config, search as searchModel, rules } from 'BoomTown';
import { coordinateFilters, PROPERTY_TYPES } from 'models/search/constants';
import { getPendingSearch } from 'models/search/reducers/pending/selectors';
import { getSaleTypeCheckboxes } from 'reducers/saleTypes';
import tryParseFloat from 'utility/tryParseFloat';
import { MOBILE_SEARCH_MENU } from 'cypress_constants';

// TODO: Move to searchFields reducer
const getPropTypes = state => state.searchFields.proptypes;

// TODO: Move these selectors to new search model module
const getSelectedPropTypes = state => state.search.pending.proptypes;

export const getPropertyTypeLabel = ({ id, name }) => {
  switch (id) {
    case PROPERTY_TYPES.HOMES:
      return 'Single Family Home';
    case PROPERTY_TYPES.TOWNHOMES:
      return rules.get('ShouldShowVillas') ? name : 'Townhouse';
    case PROPERTY_TYPES.LOTS_AND_LAND:
      return 'Lots & Land';
    case PROPERTY_TYPES.CONDO:
      return 'Condo';
    default:
      return name;
  }
};

export const getPropertyTypes = createSelector(
  [getPropTypes, getSelectedPropTypes],
  (proptypes, selected) =>
    proptypes.map(({ id, name }) => ({
      id,
      label: getPropertyTypeLabel({ id, name }),
      name: `proptype-${id}`,
      checked: selected.includes(id),
      dataCY: MOBILE_SEARCH_MENU.PROPTYPES[id]?.input,
    }))
);

/**
 * @param {Object} state Root state of our Redux store
 */
export function getPendingSaleTypeFormData(state) {
  return getSaleTypeCheckboxes(
    getPendingSearch(state),
    state.specialRules,
    config.allowSoldData
  );
}

/**
 * Combine our three ways of specifying a lower bound on "baths" into a common
 * API for all our bath components.
 *
 * @param {{ minbaths: string?, minhalfbaths: string?, minfullbaths: string? }} search
 * @returns {Number}
 */
export function getMinBaths({ minbaths, minhalfbaths, minfullbaths }) {
  // @ts-ignore
  // minhalfbaths is only ever set in `search.pending` on the initial mapping from the committed
  // search schema to the pending search schema. As soon as the baths UI is interacted with,
  // we set `minhalfbaths` to 0 and use `pending.minbaths` as the source of truth for managing
  // the combined value of `minfullbaths` and `minhalfbaths`
  const [mb, mhb, mfb] = [minbaths, minhalfbaths, minfullbaths].map(x => tryParseFloat(x, 0));

  if ([mb, mhb, mfb].every(x => x === 0)) {
    return 0;
  }
  if (mhb > 0 || mb % 1 !== 0) {
    return Math.max(mb, mfb + (mhb / 2));
  }
  return Math.max(mb, mfb + (mhb / 2));
}

/**
 * Get the query needed to fetch listings.
 *
 * @param {Object} existingSearch Typically the local or current search state
 * @param {PendingSearch|Object} newSearch Either pending search schema or the committed search schema
 * @param {MapBounds|{}} [mapBounds={}] Current map boundaries from state
 *
 * @returns {Object|null} The query if a request is needed, null otherwise.
 */
export function getSearchQuery(existingSearch, newSearch, mapBounds = {}) {
  // `pick` returns an empty object if the first param is null
  const prevLocationSearch = pick(existingSearch, searchModel.locationParams);
  const locationSearch = pick(newSearch, searchModel.locationParams);

  const isNewLocationSearch = !isEmpty(locationSearch) && !isEqual(locationSearch, prevLocationSearch);

  let query;
  if (isNewLocationSearch) {
    // For new locations searches, ensure the search does not include map bounds
    query = omit(newSearch, coordinateFilters);
  } else {
    // It doesn't make any sense to do a non-location search query without bounds.
    // Note: For legacy purposes, mapBounds would have to be specifically passed in as `null`
    if (!mapBounds) {
      return null;
    }

    query = isEmpty(mapBounds) ? newSearch : { ...mapBounds, ...newSearch };
  }

  return isEqual(query, existingSearch) ? null : query;
}

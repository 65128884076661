import { createReducer } from 'redux-act';

import * as a from './actions';

const defaultState = {
  isSubmitting: false,
  isResModalOpen: false,
  isSuccess: false,
};

export const getUIState = state => state.desktop.account.contactAgent;

export default createReducer(
  {
    [a.autoCloseContactFormSuccessModal]: () => ({ ...defaultState }),

    [a.clickBackInFailureModal]: state => ({ ...state, isResModalOpen: false }),

    [a.closeContactFormSuccessModal]: () => ({ ...defaultState }),

    [a.receiveContactFormRes]: (state, { success }) => ({
      ...state,
      isResModalOpen: true,
      isSuccess: success,
      isSubmitting: false,
    }),

    [a.clickRetryInFailureModal]: state => ({ ...state, isResModalOpen: false }),

    [a.submitContactForm]: state => ({ ...state, isSubmitting: true }),
  },
  defaultState
);

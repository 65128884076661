import { connectBBHoc } from 'hoc/connectBB';
import { tags } from 'BoomTown';
import { connect } from 'react-redux';
import { compose } from 'redux';

import DesktopTagList from './DesktopTagList';
import * as a from './actions';

const applyTagsProps = props => ({
  ...props,
  tags: tags.toJSONWithCid(),
});

function getInitialState() {
  return {
    tags: tags.toJSONWithCid(),
  };
}

export default compose(
  connectBBHoc(
    [
      {
        getEmitter: () => tags,
        events: [{ name: 'reset change', replaceProps: applyTagsProps }],
      },
    ],
    getInitialState
  ),
  connect(
    null,
    { onTagClick: a.tagClick }
  )
)(DesktopTagList);

/* eslint-disable new-cap, space-before-function-paren, func-names, no-mixed-operators */
import $ from 'jquery';
import * as OffCanvasActions from 'actions/OffCanvasActions';
import bt from 'BoomTown';

/**
 * Moves appropriate filter bar and search tags based on screen-size.
 * @return {Boolean|undefined}
 * @public
 */
export function filterBarAppend() {
  filterBarUI();

  bt.events.trigger('resultsHeight');
  bt.events.trigger('filterBarAppend__Complete');
  return undefined;
}

/**
 * A function that does all the work that this file originally did in its
 * outermost scope.
 * @public
 */
export function initialize() {
  // Opening the search menu from details / abbey-{details|gallery}
  $(document).on('click', '.js-filter-bar__trigger', e => {
    e.preventDefault();

    // The store depends on all the Backbone models/collections being
    // instantiated, so this must be dynamically required
    // eslint-disable-next-line
    const { dispatch } = require('store').default;
    const initialSearchCount = bt.listings.TotalItems || bt.listings.length;
    dispatch(OffCanvasActions.open({ initialSearchCount }));
  });

  // Tags appropriate "active" filter bar and search tags area
  // Remove other filter bar (not needed and will screw with input IDs) and search bar instance
  const filterBarTag = function() {
    if (bt.utility.MQ_Medium()) {
      bt.events.trigger('resultsHeight');
    }

    bt.events.trigger('mapHeightSet');
    bt.events.trigger('filterBarTag__Complete');
  };

  bt.utility.optimizedResize.add(() => {
    filterBarAppend();
  });

  const initSearchUI = function() {
    filterBarUI();
    filterBarTag();
  };

  // Server-side load
  let searchUICalculated = false;
  initSearchUI();
  searchUICalculated = true;

  bt.events.once('route', routeObj => {
    if (['results', 'results-gallery', 'results-map', 'listing'].includes(routeObj.route)) {
      if (searchUICalculated === false) {
        initSearchUI();
      }
    }
  });

  // scroll the filters applied section to the top when it's opened
  bt.events.on('ccomp_filters-applied_open', () => {
    const $panel = $('.js-off-canvas-filter');
    const $filtersApplied = $('.js-filters-applied');
    const scrollOffset = $filtersApplied.offset().top - $panel.scrollTop();
    const stickyHeight = $('.js-off-canvas__sticky-header').outerHeight();
    if (scrollOffset !== stickyHeight) {
      $panel.animate({ scrollTop: $panel.scrollTop() + scrollOffset - stickyHeight }, 300);
    }
  });
}

/**
 * Swap toggle/dropdown based on screen-size and re-check height.
 */
var filterBarUI = function() {
  let $filterBar;
  if (bt.utility.MQ_Medium()) {
    $filterBar = $('.js-results__page-header .js-filter-bar');
    $filterBar.find('[data-bt-ccomp]').attr('data-bt-ccomp', 'dropdown');
    // Make sure close the off-canvas
    OffCanvasActions.close();
    $filterBar.find('[data-bt-ccomp=dropdown]').removeClass('bt-ccomp--active');
    $filterBar.find('[data-bt-ccomp-trigger]').removeClass('bt-active');
  } else {
    $filterBar = $('.js-filter-bar').not('.js-results__page-header .js-filter-bar');
    $filterBar.find('[data-bt-ccomp]').attr('data-bt-ccomp', 'toggle');
  }
};

/* eslint-disable import/prefer-default-export */
import { select, call, put } from 'redux-saga/effects';
import { replace } from 'redux-little-router';
import { omit } from 'underscore';
import { search } from 'BoomTown';
import { go } from 'sagas/routerTasks/search';
import { getConversionQueryStringName } from 'models/appConfig/selectors';
import { getCurrentListingID } from 'selectors/listings';

/**
 * Sagas that relate to the view layer, but are reused across a view different
 * components/instances.
 */

/**
 *
 * @param {Object} terms
 */
export function* searchBarSelect(terms) {
  // Redirect to details if suggestion is for a property, and don't redirect
  // to the same page

  // TODO: This might need to be factored back out to the watcher
  if (terms.listingid && !terms.listingid.includes('~')) {
    const currentListingID = yield select(getCurrentListingID);
    if (currentListingID === null || terms.listingid !== String(currentListingID)) {
      yield call(go, {
        pathname: `/listing/${terms.listingid}`,
        query: {},
      });
    }
    return;
  }
  const entries = Object.entries(terms);

  if (!entries.length) {
    return;
  }

  // Limit the suggestion to a single search
  // term for when there may be multiple ids for same listing
  const [termKey, termValue] = entries[0];

  // Get the next search, ensuring that all map attributes are removed. This
  // is a good sign that this state should be maintained separately.
  const previousSearchAttrs = omit(yield call([search, search.toJSON]), [
    'pageindex',
    'pagecount',
    'swlat',
    'swlng',
    'nelat',
    'nelng',
    'midlat',
    'midlng',
    'zoom',
    'polygon',
    'latlonrad',
  ]);

  const query = yield call([search, search.addReducer], previousSearchAttrs, {
    [termKey]: termValue,
  });

  // Would have used the add effect without removing map params above.
  yield call(go, { query });
}

/**
 * Removes the post registration query param from the url
 */
export function* removePostRegQueryParam() {
  const conversionQueryStringName = yield select(getConversionQueryStringName);
  const { [conversionQueryStringName]: _removed, ...restQuery } = yield select(
    (state) => state.router.query
  );

  if (_removed) {
    yield put(replace({ query: restQuery }));
  }
}

/**
 * Augment a promise with an API that allows for cancellation. Straight from
 * https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html.
 */
export default function makeCancelable(promise) {
  let _hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (_hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      error => (_hasCanceled ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    /**
     * Cancel the wrapped promise. If the promise has already been resolved,
     * this is a no-op. Note that this doesn't immediately reject the exposed
     * promise, but instead will reject whenever the wrapped promise is
     * resolved or rejected.
     */
    cancel() {
      _hasCanceled = true;
    }
  };
}

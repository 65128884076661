import { MIN_BATHS_OPTIONS } from 'models/search/constants';

export function getMinBathsOptions(state) {
  return state.specialRules.ShowHalfBaths
    ? MIN_BATHS_OPTIONS.W_HALF_BATHS
    : MIN_BATHS_OPTIONS.WO_HALF_BATHS;
}

export function getMatchDisclaimerFontStyles(state) {
  return state.specialRules.MatchDisclaimerFontStyles;
}

export function getMaxListingResults(state) {
  return state.specialRules.MaxListingResults;
}

export function getShowHalfBaths(state) {
  return state.specialRules.ShowHalfBaths;
}

export function getIncreaseDisclaimerFontSize(state) {
  return state.specialRules.IncreaseDisclaimerFontSize;
}

export function getSpecialRules(state) {
  return state.specialRules;
}

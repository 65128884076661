import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/**
 * @param {Object} props
 * @param {string} props.placement - Arrow placement
 * @param {string} props.status - Provides alternate color scheme
 * @param {any} props.children
 */
const Callout = props => {
  const {
    children,
    placement,
    status,
    className = '',
  } = props;
  const classes = cx(
    'callout',
    className,
    {
      [`callout--${placement}`]: placement,
      [`callout--${status}`]: status,
    }
  );
  return (
    <div className={classes}>
      {children}
    </div>
  );
};

Callout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  placement: PropTypes.oneOf([
    'top-middle',
    'top-left',
    'top-right',
    'bottom-middle',
    'bottom-left',
    'bottom-right',
    'left',
    'right'
  ]),
  status: PropTypes.oneOf([
    'success',
    'danger'
  ]),
};

export default Callout;

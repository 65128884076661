import { call } from 'redux-saga/effects';
import postAppMessage from 'consumerApp/eventBus';

/**
 * Side-effect used by the Map Saga for the onNearbyClick action. Sends the `NEARBY_BUTTON_TAP`
 * AppMessage to the app.
 *
 * @param {boolean} isNearbyActive
 * @export
 */
export default function* handleNearbyButtonTap(isNearbyActive) {
  /** @type {AppMessage} */
  const msg = {
    type: 'NEARBY_BUTTON_TAP',
    view: 'MAP',
    payload: { isNearbyActive },
  };

  yield call(postAppMessage, msg);
}

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <iframe\n        style=\"width: 260px; height: 44px; display: block; margin: 8px auto; border: none; overflow: hidden;\"\n        src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"authGatewaySrc") : depth0), depth0))
    + "\"\n    ></iframe>\n    <div class=\"bt-fb-login__or\"><span>or</span></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"bt-google-login\">\n    <div class=\"bt-google-login__icon\"></div>\n    <span class=\"bt-google-login__text\">Continue with Google</span>\n</div>\n\n<div class=\"bt-google-login-onetap\" style=\"overflow:hidden; display:flex; justify-content: center;\"></div>\n\n"
    + ((stack1 = __default(require("../../../assets/js/helpers/and.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"allowFBLogin") : depth0),(depth0 != null ? lookupProperty(depth0,"authGatewaySrc") : depth0),{"name":"and","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":0},"end":{"line":17,"column":8}}})) != null ? stack1 : "")
    + "\n";
},"useData":true});
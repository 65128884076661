import moment from 'moment-timezone';

// Known date formats we may receive from the API. Passing expected non-ISO 8601 formats helps
// `moment` better parse the date/time string.
export const PARSE_FORMATS = [
  'M/DD/YYYY',
  'M/DD/YYYY h:mm A',
  'M/DD/YYYY hh:mm A',
  'M/DD/YYYY hh:mm:ss A',
  'M/DD/YYYY H:mm',
  'M/DD/YYYY HH:mm',
  'M/DD/YYYY HH:mm:ss',

  'MM/DD/YYYY',
  'MM/DD/YYYY h:mm A',
  'MM/DD/YYYY hh:mm A',
  'MM/DD/YYYY hh:mm:ss A',
  'MM/DD/YYYY H:mm',
  'MM/DD/YYYY HH:mm',
  'MM/DD/YYYY HH:mm:ss',
  moment.ISO_8601,
];


/**
 * Maps to a list of TimeZoneIDs in the CRM. The original list is documented below, however the
 * values we use with moment-timezones needed to be more specific than just basic EST, PST, etc.
 *
 * Original Mapping from CRM:
 * 1 - Eastern
 * 2 - Central
 * 3 - Mountain
 * 4 - Pacific
 * 5 - Arizona
 * 6 - Alaska
 * 7 - Hawaii
 * 8 - Saskatchewan - Observes CST year round
 */
export const TIMEZONE_IDS = {
  0: '',
  1: 'US/Eastern',
  2: 'US/Central',
  3: 'US/Mountain',
  4: 'US/Pacific',
  5: 'America/Phoenix',
  6: 'US/Alaska',
  7: 'US/Hawaii',
  8: 'America/Regina',
  9: 'America/Halifax',
};

/**
 * A utility function that uses momentjs to parse an incoming date string and return a newly
 * formatted string. Accepts an `option` parameter for setting the display format as well as the
 * format(s) to use when parsing the input string if not in standard ISO 8601 format.
 *
 * @export {Function}
 * @param {string} input A Date/Time string
 * @param {Object} [options]
 * @param {string} [options.dateFormat='LL']
 * @param {string} [options.timeFormat='LT']
 * @param {string|Array} [options.parseFormat=PARSE_FORMATS]
 * @param {boolean} [options.timezone=0]
 * @param {boolean} [options.strict=false]
 *
 * @returns {Object} {{ date: string, time: string }}
 * @returns {string} A Date/Time String
 * @returns null or undefined
 */
export const formatDateTime = (input, options) => {
  if (!input) {
    return input;
  }

  options = {
    dateFormat: 'LL',
    timeFormat: 'LT',
    parseFormat: PARSE_FORMATS,
    strict: false,
    timezone: 0,
    ...options,
  };

  let parsedDateTime;
  if (options.timezone) {
    parsedDateTime = moment.tz(
      input,
      options.parseFormat,
      options.strict,
      TIMEZONE_IDS[options.timezone]
    );

    // If we're parsing with a timezone, make sure that timezone is reflected in the display format
    options.timeFormat = `${options.timeFormat} z`;
  } else {
    parsedDateTime = moment(input, options.parseFormat, options.strict);
  }

  if (!parsedDateTime.isValid()) {
    return input;
  }

  let date = null;
  let time = null;

  if (options.timezone) {
    date = parsedDateTime.format(options.dateFormat);
    time = parsedDateTime.format(options.timeFormat);
  } else {
    date = parsedDateTime.tz('America/New_York').format(options.dateFormat);
    time = parsedDateTime.tz('America/New_York').format(options.timeFormat);
  }

  return {
    date,
    time,
  };
};

/**
 * Takes a start and end DateTime string and an optional timezone id and returns a boolean
 * designating whether or not the current moment falls between the start and end times provided.
 *
 * @export {Function}
 * @param {string} start A DateTime string
 * @param {string} end A DateTime string
 * @param {1|2|3|4|5|6|7|8} [timezone=0] Timezone ID. See TIMEZONE_IDS.
 * @returns {boolean}
 */
export const isNowBetween = (start, end, timezone = 0) => {
  let startTime = start;
  let endTime = end;
  const now = moment();

  if (timezone) {
    const tz = TIMEZONE_IDS[timezone] || 1;

    startTime = moment.tz(startTime, PARSE_FORMATS, tz);
    endTime = moment.tz(endTime, PARSE_FORMATS, tz);
  } else {
    startTime = moment(startTime, PARSE_FORMATS);
    endTime = moment(endTime, PARSE_FORMATS);
  }

  if (!startTime.isValid() || !endTime.isValid()) {
    return false;
  }

  return now.isBetween(startTime, endTime);
};

import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'redux-little-router';
import { RESULTS_GALLERY, RESULTS_MAP } from 'routes';
import * as a from 'actions/MobileGalleryActions';

export default [
  takeEvery(a.clickMapButtonInResultsHeader, function* goToMap() {
    yield put(push(`/${RESULTS_MAP}/`, { persistQuery: true }));
  }),
  takeEvery(a.clickListButtonInResultsHeader, function* goToGallery() {
    yield put(push(`/${RESULTS_GALLERY}/`, { persistQuery: true }));
  }),
];

/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal as ReactOverlayModal } from 'react-overlays';
import cx from 'classnames';
import { ContentFade, BackdropFade } from './animations';

/**
 * Modal component that uses v.0.8.0 of `Modal` from `react-overlays` to standardize some
 * layout and prop values while adding a few additional props for customization.
 * @see https://react-bootstrap.github.io/react-overlays/#modals
 *
 * @todo Setup a "close" button once we decide what close buttons for new modals look like
 * @todo Setup functionality for handling `modal--gradient` for registration modals
 *
 * @param {Object} props
 * @param {any} props.children
 *
 * Custom Props
 * @param {boolean} props.animate
 * @param {string} props.modalClassName
 * @param {string} props.wrapperClassName
 * @param {string} props.contentClassName
 *
 * `React-Overlays` Props
 * @param {boolean} props.autoFocus
 * @param {boolean | string} props.backdrop
 * @param {string} props.backdropClassName
 * @param {Object} props.backdropStyle
 * @param {React.ReactElement} props.backdropTransition
 * @param {number} props.backdropTransitionTimeout
 * @param {React.ReactElement | Function} props.container
 * @param {string} props.containerClassName
 * @param {number} props.dialogTransitionTimeout
 * @param {boolean} props.enforceFocus
 * @param {boolean} props.keyboard
 * @param {Function} props.onBackdropClick
 * @param {Function} props.onEnter
 * @param {Function} props.onEntered
 * @param {Function} props.onEscapeKeyUp - Deprecated in next version (v0.8.1)
 * @param {Function} props.onExit
 * @param {Function} props.onExited
 * @param {Function} props.onExiting
 * @param {Function} props.onHide
 * @param {Function} props.onShow
 * @param {Function} props.renderBackdrop
 * @param {boolean} props.restoreFocus
 * @param {boolean} props.show
 * @param {React.ReactElement} props.transition
 */
const Modal = props => {
  const {
    animate,
    backdropClassName,
    modalClassName,
    contentClassName,
    children,
    show,
    ...rest
  } = props;
  return (
    <ReactOverlayModal
      className={cx('modal', modalClassName)}
      backdropClassName={cx('modal__backdrop', backdropClassName)}
      transition={animate && ContentFade}
      backdropTransition={animate && BackdropFade}
      show={show}
      {...rest}
    >
      <div className={cx('modal__content', contentClassName)}>
        {children}
      </div>
    </ReactOverlayModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,

  // Custom Props
  animate: PropTypes.bool,
  modalClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  contentClassName: PropTypes.string,

  // Props from `react-overlays`
  /**
   * Set the visibility of the Modal
   */
  show: PropTypes.bool,

  /**
   * A Node, Component instance, or function that returns either. The Modal is appended to it's container element.
   *
   * For the sake of assistive technologies, the container should usually be the document body, so that the rest of the
   * page content can be placed behind a virtual backdrop as well as a visual one.
   */
  container: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func
  ]),

  /**
   * A callback fired when the Modal is opening.
   */
  onShow: PropTypes.func,

  /**
   * A callback fired when either the backdrop is clicked, or the escape key is pressed.
   *
   * The `onHide` callback only signals intent from the Modal,
   * you must actually set the `show` prop to `false` for the Modal to close.
   */
  onHide: PropTypes.func,

  /**
   * Include a backdrop component.
   */
  backdrop: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['static'])
  ]),

  /**
   * A function that returns a backdrop component. Useful for custom
   * backdrop rendering.
   *
   * ```js
   *  renderBackdrop={props => <MyBackdrop {...props} />}
   * ```
   */
  renderBackdrop: PropTypes.func,

  /**
   * Support for this function will be deprecated in v0.8.1 of `react-overlays` in favor of
   * `onEscapeKeyDown`
   * A callback fired when the escape key, if specified in `keyboard`, is pressed.
   * @see https://github.com/react-bootstrap/react-overlays/pull/195
   */
  onEscapeKeyUp: PropTypes.func,

  /**
   * A callback fired when the backdrop, if specified, is clicked.
   */
  onBackdropClick: PropTypes.func,

  /**
   * A style object for the backdrop component.
   */
  backdropStyle: PropTypes.object,

  /**
   * A css class or classes for the backdrop component.
   */
  backdropClassName: PropTypes.string,

  /**
   * A css class or set of classes applied to the modal container when the modal is open,
   * and removed when it is closed.
   */
  containerClassName: PropTypes.string,

  /**
   * Close the modal when escape key is pressed
   */
  keyboard: PropTypes.bool,

  /**
   * A `react-transition-group@2.0.0` `<Transition/>` component used
   * to control animations for the dialog component.
   */
  transition: PropTypes.element,

  /**
   * A `react-transition-group@2.0.0` `<Transition/>` component used
   * to control animations for the backdrop components.
   */
  backdropTransition: PropTypes.element,

  /**
   * When `true` The modal will automatically shift focus to itself when it opens, and
   * replace it to the last focused element when it closes. This also
   * works correctly with any Modal children that have the `autoFocus` prop.
   *
   * Generally this should never be set to `false` as it makes the Modal less
   * accessible to assistive technologies, like screen readers.
   */
  autoFocus: PropTypes.bool,

  /**
   * When `true` The modal will prevent focus from leaving the Modal while open.
   *
   * Generally this should never be set to `false` as it makes the Modal less
   * accessible to assistive technologies, like screen readers.
   */
  enforceFocus: PropTypes.bool,

  /**
   * When `true` The modal will restore focus to previously focused element once
   * modal is hidden
   */
  restoreFocus: PropTypes.bool,

  /**
   * Callback fired before the Modal transitions in
   */
  onEnter: PropTypes.func,

  /**
   * Callback fired as the Modal begins to transition in
   */
  onEntering: PropTypes.func,

  /**
   * Callback fired after the Modal finishes transitioning in
   */
  onEntered: PropTypes.func,

  /**
   * Callback fired right before the Modal transitions out
   */
  onExit: PropTypes.func,

  /**
   * Callback fired as the Modal begins to transition out
   */
  onExiting: PropTypes.func,

  /**
   * Callback fired after the Modal finishes transitioning out
   */
  onExited: PropTypes.func,
};

export default Modal;

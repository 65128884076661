import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { visitor as visitorModel, config } from 'BoomTown';
import { connectBBHoc } from 'hoc/connectBB';

import { gravatarError } from 'screens/MobileMenu/actions';
import { clickEditInfoLink, clickClose } from 'screens/Account/Mobile/MobileAccountActions';
import { getVisitorData } from 'models/visitor/reducers/selectors';

import { BackButton } from 'components/Common/MobileScreen';
import UserInfoHeader from 'components/Common/User/UserInfoHeader';
import MenuLayout from 'components/MobileMenu/MenuLayout';
import { LinkListItem, LinkList } from 'components/MobileMenu/LinkList';
import { MOBILE_ACCOUNT } from 'cypress_constants';


const MenuItem = ({ children, ...props }) => (
  <LinkListItem {...props} icon="angle-right">
    {children}
  </LinkListItem>
);
MenuItem.propTypes = {
  children: PropTypes.string.isRequired,
};

const MobileAccount = ({ visitor, onBackClick, onEditInfoClick, onGravatarError }) => (
  <MenuLayout topLeftButton={<BackButton onClick={onBackClick} color="primary" />}>
    <UserInfoHeader
      visitor={visitor}
      avatarClass="at-account__avatar"
      onEditInfoClick={onEditInfoClick}
      onGravatarError={onGravatarError}
    />
    <LinkList>
      <MenuItem
        className="at-email-pref-btn"
        href="/account/email-preferences/"
        dataCY={MOBILE_ACCOUNT.EMAIL_PREFERENCES_BUTTON}
      >
        Email Preferences
      </MenuItem>
      {config.enableTextPreferences &&
      // CNS-6795: Text Pref feature flag
        <MenuItem
          className="at-text-pref-btn"
          href="/account/text-preferences/"
          dataCY={MOBILE_ACCOUNT.TEXT_MESSAGE_PREFERENCES_BUTTON}
        >
          Text Preferences
        </MenuItem>
      }
      <MenuItem
        className="at-change-pass-btn"
        href="/account/change-password/"
        dataCY={MOBILE_ACCOUNT.CHANGE_PASSWORD_BUTTON}
      >
        Change Password
      </MenuItem>
    </LinkList>
  </MenuLayout>
);

MobileAccount.propTypes = {
  visitor: PropTypes.object.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onEditInfoClick: PropTypes.func.isRequired,
  onGravatarError: PropTypes.func.isRequired,
};

const connectToVisitorModel = connectBBHoc(
  [
    {
      getEmitter: () => visitorModel,
      events: [
        {
          name: 'change',
          replaceProps: (prevProps, model) => ({ visitor: getVisitorData(model) }),
        },
      ],
    },
  ],
  () => ({ visitor: getVisitorData(visitorModel) })
);

const mapDispatchToProps = connect(
  null,
  {
    onGravatarError: gravatarError,
    onEditInfoClick: clickEditInfoLink,
    onBackClick: clickClose,
  }
);

export default compose(
  connectToVisitorModel,
  mapDispatchToProps,
)(MobileAccount);

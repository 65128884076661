import React, { useState, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { flatten } from 'underscore';
import { InputBase, TextButton } from 'coreComponents';
import useEventCallback from 'hooks/useEventCallback';
import FAIcon from 'components/core/FAIcon';
import useTimeout from 'hooks/useTimeout';
import AutocompleteResults from './Results';

/**
   * Cleans a search string before being sent to the server.
   * Cleans bad characters and replaces them with spaces, furthermore trims spaces.
   *
   * @param {string} val
   * @return {string}
   */
export const filterInputValue = (val) => {
  val = val.replace(/[\+\&\|\!\(\)\{\}\[\]\^\"\~\*\?\:\\]/g, ' ');
  val = val.replace(/[\s]{2,}/g, ' ');
  return val.trim();
};

const Autocomplete = React.forwardRef((props, ref) => {
  const {
    activeIndex,
    activeSearchType,
    debounceLength = 250,
    inputProps,
    hasError,
    inputDisabled,
    placeholderText,
    suggestions,
    fetchSuggestions,
    handleItemSelect,
    handleSetActiveIndex,
  } = props;

  /** @type {import('react').Ref} */
  const inputRef = useRef();

  /**
   * Other native DOM methods we want to expose from the input instance value to parent components
   * when using refs.
   *
   * @see https://reactjs.org/docs/hooks-reference.html#useimperativehandle
   */
  useImperativeHandle(
    // Ref that comes from forwardRef
    ref,

    // We tell React to expose the return value
    // to the parent component using ref
    () => ({
      value: inputRef.current.value,
      focus: () => {
        inputRef.current.focus();
      },
    })
  );

  const [
    /** @type {string} The value of the input field */
    value,
    setValue,
  ] = useState('');

  const [
    /** @type {boolean} Whether the suggestion dropdown is active */
    menuActive,
    setMenuActive,
  ] = useState(false);

  /** @type {import('react').Ref} */
  const blurTimer = useRef();
  React.useEffect(() => {
    if (!blurTimer.current) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(blurTimer.current);
  }, []);

  useTimeout(
    () => {
      const val = filterInputValue(value);
      fetchSuggestions(val);
    },
    debounceLength,
    [value]
  );

  /**
   * A set of event listeners used for navigating the the suggestion dropdown via the keyboard.
   *
   * @type {Object.<string, (Event) => void>}
   */
  const eventListeners = {
    ArrowDown: (event) => {
      event.preventDefault();

      // If the menu isn't visible, this event should be a no-op
      if (!menuActive) {
        return;
      }

      let currentIndex = 0;

      if (activeIndex !== null && activeIndex >= 0) {
        currentIndex = activeIndex;
        // Make sure our selected index doesn't trail off into infinity
        const maxIndex = getMaxIndex();
        if (currentIndex < maxIndex) {
          currentIndex++;
        }
      }

      // Update the activeIndex value to be fed into the component's props
      handleSetActiveIndex(currentIndex);
    },

    ArrowUp: (event) => {
      event.preventDefault();

      if (!menuActive) {
        return;
      }

      let currentIndex = 0;

      if (activeIndex !== null && activeIndex >= 0) {
        currentIndex = activeIndex;

        // We don't want a negative index value
        if (currentIndex > 0) {
          currentIndex--;
        }
      }

      handleSetActiveIndex(currentIndex);
    },

    Enter: (event) => {
      event.preventDefault();

      if (!menuActive) {
        return;
      }

      const flattenedSuggestions = flattenSuggestions(suggestions);
      const filteredSuggestionsArr = flattenedSuggestions.filter(
        (item) => item.index === activeIndex
      );
      const suggestion = filteredSuggestionsArr[0];

      setMenuActive(false);

      /**
       * CNS-3853
       * The following function updates the state of our component in order to
       * dismiss the Autocomplete menu and then execute the search in the form
       * of a callback
       */
      // This was originally a callback passed to `setState()`, so I need to test if calling it here
      // is ok or if I need to setup a `useEffect` hook or potentially find another solution.
      handleItemSelect(suggestion);
    },

    Escape: () => setMenuActive(false),
  };

  /**
   *
   * @param {{items: SearchSuggestion[], name: string}[]} data
   * @returns {SearchSuggestion[]}
   */
  const flattenSuggestions = (data) => flatten(Object.keys(data).map((item) => data[item].items));

  /**
   * Sets a max index value that can be selected when using the Arrow keys to navigate down the
   * list of items in the rendered suggestion list.
   *
   * @return {number}
   */
  const getMaxIndex = () => {
    // If no suggestions exist, func is a no-op
    if (!suggestions) {
      return null;
    }

    const items = flattenSuggestions(suggestions);

    // We need to shorten the available indexes by one because the suggestion index begins at zero.
    return items.length - 1;
  };

  /**
   * Set the `value` state when the Autocomplete input changes.
   *
   * @param {Event}
   */
  const handleChange = (event) => {
    if (menuActive === false) {
      setMenuActive(true);
    }

    setValue(event?.target?.value);
  };

  /**
   * Handles the click inside hte Autocomplete input field.
   */
  const handleClick = () => {
    fetchSuggestions();
    setMenuActive(true);
  };

  /**
   * Add delay in order to give click handler enough time to execute
   * and perform search when an option is clicked. This does not work
   * without the delay
   */
  const handleBlur = useEventCallback(() => {
    blurTimer.current = setTimeout(() => setMenuActive(false), 150);
  });

  /**
   * Handles calling the correct event listener for the corresponding keypress.
   * @param {Event} event
   */
  const handleKeyDown = (event) => {
    if (eventListeners[event.key]) {
      eventListeners[event.key].call(null, event);
    }
  };

  /**
   * Handles the user's click on the X icon inside the input field when a value exists.
   */
  const handleClear = () => {
    setMenuActive(false);
    setValue('');

    inputRef.current.focus();
  };

  /**
   * Renders the suggestions dropdown UI when the Autocomplete input field is focussed.
   * @returns {import('react').ReactElement}
   */
  const renderSuggestions = () => {
    if (suggestions.length > 0) {
      return (
        <AutocompleteResults
          activeIndex={activeIndex}
          handleItemSelect={handleItemSelect}
          handleSetActiveIndex={handleSetActiveIndex}
          suggestions={suggestions}
          renderHelpText={value.length === 0 && activeSearchType !== 'all'}
        />
      );
    }

    return null;
  };

  const inputClasses = cx({
    [inputProps.className]: inputProps?.className,
    'bt-esearch--error': hasError,
  });

  return (
    <>
      <InputBase
        {...inputProps}
        className={inputClasses}
        role="combobox"
        aria-autocomplete="list"
        aria-expanded={menuActive}
        autoComplete="off"
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={handleClick}
        onFocus={() => setMenuActive(true)}
        onKeyDown={handleKeyDown}
        placeholder={placeholderText}
        ref={inputRef}
        value={value}
        disabled={inputDisabled}
      />

      {value.length > 0 && (
        <TextButton className="form__input-suffix" onClick={handleClear}>
          <FAIcon icon="times" type="solid" />
        </TextButton>
      )}

      {menuActive && renderSuggestions(value)}
    </>
  );
});

Autocomplete.displayName = 'Autocomplete';

Autocomplete.propTypes = {
  /** @type {?number} activeIndex */
  activeIndex: PropTypes.number,

  activeSearchType: PropTypes.string,

  className: PropTypes.string,

  hasError: PropTypes.bool,

  debounceLength: PropTypes.number,

  fetchSuggestions: PropTypes.func.isRequired,

  /** @type {Function} */
  handleItemSelect: PropTypes.func.isRequired,

  handleSetActiveIndex: PropTypes.func.isRequired,

  /**
   * Any normal props/attributes that could be passed to an input element or the InputBase
   * component.
   *
   * @type {Object}
   */
  inputProps: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    maxLength: PropTypes.string, // React requires max length to be a string
  }),

  /** @type {string} placeholderText */
  placeholderText: PropTypes.string,

  suggestions: PropTypes.array.isRequired,

  inputDisabled: PropTypes.bool,
};

export default Autocomplete;

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import FAIcon from 'components/core/FAIcon';
import { Link } from 'redux-little-router';
import { connect } from 'react-redux';

/**
 * A button that is used to toggle between the different results views in the
 * MobileSearchHeader.
 * @param {Object} props
 */
function ViewToggleButton({ resultsView, nextLocation }) {
  const icon = resultsView === 'list' ? 'map-marker' : 'list';
  const buttonText = resultsView === 'list' ? 'Map' : 'List';

  return (
    <Link href={nextLocation} className="width-1-1 btn btn--secondary" role="button">
      <FAIcon icon={icon} /> {buttonText}
    </Link>
  );
}

ViewToggleButton.propTypes = {
  resultsView: PropTypes.string,
  nextLocation: PropTypes.object.isRequired,
};

export default connect(state => {
  const isListView = state.router.result && state.router.result.id === 'results-gallery';
  const currentQuery = state.router.query;
  const mapParams = ['swlat', 'swlng', 'nelat', 'nelng', 'midlat', 'midlng', 'zoom'];

  return {
    nextLocation: isListView
      ? { pathname: '/results-map/', query: currentQuery }
      : { pathname: '/results-gallery/', query: _.omit(currentQuery, mapParams) },
  };
})(ViewToggleButton);

/* eslint-disable react/prop-types */
import React from 'react';
// TODO Update and refactor `react-transition-group`
// Fixes an issue with the version of `react-transition-group` we have as a dependency
// compared to the version `react-overlays` uses and needs here for this implementation
import Transition, { ENTERED, ENTERING, EXITING } from 'react-overlays/node_modules/react-transition-group/Transition';

// Should correlate with animation duration in `scss/flagship/core/_modal.scss`
// 300ms total for entering, 200ms for exiting, but having an extra buffer here
// is OK.
const ANIMATION_DURATION = 300;

const contentFadeStyles = {
  [ENTERING]: 'modal__content--animate-in',
  [ENTERED]: 'modal__content--animate-in',
  [EXITING]: 'modal__content--animate-out',
};

export const ContentFade = ({ children, ...props }) => (
  <Transition {...props} timeout={{ enter: 300, exit: 200 }}>
    {(status, innerProps) => React.cloneElement(children, {
      ...innerProps,
      className: `modal__content--animate ${contentFadeStyles[status]} ${children.props.className}`
    })}
  </Transition>
);

const backdropFadeStyles = {
  [ENTERING]: 'modal__backdrop--animate-in',
  [ENTERED]: 'modal__backdrop--animate-in',
};

export const BackdropFade = ({ children, ...props }) => (
  <Transition {...props} timeout={(ANIMATION_DURATION / 2)}>
    {(status, innerProps) => React.cloneElement(children, {
      ...innerProps,
      className: `modal__backdrop--animate ${backdropFadeStyles[status]} ${children.props.className}`
    })}
  </Transition>
);

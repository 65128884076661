import { createAction } from 'redux-act';
import { listings as listingCollection } from 'BoomTown';
import { createUpdateCountAction } from 'actions/OffCanvasActions';

export const clickSearch = createAction('Click search', () => ({
  count: listingCollection.TotalItems || listingCollection.length,
  scrollY: window.scrollY || 0,
}));

export const clickNewSearch = createUpdateCountAction('CLICK_NEW_SEARCH', () => ({
  scrollY: window.scrollY || 0,
}));

export const selectSort = createAction(
  'In-between mobile results header > Select sort',
  /**
   * @param {String} x
   */
  id => id
);

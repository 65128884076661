import { put, select } from 'redux-saga/effects';
import { goBack, push } from 'redux-little-router';

export default function* maybeGoBack() {
  const locationChanges = yield select(s => s.locationChanges);
  if (locationChanges > 1) {
    yield put(goBack());
  } else {
    yield put(push('/'));
  }
}

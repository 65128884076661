import PropTypes from 'prop-types';
import React from 'react';

export default function Phone({ number, type }) {
  return (
    <a
      className="width-1-1 js-call-agent bt-listing__agent__call-agent"
      href={`tel:${number}`}
    >
      <span className="bt-listing__agent__info_phone__type">{type}: </span>
      <span itemProp="telephone">{number.replace(/-/g, '.')}</span>
    </a>
  );
}

Phone.propTypes = {
  number: PropTypes.string,
  type: PropTypes.string
};

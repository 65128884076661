/* eslint-disable no-param-reassign, func-names, space-before-function-paren */
import $ from 'jquery';
import _ from 'underscore';
import UIkit from 'uikit';
import BaseModel from 'legacy/Base/model';
import bt from 'BoomTown';
import { registerData } from 'constants/registrationForms';
import { updateVisitor } from 'actions/visitorActions';

/**
 * @typedef {Object} Attributes
 *
 * @property {number | string} _ID WebVisitorID, the primary identifier
 * @property {string} VisitID Session identifier
 * @property {string} Username Typically an email address
 *
 * @property {string} FirstName
 * @property {string} LastName
 * @property {string} BestPhone
 * @property {string} City
 * @property {string} State
 * @property {string} ZipCode
 *
 * @property {number} ActiveSearchCount The number of saved searches
 * @property {number} AgentID
 * @property {number} BuyerAgentID
 * @property {number} FavoriteCount
 * @property {number[]} Favorites
 * @property {number} FullViews
 * @property {boolean} IsBuyerLead
 * @property {boolean} IsRegistered
 * @property {boolean} IsSellerLead
 * @property {number} LenderID From window.widget_lender.id
 * @property {string} OriginalUrl
 * @property {string} Referrer
 * @property {number} TenantID
 * @property {boolean} hasGravatar
 */

export default class Visitor extends BaseModel {
  get idAttribute() {
    return '_ID';
  }

  get defaults() {
    return {
      FullViews: 0,
      hasGravatar: true,
    };
  }

  initialize() {
    this.readCookie();
    this.readVisitorDetails();
    this.readUrl();

    if (!this.get('Username')) {
      this.set('hasGravatar', false);
    }

    // Log previews for certain events
    this.listenTo(window.bt.events, '/sidebaritem/click', function(e, id) {
      this.addView(id, false);
    });

    this.listenTo(window.bt.events, '/listing/preview', function(id) {
      this.addView(id, false);
    });

    this.listenTo(window.bt.events, 'loancalculated', function(id) {
      this.logAction(id, 'LoanCalculated');
    });

    this.listenTo(window.bt.events, 'logPrinting', function(id) {
      this.logAction(id, 'Printed');
    });

    // The listing data needs to be available when logging the view in order to
    // check the status. Doing this in an onRoute method in the
    // SingleListingView class isn't viable because it won't be invoked the first
    // time the 'route' event is processed during a server-side page load.
    // (See: http://jsbin.com/kipayi/edit?js,console,output)
    this.listenTo(window.bt.events, 'listingData', function() {
      const code = window.bt.listing.get('StatusCode');
      if (['A', 'AC', 'S', 'P', 'CS'].includes(code)) {
        this.showRegForm();
        this.addView(window.bt.listing.id, true);
      }
    });

    // Dismiss the reg. modal with the end key
    const namespace = 'keydown.bt.regform';
    $('body').on(namespace, e => {
      if (e.which === 35) {
        e.preventDefault();
        UIkit.modal('#user-action-modal').hide();
      }
    });

    this.on('change', visitor => {
      /* eslint-disable global-require */
      const store = require('store');
      /* eslint-enable */
      if (store.default) {
        store.default.dispatch(updateVisitor(visitor.toJSON()));
      }
    });
  }

  fullName() {
    return `${this.get('FirstName')} ${this.get('LastName')}`;
  }

  /**
   * Is the visitor a bot?
   *
   * @returns {boolean}
   */
  isBot() {
    return this.id === -1;
  }

  isRegistered() {
    const isRegistered = this.get('IsRegistered');
    if (isRegistered) {
      return true;
    }

    // A bug where we haven't flagged the user as registered, but they have gone through _some_
    // registrable action we didn't catch
    const firstName = this.get('FirstName');
    const isFirstNameValid = firstName != null && firstName !== '' && firstName !== 'Guest';

    if (isFirstNameValid) {
      // CNS-5502: Only log these if we are not a crawler.
      // Assume that if a crawler, just allow them to be "registered"
      if (!this.isBot()) {
        // The data cookie is in an inconsistent state
        this.log('info', 'Visitor.js: re-setting cookie');
      }

      this.set('IsRegistered', true);
      this.writeCookie();

      return true;
    }

    return false;
  }

  log(type, label) {
    if (type == null) {
      type = 'info';
    }

    const vals = {
      label,
      Url: location.href,
      UserAgent: navigator.userAgent,
      DataCookie: $.cookie(window.bt.config.dataCookieName),
      ConversionCookie: $.cookie('ConversionType'),
      currentVisitorAttrs: this.attributes,
    };
    return window.bt.global.graylog(type, vals);
  }

  updateContactType(newType) {
    // contact type order
    // visitor >> registrant >> lead >> prospect
    const typeArr = ['visitor', 'registrant', 'lead', 'prospect'];

    const existingType = $.cookie('ContactType');

    if (existingType != null) {
      if (typeArr.indexOf(existingType) > typeArr.indexOf(newType)) {
        newType = existingType;
      }
    }

    // set type Cookie
    window.bt.global.cookie('ContactType', newType);
    return newType;
  }

  readCookie() {
    // grab id from auth cookie
    if (!this.id) {
      const auth = $.cookie(window.bt.config.authCookieName);
      // if it's a bot, we may not have cookie
      if (!auth) {
        return;
      }
      this.set(this.idAttribute, parseInt(auth, 10));
    }

    // Phone is initially picked up from visitorDetails
    // but it might exist in the data cookie too
    this.set('BestPhone', bt.visitorDetails.BestPhone || '');

    // Data Cookie
    const obj = this.parseAttributesFromDataCookie();
    this.set(obj, { silent: true });
  }

  readVisitorDetails() {
    if (bt.visitorDetails) {
      this.set(bt.visitorDetails);
    }
  }

  writeCookie() {
    const attr = _.omit(this.toJSON(), ['hasGravatar']);

    // Join the array of Favorite Ids so that we only have one Favorites param in the cookie. Fixes
    // a parsing issue where we'd end up with an extra `Favorites[]` attribute on the visitor model
    const favs = this.get('Favorites') || [];
    if (favs.length > 0) {
      attr.Favorites = favs.join();
    }

    const value = $.param(attr);
    window.bt.global.cookie(window.bt.config.dataCookieName, value);
  }

  readUrl() {
    this.isAgent = /is[aA]gent=/i.test(location.search);
    this.isCraigs = /utm_source=CraigsList/.test(location.search);
    this.showForm = /req=1/i.test(location.search);
  }

  /**
   * Update this model's Favorites and FavoriteCount attributes with the corresponding values from
   * the visitorModel. Don't ask me why we do this. I believe it's some sort of legacy
   * implementation that would take a lot of untangling, so it's best saved for when we convert
   * everything to Redux.
   *
   * Also update our BoomTownData cookie with the new values
   */
  updateFavorites() {
    this.set({
      Favorites: window.bt.visitorDetails.Favorites,
      FavoriteCount: window.bt.visitorDetails.Favorites.length,
    });
    this.writeCookie();
  }

  updateSearchCount(direction) {
    return this._updateCount('ActiveSearchCount', direction);
  }

  _updateCount(key, direction) {
    let count = parseInt(this.get(key), 10);
    if ($.isNumeric(count)) {
      if (direction === 'up') {
        count++;
      } else if (count > 0) {
        count--;
      }
    }
    this.set(key, count);
    return this.writeCookie();
  }

  addView(listingID, fullView) {
    // Bots are identified by PHP and given a visitor ID of -1. `@id` will be 0
    // because PHP doesn't set an auth cookie in this case.
    if (fullView == null) {
      fullView = false;
    }
    if (window.bt.visitorDetails._ID === -1) {
      return false;
    }

    if (!this.id) {
      window.bt.utility.graylog('error', {
        label: `\`visitor.js@addView()\` called for non-bot with an id of ${
          this.id
        }. Not logging listing view.`,
        fullMessage: `\
listingID: ${listingID}
bt.visitor:
${JSON.stringify(window.bt.visitor, null, 2)}
bt.visitorDetails:
${JSON.stringify(window.bt.visitorDetails, null, 2)}
window.location.href: ${window.location.href}\
`,
      });
      return false;
    }

    if (fullView) {
      const views = this.get('FullViews');
      this.set('FullViews', parseInt(views, 10) + 1);
      this.writeCookie();
    }

    const jsVisitID = this.get('VisitID') || window.bt.visitDetails._ID;

    // http://codepen.io/anon/pen/azxxjq?editors=101
    if (!_.isString(listingID) && !_.isNumber(listingID)) {
      return false;
    }
    if (listingID === '') {
      return false;
    }

    const qs = {
      listingID,
      visitID: jsVisitID,
      fullView,
      action: 'ajax_submit',
      access_token: window.bt.config.token,
    };

    // If the user has performed a nearby search, send their location.
    if (fullView) {
      const { lat, lng } = window.bt.location;
      if (lat && lng) {
        _.extend(qs, {
          deviceLat: lat,
          deviceLng: lng,
        });
      }
    }

    const url = `${window.bt.config.apiUrl}/lc/1/leads/${this.id}/loglistingview/?${$.param(qs)}`;
    $.get(url);
    return true;
  }

  logAction(listingID, type) {
    // Exclude bot traffic
    let url;
    if (window.bt.visitorDetails._ID === -1) {
      return;
    }

    const visitID = this.get('VisitID') || window.bt.visitDetails._ID;
    const visitorID = this.id || window.bt.visitorDetails._ID;

    if (type === 'SendListing') {
      url = `${window.bt.config.apiUrl}/lc/1/leads/${visitorID}/sendlisting`;
    } else {
      url = `${window.bt.config.apiUrl}/lc/1/leads/${visitorID}/contactform`;
    }

    const data = {
      listingID,
      type,
      visitID,
      visitorID,
      action: 'ajax_submit',
      access_token: window.bt.config.token,
    };

    if (visitorID !== undefined) {
      $.post(url, data);
      return;
    }
    window.bt.utility.graylog('error', {
      label: `Attempted to send req. with undefined visitor ID to ${url} from visitor.js#logAction().`,
      fullMessage: `\
listingID: ${listingID}
bt.visitor:
${JSON.stringify(window.bt.visitor, null, 2)}
bt.visitorDetails:
${JSON.stringify(window.bt.visitorDetails, null, 2)}
window.location.href: ${window.location.href}\
`,
    });
  }

  toggleFav(listingid, adding) {
    if (adding) {
      // EC-749: New visits are not coming down with a Favorites array preinitialized
      // Check to see if it is null or undefined when creating a favorite, and if so, initialize
      if (window.bt.visitorDetails.Favorites != null) {
        window.bt.visitorDetails.Favorites.push(listingid);
      } else {
        window.bt.visitorDetails.Favorites = [listingid];
      }
    } else {
      window.bt.visitorDetails.Favorites = window.bt.global.arrayRemove(
        window.bt.visitorDetails.Favorites,
        listingid
      );
    }
    // @updateFavorites direction
    this.updateFavorites();
    const url = `/visitor/favorite/?listingid=${listingid}&adding=${adding}`;
    return $.get(url);
  }

  needsToRegister() {
    if (this.isRegistered() || this.isAgent) {
      return false;
    }

    return true;
  }

  /**
   * Determine if we should show the registration modal
   *
   * @returns {boolean} Whether or not the visitor was squeezed
   */
  showRegForm() {
    if (this.isRegistered()) {
      return false;
    }
    if (this.isAgent) {
      return false;
    }

    // CNS-1304: Suppress the registration form if on a brand-level site in a multi-site approach
    if (_.has(window.bt.config, 'isBrandLevelSite')) {
      return false;
    }

    // How many full page views before registration
    const isBelowMaxViews = this.get('FullViews') < window.bt.config.maxviews;

    // Overrides the maxview setting
    const showWithOverride = Boolean(this.isCraigs || this.showForm);

    // TODO: Should be replaced when the mobile app user agent string is implemented
    const showForm = !bt.config.isMobileApp && (!isBelowMaxViews || showWithOverride);
    if (showForm) {
      window.bt.app.squeezeForm(registerData.urlParam, false, false);
    }
    return showForm;
  }

  _request(obj, type, successCallback, errorCallback) {
    if (successCallback == null) {
      successCallback = $.noop;
    }
    if (errorCallback == null) {
      errorCallback = $.noop;
    }
    if (typeof successCallback === 'undefined') {
      successCallback = $.noop;
    }
    if (typeof errorCallback === 'undefined') {
      errorCallback = $.noop;
    }
    const querystring = $.param(obj);
    const timestamp = new Date().getTime();

    let url = '';
    if (window.bt.config.contactFormMigration && type === 'contactForm') {
      url = `/index.php?action=contact&${querystring}&cachebuster=${timestamp}`;
    } else {
      url = `/visitor/${type}/?${querystring}&cachebuster=${timestamp}`;
    }

    this.trigger('ContactForm', 'request', type, obj);

    return $.post(url, (...args) => {
      // Success Trigger
      const fnSuccess = () => {
        // BTGA4: CNS-8653
        if (typeof window.BoomTownGA4 !== 'undefined') {
          window.BoomTownGA4.eventFormSubmitLegacy(
            obj.type,
            !bt.visitor.isRegistered()
          );
        }

        this.trigger('ContactForm', 'success', type, obj, args);
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'formSubmitted',
            formtype: type,
          });
        }
        return successCallback.apply(this, args);
      };

      //  Test for Legacy Response (Registration, For Example)
      if (args.length === 0 || args[0] === 'success') {
        return fnSuccess();
      } else if (args.length >= 2 && obj && obj.VisitorDetailsResponse && args[1] && args[1] === 'success') {
        return fnSuccess();
      }

      // Test for New-World JSON Response
      const errorResponse = { error: true, reason: 'UKNOWN_FAILURE' };
      let response = '';

      try {
        response = JSON.parse(args[0]);
      } catch {
        response = errorResponse;
      }

      if (response.success) {
        return fnSuccess();
      }

      // Simulate Error Response
      this.trigger('ContactForm', 'error', type, obj, args);
      return errorCallback.apply(this, args);
    }).error((...args) => {
      this.trigger('ContactForm', 'error', type, obj, args);
      return errorCallback.apply(this, args);
    });
  }

  signout({ href = '' } = {}) {
    window.bt.global.cookie(window.bt.config.authCookieName, null);
    window.bt.global.cookie(window.bt.config.tempCookieName, null);
    window.bt.global.cookie(window.bt.config.dataCookieName, null);

    // Logging out on favorites should redirect you to the homepage
    if (/favs=1/i.test(location.search) || location.pathname === '/favorites/') {
      href = '/';
    }

    if (href) {
      window.location.href = href;
      return;
    }

    window.location.reload();
  }

  register(obj, success, error) {
    return this._request(obj, 'register', success, error);
  }

  registerSeller(obj, success, error) {
    return this._request(obj, 'registerSeller', success, error);
  }

  login(obj, success, error) {
    return this._request(obj, 'login', success, error);
  }

  getleadbyloginemail(obj, success, error) {
    return this._request(obj, 'getleadbyloginemail', success, error);
  }

  sendPassword(obj, success, error) {
    return this._request.call(this, obj, 'password', success, error);
  }

  contactForm(obj, success, error) {
    return this._request(obj, 'contactForm', success, error);
  }

  contactFormPromise(obj) {
    return new Promise((res, rej) => {
      this.contactForm(obj, res, rej);
    });
  }

  updateContact(obj) {

    // CSRF Token Security
    try {
      obj.csrf = bt.config.csrf.accountUpdate;
    } catch {
      console.error('Could Not Get Token');
    }

    return new Promise((resolve, reject) => {
      this.trigger('ContactForm', 'request', 'updateContact', obj);
      $.post(
        '/visitor/updateContact/',
        obj,
        data => {
          this.trigger('ContactForm', 'success', 'updateContact', obj, data);
          if (window.dataLayer) {
            window.dataLayer.push({
              event: 'formSubmitted',
              formtype: 'updateContact',
            });
          }
          resolve(data);
        },
        'json'
      ).error(args => {
        this.trigger('ContactForm', 'error', 'updateContact', obj, args);
        reject(args);
      });
    });
  }

  sendlisting(obj, success, error) {
    return this._request(obj, 'sendlisting', success, error);
  }

  account(obj, success, error) {
    return this._request(obj, 'account', success, error);
  }

  updatePassword(obj, success, error) {
    return this._request(obj, 'updatePassword', success, error);
  }

  updatePasswordPromise(obj) {
    return new Promise((resolve, reject) => {
      this.updatePassword(obj, resolve, reject);
    });
  }

  updateEmail(obj, success, error) {
    return this._request(obj, 'updateEmail', success, error);
  }

  saveSearch(obj, success, error) {
    return this._request(obj, 'saveSearch', success, error);
  }

  saveSearchPromise(obj) {
    return new Promise((res, rej) => {
      this.saveSearch(obj, res, rej);
    });
  }

  updateSaveSearchPromise(obj) {
    return new Promise((res, rej) => {
      this.updateSavedSearch(obj, res, rej);
    });
  }

  updateSavedSearch(obj, success, error) {
    return this._request(obj, 'updateSavedSearch', success, error);
  }

  deleteSavedSearchPromise(obj) {
    return new Promise((res, rej) => {
      this.deleteSavedSearch(obj, res, rej);
    });
  }

  deleteSavedSearch(obj, success, error) {
    return this._request(obj, 'deleteSavedSearch', success, error);
  }

  /**
   * Request that the user's email preferences are updated.
   *
   * @param {FlagshipAPI.EmailPrefsUpdateRequest} obj
   * @param {Function} success
   * @param {Function} error
   */
  updatePreferences(obj, success, error) {
    return this._request(obj, 'updatePreferences', success, error);
  }

  /**
   * Request that the user's email are updated.
   * This correlates to flagship/actions/visitor.php
   */
  updatePreferencesPromise(obj) {
    return new Promise((resolve, reject) => {
      this._request(obj, 'updatePreferences', resolve, reject);
    });
  }

  updateCommunicationPreferences(obj, success, error) {
    return this._request(obj, 'updateCommunicationPreferences', success, error);
  }

  /**
   * Request that the user's communication preferences are updated.
   */
  updateCommunicationPreferencesPromise(obj) {
    return new Promise((resolve, reject) => {
      this._request(obj, 'updateCommunicationPreferences', resolve, reject);
    });
  }

  runTests() {
    this.id = 111;
    const listingid = 222;
    const visitid = 333;
    const full = false;
    const url = '{0}/lc/1/leads/{1}/loglistingview?listingid={2}&visitid={3}&fullview={4}'.format(
      window.bt.config.apiUrl,
      this.id,
      listingid,
      visitid,
      full
    );
    const newurl = `${window.bt.config.apiUrl}/lc/1/leads/${
      this.id
    }/loglistingview?listingid=${listingid}&visitid=${visitid}&fullview=${full}`;
    window.console.assert(url === newurl, `no dice on the url: ${[url, newurl]}`);
  }

  /**
   * Call this method whenever we send a request through this model and the
   * result should be that the visitor is registered, Ideally we would
   * delineate which requests have this behavior and do this automatically, but
   * this is a smaller, safer change for now. Also, this is easier to grep for
   * when trying to identify which API calls are "registrable".
   *
   * We don't receive the phone number in the incoming datacookie, so we are passing it
   * from the form if we have it
   */
  updateAfterRegistrableAction({ phone = '' }) {
    // This is a no op if the user is already registered
    if (this.get('IsRegistered') === true) {
      return;
    }

    // Trust that the cookie has the source of truth for our data we just need to sync with it
    // Drop FavoriteCount it can be out of date, and we have the most recent count for a new
    // user anyway
    // eslint-disable-next-line
    const { FavoriteCount: _drop, IsRegistered , ...attrs } = this.parseAttributesFromDataCookie();
    this.set({
      ...attrs,
      IsRegistered,
      BestPhone: phone,
    });

    // Trying to centralize around the visitor model, but we still ref. this
    window.bt.visitorDetails.IsRegistered = IsRegistered;

    // Fire client custom conversion scripts via iframe for unregistered users
    if (IsRegistered) {
      this.fireCustomConversionScript();
    }
  }

  /**
   * All cookie values are string so we need to clean them up a bit
   */
  parseAttributesFromDataCookie() {
    const data = $.cookie(window.bt.config.dataCookieName);
    if (!data) return {};

    const attrs = window.bt.global.parseQueryString(data, false);
    return {
      ...attrs,
      IsRegistered: attrs.IsRegistered === '1',
      ActiveSearchCount: parseInt(attrs.ActiveSearchCount, 10),
    };
  }

  fireCustomConversionScript() {
    const customConversion = bt.account.get('CustomConversionScript');
    if (customConversion) {
      const iframe = document.createElement('iframe');
      iframe.onload = function () {
        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(customConversion);
        iframe.contentWindow.document.close();
      };
      iframe.src = 'about:blank';
      iframe.style.height = '0';
      iframe.style.width = '0';
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
    }
  }
}

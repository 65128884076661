/* global grecaptcha */
/* eslint no-console: "off" */
import $ from 'jquery';
import { View } from 'backbone';
import bt from 'BoomTown';
import * as routerUtils from 'legacy/Routing/routerUtils';

/*
    ContactView Class
    Rewritten as a result of CNS-6572
    Handles all of the PHP/Backbone Contact Forms
*/
export default class ContactView extends View {
  /** Function Getter For Events * */
  get events() {
    return {
      'click .js-btnSubmitForm': 'submit'
    };
  }

    /** Fields * */
    selectorFields = {
      fname:            { selector: '.js-txtFirstName', fn: 'val' },
      lname:            { selector: '.js-txtLastName', fn: 'val' },
      email:            { selector: '.js-txtEmail', fn: 'val' },
      phone:            { selector: '.js-txtPhone', fn: 'val' },
      address:          { selector: '.js-txtStreetAddress', fn: 'val' },
      city:             { selector: '.js-txtCity', fn: 'val' },
      state:            { selector: '.js-txtState', fn: 'val' },
      zipCode:          { selector: '.js-txtZipCode', fn: 'val' },
      spamcheck:        { selector: '.LastName', fn: 'val' }, // Useless, but whatever
      timeFrame:        { selector: '.txtTimeframe', fn: 'val' },
      priceRange:       { selector: '.js-ddlPriceRange', fn: 'val' },
      desiredPrice:     { selector: '.js-txtPrice', fn: 'val' },
      proptype:         { selector: '.js-ddlPropType option:selected', fn: 'text' },
      area:             { selector: '.js-ddlLocations option:selected', fn: 'text' },
      nrOfBedrooms:     { selector: '.js-ddlMinBeds', fn: 'val' },
      nrOfBathrooms:    { selector: '.js-ddlMinBaths', fn: 'val' },
      describeFeatures: { selector: '.js-txtFeatures', fn: 'val' },
      reason:           { selector: '.txtReason', fn: 'val' },
      preApprove:       { selector: '.js-ckApproval', fn: 'checked' },
      loanApp:          { selector: '.js-ckApply', fn: 'checked' },
      contactBy:        { selector: '.js-ddlContactPreference', fn: 'val' },
      showTime:         { selector: '.showTime', fn: 'val' },
      showDate:         { selector: '.showDate', fn: 'val' }
    };

    /* List of Forms With Their Respective Data Defaults */
    /*
        Developer Note:
        I wanted to consolidate this into a simple array, however
        someone in the past decided that price would equal two values
        depending on the form submitted, one would be priceRange and the
        other desiredPrice.

        This is unfortunate as I can't do a 1:1 mapping
        without rework.

        .. Still, better than it was.
    */
    formFieldsMapping = {
      buy: {
        type:         { default: 'Buy' },
        firstName:    { field: 'fname' },
        lastName:     { field: 'lname' },
        email:        { field: 'email' },
        Phone:        { field: 'phone' },
        TimeFrame:    { field: 'timeFrame' },
        Reason:       { field: 'reason' },
        PropType:     { field: 'proptype' },
        Area:         { field: 'area' },
        Price:        { field: 'priceRange' },
        Beds:         { field: 'nrOfBedrooms' },
        Baths:        { field: 'nrOfBathrooms' },
        Comments:     { field: 'describeFeatures' },
        visitorID:    { field: 'visitorID' },
        visitID:      { field: 'visitID' }
      },
      sell: {
        type:         { default: 'Sell' },
        firstName:    { field: 'fname' },
        lastName:     { field: 'lname' },
        email:        { field: 'email' },
        Phone:        { field: 'phone' },
        Address:      { field: 'address' },
        City:         { field: 'city' },
        State:        { field: 'state' },
        ZipCode:      { field: 'zipCode' },
        TimeFrame:    { field: 'timeFrame' },
        Price:        { field: 'desiredPrice' },
        Comments:     { field: 'describeFeatures' },
        visitorID:    { field: 'visitorID' },
        visitID:      { field: 'visitID' }
      },
      finance: {
        type:         { default: 'Finance' },
        firstName:    { field: 'fname' },
        lastName:     { field: 'lname' },
        email:        { field: 'email' },
        Phone:        { field: 'phone' },
        ContactBy:    { field: 'contactBy' },
        TimeToContact: { field: 'timeFrame' },
        PreApprove:   { field: 'preApprove' },
        LoanApp:      { field: 'loanApp' },
        Comments:     { field: 'describeFeatures' },
        visitorID:    { field: 'visitorID' },
        visitID:      { field: 'visitID' },
        lenderID:     { field: 'lenderID' }
      },
      contactagent: {
        type:         { default: 'Agent' },
        firstName:    { field: 'fname' },
        lastName:     { field: 'lname' },
        email:        { field: 'email' },
        Phone:        { field: 'phone' },
        Comments:     { field: 'describeFeatures' },
        AgentID:      { field: 'agentID' },
        visitorID:    { field: 'visitorID' },
        visitID:      { field: 'visitID' }
      },
      contactus: {
        type:         { default: 'Office' },
        firstName:    { field: 'fname' },
        lastName:     { field: 'lname' },
        email:        { field: 'email' },
        Phone:        { field: 'phone' },
        Comments:     { field: 'describeFeatures' },
        visitorID:    { field: 'visitorID' },
        visitID:      { field: 'visitID' }
      },
      askaquestion: {
        type:         { default: 'AskExpert' },
        firstName:    { field: 'fname' },
        lastName:     { field: 'lname' },
        email:        { field: 'email' },
        Phone:        { field: 'phone' },
        Comments:     { field: 'describeFeatures' },
        visitorID:    { field: 'visitorID' },
        visitID:      { field: 'visitID' }
      },
      requestshowing: {
        type:         { default: 'ScheduleShowing' },
        firstName:    { field: 'fname' },
        lastName:     { field: 'lname' },
        email:        { field: 'email' },
        Phone:        { field: 'phone' },
        ShowDate:     { field: 'showDate' },
        ShowTime:     { field: 'showTime' },
        Comments:     { field: 'describeFeatures' },
        visitorID:    { field: 'visitorID' },
        visitID:      { field: 'visitID' }
      },
      registerWidget: {
        type:         { default: 'ScheduleShowing' },
        postvars:     { default: ['_btemail', '_btsubject', '_btsellerlead'] },
        firstName:    { field: 'fname' },
        lastName:     { field: 'lname' },
        email:        { field: 'email' },
        IsSellerLead: { field: 'isSellerLead' },
        Phone:        { field: 'phone' },
        visitorID:    { field: 'visitorID' },
        visitID:      { field: 'visitID' },
        widget:       { field: 'widget' },
        postid:       { field: 'postID' }
      }
    };

    /** Value Storage * */
    values = {};

    /** Form Value Storage * */
    formValues = {};

    /** Mutex Lock to Prevent Multi-Submit * */
    loading = false;

    /** Tracking Parameters, Defined in Init * */
    trackingParams = {};

    /** FormID * */
    formId = false;

    /** Form Container * */
    formContainer = false;

    // /////////////////////////////////////
    // Data Assembly
    // /////////////////////////////////////

    /**
     * Initializes the Contact Class
     * @param {string} target - Target of Form In Question
     * @return {void}
     */
    init(target) {
      // Reset
      this.form = null;
      this.formName = null;
      this.values = {};
      this.formValues = {};
      this.trackingParams = {};
      this.formContainer = null;

      // Get Contact Form
      const form = $(target).closest('form');
      this.formId = form.data('formname');
      this.formContainer = $('.js-contact-form');

      // Merge Aliases for Forms
      if (this.formId === 'widget-contactagent') {
        this.formId = 'contactagent';
      }

      // Special Values
      this.getSpecialValues(form, this.formId);

      // Get Values for Submit
      this.getValues(form, this.formId);
      this.getValuesByForm(this.formId);
      this.setTrackingByForm(this.formId);

      // Mortage Tracking for FB Leads
      if (bt.tenant.lenderChecked) {
        this.formValues.MortgageOK = true;
      }

      // Recaptcha
      if (bt.config.recaptchaEnabled) {
        this.formValues.token = $('.g-recaptcha-response', form).val();
      }
    }

    /**
     * Generates the special values of the form such as AgentID and sich.
     * @param {object} form - Form jQuery Object
     * @param {object} formId - ID of Form Submitted
     * @return {void}
     */
    getSpecialValues(form, formId) {
      // Agent and Listing IDs
      this.values.agentID = this.$el.data('agentid');
      this.values.listingID = this.$el.data('listingid');

      // Determine Seller Leads
      this.values.isSellerLead = false;
      if ($('.registerWidgetView').data('sellerlead') === 1) {
        this.values.isSellerLead = true;
      }

      // Visitor and VisitIDs
      this.values.visitorID = bt.visitor.id || bt.visitorDetails._ID;
      this.values.visitID = bt.visitor.get('VisitID');

      // Attempt to read visitID from cookie
      if (!this.values.visitID) {
        const str = $.cookie(bt.config.dataCookieName);
        const obj = bt.global.parseQueryString(str, false);
        this.values.visitID = obj.VisitID;
      }

      // Undefined visitorID results in a thrown exception and a graylog entry.
      if (this.values.visitorID === undefined) {
        bt.utility.graylog(
          'warn',
          `label=Undefined visitor ID on visit ID ${this.values.visitID} submitting contact form ${formId}`
        );

        if (!bt.outage) {
          throw new Error('Undefined visitor ID on visit ID on form Submit');
        }
      }

      // Lender ID
      this.values.lenderID = bt.visitorDetails.LenderID;
    }

    /**
     * Gets the values of the form and normalizes them
     * @param {object} form - Form jQuery Object
     * @return {void}
     */
    getValues(form) {
      for (const key in this.selectorFields) {
        if (this.selectorFields[key]) {
          let value = false;
          const field = this.selectorFields[key];

          try {
            if (field.fn === 'val') {
              value = form.find(field.selector).val().trim();
            } else if (field.fn === 'text') {
              value = form.find(field.selector).text();
            } else if (field.fn === 'checked') {
              value = form.find(field.selector).prop('checked');
            }

            this.values[key] = (typeof value === 'undefined') ? false : value;
          } catch {
            this.values[key] = false;
          }
        }
      }
    }

    /**
     * From the value table, assign them to the form values by formId.
     * @param {object} formId - ID of Form Submitted
     * @return {void}
     */
    getValuesByForm(formId) {
      if (this.formFieldsMapping[formId]) {
        const map = this.formFieldsMapping[formId];
        for (const key in map) {
          if (map[key]) {
            const field = map[key];
            if (field.default) {
              this.formValues[key] = field.default;
            } else if (field.field) {
              this.formValues[key] = this.values[field.field];
            }
          }
        }
      } else {
        throw new Error(`Undefined formId: ${formId}`);
      }

      // Special Variable Rules
      if (formId === 'contactus') {
        if (bt.agent) {
          this.formValues.type = 'Agent';
          this.formValues.AgentID = bt.agent.id;
        }
      }
    }

    /**
     * Set tracking rules by the form in question.
     * @param {object} formId - ID of Form Submitted
     * @return {void}
     */
    setTrackingByForm(formId) {
      // Set Tracking
      const formTrackingId = `${formId.toLowerCase()}form`;
      this.trackingParams = {
        form: formTrackingId,
        contacttype: bt.visitor.updateContactType('lead'),
        formtype: 'email'
      };
    }

    // /////////////////////////////////////
    // Main Submit Event
    // /////////////////////////////////////

    /**
     * Handles the on submit event
     * @param {event} e
     * @return {void}
     */
    submit(e) {
      // Already Working
      if (this.loading) {
        return;
      }

      // Mutex should come as soon as possible.
      this.onToggleLoading(true);

      // Global Exception Handler
      try {
        this.init(e.currentTarget);
      } catch (error) {
        console.error(error);
        this.onError();
        return;
      }

      // Validate Form
      const validated = bt.utility.validateParsley('.js-contact-form');

      // Submit Form
      if (validated) {
        this.onSubmit();
      }

      // Validation Errors Display
      this.onToggleLoading(false);
    }

    // /////////////////////////////////////
    // Callback Events
    // /////////////////////////////////////

    /**
     * Handles submitting to the server
     * @return {void}
     */
    onSubmit() {
      bt.visitor.contactForm(this.formValues, this.onResponse, this.onResponse);
    }

    /**
     * Handles diplaying the generic error at the top of the form.
     * @param {string} message
     * @return {void}
     */
    onError = (message = 'Something went wrong. Please contact support.') => {
      this.onComplete(true);
      const $alert = $('.js-alert');
      $alert.addClass('alert-error').removeClass('alert-success').html(message);
      bt.utility.show($alert);
    }

    /**
     * Handles the response logic from the server.
     * @param {data} mixed
     * @return {void}
     */
    onResponse = (data) => {
      // Cannot parse, throw error.
      try {
        data = JSON.parse(data.trim());
      } catch (error) {
        console.error(`Cannot parse response:\n${data}`);
        this.onError();
      }

      // Good Time By All
      if (data.success) {
        this.onComplete(false, data.existingEmail);
        return;
      }

      // Error Logic
      let message = '';
      switch (data.reason) {
        case 'EMPTY_TOKEN':
          message = 'Invalid Recaptcha Response. If this persists, please contact support.';
          break;
        default:
          message = 'Something went wrong. Please contact support.';
      }
      this.onError(message);
      return;
    }

    /**
     * Mutex lock for forms handler.
     * @param {bool} state
     * @return {void}
     */
    onToggleLoading = (state) => {
      const button = this.$('.js-btnSubmitForm');
      if (!state) {
        this.loading = false;
        button.removeAttr('disabled');
      } else {
        this.loading = true;
        button.attr('disabled', 'disabled');
      }
    }

    /**
     * Handles removing the mutex lock, on success does a bunch of things like show the success message and GA.
     * @param {bool} error
     * @return {void}
     */
    onComplete = (error = false, existingEmail = false) => {
      this.onToggleLoading(false);

      // If there is a critcal error do nothing else.
      // We'll let the response handle the error display.
      if (error) {
        return;
      }

      // Determine scroll offset (Why are you doing this?)
      let scrollOffset = this.formContainer.offset().top;
      if (bt.utility.MQ_Medium() && routerUtils.isDetailsPath()) {
        scrollOffset = this.formContainer.offset().top - $('.js-listing__header').height() - 15; // Global spacing
      }

      // I hate this, put it in a template :/
      $('html, body').animate({ scrollTop: scrollOffset }, 300, () => {
        bt.utility.hide(this.formContainer);
        $('.js-alert')
          .addClass('alert-success')
          .html(
            'Your Request Was Submitted Successfully.</br>An agent will be in touch with you soon to address your specific needs.'
          );
        bt.utility.show($('.js-alert'));
      });

      // GTM Tracking
      if (!bt.visitor.isRegistered() && !existingEmail) {
        if (this.trackingParams) {
          if (window.dataLayer) {
            this.trackingParams.event = 'contact-form-registration';
            window.dataLayer.push(this.trackingParams);
          }
          this.trackingParams = null;
        }
        const phone = this.formValues.phone;
        bt.visitor.updateAfterRegistrableAction({ phone });
      }
    }
}

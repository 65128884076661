import { View } from 'backbone';
import bt from 'BoomTown';
import template from 'templates/listings/cards/base.hbs';
import { push } from 'redux-little-router';

export default class ListingCardView extends View {
  get className() {
    return 'property-cards';
  }

  get events() {
    return {
      'click .js-card-fav': 'toggleFavorite',
      'click .js-full-deets': 'onButtonClick',
      click: 'viewListing'
    };
  }

  initialize(options) {
    super.initialize();

    const { renderUI = true, inCollection = true } = options;
    this.renderUI = renderUI;
    this.inCollection = inCollection;
    this.template = template;

    if (this.renderUI) {
      this.$el.html(this.template(this.model.attributes));
    } else {
      this.delegateEvents();
    }

    // Hack to make themes consistent ...
    const articles = this.$('article');
    const favoriteBtn = this.$('.favorite-icon');
    const heartIcon = this.$('.favorite-icon__heart');

    if (articles.length > 0) {
      this.setElement(articles[0]);
    }

    this.listenTo(bt.favorites, 'add', model => {
      if (model.id === this.model.id) {
        this.$el.find(favoriteBtn).addClass('favorite-icon--active');
        this.$el.find(heartIcon).addClass('fas').removeClass('far');
      }
    });

    this.listenTo(bt.favorites, 'remove', model => {
      if (model.id === this.model.id) {
        this.$el.find(favoriteBtn).removeClass('favorite-icon--active favorite-icon--paused');
        this.$el.find(heartIcon).addClass('far').removeClass('fas');
      }
    });
  }

  /*
     * If client-side routing is disabled via this Listing model property or if
     * the user is cmd-clicking to open in a new tab, honor the href and prevent
     * the event from propagating to the delegate handler on the @el. Otherwise,
     * ignore the href and allow the event to propagate.
    */
  onButtonClick(e) {
    if (this.model.overrideRoute || e.metaKey || e.ctrlKey) {
      e.stopPropagation();
    } else {
      e.preventDefault();
    }
  }

  viewListing(e) {
    // eslint-disable-next-line
    const { dispatch } = require('store').default;

    if (!this.model.get('shouldDisplayAsOffMarket')) {
      // Get the URL from either the listing model that backs this view, or
      // the data-url attribute on the <article>
      const url = this.model.get('Url') || e.currentTarget.dataset.url;
      const path = bt.utility.absoluteToRelative(url);
      dispatch(push(path));
    } else {
      e.stopPropagation();
    }
  }

  toggleFavorite(e) {
    e.preventDefault();
    e.stopPropagation();
    bt.favorites.toggle(this.model.id);
  }
}

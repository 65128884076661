/**
 * Shares a lot in common with ./mobileDetailsStartAnOffer
 */
import { createAction } from 'redux-act';
import validator from 'utility/validator';
import * as NameParse from 'utility/parse-names';
import { toDollarString } from 'utility/string';
import { config, visitor } from 'BoomTown';
import * as Notify from 'utility/uikitNotify';

// not called from the ui
export const handleErrors = createAction('Single Details > Start An Offer > handleErrors');
export const onSuccess = createAction('Single Details > Start An Offer > onSuccess');
export const onSubmit = createAction('Single Details > Start An Offer > onSubmit');
export const onSubmitError = createAction('Single Details > Start An Offer > onSubmitError');

// called from the ui
export const showStartAnOfferForm = createAction('Single Details > Show Start An Offer Modal');
export const hideStartAnOfferForm = createAction(
  'Single Details > Hide Start An Offer Modal',
  () => {}
);
export const onChange = createAction('Single Details > Start An Offer > onChange');
export const logGTM = createAction('Single Details > Start An Offer > logGTM');


export const handleSubmit = () => (dispatch, getState) => {
  dispatch(onSubmit());
  const { startAnOffer } = getState();

  const errors = [];
  if (!NameParse.isFullName(startAnOffer.fullName.value)) {
    errors.push({
      field: 'fullName',
      message: 'Please enter a valid full name.',
    });
  }

  if (!validator.isEmail(startAnOffer.email.value)) {
    errors.push({
      field: 'email',
      message: 'Please enter a valid email address.',
    });
  }

  if (!validator.isPhone(startAnOffer.phone.value)) {
    errors.push({
      field: 'phone',
      message: 'Please enter a valid phone.',
    });
  }

  if (!validator.isNotWhitespace(startAnOffer.comments.value)) {
    errors.push({
      field: 'comments',
      message: 'Please leave your comment.',
    });
  }

  if (config.recaptchaEnabled && !startAnOffer.token) {
    errors.push({
      field: 'token',
      message: 'Invalid Recaptcha Response',
    });
  }

  if (errors.length > 0) {
    dispatch(handleErrors(errors));
    return false;
  }

  let recaptchaToken = null;
  if (startAnOffer.token) {
    recaptchaToken = startAnOffer.token.value;
  }
  const { firstName, lastName } = NameParse.parse(startAnOffer.fullName.value);

  const params = {
    type: 'makeanoffer',
    ListingID: startAnOffer.listingId,
    firstName,
    lastName,
    phone: startAnOffer.phone.value,
    email: startAnOffer.email.value,
    visitorID: visitor.id,
    visitID: visitor.get('VisitID'),
    comments: startAnOffer.comments.value,
    Price: toDollarString(startAnOffer.offerAmount.value),
    SeenProperty: startAnOffer.hasViewedProperty.value,
    HelpWithFinancing: startAnOffer.helpWithFinancing.value,
    token: recaptchaToken,
  };

  // GTM tracking all contact form submits
  const trackingParams = {
    form: 'startofferform',
    contacttype: visitor.updateContactType('lead'),
    formtype: 'email',
    event: visitor.get('IsRegistered') ? 'offer-form-submit' : 'offer-form-registration',
  };

  dispatch(logGTM(trackingParams));

  const fnSendContactForm = () => {
    visitor.contactForm(
      params,
      () => {
        // `emailInUse` is returned to indicate that the user has _not_ been
        // logged in. (No creds given on contact form)
        visitor.updateAfterRegistrableAction({ phone: params.phone });
        dispatch(onSuccess());
        Notify.success(
          'Your Request Was Submitted Successfully. We will be in touch with you soon to address your specific needs.'
        );
      },
      () => {
        dispatch(onSubmitError());
      }
    );
  };

  fnSendContactForm();

  return true;
};

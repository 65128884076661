/**************************************************************************************************
 *
 *                                        MOBILE
 *
 *  NOTE: These constants are specifically for elements that only appear in mobile views.
 *        If you are trying to select an element not listed here, it's likely an element
 *        shared between mobile and desktop.
 *
 *        TODO: We should eventually migrate these to their own file.
 **************************************************************************************************/

export const MOBILE_NAVBAR = {
  HAMBURGER_MENU: 'mobile-navbar__hamburger-menu',
  FILTER_BUTTON: 'mobile-navbar__filter-button',
  SAVE_SEARCH_BUTTON: 'mobile-navbar__save-search-button',
  MAIN_LOGO: 'mobile-navbar__main-logo',
};

export const MOBILE_MAIN_MENU = {
  CLOSE_BUTTON: 'mobile-main-menu__close-button',
  FAVORITES_BUTTON: 'mobile-main-menu__favorites-button',
  MY_ACCOUNT: 'mobile-main-menu__my-account',
  REGISTER_SIGN_IN_BUTTON: 'mobile-main-menu__register-sign-in-button',
  SAVED_SEARCHES_BUTTON: 'mobile-main-menu__saved-searches-button',
  SAVED_SEARCHES_PILL: 'mobile-main-menu__saved-searches-pill',
  SIGN_IN: 'mobile-main-menu__sign-in',
  SIGN_OUT: 'mobile-main-menu__sign-out',
  USER_AVATAR: 'mobile-main-menu__user-avatar',
  SITE_MENU: 'mobile-main-menu__site-menu'
};

/**************************************************************************************************
   *                                        MOBILE - ACCOUNT
   **************************************************************************************************/

export const MOBILE_ACCOUNT = {
  EMAIL_PREFERENCES_BUTTON: 'mobile-account__email-preferences-button',
  TEXT_MESSAGE_PREFERENCES_BUTTON: 'mobile-account__text-message-preferences-button',
  CHANGE_PASSWORD_BUTTON: 'mobile-account__change-password',
  EDIT_CONTACT_INFO: {
    SAVE_BUTTON: 'mobile-account__edit-contact-info__save-button',
  },
  MODAL_THANK_YOU: 'mobile-account__modal-thank-you',
  CONTACT_AGENT_MODAL_THANK_YOU: 'mobile-account__contact_agent_modal-thank-you',
};

export const MOBILE_CHANGE_PASSWORD = {
  SCREEN_HEADER: 'mobile-change-password__screen-header',
  CURRENT_PASSWORD: 'mobile-change-password__current-password',
  NEW_PASSWORD: 'mobile-change-password__new-password',
  CONFIRM_PASSWORD: 'mobile-change-password__confirm-password',
  SUBMIT_BUTTON: 'mobile-change-password__submit-button',
};

/********************************************************
   *              ACCOUNT / EDIT ROUTES
   ********************************************************/

/**
   * Screen headers for account pages.
   * Can probably be reduced to one as they share same component
   */
export const MOBILE_EMAIL_PREFS = {
  SCREEN_HEADER: 'mobile-email-prefs__screen-header',
};

export const MOBILE_CONTACT_AGENT = {
  SCREEN_HEADER: 'mobile-contact-agent__screen-header',
};

export const MOBILE_ACCOUNT_EDIT = {
  SCREEN_HEADER: 'mobile-account-edit__screen-header',
};

export const MOBILE_TEXT_PREFS = {
  UPDATE_TEXT_PREFERENCES: 'mobile-text-prefs__update-text-preferences',
};

/********************************************************
   *              ACCOUNT / SAVED-SEARCHES
   ********************************************************/

export const MOBILE_SAVED_SEARCHES_PAGE = {
  SCREEN_HEADER: 'mobile-saved-searches-page__screen-header',
  SCREEN_CONTAINER: 'mobile-saved-searches-page__screen-container',
  START_A_NEW_SEARCH: 'mobile-saved-searches-page__start-a-new-search',
};

/**************************************************************************************************
   *                                        MOBILE - FILTER MENU
   **************************************************************************************************/

export const MOBILE_SEARCH_MENU = {
  ADDITIONAL_PROPTYPE_CONTAINER: 'mobile-search-menu__additional-proptypes',

  AUTOCOMPLETE_LIST: {
    ITEM: 'mobile-search-menu__autocomplete-list-item',
    ADDRESS: 'mobile-search-menu__autocomplete-list-item__address',
    LOCATION: 'mobile-search-menu__autocomplete-list-item__location',
    ZIPCODE: 'mobile-search-menu__autocomplete-list-item__postalcode',
    SCHOOL: 'mobile-search-menu__autocomplete-list-item__school',
    NEIGHBORHOOD: 'mobile-search-menu__autocomplete-list-item__neighborhood',
    MLS_AREA: 'mobile-search-menu__autocomplete-list-item__areaname',
    COUNTY: 'mobile-search-menu__autocomplete-list-item__county',
  },

  BALLERBOX: 'mobile-search-menu__ballerbox',

  BATHS: {
    PLUS: 'mobile-search-menu__baths-plus',
    MINUS: 'mobile-search-menu__baths-minus',
    COUNT: 'mobile-search-menu__baths-count',
  },

  BEDS: {
    PLUS: 'mobile-search-menu__beds-plus',
    MINUS: 'mobile-search-menu__beds-minus',
    COUNT: 'mobile-search-menu__beds-count',
  },

  CLOSE_BUTTON: 'mobile-search-menu__close-button',
  FILTER_DROPDOWN: 'mobile_search-menu__filter_dropdown',
  FILTER_MENU: 'mobile-search-menu__filter-menu',
  MAX_PRICE_RANGE: 'mobile-search-menu__max-price-range',
  MIN_PRICE_RANGE: 'mobile-search-menu__min-price-range',
  OPEN_HOUSE: 'mobile-search-menu__open-house',
  POWER_SEARCH: 'mobile-search-menu__power-search',
  POWER_SEARCH_BAR: 'mobile-search-menu__power-search-bar',

  PROPTYPES: {
    SF: {
      input: 'mobile-search-menu__proptypes-single-family',
      label: 'mobile-search-menu__proptypes-single-family__label',
    },
    C: {
      input: 'mobile-search-menu__proptypes-condo',
      label: 'mobile-search-menu__proptypes-condo__label',
    },
    VT: {
      input: 'mobile-search-menu__proptypes-townhouse',
      label: 'mobile-search-menu__proptypes-townhouse__label',
    },
    VC: {
      input: 'mobile-search-menu__proptypes-lot',
      label: 'mobile-search-menu__proptypes-lot__label',
    },
    M: {
      input: 'mobile-search-menu__power-search-manufactured-home',
      label: 'mobile-search-menu__power-search-manufactured-home__label',
    },
    MF: {
      input: 'mobile-search-menu__power-search-multi-family',
      label: 'mobile-search-menu__power-search-multi-family__label',
    },
    CM: {
      input: 'mobile-search-menu__power-search-commerical',
      label: 'mobile-search-menu__power-search-commerical__label',
    },
    B: {
      input: 'mobile-search-menu__power-search-boat-slip',
      label: 'mobile-search-menu__power-search-boat-slip__label',
    },
    RN: {
      input: 'mobile-search-menu__power-search-rentals',
      label: 'mobile-search-menu__power-search-rentals__label',
    },
    FM: {
      input: 'mobile-search-menu__power-search-farm',
      label: 'mobile-search-menu__power-search-farm__label',
    },
    SI: {
      input: 'mobile-search-menu__power-search-shared-interest',
      label: 'mobile-search-menu__power-search-shared-interest__label',
    },
    CL: {
      input: 'mobile-search-menu__power-search-commerical-lease',
      label: 'mobile-search-menu__power-search-commerical-lease__label',
    },
  },

  RESULTS_BUTTON: 'mobile-search-menu__results-button',
  SEARCH_TAG: 'mobile_search-menu__search-tag',
  SORT_BY: 'mobile-search-menu__sort-by',
};

/**************************************************************************************************
   *                                        MOBILE - RESULTS GALLERY
   **************************************************************************************************/

export const MOBILE_GALLERY = {
  LISTING_PHOTO: 'mobile-gallery__listing-photo',
  RESULTS_COUNT: 'mobile-gallery__results-count',
  NOTIFY_ME_BUTTON: 'mobile-gallery__notify-me-button',
  SAVE_SEARCH_AD: 'mobile-gallery__save-search-ad',
  HSN_MODAL: 'mobile-gallery__hsn-modal',
  HSN_MODAL_DISMISS_BUTTON: 'mobile-gallery__hsn-modal__dismiss-button',
};

export const MOBILE_SORT_OPTIONS = {
  HIGHEST_PRICE: 'm-sort-options__highest-price',
  LOWEST_PRICE: 'm-sort-options__lowest-price',
  MOST_POPULAR: 'm-sort-options__most-popular',
  MOST_BEDROOMS: 'm-sort-options__most-bedrooms',
  MOST_BATHROOMS: 'm-sort-options__most-bathrooms',
  NEWEST_ON_SITE: 'm-sort-options__newest-on-site',
  OLDEST_ON_SITE: 'm-sort-options__oldest-on-site',
  DISTANCE: 'm-sort-options__distance',
  YEAR_BUILT_NEWEST: 'm-sort-options__newest-built',
  YEAR_BUILT_OLDEST: 'm-sort-options__oldest-built',
  HIGHEST_ACREAGE: 'm-sort-options__highest-acreage',
  LOWEST_ACREAGE: 'm-sort-options__lowest-acreage',
};

export const MOBILE_SAVE_SEARCH = {
  SEARCH_NAME: 'mobile-save-search__search-name',
  SEARCH_EMAIL: 'mobile-save-search__search-email',
  SEARCH_FREQUENCY: 'mobile-save-search__search-frequency',
  FORM: 'mobile-save-search__form',
};

/**************************************************************************************************
  *                                        MOBILE - PROPERTY DETAILS
  **************************************************************************************************/

export const MOBILE_PROP_DETAILS = {
  FAVORITE_BUTTON: 'prop-details__mobile-fav-button',
  START_OFFER_BUTTON: 'prop-details__mobile-so-button',
  START_OFFER_SUBMIT: 'prop-details__mobile-so-submit',
  CONTACT_FULL_NAME: 'prop-details__mobile-contact-firstname',
  CONTACT_PHONE: 'prop-details__mobile-contact-phone',
  CONTACT_EMAIL: 'prop-details__mobile-contact-email',
  CONTACT_COMMENTS: 'prop-details__mobile-contact-comments',
  SCHEDULE_SHOWING_CHECKBOX: 'prop-details__mobile-ss-checkbox',
  SCHEDULE_SHOWING_CHECKBOX_LABEL: 'prop-details__mobile-ss-checkbox__label',
  VIDEOTOUR_CHECKBOX: 'prop-details__mobile-video-tour-checkbox',
  VIDEOTOUR_CHECKBOX_LABEL: 'prop-details__mobile-video-tour-checkbox__label',
  SCHEDULE_SHOWING_SELECT_DATE: 'prop-details__mobile-ss-select-date',
  SCHEDULE_SHOWING_SELECT_TIME: 'prop-details__mobile-ss-select-time',
  CONTACT_SUBMIT: 'prop-details__mobile-contact-submit',
  MAP_ICON: 'prop-details__mobile-map-icon',

  // Appends a `__${index}` to the selector when used to differentiate between slides
  PHOTOSLIDER_SLIDE: 'prop-details__photoslider__slide',

  // Adding constants for mobile accordion testing, if ever adopted
  LISTING_DETAILS_ACCORDION: 'prop-details__mobile-listing-details-section',
  FEATURES_ACCORDION: 'prop-details__mobile-features-section',
  SCHOOLS_ACCORDION: 'prop-details__mobile-schools-section',
  MTG_CALCULATOR_ACCORDION: 'prop-details__mobile-mortgage-section',
  CONTACT_AGENT_ACCORDION: 'prop-details__mobile-contact-agent-section',

  MOBILE_WRAPPER: 'prop-details__mobile-wrapper',
};

/**************************************************************************************************
   *                                        MOBILE - SITE MENU
   **************************************************************************************************/

export const MOBILE_SITE_MENU = {
    HOME: 'mobile-site-menu__home',
    SEARCH: 'mobile-site-menu__search',
    BUYING: 'mobile-site-menu__buying',
    FINANCING: 'mobile-site-menu__financing',
    SELLING: 'mobile-site-menu__selling',
    RESOURCES: 'mobile-site-menu__resources',
    CONTACT_US: 'mobile-site-menu__contact-us',
    AGENTS: 'mobile-site-menu__agents',
    OUR_LISTINGS: 'mobile-site-menu__our-listings',
    MAP_SEARCH: 'mobile-site-menu__map-search',
    BACK_BUTTON: 'mobile-site-menu__back-button'
  };

/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-act';
import { visitor } from 'BoomTown';

export const clickSaveSearchInSearchBar = createAction(
  'SearchBar > Click save-search',
  () => {},
  () => ({ visitor })
);
export const clickSortOption = createAction('SearchBar > Click sort option');
export const clickResultsViewType = createAction(
  'SearchBar > Click results view type',
  routeID => routeID
);

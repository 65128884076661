import React from 'react';
import TPrice from 'btPropTypes/price';
import addCommas from 'utility/addCommas';
import PropTypes from 'prop-types';

/**
 * @param {{ min: Number, max: Number } | number | undefined} p
 */
const Price = (props) => {
  // eslint-disable-next-line react/prop-types
  const { price, dataCY, ...rest } = props;
  let s;
  if (typeof price === 'number') {
    s = `$${addCommas(price)}`;
  } else if (price && price.min && price.max) {
    s = `$${addCommas(price.min)} - $${addCommas(price.max)}`;
  } else {
    s = 'Price Not Available';
  }
  return (
    <div data-cy={dataCY} data-price={price} {...rest}>
      {s}
    </div>
  );
};

Price.propTypes = {
  dataCY: PropTypes.string,
  price: TPrice,
};

export default Price;

import { createReducer } from 'redux-act';
import { LOCATION_CHANGED } from 'redux-little-router';
import * as actions from 'actions/GalleryActions';

const initialState = {
  scrollY: -1,
};

/**
 * Save the current scroll depth on the gallery page
 *
 * @param {object} state
 * @param {number} scrollY
 */
const stash = (state, { scrollY }) => ({ scrollY });

export default createReducer(
  {
    // Clear out the scroll depth
    [LOCATION_CHANGED]: () => initialState,

    // Stash the scroll depth
    [actions.clickNewSearch]: stash,
    [actions.clickSearch]: stash,
  },
  initialState
);

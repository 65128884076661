import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton, Radio, SecondaryButton } from 'coreComponents';
import { Grid, Cell } from 'components/core/Grid';
import ResponseModal from 'components/Common/ResponseModal';
import { EMAIL_PREFS } from 'cypress_constants';
import withData from './withData';
import { ConfirmEAlerts, ConfirmUnsubscribeAll } from './ConfirmationModals';
import { ConfirmationModal } from './constants';

/**
 * Static class to represent subscribed states within the form.
 */
const SubscribeStatus = {
  subscribed: 'subscribed',
  unsubscribed: 'unsubscribed',

  /** Answers the question, "Is subscribed?" */
  asBoolean(constant) {
    switch (constant) {
      case this.subscribed:
        return true;
      case this.unsubscribed:
        return false;
      default:
        throw new Error('Unexpected SubscribeStatus');
    }
  },
};

// I thought they used to provide us with references to the DOM element classes,
// so I could just assign to `ReactDOM.something.h3`...
const FormSectionHeader = (props) => <div className="bt-heading__h4 mb-16" {...props} />; // eslint-disable-line

/**
 * @param {Object} props
 * @param {boolean | null} props.isSubscribed
 * @param {Function} [props.onChange]
 * @param {React.ReactElement} props.heading
 * @param {string} props.controlName
 */
const RadioSection = (props) => {
  const {
    isSubscribed,
    dataCYSelectors = { subscribed: '', unsubscribed: '' },
    onChange = () => {},
    heading,
    controlName,
    ...otherProps
  } = props;
  const isLoaded = typeof isSubscribed === 'boolean';
  const labelClassName = !isLoaded ? 'uk-text-muted' : '';
  const { subscribed, subscribedLabel, unsubscribed, unsubscribedLabel } = dataCYSelectors || null;

  return (
    <div {...otherProps}>
      <FormSectionHeader>{heading}</FormSectionHeader>
      <Radio
        checked={isSubscribed === true}
        dataCY={subscribed}
        disabled={!isLoaded}
        id={`email-prefs__subscribe-${controlName}`}
        label="Subscribed"
        labelProps={{ className: labelClassName, 'data-cy': subscribedLabel }}
        name={controlName}
        onChange={() => {
          if (isSubscribed === false) {
            onChange(true);
          }
        }}
        value={SubscribeStatus.subscribed}
      />
      <Radio
        checked={isSubscribed === false}
        disabled={!isLoaded}
        dataCY={unsubscribed}
        id={`email-prefs__unsubscribe-${controlName}`}
        label="Unsubscribed"
        labelProps={{ className: labelClassName, 'data-cy': unsubscribedLabel }}
        name={controlName}
        onChange={() => {
          if (isSubscribed === true) {
            onChange(false);
          }
        }}
        value={SubscribeStatus.unsubscribed}
      />
    </div>
  );
};
RadioSection.propTypes = {
  isSubscribed: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  heading: PropTypes.node.isRequired,
  controlName: PropTypes.string.isRequired,
  dataCYSelectors: PropTypes.shape({
    subscribed: PropTypes.string,
    unsubscribed: PropTypes.string,
  }),
};

/**
 * DataCY Selectors for the 3 Subscribe/Unsubscribe RadioSections
 */
const AGENT_DATACY_SELECTORS = {
  subscribed: EMAIL_PREFS.REAL_ESTATE_TEAM.SUBSCRIBED,
  subscribedLabel: EMAIL_PREFS.REAL_ESTATE_TEAM.SUBSCRIBED_LABEL,
  unsubscribed: EMAIL_PREFS.REAL_ESTATE_TEAM.UNSUBSCRIBED,
  unsubscribedLabel: EMAIL_PREFS.REAL_ESTATE_TEAM.UNSUBSCRIBED_LABEL,
};

const LENDER_DATACY_SELECTORS = {
  subscribed: EMAIL_PREFS.SPONSORED_LOCAL_LENDER.SUBSCRIBED,
  subscribedLabel: EMAIL_PREFS.SPONSORED_LOCAL_LENDER.SUBSCRIBED_LABEL,
  unsubscribed: EMAIL_PREFS.SPONSORED_LOCAL_LENDER.UNSUBSCRIBED,
  unsubscribedLabel: EMAIL_PREFS.SPONSORED_LOCAL_LENDER.UNSUBSCRIBED_LABEL,
};

const EALERT_DATACY_SELECTORS = {
  subscribed: EMAIL_PREFS.LISTING_EALERTS.SUBSCRIBED,
  subscribedLabel: EMAIL_PREFS.LISTING_EALERTS.SUBSCRIBED_LABEL,
  unsubscribed: EMAIL_PREFS.LISTING_EALERTS.UNSUBSCRIBED,
  unsubscribedLabel: EMAIL_PREFS.LISTING_EALERTS.UNSUBSCRIBED_LABEL,
};

const EmailPreferences = (props) => {
  const {
    isMobileScreen,
    subscriptions,
    isSubmitting = false,
    onClickEditSavedSearches,
    onToggleAgent,
    onToggleLender,
    onToggleEAlerts,
    onSubmit,
    onClickUnsubscribeAll,

    // Confirmation modals
    activeConfirmationModal,
    onClickConfirmUnsubAll,
    onClickCancelUnsubAll,
    onClickConfirmEAlerts,
    onClickCancelEAlerts,
    onClickConfModalEditEAlerts,

    // Response Modal
    isOpen,
    isSuccess,
    successText,
    onClickCloseInRespModal,
    onClickBackInRespModal,
    onClickRetryInRespModal,
  } = props;

  return (
    <div>
      {/* This submit handler means this component needs to store refs and
        access the DOM, since our form elements are uncontrolled. */}
      <form
        id="email-prefs__form"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <RadioSection
          heading={
            <span>
              Periodic emails from our <strong>real estate team</strong>
            </span>
          }
          controlName="agent"
          isSubscribed={subscriptions && subscriptions.agent}
          onChange={onToggleAgent}
          dataCYSelectors={AGENT_DATACY_SELECTORS}
        />
        <hr />
        <RadioSection
          heading={
            <span>
              Periodic emails from our <strong>sponsored local lender</strong>
            </span>
          }
          controlName="lender"
          isSubscribed={subscriptions && subscriptions.lender}
          onChange={onToggleLender}
          dataCYSelectors={LENDER_DATACY_SELECTORS}
        />
        <hr />
        <RadioSection
          className="mb-16"
          heading={
            <span>
              New <strong>Listing e-Alerts</strong> for saved searches
            </span>
          }
          controlName="ealerts"
          isSubscribed={subscriptions && subscriptions.eAlerts}
          onChange={onToggleEAlerts}
          dataCYSelectors={EALERT_DATACY_SELECTORS}
        />
        <div className="uk-text-center mb-50">
          <SecondaryButton
            onClick={onClickEditSavedSearches}
            size="small"
            dataCY={EMAIL_PREFS.EDIT_SAVED_SEARCHES}
            width="auto"
          >
            Edit Saved Searches
          </SecondaryButton>
        </div>

        <hr />

        <div className="uk-text-small text-xs--center mb-16">
          To stop all communication from our site, including new listing e-Alerts, click here to{' '}
          <a onClick={onClickUnsubscribeAll} data-cy={EMAIL_PREFS.UNSUBSCRIBE_FROM_ALL_BUTTON}>
            Unsubscribe
          </a>{' '}
          from all.
        </div>

        {!isMobileScreen && (
          <Grid gutters justifyContent="center">
            <Cell md={6}>
              <PrimaryButton
                type="submit"
                form="email-prefs__form"
                className="at-email-prefs-submit-btn"
                width="full"
                disabled={subscriptions === undefined}
                showSpinner={isSubmitting}
              >
                Update Email Preferences
              </PrimaryButton>
            </Cell>
          </Grid>
        )}
      </form>
      <ConfirmEAlerts
        isOpen={activeConfirmationModal === ConfirmationModal.UnsubscribeEAlerts}
        onClickConfirm={onClickConfirmEAlerts}
        onClickCancel={onClickCancelEAlerts}
        onClickEditEAlerts={onClickConfModalEditEAlerts}
      />
      <ConfirmUnsubscribeAll
        isOpen={activeConfirmationModal === ConfirmationModal.UnsubscribeAll}
        onClickConfirm={onClickConfirmUnsubAll}
        onClickCancel={onClickCancelUnsubAll}
      />
      <ResponseModal
        isOpen={isOpen}
        isSuccess={isSuccess}
        onClickSuccess={onClickCloseInRespModal}
        onClickBack={onClickBackInRespModal}
        onClickRetry={onClickRetryInRespModal}
        successText={successText}
      />
    </div>
  );
};

EmailPreferences.propTypes = {
  isMobileScreen: PropTypes.bool,
  dataCY: PropTypes.string,
  subscriptions: PropTypes.shape({
    agent: PropTypes.bool.isRequired,
    lender: PropTypes.bool.isRequired,
    eAlerts: PropTypes.bool.isRequired,
  }), // or undefined if not yet loaded
  activeConfirmationModal: PropTypes.oneOf([
    null,
    ConfirmationModal.UnsubscribeAll,
    ConfirmationModal.UnsubscribeEAlerts,
  ]),
  isSubmitting: PropTypes.bool,
  onClickClose: PropTypes.func,
  onClickEditSavedSearches: PropTypes.func,
  onSubscribedToggle: PropTypes.func,

  // (isSubscribed: boolean) => {}
  onToggleAgent: PropTypes.func,
  onToggleLender: PropTypes.func,
  onToggleEAlerts: PropTypes.func,
  onSubmit: PropTypes.func,
  onClickUnsubscribeAll: PropTypes.func,
  onClickConfirmUnsubAll: PropTypes.func,
  onClickCancelUnsubAll: PropTypes.func,
  onClickConfirmEAlerts: PropTypes.func,
  onClickCancelEAlerts: PropTypes.func,
  onClickConfModalEditEAlerts: PropTypes.func,

  // Response Modal
  isOpen: PropTypes.bool,
  isSuccess: PropTypes.bool,
  successText: PropTypes.string,
  onClickCloseInRespModal: PropTypes.func,
  onClickBackInRespModal: PropTypes.func,
  onClickRetryInRespModal: PropTypes.func,
};

export default withData(EmailPreferences);

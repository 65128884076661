import { connect } from 'react-redux';
import { getSavedSearches } from 'models/visitor/reducers/selectors';
import { getResponseToastState } from './reducer';
import * as a from './actions';

export default connect(
  state => ({
    searches: getSavedSearches(state),
    toast: {
      ...getResponseToastState(state),
    },
  }),
  (dispatch, ownProps) => ({
    onSavedSearchLinkClick: (payload) => {
      const action = ownProps.onSavedSearchLinkClick || a.selectSavedSearch;
      dispatch(action(payload));
    },
    onChange: (payload) => dispatch(a.changeSavedSearchFrequencyRequest(payload)),
    onDelete: (payload) => dispatch(a.deleteSavedSearchRequest(payload)),
  })
);

import { createAction as createReduxAction } from 'redux-act';

// Prefix all descriptions with the view that these actions pertain to.
const createAction = (desc, ...rest) => createReduxAction(`Mobile Details > ${desc}`, ...rest);

/**
 * Used on the Property Details view to populate the `mobileDetailsPage.listing` branch in our
 * global state tree.
 */
export const receiveListingFromAPI = createAction('Receive listing data from API', listing => listing);
export const receiveListingFromAPIFailure = createAction('Receive listing data from API FAILED');

export const updateListingForDetailsPage = createAction(
  'Listing attributes Updated',
  listing => listing
);

export const clickBackToResults = createAction('Click the "Back to Results" button', () => {});
export const clickContactAgent = createAction('Click the "Contact Agent" button', () => ({ scrollY: window.scrollY }));
export const clickMapThumbnail = createAction('Click the map thumbnail', () => {});
export const clickFavoriteButton = createAction('Click the favorite button', () => {});
export const clickEstimatedMortgageText = createAction(
  'Click the estimated mortgage amount',
  () => {}
);
export const toggleEstimatedMortgageSection = createAction('Estimated mortgage section toggle click', () => {});
export const toggleContactAgentSection = createAction('Contact agent form section toggle click', () => {});
export const clickNeighborhoodLink = createAction('Click the neighborhood link', () => {});
export const clickCountyLink = createAction('Click the county link', () => {});
export const clickAreaLink = createAction('Click the area link', () => {});

const createMapAction = (desc, ...rest) => createAction(`Map Modal > ${desc}`, ...rest);
export const clickMapModalCloseButton = createMapAction('Click the close button', () => {});
export const clickMapModalDirectionsBtn = createMapAction(
  'Click the "Directions" button',
  () => {}
);

export const clickTourInPerson = createAction('Click the Tour in Person Button');
export const clickTourViaVideo = createAction('Click the Tour Via Video Chat Button');

export const onPhotoSlide = createAction(
  'Photo Slider Slide',
  /** @param {{ listingId: number, slideIndex: number, photoCount: number }} */
  ({ listingId, slideIndex, photoCount }) => ({ listingId, slideIndex, photoCount })
);

/**
 * Used to trigger a photo gallery inside the HSN App.
 */
export const onPhotoSlideClick = createAction(
  'Photo Slider Slide Click',
  /** @param {Number} */
  activeIndex => activeIndex
);

/**
 * CNS-5564: Hide the Sash and Favorite button from the Details Page Slider when the CTA
 * Slide is active.
 */
export const hideSliderUI = createAction('Hide Slider UI', x => x);
export const showSliderUI = createAction('Show Slider UI', x => x);

export const clickContinuedSearchCard = createAction('Click continued search card');
export const clickContinuedSearchFavoriteButton = createAction('Click continued search card fav button');

export const receivedContinuedSearchResults = createAction(
  'Received continued search results',
  x => x
);
export const receivedRelatedListings = createAction(
  'Received related listings',
  x => x
);

import { View } from 'backbone';
import * as bbModalActions from 'services/bbModal/bbModalActions';
import UIkit from 'uikit';
import $ from 'jquery';

export default class BaseModalView extends View {
  constructor(options) {
    super(options);
    this.reload = this.reload.bind(this);
    this.fire = this.fire.bind(this);
    this.scrollTop = 0;
  }

  get className() {
    return 'uk-modal';
  }

  initialize() {
    super.initialize();

    // custom binding for regform on /login page
    if (this.id === 'static-register-form') {
      this.wrap = $('<div>').append(this.template(this.data));
      this.$el.attr('id', this.id).append(this.wrap);
      return;
    }

    // Hide these modals by default to ensure pages that don't load our styles (such as Inbound Now) don't get these in output
    this.wrap = $('<div class="uk-modal-dialog">')
      .append(
        '<a href="#" class="uk-modal-close bt-modal__close" aria-label="Close"><i class="fas fa-fw fa-times bt-fa-icon"></i>'
      )
      .append(this.template(this.data));
    this.$el.attr('id', this.id);
    this.$el.append(this.wrap);
    this.$el.appendTo('body');

    // Attach event listeners to this modals show and hide events
    this.$el.on({
      'show.uk.modal': () => {
        /* eslint-disable global-require */
        const { dispatch } = require('store').default;
        /* eslint-enable */

        dispatch(bbModalActions.open({ id: this.id }));
      },
      'hide.uk.modal': () => {
        /* eslint-disable global-require */
        const { dispatch } = require('store').default;
        /* eslint-enable */

        dispatch(bbModalActions.close({ id: this.id }));
      },
    });

    this.listenTo(window.bt.events, this.id, this.fire);
  }

  reload() {
    this.undelegateEvents();
    if (this.wrap) {
      this.wrap
        .empty()
        .append(
          '<a href="#" class="uk-modal-close bt-modal__close" aria-label="Close"> <i class="fas fa-fw fa-times bt-fa-icon"></i>'
        )
        .append(this.template(this.data));
    }
    return this.delegateEvents();
  }

  fire() {
    UIkit.modal(`#${this.id}`).show();
    return false;
  }
}

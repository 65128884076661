import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

/**
 * The badge that displays the number of currently applied filters in the off-
 * canvas search menu. NOTE: This is a perfect component to be just an inline
 * instance of a common <Badge> component.
 */
export default function FiltersAppliedBadge(props) {
  return (
    <span
      className={cx({
        'uk-badge uk-badge-notification': true,
        'uk-badge-muted': !props.count
      })}
    >
      {props.count || 0}
    </span>
  );
}

FiltersAppliedBadge.displayName = 'FiltersAppliedBadge';
FiltersAppliedBadge.propTypes = {
  count: PropTypes.number
};

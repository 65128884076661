import { call, takeEvery, select, fork } from 'redux-saga/effects';
import { LOCATION_CHANGED } from 'redux-little-router';

import { listings as listingsCollection } from 'BoomTown';
import * as r from 'routes';

// Action
import * as bootstrapActions from 'actions/bootstrapActions';
import * as BackboneActions from 'actions/BackboneActions';
import * as desktopGalleryActions from 'backbone/Views/Listings/gallery/galleryActions';
import * as noResultsActions from 'components/NoResults/actions';
import { clickSaveSearchInSearchBar } from 'components/SearchBar/actions';
import * as mobileGalleryActions from 'actions/MobileGalleryActions';
import * as mobileActions from 'actions/MobileDetailsActions';
import * as desktopDetailsActions from 'backbone/Views/Listings/single/actions';
import * as QualifyingQuestionsActions from 'actions/QualifyingQuestionsActions';
import {
  onNearbyClick,
  onPolygonClick,
  onClearPolygonClick,
  onMapTypeClick
} from 'screens/ResultsMap/ActionBar/actions';
import { logOffensiveWord } from 'actions/UtilityActions';
import { receiveError } from 'services/geolocation/actions';
import * as desktopSAO from 'actions/StartAnOfferActions';
import * as mobSAO from 'actions/mobileStartAnOffer';
import { onPinClicked, receiveResults } from 'screens/ResultsMap/Map/actions';
import { clickTag as clickSaveSearchTag } from 'actions/MobileSaveSearchActions';

// Selectors
import { getRouteID, getRouterState } from 'selectors/router';
import { isMobile as isMobileSelector } from 'selectors/browser';
import {
  getCurrentQuestion,
  getQuestionByChoiceID,
} from 'components/Modals/QualifyingQuestions/selectors';


/**
 * Logs listing search count on the gallery pages
 */

let listingCount = 0;

function* onSearchReset(action) {
  const routeID = yield select(getRouteID);

  /**
   * dispatch only if
   *  on gallery page
   *  have global dataLayer
   *  listing count is different than previous reset (to avoid sorting/paging tracking)
   */
  const fireEvent =
    routeID === r.RESULTS_GALLERY && window.dataLayer && listingCount !== action.payload.totalItems;
  if (fireEvent) {
    yield call([window.dataLayer, window.dataLayer.push], {
      event: action.type,
      listingCount,
    });
    listingCount = action.payload.totalItems;
  }
}

/**
 * Logs save search modal impressions
 */
function* logSaveSearchImpression() {
  yield call([window.dataLayer, window.dataLayer.push], {
    event: 'savesearch-form-impression',
  });
}

/**
 * @param {Action} selectAction A QualifyingQuestionsActions.selectAnswer
 * action
 */
function* logQQAnswer({ payload: { id: responseID, question: questionText, choice: choiceText } }) {
  if (!window.dataLayer) {
    return;
  }

  // When no ID is passed, the question was one used via an Optimize test
  if (typeof responseID !== 'number') {
    window.dataLayer.push({
      question: questionText,
      response: choiceText,
      event: 'buying-timeline-modal',
    });
    return;
  }

  const question = yield select(getQuestionByChoiceID, responseID);
  window.dataLayer.push({
    questionID: question.id,
    question: questionText,
    response: choiceText,
    event: 'buying-timeline-modal',
  });
}

function* logQQModalDismissal() {
  /** @type {QualifyingQuestion} */
  const question = yield select(getCurrentQuestion);

  if (window.dataLayer) {
    window.dataLayer.push({
      questionID: question.id,
      question: question.question,
      event: 'buying-timeline-modal',
    });
  }
}

function* logCTASlide() {
  yield call([window.dataLayer, window.dataLayer.push], {
    event: 'CTASlideViewed',
  });
}

function* logNoResults() {
  yield call([window.dataLayer, window.dataLayer.push], {
    event: 'deadendpage',
    noresultsPage: 'No Results',
  });
}

function* logOffensiveWordToGTM({ payload }) {
  yield call([window.dataLayer, window.dataLayer.push], {
    event: 'offensivename',
    offensiveword: payload,
  });
}
function* trackPhotoSlideEvent(action) {
  const {
    payload: { listingId },
  } = action;
  yield call([window.dataLayer, window.dataLayer.push], {
    event: 'cardSliderTriggered',
    listingID: listingId,
  });
}

function* onMapPinClick() {
  yield call([window.dataLayer, window.dataLayer.push], {
    event: 'mapPinClicked',
  });
}

function* onMapNearbyClick() {
  yield call([window.dataLayer, window.dataLayer.push], {
    event: 'mapNearbyClicked',
  });
}

function* onMapNearbyError(action) {
  yield call([window.dataLayer, window.dataLayer.push], {
    event: 'mapNearbyError',
    nearbyErrorCode: action.payload.code,
  });
}

function* onPolygonActiveClick() {
  yield call([window.dataLayer, window.dataLayer.push], {
    event: 'mapPolygonAdded',
  });
}

function* onPolygonClearClick() {
  yield call([window.dataLayer, window.dataLayer.push], {
    event: 'mapPolygonRemoved',
  });
}

function* onMapTypeToggleClick() {
  yield call([window.dataLayer, window.dataLayer.push], {
    event: 'mapTypeToggle',
  });
}

function* onDetailsView({ payload }) {
  yield call([window.dataLayer, window.dataLayer.push], {
    event: 'FBView',
    listing: payload,
  });
}

/**
 * Push FBSearch pixel to data layer for results views that still have access to the listing
 * collection. Desktop views only.
 */
function* onGalleryViewSearch() {
  const currentRoute = yield select(getRouteID);
  const listingColResultsView = [r.RESULTS_GALLERY].includes(currentRoute);

  if (listingsCollection.length && listingColResultsView) {
    // Standardize listings structure from bb listing models for use with `logFBSearch()`
    const listings = listingsCollection.map(l => l.attributes);
    yield logFBSearch(listings);
  }
}

function* onMapSearch({ payload }) {
  const currentRoute = yield select(getRouteID);
  if (payload.listings.length && currentRoute === r.RESULTS_MAP) {
    yield logFBSearch(payload.listings);
  }
}

/**
 * Push FBSearch pixel to data layer when we receive new results on the Mobile Gallery
 */
function* onMobileGallerySearch({ payload: { listings } }) {
  if (listings && listings.length) {
    yield logFBSearch(listings);
  }
}

/**
 * Push FBSearch pixel to data layer when we do a server-side render on the Mobile Gallery
 */
function* onInitialMobileGallerySearch({ payload: { listings } }) {
  if (listings && listings.Result) {
    const isMobile = yield select(isMobileSelector);
    if (isMobile) {
      yield logFBSearch(listings.Result.Items);
    }
  }
}

/**
 * Push FBSearch pixel to data layer
 */
function* logFBSearch(listings) {
  if (listings.length === 0) {
    return;
  }
  const listingIDs = listings.map(l => l._ID);
  const firstListing = listings[0];
  yield call([window.dataLayer, window.dataLayer.push], {
    event: 'FBSearch',
    listing: {
      _ID: listingIDs,
      Location: {
        City: firstListing.Location.City,
        State: firstListing.Location.State,
        Neighborhood: {
          Name: firstListing.Location.Neighborhood.Name
        }
      }
    }
  });
}

function* onFavoriteClick(action) {
  yield call([window.dataLayer, window.dataLayer.push], {
    event: action.type,
    listing: {
      listingID: action.payload,
    }
  });
}

function* onIframeResponse(action) {
  const {
    payload: { key, status },
  } = action;
  yield call([window.dataLayer, window.dataLayer.push], {
    event: key,
    status,
  });
}

function* onSocialLogin() {
  yield call([window.dataLayer, window.dataLayer.push], {
    event: 'social-login',
  });
}

function* onSAOSubmit(action) {
  const {
    payload: { event, contacttype, formtype, form },
  } = action;
  yield call([window.dataLayer, window.dataLayer.push], {
    event,
    contacttype,
    form,
    formtype,
  });
}

export default [
  takeEvery(BackboneActions.listingsReset, onSearchReset),
  takeEvery(
    [
      // desktop
      clickSaveSearchInSearchBar,
      desktopGalleryActions.clickSaveSearchCard,
      desktopGalleryActions.clickSaveSearchInNoResults,
      noResultsActions.clickSaveSearch,

      // mobile
      mobileGalleryActions.clickSaveSearchInResultsHeader,
    ],
    logSaveSearchImpression
  ),
  takeEvery(QualifyingQuestionsActions.selectAnswer, logQQAnswer),
  takeEvery([mobileActions.hideSliderUI, desktopDetailsActions.showPhotoSliderCTA], logCTASlide),
  takeEvery(QualifyingQuestionsActions.dismissQualifyingQuestionsModal, logQQModalDismissal),
  takeEvery(noResultsActions.logNoResults, logNoResults),
  takeEvery(logOffensiveWord, logOffensiveWordToGTM),
  takeEvery(onPinClicked, onMapPinClick),
  takeEvery(mobileGalleryActions.onPhotoSlide, trackPhotoSlideEvent),
  takeEvery(onNearbyClick, onMapNearbyClick),
  takeEvery(receiveError, onMapNearbyError),
  fork(trackTimeOnRoute),
  takeEvery(onPolygonClick, onPolygonActiveClick),
  takeEvery(onClearPolygonClick, onPolygonClearClick),
  takeEvery(onMapTypeClick, onMapTypeToggleClick),
  takeEvery([mobileActions.receiveListingFromAPI, desktopDetailsActions.createDataLayer], onDetailsView),
  takeEvery([BackboneActions.addFavorite, BackboneActions.removeFavorite], onFavoriteClick),

  // Log FBSearch Pixels
  takeEvery(BackboneActions.listingsReset, onGalleryViewSearch),
  takeEvery(receiveResults, onMapSearch),
  takeEvery(mobileGalleryActions.receiveSearchResults, onMobileGallerySearch),
  takeEvery(bootstrapActions.initializeState, onInitialMobileGallerySearch),

  takeEvery(BackboneActions.getResponseFromIframe, onIframeResponse),
  takeEvery(BackboneActions.loginUsingSocial, onSocialLogin),
  takeEvery([desktopSAO.logGTM, mobSAO.logGTM], onSAOSubmit),

  // Save A Search actions
  takeEvery(
    clickSaveSearchTag,
    function* logSaveSearchTagClick({ payload: { prop, value, disabled } }) {
      yield call([window.dataLayer, window.dataLayer.push], {
        event: 'saveSearchTagClick',
        searchProp: prop,
        propValue: value,
        searchPropDisabled: disabled
      });
    }
  ),
];

export function* trackTimeOnRoute() {
  let timeArrivedOnRoute;

  yield takeEvery(LOCATION_CHANGED, function* onLocationChanged() {
    const router = yield select(getRouterState);
    if (!timeArrivedOnRoute) {
      timeArrivedOnRoute = new Date().getTime();
      return;
    }

    if (!hasChangedRouteIDs(router)) {
      return;
    }

    const currentTime = new Date().getTime();
    const timeOnPage = currentTime - timeArrivedOnRoute;
    yield call([window.dataLayer, window.dataLayer.push], {
      event: 'beforeunload',
      timeOnPage,
    });
    timeArrivedOnRoute = currentTime;
  });

  window.addEventListener('beforeunload', () => {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push({
      event: 'beforeunload',
      timeOnPage: new Date().getTime() - timeArrivedOnRoute,
    });
  });
}

/**
 * Does the state indicate that the route ID has just changed?
 *
 * @param {Object} router
 */
function hasChangedRouteIDs(router) {
  // First require that the id is defined for both previous and current, only then move on to equality.
  const prevID =
    router.previous && router.previous.result && router.previous.result.id
      ? router.previous.result.id
      : null;
  const nextID = router.result && router.result.id ? router.result.id : null;
  return prevID !== nextID;
}

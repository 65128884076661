import { createReducer } from 'redux-act';
import { addCommas } from 'utility';

import * as geoActions from 'services/geolocation/actions';
import * as mapActions from '../Map/actions';
import * as polygonActions from '../Map/PolygonOverlay/actions';
import * as actionBarActions from '../ActionBar/actions';

const initialState = {
  visible: false,
  message: '',
};

export default createReducer(
  {
    [actionBarActions.onPolygonClick]: () => ({
      visible: true,
      message: 'Draw a search boundary',
    }),

    [actionBarActions.onClearPolygonClick]: reset,
    [polygonActions.onFailedSimplePath]: () => ({
      visible: true,
      message: 'Boundary failed. Please try again.',
    }),
    [polygonActions.onDrawEnd]: reset,

    // Add some feedback for the first request to the geolocation api
    [geoActions.startLocationRequest]: () => ({
      visible: true,
      message: 'Fetching your location...',
    }),

    [geoActions.receiveCurrentLocation]: state => {
      if (state.message === 'Fetching your location...') {
        return reset();
      }

      return state;
    },

    [geoActions.cancelLocationRequest]: state => {
      if (state.message === 'Fetching your location...') {
        return reset();
      }

      return state;
    },

    [geoActions.receiveError]: () => ({
      visible: true,
      message: 'An error occurred while accessing your location.',
    }),

    [mapActions.receiveResults]: (state, payload) => {
      let toastMessage;
      const { totalItems, totalItemsWithoutCoords } = payload;

      const numPinsInViewport = addCommas(totalItems);
      const numPinsWithoutCoords = addCommas(totalItemsWithoutCoords);

      if (totalItems === 0) {
        toastMessage = 'No homes found - Adjust filters or map';
      } else {
        const homeText = totalItems === 1 ? 'home' : 'homes';
        toastMessage = `${numPinsInViewport} ${homeText} in view (${numPinsWithoutCoords} total)`;
      }

      return {
        visible: true,
        message: toastMessage,
      };
    },

    [mapActions.errorResults]: () => ({
      visible: true,
      message: 'An error occurred. Please try again.'
    }),

    [mapActions.receiveNewResultsPage]: (state, payload) => {
      const { firstIndex, lastIndex, totalItems, totalItemsWithoutCoords } = payload;
      const formattedTotal = addCommas(totalItems);
      const formattedWithoutCoords = addCommas(totalItemsWithoutCoords);
      const toastMessage = `Viewing ${firstIndex} - ${lastIndex} of ${formattedTotal} (${formattedWithoutCoords} total)`;

      return ({
        visible: true,
        message: toastMessage,
      });
    },
  },
  reset()
);

export const _getToastState = (localState) => localState;

const select = fn => state => fn(state.resultsMap.toast);
export const getToastState = select(_getToastState);

function reset() {
  return initialState;
}

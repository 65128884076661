/* eslint-disable no-constant-condition, no-continue */
import { fork, take, race, takeEvery, put, select, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import * as Routes from 'routes';
import { api as Api, visitor as visitorModel } from 'BoomTown';
import { emailPreferences } from 'models/visitor/reducers/selectors';
import { isMobile as isMobileSelector } from 'selectors/browser';
import { errorFetchingEmailPreferences, receiveEmailPreferences } from 'actions/visitorActions';
import isRoute from 'utility/isRoute';
import {
  submitEmailPreferencesForm,
  failedSubmitEmailPreferencesForm,
  successSubmitEmailPreferencesForm,
  clickConfirmUnsubscribeAllModal,
  autoDismissEmailPrefsSuccessModal,
  clickCloseEmailPrefsSuccessModal,
  clickRetryInEmailPrefsFailureModal,
} from './actions';
import { getEmailPrefsFormState } from './reducer';

export default [
  takeEvery(
    // Desktop Route: `/account`
    // Mobile Route: `/account/email-preferences`
    [isRoute(Routes.EMAIL_PREFERENCES), isRoute(Routes.ACCOUNT)],
    fetchEmailPrefs,
  ),

  fork(saveEmailPreferencesWatcher),
];

function* fetchEmailPrefs(action) {
  // Don't fetch email prefs for the account route when on mobile
  if (isRoute(Routes.ACCOUNT)(action)) {
    const isMobile = yield select(isMobileSelector);
    if (isMobile) {
      return;
    }
  }
  const prefsFromState = yield select(emailPreferences);
  if (!prefsFromState) {
    /** @type {FlagshipAPI.EmailPreferencesResponse} */
    let prefs;
    try {
      prefs = yield call([Api, Api.getEmailPreferences]);
    } catch (e) {
      yield put(errorFetchingEmailPreferences(e));
      return;
    }
    yield put(receiveEmailPreferences(prefs));
  }
}

// Only exporting for testing purposes
export function* saveEmailPreferencesWatcher() {
  while (true) {
    const { clickedSubmit, clickedRetry, confirmedUnsubAll } = yield race({
      clickedSubmit: take(submitEmailPreferencesForm),
      clickedRetry: take(clickRetryInEmailPrefsFailureModal),
      confirmedUnsubAll: take(clickConfirmUnsubscribeAllModal),
    });

    if (clickedSubmit || clickedRetry) {
      yield call(updateEmailPreferences);
    } else if (confirmedUnsubAll) {
      yield call(unsubscribeAll);
    }
  }
}

function* unsubscribeAll() {
  yield call(saveEmailPreferences, {
    OptOutGlobal: true,
    OptOutAgent: true,
    OptOutLender: true,
    OptOutEAlerts: true,
  });
}

/**
 * Save the user's email preferences to the server. Independent of the UI, only
 * about saving an entity.
 *
 * @param {FlagshipAPI.EmailPrefsUpdateRequest} request
 */
function* saveEmailPreferences(request) {
  try {
    yield call([visitorModel, visitorModel.updatePreferencesPromise], request);
  } catch (error) {
    yield put(failedSubmitEmailPreferencesForm({ error }));
    return;
  }

  // Dispatching form submission here as well to avoid implementing rollback
  // in our reducer
  yield put(successSubmitEmailPreferencesForm(request));
  yield race([
    call(dismissAfterTimeout),
    take(clickCloseEmailPrefsSuccessModal),
  ]);
}

function* dismissAfterTimeout() {
  yield call(delay, 2000);
  yield put(autoDismissEmailPrefsSuccessModal());
}

/**
 * Compute the next state to send to the server to be saved, and save it.
 */
function* updateEmailPreferences() {
  /** @type {{ agent?: boolean, lender?: boolean, eAlerts?: boolean }} */
  const formData = yield select(getEmailPrefsFormState);

  yield call(saveEmailPreferences, {
    // Submitting the form with all radios set to "Unsubscribed" should be
    // equivalent to `OptOutGlobal: true`
    OptOutGlobal: [formData.agent, formData.lender, formData.eAlerts].every(x => x === false),
    OptOutAgent: !formData.agent,
    OptOutLender: !formData.lender,
    OptOutEAlerts: !formData.eAlerts,
  });
}

import validator from 'validator';
import { isFullName, isValidName } from 'utility/parse-names';
import phoneValidator from 'utility/phonevalidator';

validator.extend('isPhone', (s) => {
  const result = phoneValidator(s);
  return !!result.valid;
});

// CNS-4717: Expanded Regex to accomodate less common accents, carons, ect
// @see https://stackoverflow.com/questions/20690499/concrete-javascript-regex-for-accented-characters-diacritics
validator.extend('isFullName', isFullName);

validator.extend('isValidName', (s) => {
  const trimmed = validator.trim(s);
  if (trimmed !== '') {
    return isValidName(s);
  }
  return false;
});

validator.extend('isNotWhitespace', (s) => {
  const trimmed = validator.trim(s);
  return trimmed !== '';
});

validator.extend('isNumber', (s) => {
  const trimmed = parseFloat(validator.trim(s));
  return !Number.isNaN(trimmed) && Number.isFinite(trimmed);
});

validator.extend('isNotFalsyOrWhitespace', s => !!(s && !/^\s+$/.test(s)));

export default validator;

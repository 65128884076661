/* eslint-disable indent */
import React, { Component } from 'react';

/**
 * A higher-order component/decorator that handles validation and maintaining state
 * for a form component.
 *
 * @param  {FieldDefinition[]} fieldDefinitions An array of field def'n objects. (See above)
 * @return {Function}
 */
export default function createForm(fieldDefinitions) {
  return function decorate(WrappedFormComponent) {
    return class StatefulForm extends Component {
      static displayName = `StatefulForm<${WrappedFormComponent.displayName ||
        WrappedFormComponent.name ||
        'Component'}>`;
      static propTypes = {};

      constructor(props) {
        super(props);

        const fieldsWithInitialState = fieldDefinitions.filter(
          def => typeof def.initialState !== 'undefined' && def.initialState !== null
        );

        const formData = fieldsWithInitialState.length
          ? fieldsWithInitialState.reduce((acc, def) => {
              if (typeof def.initialState === 'function') {
                acc[def.name] = def.initialState(this.props); // eslint-disable-line no-param-reassign
              } else {
                acc[def.name] = def.initialState; // eslint-disable-line no-param-reassign
              }
              return acc;
            }, {})
          : {};

        this.state = {
          formData,
          errors: {},
        };

        this.validationRules = fieldDefinitions.filter(d => Boolean(d.validation)).map(d => {
          const obj = {
            name: d.name,
            validation: d.validation,
            step: d.step,
          };
          return obj;
        });

        this.formHandlers = this.createFormHandlers();
      }

      createFormHandlers = () =>
        fieldDefinitions.reduce((acc, def) => {
          // eslint-disable-next-line no-param-reassign
          acc[def.name] = (e, cb = function noop() {}) => {
            let value = null;
            switch (def.type) {
              case 'text':
                value = e.target.value;
                break;
              case 'checkbox':
                value = e.target.checked;
                break;
              case 'manual':
                value = e;
                break;
              default:
                value = e.target.value;
                break;
            }
            const nextState = {
              ...this.state,
              formData: {
                ...this.state.formData,
                [def.name]: value,
              },
            };

            if (this.state.hasSubmitted) {
              nextState.errors = this.validateFields(nextState.formData);
            }

            // @ts-ignore
            this.setState(nextState, () => cb(this.state.formData));
          };
          return acc;
        }, {});

      validateStep = stepCount => {
        const newState = {
          ...this.state,
          errors: this.validateFields(this.state.formData, stepCount),
        };

        this.setState(newState);

        return newState.errors;
      };

      /**
       * A function that should be called by the form component on submit.
       * Performs validation and sets `hasSubmitted` to true.
       * @return {{ [key: FieldID]: string? }} An array of errors, which is empty if valid.
       */
      validateForm = () => {
        const newState = {
          ...this.state,
          hasSubmitted: true,
          errors: this.validateFields(this.state.formData),
        };

        this.setState(newState);

        return newState.errors;
      };

      /**
       * Perform validation using the rules defined in `fieldDefinitions`.
       *
       * @param {object} formData The set of the state object that contains the forms data.
       * @param {number} [stepCount] An optional value for multi-step forms to limit
       * validation to specific fields in a step.
       * @return {{ [key: FieldID]: string? }} An object of error messages, FieldID => string?
       */
      validateFields(formData, stepCount) {
        /**
         * @param {FieldValidator} isValid
         * @param {*} fieldValue
         * @param {*} data
         * @param {*} message
         * @returns {string | null} An error message if validation fails, null if it passes.
         */
        function testValidation(isValid, fieldValue, data, message) {
          if (isValid && !isValid(fieldValue, data)) {
            return message;
          }
          return null;
        }

        return this.validationRules
          .map(({ name, validation, step }) => {
            if (Boolean(stepCount) && stepCount !== step) {
              return null;
            }
            if (Array.isArray(validation)) {
              for (const v of validation) {
                const message =
                  testValidation(v.isValid, formData[name], formData, v.message) || null;
                if (message) {
                  return { name, message };
                }
              }
              return null;
            }

            const message = testValidation(
              // @ts-ignore
              validation.isValid,
              formData[name],
              formData,
              // @ts-ignore
              validation.message
            );
            return message ? { name, message } : null;
          })
          .filter(e => !!e)
          .reduce((acc, curr) => {
            // @ts-ignore
            acc[curr.name] = curr.message; // eslint-disable-line no-param-reassign
            return acc;
          }, {});
      }

      render() {
        return (
          <WrappedFormComponent
            {...this.props}
            formData={{ ...this.state.formData }}
            errors={this.state.errors}
            formHandlers={this.formHandlers}
            validateForm={this.validateForm}
            validateStep={this.validateStep}
          />
        );
      }
    };
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import FAIcon from 'components/core/FAIcon';
import { Grid, Cell } from 'components/core/Grid';

function Toast({ message, status }) {
  const statusIcon = (() => {
    if (status === 'success') {
      return <FAIcon icon="check" type="solid" className="fa-lg" />;
    } else if (status === 'failure') {
      return <FAIcon icon="times" type="solid" className="fa-lg" />;
    }
    return (
      <div style={{ width: '24px' }}>
        <FAIcon
          icon="spinner"
          type="solid"
          animation="spin"
          className="fa-lg uk-display-block"
        />
      </div>
    );
  })();

  const toastBackground = cx({
    'bg-green': status === 'success',
    'bg-blue': status === 'processing',
    'bg-red': status === 'failure',
  });

  return (
    <div className="toast uk-animation-slide-bottom">
      <div className="toast__content">
        <Grid flexCells>
          <Cell noGrow className={`${toastBackground} grid--center p-16 bt-text--white`}>
            {statusIcon}
          </Cell>
          <Cell className="p-16 uk-text-semibold">{message}</Cell>
        </Grid>
      </div>
    </div>
  );
}
Toast.propTypes = {
  message: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['success', 'failure', 'processing', '']),
};

export default Toast;

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Cell } from 'components/core/Grid';

const UserInfoStack = props => {
  const { className = '', children } = props;
  return (
    <Grid column alignItems="center" className={className}>
      <Cell className="text-xs--center">
        {children}
      </Cell>
    </Grid>
  );
};

UserInfoStack.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]),
};

export default UserInfoStack;

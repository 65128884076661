/* eslint-disable no-confusing-arrow, no-shadow */
/**
 * This is where the main tree of our app starts to branch
 *
 * Be super careful changing the names of the comments for webpackChunkName
 * details, home, and results-gallery are magic strings used by php
 * for script loading / prefetching
 * @see /wp-content/themes/wp-base-theme/inc/assets.php
 */
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { config } from 'BoomTown';
import { connect } from 'react-redux';
import { ForRoute, ForCatchAll } from 'components/ForRoute';
import * as routes from 'routes';
import loadable from 'react-loadable';

import { isFullScreenMobileRoute } from 'selectors/router';
import { isSearchMenuOpen, isSaveSearchMenuOpen } from 'selectors/mobileGallery';

import { Laptop } from 'components/core/BreakPoints';
import IsMobile from 'components/IsMobile';
import CachedHTML from 'screens/util/CachedHTMLContainer';
import ContactAgentModal from 'components/MobileContactAgent/ContactAgentModal';
import MobileAppDownloadHeader from 'components/MobileAppDownloadHeader';
import MobileSiteHeader from 'components/MobileSiteHeader';
import MobileResultsHeader from 'components/MobileResultsHeader';
import QualifyingQuestionsModal from 'components/Modals/QualifyingQuestions';
import IsSplitLayout from 'screens/IsSplitLayout';
import ConnectedSearchMenu from 'components/SearchMenu';
import SearchBar from 'components/SearchBar';
import SearchBarEnterprise from 'components/SearchBarEnterprise';
import OffCanvas from 'components/OffCanvas/OffCanvasContainer';
import LazySaveSearchModal from 'components/SaveSearchModal/Lazy';
import SaveSearchMenus from 'components/SavedSearchMenu';
import OneClickModal from 'components/Modals/OneClick';
import ResetPasswordForm from 'components/Forms/ResetPassword';
import { PluginFairHousingNotice } from 'coreComponents';

// Screens
import Account from 'screens/Account';
import CatchAll from 'screens/CatchAll';
import DetailsPage from 'screens/Details';
import Gallery from 'screens/Gallery';
import MobileMenu from 'screens/MobileMenu';
import MobileSaveSearch from 'screens/MobileSaveSearch';
import MobileSearchMenu from 'screens/MobileSearchMenu';
import ResultsMap from 'screens/ResultsMap';

const SearchComponent = ({ overrideSearchBox = false }) => {
  // Server HTML template; logo, enterprise ballerbox
  if (overrideSearchBox) {
    return <SearchBarEnterprise />;
  }

  // Logo, ballerbox, tagbar, and results toggles
  return (
    <SearchBar>
      <ConnectedSearchMenu />
    </SearchBar>
  );
};
SearchComponent.propTypes = {
  overrideSearchBox: PropTypes.bool,
};

const LazyHome = loadable({
  loader: () => import(/* webpackChunkName: 'js/build/home' */ 'screens/Home'),
  loading: CachedHTML,
});

const LazyLogin = loadable({
  loader: () => import(/* webpackChunkName: 'js/build/login' */ 'screens/Login'),
  loading: () => null,
});

const LazyFavorites = loadable({
  loader: () => import(/* webpackChunkName: 'js/build/favorites' */ 'screens/Favorites'),
  loading: () => null,
});

const LazyCompare = loadable({
  loader: () => import(/* webpackChunkName: 'js/build/favorites' */ 'screens/Compare'),
  loading: () => null,
});

class App extends React.Component {
  /**
   * We get a server template that we aren't going to use
   * we hide it with css, but use it as a makeshift SSR
   */
  componentDidMount() {
    const els = document.getElementsByClassName('js-mobile-header__wrapper');
    if (els.length) {
      els[0].classList.add('bt-header__mobile--tablet');
    }

    // Fair Housing Plugin (Global Placement)
    const pluginFairHousingHeaderNode = document.querySelector('#bt-plugin-fair-housing-notice-react-header-global');
    if (pluginFairHousingHeaderNode) {
      ReactDOM.render(<PluginFairHousingNotice placement="header" global />, pluginFairHousingHeaderNode);
    }

    const pluginFairHousingFooterNode = document.querySelector('#bt-plugin-fair-housing-notice-react-footer-global');
    if (pluginFairHousingFooterNode) {
      ReactDOM.render(<PluginFairHousingNotice placement="footer" global />, pluginFairHousingFooterNode);
    }
    // End Fair Housing Plugin (Global Placement)

    const resetPasswordForm = document.querySelector('#bt-token-reset-password-form');
    if (resetPasswordForm) {
      ReactDOM.render(<ResetPasswordForm />, resetPasswordForm);
    }
  }

  render() {
    const {
      isSearchOpen,
      isFullScreenMobileRoute,
      isMobileSaveSearchOpen,
      isMobileSearchMenuOpen,
    } = this.props;

    // Tween Search Menu
    if (isSearchOpen) {
      return (
        <Laptop smaller>
          <OffCanvas />
        </Laptop>
      );
    }

    if (isMobileSaveSearchOpen) {
      return <MobileSaveSearch />;
    }
    if (isMobileSearchMenuOpen) {
      return <MobileSearchMenu />;
    }

    return (
      <div>

        <IsMobile>
          {isMobile => isMobile && !isFullScreenMobileRoute && <MobileAppDownloadHeader />}
        </IsMobile>

        <IsMobile>
          {isMobile => isMobile && !isFullScreenMobileRoute && <MobileSiteHeader />}
        </IsMobile>

        {/* TODO: Still being rendered for desktop */}
        <ForRoute id={routes.MENU}>
          <MobileMenu />
        </ForRoute>

        <ForRoute id={routes.CONTACT_AGENT}>
          <ContactAgentModal hideRequestShowing />
        </ForRoute>

        {/* Old - mobile header action bar */}
        <MobileResultsHeader />

        {/* Old - "Saved Searches" menu item rendered in two different places via portals */}
        <SaveSearchMenus />

        <ForRoute id={routes.HOME}>
          <LazyHome />
        </ForRoute>

        <IsSplitLayout>
          <div>
            {/* Modals */}
            <IsMobile>{isMobile => (isMobile ? null : <LazySaveSearchModal />)}</IsMobile>

            {/* SearchBar */}
            <IsMobile>
              {isMobile => isMobile ?
                null : <SearchComponent overrideSearchBox={config.overrideSearchBox} />
              }
            </IsMobile>

            {/* Main Content */}
            <ForRoute id={routes.RESULTS_GALLERY}>
              {config.isCustomHomepage ? null : <Gallery />}
            </ForRoute>
            <ForRoute id={routes.RESULTS_MAP}>
              {config.isCustomHomepage ? null : <ResultsMap />}
            </ForRoute>
            <ForRoute id={routes.FAVORITES}>
              <LazyFavorites />
            </ForRoute>
            <ForRoute id={routes.COMPARE}>
              <LazyCompare />
            </ForRoute>
            <ForRoute id={routes.DETAILS}>
              <DetailsPage />
            </ForRoute>
            <ForRoute
              ids={[
                routes.ACCOUNT,
                routes.EDIT_ACCOUNT,
                routes.EMAIL_PREFERENCES,
                routes.EDIT_SAVED_SEARCHES,
                routes.CHANGE_PASSWORD,
                routes.TEXT_PREFERENCES,
              ]}
            >
              <Account />
            </ForRoute>
            <ForRoute id={routes.LOGIN}>
              <LazyLogin />
            </ForRoute>
            <ForCatchAll>
              <CatchAll />
            </ForCatchAll>
          </div>
        </IsSplitLayout>

        <QualifyingQuestionsModal />
        <OneClickModal />
      </div>
    );
  }
}

App.propTypes = {
  isSearchOpen: PropTypes.bool.isRequired,
  isMobileSearchMenuOpen: PropTypes.bool.isRequired,
  isMobileSaveSearchOpen: PropTypes.bool.isRequired,
  isFullScreenMobileRoute: PropTypes.bool.isRequired,
};

export default connect(state => ({
  isSearchOpen: state.offCanvasSearch.menu.isOpen,
  isMobileSearchMenuOpen: isSearchMenuOpen(state),
  isMobileSaveSearchOpen: isSaveSearchMenuOpen(state),
  isFullScreenMobileRoute: isFullScreenMobileRoute(state),
}))(App);

/* eslint-disable func-names, space-before-function-paren */
import $ from 'jquery';

const $body = $('body');
const $html = $('html');
const $window = $(window);
const closeOffCanvas = function() {
  $html.removeClass('bt-off-canvas--open');
  $('.js-off-canvas__trigger').removeClass('bt-active');
};
// For public use
$.closeOffCanvas = closeOffCanvas;
const openOffCanvas = function() {
  $html.addClass('bt-off-canvas--open');
  $('.js-off-canvas__trigger').addClass('bt-active');
};

// Off-Canvas
$(document).on('click', '.js-off-canvas__trigger', e => {
  e.preventDefault();

  if ($window.scrollTop() > 0) {
    $body.animate({ scrollTop: 0 }, 300, () => {
      openOffCanvas(e);
      $('.bt-off-canvas--open .bt-off-canvas__wrapper').on('click', () => {
        closeOffCanvas();
      });
    });
  } else {
    openOffCanvas(e);
    $('.bt-off-canvas--open .bt-off-canvas__wrapper').on('click', () => {
      closeOffCanvas();
    });
  }
});

$(document).on('click', '.js-off-canvas__close', e => {
  e.preventDefault();
  closeOffCanvas();
});

import { createAction } from 'redux-act';

// Contact Info
export const updateVisitor = createAction('BB: Visitor change');

// Email Preferences
export const receiveEmailPreferences = createAction(
  'Receive email preferences from API',
  /** @type {FlagshipAPI.EmailPreferencesResponse} */ x => x
);

// Communication Preferences
export const receiveCommunicationPreferences = createAction(
  'Receive visitor communication preferences from API',
  /** @type {FlagshipAPI.CommunicationPreferencesResponse} */ x => x
);

export const errorFetchingEmailPreferences = createAction(
  'Error fetching email preferences from API'
);

export const errorFetchingCommunicationPreferences = createAction(
  'Error fetching communication preferences from API'
);

// Saved Searches
export const receiveSavedSearches = createAction(
  'Receive saved searches from API',
  /** @type {FlagshipAPI.SavedSearchesResponse} */ x => x
);
export const errorFetchingSavedSearches = createAction(
  'Error fetching saved searches from API'
);

export const updateSavedSearchInStore = createAction(
  'Change Saved Search in visitor',
  payload => ({
    searchid: payload.searchid,
    searchName: payload.searchName,
    email: payload.email,
    frequency: payload.frequency,
    querystring: payload.querystring,
    count: payload.count,
  })
);

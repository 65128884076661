import { useMemo } from 'react';

/**
 * Takes an object of Search Fields that need to be displayed via the Select component and formats
 * the `options` for each field to match the API expected by the component. Also inserts an "Any"
 * option as a default value and an "---" option to act as a divider. Returns the formatted options
 * object to pass to the Select component.
 *
 * @param {{ [key:string]: { options: SearchFieldOptions } }} fields
 * @returns {{[key:string]: FormattedSearchFieldOptions} | undefined}
 */
const useFormattedSearchFieldOptions = (fields) => {
  if (!fields) {
    return null;
  }

  /** @type {{[key:string]: FormattedSearchFieldOptions}} */
  const options = useMemo(
    () =>
      Object.keys(fields).reduce((acc, cur) => {
        acc[cur] = fields[cur].options.map((opt) => ({ value: opt.value, label: opt.text }));

        // In case the value is an empty string we _must_ assign a value
        // to the separator. That way the top option will be auto-selected by the browser.
        const headerOptions = [
          { value: 'any', label: 'Any' },
          { value: '---', label: '---', disabled: true },
        ];

        acc[cur].unshift(...headerOptions);

        return acc;
      }, {}),
    [fields]
  );

  return options;
};

export default useFormattedSearchFieldOptions;

/* eslint-disable import/prefer-default-export */
import { createAction as _create } from 'redux-act';

const createAction = (title, ...args) => _create(`Desktop Map > ${title}`, ...args);

export const savePolygonCoordinates = createAction(
  'Polygon changed',
  /** @param {{polgyon: string}} btoa */
  ({ polygon }) => ({ polygon })
);

export const recenterAfterFavs = createAction('Favs removed from search');
export const recenterAfterLatLonRad = createAction('LatLonRad removed from search');
export const recenterAfterNearby = createAction('Nearby removed from search');
export const recenterAfterRID = createAction('Rid removed from search');
export const mapIdle = createAction('Map went idle');
export const mapDragWhileNearby = createAction('Map Drag while nearby');

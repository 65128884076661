import { createReducer } from 'redux-act';
import { LOCATION_CHANGED } from 'redux-little-router';
import { DETAILS } from 'routes';
import * as MobileDetailsActions from 'actions/MobileDetailsActions';
import * as MobileDetailsContactAgentActions from 'actions/MobileDetailsContactAgentActions';
import { initializeState } from 'actions/bootstrapActions';

const initialState = {
  /**
   * The listing object used to render the details page. When searching, we
   * receive a sub-type of the full ListingDetail, so we use this object to
   * "soft render" the page until the ListingDetail is fetched.
   *
   * @type {ListingDetail|ListingSnapshot}
   */
  listing: null,

  isMapModalOpen: false,
  isPaymentSectionOpen: false,
  scrollY: null,
  isContactAgentSectionOpen: true,
  hideSliderUI: false,
  continuedSearchResults: [],
  relatedListings: [],
};

const handlers = {
  [initializeState]: (state, { listing }) => (listing ? { ...state, listing } : state),
  [MobileDetailsActions.toggleEstimatedMortgageSection]: state => ({
    ...state,
    isPaymentSectionOpen: !state.isPaymentSectionOpen,
  }),
  [MobileDetailsActions.clickEstimatedMortgageText]: state => ({
    ...state,
    isPaymentSectionOpen: true,
  }),
  [MobileDetailsActions.receiveListingFromAPI]: (state, listing) => ({
    ...state,
    listing,
  }),
  [MobileDetailsActions.updateListingForDetailsPage]: (state, listing) => ({
    ...state,
    listing,
  }),

  // Nullify the agent for display on all route changes, except ones that reload
  // this listing, and reset the persisted scroll position.
  [LOCATION_CHANGED]: (state, payload) => {
    const isRouteChangeToSameListing = Boolean(
      payload.result &&
        payload.result.id === DETAILS &&
        payload.params &&
        parseInt(payload.params.listingID, 10) === (state.listing && state.listing._ID)
    );

    if (isRouteChangeToSameListing) {
      return { ...state, scrollY: null };
    }

    return initialState;
  },

  // Contact Agent
  [MobileDetailsActions.toggleContactAgentSection]: state => ({
    ...state,
    isContactAgentSectionOpen: !state.isContactAgentSectionOpen,
  }),
  [MobileDetailsActions.clickContactAgent]: state => ({
    ...state,
    isContactAgentSectionOpen: true,
  }),
  [MobileDetailsActions.clickTourInPerson]: state => ({
    ...state,
    isContactAgentSectionOpen: true,
  }),
  [MobileDetailsActions.clickTourViaVideo]: state => ({
    ...state,
    isContactAgentSectionOpen: true,
  }),
  [MobileDetailsContactAgentActions.closeContactFormSuccessModal]: state => ({
    ...state,
    isContactAgentSectionOpen: false,
  }),
  [MobileDetailsContactAgentActions.autoCloseContactFormSuccessModal]: state => ({
    ...state,
    isContactAgentSectionOpen: false,
  }),

  // Map Modal
  [MobileDetailsActions.clickMapThumbnail]: state => ({
    ...state,
    isMapModalOpen: true,
  }),
  [MobileDetailsActions.clickMapModalCloseButton]: state => ({
    ...state,
    isMapModalOpen: false,
  }),

  // Photo Slider
  [MobileDetailsActions.hideSliderUI]: state => ({
    ...state,
    hideSliderUI: true,
  }),
  [MobileDetailsActions.showSliderUI]: state => ({
    ...state,
    hideSliderUI: false,
  }),
  [MobileDetailsActions.receivedContinuedSearchResults]: (state, payload) => ({
    ...state,
    continuedSearchResults: payload,
  }),
  [MobileDetailsActions.receivedRelatedListings]: (state, payload) => ({
    ...state,
    relatedListings: payload,
  }),
};

export default createReducer(handlers, initialState);

import 'legacy/bootstrap';
import run from 'legacy/run';
import AppView from 'legacy/Core/app';
import ListingCardView from 'legacy/Views/Listings/card';
import 'legacy/off-canvas';
import * as offcanvas from 'legacy/off-canvas-filter';
import 'legacy/ccomp';

run();

// Load Views
window.bt.app = new AppView();
// CNS-3061: Expose card view for seller plugin
window.bt.ListingCardView = ListingCardView;

offcanvas.initialize();

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-overlays';
import cx from 'classnames';

const withModal = ({ maxWidth, width, gutters }) => ModalComponent => {
  class ModalWrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {
        animate: false
      };
    }

    componentDidMount() {
      if (this.props.show) {
        window.requestAnimationFrame(() => {
          this.setState({ animate: true });
        });
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.show !== prevProps.show) {
        window.requestAnimationFrame(() => {
          this.setState({ animate: this.props.show });
        });
      }
    }

    render() {
      const modalClasses = cx('bt-modal', { 'bt-modal--is-visible': this.state.animate });

      return (
        <Modal
          renderBackdrop={(props) => <div {...props} className="bt-modal__backdrop" />}
          onHide={() => {
            this.props.onDismiss(this.props.title, 'none', this.props.category);
          }}
          show={this.props.show}
          className={modalClasses}
        >
          <div
            className="bt-modal__content"
            style={{
              width: width || '90%',
              maxWidth: maxWidth ? `${maxWidth}px` : '450px',
              padding: gutters || '24px',
            }}
          >
            <ModalComponent {...this.props} />
          </div>
        </Modal>
      );
    }
  }

  ModalWrapper.displayName = `withModal(${ModalComponent.displayName ||
    ModalComponent.name ||
    'ModalComponent'})`;

  ModalWrapper.propTypes = {
    category: PropTypes.string,
    onDismiss: PropTypes.func,
    show: PropTypes.bool,
    title: PropTypes.string
  };

  ModalWrapper.defaultProps = {
    category: '',
    onDismiss: () => {},
    show: false,
    title: ''
  };

  return ModalWrapper;
};

export default withModal;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';

class PriceOption extends Component {
  _onSelect = () => {
    this.props.onSelect(this.props.price);
  }

  /**
   * Formats an integer as a USD currency string, e.g. '$1M', '$1.25M', '$250K',
   * etc.
   * @param  {Number|null} n
   * @return {String}  Either the formatted currency, or 'Any Price' if the input is `null`.
   */
  _formatPrice(n) {
    let result;
    const commaPattern = /(\d)(?=(\d{3})+(?!\d))/g;

    if (n === Infinity) {
      return 'Any Price';
    }

    if (n < 1000000) {
      if (n % 1000 === 0) {
        result = `${n / 1000}K`;
      } else {
        result = n.toString().replace(commaPattern, '$1,');
      }
    } else if (n % 1000000 === 0) {
      result = `${n / 1000000}M`;
    } else if (n % 100000 === 0) {
      result = `${(n / 1000000).toFixed(1)}M`;
    } else if (n % 10000 === 0) {
      result = `${(n / 1000000).toFixed(2)}M`;
    } else {
      result = n.toString().replace(commaPattern, '$1,');
    }

    return `$${result}`;
  }

  render() {
    return (
      <li
        className={cx({ active: this.props.active })}
        key={this.props.price}
        onClick={this._onSelect}
      >
        {this._formatPrice(this.props.price)}
      </li>
    );
  }
}

PriceOption.displayName = 'PriceOption';
PriceOption.propTypes = {
  price: PropTypes.number,
  onSelect: PropTypes.func,
  active: PropTypes.bool
};

export default PriceOption;

import { takeEvery, call } from 'redux-saga/effects';
import { go } from 'sagas/routerTasks/search';
import { searchBarSelect } from 'sagas/routerTasks/commonViewSagas';
import { defaultSearch } from 'models/search/constants';
import { receiveError as receiveGeolocationError } from 'services/geolocation/actions';
import { emptySubmitClick, selectSuggestion } from './actions';

export default [
  takeEvery(emptySubmitClick, handleEmptySubmit),
  takeEvery(selectSuggestion, handleSuggestionSelect),
  takeEvery(receiveGeolocationError, dispatchGeolocationError),
];

/**
 * If the submit button is clicked on the homepage ballerbox with no value in the input, then we
 * want to route the user to the results gallery with the default search applied.
 */
function* handleEmptySubmit() {
  yield go({ query: defaultSearch });
}

/**
 * When an item is selected from the Autocomplete suggestions, call our common `searchBarSelect`
 * side effect to effectively handle the correct routing to update the search.
 *
 * @param {{payload: SearchSuggestion}} action
 */
function* handleSuggestionSelect({ payload: suggestion }) {
  yield call(searchBarSelect, suggestion);
}

/**
 * Used by `dispatchGeolocationError` side-effect to handle the phrasing of the `alert` window
 * that appears when we can't retrieve a user's location.
 *
 * @param {number} code - The error code
 * @returns {string}
 */
function formatErrorMessage(code) {
  const alertBase = 'Unfortunately, we are unable to retrieve your current location.\n\n';

  switch (code) {
    case 1:
      return `${alertBase}Please enable geolocation in order to perform location based searches.`;
    case 2:
      return alertBase;
    case 3:
      return `${alertBase}The geolocation service timed out.`;
    default:
      const searchText = 'Please try searching by City, Neighborhood, Address, or Zip.\n\n';
      return `${alertBase + searchText}There was an error detecting your position.`;
  }
}

/**
 * Handles the failed case for fetching a user's location via the homepage ballerbox Search By
 * dropdown UI.
 *
 * @param {{payload: {code:number}}} action
 */
function* dispatchGeolocationError({ payload: { code } }) {
  yield alert(formatErrorMessage(code)); // eslint-disable-line no-alert
}

import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { SecondaryButton } from 'coreComponents';
import Modal from 'components/core/Modal';
import { H2 } from 'components/core/Headings';
import { Grid, Cell } from 'components/core/Grid';

/**
 * Confirmation Modal
 * @description Used to provide confirmation options when interacting with a form
 * or submitting data
 * @todo Update as needed to make more flexible
 *
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {string} props.heading
 * @param {Array} props.buttons
 */
const ConfirmationModal = props => {
  const { isOpen, heading, buttons = [] } = props;
  return (
    <Modal show={isOpen} animate>
      <Grid justifyContent="center" alignItems="center">
        <Cell md={10} className="text-xs--center">
          <H2>{heading}</H2>
          {buttons.map((b, i) => (
            <SecondaryButton
              key={i}
              className={i !== buttons.length - 1 ? 'mb-16' : ''}
              onClick={b.onClick}
              width="full"
              dataCY={b.dataCY}
            >
              {b.text}
            </SecondaryButton>
          ))}
        </Cell>
      </Grid>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  heading: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      text: PropTypes.string,
      dataCY: PropTypes.string,
    })
  )
};

export default pure(ConfirmationModal);

import { Collection } from 'backbone';
import $ from 'jquery';
import _ from 'underscore';
import * as BackboneActions from 'actions/BackboneActions';
import { isConsumerApp, consumerAppVersionAtOrAbove, appVersion330 } from 'consumerApp/utils/versionUtils';

/**
 * @class A collection of favorite listing IDs. Each model in this collection
 * is a vanilla Backbone.Model with only an `id` property. Not extending
 * BaseCollection because there's no need for anything to be notified of events
 * through window.bt.events. Views can subscribe to changes on this collection
 * directly.
*/
export default class Favorites extends Collection {
  url = '/visitor/favorite/?listingid={0}&adding={1}';

  initialize() {
    super.initialize();
    this.on('add', this._addFav);
    this.on('remove', this._removeFav);
  }

  /**
   * Add/remove favorite from the collection depending on whether or not it's
   * present.
   * @param  {number} id
   * @param {function} ifAdded A callback that will be invoked if the ID was added
   * @param {function} ifRemoved A callback that will be invoked if the ID was removed
  */
  toggle(id, ifAdded = () => {}, ifRemoved = () => {}) {
    if (this.get(id)) {
      this.remove(id);
      ifRemoved();
    } else {
      this.add({ id });
      ifAdded();
    }
  }

  /**
   * Loads the consumer app UPDATE_VISITOR_FAVORITES payload.
   * @param array Set of listing ids to be favored.
   * @return void
   */
  consumerAppUpdateFavorites(listingIds) {
    if (isConsumerApp() && consumerAppVersionAtOrAbove(appVersion330)) {
      const set = [];
      for (const listingId of listingIds) {
        set.push({ id: listingId });
      }

      window.bt.visitorDetails.Favorites = listingIds;
      window.bt.visitorDetails.FavoriteCount = listingIds.length;

      this.reset(set);
      this._updateVisitorFavs();
    }
  }

  /**
   * Fetch the list of favorite ids for the visitor, then reset
   */
  resetFavorites() {
    return window.bt.api.getFavoriteIDs().then(this._resetFavs);
  }

  /**
   * Send a request to favorite the listing with ID `model.id`, as well as
   * update any computed or duplicated state.
   * @private
   * @param {Backbone.Model} model
  */
  _addFav(model) {
    if (!isConsumerApp() || !consumerAppVersionAtOrAbove(appVersion330)) {
      $.get(this.url.format(model.id, 'true'));
    }

    if (window.bt.visitorDetails.Favorites != null) {
      window.bt.visitorDetails.Favorites.push(model.id);
    } else {
      window.bt.visitorDetails.Favorites = [model.id];
    }

    this._updateVisitorFavs();

    /* eslint-disable global-require */
    const { dispatch } = require('store').default;

    if (isConsumerApp() && consumerAppVersionAtOrAbove(appVersion330)) {
      dispatch(BackboneActions.tapFavorite(model.id));
    } else {
      dispatch(BackboneActions.addFavorite(model.id));
    }
  }

  /**
   * Send a request to unfavorite the listing with ID `model.id`, as well as
   * update any computed or duplicated state.
   * @param {Backbone.Model} model
  */
  _removeFav(model) {
    if (!isConsumerApp() || !consumerAppVersionAtOrAbove(appVersion330)) {
      $.get(this.url.format(model.id, 'false'));
    }

    window.bt.visitorDetails.Favorites = _.without(window.bt.visitorDetails.Favorites, model.id);
    this._updateVisitorFavs();

    /* eslint-disable global-require */
    const { dispatch } = require('store').default;

    if (isConsumerApp() && consumerAppVersionAtOrAbove(appVersion330)) {
      dispatch(BackboneActions.tapFavorite(model.id));
    } else {
      dispatch(BackboneActions.addFavorite(model.id));
    }
  }

  /**
   * Callback function used to reset the Favorites Collection's models
   *
   * @param {Array} results - An array of Favorite Listing IDs
   * @memberof Favorites
   */
  _resetFavs = (results) => {
    window.bt.visitorDetails.Favorites = results;

    this.reset(results.map(id => ({ id })));
    this._updateVisitorFavs();
  }

  /**
   * Used to update the Favorites attribute on the Visitor Backbone model and the `FavoriteCount`
   * property on the global `visitorDetails` object.
   *
   * @memberof Favorites
   */
  _updateVisitorFavs() {
    window.bt.visitor.updateFavorites();
    window.bt.visitorDetails.FavoriteCount = window.bt.visitor.attributes.FavoriteCount;
  }
}

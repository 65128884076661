import React from 'react';
import PropTypes from 'prop-types';
import CheckboxRadioControl from 'components/Common/FormElements/CheckboxRadioControl';

const Radio = ({ onClick, onChange, ...rest }) => {
  if (onClick && !onChange) {
    onChange = () => {};
  }

  return <CheckboxRadioControl onClick={onClick} onChange={onChange} {...rest} type="radio" />;
};

Radio.propTypes = {
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default Radio;

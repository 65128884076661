import { createReducer } from 'redux-act';
import * as OptimizeActions from 'actions/OptimizeActions';
import { config } from 'BoomTown';

/**
 * CNS-6166: iPads running iOS 9/10 have incompatibilites with the new map experience.
 * They should be shown the legacy desktop map experience by default. Otherwise use
 * the bt config.
 *
 * @return {Bool} `true` desktopMapEnabled
 */
function shouldEnableDesktopMap() {
  const { deviceInfo: { platformName, platformVersion } } = config;

  if (platformName === 'iOS' && parseInt(platformVersion, 10) < 11) {
    return false;
  }

  return true;
}

/**
 * This part of the state tree is for feature flags that are set from Optimize.
 * View state for features toggled via these should be maintained elsewhere.
 * Important - keep it set to false by default and let Optimize trigger it
 */
const initialState = {
  // used for the internal timer
  testStateFinalized: false,
  qualifyingQuestionsEnabled: config.showQualifyingQuestions,
  // CNS-5387: Do you have a home to sell vs. Do you own a home?
  homeToSellQuestionVariant: false,
  desktopMapEnabled: shouldEnableDesktopMap(),

  /** @type {'control'| 'red' | 'white' | 'blue' } */
  googleLoginStyle: 'control',

  /** @type {number}
   * 0 - Default Frequency (Instantly)
   * 1 - Frequency (Daily)
   */
  eAlertFrequency: 0,
  mobileAppPrompt: 0
};

export default createReducer(
  {
    // This is the reducer for initializing test state from Optimize
    [OptimizeActions.bootOptimize]: (state, variants) => ({
      ...state,
      ...variants,
      testStateFinalized: true,
    }),
    [OptimizeActions.timeout]: state => ({
      ...state,
      testStateFinalized: true,
    }),
  },
  initialState
);

/* eslint-disable consistent-return */
import $ from 'jquery';
import _ from 'underscore';
import { utility } from 'BoomTown';

/*
 * Use this class for all communication with the API
 *
 * On auth failures this class will re-authorize, then reattempt the api call.
 * This file will overwrite the config.token value in these circumstances.
 *
 * On each request we have some defaults that you shouldn't have to pass in
 * - action: "ajax_submit"
 * - access_token: window.bt.config.token
 * - VisitorID: window.bt.visitorDetails._ID | window.bt.visitor.id
 * - VisitID: window.bt.visitDetails._ID | window.bt.visitor.get('VisitID')
 */
export default class API {
  /*
   * @param {string} tenant    bt.tenant.id
   * @param {string} visitor   bt.visitorDetails._ID or bt.visitor.id
   * @param {string} visitID   bt.visitDetails._ID or bt.visitor.get('VisitID')
   * @param {string} host      The api url
   * @param {string} token     The auth token
   */
  constructor({ tenantID, visitorID, visitID, host, token }) {
    this.tenantID = tenantID;
    this.visitorID = visitorID;
    this.visitID = visitID;
    this.host = host;
    this.token = token;
  }

  path = {
    agent: '/lc/1/agents/{0}',
    agents: '/lc/1/agents',
    nextbuyeragent: '/lc/1/agents/nextbuyer',
    favorites: '/lc/1/leads/{0}/favorites',
    favoriteIDs: '/lc/1/leads/{0}/favoriteids',
    communicationPrefs: '/lc/1/leads/{0}/communicationPrefs',
    emailPrefs: '/lc/1/leads/{0}/emailprefs',
    qquestions: '/lc/1/qualifyingquestions',
    qqanswers: '/lc/1/qualifyingquestions/contactanswer',
    guide: '/lc/1/guides/{0}',
    guides: '/lc/1/guides',
    lender: '/lc/1/lenders/{0}',
    lenders: '/lc/1/lenders',
    listing: '/lc/1/listings/{0}',
    locations: '/lc/1/listings/locations',
    mapsearch: '/lc/1/listings/mapsearch',
    neighborhood: '/lc/1/listings/neighborhood/{0}',
    neighborhoods: '/lc/1/listings/neighborhoods',
    qsguide: '/lc/1/listings/GetQueryStringForGuide?guideName={0}&tenantID={1}',
    search: '/lc/1/listings/search',
    searchcount: '/lc/1/listings/searchcount',
    similarlistings: '/lc/1/listings/similar/?ListingID={0}',
    savedsearches: '/lc/1/leads/{0}/searches',
  };

  /*
   * Get an error handler to patch 500 responses from the server, so that a
   * default response is passed to the success handler.
  */
  static getPatchedErrorHandler = (context, success, error, newResult) => xhr => {
    const { status, responseJSON } = xhr;
    if (status === 500) {
      if ($.isFunction(success)) {
        const patchedResponse = Object.assign({}, responseJSON, { Result: newResult });

        return success.call(context, patchedResponse, status, xhr);
      }
    } else if ($.isFunction(error)) {
      return error.apply(context);
    }
  };

  /**
   * Sometimes the api doesn't stick to the contract, instead of adding logic
   * to the view to handle these scenarios we patch the the api response before
   * handing off to any callbacks
   *
   * @param {Object} l Listing
   */
  static listingPatches(l) {
    /* eslint-disable no-param-reassign */
    // CNS-3479: sometimes the api leaves off HalfBaths
    l.HalfBaths = l.HalfBaths || 0;

    // CNS-5570: missing PostalCode crashes entire single details page
    if (l.Location && !l.Location.PostalCode) {
      l.Location.PostalCode = '';
    }

    // For some reason there's an extra space at the end of the PropertyType ID.
    if (l.PropertyType && typeof l.PropertyType._ID === 'string') {
      l.PropertyType._ID = l.PropertyType._ID.trim();
    }

    return l;
    /* eslint-enable no-param-reassign */
  }

  /**
   * Sometimes the api returns listings without coordinates. We want to ensure
   * that these listings are omitted from map searches.
   *
   * @param {Object} l ListingSnapshot
   */
  static listingHasCoordinates(l) {
    return (
      l.Location &&
      l.Location.Coordinates &&
      l.Location.Coordinates.Latitude &&
      l.Location.Coordinates.Longitude
    );
  }

  /**
   * Check the search params for map coordinates
   *
   * @param {SearchParams} params
   * @returns {boolean}
   */
  static requestHasMapCoordinates(params) {
    const coords = ['nelat', 'nelng', 'swlat', 'swlng'];
    return coords.every((coord) => params[coord]);
  }

  /**
   * We omit the VisitID and VisitorID from requests that
   * are made by bots.
   */
  getDefaults() {
    const isBot = this.visitID === -1;

    return {
      action: 'ajax_submit',
      access_token: this.token,
      ...isBot ? {} : { VisitorID: this.visitorID, VisitID: this.visitID },
    };
  }

  // CNS-1384 - Should we log this search?
  logSearch(searchParams) {
    // If the search doesn't want to log itself
    if (_.has(searchParams, 'LogSearch') && !searchParams.LogSearch) {
      return _.omit(searchParams, 'LogSearch');
    }

    // If we are paging
    if (_.has(searchParams, 'pageindex') && parseInt(searchParams.pageindex, 10) !== 0) {
      return _.omit(searchParams, 'LogSearch');
    }

    // default log search true
    return { ...searchParams, LogSearch: true };
  }

  // CNS-493 - refactor to remove code duplication and replace ~ to ,
  // CNS-3329: remove special char # from keyword searches
  prepareQueryString(pars = {}) {
    utility.replaceTildes(pars);

    if (pars.keyword) {
      pars.keyword = pars.keyword.replace(/#/g, ''); // eslint-disable-line no-param-reassign
    }

    return $.param(_.extend(this.getDefaults(), this.logSearch(pars)));
  }

  refreshOauth(endpoint, args, context, success, error, retries) {
    return $.get('/wp-content/mu-plugins/flagship/core/proxy.php', data => {
      if (data.oauth != null) {
        // REFACTOR: this is the one place in this file it is ok to set on another model
        // until we can move all api requests to this file
        window.bt.config.token = data.oauth;
        this.token = data.oauth;
        return this.request(endpoint, args, context, success, error, retries - 1);
      }
    });
  }

  /**
   * Get the user's qualifying questions.
   *
   * @returns {Promise<FlagshipAPI.GetQualifyingQuestionsResponse>} */
  getQualifyingQuestions() {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'GET',
        data: this.getDefaults(),
        url: `${this.host}${this.path.qquestions}`,
        success: ({ Result }) => { resolve(Result); },
        error: reject
      });
    });
  }

  /**
   * Fetch the IDs of the visitor's Favorited listings.
   * @return {Promise}
   */
  getFavoriteIDs() {
    return new Promise((resolve, reject) => this.request(
      this.path.favoriteIDs.format(this.visitorID),
      undefined,
      this,
      response => { resolve(response.Result); },
      reject
    ));
  }

  /**
   * Save qualifying question responses.
   *
   * @returns {JQueryXHR}
   */
  sendQQAnswers(answerId) {
    const qs = this.prepareQueryString();
    const endpoint = this.path.qqanswers;
    const url = `${this.host}${endpoint}?${qs}`;
    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'POST',
        data: {
          VisitId: this.visitID,
          AnswerId: answerId,
        },
        success: x => { resolve(x.Result); },
        error: reject
      });
    });
  }

  request(endpoint, args, context, success, error, retries = 3, complete = $.noop) {
    const qs = this.prepareQueryString(args);
    const url = `${this.host}${endpoint}?${qs}`;

    return $.ajax({
      url,
      dataType: 'json',
      success: (data, status, xhr) => {
        if (data.Status.Code === 401 && retries > 0) {
          return this.refreshOauth(endpoint, args, context, success, error, retries);
        } else if (data.Status.Code === 500) {
          if ($.isFunction(error)) {
            return error.call(context, xhr, status, data.Status.ErrorMessage);
          }
        } else if (data.Status.Code === 200) {
          if ($.isFunction(success)) {
            return success.call(context, data, status, xhr);
          }
        } else if ($.isFunction(error)) {
          return error.call(context, xhr, status, data.Status.ErrorMessage);
        }
      },

      error: (xhr, status, errorThrown) => {
        // if xhr.responseJSON.Status.Code is 401
        if (xhr.status === 401 && retries > 0) {
          return this.refreshOauth(endpoint, args, context, success, error, retries);
        } else if ($.isFunction(error)) {
          return error.call(context, xhr, status, errorThrown);
        }
      },

      complete() {
        return complete.call(context);
      },
    });
  }

  getEmailPreferences() {
    // Tried just returning the jqXHR from `this.request()` and their Promise
    // API was clashing with redux-saga.
    return new Promise((resolve, reject) => {
      this.request(
        this.path.emailPrefs.format(this.visitorID),
        undefined,
        this,
        response => {
          resolve(response.Result);
        },
        reject
      );
    });
  }

  getCommunicationPreferences() {
    // Tried just returning the jqXHR from `this.request()` and their Promise
    // API was clashing with redux-saga.
    return new Promise((resolve, reject) => {
      this.request(
        this.path.communicationPrefs.format(this.visitorID),
        undefined,
        this,
        response => {
          resolve(response.Result);
        },
        reject
      );
    });
  }

  getAllAgents(context, success, error = null) {
    const endpoint = this.path.agents;
    return this.request(endpoint, { LogSearch: false }, context, success, error);
  }

  getAgentById(context, agentId, success, error = null) {
    const endpoint = this.path.agent.format(agentId);
    return this.request(endpoint, { LogSearch: false }, context, success, error);
  }

  /**
   * Get either the BoomTown user that is the listing agent for the given listing, or
   * the next agent for display in the rotation.
   *
   * @param {string} listingID
   * @returns {Promise<Agent>}
   */
  getNextBuyerAgent = listingID =>
    new Promise((resolve, reject) => {
      this.request(
        this.path.nextbuyeragent,
        listingID ? { listingID } : {},
        null,
        responseObj => {
          if (responseObj.Status.Code === 200) {
            resolve(responseObj.Result);
          } else {
            reject(responseObj);
          }
        },
        reject
      );
    });

  getListing(context, listingId, success, error = null) {
    const endpoint = this.path.listing.format(listingId);

    // Prep our success patching callback
    const successPatching = (data, status, xhr) => {
      API.listingPatches(data.Result);

      if ($.isFunction(success)) {
        success.call(context, data, status, xhr);
      }
    };

    return this.request(endpoint, {}, context, successPatching, error);
  }

  getListingPromise(listingId) {
    return new Promise((resolve, reject) => {
      this.getListing(this, listingId, resolve, reject);
    });
  }

  ajaxsearch(context, args, success, error = null) {
    let endpoint = this.path.search;
    if (_.has(args, 'favs')) {
      endpoint = this.path.favorites.format(this.visitorID);
    }

    const tryHandleError = API.getPatchedErrorHandler(context, success, error, {
      Items: [],
      MetaInfo: {},
      PageCount: 0,
      PageIndex: 0,
      TotalItems: 0,
    });

    const successPatching = (data, status, xhr) => {
      data.Result.Items.forEach(API.listingPatches);

      // results should only be run through the listingHasCoordinates filter if there are coordinates in the args
      if (API.requestHasMapCoordinates(args)) {
        data.Result.Items.filter(API.listingHasCoordinates);
      }

      if ($.isFunction(success)) {
        success.call(context, data, status, xhr);
      }
    };

    return this.request(endpoint, args, context, successPatching, tryHandleError);
  }

  ajaxSearchPromise(args) {
    return new Promise((resolve, reject) => {
      this.ajaxsearch(this, args, resolve, reject);
    });
  }

  mapsearch(context, args, success, error = null) {
    const endpoint = this.path.mapsearch;
    const tryHandleError = API.getPatchedErrorHandler(context, success, error, {
      Items: [],
      MetaInfo: {},
      PageCount: 0,
      PageIndex: 0,
      TotalItems: 0,
    });

    return this.request(endpoint, args, context, success, tryHandleError);
  }

  mapsearchPromise(args) {
    return new Promise((resolve, reject) => {
      this.mapsearch(this, args, resolve, reject);
    });
  }

  searchcount(context, args, success, error = null) {
    const endpoint = this.path.searchcount;
    const tryHandleError = API.getPatchedErrorHandler(context, success, error, 0);

    return this.request(endpoint, { ...args, LogSearch: false }, context, success, tryHandleError);
  }

  searchcountPromise(args) {
    return new Promise((resolve, reject) => {
      this.searchcount(this, args, resolve, reject);
    });
  }

  getSuggestions({ query = '', type = 'all' }) {
    return new Promise((resolve, reject) => {
      // JSONp because the suggest api doesn't have cors headers
      $.getJSON(
        `${window.bt.config.suggestApiUrl}/1/SuggestListings?callback=?`,
        {
          tenantID: this.tenantID,
          q: query,
          maxResults: 15,
          type,
          visitorid: this.visitorID,
        },
        data => {
          if (data.Status.Code === 200) {
            return resolve(data);
          }
          return reject(data);
        }
      );
    });
  }

  getSavedSearches() {
    return this.request(
      this.path.savedsearches.format(this.visitorID),
      { LogSearch: false },
      null
    ).then(res => res.Result);
  }
}

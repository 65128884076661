import { config } from 'BoomTown';
import findKey from 'utility/findKey';
import { saleTypeReducer } from 'reducers/saleTypes';
import { coordinateFilters, locationParams } from '../constants';

export default class SearchConverter {
  /**
   * Since we want the state of the `pending` search in Redux to always have the same
   * shape, whether the keys are present in the QS/Url/Search models or not,
   * these are the default values.
   *
   * @static
   * @type {PendingSearch}
   */
  static defaultPendingSearch = {
    sort: null,

    minprice: null,
    maxprice: null,
    minbeds: 0,

    minbaths: 0,
    minhalfbaths: 0,
    minfullbaths: 0,

    maxbaths: null,
    maxhalfbaths: null,
    maxfullbaths: null,

    minacres: null,
    maxacres: null,
    minsqft: null,
    maxsqft: null,
    minstories: null,
    maxstories: null,
    minyear: null,
    maxyear: null,
    mingarages: null,
    maxgarages: null,
    maxdayslisted: null,
    pricereduced: null,
    proptypes: [],
    features: [],
    featureor: [],
    featurenot: [],
    status: [],
    photo: false,
    tours: false,

    // Only accessible via the Ballerbox
    schooldistrict: [],
    county: [],
    hood: [],
    area: [],
    city: [],
    custom: [],
    postalcode: [],
    school: [],
    keyword: '',

    nearby: null,
    mapbounds: null,
  };

  /**
   * A map from query string/search/URL model properties to pendingSearch keys.
   *
   * @static
   * @type {Object}
   */
  static propertyRenames = {
    proptype: 'proptypes',
    feature: 'features',
    latlonrad: 'nearby',
  };

  /**
   * @type {Object.<string, Function>}
   */
  static mappingFunctions = {
    /**
     * A no-op mapping that only returns an object with `success: false`.
     */
    toNothing: () => ({ success: false }),

    /**
     * Map an integer search term from a string to a number.
     * @param {string} value
     */
    toNumber: (value) => {
      const parsed = parseInt(value, 10);
      return {
        success: !Number.isNaN(parsed),
        value: parsed
      };
    },

    /**
     * Map a float search term from a string to a number.
     * @param {string} value
     */
    toFloat: (value) => {
      const parsed = parseFloat(value);
      return {
        success: !Number.isNaN(parsed),
        value: parsed
      };
    },

    /**
     * Map a search term represented as a comma-separated string to an array of strings.
     * @param {string} value
     */
    toArray: (value) => {
      if (value) {
        return {
          success: true,
          value: value.split(',').filter(s => s !== '')
        };
      }
      return { success: false };
    },

    /**
     * The default mapping from the search model, only calls `toString()` on the value.
     * @param {any} value
     */
    toString: (value) => ({
      success: true,
      value: value.toString()
    }),

    /**
     * Converts a value to a Boolean.
     * @param {any} value
     */
    toBoolean: (value) => ({
      success: true,
      value: Boolean(value)
    }),
  };

  /**
   * A hash of mappings from search model key to offCanvas state.
   * @type {Object.<string, Function<value>>}
   */
  static keyToFuncMappings = {
    // Arrays
    area: SearchConverter.mappingFunctions.toArray,
    city: SearchConverter.mappingFunctions.toArray,
    county: SearchConverter.mappingFunctions.toArray,
    custom: SearchConverter.mappingFunctions.toArray,
    featureor: SearchConverter.mappingFunctions.toArray,
    featurenot: SearchConverter.mappingFunctions.toArray,
    feature: SearchConverter.mappingFunctions.toArray,
    hood: SearchConverter.mappingFunctions.toArray,
    school: SearchConverter.mappingFunctions.toArray,
    schooldistrict: SearchConverter.mappingFunctions.toArray,
    postalcode: SearchConverter.mappingFunctions.toArray,
    proptype: SearchConverter.mappingFunctions.toArray,
    status: SearchConverter.mappingFunctions.toArray,

    // Numbers
    minprice: SearchConverter.mappingFunctions.toNumber,
    maxprice: SearchConverter.mappingFunctions.toNumber,
    minbeds: SearchConverter.mappingFunctions.toNumber,
    minhalfbaths: SearchConverter.mappingFunctions.toNumber,
    minfullbaths: SearchConverter.mappingFunctions.toNumber,
    maxhalfbaths: SearchConverter.mappingFunctions.toNumber,
    maxfullbaths: SearchConverter.mappingFunctions.toNumber,
    minsqft: SearchConverter.mappingFunctions.toNumber,
    maxsqft: SearchConverter.mappingFunctions.toNumber,
    minstories: SearchConverter.mappingFunctions.toNumber,
    maxstories: SearchConverter.mappingFunctions.toNumber,
    minyear: SearchConverter.mappingFunctions.toNumber,
    maxyear: SearchConverter.mappingFunctions.toNumber,
    mingarages: SearchConverter.mappingFunctions.toNumber,
    maxgarages: SearchConverter.mappingFunctions.toNumber,
    maxdayslisted: SearchConverter.mappingFunctions.toNumber,
    zoom: SearchConverter.mappingFunctions.toNumber,

    // Floats
    minbaths: SearchConverter.mappingFunctions.toFloat,
    maxbaths: SearchConverter.mappingFunctions.toFloat,
    minacres: SearchConverter.mappingFunctions.toFloat,
    maxacres: SearchConverter.mappingFunctions.toFloat,
    swlat: SearchConverter.mappingFunctions.toFloat,
    swlng: SearchConverter.mappingFunctions.toFloat,
    nelat: SearchConverter.mappingFunctions.toFloat,
    nelng: SearchConverter.mappingFunctions.toFloat,
    midlat: SearchConverter.mappingFunctions.toFloat,
    midlng: SearchConverter.mappingFunctions.toFloat,

    // Booleans
    photo: SearchConverter.mappingFunctions.toBoolean,
    tours: SearchConverter.mappingFunctions.toBoolean,

    // String
    sort: SearchConverter.mappingFunctions.toString,
    keyword: SearchConverter.mappingFunctions.toString,

    // Nothing
    search: SearchConverter.mappingFunctions.toNothing,

    // Custom
    latlonrad: value => {
      if (!value) {
        return { success: false };
      }

      const [lat, long, rad] = value.split('|').map((x) => {
        const f = SearchConverter.mappingFunctions.toFloat(null, x);
        return f.success ? f.value : null;
      });

      // Disregard this term when coordinates can't be parsed
      if (lat === null || long === null) {
        return { success: false };
      }

      return {
        success: true,
        value: {
          latitude: lat,
          longitude: long,
          radiusInMiles: rad || 3,
        },
      };
    },
  };


  /**
   * Methods
   */

  /**
   * @memberof SearchConverter
   * @public
   * @static
   *
   * @param {string} key A search key
   * @returns {Function}
   */
  static getMappingFunction(key) {
    if (!config.useListMapResultsSync && coordinateFilters.includes(key)) {
      return SearchConverter.mappingFunctions.toNothing;
    }

    // We do allow dynamic properties, so if a search term should not be mapped,
    // it needs to be specified in `mappingFunctions` with `mapSearch.toNothing`
    return SearchConverter.keyToFuncMappings[key] || SearchConverter.mappingFunctions.toString;
  }

  /**
   * @memberof SearchConverter
   * @public
   * @static
   *
   * @param {*} key
   * @returns {boolean | Array | number | null}
   */
  static getDefault(key) {
    return SearchConverter.defaultPendingSearch[key];
  }

  /**
   * Compare a given key and value to the default
   * @memberof SearchConverter
   * @public
   * @static
   * @todo Should make private/static when Babel v8 lands and we update to such. Follow the thread
   * [here](https://github.com/babel/babel/issues/10752) and [here](https://github.com/babel/babel-eslint/issues/728)
   *
   * @param  {string}  key
   * @param  {any}  value
   * @returns {boolean}
   */
  static isDefaultValue(key, value) {
    const {
      defaultPendingSearch,
      propertyRenames,
      keyToFuncMappings,
      mappingFunctions,
    } = SearchConverter;

    // We use `null` to signify an unset dynamic property
    if (!Object.keys(defaultPendingSearch).includes(key)) {
      return value === null;
    }

    const urlKey = findKey(propertyRenames, x => x === key) || key;
    if (keyToFuncMappings[urlKey] === mappingFunctions.toArray) {
      // Terms represented as arrays are really sets. We have a default value
      // if their cardinalities are equal and the difference of the two sets is ø.
      const defaultSet = new Set([...defaultPendingSearch[key]]);
      const givenSet = new Set([...value]);

      return (
        defaultSet.size === givenSet.size &&
        [...defaultSet].filter(x => !givenSet.has(x)).length === 0
      );
    }

    return defaultPendingSearch[key] === value;
  }

  /**
   * Map a single key value pair from the committed search schema to the pending search schema.
   * @memberof SearchConverter
   * @private
   * @static
   * @todo Should make private/static when Babel v8 lands and we update to such. Follow the thread
   * [here](https://github.com/babel/babel/issues/10752) and [here](https://github.com/babel/babel-eslint/issues/728)
   *
   * @param  {string} key
   * @param  {any}    value
   * @param  {Object} acc    The search object being built with the `pending` search schema.
   */
  static mapSearchProperty(key, value, acc) {
    const { keyToFuncMappings, mappingFunctions, propertyRenames } = SearchConverter;
    const map = keyToFuncMappings[key] || mappingFunctions.toString;
    const { success, value: mappedValue } = map(value);

    if (success) {
      if (coordinateFilters.includes(key)) {
        acc.mapbounds = acc.mapbounds || {};
        acc.mapbounds[key] = mappedValue;
      } else {
        acc[propertyRenames[key] || key] = mappedValue;
      }
    }
  }

  /**
   * Reducer fn for handling updates to arbitrary search terms with the pending search schema.
   * @memberof SearchConverter
   * @public
   * @static
   *
   * @param {PendingSearch} search
   * @param {{key: any}} term
   *
   * @returns {PendingSearch} The updated state object
   */
  static updatePendingSearchTerm(search, term) {
    const { mappingFunctions, getMappingFunction } = SearchConverter;
    // TODO: Consider changing the shape of this term at the UI level, so that we'd have something
    // static to work with, e.g. `{ key: 'area', value: '123' }` instead of `{ area: '123' }`
    const keys = Object.keys(term);

    if (!keys.length) {
      return search;
    }

    // Only accept a single key/value pair.
    const key = keys[0];
    const value = term[key];

    // Don't update search if we select an address
    if (key === 'listingid') {
      return search;
    }

    let statePair;
    let res;

    // This switch block, as in many other places we want to have different
    // behavior based on the "type" of the mapping, is just *screaming* to be a
    // class of some sort. Whether we're using `new` or `getSearchTerm(key: string)`,
    // it'd be nice to put all of this in one place.
    const fn = getMappingFunction(key);
    switch (fn) {
      case mappingFunctions.toArray:
        // No-op if the id is already specified.
        statePair = search[key] && search[key].includes(value)
          ? {}
          : { [key]: [...(search[key] || []), value] };
        break;
      case mappingFunctions.toNumber:
        res = mappingFunctions.toNumber(value);
        statePair = {
          [key]: res.success ? res.value : null,
        };
        break;
      case mappingFunctions.toFloat:
        res = mappingFunctions.toFloat(value);
        statePair = res.success ? { [key]: res.value } : {};
        break;
      case mappingFunctions.toNothing:
        statePair = {};
        break;
      case mappingFunctions.toString:
      default:
        statePair = { [key]: value };
        break;
    }

    return {
      ...search,
      ...statePair,

      // If the new searchTerm is a location search, remove mapbounds from our search
      ...(locationParams.includes(key) ? { mapbounds: null } : {}),

      // If rid exists in the pending search, remove it
      ...(search.rid) ? { rid: null } : search.rid
    };
  }

  /**
   * Remove a search value from a object using the `pending` search schema
   * @memberof SearchConverter
   * @public
   * @static
   *
   * @param {Object} search A pending search obj
   * @param {Object} payload
   * @param {string} payload.prop The search property name to remove
   * @param {any} payload.value The search property value
   *
   * @returns {PendingSearch}
   */
  static removePendingSearchValue(search, { prop, value }) {
    const {
      defaultPendingSearch,
      propertyRenames,
      keyToFuncMappings,
      mappingFunctions
    } = SearchConverter;
    const searchKey = propertyRenames[prop] || prop;

    if (keyToFuncMappings[prop] === mappingFunctions.toArray) {
      const i = search[searchKey].indexOf(value);

      if (i === -1) {
        return search;
      }

      return ({
        ...search,
        [searchKey]: [
          ...search[searchKey].slice(0, i),
          ...search[searchKey].slice(i + 1, search[searchKey].length),
        ],
      });
    } else if (
      ['featureor', 'featurenot'].includes(prop) &&
      ['FS', 'BO', 'SS', 'NSS', 'NBO'].includes(value)
    ) {
      // `getNextSaleTypeState()` takes a "key" that represents what sale type
      // is being toggled. For some crazy reason, our `featurenot` query string
      // params are different, and begin with 'N' (presumably for "not").
      const saleTypeKey = value.includes('N') ? value.slice(1, value.length) : value;

      return saleTypeReducer(search, saleTypeKey);
    }

    return ({
      ...search,
      [searchKey]: Object.hasOwnProperty.call(defaultPendingSearch, searchKey)
        ? defaultPendingSearch[searchKey]
        : null,
    });
  }

  /**
   * Returns a pending search obj that is equivalent to the committed search obj passed as a param
   * @memberof SearchConverter
   * @public
   * @static
   *
   * @param {Object}  committed The search obj in the `committed` search schema
   * @param {Object}  [options]
   * @param {boolean} [options.includeDefaults=true]
   *
   * @return {PendingSearch}
   */
  static mapCommittedToPending(committed = {}, { includeDefaults = true } = {}) {
    const defaults = includeDefaults
      ? JSON.parse(JSON.stringify(SearchConverter.defaultPendingSearch))
      : {};

    const pending = Object.keys(committed).reduce((acc, key) => {
      SearchConverter.mapSearchProperty(key, committed[key], acc);
      return acc;
    }, defaults);

    return pending;
  }

  /**
   * The inverse of `mapCommittedToPending`. Takes an obj with `pending` search schema and returns
   * the equivalent `committed` search schema.
   * @memberof SearchConverter
   * @public
   * @static
   *
   * @param {Object}  pendingSearch
   * @param {Object}  [options]
   * @param {boolean} [options.removeDefaults=false] Removes params that have default values
   * @param {boolean} [options.removeSort=false] Removes the `sort` search param
   *
   * @return {Object} Object in the `committed` search schema
   */
  static mapPendingToCommitted(pendingSearch, { removeDefaults = false, removeSort = false } = {}) {
    const {
      isDefaultValue,
      keyToFuncMappings,
      mappingFunctions,
      propertyRenames
    } = SearchConverter;

    /**
     * Map the `nearby` obj from the pending search schema to the single `latlonrad` value
     * in the committed search schema.
     *
     * @param {PendingSearch} pending
     * @param {Object} committed The accumulated committed search obj
     *
     * @returns {Object} The accumulated committed search obj
     */
    const handleNearby = (pending, committed) => {
      if (pending.nearby === null) {
        return committed;
      }

      // eslint-disable-next-line no-param-reassign
      committed.latlonrad = [
        pendingSearch.nearby.latitude,
        pendingSearch.nearby.longitude,
        pendingSearch.nearby.radiusInMiles,
      ].join('|');
      return committed;
    };

    /**
     * Map the `mapbounds` obj from the pending search schema to the individual coordinate values
     * in the committed search schema.
     *
     * @param {PendingSearch} pending
     * @param {Object} committed The accumulated committed search obj
     *
     * @returns {Object} The accumulated committed search obj
     */
    const handleMapBounds = (pending, committed) => {
      if (pendingSearch.mapbounds === null) {
        return committed;
      }

      // For each of our lat/lng keys in `mapbounds`, map them and their values to the search obj
      // schema that is compatible with API requests.
      Object.keys(pending.mapbounds).forEach(coord => {
        committed[coord] = pending.mapbounds[coord];
      });

      return committed;
    };

    return Object.keys(pendingSearch)
      .filter(key => (removeDefaults ? !isDefaultValue(key, pendingSearch[key]) : true))
      .filter(key => (removeSort ? key !== 'sort' : true))
      .reduce((committed, key) => {
        if (key === 'nearby') {
          return handleNearby(pendingSearch, committed);
        }

        // We store the various lat/lng params in one `mapbounds` obj for easier parsing.
        if (key === 'mapbounds') {
          return handleMapBounds(pendingSearch, committed);
        }

        const urlKey = findKey(propertyRenames, x => x === key) || key;
        switch (keyToFuncMappings[urlKey]) {
          case mappingFunctions.toArray:
            if (pendingSearch[key].length) {
              // Use `Set` to ensure uniqueness.
              const set = new Set(pendingSearch[key].filter(x => x !== ''));
              committed[urlKey] = [...set].join(','); // eslint-disable-line no-param-reassign
            }
            break;
          case mappingFunctions.toBoolean:
            committed[urlKey] = pendingSearch[key] ? '1' : '0'; // eslint-disable-line no-param-reassign
            break;
          case mappingFunctions.toNumber:
          case mappingFunctions.toFloat:
            committed[urlKey] = pendingSearch[key].toString && pendingSearch[key].toString();
            break;
          default:
            committed[urlKey] = pendingSearch[key]; // eslint-disable-line no-param-reassign
        }
        return committed;
      }, {});
  }
}

/* eslint-disable no-new */
import 'assets/scss/base/base.scss';
import React from 'react';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { push, replace } from 'redux-little-router';
import { config, utility } from 'BoomTown';
import visitorActions from 'utility/visitorActions';

import { isNonMobile as isNonMobileMQ, deviceXLargeMQ } from 'constants/mediaQueries';
import SagaContext from 'hoc/SagaContext';
import rootSaga from 'sagas';
import { changeMobileViewport, changeXLargeViewport } from 'actions/browserActions';
import * as BackboneActions from 'actions/BackboneActions';
import bootstrapState from 'store/bootstrapState';

import HubView from 'legacy/Views/Layouts/hub';
import RegisterView, { FB_LOGIN_RESPONSE } from 'legacy/Views/Modals/register';
import CompleteRegisterView from 'legacy/Views/Modals/completeRegister';

import App from 'App';

// A central place for all global side effects to occur.
// Modules executed as part of this dependency tree do things like attach
// event handlers to events like `DOMContentLoaded`, modify native prototypes
// (well, hopefully not), or instantiate classes that are needed for the
// lifetime of the app, i.e. they are not imported as needed.
import './globalSideEffects';

// The entry point to the rest of our old Backbone app. `run.js` is part of
// this dep. tree, so after this line all Backbone models and collections will
// have been initialized.
import 'legacy/legacyApp';

// After legacyApp, because much of the store's initial state is derived from
// Backbone models/collections.
import store, { runSaga } from './store';

runSaga(rootSaga);
bootstrapState(store);

// CNS-4897: Add a configured host to the webpack path at runtime to load webpack assets
// over a different host ie. a CDN
// Ensure that webpack is making ajax requests for split files through cdn
__webpack_public_path__ = `${config.publicAssetUrl}${__webpack_public_path__}`; // eslint-disable-line

$(document).ready(() => {
  // Initialize Visitor Actions
  visitorActions.getInstance();
  /**
   * CRO-73 Compare Favorites
   *
   * Our Comparison Tool is only available for devices which have
   * a width greater than 960px. We have a link in our navigation menu
   * that is written outside of our React tree. We have created a media
   * query listener that will decide whether or not we apply a click
   * handler on the anchor tag.
   */

  // onClick handler applied to anchor tag
  const pushFavoritesHandler = (e) => {
    e.preventDefault();
    const { selected } = store.getState().favorites;
    const path = selected.length > 0 ? `/favorites/?id=${selected.join(',')}` : '/favorites/';
    store.dispatch(push(path));
  };

  // the mq listener callback executes in response to the media query status changing
  // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
  const queryListenerHandler = ({ matches }) => {
    const { pathname, query } = store.getState().router;
    if (matches) {
      $('.js-favorites').on('click', pushFavoritesHandler);
      if (query && query.favs) {
        const { selected } = store.getState().favorites;
        const path = selected.length > 0 ? `/favorites/?id=${selected.join(',')}` : '/favorites/';
        store.dispatch(replace(path));
      }
    } else {
      $('.js-favorites').off('click', pushFavoritesHandler);
      if (pathname === '/favorites/' || pathname === '/compare/') {
        store.dispatch(replace('/results-gallery/?favs=1'));
      }
    }
  };

  // create the listener for future changes and execute it immediately
  const mql = window.matchMedia('(min-width: 960px)');
  mql.addListener(queryListenerHandler);
  queryListenerHandler({ matches: mql.matches });

  // Aligns with our `device--non-mobile()` mixin
  window.matchMedia(isNonMobileMQ).addListener(({ matches: isNonMobile }) => {
    store.dispatch(changeMobileViewport(!isNonMobile));
  });

  // Aligns with our `device--xlarge()` mixin
  window.matchMedia(deviceXLargeMQ).addListener(({ matches: isXLargeDevice }) => {
    store.dispatch(changeXLargeViewport(isXLargeDevice));
  });

  const { isCustomHomepage, isLandingPage } = config;

  // If they aren't registered we have some registration
  // modals we'll want to get started
  if (!window.bt.visitor.isRegistered() && !(isCustomHomepage || isLandingPage)) {

    // Create modals, which render themselves inside the <body>. Need to be
    // available before the main React tree.

    window.bt.register = new RegisterView({
      id: 'user-action-modal',
      model: window.bt.visitor,
    });

    new CompleteRegisterView({
      model: window.bt.visitor,
    });

    // Check for `fb_register` in qs, connect via social account and either log the user in if they
    // already have a phone number, or pop the second step of the reg form
    if (window.location.search && window.location.search.includes('fb_register')) {
      const fbUserParams = utility.parseQueryString();
      const params = {
        event: FB_LOGIN_RESPONSE,
      };
      if (fbUserParams.email) {
        params.status = 'connected';
        window.bt.register.connectSocialAccount(fbUserParams);
      } else if (fbUserParams.error_reason) {
        params.status = 'not_authorized';
      } else {
        params.status = 'unknown';
      }

      // send Facebook status to GTM, TODO: rename this func()
      store.dispatch(BackboneActions.getResponseFromIframe(params));
    }
  }

  window.bt.hubView = new HubView({
    el: $('.js-hub'),
    model: window.bt.visitor,
    store,
  });

  const rootEl = document.getElementById('inner-viewport');
  if (rootEl) {
    ReactDOM.render(
      (
        <Provider store={store}>
          <SagaContext.Provider value={runSaga}>
            <App />
          </SagaContext.Provider>
        </Provider>
      ),
      rootEl,
      () => {
        // Global state for automation. Only the first of these is needed now,
        // the others are left over from when there were multiple React trees.
        Object.assign(window.bt, {
          appHasRendered: true,
          mobileHeaderHasRendered: true,
          offCanvasContainerHasRendered: true,
          mobileResultsHeaderHasRendered: true,
        });
      });
  }

  /**
   * The above code sticks some stuff on the DOM some operations, like showing
   * the squeeze modal, are enqueued but need to wait for the dom, this seems
   * like the safest hack
   */
  window.bt.app.initQueuedDOMOperations();

  // required and visible for Cypress
  if (window.Cypress) {
    window.__store__ = store;
  }
});

import { createReducer } from 'redux-act';
import { isNonMobile as isNonMobileMQ, deviceXLargeMQ } from 'constants/mediaQueries';
import * as a from 'actions/browserActions';

const initial = {
  isMobile: !window.matchMedia(isNonMobileMQ).matches,
  isXLargeDevice: window.matchMedia(deviceXLargeMQ).matches,
};

const browserReducer = createReducer(
  {
    [a.changeMobileViewport]: (state, isMobile) => ({
      ...state,
      isXLargeDevice: false,
      isMobile,
    }),
    [a.changeXLargeViewport]: (state, isXLargeDevice) => ({
      ...state,
      isMobile: false,
      isXLargeDevice,
    }),
  },
  initial
);

export default browserReducer;

import $ from 'jquery';
import { app, visitor, config, search as searchModel } from 'BoomTown';
import { takeEvery, call, put, select } from 'redux-saga/effects';
import { takeOnce } from 'sagas/utils';
import { replace } from 'redux-little-router';

import { savedSearchesData } from 'constants/registrationForms';
import * as dssActions from 'actions/desktopSaveSearchActions';
import * as galleryActions from 'backbone/Views/Listings/gallery/galleryActions';
import * as noResultsActions from 'components/NoResults/actions';
import { clickSaveSearchInSearchBar } from 'components/SearchBar/actions';

import * as Notify from 'utility/uikitNotify';

export default [
  // Anytime this could be opened we want to squeeze them if they aren't registered
  takeEvery(
    [
      clickSaveSearchInSearchBar,
      galleryActions.clickSaveSearchCard,
      galleryActions.clickSaveSearchInNoResults,
      noResultsActions.clickSaveSearch,
    ],
    squeezeUnregisteredUsers
  ),

  // Save it to the server
  takeEvery(dssActions.clickSave, saveSearch),

  // We have a little clean up work to support springing the save search form postregistration
  takeOnce(
    [dssActions.clickClose, dssActions.receiveCreateSavedSearchRes],
    removePostRegQueryParam
  ),
];

/**
 * Maybe squeeze them for registration
 */
function* squeezeUnregisteredUsers() {
  const formType = savedSearchesData.urlParam;
  const showCloseBtn = true;
  const switcherOnSignIn = false;

  // Method only shows the modal if they aren't registered
  yield call([app, app.squeezeForm], formType, showCloseBtn, switcherOnSignIn);
}

function* saveSearch({ payload }) {
  const querystring = $.param(searchModel.attributes);

  let response;

  try {
    response = yield call([visitor, visitor.saveSearchPromise], {
      searchName: payload.name,
      email: payload.email,
      frequency: parseInt(payload.frequency, 10),
      querystring,
    });
  } catch (error) {
    yield put(dssActions.receiveCreateSavedSearchRes({ response: 'failure' }));
  }

  yield put(dssActions.receiveCreateSavedSearchRes({ response }));
  // Update the source of truth and let it propagate to redux
  visitor.updateSearchCount('up');
  Notify.success('Your search has been saved successfully.');
}

/**
 * Removes the post registration query param from the url
 */
function* removePostRegQueryParam() {
  const { [config.conversionQueryStringName]: _removed, ...restQuery } = yield select(
    state => state.router.query
  );

  if (_removed) {
    yield put(replace({ query: restQuery }));
  }
}

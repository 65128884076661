import { createReducer } from 'redux-act';
import * as MobileSaveSearchActions from 'actions/MobileSaveSearchActions';

/**
 * @type {Object}
 * @property {SearchTag[]} disabledTags
 * @property {number|null} resultCount
 * @property {boolean} isCountValid
 * @property {boolean} isResModalOpen
 * @property {boolean} isSubmitting
 * @property {boolean} isSuccess
 */
const defaultState = {
  disabledTags: [],
  resultCount: null,
  isCountValid: false,
  isResModalOpen: false,
  isSubmitting: false,
  isSuccess: false,
};

const handlers = {
  [MobileSaveSearchActions.clickTag]: (state, { prop, value }) => {
    const { disabledTags } = state;

    // Check to see if the selected tag is already disabled
    const i = disabledTags.findIndex(
      ({ prop: propInArray, value: valueInArray }) => prop === propInArray && value === valueInArray
    );

    // If the selected tag is not disabled, add it to the disabledTags array. Otherwise, remove it
    // from the disabledTags array.
    const nextDisabledTags =
      i === -1
        ? [...disabledTags, { prop, value }]
        : [...disabledTags.slice(0, i), ...disabledTags.slice(i + 1)];

    return {
      ...state,
      disabledTags: nextDisabledTags,
      isCountValid: false,
    };
  },
  [MobileSaveSearchActions.receiveCountFromAPI]: (state, resultCount) => ({
    ...state,
    resultCount,
    isCountValid: true,
  }),
  [MobileSaveSearchActions.receiveCountFromState]: (state, resultCount) => ({
    ...state,
    resultCount,
    isCountValid: true,
  }),
  [MobileSaveSearchActions.receiveCountError]: (state) => ({
    ...state,
    resultCount: null,
    isCountValid: false,
  }),
  [MobileSaveSearchActions.clickClose]: () => ({
    ...defaultState,
  }),

  /**
   * Show the proper modal alert when the save search request returns.
   * @todo Modify state to support a failure case when we have a UI for it.
   */
  [MobileSaveSearchActions.receiveCreateSavedSearchRes]: (state, payload, { success }) => ({
    ...state,
    isResModalOpen: true,
    isSuccess: success,
    isSubmitting: false,
  }),

  [MobileSaveSearchActions.clickSaveSearchSuccessButton]: () => ({ ...defaultState }),
  [MobileSaveSearchActions.autoDismiss]: () => ({ ...defaultState }),

  [MobileSaveSearchActions.clickSaveSearchRetryButton]: state => ({
    ...state,
    isResModalOpen: false,
  }),
  [MobileSaveSearchActions.clickSaveSearchBackButton]: state => ({
    ...state,
    isResModalOpen: false,
  }),
  [MobileSaveSearchActions.saveSearch]: state => ({
    ...state,
    isSubmitting: true,
  }),
};

export default createReducer(handlers, defaultState);

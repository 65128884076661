/* eslint-disable no-confusing-arrow, camelcase, indent */
import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';
import { LOCATION_CHANGED } from 'redux-little-router';
import isRoute from 'utility/isRoute';
import { TEXT_PREFERENCES } from 'routes';

import { getTextPreferences } from 'models/visitor/reducers/selectors';

import {
  toggleTextAllowed,
  submitTextPreferencesForm,
  failedSubmitTextPreferencesForm,
  successSubmitTextPreferencesForm,
  autoDismissTextPrefsSuccessModal,
  clickBackInTextPrefsFailureModal,
  clickCloseTextPrefsSuccessModal,
  clickRetryInTextPrefsFailureModal
} from './actions';

const isSubmitting = createReducer(
  {
    [submitTextPreferencesForm]: () => true,
    [failedSubmitTextPreferencesForm]: () => false,
    [successSubmitTextPreferencesForm]: () => false,
    [clickRetryInTextPrefsFailureModal]: () => true
  },
  false
);

// Store default state as a constant so it can be compared against.
const defaultFormState = {};

const form = createReducer(
  {
    [toggleTextAllowed]: (state, textAllowed) => ({
      ...state,
      textAllowed
    }),
    [successSubmitTextPreferencesForm]: () => defaultFormState,
    [LOCATION_CHANGED]: (state, payload) => {
      if (!isTextPrefsRoute({ type: LOCATION_CHANGED, payload }) && state !== defaultFormState) {
        return defaultFormState;
      }

      return state;
    },
  },
  defaultFormState
);

/**
 * responseModal
 */

const defaultRespModalState = {
  isOpen: false,
  isSuccess: false,
  successText: 'Your text preferences have been saved.'
};

function closeModal(state) {
  return {
    ...state,
    isOpen: false,
  };
}

const responseModal = createReducer(
  {
    // Opening The Modal
    [failedSubmitTextPreferencesForm]: state => ({
      ...state,
      isOpen: true,
      isSuccess: false
    }),
    [successSubmitTextPreferencesForm]: state => ({
      ...state,
      isOpen: true,
      isSuccess: true,
    }),
    // Closing the Modal
    [autoDismissTextPrefsSuccessModal]: closeModal,
    [clickBackInTextPrefsFailureModal]: closeModal,
    [clickCloseTextPrefsSuccessModal]: closeModal,
    [clickRetryInTextPrefsFailureModal]: closeModal


  },
  defaultRespModalState
);

export default combineReducers({
  isSubmitting,
  form,
  responseModal
});

// Selectors
export const local_isSubmittingTextPrefs = x => x.isSubmitting;
export const local_getFormState = x => x.form;

/** @returns {{isOpen, isSuccess, successText}} */
export const local_getTextResponseModal = x => x.responseModal;

// defined where this reducer is installed on our state tree
const select = selector => state => selector(state.accountView.textPreferences);

const isTextPrefsRoute = isRoute(TEXT_PREFERENCES);

export const isSubmittingTextPrefs = select(local_isSubmittingTextPrefs);

export const getTextResponseModal = select(local_getTextResponseModal);
const getLocalTextPrefsState = select(local_getFormState);


/**
 * Select the current state of the form. `undefined` only when the user's preferences
 * have not yet been loaded.
 *
 * @returns {undefined | { textAllowed: boolean}}
 */
export const getTextPrefsFormState = state => {
  const savedTextPrefs = getTextPreferences(state);
  // Not Yet loaded
  if (savedTextPrefs === undefined) {
    return undefined;
  }

  return {
    textAllowed: savedTextPrefs.textAllowed,
    ...getLocalTextPrefsState(state)
  };
};

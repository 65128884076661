import PropTypes from 'prop-types';

export default PropTypes.shape({
  _ID: PropTypes.number,
  Acreage: PropTypes.number,
  AcreageText: PropTypes.string,
  Agent: PropTypes.shape({
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
  }),
  AgentDRE: PropTypes.string,
  AgentName: PropTypes.string,
  AgentPhone: PropTypes.string,
  AllDataPagesDisclaimer: PropTypes.string,
  ApproxSqFt: PropTypes.number,
  ApproxSqFtFormatted: PropTypes.string,
  ApproxSqFtText: PropTypes.string,
  AssociationName: PropTypes.string,
  Bedrooms: PropTypes.number,
  BoardAlias: PropTypes.string,
  BoardID: PropTypes.number,
  CoAgents: PropTypes.arrayOf(PropTypes.shape({
    Name: PropTypes.string,
  })),
  ContentBottomHtml: PropTypes.string,
  CountyText: PropTypes.string,
  DetailsHeaderLogo: PropTypes.string,
  DateModified: PropTypes.string,
  DetailsBottomHtml: PropTypes.string,
  DetailsRemarksHtml: PropTypes.string,
  DetailsTopHtml: PropTypes.string,
  DisableAcreage: PropTypes.bool,
  DisclaimerPageHtml: PropTypes.string,
  DisplayBtAsDataSource: PropTypes.bool,
  DisplayPrice: PropTypes.number,
  Distance: PropTypes.number,
  FitScreenFooterHtml: PropTypes.string,
  FeatureText: PropTypes.arrayOf(PropTypes.shape({
    Name: PropTypes.string,
    Features: PropTypes.arrayOf(PropTypes.string),
  })),
  FullBaths: PropTypes.number,
  FullDetailsDisclaimer: PropTypes.string,
  HalfBaths: PropTypes.number,
  HasBeenViewed: PropTypes.bool,
  HideAddress: PropTypes.bool,
  HideBedsBaths: PropTypes.bool,
  HideLocalPics: PropTypes.bool,
  HomePageAndMapDisclaimer: PropTypes.string,
  ImgDir: PropTypes.string,
  InnerLogoUri: PropTypes.string,
  IsAgentSubdomain: PropTypes.bool,
  IsBankOwned: PropTypes.bool,
  IsFavorite: PropTypes.bool,
  IsMultiBoard: PropTypes.bool,
  IsOffMarket: PropTypes.bool,
  IsShortSale: PropTypes.bool,
  IsSold: PropTypes.bool,
  LastUpdatedAgo: PropTypes.number,
  ListDate: PropTypes.string,
  ListPrice: PropTypes.number,
  ListPriceFormatted: PropTypes.string.isRequired,
  ListingMLS: PropTypes.string,
  ListingPageTopDisclaimer: PropTypes.string,
  ListingTopHtml: PropTypes.string,
  Location: PropTypes.shape({
    Area: PropTypes.shape({
      Name: PropTypes.string,
    }),
    City: PropTypes.string,
    CityDetail: PropTypes.shape({
      Name: PropTypes.string,
    }),
    Coordinates: PropTypes.shape({
      Latitude: PropTypes.number,
      Longitude: PropTypes.number,
    }),
    FormattedAddress: PropTypes.string,
    Neighborhood: PropTypes.shape({
      ListingCount: PropTypes.number,
      Name: PropTypes.string,
      Url: PropTypes.string,
    }),
    PostalCode: PropTypes.string,
    State: PropTypes.string,
    StreetDirPrefix: PropTypes.string,
    StreetDirSuffix: PropTypes.string,
    StreetName: PropTypes.string,
    StreetNumber: PropTypes.string,
    UnitNumber: PropTypes.string,
  }),
  MLSContentOnly: PropTypes.bool,
  MLSText: PropTypes.string,
  MainPhoto: PropTypes.shape({
    FullUrl: PropTypes.string,
    LargeUrl: PropTypes.string,
    MediumUrl: PropTypes.string,
    Ordinal: PropTypes.number,
    Portrait: PropTypes.bool,
    SmallUrl: PropTypes.string,
    ThumbUrl: PropTypes.string,
  }),
  MlsName: PropTypes.string,
  MobileDisclaimer: PropTypes.string,
  MobileFullViewCompliance: PropTypes.string,
  MobileResultCompliance: PropTypes.string,
  MultiMLSThumbcardDisclaimer: PropTypes.string,
  NeighborhoodText: PropTypes.string,
  NoLazyLoad: PropTypes.bool,
  NumUnits: PropTypes.number,
  Office: PropTypes.shape({
    Name: PropTypes.string,
    Phone: PropTypes.string,
  }),
  OfficeName: PropTypes.string,
  OfficePhone: PropTypes.string,
  OnSite: PropTypes.number,
  OnSiteText: PropTypes.string,
  PhotoCount: PropTypes.number,
  PhotoFull: PropTypes.string,
  PhotoLabel: PropTypes.string,
  PhotoLarge: PropTypes.string,
  PhotoMedium: PropTypes.string,
  PhotoPortrait: PropTypes.bool,
  PhotoThumb: PropTypes.string,
  Photos: PropTypes.shape({
    FullUrl: PropTypes.string,
    LargeUrl: PropTypes.string,
    MediumUrl: PropTypes.string,
    Ordinal: PropTypes.number,
    Portrait: PropTypes.bool,
    SmallUrl: PropTypes.string,
    ThumbUrl: PropTypes.string,
  }),
  PreviewDisclaimer: PropTypes.string,
  PreviewHtml: PropTypes.string,
  PricePerSqFt: PropTypes.string, // But really should be a number
  PropertyType: PropTypes.shape({
    Category: PropTypes.string,
    Name: PropTypes.string.isRequired,
    Schema: PropTypes.string,
  }),
  ResultsDisclaimer: PropTypes.string,
  ResultsHtml: PropTypes.string,
  SashType: PropTypes.string,
  ScriptBottom: PropTypes.string,
  ShortUrl: PropTypes.string,
  ShowDaysOld: PropTypes.bool,
  ShowMortgageCalculator: PropTypes.bool,
  ShowPriceHistory: PropTypes.bool,
  ShowPricePerSqFt: PropTypes.bool,
  ShowSQFT: PropTypes.bool,
  ShowSQFTMinMax: PropTypes.bool,
  SoldFullDetailsDisclaimer: PropTypes.string,
  SoldPriceFormatted: PropTypes.string,
  SoldThumbCardDisclaimer: PropTypes.string,
  Status: PropTypes.string,
  StatusCode: PropTypes.string,
  SqFtSource: PropTypes.string,
  TermsDisclaimer: PropTypes.string,
  ThumbCardDisclaimer: PropTypes.string,
  ThumbnailDisclaimer: PropTypes.string,
  ThumbnailLogo: PropTypes.string,
  Title: PropTypes.string,
  TopFullDetailsDisclaimer: PropTypes.string,
  TopPopBottomHtml: PropTypes.string,
  TopPopHtml: PropTypes.string,
  Url: PropTypes.string,
  UrlPath: PropTypes.string,
  YearBuilt: PropTypes.number,
  beef: PropTypes.bool,
  eAlertFooter: PropTypes.string,
  eAlertHtml: PropTypes.string,
  eAlertHtmlMore: PropTypes.string,
  gtmImpression: PropTypes.bool,
  hasFeatureData: PropTypes.bool,
  hasFeatureText: PropTypes.bool,
  hideHalfBaths: PropTypes.bool,
  hideListingCardFavoriteBtn: PropTypes.bool,
  hideListingCardNeighborhood: PropTypes.bool,
  increaseDisclaimerFontSize: PropTypes.bool,
  isRegistered: PropTypes.bool,
  lazy: PropTypes.bool,
  shouldDisplayAsOffMarket: PropTypes.bool,
  showPriceTypeOnListing: PropTypes.bool,
});
// Intentionally not suffixed with isRequired

/* eslint-disable import/prefer-default-export */
export default function isTestStateFinalized(state) {
  return state.optimize.testStateFinalized;
}

/**
 * A feature toggle for the new desktop map
 *
 * @param {Object} state
 * @returns {boolean} If the new desktop map is enabled
 */
export function isDesktopMapEnabled(state) {
  return state.optimize.desktopMapEnabled;
}

/**
 *
 * @param {{optimize: {googleLoginStyle: 'control' | 'red' | 'white' | 'blue'}}} state
 * @return {false | 'red' | 'white' | 'blue'}
 */
export function getGoogleLoginStyle(state) {
  return state.optimize.googleLoginStyle !== 'control' && state.optimize.googleLoginStyle;
}

export function getEAlertFrequency(state) {
  return state.optimize.eAlertFrequency;
}

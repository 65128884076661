import { createAction as _createAction } from 'redux-act';
import { visitor } from 'BoomTown';

const createAction = (title, ...args) => _createAction(`Mobile Search Menu > ${title}`, ...args);

export const closeSearchMenu = createAction('Click close', () => {});
export const clickSaveOnSearchMenu = createAction(
  'Click save-search',
  () => {},
  () => ({ visitor })
);
export const togglePowerSearch = createAction('Click power toggle', () => {});
export const applySearch = createAction('Click the submit-search button', () => {});

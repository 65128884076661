import React from 'react';
import { connect } from 'react-redux';

/**
 * A connected component that renders its children only if the given route ID is
 * active.
 * @type {React.Component}
 */
export const ForRoute = connect(state => ({
  activeRoute: state.router.result ? state.router.result.id : null,
}))(props => {
  if (
    props.children &&
    (props.activeRoute === props.id || (props.ids && props.ids.includes(props.activeRoute)))
  ) {
    return React.Children.only(props.children);
  }

  return null;
});
ForRoute.displayName = 'ForRoute';

/**
 * Connect this component so that it matches only when there are no <Fragment>s
 * that match the current URL.
 */
export const ForCatchAll = connect(state => ({ isMatch: Boolean(!state.router.result) }))(
  props => (props.isMatch ? React.Children.only(props.children) : null)
);
ForCatchAll.displayName = 'ForCatchAll';

import PropTypes from 'prop-types';

export default {
  displayName: PropTypes.string.isRequired,
  prop: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  dataCY: PropTypes.string,
};

/* eslint-disable new-cap */
import { View } from 'backbone';
import $ from 'jquery';
import UIkit from 'uikit';
import bt from 'BoomTown';

export default class AppRoot extends View {
  get el() {
    return 'body';
  }

  // TODO: Better comments
  // backwards compatibility with old js
  body = $('body');
  html = $('html');

  initialize() {
    super.initialize();

    this.currentTheme = bt.utility.currentTheme();
    this.overrideUIKitComponents();

    return this;
  }

  /**
   * Override UIkit components before the DOM is ready
   */
  overrideUIKitComponents() {
    UIkit.on('beforeready.uk.dom', () => {
      $.extend(UIkit.components.switcher.prototype.defaults, { swiping: false });
      $.extend(UIkit.components.tab.prototype.defaults, { swiping: false });
    });
  }
}

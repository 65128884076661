import React from 'react';
import PropTypes from 'prop-types';
import * as Routes from 'routes';
import { H3 } from 'components/core/Headings';
import { PrimaryButton, SecondaryButton } from 'coreComponents';
import UserInfoStack from 'components/Common/User/UserInfoStack';
import UserAvatar from 'components/Common/User/UserAvatar';
import FAIcon from 'components/core/FAIcon';
import { MOBILE_MAIN_MENU } from 'cypress_constants';

/**
 * Replacement for libphonenumber format
 * @param {string} number
 * @return {string}
 */
const formatPhoneNumber = (number) => {
  const cleaned = (`${number}`).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return '';
};

/**
 * Renders the UserAvatar, name, email, and phone number of a user for use
 */
const UserInfoHeader = (props) => {
  const {
    visitor,
    dataCY,
    avatarClass = null,
    registerButtonClass = null,
    hidePhone,
    hideEditInfoButton,
    onGravatarError,
    onEditInfoClick = () => {},
    onSignInClick = () => {},
  } = props;

  const formatPhone = (number) => {
    return formatPhoneNumber(number);
  };

  return (
    <UserInfoStack className="mb-16">
      {visitor.isRegistered ? (
        <div>
          <UserAvatar
            className={avatarClass}
            visitor={visitor}
            gravatarError={onGravatarError}
            link={`/${Routes.EDIT_ACCOUNT}`}
            dataCY={MOBILE_MAIN_MENU.USER_AVATAR}
          />
          <div>{visitor.fullName}</div>
          <div className="bt-account__username uk-text-small uk-text-muted">{visitor.username}</div>
          { !hidePhone && <div>p: {formatPhone(visitor.bestPhone)}</div> }
          { !hideEditInfoButton && <EditContactInfoButton onClick={onEditInfoClick} /> }
        </div>
      ) : (
        <div>
          <H3>Unlock Complete Access</H3>
          <PrimaryButton
            className={registerButtonClass}
            dataCY={dataCY}
            btSize="small"
            width="full"
            onClick={onSignInClick}
          >
            Register / Sign In
          </PrimaryButton>
        </div>
      ) }

    </UserInfoStack>
  );
};

UserInfoHeader.propTypes = {
  visitor: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    bestPhone: PropTypes.string.isRequired,
    isRegistered: PropTypes.bool.isRequired,
  }).isRequired,
  avatarClass: PropTypes.string,
  dataCY: PropTypes.string,
  registerButtonClass: PropTypes.string,
  hidePhone: PropTypes.bool,
  hideEditInfoButton: PropTypes.bool,
  onGravatarError: PropTypes.func.isRequired,
  onEditInfoClick: PropTypes.func,
  onSignInClick: PropTypes.func,
};

export default UserInfoHeader;

const EditContactInfoButton = ({ onClick }) => {
  return (
    <SecondaryButton onClick={onClick} className="mt-12" width="half" size="small">
      <FAIcon icon="cog" type="solid" /> Edit
    </SecondaryButton>
  );
};

EditContactInfoButton.propTypes = {
  onClick: PropTypes.func,
};

import { createReducer } from 'redux-act';
import { LOCATION_CHANGED } from 'redux-little-router';
import * as OffCanvasActions from 'actions/OffCanvasActions';
import * as BackboneActions from 'actions/BackboneActions';
import * as DeviceLocationActions from 'actions/DeviceLocationActions';
import * as MobileGalleryActions from 'actions/MobileGalleryActions';
import * as MobileSearchMenuActions from 'screens/MobileSearchMenu/actions';
import * as GalleryActions from 'actions/GalleryActions';
import * as GeoActions from 'services/geolocation/actions';

import { listings } from 'BoomTown';

const defaultState = {
  isOpen: false,
  searchcount: listings ? (listings.TotalItems || listings.length) : 0,
  fetchingCount: false,
  requestingLocation: false,
  failedLocationRequest: false,
  ballerbox: {
    isActive: false,
    searchValue: '',
    suggestions: [],
  },

  /**
   * While the menu is open, this represents whether or not the user is
   * beginning a new search. (This affects the way that we build an API call to
   * fetch the search count; when true, we don't extend the committed search.)
   * @type {boolean}
   */
  startingNewSearch: false,
};

/**
 * Reducer for both success and failure actions when requesting location.
 */
const finishRequestingLocation = state => ({
  ...state,
  requestingLocation: false,
});

const reducerObj = {
  [BackboneActions.listingsReset]: (state, { totalItems }) => ({
    ...state,
    searchcount: totalItems,
  }),

  // Opening the Quick Search screen, make sure to reset pendingSearch meta
  [GalleryActions.clickSearch]: (state, { count }) => ({
    ...state,
    isOpen: true,
    searchcount: count,
    startingNewSearch: false,
  }),

  [OffCanvasActions.open]: (state, { initialSearchCount }) => ({
    ...state,
    isOpen: true,
    searchcount: initialSearchCount,
    startingNewSearch: false,
  }),

  [GalleryActions.clickNewSearch]: state => ({
    ...state,
    isOpen: true,
    startingNewSearch: true,
  }),

  [MobileGalleryActions.clickNewSearchInResultsHeader]: state => ({
    ...state,
    startingNewSearch: true,
  }),

  [MobileGalleryActions.receiveSearchResults]: (state, { totalItems }) => ({
    ...state,
    searchcount: totalItems,
  }),

  [MobileSearchMenuActions.closeSearchMenu]: state => ({
    ...state,
    startingNewSearch: false,
  }),

  // Short term solution, if the user navigates back we shoul atleast
  // close the menu. In the future this might be a route param/path
  // that we just respond to
  [LOCATION_CHANGED]: state => ({
    ...state,
    isOpen: false,
  }),
  [OffCanvasActions.close]: state => ({
    ...state,
    isOpen: false,
    startingNewSearch: false,
  }),

  [OffCanvasActions.invalidateCount]: state => ({
    ...state,
    fetchingCount: true,
  }),

  [OffCanvasActions.updateCountSuccess]: (state, payload) => ({
    ...state,
    searchcount: payload,
    fetchingCount: false,
  }),

  [OffCanvasActions.updateCountFailure]: state => ({
    ...state,
    fetchingCount: false,
  }),

  [DeviceLocationActions.beginLocationRequest]: state => ({
    ...state,
    requestingLocation: true,
  }),

  [DeviceLocationActions.receiveLocationDataSuccess]: finishRequestingLocation,

  [DeviceLocationActions.receiveLocationDataFailure]: finishRequestingLocation,

  [OffCanvasActions.ballerboxOnClose]: state => ({
    ...state,
    ballerbox: {
      ...state.ballerbox,
      isActive: false,
      searchValue: '',
      suggestions: [],
    },
  }),

  [OffCanvasActions.ballerboxOnFocus]: state => ({
    ...state,
    ballerbox: {
      ...state.ballerbox,
      isActive: true,
      searchValue: '',
    },
  }),

  [OffCanvasActions.ballerboxOnChange]: (state, searchValue) => ({
    ...state,
    ballerbox: {
      ...state.ballerbox,
      searchValue,
    },
  }),

  [OffCanvasActions.ballerboxOnSelection]: state => ({
    ...state,
    ballerbox: {
      ...state.ballerbox,
      isActive: false,
      searchValue: '',
    },
  }),

  // CNS-6841
  [GeoActions.startLocationRequest]: state => ({
    ...state,
    requestingLocation: true,
  }),

  [GeoActions.receiveCurrentLocation]: state => ({
    ...state,
    requestingLocation: false,
    failedLocationRequest: false,
  }),

  [GeoActions.receiveError]: state => ({
    ...state,
    requestingLocation: false,
    failedLocationRequest: true,
  }),

  [MobileSearchMenuActions.applySearch]: state => ({
    ...state,
    ballerbox: {
      ...state.ballerbox,
      ...defaultState.ballerbox,
    }
  }),

  [OffCanvasActions.ballerboxReceiveSuggestions]: (state, { suggestions }) => ({
    ...state,
    ballerbox: {
      ...state.ballerbox,
      suggestions,
    },
  }),
};

export default createReducer(reducerObj, defaultState);


/**
 * Selectors
 */

const getState = state => state.offCanvasSearch.menu;
const select = fn => state => fn(getState(state));

export const isStartingNewSearch = select(x => x.startingNewSearch);
export const isRequestingLocation = select(x => x.requestingLocation);
export const hasLocationRequestFailed = select(x => x.failedLocationRequest);
export const getIsOpen = select(x => x.isOpen);
export const getSearchCount = select(localState => localState.searchcount);
export const getIsFetchingCount = select(localState => localState.fetchingCount);

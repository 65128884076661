import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Pill from 'components/core/Pill';

/**
 * @typedef SlideCountPillProps
 * @type {Object}
 * @property {number} [current=0]
 * @property {number} [total=0]
 * @property {string} className
 */

/**
 * Renders the counter UI for a slider
 *
 * @param {SlideCountPillProps} props
 * @returns {JSX.Element}
 */
export default function SlideCountPill(props) {
  const { current = 0, total = 0, className } = props;
  const classes = cx('bt-listing__slide-count-pill', className);

  return (
    <Pill className={classes}>
      {current} <span className="bt-text--regular">of</span> {total}
    </Pill>
  );
}

SlideCountPill.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  className: PropTypes.string,
};

import React from 'react';
import * as routes from 'routes';
import { ForRoute } from 'components/ForRoute';

import AccountDashboard from './Dashboard';
import DesktopSavedSearches from './EditSavedSearches';

export default () => (
  <div>
    <ForRoute id={routes.ACCOUNT}>
      <AccountDashboard />
    </ForRoute>
    <ForRoute id={routes.EDIT_ACCOUNT}>
      <AccountDashboard />
    </ForRoute>
    <ForRoute id={routes.EMAIL_PREFERENCES}>
      <AccountDashboard />
    </ForRoute>
    <ForRoute id={routes.TEXT_PREFERENCES}>
      <AccountDashboard />
    </ForRoute>
    <ForRoute id={routes.CHANGE_PASSWORD}>
      <AccountDashboard />
    </ForRoute>
    <ForRoute id={routes.EDIT_SAVED_SEARCHES}>
      <DesktopSavedSearches />
    </ForRoute>
  </div>
);

import { has, omit, pick, isEmpty } from 'underscore';
import { config, compliance } from 'BoomTown';
import { CAP_ID_PARAMS, socialLoginParams, coordinateFilters, utmParams } from './constants';

/**
 * Run multiple filters on search parameters. Currently just filtering null values and
 * converting values for certain keys to uppercase.
 *
 * @param {Object} searchObj
 * @returns {Object} A filtered search object
 */
export const formatSearchParams = (searchObj) => Object.keys(searchObj).reduce(
  (acc, key) => {
    const value = searchObj[key];

    // Strip `null` values from our search obj
    if (value === null) {
      return acc;
    }

    return ({
      ...acc,
      [key]: searchIDToUpper(key, value),
    });
  },
  {}
);

/**
 * Ensure a property has capitalized values if the search param requires it
 *
 * @param {string} key
 * @param {string} value
 * @returns {string} The value param either as it was or capitalized
 */
const searchIDToUpper = (key, value) => (
  CAP_ID_PARAMS.includes(key) && value.toUpperCase ? value.toUpperCase() : value
);

/**
 * Remove query params that do not belong in the search
 *
 * @param {Object} searchObj
 */
export const filterByOmitList = (searchObj) => omit(
  searchObj,
  [
    // PRM-359
    config.maxphotoParam,
    config.maxviewsParam,
    'reg',
    'regformcomplete',
    config.blTrackingOrigUrlQSName,
    config.blTrackingReferrerQSName,
    'search', // Param representing the search menu
    ...socialLoginParams,
    ...utmParams
  ]
);

/**
 * If the URL contains 'pageindex' and our MaxListingResults would be exceeded,
 * interpret `searchObj` as having pageindex 0.
 *
 * This leaves the URL out of sync with whatever model uses this.
 *
 * @param {Object} searchObj
 */
export const filterPageIndex = (searchObj) => {
  if (has(searchObj, 'pageindex')) {
    const count = has(searchObj, 'pagecount') ? searchObj.pagecount : 10;
    const total = (parseInt(searchObj.pageindex, 10) + 1) * count;

    if (compliance.MaxListingResults > 0 && compliance.MaxListingResults < total) {
      searchObj.pageindex = 0;
    }
  }

  return searchObj;
};

/**
 * Strip map search params from legacy map urls to prevent issues with our new map api. Here we
 * filter out those map params so they don't get set in our search model.
 *
 * @param {Object} searchObj
 * @returns {Object}
 */
export const filterCoordsParams = (searchObj) => omit(searchObj, coordinateFilters);

/**
 * @function getMapFilters
 * Returns any coordinate filter or zoom values found in a search object. These values may be
 * stored as strings in the search obj, so we convert them back to floats if need be.
 *
 * @param {Object} searchObj
 * @returns {{zoom: Number|undefined, mapBounds: MapBounds|undefined}}
 */
export const getMapFilters = (searchObj) => {
  const filteredMapBounds = {};
  let { zoom, ...mapBounds } = pick(searchObj, coordinateFilters);

  // If we have mapBounds, ensure they're formatted as floats instead of strings.
  if (!isEmpty(mapBounds)) {
    const coords = Object.keys(mapBounds);
    for (const coord of coords) {
      filteredMapBounds[coord] = parseFloat(mapBounds[coord]);
    }

    mapBounds = filteredMapBounds;
  }

  if (zoom) {
    zoom = parseInt(zoom, 10);
  }

  return { zoom, mapBounds };
};

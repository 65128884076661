import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { connectBBHoc } from 'hoc/connectBB';
import { visitor as visitorModel } from 'BoomTown';
import { ACCOUNT_PAGE } from 'cypress_constants';
import { getVisitorData, getSavedSearchCount } from 'models/visitor/reducers/selectors';
import * as a from 'screens/MobileMenu/actions';

import Pill from 'components/core/Pill';
import { LinkList, LinkListItem } from 'components/MobileMenu/LinkList';
import UserInfoHeader from 'components/Common/User/UserInfoHeader';
import SettingsCard from 'screens/Account/SettingsCard';

const propTypes = {
  visitor: PropTypes.shape({
    isRegistered: PropTypes.bool.isRequired,
  }).isRequired,
  currentRoute: PropTypes.string,
  onEditInfoClick: PropTypes.func,
  gravatarError: PropTypes.func.isRequired,
};

const AccountMenu = ({ visitor: v, currentRoute, gravatarError }) => (
  <SettingsCard>
    <UserInfoHeader
      visitor={v}
      avatarClass="at-account__avatar"
      hideEditInfoButton
      onGravatarError={gravatarError}
    />
    <LinkList>
      <LinkListItem
        className="at-main-menu__favs"
        href="/favorites/"
        icon="favorite"
        disabled={!v.isRegistered}
        currentRoute={currentRoute}
      >
        My Favorites
        {v.favoriteCount > 0 && (
          <Pill
            className="link-list__pill pill--badge"
            bgColor="red"
          >
            {v.favoriteCount}
          </Pill>
        )}
      </LinkListItem>
      <LinkListItem
        className="at-main-menu__notifications"
        href="/account/saved-searches/"
        icon="save"
        disabled={!v.isRegistered}
        currentRoute={currentRoute}
        dataCY={ACCOUNT_PAGE.SAVED_SEARCHES}
      >
        Saved Searches
        {v.savedSearchCount > 0 && (
          <Pill
            className="link-list__pill pill--badge"
            bgColor="red"
            dataCY={ACCOUNT_PAGE.SAVED_SEARCHES_PILL}
          >
            {v.savedSearchCount}
          </Pill>
        )}
      </LinkListItem>
      <LinkListItem
        className="at-main-menu__account"
        href="/account/"
        icon="user"
        disabled={!v.isRegistered}
        currentRoute={currentRoute}
      >
        My Account
      </LinkListItem>
    </LinkList>
  </SettingsCard>
);

AccountMenu.propTypes = propTypes;

export default compose(connectBBHoc([
  {
    getEmitter: () => visitorModel,
    events: [
      {
        name: 'change',
        replaceProps: (prevProps, model) => ({ visitor: getVisitorData(model) }),
      },
    ],
  },
],
  () => ({ visitor: getVisitorData(visitorModel) })),
  connect((state, ownProps) => ({
    visitor: {
      ...ownProps.visitor,
      savedSearchCount: getSavedSearchCount(state),
    },
    currentRoute: state.router.pathname,
  }),
    {
      gravatarError: a.gravatarError,
    }))(AccountMenu);

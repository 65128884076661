import { select } from 'redux-saga/effects';
import { isIOS } from 'utility';
import { getActiveListing } from 'selectors/listings';

/**
 * Navigate the user to a native app for directions.
 */
export default function* handleDirectionsBtnClick() {
  const { listing } = yield select(getActiveListing);
  const { Latitude: lat, Longitude: long } = listing.Location.Coordinates;

  // Prefer Apple Maps only on iOS devices
  window.open(
    isIOS() ? `//maps.apple.com/?daddr=${lat},${long}` : `//maps.google.com/?daddr=${lat},${long}`
  );
}

import { createReducer } from 'redux-act';
import { initializeState } from 'actions/bootstrapActions';

/** @type {Array<{id: number, url: string, title: string, parent: number}>} */
const initialState = [];

export default createReducer(
  {
    // Note that the slug is not really needed for items that don't have children
    [initializeState]: (state, { menuItems }) => (typeof menuItems === 'undefined') ? [] : menuItems,
  },
  initialState
);

import $ from 'jquery';
import { initializeCurrentLocation } from 'redux-little-router';
import { config, utility } from 'BoomTown';
import { savedSearchesData as saveSearchRegForm } from 'constants/registrationForms';
import { initializeState } from 'actions/bootstrapActions';
import { isMobile } from 'selectors/browser';
import { StoredPendingSearch } from 'models/search/services';

export default function bootstrapState(store) {
  let mapBounds;
  let mapZoom;

  if (config.useListMapResultsSync) {
    const mapBoundsCookie = $.cookie('lastMapBounds');
    const lastMapBounds = mapBoundsCookie && utility.parseQueryString(mapBoundsCookie);

    const mapZoomCookie = $.cookie('lastMapZoom');
    mapZoom = mapZoomCookie ? parseInt(mapZoomCookie, 10) : null;

    // When we parse the map bounds query string from the cookie, the values are stored as strings.
    // However, the various pieces of state that depend on these values need them to be numbers.
    mapBounds = lastMapBounds ? Object.keys(lastMapBounds).reduce(
      (acc, key) => {
        acc[key] = parseFloat(lastMapBounds[key]);
        return acc;
      },
      {}
    ) : null;
  }

  const lastSearchState = utility.parseQueryString($.cookie(config.searchCookieName));

  // The mobile Saved Search Menu can be implicitly opened if it was the reason you registered.
  // Although we manage opening that menu in our App root if this is the case, we don't update
  // the appropriate value in our `mobileGallery` branch of state. We'll determine if that value
  // should be true/false here and let the mobileGallery reducer handle setting its state.
  let isSaveSearchMenuOpen = false;
  if (isMobile(store.getState()) && lastSearchState) {
    isSaveSearchMenuOpen =
      lastSearchState[config.conversionQueryStringName] === saveSearchRegForm.urlParam;
  }

  // Hydrate store with state included in the document.
  // TODO: Migrate any impure state in reducers to this.
  store.dispatch(
    initializeState({
      ...window.bt_data,

      lastSearchRoute: $.cookie('lastSearchRoute'),
      lastSearchState,
      lastMapBounds: mapBounds,
      lastMapZoom: mapZoom,
      isSaveSearchMenuOpen,

      // Fetches a pending search obj from localStorage if the user registered from the
      // Mobile Filter Menu
      pendingSearch: StoredPendingSearch.get(),
    })
  );

  const routerState = store.getState().router;
  if (routerState) {
    store.dispatch(initializeCurrentLocation(routerState));
  }
}

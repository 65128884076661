import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Select, Fieldset, FieldsetLegend } from 'coreComponents';
import { Grid, Cell } from 'components/core/Grid';

/**
 * A pair of <select> menus used to set range values in the user's search.
 * Expects the `field` prop to correspond to search state when
 * prepended with 'min' and 'max'.
 */
const SelectRange = (props) => {
  const {
    dataCY,
    label,
    field,
    id,
    className,
    minValue,
    maxValue,
    minClassName,
    maxClassName,
    minOptions,
    maxOptions,
    onMinChange,
    onMaxChange,
  } = props;

  // Desktop is passing 'any', offcanvas is passing '' for initial state
  // TODO: Still necessary? Or should we consolidate?
  const realMax = maxValue === 'any' || maxValue === '' ? Infinity : maxValue;
  const realMin = minValue === 'any' || minValue === '' ? 0 : minValue;

  /**
   * We remove any options that would result in a contradiction.
   *
   * @param {FormattedSearchFieldOptions} opts
   * @param {'min'|'max'} [type='min']
   * @returns {boolean}
   */
  const filterContradictions = (opts, type = 'min') =>
    opts.filter((opt) => {
      if (opt.value === 'any' || opt.value === '---') {
        return true;
      }

      if (type === 'min') {
        return realMax > opt.value;
      } else if (type === 'max') {
        return realMin < opt.value;
      }

      return false;
    });

  return (
    <Fieldset className={cx(className)}>
      <FieldsetLegend>{label}</FieldsetLegend>
      <Grid alignItems="center">
        <Cell>
          <Select
            id={`${id}Min`}
            className={minClassName}
            formGroupProps={{ className: 'mb-0 form__group--select' }}
            data-cy={`${dataCY}__min`}
            name={`min${field}`}
            value={minValue}
            onChange={onMinChange}
            options={filterContradictions(minOptions, 'min')}
            aria-label={`Minimum ${label}`}
          />
        </Cell>

        <Cell xs={1} className="text-xs--center">
          -
        </Cell>

        <Cell>
          <Select
            id={`${id}Max`}
            className={maxClassName}
            formGroupProps={{ className: 'mb-0 form__group--select' }}
            data-cy={`${dataCY}__max`}
            name={`max${field}`}
            value={maxValue}
            onChange={onMaxChange}
            options={filterContradictions(maxOptions, 'max')}
            aria-label={`Maximum ${label}`}
          />
        </Cell>
      </Grid>
    </Fieldset>
  );
};

SelectRange.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  minClassName: PropTypes.string,
  maxClassName: PropTypes.string,
  minValue: PropTypes.any,
  maxValue: PropTypes.any,
  onMinChange: PropTypes.func,
  onMaxChange: PropTypes.func,
  dataCY: PropTypes.string,
  field: PropTypes.string,
  minOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  maxOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

export default SelectRange;

import PropTypes from 'prop-types';
import React from 'react';
import CheckboxGroup from './CheckboxGroup';

/**
 * NOTE: While this is a function of props, it does NOT return a single
 * ReactElement, so you can't use it like:
 *
 * ```
 * <FeatureGroups ... />
 * ```
 *
 * React does not currently allow you to return arrays of React elements, so
 * to avoid unnecessary DOM nodes use it like this instead:
 *
 * ```
 * <div>
 *   {FeatureGroups({
 *     // props
 *   })}
 * </div>
 * ```
 *
 * @param {Object} props
 */
export default function FeatureGroups(props) {
  return Object.keys(props.features).map(key => {
    const featureGroup = props.features[key];
    const groupName = key.replace(/\s+/g, '');
    return (
      <CheckboxGroup
        flushSides={props.flushSides}
        key={key}
        title={key === 'popular' ? 'Popular Features' : key}
        name={groupName}
        options={featureGroup}
        checkedOptions={props.checkedFeatures}
        onToggleOption={props.onToggleFeature}
      />
    );
  });
}

FeatureGroups.propTypes = {
  flushSides: PropTypes.boolean,
  features: PropTypes.object,
  checkedFeatures: PropTypes.arrayOf(PropTypes.string),
  onToggleFeature: PropTypes.func
};

FeatureGroups.defaultProps = {
  flushSides: false
};

import React from 'react';
import PropTypes from 'prop-types';
import AgentAvatar from 'components/Common/Agent/AgentAvatar';

const MobileAgentAvatar = (props) => {
  const { className, ...other } = props;
  return (
    <AgentAvatar className={`mobile-agent__avatar ${className}`} {...other} />
  );
};

MobileAgentAvatar.propTypes = {
  className: PropTypes.string
};

export default MobileAgentAvatar;

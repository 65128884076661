/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-act';

/**
 * Dispatched by the 'UPDATE_CURRENT_SEARCH' MessageListener
 *
 * @payload {Object} payload
 * @see {MessageListener} updateListSearch | updateMapSearch
 */
export const applyPendingSearch = createAction(
  'Apply Pending Search from Native App',
  payload => payload,
);

/**
 * Dispatched by the 'APPLY_SAVED_SEARCH' MessageListener
 *
 * @payload {Object} querystring
 * @see {MessageListener} applySavedSearchToList | applySavedSearchToMap
 */
export const applySavedSearch = createAction(
  'Apply saved search from Native App',
  querystring => querystring,
);

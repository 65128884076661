/**
 * Be super careful changing the names of the comments for webpackChunkName
 * details, home, and results-gallery are magic strings used by php
 * for script loading / prefetching
 * @see /wp-content/themes/wp-base-theme/inc/assets.php
 *
 * This module is for defining split points into either of the two listing
 * details pages, and determining which of the two to show based on A/B test
 * state and viewport width.
 */
import React from 'react';
import loadable from 'react-loadable';
import CachedHTML from 'screens/util/CachedHTMLContainer';
import IsMobile from 'components/IsMobile';
import backboneToReactView from 'legacy/Routing/backboneToReactView';
import LazyMobileDetails from './Mobile';

const LazyBackboneDetails = loadable({
  loader: () =>
    import(/* webpackChunkName: 'js/build/details' */ 'legacy/Views/Listings/single').then(mod =>
      backboneToReactView(mod.default)
    ),
  loading: CachedHTML
});

export default function ListingDetails() {
  return (
    <IsMobile>
      {isMobile => (isMobile ? <LazyMobileDetails /> : <LazyBackboneDetails />)}
    </IsMobile>
  );
}

import { createReducer } from 'redux-act';
import * as homeValActions from './actions';

const initialState = {
  working: false,
};

export default createReducer(
  {
    [homeValActions.onHomeValSearchSubmit]: (state) => ({
      ...state,
      working: true,
    }),
  },
  initialState
);

// Localize our selectors
const createSelector = selector => (state, ...args) =>
  selector(state.homeValFinder, ...args);

export const getWorkingStatus = createSelector(state => state.working);

import { Model } from 'backbone';

/**
 * Shape
 * ----
 *
 * FieldID: number;
 * FieldName: string;
 * FieldValue: string;
 * Group: String;
 *
 * // Unused
 * // InQuickSearch: boolean;
 * // IsHidden: boolean;
 * // ListingField: string;
 */
export default class SearchField extends Model {
  get defaults() {
    return {
      // FieldID: null,
      FieldName: null,
      FieldValue: null,
      Group: null,
    };
  }
}

import { config } from 'BoomTown';
import { race, put, take, takeLatest, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import * as geoActions from 'services/geolocation/actions';
import * as mapActions from '../Map/actions';
import { onPolygonClick } from '../ActionBar/actions';
import * as a from './actions';

export default [
  takeLatest([
    mapActions.receiveResults,
    mapActions.receiveNewResultsPage,
    geoActions.receiveError
  ], dismissAfterTimeout),
];

/**
 * Dimiss the Toast after a 3 second unless either the `startLocationRequest` or `onPolygonClick`
 * actions are dispatched first.
 *
 * @note Only necessary for the legacy implementation. If Map/List sync is enabled, then just
 *       return immediately. Once Map/List sync is solidified, this saga can be removed.
 *
 */
function* dismissAfterTimeout() {
  if (config.useListMapResultsSync) {
    return;
  }

  yield race([
    call(function* waitAndPut() {
      yield call(delay, 3000);
      yield put(a.dismissToast());
    }),
    take(geoActions.startLocationRequest),
    take(onPolygonClick),
  ]);
}

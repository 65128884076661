import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox, Select } from 'coreComponents';
import { CCompToggle } from 'components/Common/Dropdown';
import useFormattedSearchFieldOptions from 'hooks/useFormattedSearchFieldOptions';
import SelectRange from 'components/Common/FormElements/SelectRange';

const PropertyDetailsAccordion = (props) => {
  const {
    account,
    actions,
    pendingSearch,
    searchFields,
    specialRules,
  } = props;

  const searchFieldOptions = useFormattedSearchFieldOptions({
    minsqft: searchFields.minsqft,
    maxsqft: searchFields.maxsqft,
    minacres: searchFields.minacres,
    maxacres: searchFields.maxacres,
    minstories: searchFields.minstories,
    maxstories: searchFields.maxstories,
    minyear: searchFields.minyear,
    maxyear: searchFields.maxyear,
    mingarages: searchFields.mingarages,
    maxgarages: searchFields.maxgarages,
    maxdayslisted: searchFields.maxdayslisted,
    pricereduced: searchFields.pricereduced,
  });

  /**
   * Handles prepping the payload and dispatching the action for changes made to the Days On Site
   * select field.
   *
   * @param {ClickEvent} e
   */
  const handleDaysOnSiteChange = (e) => {
    const value = e.currentTarget.value;
    const n = parseInt(value, 10);
    const payload = isNaN(n) ? value : n;
    actions.updateMaxDaysListed(payload);
  };

  /**
   * Parse the value from a Select input option to an integer or float.
   *
   * @note Pulled from InitialOptionsPane component (previously "RegularPane")
   * @todo Stop doing this inline and put it in a selector function.
   *
   * @param {number|string} value
   * @param {'int'|'float'} type
   *
   * @returns {number|'any'}
   */
  const parseRangeValue = (value, type) => {
    if (value == null) {
      return 'any';
    }

    let parsedValue;
    switch (type) {
      case 'int':
        parsedValue = parseInt(value, 10);
        break;
      case 'float':
        parsedValue = parseFloat(value, 10);
        break;
      default:
        return value;
    }

    return isNaN(parsedValue) ? 'any' : parsedValue;
  };

  const {
    ShowSQFT,
    ShowAcresSearch,
    ShowStories,
    ShowYearBuilt,
    ShowDaysListed,
    ShowGarages,
    ShowPriceReduced,
  } = specialRules;
  const { ShowOpenHouse } = account;

  return (
    <CCompToggle heading="Property Details">
      <div className="pb-16 px-16">
        <ul className="grid grid--gutters grid-xs--full grid-md--fourths mb-4">
          <li className="cell">
            <Checkbox
              label="Has Photos"
              id="ocs--has-photo"
              name="hasPhotos"
              value="ocs--has-photo"
              checked={pendingSearch.photo}
              onChange={() => actions.toggleHasPhotos('ocs--has-photo')}
            />
          </li>
          {ShowOpenHouse && (
            <li className="cell">
              <Checkbox
                label="Open House"
                id="ocs--oh-feature"
                name="openHouse"
                value="ocs--oh-feature"
                checked={pendingSearch.features.includes('OH')}
                onChange={() => actions.toggleHasOpenHouse('ocs--oh-feature')}
              />
            </li>
          )}
          <li className="cell">
            <Checkbox
              label="Virtual Tours"
              id="ocs--virtual-tours"
              name="virtualTours"
              value="ocs--virtual-tours"
              checked={pendingSearch.tours}
              onChange={() => actions.toggleVirtualTours('ocs--virtual-tours')}
            />
          </li>
        </ul>

        <div>
          {ShowSQFT && (
            <SelectRange
              id="range-sqft"
              label="Square Footage"
              field="sqft"
              minValue={parseRangeValue(pendingSearch.minsqft, 'int')}
              maxValue={parseRangeValue(pendingSearch.maxsqft, 'int')}
              onMinChange={(e) => actions.updateMinSqft(e.currentTarget.value)}
              onMaxChange={(e) => actions.updateMaxSqft(e.currentTarget.value)}
              minOptions={searchFieldOptions.minsqft}
              maxOptions={searchFieldOptions.maxsqft}
            />
          )}

          {ShowAcresSearch && (
            <SelectRange
              id="minmax-acres"
              label="Acres"
              field="acres"
              minValue={parseRangeValue(pendingSearch.minacres, 'float')}
              maxValue={parseRangeValue(pendingSearch.maxacres, 'float')}
              onMaxChange={(e) => actions.updateMinAcres(e.currentTarget.value)}
              onMinChange={(e) => actions.updateMaxAcres(e.currentTarget.value)}
              minOptions={searchFieldOptions.minacres}
              maxOptions={searchFieldOptions.maxacres}
            />
          )}

          {ShowStories && (
            <SelectRange
              id="minmax-stories"
              label="Stories"
              field="stories"
              minValue={parseRangeValue(pendingSearch.minstories, 'float')}
              maxValue={parseRangeValue(pendingSearch.maxstories, 'float')}
              onMaxChange={(e) => actions.updateMinStories(e.currentTarget.value)}
              onMinChange={(e) => actions.updateMaxStories(e.currentTarget.value)}
              minOptions={searchFieldOptions.minstories}
              maxOptions={searchFieldOptions.maxstories}
            />
          )}

          {ShowYearBuilt && (
            <SelectRange
              id="minmax-year"
              label="Year Built"
              field="year"
              minValue={parseRangeValue(pendingSearch.minyear, 'int')}
              maxValue={parseRangeValue(pendingSearch.maxyear, 'int')}
              onMaxChange={(e) => actions.updateMinYear(e.currentTarget.value)}
              onMinChange={(e) => actions.updateMaxYear(e.currentTarget.value)}
              minOptions={searchFieldOptions.minyear}
              maxOptions={searchFieldOptions.maxyear}
            />
          )}

          {ShowGarages && (
            <SelectRange
              id="minmax-garage"
              label="Garage Spaces"
              field="garages"
              minValue={parseRangeValue(pendingSearch.mingarages, 'int')}
              maxValue={parseRangeValue(pendingSearch.maxgarages, 'int')}
              onMaxChange={(e) => actions.updateMinGarages(e.currentTarget.value)}
              onMinChange={(e) => actions.updateMaxGarages(e.currentTarget.value)}
              minOptions={searchFieldOptions.mingarages}
              maxOptions={searchFieldOptions.maxgarages}
            />
          )}

          {ShowDaysListed && (
            <Select
              label="# Days On Site"
              name="maxdayslisted"
              value={parseRangeValue(pendingSearch.maxdayslisted, 'int')}
              options={searchFieldOptions.maxdayslisted}
              onChange={handleDaysOnSiteChange}
            />
          )}
          {ShowPriceReduced && (
            <Select
              label="Price Reduced"
              name="pricereduced"
              value={pendingSearch.pricereduced === null ? '' : pendingSearch.pricereduced}
              options={searchFieldOptions.pricereduced}
              onChange={(e) => actions.updatePriceReducedDate(e.currentTarget?.value)}
            />
          )}
        </div>
      </div>
    </CCompToggle>
  );
};

PropertyDetailsAccordion.propTypes = {
  pendingSearch: PropTypes.object,
  searchFields: PropTypes.object,
  specialRules: PropTypes.object,
  account: PropTypes.object,
  actions: PropTypes.object,
};

export default PropertyDetailsAccordion;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'redux-little-router';
import FAIcon from 'components/core/FAIcon';

/**
 * Used to render the content of a dropdown menu. Spreads extra props onto the wrapping `<ul/>`
 * element, so HTML attributes are allowed.
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {(func|string|element)} props.children
 */
export default function MenuList({
  className,
  children,
  ...props
}) {
  return (
    <ul className={cx('menu-list', className)} {...props}>
      {children}
    </ul>
  );
}

MenuList.propTypes = {
  /** Should be individual `<li/>` UI components/elements */
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.array,
    PropTypes.element,
  ]).isRequired,
  className: PropTypes.string,
};

/**
 * Handle New Email Share Link
 */
export const MenuListEmailShareLink = ({ dataCY }) => {
  let elementLink = null;

  try {
    const subject = `${window.bt.account.attributes.LegalName} - ${window.bt.listing.attributes.Location.FormattedAddress}`;
    const body = `Take a look at this property I found on ${window.bt.account.attributes.Website}: ${window.location.href}`;
    const uri = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    elementLink = (
      <li>
        <a
          href={uri}
          className="menu-list__link"
          data-cy={dataCY}
          title="Email This Listing"
        >
          <FAIcon icon="envelope" type="solid" /> Email this listing
        </a>
      </li>
    );
  } catch {
    console.warn('Could not load Email Share Link');
  }

  return elementLink;
};

MenuListEmailShareLink.propTypes = {
  dataCY: PropTypes.string,
};

/**
 * Used to render a basic `<a/>` element wrapped in a `<li/>` tag.
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} [props.itemClassName]
 * @param {string} [props.id]
 * @param {string} [props.title]
 * @param {boolean} [props.isActive]
 * @param {func} [props.onClick]
 * @param {(string|array)} props.children
 */
export const MenuListLink = ({
  className,
  itemClassName,
  id,
  dataCY,
  title,
  children,
  isActive,
  onClick = undefined,
  ...props
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick(id);
  };

  const itemClasses = cx(
    'menu-list__link',
    className,
    { 'menu-list__link--active': isActive },
  );

  return (
    <li className={itemClassName}>
      <a
        role="menuitem"
        id={id}
        tabIndex={0}
        data-cy={dataCY}
        className={itemClasses}
        title={title}
        onClick={onClick && handleClick}
        {...props}
      >
        {children || title}
      </a>
    </li>
  );
};

MenuListLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  /* Class name to apply to the `<a/>` tag */
  className: PropTypes.string,
  /* Class name to apply to the `<li/>` tag */
  itemClassName: PropTypes.string,
  id: PropTypes.string,
  /* Used as `title` attribute on `<a/>` tag and default content if no children are provided */
  title: PropTypes.string,
  /* Whether or not the link should display "active" styles */
  isActive: PropTypes.bool,
  /* Method to be called when the link is clicked */
  onClick: PropTypes.func,
  dataCY: PropTypes.string,
};

/**
 * Used to render a redux-little-router Link component wrapped in a `<li/>` tag.
 * @param {object} props
 * @param {string} [props.itemClassName]
 * @param {string} [props.className]
 * @param {string} [props.id]
 * @param {string} [props.title]
 * @param {func} [props.onClick]
 * @param {(string|array)} props.children
 * @param {string} props.href
 *
 * @see https://github.com/FormidableLabs/redux-little-router#link
 */
export const MenuListRouterLink = ({
  itemClassName,
  className,
  id,
  title,
  children,
  ...props
}) => {
  return (
    <li id={id} className={itemClassName}>
      <Link
        className={cx('menu-list__link', className)}
        title={title}
        {...props}
      >
        {children || title}
      </Link>
    </li>
  );
};

MenuListRouterLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  itemClassName: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  title: PropTypes.string,
};

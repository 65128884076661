/**
 * A _very_ simple component. Its only reason for existence is to identify
 * what part of the component tree, by `displayName`, should be conditionally
 * rendered.
 * @param {Object} props
 */
export default function DropdownContent(props) {
  return props.children;
}

DropdownContent.displayName = 'DropdownContent';

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { CCompDropdown } from 'components/Common/Dropdown';
import { PrimaryButton, SecondaryButton } from 'coreComponents';
import MoreLessTrigger from './MoreLessTrigger';
import InitialOptionsPane from './InitialOptionsPane';
import MoreFiltersPane from './MoreFiltersPane';

/**
 * The "More +" dropdown menu in the SearchMenu
 */
export default function SubMenu(props) {
  const { search, searchFields, onToggleFeature, ...rest } = props;
  const [
    /**
     * @type {boolean}
     */
    showMorePane,
    setShowMorePane,
  ] = useState(false);

  /**
   * Gets replaced with a function to close the search type dropdown.
   *
   * Passed to CCompDropdown -> Dropdown
   *
   * This is awful, but refactoring the CCompDropdown tree of components might be out of scope
   * for the Ballerbox refactor, unfortunately.
   *
   * @type {function}
   */
  const _closeDropdown = useRef(() => null);

  const selectedFeatures = (search.feature || '').split(',').filter((x) => x !== '');

  const renderContents = () => (
    <div className="bt-ccomp__content__inner">
      <div className="bt-sticky-footer__wrapper">
        {!showMorePane ? (
          <InitialOptionsPane
            search={search}
            searchFields={searchFields}
            selectedFeatures={selectedFeatures}
            onToggleFeature={onToggleFeature}
            {...rest}
          />
        ) : (
          <MoreFiltersPane
            features={searchFields.features}
            selectedFeatures={selectedFeatures}
            onToggleFeature={onToggleFeature}
          />
        )}
        <div className="bt-sticky-footer__push" />
      </div>
      <div className="bt-listing-search__more-panel">
        <div className="grid grid--justifyBetween">
          <div className="cell">
            <SecondaryButton
              size="small"
              onClick={() => setShowMorePane((prevState) => !prevState)}
            >
              {!showMorePane ? 'More Options' : 'Fewer Options'}
            </SecondaryButton>
          </div>
          <div className="cell text-xs--right">
            <PrimaryButton
              size="small"
              className="bt-dropdown__more__close"
              onClick={() => _closeDropdown.current()}
            >
              Show Results
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <CCompDropdown
      className="bt-ccomp--full-width"
      align="right"
      getClose={(close) => (_closeDropdown.current = close)}
      passOpenState
      trigger={<MoreLessTrigger className="btn-group__item-btn btn-group__item-btn--last" />}
      triggerClassName="at-sub-menu-trigger"
      content={renderContents()}
    />
  );
}

const selectType = PropTypes.shape({
  options: PropTypes.array,
});

SubMenu.propTypes = {
  search: PropTypes.shape({
    minsqft: PropTypes.string,
    maxsqft: PropTypes.string,
    minacres: PropTypes.string,
    maxacres: PropTypes.string,
    minstories: PropTypes.string,
    maxstories: PropTypes.string,
    minyear: PropTypes.string,
    maxyear: PropTypes.string,
    mingarages: PropTypes.string,
    maxgarages: PropTypes.string,
    maxdayslisted: PropTypes.string,
    pricereduced: PropTypes.string,
    photo: PropTypes.string,
    feature: PropTypes.string,
  }),
  searchFields: PropTypes.shape({
    minsqft: selectType,
    minacres: selectType,
    minstories: selectType,
    minyear: selectType,
    mingarages: selectType,
    maxdayslisted: selectType,
    pricereduced: selectType,
    features: PropTypes.object,
  }),

  onSelectMenuChange: PropTypes.func.isRequired,
  onTogglePhoto: PropTypes.func.isRequired,
  onToggleFeature: PropTypes.func.isRequired,

  ShowSQFT: PropTypes.bool,
  ShowAcresSearch: PropTypes.bool,
  ShowStories: PropTypes.bool,
  ShowYearBuilt: PropTypes.bool,
  ShowGarages: PropTypes.bool,
  ShowDaysListed: PropTypes.bool,
  ShowPriceReduced: PropTypes.bool,
  ShowOpenHouse: PropTypes.bool,
};

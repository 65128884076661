import { app, visitor } from 'BoomTown';
import { signinFormData } from 'constants/registrationForms';
import { takeEvery } from 'redux-saga/effects';
import * as mobileMenu from 'screens/MobileMenu/actions';

const visitorEffects = [
  takeEvery([mobileMenu.clickSignInLink, mobileMenu.clickSignInButton], showRegForm),
  takeEvery(mobileMenu.clickSignOutLink, signOut),
  takeEvery(mobileMenu.gravatarError, recordGravatarError),
];
export default visitorEffects;

/**
 * Reach out to backbone and trigger the modal
 */
function showRegForm() {
  const action = signinFormData.urlParam;
  const showCloseBtn = true;
  const isSignIn = false;
  app.squeezeForm(action, showCloseBtn, isSignIn);
}

function signOut() {
  // We are signing out from mobile menu, redirect to homepage
  visitor.signout({ href: '/' });
}

function recordGravatarError() {
  visitor.set('hasGravatar', false);
}

import $ from 'jquery';
import _ from 'underscore';
import bt from 'BoomTown';
import BaseModel from 'legacy/Base/model';
import btLocalStorage from 'utility/btLocalStorage';
import utility from 'legacy/Utility/utility';

export default class Tag extends BaseModel {
  get defaults() {
    return {
      noclose: false,
      displayName: '',
      prop: '',
      value: '',
      overflow: false
    };
  }

  initialize() {
    super.initialize();
    this.setDisplayName();
  }

  // Keys should have the format `${proptype}=${id}` to ensure they are local
  static localStorageNamespace = 'tags';

  static getLocalStorageKey(name, value) {
    return `${name}=${value}`;
  }

  static setLocalStorage(name, value, displayName) {
    const localStorageKey = Tag.getLocalStorageKey(name, value);
    return btLocalStorage(Tag.localStorageNamespace, localStorageKey, displayName);
  }

  /**
   * Get the search params english name from somewhere
   *
   * @param {string} name Like city, or hood
   * @param {string | number} value Like "1234" or "1234~1232"
   */
  setSearchParam(name, value) {
    // This storage is set either from here or from Autocomplete/Ballerbox select
    const localStorageKey = Tag.getLocalStorageKey(name, value);
    const localName = btLocalStorage(Tag.localStorageNamespace, localStorageKey);
    if (localName !== null) {
      return localName;
    }

    // We swap the tildes with commas, ONLY for the api,
    // don't go using this variable anywhere else
    const transferableValue = String(value).replace(/~/g, ',');

    $.ajax({
      url: '{0}/1/getquerystringname?tenantID={1}&q={2}'.format(
        bt.config.suggestApiUrl,
        bt.tenant.id,
        `${name}=${encodeURIComponent(transferableValue)}`
      ),
      dataType: 'jsonp',
      success: data => {
        // Updating the name async
        if (_.isEmpty(data.Result)) {
          this.set('displayName', this.get('prop'));
        } else {
          btLocalStorage(Tag.localStorageNamespace, localStorageKey, data.Result);
          this.set('displayName', data.Result);
        }
      }
    });

    // Have to return something,
    // but we'll get back to this with some async result
    return null;
  }

  setDisplayName() {
    const name = this.get('prop').toLowerCase();
    const value = this.get('value');
    const { pluralize } = utility;
    let display = '';

    switch (name) {
      case 'minprice':
        display = `over $${bt.utility.shortenDollarAmount(value.toString().replace(/\D/g, ''))}`;
        break;
      case 'maxprice':
        display = `up to $${bt.utility.shortenDollarAmount(value.toString().replace(/\D/g, ''))}`;
        break;

      case 'minbeds':
        display = `${value}+ ${pluralize('Bed', parseInt(value, 10))}`;
        break;
      case 'maxbeds':
        display = `${value} ${pluralize('Bed', parseInt(value, 10))} Max`;
        break;

      case 'minbaths':
        display = `${value}+ ${pluralize('Bath', parseFloat(value))}`;
        break;
      case 'maxbaths':
        display = `${value} ${pluralize('Bath', parseFloat(value))} Max`;
        break;
      case 'minfullbaths':
        display = `${value}+ Full ${pluralize('Bath', parseFloat(value))}`;
        break;
      case 'maxfullbaths':
        display = `${value} Full ${pluralize('Bath', parseFloat(value))} Max`;
        break;
      case 'minhalfbaths':
        display = `${value}+ Half ${pluralize('Bath', parseFloat(value))}`;
        break;
      case 'maxhalfbaths':
        display = `${value} Half ${pluralize('Bath', parseFloat(value))} Max`;
        break;

      case 'proptype':
        let field = bt.searchFields.findWhere({ FieldValue: value, Group: 'PropType' });
        if (field != null && field.get('FieldName') != null) {
          display = field.get('FieldName');
        } else {
          display = this.get('prop');
        }
        break;

      case 'feature':
        field = bt.searchFields.findWhere({ FieldValue: value, Group: 'Feature' });
        if (field != null && field.get('FieldName') != null) {
          display = field.get('FieldName');
        } else {
          display = this.get('prop');
        }
        break;

      case 'featureor':
        if (value === 'BO') {
          display = 'Include Foreclosures';
        } else if (value === 'SS') {
          display = 'Include Short Sales';
        }
        break;
      case 'featurenot':
        if (value === 'NBO') {
          display = 'Exclude Foreclosures';
        } else if (value === 'NSS') {
          display = 'Exclude Short Sales';
        }
        break;
      case 'minsqft':
        display = '{0} sqft and up'.format(bt.utility.addCommas(value));
        break;
      case 'maxsqft':
        display = 'up to {0} sqft'.format(bt.utility.addCommas(value));
        break;
      case 'minacres':
        display = '{0} acres and up'.format(value);
        break;
      case 'maxacres':
        display = 'up to {0} acres'.format(value);
        break;
      case 'minstories':
        display = '{0} stories and up'.format(value);
        break;
      case 'maxstories':
        display = 'up to {0} stories'.format(value);
        break;
      case 'minyear':
        display = 'built in {0} or later'.format(value);
        break;
      case 'maxyear':
        display = 'built in {0} or earlier'.format(value);
        break;
      case 'mingarages':
        display = 'at least {0} garage spaces'.format(value);
        break;
      case 'maxgarages':
        display = 'up to {0} garage spaces'.format(value);
        break;
      case 'photo':
        display = 'Has Photos';
        break;
      case 'tours':
        display = 'Virtual Tours';
        break;
      case 'postalcode':
        switch (document.documentElement.lang) {
          case 'en-US':
            display = 'ZIP: {0}'.format(value);
            break;
          case 'en-CA':
            display = 'Postal Code: {0}'.format(value);
            break;
          default:
            break;
        }
        break;
      case 'status':
        if (value === 'AC') {
          display = bt.compliance.ReplaceUnderContract;
        } else if (value === 'S') {
          display = 'Sold';
        } else if (value === 'P') {
          display = 'Pending';
        } else if (value === 'CS' && !bt.rules.get('HideComingSoon')) {
          display = 'Coming Soon';
        }
        break;
      case 'maxdayslisted':
        if (value === 1) {
          display = 'New on Site';
        } else {
          display = 'On Site {0} Days or Less'.format(value);
        }
        break;
      case 'city':
      case 'hood':
      case 'area':
      case 'street':
      case 'streetname':
      case 'custom':
      case 'county':
      case 'school':
      case 'schooldistrict':
      case 'listingid':
        display = this.setSearchParam(name, value);
        break;
      case 'latlonrad':
        display = 'Properties Near Me';
        break;
      case 'rid':
        display = 'e-Alert Results';
        break;
      case 'favs':
        display = 'My Favorites';
        break;
      case 'keyword':
        display = 'Keyword: "{0}"'.format(value);
        break;
      case 'polygon':
        display = 'My Map Polygon';
        break;
      case 'pricereduced':
        display = '$ Reduced';

        if (value === '<=1') {
          const temp = '$ Reduced {0} Day Ago'.format(value);
          display = temp.replace('>', '> ').replace('<=', '< ');
        } else {
          const temp = '$ Reduced {0} Days Ago'.format(value);
          display = temp.replace('>', '> ').replace('<=', '< ');
        }
        break;

      case 'hoodlike':
        display = value;
        break;
      case 'citylike':
        display = value;
        break;
      case 'guide':
        display = 'Guide: {0}'.format(value.replace(/_/g, ' '));
        break;
      // Leave 'officeid' for legacy purposes until we're sure it's all phased out
      case 'officeid':
        display = 'Our Listings';
        break;
      // CNS-4833: Our Listings was no longer creating a filter pill since `userID` in the qs
      // was not being accounted for in `allowedTags` in tags.js
      case 'userid':
        display = 'Our Listings';
        break;

      // We now have a case where if searchModel.coordinateFilters are in the search query, then we
      // need to create a single search tag for all coord filters.
      case 'mapbounds':
        display = 'Map Boundaries';
        break;

      default:
        break;
    }

    if (display !== '') {
      this.set({ displayName: display }, { silent: true });
    }
  }
}

export const appVersion330 = 3003000;

export const isConsumerApp = () => window.bt.config.isMobileApp;

export const consumerAppVersionAtOrAbove = (minimumVersion) => {
  const currentVersion = window.bt.config.mobileAppVersion;

  if (!currentVersion) {
    return false;
  }

  if (currentVersion >= minimumVersion) {
    return true;
  }

  return false;
};

/* eslint-disable indent */
import { connect } from 'react-redux';
import { isSubmittingEmailPrefs, activeConfirmationModal, getResponseModal, getEmailPrefsFormState } from './reducer';
import * as emailPrefsActions from './actions';

export default connect(
  state => ({
    subscriptions: getEmailPrefsFormState(state),
    activeConfirmationModal: activeConfirmationModal(state),
    isSubmitting: isSubmittingEmailPrefs(state),
    ...getResponseModal(state),
  }),
  {
    onSubmit: emailPrefsActions.submitEmailPreferencesForm,
    onToggleAgent: emailPrefsActions.toggleAgentSubscription,
    onToggleLender: emailPrefsActions.toggleLenderSubscription,
    onToggleEAlerts: emailPrefsActions.toggleEAlertsSubscription,
    onClickUnsubscribeAll: emailPrefsActions.clickUnsubscribeAll,
    onClickConfirmUnsubAll: emailPrefsActions.clickConfirmUnsubscribeAllModal,
    onClickCancelUnsubAll: emailPrefsActions.clickCancelUnsubscribeAllModal,
    onClickConfirmEAlerts: emailPrefsActions.clickConfirmUnsubscribeEAlertsModal,
    onClickCancelEAlerts: emailPrefsActions.clickCancelUnsubscribeEAlertsModal,
    onClickConfModalEditEAlerts: emailPrefsActions.clickEditSavedSearchesUnsubscribeEAlertsModal,
    onClickEditSavedSearches: emailPrefsActions.clickEditSavedSearchesButton,
    onClickCloseInRespModal: emailPrefsActions.clickCloseEmailPrefsSuccessModal,
    onClickBackInRespModal: emailPrefsActions.clickBackInEmailPrefsFailureModal,
    onClickRetryInRespModal: emailPrefsActions.clickRetryInEmailPrefsFailureModal,
  }
);

/* global updateRecaptchaV2Token, loadRecaptchaToken */
import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { config } from 'BoomTown';
import { ErrorMessage } from 'coreComponents';

export default class RecaptchaV2 extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    alignRight: PropTypes.bool,
    widget: PropTypes.bool,
    error: PropTypes.shape({
      type: PropTypes.string,
      message: PropTypes.string,
    }),
  };

  /**
   * Returns the token to callback handler.
   * @param {string} token
   * @return {void}
   */
  onChange = (token) => {
    if (this.props.onChange) {
      this.props.onChange(token);
    }
  }

  /**
   * React Render
   * @return {ReactDOM}
   */
  render() {
    const { alignRight, widget, error } = this.props;

    const styles = {
      padding: '8px 0',
      width: '304px'
    };

    if (alignRight) {
      styles.marginLeft = 'auto';
    }

    if (widget) {
      styles.transform = 'scale(0.75)';
      styles.transformOrigin = '0 0';
    }

    if (config.recaptchaEnabled) {
      return (
        <div style={styles}>
          <ReCAPTCHA sitekey={config.recaptchaKey} onChange={this.onChange} />
          {error && <ErrorMessage message={error.message} />}
        </div>
      );
    }

    return null;
  }
}

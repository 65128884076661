import React from 'react';

/**
 * Create a declarative, leak-safe wrapper around the native imperative setTimeout API for use in
 * functional React components.
 * - The timer is restarted every time an item in `deps` change (much like a `useEffect` or
 *   `useMemo` hook).
 * - If a new callback is given to the hook before the previous timeout expires, only the new
 *   callback will be executed at the moment the timeout expires.
 * - When the hook receives a new callback, the timeout isn't reset.
 * - If the `delay` param is false (note: strictly false, not falsy), then the callback is not
 *   called and any existing timeout is canceled.
 * @param {() => void} callback
 * @param {number | boolean} [timeout=0]
 * @param {[]} [deps=[]]
 */
const useTimeout = (callback, delay = 0, deps = []) => {
  const callbackRef = React.useRef();
  const timerRef = React.useRef();

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  /**
   * Restart the timer every time:
   * a.) An item in `deps`.
   * b.) The `delay` parameter value changes.
   */
  React.useEffect(() => {
    if (delay) {
      const timerID = setTimeout(callbackRef?.current, delay);
      timerRef.current = timerID;

      // Clean the timer identified by timerID when the effect is unmounted.
      return () => clearTimeout(timerID);
    }

    return () => cancelTimer();
  }, [...deps, delay]);

  /**
   * Returns a function that can be used to cancel the current timeout. It does this by using
   * `clearTimeout()` without exposing the last reference to the timer to the user.
   */
  function cancelTimer() {
    return clearTimeout(timerRef.current);
  }

  return cancelTimer;
};

export default useTimeout;

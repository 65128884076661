/* eslint-disable no-confusing-arrow */
import React from 'react';
import PropTypes from 'prop-types';
import { Input, PrimaryButton } from 'coreComponents';
import { Grid, Cell } from 'components/core/Grid';
import Callout from 'components/core/Callout';
import ResponseModal from 'components/Common/ResponseModal';
import { MOBILE_CHANGE_PASSWORD, PARSLEY } from 'cypress_constants';
import withData, { FieldIDs } from './withData';

const blockName = 'change-password';
const getFieldElementID = (x) => `${blockName}__${x}`;

const ChangePassword = (props) => {
  const {
    onSubmit,
    onFieldFocus,
    isSubmitting,
    isMobileScreen,
    validateForm,
    formData,
    formHandlers,
    errors,
    isOpen,
    isSuccess,
    successText,
    errorMessage,
    onClickCloseInRespModal,
    onClickBackInRespModal,
    onClickRetryInRespModal,
  } = props;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      return;
    }

    onSubmit({
      password: formData[FieldIDs.Current],
      newPassword: formData[FieldIDs.New],
    });
  };

  /**
   * @returns {Boolean} Whether or not the form is valid
   */
  const isFormValid = () => {
    const errorObj = validateForm();
    return Object.keys(errorObj).length === 0;
  };

  /**
   * Get the corresponding error object from our state.
   *
   * @note There are better ways of handling error state, but we'll refactor this component soon
   * hopefully anyway to a functional component. We can implement a better error state structure
   * at that time.
   *
   * @param {string} fieldName
   * @returns {{type:string, message:string}}
   */
  const getErrors = (fieldName) => {
    if (errors) {
      const fieldError = errors[fieldName];

      if (fieldError) {
        return { type: fieldName, message: fieldError };
      }

      return null;
    }

    return null;
  };

  const errorProps = { dataCY: PARSLEY.ERRORS_LIST };

  return (
    <div>
      <form id={`${blockName}__form`} onSubmit={handleSubmit}>
        <Grid gutters resetVertical>
          <Cell xs={12} md={6}>
            <Input
              label="Current Password"
              type="password"
              id={getFieldElementID(formData[FieldIDs.Current])}
              value={formData[FieldIDs.Current]}
              onChange={formHandlers[FieldIDs.Current]}
              onFocus={onFieldFocus}
              error={getErrors(FieldIDs.Current)}
              errorProps={errorProps}
              dataCY={MOBILE_CHANGE_PASSWORD.CURRENT_PASSWORD}
            />
            {isMobileScreen && (
              <div className="mb-12">
                <small>
                  <strong>PASSWORD REMINDER</strong>: If you have forgotten your password, you may
                  have used your phone number.
                </small>
              </div>
            )}
          </Cell>

          {!isMobileScreen && (
            <Cell xs={12} className="pt-0 pb-12">
              <Callout placement="top-left">
                <strong>PASSWORD REMINDER</strong>: If you have forgotten your password, you may
                have used your phone number.
              </Callout>
            </Cell>
          )}

          <Cell xs={12}>
            <p className="pb-12">
              Your new password must contain letters and numbers, be at least 8 characters in length, and contain no spaces.
            </p>
          </Cell>

          <Cell xs={12} md={6}>
            <Input
              label="New Password"
              type="password"
              id={getFieldElementID([FieldIDs.New])}
              value={formData[FieldIDs.New]}
              onChange={formHandlers[FieldIDs.New]}
              onFocus={onFieldFocus}
              error={getErrors(FieldIDs.New)}
              errorProps={errorProps}
              dataCY={MOBILE_CHANGE_PASSWORD.NEW_PASSWORD}
              maxLength={20}
            />
          </Cell>
          <Cell xs={12} md={6}>
            <Input
              label="Confirm Password"
              type="password"
              id={getFieldElementID([FieldIDs.NewRepeated])}
              value={formData[FieldIDs.NewRepeated]}
              onChange={formHandlers[FieldIDs.NewRepeated]}
              onFocus={onFieldFocus}
              error={getErrors(FieldIDs.NewRepeated)}
              errorProps={errorProps}
              dataCY={MOBILE_CHANGE_PASSWORD.CONFIRM_PASSWORD}
              maxLength={20}
            />
          </Cell>
        </Grid>

        {!isMobileScreen && (
          <Grid gutters justifyContent="center" className="mt-12">
            <Cell md={6}>
              <PrimaryButton
                type="submit"
                className="at-change-password-submit-btn"
                dataCY={MOBILE_CHANGE_PASSWORD.SUBMIT_BUTTON}
                width="full"
                form={`${blockName}__form`}
                showSpinner={isSubmitting}
              >
                Confirm Password Change
              </PrimaryButton>
            </Cell>
          </Grid>
        )}
      </form>
      <ResponseModal
        isOpen={isOpen}
        isSuccess={isSuccess}
        onClickSuccess={onClickCloseInRespModal}
        onClickBack={onClickBackInRespModal}
        onClickRetry={(e) => {
          onClickRetryInRespModal();
          handleSubmit(e);
        }}
        successText={successText}
        errorMessage={errorMessage}
      />
    </div>
  );
};

ChangePassword.propTypes = {
  onSubmit: PropTypes.func,
  onFieldFocus: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isMobileScreen: PropTypes.bool,

  // From createForm()
  validateForm: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  formHandlers: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,

  // Response Modal
  isOpen: PropTypes.bool,
  isSuccess: PropTypes.bool,
  successText: PropTypes.string,
  errorMessage: PropTypes.string,
  onClickCloseInRespModal: PropTypes.func,
  onClickBackInRespModal: PropTypes.func,
  onClickRetryInRespModal: PropTypes.func,
};

ChangePassword.defaultProps = {
  isSubmitting: false,
  onSubmit: () => {},
  onFieldFocus: () => {},
};

export default withData(ChangePassword);

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default class Disclaimer extends React.PureComponent {
  static displayName = 'Disclaimer';

  static propTypes = {
    disclaimerHTML: PropTypes.string,
    dataCY: PropTypes.string,
    className: PropTypes.string,
    small: PropTypes.bool,
    muted: PropTypes.bool
  };

  static defaultProps = {
    disclaimerHTML: '',
    small: false,
    muted: false,
  };

  getCleanDisclaimerText(disclaimerHTML) {
    // ADA Compliance
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(disclaimerHTML, 'text/html');
      const images = doc.querySelectorAll('img');
      for (const img of images) {
        if (img.getAttribute('alt') === null) {
          img.setAttribute('alt', 'MLS');
        }
      }
      return doc.querySelector('body').innerHTML;
    } catch (ex) {
      /* eslint-disable-next-line no-console */
      console.error('Disclaimer Parser Error:', ex);
    }
    return disclaimerHTML;
  }

  render() {
    const { dataCY, className, small, muted, disclaimerHTML } = this.props;
    return (
      <div
        data-cy={dataCY}
        className={
          cx(
            className,
            {
              'font-size--14': small,
              'bt-text--muted': muted
            }
          )}
        dangerouslySetInnerHTML={{ __html: this.getCleanDisclaimerText(disclaimerHTML) }}
      />
    );
  }
}

/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { config } from 'BoomTown';
import {
  ACCOUNT,
  EDIT_ACCOUNT,
  EMAIL_PREFERENCES,
  CHANGE_PASSWORD,
  TEXT_PREFERENCES
} from 'routes';
import ToggleCard from 'components/Common/ToggleCard';
import { Laptop } from 'components/core/BreakPoints';
import { ACCOUNT_PAGE } from 'cypress_constants';
import Layout from './Layout';
import ConnectedEditAccount from '../Forms/EditAccount';
import ConnectedChangePassword from '../Forms/ChangePassword';
import ConnectedEditEmailPrefs from '../Forms/EditEmailPrefs';
import ConnectedEditTextPrefs from '../Forms/EditTextPrefs';
import ContactAgent from './ContactAgent';

const Content = ({ route }) => (
  <React.Fragment>
    <ToggleCard
      title="Contact Information"
      defaultOpen={route === EDIT_ACCOUNT || route === ACCOUNT}
      className="mb-12"
    >
      <ConnectedEditAccount />
    </ToggleCard>
    <ToggleCard
      title="Email Preferences"
      defaultOpen={route === EMAIL_PREFERENCES}
      className="mb-12"
      dataCY={ACCOUNT_PAGE.EMAIL_PREFERENCES_BUTTON}
    >
      <ConnectedEditEmailPrefs />
    </ToggleCard>
    {config.enableTextPreferences &&
      <ToggleCard
        title="Text Message Preferences"
        defaultOpen={route === TEXT_PREFERENCES}
        className="mb-12"
        dataCY={ACCOUNT_PAGE.TEXT_MESSAGE_PREFERENCES_BUTTON}
      >
        <ConnectedEditTextPrefs />
      </ToggleCard>
    }

    <ToggleCard
      title="Change Password"
      defaultOpen={route === CHANGE_PASSWORD}
      className="mb-12"
    >
      <ConnectedChangePassword />
    </ToggleCard>
  </React.Fragment>
);
Content.propTypes = {
  routes: PropTypes.oneOf([
    EDIT_ACCOUNT,
    EMAIL_PREFERENCES,
    CHANGE_PASSWORD,
  ])
};
// Since we show the full AccountDashboard on each of the 3 routes, subscribe to the store
// and use the route to determine which section is open by default to provide a bit more
// convenience to the user (especially from Unsubscribe links)
const ConnectedContent = connect(
  state => ({
    route: state.router.result.id
  })
)(Content);

const AccountDashboard = () => (
  <React.Fragment>
    <Laptop larger>
      <Layout rightSlot={<ContactAgent />}>
        <ConnectedContent />
      </Layout>
    </Laptop>

    <Laptop smaller>
      <Layout>
        <ConnectedContent />
      </Layout>
    </Laptop>
  </React.Fragment>
);

export default AccountDashboard;

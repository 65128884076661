import { takeEvery, select, call } from 'redux-saga/effects';
import { getCurrentListingID } from 'selectors/listings';
import { clickBackToSearch, clickListingAttribute } from 'backbone/Views/Listings/single/actions';
import { goToLastResults } from 'sagas/routerTasks/goToLastResults';
import { add } from 'sagas/routerTasks/search';
import ls from 'utility/btLocalStorage';
import Tag from 'backbone/Model/tag';

export default [
  takeEvery([clickBackToSearch], function* onBackToSearchClick() {
    yield call(goToLastResults, yield select(getCurrentListingID));
  }),
  takeEvery(clickListingAttribute, function* onClickListingAttr({ payload }) {
    const { name, id, type } = payload;

    // Add this information to our tags displayName state in localStorage
    const lsKey = Tag.getLocalStorageKey(type, id);
    if (!ls(Tag.localStorageNamespace, lsKey)) {
      ls(Tag.localStorageNamespace, lsKey, name);
    }

    yield add({ [type]: id });
    window.scrollTo(0, 0);
  }),
];

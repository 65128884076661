import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import cx from 'classnames';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import emptyFunction from 'utility/emptyFunction';
import DropdownTrigger from './DropdownTrigger';
import DropdownContent from './DropdownContent';
import CCompDropdown from './CCompDropdown';
import CCompToggle from './CCompToggle';

/* eslint-disable react/prop-types */
const animations = {
  fadeDown: props => (
    <CSSTransitionGroup
      transitionName="bt-ccomp"
      transitionEnterTimeout={300}
      transitionLeaveTimeout={300}
    >
      {props.children}
    </CSSTransitionGroup>
  )
};
/* eslint-enable */

/**
 * A dropdown menu component, implemented using children to identify the
 * trigger and content.
 *
 * @example
 * render() {
 *   return (<Dropdown>
 *     <DropdownTrigger><button>Click me to open the dropdown<button><DropdownTrigger>
 *     <DropdownContent>
 *       <ul>
 *         <li>Some stuff that you want rendered, but only if this Dropdown is open.</li>
 *       </ul>
 *     </DropdownContent>
 *   </Dropdown>);
 * }
 *
 */
class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.isOpen
    };
  }

  componentDidMount() {
    // We could expose this functionality by having parent components obtain a
    // ref and then calling a `close()` method, but doing it this way keeps
    // the API a little more well-defined, although it is a little ugly.
    this.props.getClose(this._close);
    $(document).on('click', this._onClickElsewhere);
  }

  componentWillUnmount() {
    $(document).off('click', this._onClickElsewhere);
  }

  _getFirstChildByType = (children, type) => {
    for (let i = 0; i < children.length; i++) {
      if (children[i].type === type) {
        return children[i];
      }
    }
    return undefined;
  };

  /**
   * Searches for the first child with the same ctor function as
   * `DropdownTrigger`, and returns its clone if found.
   * @return {ReactElement|undefined}
   */
  _getTrigger = () => {
    const trigger = this._getFirstChildByType(this.props.children, DropdownTrigger);
    if (!trigger) {
      throw new Error('No DropdownTrigger found in children of Dropdown.');
    }
    return trigger;
  };

  /**
   * Searches for the first child with the same ctor function as
   * `DropdownContent`, and returns its clone if found.
   * @return {ReactElement|undefined}
   */
  _getContent = () => {
    const content = this._getFirstChildByType(this.props.children, DropdownContent);
    if (!content) {
      throw new Error('No DropdownContent found in children of Dropdown.');
    }
    return content;
  };

  _triggerRef = (c) => {
    if (c) {
      this._triggerNode = ReactDOM.findDOMNode(c);
    }
  };

  _ccompRef = (c) => {
    if (c) {
      this._ccompNode = ReactDOM.findDOMNode(c);
    }
  };

  _onClickElsewhere = (e) => {
    if (this.state.isOpen && e.target !== this._triggerNode && !this._ccompNode.contains(e.target)) {
      this.props.onClose();
      this.setState({ isOpen: false });
    }
  };

  /**
   * Click handler for the DropdownTrigger. Toggles the isOpen state stored in this
   * component.
   */
  _triggerClick = () => {
    if (this.props.disabled) {
      return;
    }
    if (this.state.isOpen) {
      this._close();
    } else {
      this._open();
    }
  };

  _open = () => {
    this.props.onOpen();
    this.setState({
      isOpen: true
    });
  };

  _close = () => {
    // REFACTOR: ...
    $.ccompCloseAllDropdowns();
    this.props.onClose();
    this.setState({
      isOpen: false
    });
  };

  /**
   * Render a clone of the trigger with a click handler passed, and only
   * render the content if `this.state.isOpen` is true.
   * @return {ReactElement}
   */
  render() {
    const trigger = React.cloneElement(this._getTrigger(), Object.assign({
      key: 'dropdown-trigger',
      onClick: this._triggerClick,
      ref: this._triggerRef
    }, this.props.passOpenState ? {
      isOpen: this.state.isOpen
    } : {}));
    const content = React.cloneElement(this._getContent(), {
      key: 'dropdown-content',
    });
    const classes = cx(
      {
        [this.props.className]: !!this.props.className,
        'at-active bt-active': this.state.isOpen,
        [this.props.activeClass]: !!this.props.activeClass && this.state.isOpen
      }
    );

    const Animation = animations[this.props.animation];
    const child = this.state.isOpen ? content : null;

    return (
      <div
        className={classes}
        ref={this._ccompRef}
        data-cy={this.props.dataCY}
      >
        {trigger}
        {Animation ? (
          <CSSTransitionGroup
            transitionName="bt-ccomp"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
          >
            {child}
          </CSSTransitionGroup>
        ) : child}
      </div>
    );
  }
}

Dropdown.displayName = 'Dropdown';
Dropdown.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  getClose: PropTypes.func,
  passOpenState: PropTypes.bool,
  activeClass: PropTypes.string,
  animation: PropTypes.oneOf(['fadeDown']),
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  dataCY: PropTypes.string,
};
Dropdown.defaultProps = {
  onClose: emptyFunction,
  onOpen: emptyFunction,
  getClose: emptyFunction,
  isOpen: false,
};

export {
  Dropdown,
  DropdownTrigger,
  DropdownContent,
  CCompDropdown,
  CCompToggle
};

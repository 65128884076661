import { PUSH } from 'redux-little-router';

export {
  scrollPositionMiddleware as middleware,
  restoreScroll
};


/**
 * Set the scroll position to a value stored in `history.state` for this
 * history entry.
 *
 * @description Since we have React views that are code-split and render
 * asynchronously, we can't do this in a middleware or saga for all route
 * changes; it is important to set scroll position _after_ the next route is
 * fully rendered, and that state is local to react-loadable components. Call
 * this function from screens' cDM, to ensure that scroll position is restored.
 *
 * @returns {boolean} Whether or not the scroll position was modified
 */
function restoreScroll() {
  if (
    !window.history ||
    !window.history.state ||
    typeof window.history.state.scrollPosition === 'undefined'
  ) {
    return false;
  }
  const { scrollPosition } = window.history.state;
  window.scroll(window.scrollX, scrollPosition);
  return true;
}

function scrollPositionMiddleware() {
  return next => action => {
    if (action?.type === PUSH) {
      // Prior to a state transition, persist the previous scroll position in
      // history.state.
      window.history.replaceState(
        {
          ...window.history.state,
          scrollPosition: window.scrollY
        },
        ''
      );
    }
    next(action);
  };
}

import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { uniqueId } from 'underscore';
import withModal from 'hoc/withModal';
import * as QualifyingQuestionsActions from 'actions/QualifyingQuestionsActions';
import { SecondaryButton } from 'coreComponents';
import FAIcon from 'components/core/FAIcon';
import { QUAL_QUESTIONS } from 'cypress_constants';
import getQuestionProps from './selectors';

/**
 * When moving to API-provided questions, we ran into a problem of how to
 * determine what icon to show for what question. If these are joined to the
 * backend state by ID, we would have issues across environments, since it
 * looks like the IDs are auto-incremented, and not bound to the question.
 *
 * For now we're sticking with a single icon, but these are the icons we were
 * using before:
 *
 *
 *  {
 *    icon: 'components/SvgIcons/custom/timeline',
 *    question: 'What is your timeline for purchase?',
 *  }
 *  {
 *    icon: 'components/SvgIcons/custom/mortgage',
 *    question: 'Have you been pre-approved for a mortgage?',
 *  }
 *  {
 *    icon: 'components/SvgIcons/custom/homeforsale',
 *    question: 'Do you also have a home to sell?',
 *  }
 */

const defaultIcon = <FAIcon icon="home" type="solid" />;

export const QualifyingQuestion = ({ choices, icon = defaultIcon, onSelect, question }) => (
  <div className="at-qq-modal">
    <div className="text-xs--center mb-16">{icon}</div>
    <div className="grid grid--justifyCenter">
      <div className="cell cell-md-9">
        {question && <div className="bt-heading__h2 text-xs--center mb-4 at-qq-question">{question}</div>}
        <div className="cell text-xs--center">
          {choices &&
            choices.map(choice => (
              <SecondaryButton
                key={choice.id || uniqueId()}
                className="mt-12 at-qq-answer"
                dataCY={QUAL_QUESTIONS.ANSWERS}
                title={choice.text}
                width="full"
                onClick={() => {
                  onSelect({ id: choice.id, question, choice: choice.text });
                }}
              >
                {choice.text}
              </SecondaryButton>
            ))}
        </div>
      </div>
    </div>
  </div>
);

QualifyingQuestion.displayName = 'QualifyingQuestion';

QualifyingQuestion.propTypes = {
  icon: PropTypes.element,
  question: PropTypes.string,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      id: PropTypes.number,
    })
  ),
  onSelect: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default compose(
  connect(
    getQuestionProps,
    {
      onDismiss: QualifyingQuestionsActions.dismissQualifyingQuestionsModal,
      onSelect: QualifyingQuestionsActions.selectAnswer,
    }
  ),
  withModal({ maxWidth: 450 })
)(QualifyingQuestion);

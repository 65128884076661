import React from 'react';
import { connect } from 'react-redux';

function IsSplitLayout(props) {
  return props.isMatch ? React.Children.only(props.children) : null;
}

/**
 * Does the current location object correspond to a URL that should show the
 * split layout (SearchBar on top, main view on bottom)?
 * @param {string} location
 */
function matches(location) {
  return !location.result || location.result.id !== 'home';
}

export default connect(state => ({
  isMatch: matches(state.router)
}))(IsSplitLayout);

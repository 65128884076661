import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { Modal, Backdrop, ModalContent } from 'components/Common/MobileXPModal';
import { SecondaryButton, PrimaryButton } from 'coreComponents';
import { H2, H3 } from 'components/core/Headings';
import { pure } from 'recompose';
import { Grid, Cell } from 'components/core/Grid';
import AgentInfo from 'components/Common/Agent/Mobile/MobileAgentInfo';
import { MOBILE_ACCOUNT } from 'cypress_constants';

const ContactAgentAlert = ({
  agentInfo,
  isOpen,
  isSuccess,
  onSuccessClick,
  onBackClick,
  onSubmit,
  onRetryClick
}) => (
  <Modal
    show={isOpen}
    renderBackdrop={() => <Backdrop />}
    backdropStyle={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 40,
      overflowY: 'auto',
      '-webkit-overflow-scrolling': 'touch',
      background: 'rgba(0, 0, 0, 0.6)'
    }}
  >
    <ModalContent>
      <Grid justifyContent="center" alignItems="center">
        <Cell className="text-xs--center">
          {
            isSuccess ? (
              <ContactAgentSuccess agentInfo={agentInfo} onSuccessClick={onSuccessClick} />
            ) : (
              <ContactAgentFailure
                onRetryClick={onRetryClick}
                onBackClick={onBackClick}
                onSubmit={onSubmit}
              />
            )
          }
        </Cell>
      </Grid>
    </ModalContent>
  </Modal>
);

ContactAgentAlert.displayName = 'ContactAgentAlert';
ContactAgentAlert.propTypes = {
  agentInfo: PropTypes.shape({
    agent: PropTypes.object,
    state: PropTypes.oneOf(['valid', 'invalid', 'failed'])
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  theme: PropTypes.object,
  isSuccess: PropTypes.bool,
  onSuccessClick: PropTypes.func,
  onBackClick: PropTypes.func,
  onSubmit: PropTypes.func,
  onRetryClick: PropTypes.func
};

export default compose(
  pure,
)(ContactAgentAlert);

const ContactAgentSuccess = ({ agentInfo, onSuccessClick }) => (
  <div>
    <AgentInfo agentInfo={agentInfo} />
    <H2>Thanks for reaching out!<br />I'll be in touch soon.</H2>
    <PrimaryButton
      className="px-16 at-thankyou-btn"
      dataCY={MOBILE_ACCOUNT.CONTACT_AGENT_MODAL_THANK_YOU}
      onClick={() => onSuccessClick()}
    >
      Thank You!
    </PrimaryButton>
  </div>
);
ContactAgentSuccess.propTypes = {
  agentInfo: PropTypes.object,
  onSuccessClick: PropTypes.func
};

const ContactAgentFailure = ({ onRetryClick, onBackClick, onSubmit }) => (
  <div>
    <H2 className="mb-0">Whoops!</H2>
    <H3>Something went wrong.</H3>
    <Grid gutters>
      <Cell>
        <SecondaryButton
          className="px-16"
          width="full"
          onClick={() => onBackClick()}
        >
          Back
        </SecondaryButton>
      </Cell>
      <Cell xs={7}>
        <PrimaryButton
          className="px-16"
          width="full"
          onClick={e => {
            onRetryClick();
            // TODO: MOVE INTO THE SAGA
            onSubmit(e);
          }}
        >
          Retry Submit
        </PrimaryButton>
      </Cell>
    </Grid>
  </div>
);
ContactAgentFailure.propTypes = {
  onRetryClick: PropTypes.func,
  onBackClick: PropTypes.func,
  onSubmit: PropTypes.func
};

import { createAction as createReduxAction } from 'redux-act';

const createAction = (desc, ...rest) => createReduxAction(`Mobile Contact Agent > ${desc}`, ...rest);

export const submitContactForm = createAction('Submit the contact agent form');

export const receiveContactFormRes = createAction(
  'Receive response from contact agent request',
  response => ({ success: response === 'success' }),
);

export const clickClose = createAction('Click the close button in the header');

export const closeContactFormSuccessModal = createAction('Response Modal > Tap Confirm button');
export const autoCloseContactFormSuccessModal = createAction('Response Modal > Toast timeout');
export const closeContactFormFailureModal = createAction('Response Modal > Tap Back button');
export const retrySubmitContactForm = createAction('Response Modal > Resubmit after failure');

/* eslint-disable */
// [aknox] Feel free to knock this out ^ if you are in here.
import $ from 'jquery';
import _ from 'underscore';
import moment from 'moment-timezone';
import BaseModel from 'legacy/Base/model';
import bt, { utility as utils } from 'BoomTown';
import listingRules from 'legacy/Utility/listingSpecialRules';
import { formatDateTime } from 'utility/dateTime';

export default class Listing extends BaseModel {
  get idAttribute() {
    return '_ID';
  }

  get defaults() {
    return {
      lazy: false,
      BoardAlias: bt.config.boardAlias,
      gtmImpression: false,
    };
  }

  /**
   * Add computed properties to the base model
   *
   * We have also kind of basterdized this approach by adding other
   * models to the listing so that we can render views with handlebars
   */
  beefUp() {
    if (this.has('sellerAd')) {
      return false;
    }

    // We only need to beef listing once
    if (this.has('beef')) {
      return this.attributes;
    }

    this.normalize();

    if (!this.attributes.PhotoMedium && this.attributes.Photos) {
      // Take the first photo from the Photos set and slap it on there
      const firstPhoto = this.attributes.Photos.sort((p1, p2) => p1.Ordinal - p2.Ordinal)[0];
      this.attributes.PhotoMedium = firstPhoto.MediumUrl;
    }

    // CNS-1413 -> weird one, see comments
    if (this.attributes.OfficeName == null) {
      this.attributes.OfficeName = this.attributes.Office.Name;
    }

    if (this.attributes.OfficePhone == null) {
      this.attributes.OfficePhone = this.attributes.Office.Phone;
    }

    // TODO: Previously incorrect; not only subdomain but any agent on $context.
    this.attributes.IsAgentSubdomain = !!bt.agent;

    this.attributes.isRegistered = bt.visitor.isRegistered();

    this.attributes.MLSText = 'MLS #:';
    if (bt.compliance.RestrictMLS != null && bt.compliance.RestrictMLS) {
      this.attributes.MLSText = 'ID#: ';
    }

    // Performed in plugin responder, not model on server (actions/listing.php)
    this.attributes.IsFavorite = this.isFavorite();

    this.attributes.ShortUrl = `${location.protocol}//${location.hostname}/homes/${
      this.attributes._ID
    }`;

    this.attributes.ImgDir = bt.globalVars.ImgDir;

    // Provide human-readable name for "type" of school
    if (this.attributes.Schools != null) {
      this.attributes.Schools.forEach(school => {
        switch (school.Type) {
          case 'E':
            school.TypeName = 'Elementary School';
            return;
          case 'M':
            school.TypeName = 'Middle School';
            return;
          case 'H':
            school.TypeName = 'High School';
            return;
          default:
            return;
        }
      });
    }

    this.attributes.ListPriceFormatted =
      this.attributes.ListPrice != null ? utils.addCommas(this.attributes.ListPrice) : 'N/A';

    // Set Property to IsPending if Status is Pending
    if (this.attributes.StatusCode === 'P') {
      this.attributes.IsPending = true;
    }

    // Set Property to IsSold if Status is Sold
    if (this.attributes.StatusCode === 'S') {
      this.attributes.IsSold = true;
    }

    // https://boomtownroi.atlassian.net/browse/CNS-8626
    this.attributes.SoldSidebarDisclaimer = false;
    if (this.attributes.IsSold && bt.config.soldSidebarDisclaimer) {
      this.attributes.SoldSidebarDisclaimer = true;
    }

    // Format Sold Date
    if (this.attributes.SoldDate != null) {
      const soldDate = formatDateTime(this.attributes.SoldDate, { dateFormat: 'MM/DD/YYYY' });
      this.attributes.SoldDateFormatted = soldDate.date;
    }

    // Format Sold Price
    this.attributes.SoldPriceFormatted =
      this.attributes.SoldPrice != null ? utils.addCommas(this.attributes.SoldPrice) : 'N/A';

    if (!bt.config.allowSoldData && ['S', 'P'].includes(this.attributes.StatusCode)) {
      this.attributes.SashType = 'offmarket';
    }

    if (!bt.rules.get('HideComingSoon') && ['CS'].includes(this.attributes.StatusCode)) {
      this.attributes.SashType = 'comingsoon';
    }

    if (this.attributes.OnMarketDate != null && ['CS'].includes(this.attributes.StatusCode)) {
      const onMarketDate = formatDateTime(this.attributes.OnMarketDate, { dateFormat: 'MM/DD/YYYY' });
      this.attributes.OnMarketDateFormatted = onMarketDate.date;
    }

    this.attributes.ShowDaysOld = true;

    if (!bt.rules.get('ShowDaysOld')) {
      this.attributes.OnSite = null;
      this.attributes.ShowDaysOld = false;
    }

    if (this.attributes.OnSite != null) {
      this.attributes.OnSite = this.attributes.OnSite;
      if (this.attributes.OnSite === 0) {
        this.attributes.OnSiteText = 'Today';
      } else if (this.attributes.OnSite === 1) {
        this.attributes.OnSiteText = `${this.attributes.OnSite} Day`;
      } else {
        this.attributes.OnSiteText = `${this.attributes.OnSite} Days`;
      }
    }

    // if listing is a rental or commercial lease,  hide the mortgage calculator
    if (
      this.attributes.PropertyType._ID &&
      (this.attributes.PropertyType._ID.trim() === 'RN' ||
        this.attributes.PropertyType._ID.trim() === 'CL')
    ) {
      this.attributes.ShowMortgageCalculator = false;
    } else {
      this.attributes.ShowMortgageCalculator = true;
    }

    // CNS-2277 Filter specific SashType to show the appropriate label in Sash
    if (this.attributes.SashType != null) {
      switch (this.attributes.SashType) {
        case 'foreclosure':
          this.attributes.SashText = 'Foreclosure';
          break;
        case 'new':
          if (bt.rules.get('ShowDaysOld')) {
            if (this.attributes.OnSite === 0) {
              this.attributes.SashText = 'New On Site Today';
            } else {
              this.attributes.SashText = `New On Site ${this.attributes.OnSiteText} Ago`;
            }
          } else {
            this.attributes.SashText = 'New';
          }
          break;
        case 'offmarket':
          this.attributes.SashText = 'Off Market';
          break;
        case 'reduced':
          this.attributes.SashText = 'Price Reduced';
          break;
        case 'shortsale':
          this.attributes.SashText = 'Short Sale';
          break;
        case 'undercontract':
          this.attributes.SashText = 'Under Contract';
          break;
        case 'openhouse':
          this.attributes.SashText = 'Open House';
          break;
        case 'virtualopenhouse':
          this.attributes.SashText = 'Virtual Open House';
          break;
        case 'comingsoon':
          this.attributes.SashText = 'Coming Soon';
          break;
        case 'pending':
          this.attributes.SashText = 'Pending';
          break;
        case 'rental':
          this.attributes.SashText = 'Rental';
          break;
        case 'sold':
          if (this.attributes.SoldDateFormatted) {
            this.attributes.SashText = `Sold - ${this.attributes.SoldDateFormatted}`;
          } else {
            this.attributes.SashText = 'Sold';
          }
          break;
        default:
          break;
      }
    }

    this.attributes.CountyText = (state => {
      if (!bt.tenant.isCanadian) {
        return 'County';
      }
      switch (state) {
        case 'ON':
          return 'County';
        default:
          return 'Municipality';
      }
    })(this.attributes.Location.State);

    this.attributes.NeighborhoodText = bt.tenant.isCanadian ? 'Neighbourhood' : 'Neighborhood';

    if (this.attributes.PriceTypeID === 1) {
      // TODO: Should we start rendering the first "$" here as well? We typically do this in the
      // templates, but it might make sense to start adding the "$" as part of the
      // `ListPriceFormatted` in the future.
      this.attributes.ListPriceFormatted = `${utils.addCommas(
        this.attributes.MinPrice
      )} - $${utils.addCommas(this.attributes.MaxPrice)}`;
    }

    const updateAgos = _.values(bt.config.boards);
    const updatedAgo = updateAgos[0].LastUpdatedAgo;
    if (updatedAgo > 240) {
      this.attributes.LastUpdatedAgo = null;
    } else {
      this.attributes.LastUpdatedAgo = updatedAgo;
    }

    // makes mustache handle the {{^AcreageText}} correctly JF
    if (this.attributes.AcreageText === '') {
      this.attributes.AcreageText = null;
    }

    this.attributes.DisableAcreage = bt.rules.get('DisableAcreage');
    // Until the DisableAcreage rule is added we'll need to check to see if it exists
    // Until it does, we'll want to render the default UI
    if (this.attributes.DisableAcreage === null || this.attributes.DisableAcreage === undefined) {
      this.attributes.DisableAcreage = false;
    }

    if (this.attributes.ApproxSqFt != null) {
      this.attributes.ApproxSqFtFormatted = utils.addCommas(this.attributes.ApproxSqFt);
    }

    // Might look like '4000' or '3000-4000'
    if (this.attributes.ApproxSqFtText != null) {
      this.attributes.ApproxSqFtText = this.formatApproxSqFtText(this.attributes.ApproxSqFtText);
    }

    if (this.attributes.DisplayPrice != null && this.attributes.ApproxSqFt != null) {
      if (this.attributes.ApproxSqFt > 0) {
        this.attributes.PricePerSqFt = utils.addCommas(
          Math.round(this.attributes.DisplayPrice / this.attributes.ApproxSqFt)
        );
      } else {
        this.attributes.PricePerSqFt = null;
      }
    } else if (this.attributes.PricePerSqFt != null) {
      // This property comes from the API as a number. Ensure that it's a string.
      this.attributes.PricePerSqFt = this.attributes.PricePerSqFt.toString();
    }

    if (this.attributes.Photos != null) {
      const count = this.attributes.Photos.length;
      this.attributes.PhotoLabel = `${count} ${utils.pluralize('Photo', count)}`;
      this.attributes.PhotoCount = count;
    }

    // JWS: Override coming soon image
    // Make sure we get each image for full deets and snapshot views
    if (
      __guard__(this.attributes.Photos, x1 => x1.length) === 1 &&
      /comingsoon/.test(this.attributes.Photos[0].ThumbUrl)
    ) {
      this.attributes.Photos[0].FullUrl =
      this.attributes.Photos[0].LargeUrl =
      this.attributes.Photos[0].MediumUrl =
      this.attributes.Photos[0].SmallUrl =
      this.attributes.Photos[0].ThumbUrl = '/wp-content/themes/wp-base-theme/assets/media/build/comingsoon-lg.webp';
      this.attributes.PhotoCount = 0;
      this.attributes.PhotoLabel = '';
    }

    // maybe there is a better way to display main thumb. Tomas
    if (this.attributes.Photos != null && this.attributes.Photos.length > 0) {
      this.attributes.MainPhoto = this.attributes.Photos[0];
    }

    if (this.attributes.Location) {
      const loc = this.attributes.Location;
      if (loc.StreetDirSuffix == null) {
        loc.StreetDirSuffix = '';
      }

      // NOTE: update core/listing.php if you change this
      const address = utils.slugify(
        `${loc.StreetNumber} ${loc.StreetDirPrefix} ${loc.StreetName} ${loc.StreetDirSuffix}`
      );

      // For cases when we don't have a City
      let city = '-';
      if (__guard__(loc.CityDetail, x2 => x2.Name) != null) {
        city = utils.slugify(loc.CityDetail.Name);
      }

      // For cases when we don't have a PostalCode
      let zip = '-';
      if (_.has(loc, 'PostalCode')) {
        zip = utils.slugify(loc.PostalCode);
      }

      let slug = `homes/${address}/${city}/${loc.State}/${zip}`;
      if (this.attributes.HideAddress) {
        slug = 'listing';
      }

      this.attributes.UrlPath = `/${slug}/${this.attributes._ID}/`;
      this.attributes.Url = `${window.location.protocol}//${window.location.host}${
        this.attributes.UrlPath
      }`;

      // if we have a unit number and it's digits, prepend a #
      if (loc.UnitNumber != null) {
        if ($.isNumeric(loc.UnitNumber)) {
          loc.UnitNumber = `#${loc.UnitNumber}`;
        }
      }

      const streetName = _.filter(
        _.map([loc.StreetDirPrefix, loc.StreetName, loc.StreetDirSuffix], s => s.trim()),
        s => !!s
      ).join(' ');

      try {
        const addressStr = _.filter(
          _.map([loc.StreetNumber, streetName, loc.UnitNumber], s => s.trim()),
          s => !!s
        ).join(' ');
        this.attributes.Title = `${loc.CityDetail.Name}, ${loc.State} ${zip}`;
        if (!this.attributes.HideAddress) {
          this.attributes.Title = `${addressStr}, ${this.attributes.Title}`;
        }
        if (this.attributes.ListingMLS) {
          this.attributes.Title += bt.compliance.RestrictMLS
            ? ` (#${this.attributes.ListingMLS})`
            : ` (MLS #${this.attributes.ListingMLS})`;
        }
        this.attributes.Title += ` :: ${bt.account.get('CompanyName')}`;
      } catch (error) {
        // shhhh
      }
      if (this.attributes.Location.Coordinates.Latitude != null && !this.attributes.HideAddress) {
        this.attributes.ShowMap = true;
        if (
          this.attributes.Location.Coordinates.Latitude === 0 &&
          this.attributes.Location.Coordinates.Longitude === 0
        ) {
          this.attributes.ShowMap = false;
        }
      }
    }

    // Compliance
    this.attributes.HideLocalPics =
      bt.compliance.HideLocalPics != null ? bt.compliance.HideLocalPics : false;
    if (bt.compliance.HideYearBuilt) {
      this.attributes.YearBuilt = null;
    }

    if (bt.compliance.HideDaysOld) {
      this.attributes.OnSite = false;
    }
    this.attributes.MLSContentOnly = bt.compliance.MLSContentOnly;

    // CNS-2247: Pass in the board ID for boardHTML to set for this particular listing
    this.setBoardHtml(this.attributes.BoardID);
    this.attributes.IsMultiBoard = bt.tenant.IsMultiBoard;

    // Price History
    this.attributes.ShowPriceHistory = false;
    if (this.attributes.PriceHistory != null && this.attributes.PriceHistory.length > 1) {
      this.attributes.ShowPriceHistory = true;
      for (let i = 0; i < this.attributes.PriceHistory.length; i++) {
        const change = this.attributes.PriceHistory[i];
        this.getPriceChange(change);
      }
    }

    // Handle special sash and price formatting for rentals
    if (__guard__(this.attributes.PropertyType, x3 => x3._ID) === 'RN') {
      this.attributes.isRental = true;
      this.attributes.ListPriceFormatted = `${this.attributes.ListPriceFormatted}/mo.`;
      if (this.attributes.SashType === '') {
        this.attributes.SashType = 'rental';
        this.attributes.SashText = 'Rental';
      }
    }

    // Beef up URLs for neighborhood, county, and area
    if (this.attributes.Location != null) {
      if (this.attributes.Location.Neighborhood != null) {
        this.attributes.Location.Neighborhood.Url = utils.getDefaultSearchUrl(
          `hood=${this.attributes.Location.Neighborhood._ID}`
        );
      }
      if (this.attributes.Location.CountyDetail != null) {
        this.attributes.Location.CountyDetail.Url = utils.getDefaultSearchUrl(
          `county=${this.attributes.Location.CountyDetail._ID}`
        );
      }
      if (this.attributes.Location.Area != null) {
        this.attributes.Location.Area.Url = utils.getDefaultSearchUrl(
          `area=${this.attributes.Location.Area._ID}`
        );
      }
    }

    // Hide beds,baths and sqft if VC or no ApproxSqFtText
    if (__guard__(this.attributes.PropertyType, x4 => x4._ID) === 'VC') {
      this.attributes.HideBedsBaths = true;
      // @deprecated
      this.attributes.ShowSQFT = false;
    } else {
      if (window.bt_data.specialRules.ShowSQFT != null) {
        this.attributes.ShowSQFTMinMax = window.bt_data.specialRules.ShowSQFT;
      } else {
        this.attributes.ShowSQFTMinMax = true;
      }

      // @deprecated
      if (__guard__(this.attributes.ApproxSqFtText, x5 => x5.length)) {
        if (this.attributes.ApproxSqFtText === '0') {
          this.attributes.ShowSQFT = false;
        } else {
          this.attributes.ShowSQFT = true;
        }
      }

      // show/hide PricePerSqft
      if (window.bt_data.specialRules.ShowPricePerSqFt != null) {
        this.attributes.ShowPricePerSqFt = window.bt_data.specialRules.ShowPricePerSqFt;
      } else {
        this.attributes.ShowPricePerSqFt = true;
      }

      this.attributes.HideBedsBaths = false;
    }

    // Mimick Schema Types for SEO from server side load in case we only run
    // client side loads in the future.
    if (this.attributes.PropertyType != null) {
      switch (this.attributes.PropertyType.Name) {
        case 'Condo':
          this.attributes.PropertyType.Schema = 'ApartmentComplex';
          break;
        case 'Multi-Family':
          this.attributes.PropertyType.Schema = 'Residence';
          break;
        case 'Single-Family Home':
          this.attributes.PropertyType.Schema = 'SingleFamilyResidence';
          break;
        case 'Manufactured Home':
          this.attributes.PropertyType.Schema = 'Residence';
          break;
        default:
          this.attributes.PropertyType.Schema = 'Place';
          break;
      }
    }
    this.attributes.PropertyType.Name =
      listingRules.shouldShowVillas(bt.tenant.id, this.attributes.PropertyType.Name);

    // Virtual Tour URLs
    if (
      this.attributes.TourUrls != null &&
      typeof this.attributes.TourUrls === 'string' &&
      this.attributes.TourUrls !== ''
    ) {
      this.attributes.TourUrls = this.attributes.TourUrls.split(',');
      this.attributes.TourUrls = this.attributes.TourUrls.map((url, index) => ({
        key: index + 1,
        url,
      }));
    }

    // Inner page logo (weird place, but needs to be attached to the listing -- interesting use case, actually)
    if (bt.config.inner_logo_uri != null) {
      this.attributes.InnerLogoUri = bt.config.inner_logo_uri;
    }

    this.attributes.showPriceTypeOnListing = bt.rules.get('ShowPriceTypeOnListing');

    this.attributes.hasFeatureData = this.attributes.FeatureData != null;
    if (this.attributes.hasFeatureData) {
      // Get number of feature-data "pockets"
      this.attributes.numTypeFeatures = Object.keys(this.attributes.FeatureData).length;

      if (this.attributes.showPriceTypeOnListing) {
        const saleTypeIndex = this.attributes.FeatureData.Property.findIndex(el => el.Name === 'Sale Type');
        if (saleTypeIndex !== -1) {
          this.attributes.FeatureData.Property[saleTypeIndex].Name = 'Price Type';
        }
      }
    }

    this.attributes.hasFeatureText = this.attributes.FeatureText != null;
    if (this.attributes.hasFeatureText) {
      // Set a `ShowFeatureTextOnCard` attribute on bt.compliance if FeatureText comes through for
      // a listing, this means a board requires extra data on the listing cards, so we've added some
      // data from the api for that particular board
      if (!bt.compliance.ShowFeatureTextOnCard) {
        bt.compliance.ShowFeatureTextOnCard = true;
      }

      if (this.attributes.showPriceTypeOnListing) {
        const saleTypeIndex = this.attributes.FeatureText.findIndex(el => el.Name === 'Sale Type');
        if (saleTypeIndex !== -1) {
          this.attributes.FeatureText[saleTypeIndex].Name = 'Price Type';
        }
      }
    }

    // CNS-2635: OffMarket helper attribute
    this.attributes.IsOffMarket = false;
    if (!bt.rules.get('HideComingSoon')) {
      if (this.attributes.StatusCode !== 'A' && this.attributes.StatusCode !== 'AC' && this.attributes.StatusCode !== 'CS') {
        this.attributes.IsOffMarket = true;
      }
    } else if (this.attributes.StatusCode !== 'A' && this.attributes.StatusCode !== 'AC') {
      this.attributes.IsOffMarket = true;
    }

    this.attributes.shouldDisplayAsOffMarket = (StatusCode => {
      const activeStatuses = ['A', 'AC'];
      if (!bt.rules.get('HideComingSoon')) {
        activeStatuses.push('CS');
      }
      if (bt.config.allowSoldData) {
        activeStatuses.push('S', 'P');
      }
      return !activeStatuses.includes(StatusCode);
    })(this.attributes.StatusCode);

    // CNS-3013: Don't allow FormattedAddress through on HideAddress=true properties
    if (this.attributes.HideAddress != null && this.attributes.HideAddress === true) {
      this.attributes.Location.FormattedAddress = `${this.attributes.Location.City}, ${
        this.attributes.Location.State
      } ${this.attributes.Location.PostalCode}`;
    }

    this.attributes.hideListingCardFavoriteBtn = bt.rules.get('HideListingCardFavoriteBtn');

    // CNS-6253: Hides Neighborhood on listing cards for CMLS
    this.attributes.hideListingCardNeighborhood = listingRules.shouldHideNeighborhood(this.get('BoardID'));

    // CNS-6292: Hide half baths for CMLS
    this.attributes.hideHalfBaths = listingRules.shouldHideHalfBaths(this.get('BoardID'));

    // CNS-8116: Show Area on listing details for boardID 38
    this.attributes.showArea = listingRules.shouldShowArea(this.get('BoardID'));

    // CNS-6287: Display BoomTown as Source for BID 30, MLSPIN
    this.attributes.DisplayBtAsDataSource = bt.rules.get('DisplayBtAsDataSource');

    // Compliance to increase disclaimer font size
    this.attributes.increaseDisclaimerFontSize = bt.rules.get('IncreaseDisclaimerFontSize');


    // CNS-7470: If BuyerOfficeName is not set or is empty, or if the property is not sold, then empty SoldThumbCardDisclaimer
    if (!this.attributes.BuyerOfficeName || !this.attributes.IsSold) {
      this.attributes.SoldThumbCardDisclaimer = null;
    }

    this.attributes.hideSchoolsInfoLink = bt.config.hideSchoolsInfoLink;

    this.attributes.beef = true;
    return this.attributes;
  }

  isFavorite() {
    return bt.favorites.get(this.id) != null;
  }

  normalize() {
    if (this.attributes.Office != null) {
      if (__guard__(this.attributes.Office, x => x.Phone) == null) {
        this.attributes.Office.Phone = '';
      }
      if (__guard__(this.attributes.Office, x1 => x1.Name) == null) {
        this.attributes.Office.Name = '';
      }
    } else {
      this.attributes.Office = { Name: '', Phone: '' };
      if (this.attributes.OfficeName != null) {
        this.attributes.Office.Name = this.attributes.OfficeName;
      }
      if (this.attributes.OfficePhone != null) {
        this.attributes.Office.Phone = this.attributes.OfficePhone;
      }
    }

    if (this.attributes.Agent != null) {
      if (__guard__(this.attributes.Agent, x2 => x2.FirstName) == null) {
        this.attributes.Agent.FirstName = '';
      }
      if (__guard__(this.attributes.Agent, x3 => x3.LastName) == null) {
        this.attributes.Agent.LastName = '';
      }
    } else {
      this.attributes.Agent = { FirstName: '', LastName: '' };
      if (this.attributes.AgentName != null) {
        const names = this.attributes.AgentName.split(' ');
        this.attributes.Agent.FirstName = names[0];
        this.attributes.Agent.LastName = names[1];
      }
    }

    // CNS-1777 workaround for AgentPhone/Agent.DirectPhone API shape discrepancies
    if (__guard__(this.attributes.Agent, x4 => x4.DirectPhone) != null) {
      this.attributes.AgentPhone = this.attributes.Agent.DirectPhone;
    }

    // CNS-2333: set AgentDRE number on the listing obj
    if (!this.attributes.AgentDRE) {
      if (__guard__(this.attributes.Agent, x5 => x5.DRENumber) != null) {
        this.attributes.AgentDRE = this.attributes.Agent.DRENumber;
      }
    }
  }

  /**
   * "Render" all board HTML templates and add them as attributes.
   * @param {*} boardId
   */
  setBoardHtml(boardId) {
    const args = {
      LISTINGADDRESSFULL: '',
      COAGENTS: [],
      YEAR: new Date().getFullYear(),
      SOLDAGENTNAME: '',
      SOLDOFFICENAME: '',
    };

    // CNS-2377: New replacements for [ASSOCNAME] and [MLSNAME]
    if (this.attributes.AssociationName != null) {
      args.ASSOCNAME = this.attributes.AssociationName;
    }
    if (this.attributes.MlsName != null) {
      args.MLSNAME = this.attributes.MlsName;
    }
    if (this.attributes.Agent != null) {
      args.AGENTNAME = this.attributes.Agent.FirstName + ' ' + this.attributes.Agent.LastName;
    }
    // CNS-4183: Sold data replacements
    if (this.attributes.BuyerAgentName != null) {
      args.SOLDAGENTNAME = this.attributes.BuyerAgentName;
    }
    if (this.attributes.BuyerOfficeName != null) {
      args.SOLDOFFICENAME = this.attributes.BuyerOfficeName;
    }

    if (this.attributes.Location.FormattedAddress != null) {
      args.LISTINGADDRESSFULL = this.attributes.Location.FormattedAddress;
    }

    // CNS-8526
    args.PROPERTYTYPE = '';
    const isResidential = ['Manufactured Home', 'Single-Family Home'];
    if (this.attributes.PropertyType.Name !== null) {
      if (isResidential.includes(this.attributes.PropertyType.Name)) {
        args.PROPERTYTYPE = 'Residential';
      } else {
        args.PROPERTYTYPE = this.attributes.PropertyType.Name;
      }
    }

    // CRM-18472 MERGE FIELDS FIX
    for (const key in this.attributes) {
      const value = this.attributes[key];
      const argKey = key.toUpperCase();
      if (!args[argKey]) {
        if (typeof value === 'string') {
          args[argKey] = value;
        } else if (typeof value === 'number') {
          args[argKey] = String(value);
        } else {
          args[argKey] = '';
        }
      }
    }

    // CNS-2247: If we have the bt.config.boardHTMLs object (all sites should
    // with listing sets), set up the board HTML based on the board attached to
    // the listing, otherwise just get the global boardHtml (which should be
    // deprecated, probably)
    const activeBoardHtml = bt.tenant.IsMultiBoard
      ? bt.config.boardHTMLs[boardId]
      : bt.config.boardHtml;

    if (activeBoardHtml) {
      Object.keys(activeBoardHtml).forEach(key => {
        this.set(key, utils.getReplacedDisclaimer(activeBoardHtml[key], args), { silent: true });
      });
    }
  }

  getPriceChange(change) {
    // I don't believe there is a way to request 'days' using Moment's `from()`.
    const diffInDays = moment().diff(moment(change.Date), 'days');
    change.Ago = `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
    change.FormattedDate = utils.dateFormat(change.Date, 'MMM D, YYYY');
    change.Percent = 0;
    if (change.Type === 'New Listing') {
      change.Type = 'New on market';
      // CNS-2478: Stub in "New Price" with a formatted version of the original price for new listings
      change.NewPrice = utils.addCommas(change.OriginalPrice);
    } else if (change.NewPrice != null && (change.NewPrice > 0 && change.OriginalPrice > 0)) {
      const oldPrice = parseFloat(change.OriginalPrice);
      const newPrice = parseFloat(change.NewPrice);
      let result = (oldPrice - newPrice) / oldPrice;
      result = utils.round(result * -100.0, 2);
      if (result > 0) {
        change.PriceUp = true;
      } else {
        change.PriceDown = true;
      }

      change.Percent = result;
      change.PriceDiff = utils.addCommas(change.PriceDiff);
      change.NewPrice = utils.addCommas(change.NewPrice);
    }

    return this.change;
  }


  /**
   * Add commas if it looks like a simple number
   * Ranges don't get numbers currently
   *
   * @param {string} sqftText
   */
  formatApproxSqFtText(sqftText) {
    const rangeRegx = /^([-+]?[0-9]*\.?[0-9]+)\s*-\s*([-+]?[0-9]*\.?[0-9]+)$/;
    const digitsRegex = /^[0-9]*$/;
    // add commas unless it's a range, e.g 3000-5000
    if (__guard__(sqftText, x => x.match(digitsRegex)) &&
      !sqftText.match(rangeRegx)) {
      return utils.addCommas(sqftText);
    }

    return sqftText;
  }
}

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
}

import { combineReducers } from 'redux';

// global (non-ui)
import { routerComponents } from 'getRouterComponents';
import locationChanges from 'reducers/locationChanges';
import { reducer as cachedHtml } from 'htmlCache';
import optimize from 'reducers/optimize';
import browser from 'reducers/browser';

// Models
import geolocation from 'services/geolocation/reducer';
import lastSearch from 'reducers/lastSearch';
import visitor from 'models/visitor/reducers';
import search from 'models/search/reducers';
import modals from 'models/modals/reducers';

// Static
import menuItems from 'reducers/menuItems';
import searchFields from 'reducers/searchFields';
import account from 'models/account/reducers';
import specialRules from 'models/specialRules/reducers';

// Screens
import accountView from 'screens/Account/reducer';
import bbModal from 'services/bbModal/bbModalReducer';
import compare from 'reducers/compare';
import favorites from 'reducers/favorites';
import gallery from 'reducers/gallery';
import mobileContactAgent from 'reducers/Mobile/contactAgent';
import mobileDetailsPage from 'reducers/Mobile/detailsPage';
import mobileGallery from 'reducers/Mobile/gallery';
import mobileSaveSearch from 'reducers/Mobile/saveSearch';
import offCanvasSearch from 'reducers/OffCanvasSearch';
import resultsMap from 'screens/ResultsMap/reducer';
import siteMenu from 'screens/MobileMenu/reducer';

// Other
import agentForContact from 'components/MobileContactAgent/reducer';
import ballerbox from 'components/BallerBox/reducer';
import desktopSaveSearch from 'reducers/desktopSaveSearch';
import homeValFinder from 'components/HomepageHero/HomeValFinder/reducer';
import qualifyingQuestions from 'reducers/qualifyingQuestions';
import startAnOffer from 'reducers/startAnOffer';

import accountDesktopContactAgent from 'screens/Account/Desktop/ContactAgent/reducer';
import map from 'services/mapParams/reducer';

const { reducer: router } = routerComponents;

export default combineReducers({
  // global (non-ui)
  browser,
  cachedHtml,
  locationChanges,
  optimize,
  router,

  // Models
  geolocation,
  lastSearch,
  visitor,
  account,
  search,
  modals,

  // Static
  menuItems,
  searchFields,
  specialRules,

  // Screens
  accountView,
  bbModal,
  compare,
  favorites,
  gallery,
  mobileContactAgent,
  mobileDetailsPage,
  mobileGallery,
  mobileSaveSearch,
  offCanvasSearch,
  resultsMap,
  siteMenu,

  // other
  agentForContact,
  ballerbox,
  desktopSaveSearch,
  homeValFinder,
  qualifyingQuestions,
  startAnOffer,

  // All UI state for desktop views
  desktop: combineReducers({
    map,
    account: combineReducers({
      contactAgent: accountDesktopContactAgent,
    }),
  }),
});

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { formatResultCount } from 'utility';
import { PrimaryButton } from 'coreComponents';

/**
 * "Submit" button for the off-canvas search menu. Shows the number of results
 * given current search params. Click closes off-canvas and reveals the
 * current results view.
 */
export default class ResultsButton extends Component {
  // Format the button text based on the count
  get resultsText() {
    const { isPlural, displayCount } = formatResultCount(this.props.count);
    const suffix = isPlural ? 'Results' : 'Result';
    return `${displayCount} ${suffix}`;
  }

  render() {
    return (
      <PrimaryButton
        onClick={this.props.onClick}
        width="full"
        className="bt-filter__header-button--off-canvas"
        showSpinner={this.props.isFetching}
      >
        {this.resultsText}
      </PrimaryButton>
    );
  }
}

ResultsButton.displayName = 'OffCanvasResultsButton';
ResultsButton.propTypes = {
  count: PropTypes.number.isRequired,
  isFetching: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

// CNS-7081 - bt.rules cannot be imported in this scope.
// Instead of modifying the bundle, just use 'bt_data' object
export function hideComingSoon() {
  return !!window.bt_data?.specialRules?.HideComingSoon;
}

/**
 * One of the two possible base searches that the user can edit before saving
 */
export const BASE_SEARCH = {
  PENDING: 'PENDING',
  COMMITTED: 'COMMITTED',
};

export const PENDING_BRANCH = 'pending';

// CNS-7081
export const DEFAULT_SEARCH_STRING = (function () {
  if (hideComingSoon()) {
    return '?photo=1&sort=importdate&status=A';
  }
  return '?photo=1&sort=importdate&status=A,CS';
}());

// CNS-7081
export const defaultSearch = (function () {
  const defaults = {
    sort: 'importdate',
    status: hideComingSoon() ? 'A' : 'A,CS',
    photo: '1',
  };

  return defaults;
}());

/**
 * Note: not enforced
 */
export const whitelist = [
  'area',
  'boundingbox',
  'city',
  'county',
  'custom',
  'feature',
  'featurenot',
  'featureor',
  'hood',
  'latlonrad',
  'maxacres',
  // Represents half and full added together
  'maxbaths',
  'maxbeds',
  'maxdayslisted',
  'maxfullbaths',
  'maxgarages',
  'maxhalfbaths',
  'maxprice',
  'maxsqft',
  'maxstories',
  'maxyear',
  'minacres',
  'minbaths',
  'minbeds',
  'minfullbaths',
  'mingarages',
  'minhalfbaths',
  'minprice',
  'minsqft',
  'minstories',
  'minyear',
  'photo',
  'polygon',
  'postalcode',
  'pricereduced',
  'proptype',
  'school',
  'schooldistrict',
  'sort',
  'status',
  'tours',
];

export const locationParams = [
  'area',
  'city',
  'citylike',
  'county',
  'custom',
  'hood',
  'keyword',
  'neighborhood',
  'OfficeID',
  'OfficeMLS',
  'postalcode',
  'rid',
  'school',
  'street',
  'streetname',
  'UserID',
];

export const allowedMultipleFields = [
  'area',
  'city',
  'county',
  'custom',
  'feature',
  'featurenot',
  'featureor',
  'hood',
  'postalcode',
  'proptype',
  'school',
  'schooldistrict',
  'status',
];

export const socialLoginParams = [
  'email',
  'first_name',
  'last_name',
  'fb_register',
  'error_reason',
];

export const coordinateFilters = ['swlat', 'swlng', 'nelat', 'nelng', 'midlat', 'midlng', 'zoom'];

/**
 * These properties should have capitalized values
 * @type {Array.<string>}
 */
export const CAP_ID_PARAMS = ['proptype', 'status', 'feature', 'featureor', 'featurenot'];

/**
 * The search param added to the URL when the mobile Filter Menu is open. Does not get a value
 * assigned to it, it simply equals `null`.
 * @type {string}
 */
export const SEARCH_MENU_QUERY = 'search';

export const SALE_TYPES = (function () {
  const types = {
    STANDARD: 'FS',
    FORECLOSURE: 'BO',
    SHORT_SALE: 'SS',
    UNDER_CONTRACT: 'AC',
    SOLD: 'S',
    ACTIVE: 'A',
    PENDING: 'P',
  };

  if (!hideComingSoon()) {
    types.COMING_SOON = 'CS';
  }

  return types;
}());

export const NEGATED_SALE_TYPES = {
  FORECLOSURE: 'NBO',
  SHORT_SALE: 'NSS',
};

export const PROPERTY_TYPES = {
  HOMES: 'SF',
  TOWNHOMES: 'VT',
  LOTS_AND_LAND: 'VC',
  CONDO: 'C',
  BOAT_SLIP: 'B',
  MULTI_FAMILY_HOME: 'MF',
};

export const MIN_BATHS_OPTIONS = {
  W_HALF_BATHS: [0, 1, 1.5, 2, 2.5, 3, 3.5, 4, 5],
  WO_HALF_BATHS: [0, 1, 2, 3, 4, 5],
};

export const utmParams = [
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
];

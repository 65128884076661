import PropTypes from 'prop-types';
import React from 'react';
import { Radio, SecondaryButton } from 'coreComponents';
import { CCompDropdown } from 'components/Common/Dropdown';
import FAIcon from 'components/core/FAIcon';
import { SEARCH_BAR } from 'cypress_constants';

const SortResults = (props) => {
  const { sortOptions, onChange } = props;

  return (
    <CCompDropdown
      className="mr-12 sort-button"
      align="right"
      trigger={
        <SecondaryButton size="small" className="bt-sort-by" dataCY={SEARCH_BAR.SORT}>
          Sort <FAIcon icon="angle-down" type="regular" />
        </SecondaryButton>
      }
      content={
        <form>
          <ul className="uk-list uk-list-space m-0">
            {sortOptions.map((o) => {
              const uid = `header-sort-${o.value}`;

              return (
                <li key={uid}>
                  <Radio
                    label={o.label}
                    id={uid}
                    type="radio"
                    name="sort"
                    value={o.value}
                    checked={o.checked}
                    dataCY={o.cySelector}
                    labelProps={{ 'data-cy': o.cyLabelSelector }}
                    onChange={() => onChange(o.value)}
                  />
                </li>
              );
            })}
          </ul>
        </form>
      }
    />
  );
};

SortResults.propTypes = {
  sortOptions: PropTypes.array,
  onChange: PropTypes.func,
};

SortResults.defaultProps = {
  sortOptions: [],
  onChange: () => {},
};

export default SortResults;

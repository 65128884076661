import { sliceFactory } from 'reducers/util';
import * as OffCanvasActions from 'actions/OffCanvasActions';
import { SearchConverter } from 'models/search/services';
import { PENDING_BRANCH } from '../../../constants';

/**
 * CASE FUNCTIONS
 *
 * Below are functions that can be reused to update specific `search.pending` values in
 * our state tree
 */

/**
 * SEARCH TAG REDUCERS
 *
 * A set of Reducers for actions dispatched by interacting with search tags
 */
export default {
  /* REMOVE SEARCH TAG */
  [OffCanvasActions.removeSearchTerm]: sliceFactory(
    PENDING_BRANCH,
    SearchConverter.removePendingSearchValue
  ),
};

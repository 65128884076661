import $ from 'jquery';
import UIkit from 'uikit';
import { View } from 'backbone';
import Flickity from 'flickity-bg-lazyload';

export default class FlickityView extends View {
  initialize() {
    this.small = 480;
    this.medium = 768;
    this.large = 960;
    this.cardsVisible = 1;

    this.cards = this.$('.bt-card-wrapper').length;
    this.slider = new Flickity(this.el, {
      cellSelector: '.bt-card-wrapper',
      cellAlign: 'left',
      contain: true,
      pageDots: false,
      imagesLoaded: true
    });

    this.slider.on('cellSelect', () => {
      if (this.slider.selectedIndex >= this.slider.cells.length - this.cardsVisible) {
        this.slider.nextButton.isEnabled = false;
        $(this.slider.nextButton.element).prop('disabled', true);
      } else {
        this.slider.nextButton.isEnabled = true;
        $(this.slider.nextButton.element).prop('disabled', false);
      }
    });

    this.debouncedWatcher = UIkit.Utils.debounce(() => this.watchWindowResize(), 100);
    $(window).on(
      'load resize orientationchange',
      this.debouncedWatcher
    );
  }

  remove() {
    this.slider.destroy();
    $(window).off('load resize orientationchange', this.debouncedWatcher);
    super.remove();
  }

  watchWindowResize() {
    const winWidth = this.$el.parent().width();
    this.cardsVisible = 1;
    if (winWidth > this.small) {
      this.cardsVisible = 2;
    }
    if (winWidth > this.medium) {
      this.cardsVisible = 3;
    }
    if (winWidth > this.large) {
      this.cardsVisible = 4;
    }

    if (this.cards > this.cardsVisible) {
      this.$el.removeClass('bt-card-slider__prev-next--hide');
    } else {
      this.$el.addClass('bt-card-slider__prev-next--hide');
    }
  }
}

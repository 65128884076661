import { compose } from 'recompose';
import { connect } from 'react-redux';
import { connectBBHoc } from 'hoc/connectBB';
import { visitor as visitorModel } from 'BoomTown';
import { getVisitorData, getSavedSearchCount } from 'models/visitor/reducers/selectors';
import * as a from './actions';

export default compose(
  connectBBHoc(
    // listeners
    [
      {
        getEmitter: () => visitorModel,
        events: [
          {
            name: 'change',
            replaceProps: (prevProps, model) => ({ visitor: getVisitorData(model) }),
          },
        ],
      },
    ],
    // initial state
    () => ({
      visitor: getVisitorData(visitorModel)
    })
  ),

  // eslint-disable-next-line
  connect(
    (state, ownProps) => ({
      visitor: {
        ...ownProps.visitor,
        savedSearchCount: getSavedSearchCount(state),
      },
    }),
    {
      clickClose: a.clickClose,
      clickSignInButton: a.clickSignInButton,
      clickSignInLink: a.clickSignInLink,
      clickSearchMenuLink: a.clickSearchMenuLink,
      clickSignOutLink: a.clickSignOutLink,
      gravatarError: a.gravatarError,
      clickSiteMenuLink: a.clickSiteMenuLink,
    }
  )
);

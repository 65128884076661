import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { compliance } from 'BoomTown';
import cx from 'classnames';
import { Link } from 'redux-little-router';
import FAIcon from 'components/core/FAIcon';

/**
 * Renders list items for use in LinkList
 *
 * @export
 * @component LinkListItem
 */
export const LinkListItem = (props) => {
  const {
    children,
    disabled = false,
    href = '#',
    icon,
    dataCY,
    isRoute = true,
    onClick,
    className,
    currentRoute = null,
  } = props;

  const [isLoadingRoute, setIsLoadingRoute] = useState(false);

  const targetAttrs = {
    href,
    className: 'py-12 link-list__target',
    onClick: () => setIsLoadingRoute(!currentRoute || currentRoute !== href),
  };

  if (disabled) {
    return (
      <li className={cx('link-list__item', className)}>
        <div className="py-12 link-list__target link-list__target--disabled" data-cy={dataCY}>
          <FAIcon
            icon="lock"
            type="solid"
            dataCY={dataCY}
            className="link-list__icon link-list__icon--disabled"
          />
          <div className="uk-text-truncate">{children}</div>
        </div>
      </li>
    );
  }

  if (isRoute && !compliance.SuperAggroMode) {
    return (
      <li className={cx('link-list__item', className)}>
        <Link
          {...targetAttrs}
          activeProps={{ className: 'py-12 link-list__target link-list__target--active' }}
          data-cy={dataCY}
        >
          <LinkListIcon icon={icon} data-cy={dataCY} isLoadingRoute={isLoadingRoute} />
          <div className="uk-text-truncate">{children}</div>
        </Link>
      </li>
    );
  }

  // If we have a click handler on a link we'll go ahead and prevent default
  // This means this link isn't a route/url
  targetAttrs.onClick = (e) => {
    if (onClick !== undefined) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <li className={cx('link-list__item', className)}>
      <a {...targetAttrs} data-cy={dataCY}>
        <LinkListIcon icon={icon} data-cy={dataCY} isLoadingRoute={isLoadingRoute} />
        <div className="uk-text-truncate">{children}</div>
      </a>
    </li>
  );
};

LinkListItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  currentRoute: PropTypes.string,
  dataCY: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.string,
  isRoute: PropTypes.bool,
  onClick: PropTypes.func,
};

/**
 * Renders an icon for use inside a LinkListItem
 *
 * @export
 * @component LinkListIcon
 */
function LinkListIcon(props) {
  const { dataCY, icon, isLoadingRoute } = props;

  if (isLoadingRoute) {
    return <FAIcon icon="spinner" type="solid" animation="spin" className="link-list__icon link-list__icon--loading" />;
  }

  if (icon !== undefined) {
    return <FAIcon icon={icon} type="solid" dataCY={dataCY} className="link-list__icon" />;
  }

  return null;
}

LinkListIcon.propTypes = {
  dataCY: PropTypes.string,
  icon: PropTypes.string,
  isLoadingRoute: PropTypes.bool.isRequired,
};

/**
 * Renders a list of links for the mobile nav menu
 *
 * @export
 * @component LinkList
 */
export const LinkList = (props) => {
  const { className, ...rest } = props;

  return <ul className={cx('uk-list', className)} {...rest} />;
};

LinkList.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

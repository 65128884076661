import { bootOptimize } from 'actions/OptimizeActions';

/**
 * Initialize some optimize-related functionality.
 * Used when initializing our store object.
 *
 * @param {Object} store The global Redux store
 */
export default function initOptimize(store) {
  /**
   * Expose a function for google optimize code that dispatches an optimize boot
   * action
   *
   * @param {Object} variants An object with string keys for which feature flag
   * is being modulated, and values defining the chosen variant
   */
  window.bt.bootOptimize = variants => store.dispatch(bootOptimize(variants));

  // Tell optimize that we are ready for them
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({ event: 'optimize.activate' });
  }
}

/* eslint-disable import/prefer-default-export, spaced-comment */
import { createAction as _createAction } from 'redux-act';

const createAction = (desc, ...args) => _createAction(`SearchMenu > ${desc}`, ...args);

/****************** ToolBar Actions  *******************************/
export const selectMinBeds = createAction(
  'Select a min beds value',
  /** @param {number} n 0 passed to clear the filter */
  n => n
);

export const selectMinBaths = createAction(
  'Select a min baths value',
  /** @param {number} n 0 passed to clear the filter */
  n => n
);

export const selectPriceRange = createAction(
  'Select a price range',
  /** @param {{ minprice: number, maxprice: number }} x */
  x => x
);

/**
 * When the user selects any of our multiple choice <select> dropdowns, e.g.
 * Year Built
 */
export const selectSelectOption = createAction(
  'Select a <select> search term',
  /** @param {{ field: string, value: any }} x */
  x => x
);

export const clickHasPhotos = createAction('Click the "Has Photos" checkbox', () => {});

export const clickVirtualTours = createAction('Click the "Virtual Tours" checkbox', () => {});

export const clickFeature = createAction(
  'Click a feature checkbox',
  /** @param {String} x The ID of the feature */
  x => x
);

export const clickPropertyType = createAction(
  'Click a property type checkbox',
  /** @param {String} x The ID of the feature */
  x => x
);

export const clickStatus = createAction(
  'Click a status checkbox',
  /** @param {String} x The ID of the feature */
  x => x
);

export const clickSaleType = createAction(
  'Click a sale type checkbox',
  /** @param {SaleTypeID} id */
  id => id
);

import PropTypes from 'prop-types';
import React from 'react';

export default function MobileResultsTitle({ title }) {
  return (
    <div className="bt-results__num-sort uk-visible-small">
      <h1 className="m-0 bt-text-normal bt-heading__h4">
        {title}
      </h1>
    </div>
  );
}

MobileResultsTitle.propTypes = {
  title: PropTypes.string
};

import { createReducer } from 'redux-act';
import { LOCATION_CHANGED } from 'redux-little-router';
import { visitor } from 'BoomTown';
import { multiCase } from 'reducers/util';

// Actions
import * as desktopSAO from 'actions/StartAnOfferActions';
import * as mobSAO from 'actions/mobileStartAnOffer';

// REFACTOR: Once we get the visitor model in redux
// then i guess this would get moved to a thunk? or maybe just
// react state
function getVisitorDetailsFromBB(visitorModel) {
  const first = getNotDefault('FirstName', 'Visitor');
  const last = getNotDefault('LastName', 'Visitor');

  return {
    fullName: {
      value: first && last ? `${first} ${last}` : '',
      touched: false,
    },
    email: {
      value: visitorModel.get('Username') || '',
      touched: false,
    },
    phone: {
      value: visitorModel.get('BestPhone'),
      touched: false,
    },
  };

  function getNotDefault(prop, def) {
    const val = visitorModel.get(prop);
    if (val && val !== def) {
      return val;
    }
    return '';
  }
}

const initialState = {
  isOpen: false,
  listingId: null,
  listPrice: null,
  address: null,
  isLoading: true,
  // Form Fields
  errors: [],
  ...getVisitorDetailsFromBB(visitor),
  offerAmount: {
    value: null,
    touched: false,
  },
  helpWithFinancing: {
    value: '',
    touched: false,
    options: ['Yes', 'No', "I'm buying with cash"],
  },
  hasViewedProperty: {
    value: '',
    touched: false,
    options: ['Yes', 'No', 'I would like to go see it'],
  },
  comments: {
    value: '',
    touched: false,
  },

  // API State
  submitState: '', // '' | 'error' | 'success'
  isSubmitting: false,
};

export default createReducer(
  {
    // Since visitor details are pulled in on a "true" reset we are just ensuring the modal
    // is closed instead of actually resetting to avoid over-checking state changes
    [LOCATION_CHANGED]: prevState => ({
      ...prevState,
      isOpen: false,
    }),

    ...multiCase(
      [mobSAO.handleButtonClick, desktopSAO.showStartAnOfferForm],
      (prevState, { listingId, listPrice, address }) => ({
        // Reset initial state
        ...initialState,
        ...getVisitorDetailsFromBB(visitor),

        // Apply payload
        isOpen: true,
        listingId,
        listPrice,
        isLoading: false,
        offerAmount: {
          ...initialState.offerAmount,
          value: listPrice,
        },
        helpWithFinancing: {
          ...initialState.helpWithFinancing,
          value: '',
        },
        hasViewedProperty: {
          ...initialState.hasViewedProperty,
          value: '',
        },
        comments: {
          ...initialState.comments,
          value: `I would like to start an offer on ${address}.`,
        },
      })
    ),
    ...multiCase([mobSAO.handleModalClose, desktopSAO.hideStartAnOfferForm], () => ({
      ...initialState,
    })),
    ...multiCase([mobSAO.handleFieldChange, desktopSAO.onChange], (state, { field, value }) => ({
      ...state,
      [field]: {
        ...state[field],
        touched: true,
        value,
      },
    })),
    ...multiCase([mobSAO.onSubmit, desktopSAO.onSubmit], state => ({
      ...state,
      isSubmitting: true,
      errors: [],
      submitState: '',
    })),
    ...multiCase([mobSAO.handleErrors, desktopSAO.handleErrors], (state, errors) => ({
      ...state,
      isSubmitting: false,
      errors,
    })),

    // Desktop pops a toast in the thunk
    [desktopSAO.onSuccess]: state => ({
      ...state,
      isOpen: false,
      isSubmitting: false,
      submitState: 'success',
    }),

    // On mobile we don't close the modal
    [mobSAO.onSuccess]: state => ({
      ...state,
      isSubmitting: false,
      submitState: 'success',
    }),
    [mobSAO.toastTimeout]: state => ({ ...state, isOpen: false }),

    ...multiCase([mobSAO.onSubmitError, desktopSAO.onSubmitError], state => ({
      ...state,
      isSubmitting: false,
      submitState: 'error',
    })),
  },
  initialState
);

import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import qs from 'querystring';
import { DETAILS } from 'routes';
import { firebase } from 'BoomTown';
import { getRouterState } from 'selectors/router';
import { getLastSearchState } from 'reducers/lastSearch';
import { showStartAnOffer } from 'selectors/listings';
import { getListingFromStore } from 'selectors/mobileDetails';
import { getIsRegistered } from 'models/visitor/reducers/selectors';
import getAccount from 'models/account/reducers/selectors';
import { getValueFromConfig } from 'models/appConfig/selectors';
import { getSpecialRules } from 'models/specialRules/reducers/selectors';
import MobileAppDownloadHeader from './component';
/**
 * A memoized selector function to ensure that we only calculate new values for `linkParams` when
 * the input values (specific state) have changed. This will also ensure that if the input values
 * haven't changed, `mapStateToProps` will still return the same result values as before, and
 * `connect` can skip re-rendering.
 *
 * @see https://react-redux.js.org/using-react-redux/connect-mapstate#only-return-new-object-references-if-needed
 * @see https://redux.js.org/recipes/computing-derived-data#creating-a-memoized-selector
 * @returns {Function} A memoized selector function that takes `state` as a parameter
 */
const getLinkParams = createSelector(
  [getListingFromStore, getLastSearchState],
  (listing, lastSearchState) => ({
    showStartAnOffer: showStartAnOffer(listing),
    lastSearchState: qs.encode(lastSearchState),
  })
);

const isBannerEnabled = (state) => {
  // Now we can mock config.isCrawler for a test case
  if (getValueFromConfig('isCrawler')) {
    return false;
  }

  // We can mock state to test each of these conditional branches
  if (getSpecialRules(state)?.HideHomeSearchNowBanner) {
    return false;
  }


  if (getIsRegistered(state) && getValueFromConfig('enableAppBanner')) {
    return true;
  }

  return false;
};

export { isBannerEnabled };

export default connect((state) => {
  let linkParams;
  const { pathname, query: searchQuery, result } = getRouterState(state);
  // We need to add some extra parameters if we're on the details page
  if (result && result.id === DETAILS) {
    linkParams = getLinkParams(state);
  }

  const { TenantID } = getAccount(state);

  const queryParams = {
    ...searchQuery,
    ...linkParams,
    vid: firebase.vid,
    tid: TenantID,
  };

  const bannerEnabled = isBannerEnabled(state);

  return {
    pathname,
    bannerEnabled,
    queryParams,
  };
})(MobileAppDownloadHeader);

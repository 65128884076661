export default [
  'anal',
  'anus',
  'ass',
  'assfucker',
  'asshole',
  'assholes',
  'ballsack',
  'bastard',
  'biatch',
  'bitch',
  'blowjob',
  'bullshit',
  'buttfuck',
  'cocksucker',
  'cunt',
  'damn',
  'damnit',
  'fag',
  'faggot',
  'faggots',
  'fuck',
  'fucker',
  'fuckhole',
  'fucking',
  'fuckk',
  'fuckme',
  'fuckoff',
  'fuckyou',
  'fuckyourself',
  'gofuck',
  'jigaboo',
  'jiggaboo',
  'motherfucker',
  'nigga',
  'niggah',
  'niggaz',
  'nigger',
  'penis',
  'piss',
  'pissed',
  'pissoff',
  'pussy',
  'retard',
  'shit',
  'slut',
  'tits',
  'whore',
];

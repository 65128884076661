/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-act';

export const changeMobileViewport = createAction(
  'Browser viewport size crosses our "is mobile" threshold',
  isMobile => isMobile
);

export const changeXLargeViewport = createAction(
  'Browser viewport size crosses our "xlarge" threshold',
  isXLargeDevice => isXLargeDevice
);

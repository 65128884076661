import { Collection } from 'backbone';

export default class BaseCollection extends Collection {
  initialize() {
    this.on('add', (model, collection, options) =>
      window.bt.events.trigger('add', model, collection, options)
    );

    this.on('remove', (model, collection, options) =>
      window.bt.events.trigger('remove', model, collection, options)
    );

    this.on('reset', (collection, options) =>
      window.bt.events.trigger('reset', collection, options)
    );

    this.on('done', collection => window.bt.events.trigger('done', collection));
  }

  log(msg) {
    window.bt.events.trigger('log', msg);
  }

  slog(msg, css) {
    window.bt.events.trigger('chalk', msg, css);
  }
}

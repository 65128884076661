/**
 * Promised based scrollIntoView( { behavior: 'smooth' } )
 * @param  { Element } elem      An Element on which we'll call scrollIntoView
 * @param  { object }  [options] An optional scrollIntoViewOptions dictionary
 * @return { Promise } (void)    Resolves when the scrolling ends
 */
export default function scrollToElement(elem, options) {
  return new Promise((resolve) => {
    if (!(elem instanceof Element)) {
      throw new TypeError('Argument 1 must be an Element');
    }

    let same = 0; // a counter
    let lastPos = null; // last known Y position

    // Pass the user defined options along with our default
    const scrollOptions = {
      behavior: 'smooth',
      ...options,
    };

    elem.scrollIntoView(scrollOptions);
    requestAnimationFrame(check);

    // this function will be called every painting frame
    // for the duration of the smooth scroll operation
    /* eslint-disable consistent-return */
    function check() {
      // check our current position
      const newPos = elem.getBoundingClientRect().top;

      // resolve promise if 3 consecutive frames are equal
      if (newPos === lastPos) {
        if (same++ > 2) { // multiple frame checks serve as buffer
          return resolve();
        }
      } else {
        same = 0; // reset our counter
        lastPos = newPos; // remember our current position
      }
      // recursively call until promise resolves
      requestAnimationFrame(check);
    }
  });
}

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"top-level-error\">\n  <div class=\"alert alert--small uk-hidden js-alert-messages-container\"></div>\n</div>\n<div class=\"bt-heading__h2 text-xs--center\">Unable to Log In?</div>\n<div class=\"text-xs--center mb-16\">Please enter the email address you registered with and we'll send you a link to reset your password.</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "<div class=\"bt-heading__h2 text-xs--center\">Unable to login?</div>\n<div class=\"text-xs--center mb-16\">Please enter the email address you registered with and we'll send your password to you.</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                    <button type=\"submit\" class=\"btn btn--primary btn--primary-red btn--width-full btn--square btn--size-large bt-text--regular js-forgot-button at-submit-btn\">Send Reset Link</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                    <button type=\"submit\" class=\"btn btn--primary btn--primary-red btn--width-full btn--square btn--size-large bt-text--regular js-forgot-button at-submit-btn\">Send Password</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNewPasswordReset") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":11,"column":7}}})) != null ? stack1 : "")
    + "<form class=\"js-forgot-form\" data-parsley-validate>\n    <div class=\"grid grid--justifyCenter\">\n        <div class=\"cell cell-md-9\">\n            <div class=\"alert alert--small uk-hidden js-alert-messages-container\"></div>\n            <div class=\"grid grid--gutters grid-xs--full\">\n                <div class=\"cell\">\n\n                    <div class=\"btgf-field\">\n                      <div class=\"form__group\">\n                        <div class=\"form__label-group\">\n                          <label class=\"form__label bt-text--regular font-size--14\" for=\"unableEmail\">Email Address</label>\n                        </div>\n                        <input\n                          type=\"email\"\n                          id=\"unableEmail\"\n                          name=\"email\"\n                          autocomplete=\"given-name\"\n                          class=\"form__input form__input--square at-email-txt\"\n                          data-parsley-emailsanitizer\n                          required\n                        />\n                      </div>\n                    </div>\n\n                </div>\n                <div class=\"cell text-xs--center\">\n                    <input type=\"text\" name=\"LastName\" class=\"LastName uk-hidden\" aria-label=\"hidden field\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNewPasswordReset") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":40,"column":20},"end":{"line":44,"column":27}}})) != null ? stack1 : "")
    + "                    <div class=\"mt-12\"><button type=\"button\" class=\"btn btn--text btn--width-full js-user-signin\">Back to Log in</button></div>\n                </div>\n            </div>\n        </div>\n    </div>\n</form>\n";
},"useData":true});
import { delay } from 'redux-saga';
import { all, take, fork, takeEvery, takeLatest, call, put, select } from 'redux-saga/effects';
import { push, replace, LOCATION_CHANGED } from 'redux-little-router';
import { api } from 'BoomTown';
import goBackIfNotFirstRoute from 'sagas/routerTasks/goBackIfNotFirstRoute';
import { SEARCH_MENU_QUERY } from 'models/search/constants';

// Actions
import { savedSearchesData } from 'constants/registrationForms';
import {
  ballerboxOnChange,
  ballerboxOnFocus,
  ballerboxReceiveSuggestions,
  ballerboxOnSelection,
  selectNearbyThunk,
  invalidateCount,
  ballerboxReceiveSuggestionsFailure,
} from 'actions/OffCanvasActions';
import * as mgActions from 'actions/MobileGalleryActions';
import * as mSearchMenuActions from 'screens/MobileSearchMenu/actions';

// Selectors
import { getPostRegistrationParam } from 'selectors/router';
import { isSearchParamInURLonFirstLoad } from 'selectors/mobileGallery';

// Tasks
import { searchBarSelect } from 'sagas/routerTasks/commonViewSagas';
import { applyPendingSearch } from './offCanvas';

export default function* mobileSearchMenu() {
  yield all([
    ballerBox(),
    takeEvery(
      [
        mgActions.clickNewSearchInResultsHeader,
        mgActions.clickSearchInResultsHeader,
      ],
      openSearchMenu
    ),
    takeEvery(mSearchMenuActions.closeSearchMenu, goBackIfNotFirstRoute),
    takeEvery(mSearchMenuActions.applySearch, applyPendingSearch),
    fork(removeSearchParamFromInitialURL),
  ]);
}

/**
 * This saga only runs once, and waits for the first location change to
 * go through. Its job is to replace the current url with the a copy that
 * omits the search menu param.
 *
 * This does count toward location changes... but that seems ok
 */
function* removeSearchParamFromInitialURL() {
  yield take(LOCATION_CHANGED);

  // We don't need to do anything if the menu param isn't in the url
  const store = yield select();
  if (!isSearchParamInURLonFirstLoad(store)) {
    return;
  }

  // We don't want to remove the param from the URL if we are trying to restore
  // the save search experience and a history.replace would break the flow as well
  if (getPostRegistrationParam(store) === savedSearchesData.urlParam) {
    return;
  }

  const { pathname, query } = store.router;
  // eslint-disable-next-line no-unused-vars
  const { [SEARCH_MENU_QUERY]: _discard, ...alteredQuery } = query;
  yield put(replace({ pathname, query: alteredQuery }));
}

/** Delay in ms before fetching suggestions in response to input changes. */
const DEBOUNCE_DELAY = 200;

/**
 * Handle starting the fetchSuggestions task in response to UI actions. The
 * focus action should immediately fork, but input changes should be debounced.
 */
function* ballerBox() {
  yield all([
    takeEvery(ballerboxOnFocus.getType(), fetchSuggestions),
    takeLatest(ballerboxOnChange.getType(), function* debounced() {
      yield call(delay, DEBOUNCE_DELAY);
      yield fetchSuggestions();
    }),
    takeEvery(ballerboxOnSelection.getType(), handleSearchBarSelection),
  ]);
}

export function* fetchSuggestions() {
  const inputValue = yield select(state => state.offCanvasSearch.menu.ballerbox.searchValue);

  let result;
  try {
    result = yield call([api, api.getSuggestions], {
      query: inputValue,
      type: 'all',
    });
  } catch (e) {
    yield put(ballerboxReceiveSuggestionsFailure());
    return;
  }

  if (result.Status && result.Status.Code === 200) {
    yield put(ballerboxReceiveSuggestions({ suggestions: result.Result }));
  }
}

/**
 * This function is really about calling invalidateCount.
 *
 * But since we know that the user is commiting to this tag we decide to store
 * the displayName in local storage as well. It might make sense to store the name
 * directly after receiving suggestions, but then our cache would get bloated with
 * mostly unused data.
 */
function* handleSearchBarSelection({ payload: { searchTerm } }) {
  const [name, value] = searchTerm.split('=');

  if (name === 'latlonrad') {
    yield put(selectNearbyThunk());
  }

  // change route to property details page is address is selected
  if (name.toLowerCase() === 'listingid') {
    yield call(searchBarSelect, { listingid: value });
  }

  // Dispatching this since `handleSearchBarSelection` isn't an "update count
  // action" that first dispatches itself, followed by `invalidateCount()`. Doing
  // this instead of duplicating the "fetch new count" saga, which would be
  // started in response to any action created with `createUpdateCountAction()`.
  yield put(invalidateCount());
}

export function* openSearchMenu() {
  const pathname = yield select(state => state.router.pathname);
  yield put(push({ pathname, query: { [SEARCH_MENU_QUERY]: null } }, { persistQuery: true }));
  yield put(invalidateCount());
}

import xhr from 'xhr';
import { stringify } from 'query-string';
import { visitor, visitDetails } from 'BoomTown';

export default class BoomTownVisitorActions {
    static instance = null;

    actions = {}; // Defined in window.bt.visitorActions, in main.php

    constructor() {
      this.actions = window.bt.visitorActions;
      if (!BoomTownVisitorActions.instance) {
        window.bt.utility.visitorActions = this;
      }
    }

    static getInstance() {
      if (!BoomTownVisitorActions.instance) {
        BoomTownVisitorActions.instance = new BoomTownVisitorActions();
      }
      return BoomTownVisitorActions.instance;
    }

    write(actionId) {
      let qs = {
        actionId,
        visitId: visitDetails._ID,
        visitorId: visitor.id
      };

      qs = stringify(qs);

      xhr({
        method: 'get',
        uri: `/index.php?action=logaction&${qs}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }, () => {});
    }
}

import { all, call, put, select } from 'redux-saga/effects';
import { visitor, api, rules } from 'BoomTown';
import {
  receiveListingFromAPI,
  receiveListingFromAPIFailure,
  receivedRelatedListings,
  updateListingForDetailsPage,
} from 'actions/MobileDetailsActions';
import Listing from 'legacy/Model/listing';
import { getActiveListing, getCurrentListingID } from 'selectors/listings';
import { getContinuedResults } from 'selectors/mobileDetails';
import { maybeFetchNextAgentForContact } from 'components/MobileContactAgent/effects';
import { isFBBrowserRedirect } from 'selectors/router';
import setVirtualOpenHousesLive from 'components/Common/OpenHouses/setVirtualOpenHousesLive';

export function* fetchDataForDetailsPage() {
  yield all([fetchListingIfNeeded(), maybeFetchNextAgentForContact()]);
}

export function* squeezeAndAddView() {
  // CNS-4759: Do not display regform if we've just completed it
  // do not addView if we only changed the query (eeks what about other changes)
  // do not collect $200
  const hasRegFormComplete = yield select(
    ({ router }) => router && router.query && router.query.regformcomplete
  );
  const wasFBBrowserRedirect = yield select(isFBBrowserRedirect);

  if (hasRegFormComplete || wasFBBrowserRedirect) {
    return;
  }

  // This shows the reg modal
  yield call([visitor, visitor.showRegForm]);

  // THEN add a view
  const listingID = yield select(getCurrentListingID);
  yield call([visitor, visitor.addView], listingID, true);
}

/**
 * Fetch a full listing when needed to render the details page.
 * (_Only exported for testing._)
 */
export function* fetchListingIfNeeded() {
  const { isFullDetail, listing: listingFromStore } = yield select(getActiveListing);
  if (!isFullDetail) {
    const id = yield select(getCurrentListingID);
    let Result;
    let Status;
    try {
      ({ Result, Status } = yield call([api, api.getListingPromise], id));
    } catch (e) {
      yield put(receiveListingFromAPIFailure());
      return;
    }
    if (Status.Code === 200) {
      const listing = new Listing(Result).beefUp();

      // If the listing has Virtual Open Houses, we need to check if any are live.
      if (listing.SashType === 'virtualopenhouse') {
        listing.OpenHouses = yield call(setVirtualOpenHousesLive, listing.OpenHouses);
      }

      yield put(receiveListingFromAPI(listing));

      const hasContinuedSearchResults = yield select(getContinuedResults);
      if (!hasContinuedSearchResults?.length) {
        yield fetchRelatedListings(listing);
      }
    } else {
      yield put(receiveListingFromAPIFailure());
    }
  } else if (listingFromStore) {
    // If the listing has Virtual Open Houses, we need to check if any are live.
    if (listingFromStore.SashType === 'virtualopenhouse') {
      listingFromStore.OpenHouses = yield call(
        setVirtualOpenHousesLive,
        listingFromStore.OpenHouses
      );
      yield put(updateListingForDetailsPage(listingFromStore));
    }
  }
}

/**
 * Use attributes of a listing to fetch similar listings from the API. We only need to do this
 * on the mobile details page if a user accesses a property directly as opposed to through
 * results-gallery or map
 * @param {Object} listing
 */
export function* fetchRelatedListings(listing) {
  const price = listing.ListPrice;
  const deviation = price * 0.15;
  const maxprice = parseInt(price + deviation, 10);
  const minprice = parseInt(price - deviation, 10);
  const status = (rules.get('HideComingSoon')) ? 'A,AC' : 'A,AC,CS';

  const {
    Result: { Items },
  } = yield call([api, api.ajaxSearchPromise], {
    proptype: listing.PropertyType._ID,
    postalcode: listing.Location.PostalCode,
    maxprice,
    minprice,
    status,
    pagecount: 6,
    pageindex: 0,
  });
  const listingsForGallery = Items.map(l => new Listing(l).beefUp());
  const filteredListings = listingsForGallery.filter(i => i._ID !== listing._ID);
  const listings = filteredListings.slice(0, 5);

  yield put(receivedRelatedListings(listings));
}

import { config } from 'BoomTown';
import { LOCATION_CHANGED } from 'redux-little-router';
import * as r from 'routes';

export const getTitle = (id) => {
  switch (id) {
    case r.MENU:
      return 'Menu';

    case r.CONTACT_AGENT:
      return 'Contact Agent';

    case r.FAVORITES:
      return 'My Favorite Real Estate Properties';

    case r.ACCOUNT:
      return 'My Account';

    case r.EDIT_ACCOUNT:
    case r.CHANGE_PASSWORD:
      return 'Edit Account';

    case r.EMAIL_PREFERENCES:
      return 'Email Preferences';
    case r.TEXT_PREFERENCES:
      return 'Text Preferences';
    case r.EDIT_SAVED_SEARCHES:
      return 'Saved Searches';

    case r.COMPARE:
      return 'Comparing Properties';

    case r.FILTER_MENU:
      return 'Filter Menu';

    case r.SAVE_SEARCH:
      return 'Save Search';

    case r.RESULTS_GALLERY:
    case r.RESULTS_MAP:
      return `Real Estate Search Results :: ${config.browserTitle}`;

    default:
      return '';
  }
};

export default (store) => (next) => (action) => {
  const routeID = action.payload && action.payload.result && action.payload.result.id;
  const state = store.getState();

  if (action.type === LOCATION_CHANGED && routeID) {
    const title = getTitle(routeID);
    if (state.locationChanges > 0 && title) {
      document.title = title;
    }
  }

  return next(action);
};

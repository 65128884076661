/**
 * Encapsulates Listing related rules
 */
export default {

  // CNS-6253: Hides Neighborhood on listing cards for CMLS (boardID:13)
  shouldHideNeighborhood(boardID) {
    return boardID === 13;
  },

  // CNS-6292: Hides half baths on listing cards for CMLS (boardID:13)
  shouldHideHalfBaths(boardID) {
    return boardID === 13;
  },

  // CNS-8116: Show Area on listing details for boardID 38
  shouldShowArea(boardID) {
    return boardID === 38;
  },

  // CNS-8271: Use 'Villa' terminology for these TenantIDs else use 'Townhouse'
  shouldShowVillas(tenantID, propertyTypeName) {
    const showVillas = 3; // tenantID
    if (showVillas !== tenantID) {
      if (propertyTypeName === 'Villa/Townhouse') {
        return 'Townhouse';
      }
    }
    return propertyTypeName;
  },
};

import { createAction as createReduxAction } from 'redux-act';

const createAction = (desc, ...rest) => createReduxAction(`Contact Agent > ${desc}`, ...rest);

export const receiveAgentForContactFromAPI = createAction(
  'Receive the agent to be displayed from API'
);
export const receiveAgentForContactFromAPIFailure = createAction(
  'Receive the agent to be displayed from API FAILED'
);

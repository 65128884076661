import { createAction } from 'redux-act';
import { get as getLocation } from 'services/locationService';

// Async flow with window.navigator
export const beginLocationRequest = createAction('BEGIN_LOCATION_REQUEST');
export const receiveLocationDataSuccess = createAction('RECEIVE_LOCATION_DATA_SUCCESS');
export const receiveLocationDataFailure = createAction('RECEIVE_LOCATION_DATA_FAILURE');

// Thunk that represents flow with window.navigator
export const requestLocation = () => dispatch => {
  dispatch(beginLocationRequest());
  return getLocation()
    .then(
      data => dispatch(receiveLocationDataSuccess(data)),
      error => {
        dispatch(receiveLocationDataFailure(error));
        return Promise.reject(error);
      }
    );
};

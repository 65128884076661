import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';
import { LOCATION_CHANGED } from 'redux-little-router';
import {
  clickCloseEditAccount,
  closeEditAccountFailureModal,
  closeEditAccountSuccessModal,
  autoCloseEditAccountSuccessModal,
  receiveEditAccountSuccess,
  receiveEditAccountFailure,
  retrySubmitEditAccount,
  submitEditAccountForm,
} from './actions';

const isSubmitting = createReducer(
  {
    [receiveEditAccountSuccess]: () => false,
    [receiveEditAccountFailure]: () => false,
    [submitEditAccountForm]: () => true,
    [clickCloseEditAccount]: () => false,
    [LOCATION_CHANGED]: () => false,
  },
  false
);

/**
 * The `responseModal` state is passed down through the form component to the
 * ResponseModal as props.
 */
const defaultRespModalState = {
  isOpen: false,
  isSuccess: false,
  successText: null,
};
function closeModal(state) {
  return {
    ...state,
    isOpen: false,
  };
}
const responseModal = createReducer(
  {
    [receiveEditAccountSuccess]: (state, { isPhoneUpdated }) => ({
      ...state,
      isOpen: true,
      isSuccess: true,
      successText: !isPhoneUpdated
        ? 'Account settings successfully updated.'
        : `Account settings successfully updated.
        We recommend updating your password to your new phone number.`,
    }),
    [receiveEditAccountFailure]: state => ({
      ...state,
      isOpen: true,
      isSuccess: false,
    }),

    // Closing the modal
    [LOCATION_CHANGED]: closeModal,
    [retrySubmitEditAccount]: closeModal,
    [closeEditAccountFailureModal]: closeModal,
    [closeEditAccountSuccessModal]: closeModal,
    [autoCloseEditAccountSuccessModal]: closeModal,
  },
  defaultRespModalState
);

export default combineReducers({
  isSubmitting,
  responseModal,
});

// Selector
export const getEditAccountState = state => ({
  isSubmitting: isSubmitInProgress(state),
  ...getResponseModal(state),
});

export const _isSubmitInProgress = state => state.isSubmitting;
export const _getResponseModal = state => state.responseModal;

const select = fn => state => fn(state.accountView.editAccount);

export const isSubmitInProgress = select(x => x.isSubmitting);
export const getResponseModal = select(x => x.responseModal);

import { BASE_SEARCH } from 'models/search/constants';

// Utility Fns
import { SearchConverter } from 'models/search/services';

// Selectors
import {
  getBaseSearchConstant,
  getCommittedTags,
  getSearchWithBounds
} from 'models/search/reducers/selectors';
import { getPendingSearch, getPendingTags } from 'models/search/reducers/pending/selectors';
import { getSearchQuery } from 'selectors/searchSelectors';

/**
 * @function getDisabledTags
 *
 * Returns the `disabledTags` array from the `mobileSaveSearch` branch of the redux state tree
 *
 * @param {Object} state Global state object
 * @returns {SearchTag[]}
 */
const getDisabledTags = state => state.mobileSaveSearch.disabledTags;

/**
 * @function getResultCount
 *
 * @param {Object} state Global state object
 * @returns {{resultCount: number, isCountValid: boolean}}
 */
export const getResultCount = state => ({
  resultCount: state.mobileSaveSearch.resultCount,
  isCountValid: state.mobileSaveSearch.isCountValid,
});

/**
 * Get the base search object with the `pending` search schema.
 *
 * @param {Object} state
 * @return {Object}
 */
function getBaseSearchInPendingFormat(state) {
  switch (getBaseSearchConstant(state)) {
    case BASE_SEARCH.COMMITTED:
      const obj = getSearchWithBounds(state);
      return SearchConverter.mapCommittedToPending(obj, { includeDefaults: false });
    case BASE_SEARCH.PENDING:
      return getPendingSearch(state);
    default:
      throw new Error('Invalid base search constant');
  }
}

/**
 * Depending on the `state.saveSearch.search`, get the location tags based on
 * either the committed search in the Search model, the pending search, or a
 * new, altered search.
 *
 * @param {Object} state The state of the Redux store
 * @param {Backbone.Model} searchModel The Backbone search model
 * @returns {SearchTag[]} An array of tag objects
 */
export function getLocationTags(state) {
  let tags = [];

  switch (getBaseSearchConstant(state)) {
    case BASE_SEARCH.COMMITTED:
      tags = getCommittedTags(state);
      break;
    case BASE_SEARCH.PENDING:
      tags = getPendingTags(state);
      break;
    default:
      throw new Error('Invalid base search constant');
  }

  const { disabledTags } = state.mobileSaveSearch;

  return tags.map(t => ({
    ...t,
    isDisabled:
      disabledTags.findIndex(({ prop, value }) => prop === t.prop && value === t.value) !== -1,
  }));
}

/**
 * Get the user's search with each of the tags they've disabled on the save
 * search screen removed from their base search.
 *
 * @param {Object} state The global state tree
 * @returns {Object} A search obj with the `committed` search schema
 */
export function getStateWithoutDisabledTags(state) {
  const disabledTags = getDisabledTags(state);
  const baseSearchObj = getBaseSearchInPendingFormat(state);

  // Compares the original search obj with the obj that has disabled tags. If they're the same, the
  // return value of `getSearchQuery` is `null`. In that case, use the `baseSearchObj`.
  const query = getSearchQuery(
    baseSearchObj,
    disabledTags.reduce(SearchConverter.removePendingSearchValue, baseSearchObj)
  ) || baseSearchObj;

  return SearchConverter.mapPendingToCommitted(query, { removeDefaults: true, removeSort: true });
}

export const isResModalOpen = state => state.mobileSaveSearch.isResModalOpen;
export const isSubmitInProgress = state => state.mobileSaveSearch.isSubmitting;
export const isSubmitSuccess = state => state.mobileSaveSearch.isSuccess;

import { createAction } from 'redux-act';

/** Save Search Modal */
const ssModalAction = (title, ...args) => createAction(`Desktop Save Search > ${title}`, ...args);

export const clickSave = ssModalAction(
  'Submit a save search form',
  /**
   * Form => API
   * @param {{searchName: string, email: string, frequency: string}}
   */
  x => x
);

export const clickClose = ssModalAction('Click the close button in the header');
export const receiveCreateSavedSearchRes = ssModalAction(
  'API response received',
  x => x,
  ({ response }) => ({ success: response === 'success' })
);

import PropTypes from 'prop-types';
import React from 'react';

/**
 * Header
 * Formats and renders a Header for each chunk of results
 * @return {ReactElement}
 */
const Header = props => (
  <li className="uk-nav-header at-suggestion-header">{props.name.toUpperCase()}</li>
);

Header.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Header;

/* eslint no-console: "off" */
import { delay } from 'redux-saga';
import { call, put, select, race, take } from 'redux-saga/effects';
import { visitor as visitorModel, api, agent as agentModel } from 'BoomTown';
import { DETAILS } from 'routes';
import Agent from 'legacy/Model/agent';
import { getCurrentListingID } from 'selectors/listings';
import { getRouteID } from 'selectors/router';
import { getAgentForContact } from './reducer';
import { receiveAgentForContactFromAPI, receiveAgentForContactFromAPIFailure } from './actions';

/**
 * Reusable side-effect fn for submitting the Contact Agent component. Accepts an object of named
 * params. The action object will get appended to the args array via saga's takeEvery/takeLatest API.
 *
 * Usage:
 * takeLatest(
 *  submitAction,
 *  handleSubmitContactForm,
 *  { responseAction: ReduxAction, gtmEvent: Object, registerVisitor: true }
 * )
 *
 * @param {Object} obj
 * @param {ReduxAction} obj.responseAction
 * @param {ReduxAction} obj.gtmEvent
 * @param {bool} obj.registerVisitor
 * @param {Object} action - redux action appended to the args array by redux-saga's api
 */
export function* handleSubmitContactForm({ responseAction, gtmEvent, registerVisitor }, action) {
  /**
   * This payload contains information for logged in users that we can access from the state tree
   * This is a byproduct of reusing a single component.
   *
   * REFACTOR: Create a new underlying component that contains only fields that are needed.
   */

  const { name, phone, email, comments, token } = action.payload;

  const [firstName, lastName] = name.split(' ');

  // Build the request. Note that the API is case-insensitive when deserializing
  // the request and binding to controllers. (Not sure about PHP though...)
  const request = {
    type: 'Agent',
    firstName,
    lastName,
    phone,
    email,
    comments,
    token,
    visitorID: visitorModel.id,
    visitID: visitorModel.get('VisitID'),
  };


  // TODO: Move to GTM
  // Dispatch GTM event before we update visitorModel, so we could track contact form registrations
  if (window.dataLayer) {
    window.dataLayer.push({
      form: 'contactagentmodalform',
      contacttype: visitorModel.updateContactType('lead'),
      formtype: 'email',
      event: 'contact-menu-form-submit',
      ...gtmEvent,
    });
  }

  /** @type {'success' | 'failure'} */
  // CNS-6572 updated to handle JSON response.
  let response;
  try {
    response = yield call([visitorModel, visitorModel.contactFormPromise], request);
    response = JSON.parse(response.trim());

    if (response.error) {
      throw response;
    }

    response = 'success';
  } catch (e) {
    console.error(e);
    response = 'failure';
  }

  yield put(responseAction(response));

  if (registerVisitor) {
    visitorModel.updateAfterRegistrableAction({ phone });
  }
}

/**
 * Reusable side-effect fn for receiving an API response after submitting the Contact Agent
 * component. Accepts an object of named params. The action object will get appended to the args
 * array via saga's takeEvery/takeLatest API.
 *
 * Usage:
 * takeEvery(
 *  receiveResponseAction,
 *  handleAPIResponse,
 *  { clickCloseModalAction: ReduxAction, autoCloseModalAction: ReduxAction }
 * )
 *
 * @param {Object} obj
 * @param {ReduxAction} obj.clickCloseModalAction
 * @param {ReduxAction} obj.autoCloseModalAction
 * @param {{ payload: {success: boolean }} } action
 */
export function* handleAPIResponse({ clickCloseModalAction, autoCloseModalAction }, action) {
  if (action.payload.success) {
    // CNS-5332: Begins the 3 sec delay before auto-close unless the `clickCloseModalAction`
    // action is fired.
    // @see https://redux-saga.js.org/docs/api/#raceeffects
    yield race([call(dismissAfterTimeout, autoCloseModalAction), take(clickCloseModalAction)]);
  }
}

/**
 * Called by `handleAPIResponse`, handles dismissing the confirmation modal after 3sec delay
 * @param {ReduxAction} autoCloseModalAction
 */
export function* dismissAfterTimeout(autoCloseModalAction) {
  yield call(delay, 3000);
  yield put(autoCloseModalAction());
}

/**
 * Fetch an agent for display in the contact form if needed.
 */
export function* maybeFetchNextAgentForContact() {
  const { agent } = yield select(state => getAgentForContact(state, agentModel));
  if (agent) {
    return;
  }

  // We don't need the listing ID if we're on the `/contact-agent` route
  const routeID = yield select(getRouteID);
  const listingID = routeID === DETAILS ? yield select(getCurrentListingID) : null;

  let agentData;
  try {
    agentData = yield call([api, api.getNextBuyerAgent], listingID);
  } catch (e) {
    yield put(receiveAgentForContactFromAPIFailure());
    return;
  }

  // A beefUp is done on `initialize()`.
  yield put(receiveAgentForContactFromAPI(new Agent(agentData).attributes));
}

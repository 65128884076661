import { createAction as _createAction } from 'redux-act';

const createAction = (title, ...args) => _createAction(`Mobile Save Search > ${title}`, ...args);

export const saveSearch = createAction('Submit a save search form');

export const clickTag = createAction('Click a tag');

export const clickClose = createAction('Click the close button in the header', () => {});

export const clickSaveSearchSuccessButton = createAction('Response Modal > Click "Keep Searching"');

export const clickSaveSearchRetryButton = createAction('Response Modal > Click "Retry Submit"');

export const clickSaveSearchBackButton = createAction('Response Modal > Click "Back"');

/** Not called by the UI */
export const receiveCountFromAPI = createAction(
  'Received searchcount from API',
  /** @param {Number} */
  count => count
);
export const receiveCountFromState = createAction(
  'Received Results Count from State',
  /** @param {Number} */
  count => count
);
export const receiveCountError = createAction(
  'ERROR Receiving Results Count from API',
  /** @param {Error} */
  error => error
);

// No failure action just yet, but probably will have one soon.
export const receiveCreateSavedSearchRes = createAction(
  'Received API response',
  x => x,
  // Metadata based on the API's response
  ({ response }) => ({ success: response === 'success' })
);

export const autoDismiss = createAction('Time passed autodismiss');

import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { config, compliance } from 'BoomTown';
import MenuLayout from 'components/MobileMenu/MenuLayout';
import { CloseButton } from 'components/Common/MobileScreen';
import UserInfoHeader from 'components/Common/User/UserInfoHeader';
import Pill from 'components/core/Pill';
import { MOBILE_MAIN_MENU } from 'cypress_constants';

import { LinkList, LinkListItem } from 'components/MobileMenu/LinkList';

import withData from './connectMainMenu';

const propTypes = {
  visitor: PropTypes.shape({
    isRegistered: PropTypes.bool.isRequired,
  }).isRequired,

  // Actions
  clickClose: PropTypes.func.isRequired,
  clickSignInButton: PropTypes.func.isRequired,
  clickSignInLink: PropTypes.func.isRequired,
  clickSearchMenuLink: PropTypes.func.isRequired,
  clickSignOutLink: PropTypes.func.isRequired,
  gravatarError: PropTypes.func.isRequired,
  clickSiteMenuLink: PropTypes.func.isRequired,
};

export const MobileMenu = ({
  visitor: v,
  clickClose,
  clickSignInButton,
  clickSignInLink,
  clickSearchMenuLink,
  clickSignOutLink,
  gravatarError,
  clickSiteMenuLink,
}) => (
  <MenuLayout
    topLeftButton={(
      <CloseButton
        onClose={clickClose}
        color="primary"
        dataCY={MOBILE_MAIN_MENU.CLOSE_BUTTON}
      />
    )}
  >
    { config.showUserMenu && !compliance.SuperAggroMode && (
      <UserInfoHeader
        visitor={v}
        avatarClass="at-main-menu__avatar"
        registerButtonClass="at-main-menu__register-btn"
        dataCY={MOBILE_MAIN_MENU.REGISTER_SIGN_IN_BUTTON}
        onGravatarError={gravatarError}
        onSignInClick={clickSignInButton}
        hidePhone
        hideEditInfoButton
      />
    )}

    <LinkList>
      {/* These links are only enabled if the user is registered */}
      { config.showUserMenu && !compliance.SuperAggroMode && (
        <>
          <LinkListItem
            className="at-main-menu__favs"
            href="/results-gallery?favs=1"
            icon="heart"
            disabled={!v.isRegistered}
            dataCY={MOBILE_MAIN_MENU.FAVORITES_BUTTON}
          >
            My Favorites
            {v.favoriteCount > 0 && (
              <Pill
                className="link-list__pill pill--badge"
                bgColor={v.isRegistered ? 'red--dark' : null}
                disabled={!v.isRegistered}
              >
                {v.favoriteCount}
              </Pill>
            )}
          </LinkListItem>
          <LinkListItem
            className="at-main-menu__notifications"
            href="/account/saved-searches"
            icon="save"
            disabled={!v.isRegistered}
            dataCY={MOBILE_MAIN_MENU.SAVED_SEARCHES_BUTTON}
          >
            Saved Searches
            {v.savedSearchCount > 0 && (
              <Pill
                className="link-list__pill pill--badge"
                bgColor="red--dark"
                dataCY={MOBILE_MAIN_MENU.SAVED_SEARCHES_PILL}
              >
                {v.savedSearchCount}
              </Pill>
            )}
          </LinkListItem>
          <LinkListItem
            className="at-main-menu__account"
            href="/account/"
            icon="user"
            dataCY={MOBILE_MAIN_MENU.MY_ACCOUNT}
            disabled={!v.isRegistered}
          >
            My Account
          </LinkListItem>

          {/* These links are always enabled */}
          <LinkListItem
            className="at-main-menu__contact-agent"
            href="/contact-agent/"
            icon="phone-volume"
          >
            Contact Agent
          </LinkListItem>
          <LinkListItem
            className="at-main-menu__search"
            href="/results-gallery/?search"
            onClick={clickSearchMenuLink}
            icon="search"
          >
            Search
          </LinkListItem>
        </>
      )}
      <LinkListItem className="at-main-menu__home" href="/" icon="home">
        Home
      </LinkListItem>
      <LinkListItem
        className="at-main-menu__site-menu"
        isRoute={false}
        onClick={clickSiteMenuLink}
        icon="angle-right"
        dataCY={MOBILE_MAIN_MENU.SITE_MENU}
      >
        Site Menu
      </LinkListItem>

      { config.showUserMenu && !compliance.SuperAggroMode && (
        v.isRegistered ? (
          <LinkListItem
            className="at-main-menu__sign-out"
            isRoute={false}
            onClick={clickSignOutLink}
            dataCY={MOBILE_MAIN_MENU.SIGN_OUT}
          >
            Sign Out
          </LinkListItem>
        ) : (
          <LinkListItem
            className="at-main-menu__sign-in"
            isRoute={false}
            onClick={clickSignInLink}
            dataCY={MOBILE_MAIN_MENU.SIGN_IN}
          >
            Register / Sign In
          </LinkListItem>
        )
      )}
    </LinkList>
  </MenuLayout>
);
MobileMenu.propTypes = propTypes;

export default compose(withData)(MobileMenu);

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Cell } from 'components/core/Grid';
import NoResults from 'components/NoResults';
import Toast from 'components/Common/Toast';
import { SAVED_SEARCH_PAGE } from 'cypress_constants';
import SaveSearchItem from './SaveSearchItem';
import withData from './withData';

const EditSavedSearches = ({
  onSavedSearchLinkClick = () => {},
  onStartNewSearchClick = () => {},
  onChange,
  onDelete,
  searches,
  toast,
  isMobile,
}) => (
  <>
    {searches && searches.length ? (
      <Grid gutters>
        {searches.map((s) => (
          <Cell xs={12} md={6} lg={4} key={s.searchid}>
            <SaveSearchItem
              {...s}
              className="uk-height-1-1"
              emailFrequency={s.frequency}
              onChange={onChange}
              onDelete={onDelete}
              onLinkClick={onSavedSearchLinkClick}
              isCollapsible={isMobile}
              dataCY={SAVED_SEARCH_PAGE.SAVED_SEARCHES_CONTAINER}
            />
          </Cell>
        ))}
      </Grid>
    ) : (
      <NoResults
        isSaveSearchVisible={false}
        onStartNewSearchClick={onStartNewSearchClick}
        shouldStartNewSearch
        dataCY={SAVED_SEARCH_PAGE.NO_RESULTS_CONTAINER}
      />
    )}
    {toast.isVisible && (
      <Toast status={toast.status} message={toast.message} />
    )}
  </>
);

EditSavedSearches.propTypes = {
  onSavedSearchLinkClick: PropTypes.func,
  onStartNewSearchClick: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  toast: PropTypes.shape({
    isVisible: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,

  searches: PropTypes.array.isRequired,
};

EditSavedSearches.defaultProps = {
  onSavedSearchLinkClick: () => {},
  onStartNewSearchClick: () => {},
};

export default withData(EditSavedSearches);

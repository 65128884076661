import PropTypes from 'prop-types';
import React from 'react';
import Header from 'components/OffCanvas/Header';
import TagBar from 'components/OffCanvas/TagBar';
import BallerBox from 'components/BallerBox';
import BedsBathsToggle from 'components/OffCanvas/BedsBathsToggle';
import { getMinBaths } from 'selectors/searchSelectors';

import FeatureGroups from './FeatureGroups';
import OffCanvasPriceRange from './PriceRange';
import PropertyTypeAccordion from './PropertyTypeAccordion';
import PropertyDetailsAccordion from './PropertyDetailsAccordion';

export default function OffCanvas(props) {
  const { searchcount, fetchingCount } = props.offCanvasSearch.menu;
  const { pendingSearch } = props.offCanvasSearch;
  const { searchFields, searchTags } = props;

  return (
    <div className="offcanvas__container">
      <Header
        listingCount={searchcount}
        fetchingCount={fetchingCount}
        onCancelBtnClick={props.OffCanvasActions.close}
        onSaveBtnClick={props.OffCanvasActions.saveSearch}
        onResultsBtnClick={props.OffCanvasActions.applyAndClose}
      />

      <nav className="js-off-canvas-filter offcanvas__nav">
        <TagBar
          searchTags={searchTags}
          onTagClick={props.OffCanvasActions.removeSearchTerm}
        />
        <div className="bt-listing-search__wrapper js-filter-bar">
          <div className="bt-filter-bar">
            <div className="bt-filter-bar__components">
              <div className="my-16">
                <BallerBox renderSearchButton />
              </div>

              <OffCanvasPriceRange
                min={pendingSearch.minprice}
                max={pendingSearch.maxprice}
                onChange={props.OffCanvasActions.updatePrice}
              />

              <BedsBathsToggle
                id="minbeds"
                title="Bedrooms"
                value={pendingSearch.minbeds}
                onToggle={props.OffCanvasActions.updateMinBeds}
              />

              <BedsBathsToggle
                id="minbaths"
                title="Bathrooms"
                value={getMinBaths(pendingSearch)}
                onToggle={props.OffCanvasActions.updateMinBaths}
                options={
                  props.specialRules.ShowHalfBaths ? [0, 1, 1.5, 2, 3, 4, 5] : [0, 1, 2, 3, 4, 5]
                }
              />

              <PropertyTypeAccordion
                propertyTypes={searchFields.proptypes}
                pendingSearch={pendingSearch}
                onPropertyTypeToggle={props.OffCanvasActions.togglePropertyType}
                onUnderContractToggle={props.OffCanvasActions.toggleUnderContract}
                onSaleTypeToggle={props.OffCanvasActions.toggleSaleType}
                onToggleStatus={props.OffCanvasActions.toggleStatus}
              />

              <PropertyDetailsAccordion
                pendingSearch={pendingSearch}
                searchFields={searchFields}
                specialRules={props.specialRules}
                account={props.account}
                actions={props.OffCanvasActions}
              />

              {/* eslint-disable new-cap */}
              {FeatureGroups({
                features: searchFields.features,
                checkedFeatures: pendingSearch.features,
                onToggleFeature: props.OffCanvasActions.toggleFeature,
              })}
              {/* eslint-enable new-cap */}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

OffCanvas.displayName = 'OffCanvas';
OffCanvas.propTypes = {
  searchFields: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  specialRules: PropTypes.shape({
    HideForeclosure: PropTypes.bool.isRequired,
    HideShortsale: PropTypes.bool.isRequired,
    ReplaceUnderContract: PropTypes.string.isRequired,
    ShowAcresSearch: PropTypes.bool.isRequired,
    ShowActiveContingent: PropTypes.bool.isRequired,
    ShowDaysListed: PropTypes.bool.isRequired,
    ShowGarages: PropTypes.bool.isRequired,
    ShowHalfBaths: PropTypes.bool.isRequired,
    ShowPriceReduced: PropTypes.bool.isRequired,
    ShowSQFT: PropTypes.bool.isRequired,
    ShowStories: PropTypes.bool.isRequired,
    ShowYearBuilt: PropTypes.bool.isRequired,
  }),
  offCanvasSearch: PropTypes.shape({
    menu: PropTypes.shape({
      searchcount: PropTypes.number,
      fetchingCount: PropTypes.bool,
    }),
    pendingSearch: PropTypes.object, // TODO: Fill this out once complete.
  }),
  searchTags: PropTypes.arrayOf(
    PropTypes.shape({
      prop: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      displayName: PropTypes.string,
    }),
  ),
  OffCanvasActions: PropTypes.object.isRequired,
};

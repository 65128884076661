import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { Dropdown, DropdownTrigger, DropdownContent } from '.';

/**
 * This is a React version of the ccomp jQuery component (@see ccomp.js)
 * with `data-bt-ccomp="dropdown"`. Note that there are a couple of style
 * variations on the ccomp.
 *
 * The idea here is that the dropdown component should be style-agnostic,
 * so that we can create components that provide styling to the conditional
 * render functionality of the `Dropdown`
 */

export default function CCompDropdown(props) {
  const { trigger, content, align, size, contentProps, dataCY, ...otherProps } = props; // eslint-disable-line no-unused-vars
  return (
    <Dropdown
      data-cy={dataCY}
      {...otherProps}
      className={cx('bt-ccomp bt-ccomp__dropdown', {
        [otherProps.className]: !!otherProps.className,
        'uk-display-block': otherProps.fullWidth
      })}
      animation="fadeDown"
    >
      <DropdownTrigger className={cx('bt-ccomp__trigger', props.triggerClassName)}>
        {props.trigger}
      </DropdownTrigger>
      <DropdownContent>
        <div
          {...props.contentProps}
          className={cx('bt-react-dropdown__content', {
            [props.contentProps.className]: !!props.contentProps.className,
            'bt-dropdown--right': props.align === 'right',
            'bt-dropdown--center': props.align === 'center',
            'bt-dropdown--small': props.size === 'small',
            'bt-dropdown--large': props.size === 'large',
          })}
        >
          {props.content}
        </div>
      </DropdownContent>
    </Dropdown>
  );
}

CCompDropdown.propTypes = {
  trigger: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  triggerClassName: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  align: PropTypes.oneOf(['right', 'center', 'left']),
  size: PropTypes.oneOf(['small', 'large']),
  dataCY: PropTypes.string,
  // For when you need to add props to the styled div that wraps the contents
  contentProps: PropTypes.object,
  fullWidth: PropTypes.bool
};

CCompDropdown.defaultProps = {
  contentProps: {}
};

import { put, select, call } from 'redux-saga/effects';
import { api } from 'BoomTown';
import { DESKTOP_MAP_PAGE_COUNT } from 'screens/ResultsMap/constants';
import { isMobile } from 'selectors/browser';
import * as MapActions from 'screens/ResultsMap/Map/actions';
import { getLocalSearch, getTotalItemsWithoutCoords } from 'screens/ResultsMap/Map/reducer';
import { getPageIndex } from 'screens/ResultsMap/Desktop/ResultsPagination/reducer';
import beefUpListings from 'screens/ResultsMap/Map/effects/beefUpListings';
import getPaginationState from 'screens/ResultsMap/Desktop/ResultsPagination/getPaginationState';
import * as PaginationActions from 'screens/ResultsMap/Desktop/ResultsPagination/actions';

/**
 * Fetch a new page of results via pagination for the Desktop Maps view
 */
export default function* fetchNewPageOfResults(action) {
  const isMobileXP = yield select(isMobile);

  // We don't care about beefing up listings on mobile devices, though this effect shouldn't even
  // run on mobile devices
  if (!isMobileXP) {
    /** @type {PaginationState} */
    let paginationState = {};
    paginationState.pageIndex = yield select(getPageIndex);

    if (action.type === PaginationActions.onClickPreviousButton.getType()) {
      paginationState.pageIndex -= 1;
    } else {
      paginationState.pageIndex += 1;
    }

    const currentSearch = yield select(getLocalSearch);

    try {
      yield put(MapActions.fetchListings());
      const searchResponse = yield call([api, api.ajaxSearchPromise], {
        ...currentSearch,
        sort: 'popularity',
        pageindex: paginationState.pageIndex,
        pagecount: DESKTOP_MAP_PAGE_COUNT
      });

      const { Items, TotalItems } = searchResponse.Result;
      let newListings = Items;

      // To avoid all sorts of var scope issues and to keep our code DRY, we use `getPaginationState()`
      // to manage getting the correct indexes for pagination, as well as the logic around the
      // MaxListingResults special rule
      ({
        paginationState,
        newListings,
      } = yield getPaginationState(paginationState, newListings, TotalItems));

      newListings = yield call(beefUpListings, newListings);

      // We're only getting totalItemsWithoutCoords to update the Toast text correctly on pagination
      const totalItemsWithoutCoords = yield select(getTotalItemsWithoutCoords);
      yield put(
        MapActions.receiveNewResultsPage({
          listings: newListings,
          totalItems: TotalItems,
          totalItemsWithoutCoords,
          ...paginationState,
        })
      );
    } catch (e) {
      yield put(MapActions.errorResults());
    }
  }
}

import { sliceFactory } from 'reducers/util';
import { SearchConverter } from 'models/search/services';
import { PENDING_BRANCH } from 'models/search/constants';
import * as OffCanvasActions from 'actions/OffCanvasActions';

/**
 * CASE FUNCTIONS
 *
 * Below are functions that can be reused to update specific `search.pending` values in
 * our state tree
 */

/**
 * Add/update the correct search term when an item is selected from the Ballerbox
 *
 * @param {Object} state The global state tree
 * @param {Object} payload
 * @param {string} payload.searchTerm The search term to add/update
 *
 * @returns {Object} The updated state object
 */
const updateBallerBoxSearchTerm = (state, { searchTerm }) => {
  if (!searchTerm.includes('=')) {
    throw new Error(
      'Ballerbox Search Term should be in name=value format, but instead ' +
        `we received ${searchTerm}`
    );
  }

  const [name, value] = searchTerm.split('=');

  if (name === 'latlonrad') {
    return state;
  }

  if (name.toLowerCase() === 'listingid') {
    return state;
  }

  return SearchConverter.updatePendingSearchTerm(state, { [name.toLowerCase()]: value });
};

/**
 * SEARCH BOX REDUCERS
 *
 * A set of Case Reducers for actions dispatched by interacting with the Search Box UI
 */
export default {
  // For adding arbitrary search terms.
  [OffCanvasActions.ballerboxOnSelection]: sliceFactory(
    PENDING_BRANCH,
    updateBallerBoxSearchTerm
  ),

  // This action is the same as `ballerboxOnSelection` except it is used on the legacy OffCanvas
  // component that should be removed once it's no longer used on the bastardized viewports of
  // smaller/older tablets.
  [OffCanvasActions.updateSearchTerm]: sliceFactory(
    PENDING_BRANCH,
    SearchConverter.updatePendingSearchTerm
  ),
};

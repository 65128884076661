import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import scrollToTop from 'hoc/scrollToTop';
import Footer from './Footer';
import { MobileScreenHeader } from './Header';

export { default as Footer } from './Footer';
export { MobileScreenHeader, Title, Header } from './Header';
export { CloseButton, BackButton } from './Buttons';

/**
 * Only exists to be wrapped by the `scrollToTop()` hoc and exported as the Container component.
 * This could probably just be a functional component down where we export Container.
 * ie) `export const Container = scrollToTop((props) => { ... })`
 *
 * @component ContainerWrapper
 */
const ContainerWrapper = (props) => {
  const { isLandscape, hasFooter, children, className, dataCY } = props;

  const classes = cx('mobile-screen__container', className, {
    'mobile-screen__container--landscape': isLandscape,
    'mobile-screen__container--no-footer': !hasFooter,
  });

  return (
    <div className={classes} data-cy={dataCY}>
      {children}
    </div>
  );
};

ContainerWrapper.propTypes = {
  isLandscape: PropTypes.bool,
  hasFooter: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
  className: PropTypes.any,
  dataCY: PropTypes.string,
};

export const Container = scrollToTop(ContainerWrapper);

/**
 * The main MobileScreen component used across the majority of our mobile views.
 *
 * @export
 * @component MobileScreen
 */
export const MobileScreen = (props) => {
  const { children, dataCY, footer, headerLeft, headerRight, title } = props;

  return (
    <Container hasFooter={Boolean(footer)}>
      <MobileScreenHeader
        dataCY={dataCY}
        title={title}
        leftSlot={headerLeft}
        rightSlot={headerRight}
      />
      <div className="container px-16--safe pt-16">{children}</div>
      {footer && <Footer children={footer} />}
    </Container>
  );
};

MobileScreen.propTypes = {
  title: PropTypes.string.isRequired,
  headerLeft: PropTypes.element,
  headerRight: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
  dataCY: PropTypes.string,
  footer: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
};

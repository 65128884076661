import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton, Input } from 'coreComponents';
import createForm from 'hoc/createForm';
import validator from 'utility/validator';
import { Grid, Cell } from 'components/core/Grid';
import Callout from 'components/core/Callout';
import ResponseModal from 'components/Common/ResponseModal';
import { ACCOUNT_PAGE, PARSLEY } from 'cypress_constants';
import withData from './withData';

@createForm([
  {
    name: 'firstname',
    type: 'text',
    initialState: ({ visitor }) => visitor.FirstName,
    validation: {
      isValid: validator.isValidName,
      message: 'Enter a valid first name',
    },
  },
  {
    name: 'lastname',
    type: 'text',
    initialState: ({ visitor }) => visitor.LastName,
    validation: {
      isValid: validator.isValidName,
      message: 'Enter a valid last name',
    },
  },
  {
    name: 'phonenumber',
    type: 'tel',
    initialState: ({ visitor }) => visitor.BestPhone,
    validation: {
      isValid: validator.isPhone,
      message: 'Enter a valid phone',
    },
  },
  {
    name: 'email',
    type: 'email',
    initialState: ({ visitor }) => visitor.Username,
    validation: [
      {
        isValid: validator.isEmail,
        message: 'Invalid email address',
      },
      {
        isValid: (x) => Boolean(x),
        message: 'Please enter an email address.',
      },
    ],
  },
  {
    name: 'address',
    type: 'text',
    initialState: ({ visitor }) => visitor.Address || '',
  },
  {
    name: 'city',
    type: 'text',
    initialState: ({ visitor }) => visitor.City || '',
  },
  {
    name: 'state',
    type: 'text',
    initialState: ({ visitor }) => visitor.State || '',
  },
  {
    name: 'zipcode',
    type: 'text',
    initialState: ({ visitor }) => visitor.ZipCode || '',
  },
])
class EditAccountForm extends PureComponent {
  static propTypes = {
    isMobileScreen: PropTypes.bool,
    // From connectBB
    visitor: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      bestPhone: PropTypes.string,
      username: PropTypes.string,
      address: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipcode: PropTypes.string,
    }),
    dataCY: PropTypes.string,

    // From state
    isOpen: PropTypes.bool,
    isSuccess: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    successText: PropTypes.string,

    // From actions
    onSubmit: PropTypes.func,
    onResSuccessButtonClick: PropTypes.func.isRequired,
    onResBackButtonClick: PropTypes.func.isRequired,
    onResRetryButtonClick: PropTypes.func.isRequired,

    // From createForm()
    formData: PropTypes.object,
    errors: PropTypes.object,
    formHandlers: PropTypes.object,
    validateForm: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {};

    /** @type {HTMLFormElement} */
    this._formEl = null;
  }

  onSubmit = (e) => {
    e.preventDefault();

    const errors = this.props.validateForm();
    if (Object.keys(errors).length) {
      if (this._formEl) {
        setTimeout(() => {
          const firstError = this._formEl.querySelector('.parsley-errors-list');
          if (firstError) {
            firstError.scrollIntoView({ block: 'center', behavior: 'smooth' });
          }
        });
      }
      return;
    }

    const { formData } = this.props;

    this.props.onSubmit({
      FirstName: formData.firstname,
      LastName: formData.lastname,
      Email: formData.email,
      BestPhone: formData.phonenumber,
      Address: formData.address,
      State: formData.state,
      City: formData.city,
      ZipCode: formData.zipcode,
    });
  };

  render() {
    const {
      formData,
      formHandlers,
      errors,
      isMobileScreen,
      isSubmitting,
      isOpen,
      isSuccess,
      onResSuccessButtonClick,
      onResBackButtonClick,
      onResRetryButtonClick,
      successText,
    } = this.props;

    /**
     * Get the corresponding error object from our state.
     *
     * @note There are better ways of handling error state, but we'll refactor this component soon
     * hopefully anyway to a functional component. We can implement a better error state structure
     * at that time.
     *
     * @param {string} fieldName
     * @returns {{type:string, message:string}}
     */
    const getErrors = (fieldName) => {
      if (errors) {
        const fieldError = errors[fieldName];

        if (fieldError) {
          return { type: fieldName, message: fieldError };
        }

        return null;
      }

      return null;
    };

    return (
      <div>
        <form
          id="edit-account-info"
          onSubmit={this.onSubmit}
          ref={(r) => {
            this._formEl = r;
          }}
        >
          <Grid gutters resetVertical>
            <Cell xs={12} md={6}>
              <Input
                value={formData.firstname}
                onChange={formHandlers.firstname}
                error={getErrors('firstname')}
                errorProps={{ dataCY: PARSLEY.ERRORS_LIST }}
                label="First Name"
                id="accountFirstName"
                dataCY={ACCOUNT_PAGE.FIRST_NAME}
                type="text"
                name="firstname"
                autoComplete="given-name"
              />
            </Cell>
            <Cell xs={12} md={6}>
              <Input
                value={formData.lastname}
                onChange={formHandlers.lastname}
                error={getErrors('lastname')}
                label="Last Name"
                id="accountLastName"
                dataCY={ACCOUNT_PAGE.LAST_NAME}
                errorProps={{ dataCY: PARSLEY.ERRORS_LIST }}
                type="text"
                name="lastname"
                autoComplete="family-name"
              />
            </Cell>
            <Cell xs={12} md={6}>
              <Input
                value={formData.email}
                onChange={formHandlers.email}
                error={getErrors('email')}
                label="Primary Email Address"
                id="accountEmailAddress"
                type="email"
                name="email"
                autoComplete="email"
                helperTextProps={isMobileScreen && {
                  children: 'If you change your email address, this will also change your login information',
                }}
              />
            </Cell>
            <Cell xs={12} md={6}>
              <Input
                value={formData.phonenumber}
                onChange={formHandlers.phonenumber}
                error={getErrors('phonenumber')}
                label="Phone Number"
                id="accountPhoneNumber"
                className="at-phonenumber-txt"
                type="tel"
                name="phonenumber"
                autoComplete="tel"
              />
            </Cell>
            {!isMobileScreen && (
              <Cell xs={12} className="py-0">
                <Callout placement="top-left">
                  <strong>FRIENDLY REMINDER</strong>
                  : If you change your email address, this will also change your login information.
                </Callout>
              </Cell>
            )}
            <Cell xs={12}>
              <hr />
              <Input
                value={formData.address}
                onChange={formHandlers.address}
                error={getErrors('address')}
                label="Street Address"
                id="accountStreetAddress"
                dataCY={ACCOUNT_PAGE.STREET_ADDRESS}
                className="at-address-txt"
                type="text"
                name="address"
                autoComplete="street-address"
              />
            </Cell>
            <Cell xs={12} md={6}>
              <Input
                value={formData.city}
                onChange={formHandlers.city}
                error={getErrors('city')}
                label="City"
                id="accountCity"
                dataCY={ACCOUNT_PAGE.CITY}
                className="at-city-txt"
                type="text"
                name="city"
              />
            </Cell>
            <Cell xs={12} md={6}>
              <Input
                value={formData.state}
                onChange={formHandlers.state}
                error={getErrors('state')}
                label="State"
                id="accountState"
                dataCY={ACCOUNT_PAGE.STATE}
                className="at-state-txt"
                type="text"
                name="state"
                helper="(Two letter abbr.)"
                maxLength={2}
              />
            </Cell>
            <Cell xs={12} md={6}>
              <Input
                value={formData.zipcode}
                onChange={formHandlers.zipcode}
                error={getErrors('zipcode')}
                label="Zip Code"
                id="accountZipCode"
                dataCY={ACCOUNT_PAGE.ZIP_CODE}
                className="at-zipcode-txt"
                type="text"
                name="zipcode"
                autoComplete="postal-code"
              />
            </Cell>
          </Grid>
          {!isMobileScreen && (
            <Grid gutters justifyContent="center">
              <Cell md={6}>
                <PrimaryButton
                  type="submit"
                  className="at-edit-account-info-submit-btn"
                  dataCY={ACCOUNT_PAGE.SAVE_BUTTON}
                  width="full"
                  form="edit-account-info"
                  showSpinner={isSubmitting}
                >
                  Save Contact Changes
                </PrimaryButton>
              </Cell>
            </Grid>
          )}
        </form>
        <ResponseModal
          isOpen={isOpen}
          isSuccess={isSuccess}
          onClickSuccess={onResSuccessButtonClick}
          onClickBack={onResBackButtonClick}
          onClickRetry={(e) => {
            onResRetryButtonClick();
            this.onSubmit(e);
          }}
          successText={successText}
        />
      </div>
    );
  }
}

// Export the already connected Edit Account Form by default
export default withData(EditAccountForm);

import { LOCATION_CHANGED, push } from 'redux-little-router';
import { cacheListing, loadComparisonTable, loadingComparisonTable, loadFeatures } from 'actions/ComparisonTableActions';
import { api, favorites as btFavorites } from 'BoomTown';
import Listing from 'legacy/Model/listing';
import allowListingForCompare from 'utility/allowListingForCompare';
import qs from 'query-string';

export default ({ dispatch, getState }) => (next) => (action) => {
  const { type, payload } = action;

  // We only care about route changes going to /compare
  if (type === LOCATION_CHANGED && payload.result && payload.result.id === 'compare') {
    // See https://github.com/FormidableLabs/redux-little-router/issues/165
    // Should be fixed in v14.0.0
    const { id: idString, features: featuresString } = qs.parse(payload.search);

    if (idString) {
      if (featuresString) {
        const decoded = window.atob(featuresString);
        dispatch(loadFeatures(decoded.split('%%')));
      } else {
        dispatch(loadFeatures([]));
      }

      /** @type {Array<number>} */
      const listingIds = idString.split(',').map(id => parseInt(id, 10));

      // You can remove favs on the compare page, if you get low we want to redirect you back
      // to /favs
      if (listingIds.length < 2) {
        dispatch(push(`/favorites/?id=${idString}`));
        return;
      }

      // Collecting the listings we want to render
      const listings = [];
      const { compare, favorites: { favorites } } = getState();
      const needToFetch = listingIds.filter((id) => {
        const listingInCache = compare.listingCache[id];
        if (listingInCache && allowListingForCompare(listingInCache)) {
          listings.push(listingInCache);
        }

        return !listingInCache;
      });

      const availableFavorites = favorites.length || btFavorites.length;

      if (needToFetch.length > 0) {
        dispatch(loadingComparisonTable());
        Promise.all(needToFetch.map(id => api.getListingPromise(id)))
          .then((results) => {
            const combinedListings = listings.concat(
              results
                .map(({ Result }) => {
                  const newListing = new Listing(Result);
                  newListing.beefUp();
                  dispatch(cacheListing(newListing.attributes));
                  return newListing.attributes;
                })
                .filter(l => allowListingForCompare(l))
            );

            dispatch(loadComparisonTable({ listings: combinedListings, availableFavorites }));
          });
      } else {
        dispatch(loadComparisonTable({ listings, availableFavorites }));
      }
    } else {
      // If there was nothing to compare then send them back to favs
      dispatch(push('/favorites/'));
      return;
    }
  }

  next(action);
};

import { call, put, select, cancelled } from 'redux-saga/effects';
import { isEqual } from 'underscore';
import { api, config } from 'BoomTown';
import { push as routerPush } from 'redux-little-router';
import Listing from 'legacy/Model/listing';
import { SEARCH_MENU_QUERY } from 'models/search/constants';

// Selectors
import { getLastGallerySearch } from 'selectors/mobileGallery';
import { getCommittedSearch, getMapBounds } from 'models/search/reducers/selectors';
import { getSearchQuery } from 'selectors/searchSelectors';

// Tasks
import { add } from 'sagas/routerTasks/search';

// Actions
import * as MobileGalleryActions from 'actions/MobileGalleryActions';
import { logNoResults } from 'components/NoResults/actions';

export function* handleSortClick(action) {
  const sortDir = action.payload;
  if (sortDir) {
    if (config.isMobileApp) {
      yield put(routerPush({ query: { sort: sortDir } }, { persistQuery: true }));
    } else {
      yield add({ sort: sortDir });
    }
  }
}

export function* maybeFetchSearchResults({ payload: { query } }) {
  // Search menu is open, don't even start prepping a query
  if (query[SEARCH_MENU_QUERY] !== undefined) {
    return;
  }

  let nextQuery = {};

  // Previous search state from `mobileGallery` branch. Not updated until `receiveSearchResults`
  // action is dispatched.
  const lastGallerySearch = yield select(getLastGallerySearch);
  const committedSearch = yield select(getCommittedSearch);
  const mapBounds = yield select(getMapBounds);

  if (config.useListMapResultsSync && mapBounds) {
    nextQuery = getSearchQuery(lastGallerySearch, committedSearch, mapBounds);

    // `getMapQueryForGallery` could return `null` if updated search is equal to existing search.
    if (!nextQuery) {
      return;
    }
  } else {
    if (isEqual(committedSearch, lastGallerySearch)) {
      // We're now initializing our state with listings from server-side data on a ssr but this
      // `listings` property is left to indicate the initial route event -> search change chain.
      // @see listings.js@searchChange
      if (window.bt_data.listings != null) {
        delete window.bt_data.listings;
      }
      return;
    }

    nextQuery = committedSearch;
  }

  try {
    yield put(MobileGalleryActions.startFetchingResults());

    yield call(fetchSearchResults, nextQuery);
  } catch (e) {
    yield put(MobileGalleryActions.errorWithResults());
  } finally {
    if (yield cancelled()) {
      yield put(MobileGalleryActions.cancelledFetchingResults());
    }
  }
}

/**
 * Reusable side-effect fn to fetch new listings, beef them up, and dispatch actions to update
 * the mobileGallery branch of the state tree accordingly.
 *
 * @export
 * @param {Object} searchQuery The search object to use in the query
 */
export function* fetchSearchResults(searchQuery) {
  const {
    Result: { Items, TotalItems },
  } = yield call([api, api.ajaxSearchPromise], { ...searchQuery, pagecount: config.pageCount || 10 });

  const listings = Items.map(obj => {
    const l = new Listing(obj).beefUp();
    return l;
  });

  yield put(
    MobileGalleryActions.receiveSearchResults({
      listings,
      search: searchQuery,
      totalItems: TotalItems,
    })
  );

  if (TotalItems === 0) {
    yield put(logNoResults());
  }
}

import { createReducer } from 'redux-act';
import { initializeState } from 'actions/bootstrapActions';

export default createReducer(
  {
    [initializeState]: (prevState, { account }) => {
      let CustomConversionScript = '';
      if (account && account.CustomConversionScript) {
        CustomConversionScript = window.atob(account.CustomConversionScript);
      }

      let GMapKey = '';
      if (account && account.GMapKey) {
        GMapKey = account.GMapKey;
        if (GMapKey == null || GMapKey === 'gmapkey') {
          GMapKey = '';
        }
      }

      return {
        ...account,
        CustomConversionScript,
        GMapKey,
      };
    },
  },
  {}
);

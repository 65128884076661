/* eslint max-len:0 */
import PropTypes from 'prop-types';

import React from 'react';
import { SecondaryButton } from 'coreComponents';
import ResultsButton from 'components/OffCanvas/ResultsButton';
import { compose } from 'redux';
import { connect } from 'react-redux';

function Header(props) {
  const { favs } = props.query;
  const isFavoritesRoute = favs && (favs === '1');

  return (
    <div className="grid js-off-canvas__sticky-header offcanvas__header p-12">
      <div className={`cell ${!isFavoritesRoute ? 'cell-xs-3' : ''} pr-12`}>
        <SecondaryButton
          className="bt-filter__header-button--off-canvas"
          onClick={props.onCancelBtnClick}
          width="full"
        >
          Cancel
        </SecondaryButton>
      </div>
      {!isFavoritesRoute && (
        <div className="cell cell-xs-3 pr-12">
          <SecondaryButton
            className="bt-filter__header-button--off-canvas"
            onClick={props.onSaveBtnClick}
            width="full"
          >
            Save
          </SecondaryButton>
        </div>
      )}
      <div className={`cell ${!isFavoritesRoute ? 'cell-xs-6' : ''}`}>
        <ResultsButton
          count={props.listingCount}
          isFetching={props.fetchingCount}
          onClick={props.onResultsBtnClick}
        />
      </div>
    </div>
  );
}

Header.displayName = 'OffCanvasHeader';
Header.propTypes = {
  onCancelBtnClick: PropTypes.func.isRequired,
  onSaveBtnClick: PropTypes.func.isRequired,
  onResultsBtnClick: PropTypes.func.isRequired,
  listingCount: PropTypes.number.isRequired,
  fetchingCount: PropTypes.bool,
  query: PropTypes.shape({
    favs: PropTypes.string,
  }),
};

export default compose(
  connect(
    state => ({ query: state.router.query }),
  )
)(Header);

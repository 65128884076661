import { takeEvery, takeLatest, select, call, put } from 'redux-saga/effects';
import { omit } from 'underscore';
import { api } from 'BoomTown';
import { go } from 'sagas/routerTasks/search';

import { getCommittedSearch, getSearchWithBounds } from 'models/search/reducers/selectors';
import { defaultSearch } from 'models/search/constants';
import { getPendingSearch } from 'models/search/reducers/pending/selectors';
import { getSearchQuery } from 'selectors/searchSelectors';
import { isStartingNewSearch } from 'reducers/OffCanvasSearch/menu';
import * as a from 'actions/OffCanvasActions';
import { SearchConverter } from 'models/search/services';
import { searchBarSelect } from 'sagas/routerTasks/commonViewSagas';


export default [
  takeEvery(a.applySearch, applyPendingSearch),
  takeEvery(a.updateSearchTerm, redirectToDetailsPage),
  takeLatest(a.invalidateCount, updateCountTask),

];

// handles legacy tablet view. Redirects to details page when listing suggestion is
// selected from downdown
function* redirectToDetailsPage(suggestion) {
  const searchKey = Object.keys(suggestion.payload);
  if (searchKey[0] === 'listingid') {
    yield call(searchBarSelect, suggestion.payload);
  }
}

export function* applyPendingSearch() {
  const startingNewSearch = yield select(isStartingNewSearch);
  const pendingSearch = yield select(getPendingSearch);
  const committedSearch = yield select(getCommittedSearch);

  const mergedState = {
    ...(startingNewSearch ? {} : SearchConverter.mapCommittedToPending(committedSearch)),
    // mapbounds were applied in the `applySearch` case reducer, so remove them here so they don't
    // get pushed to the query string.
    ...omit(pendingSearch, ['mapbounds']),
  };

  const query = SearchConverter.mapPendingToCommitted(mergedState, { removeDefaults: true });
  yield go({ query }, 'replace');
}

/**
 * Sends a request to get the new search count from the API, based on the
 * current state of the search model, and dispatches the result when it
 * returns.
 */
function* updateCountTask() {
  let searchQuery = {};
  const startingNewSearch = yield select(isStartingNewSearch);

  if (startingNewSearch) {
    searchQuery = defaultSearch;
  } else {
    const committedSearch = yield select(getSearchWithBounds);
    const pendingSearch = yield select(getPendingSearch);

    searchQuery = getSearchCountParams(
      SearchConverter.mapCommittedToPending(committedSearch),
      pendingSearch
    );
  }

  try {
    const { Result } = yield call([api, api.searchcountPromise], searchQuery);
    yield put(a.updateCountSuccess(Result));
  } catch (error) {
    yield put(a.updateCountFailure(error));
  }
}

/**
 * Get an object to be used in a search count query. We use the previous search as the base state,
 * and then extend it with the state of the pending search.
 *
 * @param {Object} previousSearch The state of the previous search
 * @param {Object} pendingSearch The state of the pending search
 */
export function getSearchCountParams(previousSearch, pendingSearch) {
  const params = getSearchQuery(previousSearch, pendingSearch) || pendingSearch;
  return SearchConverter.mapPendingToCommitted(params, { removeDefaults: true, removeSort: true });
}

import { createAction } from 'redux-act';

/**
 * @function committedSearchChange
 *
 * Dispatched when the `search.committed` branch of the state tree changes. Replaces the former
 * `SEARCH_CHANGE` (searchChange) BackboneAction.
 *
 * @todo Should we send the search obj as payload? Might be safer to let listeners just select
 * from the state tree. We'll see.
 *
 * @returns {ReduxAction}
 */
const committedSearchChange = createAction('COMMITTED_SEARCH_CHANGE', search => search);

export default committedSearchChange;

/**
 * Basic Phone Validator For Frontend
 * Backend Handles Heavy Lifting
 * @return {object}
 */
export default function phoneValidator(phone) {
  // Normalize To String
  phone = `${phone}`.trim();

  if (phone === '') {
    return {
      valid: false,
    };
  }

  // Check for invalid characters that are not commonly-used in phone numbers
  // Allowed Characters: + _ - . () [space]
  if (RegExp(/[^\d_()+\-.\s]+/).test(phone)) {
    return {
      valid: false,
    };
  }

  // Remove non-digit characters and spaces to check phone number length
  phone = phone.replace(/[_()+\-.\s]/g, '');

  // As long as number is supplied, ensure it's between min and max global phone number length
  if ((phone.length > 15 || phone.length < 7) && phone.length > 0) {
    return {
      valid: false,
    };
  }

  // Last check for invalid characters
  if (isNaN(phone)) {
    return {
      valid: false,
    };
  }

  return {
    valid: true,
  };
}

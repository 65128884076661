import { LOCATION_CHANGED } from 'redux-little-router';

/**
 * Is the action LOCATION_CHANGED to the given routeID
 *
 * @param {string} routeID One of the route IDs from /routes.js
 * @return {bool}
 */
const isRoute = routeID => action =>
  action.type === LOCATION_CHANGED && action.payload.result && action.payload.result.id === routeID;

export default isRoute;

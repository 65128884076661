/* eslint-disable no-confusing-arrow, camelcase, indent */
import { combineReducers } from 'redux';
import editAccount from './Forms/EditAccount/reducer';
import emailPreferences from './Forms/EditEmailPrefs/reducer';
import savedSearches from './Forms/EditSavedSearches/reducer';
import changePassword from './Forms/ChangePassword/reducer';
import textPreferences from './Forms/EditTextPrefs/reducer';

export default combineReducers({
  editAccount,
  emailPreferences,
  textPreferences,
  savedSearches,
  changePassword,
});

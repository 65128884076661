import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/**
 * @param {Object} props
 * @param {string} props.pillText - Text of the pill
 * @param {string} props.position - CSS position attr of pill
 * @param {string} props.bgColor - pill background color. Default = red
 *
 */

const Pill = (props) => {
  const {
    className = '',
    children,
    bgColor,
    branded = false,
    overlay = false,
    rounded = false,
    disabled = false,
    position,
    dataCY,
  } = props;

  const classes = cx('pill', className, {
    'pill--overlay': overlay,
    'pill--rounded': rounded,
    'pill--muted': disabled,
    'pill--brand': branded,
    [`bg-${bgColor}`]: bgColor,
    [`bt-position--${position}`]: position,
  });

  return (
    <span className={classes} data-cy={dataCY}>
      {children}
    </span>
  );
};

Pill.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dataCY: PropTypes.string,
  bgColor: PropTypes.oneOf(['red', 'red--dark']),
  overlay: PropTypes.bool,
  rounded: PropTypes.bool,
  disabled: PropTypes.bool,
  branded: PropTypes.bool,
  position: PropTypes.oneOf(['static', 'relative', 'absolute', 'fixed']),
};

export default Pill;

// These functions are used to implement currency mask-like behaviors when
// typing into price fields.
import addCommas from './addCommas';

/**
 * Remove all non-digit characters, and replace thousand and million
 * placeholders.
 * @param  {String} s
 * @return {String}
 */
export function strip(s) {
  return s.replace(/[kK]/, '000').replace(/[mM]/, '000000').replace(/\D/g, '');
}

/**
 * Format a numeric string representing USD with a '$' and commas.
 * @param  {String} s
 * @return {String}
 */
export function decorate(s) {
  return s.length > 0 ? `$ ${s.slice(0, 8).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : '';
}

/**
 * A combination of `addCommas` and `decorate` to add commas to a string without a '$'.
 * @todo Update the naming and use of `decorate` throughout
 * @param  {String} s
 * @return {String}
 */
export function decorateClean(s) {
  return s.length > 0 ? s.slice(0, 8).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
}

/**
 * Format a number to a string representing USD with a '$' and commas.
 * @param  {Number|String} value  the value to convert to a dollar string
 * @return {String} $1,000,000
 */
export const toDollarString = (value) => {
  const withCommas = addCommas(value);
  return withCommas && withCommas.length > 0 ? `$${withCommas}` : '$0';
};

/**
 * Strip a dollar string of non-digit characters to return a number
 * @param {String} value  the dollar string to be converted - defaults to '0'
 */
export const toDollarNum = (value) => {
  if (!value) return 0;
  let s = value;
  if (typeof s !== 'string') {
    s = s.toString();
  }
  return Math.max(strip(s).substring(0, 9));
};

/**
 * Convert a string representing a percentage back to a float.
 * @param  {String} percentage
 * @return {Number} ex) 3.5
 */
export const toPercentage = (percentage) => {
  if (!percentage) {
    return 0;
  }
  const ensureFloat = parseFloat(percentage.toString().replace(/[^0-9.-]/g, ''));
  if (Number.isNaN(ensureFloat)) {
    return 0;
  }
  if (ensureFloat > 100) {
    return 100;
  }
  if (ensureFloat < 0) {
    return 0;
  }
  return parseFloat(ensureFloat.toFixed(2));
};

/**
 * Converts a float back to a string representation.
 * @param  {Number|String} percentage
 * @return {String} ex) '3.5'
 */
export const toPercentageString = (percentage) => {
  const ensureFloat = typeof percentage === 'string' ?
    parseFloat(percentage.replace(/[^0-9.-]/g, '')) : parseFloat(percentage);

  if (Number.isNaN(ensureFloat)) {
    return '0';
  }
  if (ensureFloat > 100) {
    return '100';
  }
  if (ensureFloat < 0) {
    return '0';
  }
  return ensureFloat.toFixed(1);
};

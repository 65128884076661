import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PrimaryButton, SecondaryButton } from 'coreComponents';
import { Grid, Cell } from 'components/core/Grid';

export default class BedsBathsToggle extends Component {
  static propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    // The currently set min. When not specified, the parent component should pass in 0.
    value: PropTypes.number,
    onToggle: PropTypes.func.isRequired,
    options: PropTypes.array,
  };

  static defaultProps = {
    options: [0, 1, 2, 3, 4, 5],
  };

  _handleClick = e => {
    this.props.onToggle(parseFloat(e.target.value));
  };

  render() {
    const currentMin = this.props.value || 0;
    const { options, title, id } = this.props;

    const elements = [];
    for (const min of options) {
      if (min === currentMin) {
        elements.push(
          <Cell>
            <PrimaryButton
              className={`px-4 at-${id}-toggle`}
              key={min}
              onClick={this._handleClick}
              value={min}
              width="full"
            >
              {min === 0 ? 'Any' : `${min}+`}
            </PrimaryButton>
          </Cell>
        );
      } else {
        elements.push(
          <Cell>
            <SecondaryButton
              className={`px-4 at-${id}-toggle`}
              key={min}
              onClick={this._handleClick}
              value={min}
              width="full"
            >
              {min === 0 ? 'Any' : `${min}+`}
            </SecondaryButton>
          </Cell>
        );
      }
    }

    return (
      <div className="p-12 bt-off-canvas__filter--fixed-open">
        <div className="bt-ccomp__content__inner">
          <span className="uk-display-block mb-4">{title}</span>
          <Grid guttersSmall>
            {elements}
          </Grid>
        </div>
      </div>
    );
  }
}

import PropTypes from 'prop-types';
import React from 'react';
import Radio from 'components/Common/FormElements/Radio';

const MobileResultsSortRadio = (props) => {
  const { children, currentSort, onClick, sortKey } = props;

  /**
   * Click handler for the <input>
   * @param  {ClickEvent} e
   */
  const handleClick = (e) => {
    if (onClick) {
      onClick(e.target.value);
    }
  };

  return (
    <li>
      <Radio
        label={children}
        id={`sort-radio-${sortKey}`}
        value={sortKey}
        checked={sortKey === currentSort}
        onChange={handleClick}
      />
    </li>
  );
};

MobileResultsSortRadio.propTypes = {
  children: PropTypes.string,
  // The name of the sort key set in the url
  sortKey: PropTypes.string.isRequired,
  currentSort: PropTypes.string,
  // A function that will be called with the sort key, whenever the radio
  // button is clicked.
  onClick: PropTypes.func,
};

export default MobileResultsSortRadio;

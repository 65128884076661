import { compose } from 'recompose';
import { connect } from 'react-redux';
import { connectBBHoc } from 'hoc/connectBB';
import { visitor as visitorModel, agent as agentModel } from 'BoomTown';
import { getAgentForContact } from 'components/MobileContactAgent/reducer';
import * as a from './actions';

import { getUIState } from './reducer';

/**
 * Map the visitor model to props to be injected
 * @param {Backbone.Model} model
 */
const mapVisitorModelToProps = model => ({
  visitorEmail: model.get('Username'),
  visitorName: model.fullName(),
  visitorPhone: model.get('BestPhone'),
  isRegistered: model.isRegistered(),
});

// in usage `withData({click: a.click })(Component)`
const withData = compose(
  connectBBHoc(
    // listeners
    [
      {
        getEmitter: () => visitorModel,
        events: [
          {
            name: 'change',
            replaceProps: (prevProps, model) => mapVisitorModelToProps(model),
          },
        ],
      },
    ],
    // initial state
    () => ({
      ...mapVisitorModelToProps(visitorModel),
    })
  ),

  connect(
    state => {
      const uiState = getUIState(state);
      const agentInfo = getAgentForContact(state, agentModel);

      return {
        isSubmitting: uiState.isSubmitting,
        isSuccess: uiState.isSuccess,
        isResModalOpen: uiState.isResModalOpen,
        agentInfo,
      };
    },
    {
      onSubmit: a.submitContactForm,
      onResSuccessButtonClick: a.closeContactFormSuccessModal,
      onResBackButtonClick: a.clickBackInFailureModal,
      onResRetryButtonClick: a.clickRetryInFailureModal,
    }
  )
);

export default withData;

import { account } from 'BoomTown';
import { isNowBetween } from 'utility/dateTime';

/**
 * Used to determine whether the user's current time falls between the start/end times of a virtual
 * open house. Takes the timezone of the realty brokerage into account when performing the
 * comparison.
 *
 * @param {import('./types').OpenHouse[]} openHouses An array of OpenHouse objects
 * @returns {import('./types').OpenHouse[]}
 */
export default function setVirtualOpenHousesLive(openHouses) {
  if (!openHouses) {
    return [];
  }

  return openHouses.map((oh) => {
    // Don't need to check if the OH is Live if it's an "in person" OH.
    if (!oh.VirtualOpenHouseURL) {
      return oh;
    }

    const isLive = isNowBetween(
      oh.StartDateText,
      oh.EndDateText,
      account.get('TimeZoneID')
    );

    return ({ ...oh, isLive });
  });
}

import { createReducer } from 'redux-act';
import { LOCATION_CHANGED } from 'redux-little-router';
import * as actions from './actions';

/**
 * Get an array representing the site navigation stack.
 *
 * @param {Number[]}
 * @returns {Number[]}
 */
export const getStack = s => s;

/**
 * Get the ID of the menu that is currently in view
 *
 * @param {Number[]} s
 * @returns {Number|undefined}
 */
export const getCurrentMenuID = s => s[s.length - 1];

const initial = [];

export default createReducer(
  {
    // The parent ID of all top level items is 0
    [actions.clickSiteMenuLink]: () => [0],
    [actions.clickNonLeafMenuItem]: (stack, id) => [...stack, id],

    // Return a new array with all but the last element
    [actions.clickBackOnSubMenu]: stack => stack.filter((x, i, a) => i !== a.length - 1),

    // Reset on all route changes
    [LOCATION_CHANGED]: () => initial,
  },
  initial
);

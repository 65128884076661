import phpState from 'bt_data';

const sqftOptions = [
  { text: '500', value: 500 },
  { text: '550', value: 550 },
  { text: '600', value: 600 },
  { text: '650', value: 650 },
  { text: '700', value: 700 },
  { text: '750', value: 750 },
  { text: '800', value: 800 },
  { text: '850', value: 850 },
  { text: '900', value: 900 },
  { text: '950', value: 950 },
  { text: '1,000', value: 1000 },
  { text: '1,050', value: 1050 },
  { text: '1,100', value: 1100 },
  { text: '1,150', value: 1150 },
  { text: '1,200', value: 1200 },
  { text: '1,250', value: 1250 },
  { text: '1,500', value: 1500 },
  { text: '1,750', value: 1750 },
  { text: '2,000', value: 2000 },
  { text: '2,250', value: 2250 },
  { text: '2,500', value: 2500 },
  { text: '2,750', value: 2750 },
  { text: '3,000', value: 3000 },
  { text: '3,500', value: 3500 },
  { text: '4,000', value: 4000 },
  { text: '5,000', value: 5000 },
  { text: '6,000', value: 6000 },
  { text: '7,000', value: 7000 },
  { text: '7,500', value: 7500 }
];

const acresOptions = [
  { text: '1/100', value: 0.01 },
  { text: '1/8', value: 0.15 },
  { text: '1/4', value: 0.25 },
  { text: '1/3', value: 0.33 },
  { text: '1/2', value: 0.5 },
  { text: '3/4', value: 0.75 },
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
  { text: '6', value: 6 },
  { text: '7', value: 7 },
  { text: '8', value: 8 },
  { text: '9', value: 9 },
  { text: '10', value: 10 },
  { text: '20', value: 20 },
  { text: '30', value: 30 },
  { text: '40', value: 40 },
  { text: '50', value: 50 },
  { text: '60', value: 60 },
  { text: '70', value: 70 },
  { text: '80', value: 80 },
  { text: '90', value: 90 },
  { text: '100', value: 100 }
];

const daysListedOptions = [
  { text: 'New Listings (Since Yesterday)', value: 1 },
  { text: 'Less than 3 Days', value: 3 },
  { text: 'Less than 7 Days', value: 7 },
  { text: 'Less than 14 Days', value: 14 },
  { text: 'Less than 30 Days', value: 30 },
  { text: 'Less than 45 Days', value: 45 },
  { text: 'Less than 60 Days', value: 60 }
];

const priceReducedOptions = [
  { text: 'Newest (Since Yesterday)', value: '<=1' },
  { text: 'Last 3 Days', value: '<=3' },
  { text: 'Less than 7 Days', value: '<=7' },
  { text: 'Less than 14 Days', value: '<=14' },
  { text: 'Less than 30 Days', value: '<=30' },
  { text: 'More than 30 Days', value: '>30' },
  { text: 'More Than 60 Days', value: '>60' },
  { text: 'More than 120', value: '>120' }
];

const storiesOptions = [
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 }
];
const garageOptions = [
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 }
];

let yearsOptions = [];
if (phpState && phpState.searchFieldsCondensed && phpState.searchFieldsCondensed.year && phpState.searchFieldsCondensed.year.length > 0) {
  yearsOptions = phpState.searchFieldsCondensed.year.map(value => ({
    text: String(value),
    value
  }));
}

/**
 * Initial state of the `searchFields` property of the store.
 * @type {Object}
 */
export const searchFields = {
  minsqft: {
    options: sqftOptions
  },
  maxsqft: {
    options: sqftOptions
  },
  minacres: {
    options: acresOptions
  },
  maxacres: {
    options: acresOptions
  },
  minstories: {
    options: storiesOptions,
  },
  maxstories: {
    options: storiesOptions,
  },
  minyear: {
    options: yearsOptions
  },
  maxyear: {
    options: yearsOptions
  },
  mingarages: {
    options: garageOptions,
  },
  maxgarages: {
    options: garageOptions,
  },
  maxdayslisted: {
    options: daysListedOptions
  },
  pricereduced: {
    options: priceReducedOptions
  }
};

if (phpState && phpState.searchFieldsCondensed && phpState.searchFieldsCondensed.expandedproptypes) {
  searchFields.proptypes = Object.keys(phpState.searchFieldsCondensed.expandedproptypes).map((key) => {
    const value = phpState.searchFieldsCondensed.expandedproptypes[key];
    return {
      id: key,
      name: value.DDName
    };
  });
}

// Build a grouped hash of features. It's important to note that this state
// mirrors the mobile search view. Each key represents an accordion dropdown
// list.
if (phpState && phpState.searchFieldsCondensed && phpState.searchFieldsCondensed.features) {
  searchFields.features = Object.keys(phpState.searchFieldsCondensed.features).reduce((acc, subgroup) => {
    const mappedGroup = acc[subgroup] = {}; // eslint-disable-line no-multi-reassign
    const sourceGroup = phpState.searchFieldsCondensed.features[subgroup]; // eslint-disable-line no-param-reassign
    Object.keys(sourceGroup).forEach((featureName) => {
      const id = sourceGroup[featureName]; // The ID is value in the subgroup object.
      mappedGroup[id] = {
        id,
        name: featureName
      };
    });
    return acc;
  }, {});
}

export const listings = phpState.listings;

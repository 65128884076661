import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { config } from 'BoomTown';
import { Checkbox, ErrorMessage, Radio, RadioGroup, TextButton } from 'coreComponents';
import { Grid, Cell } from 'components/core/Grid';
import Calendar from 'components/Common/FormElements/DatePicker/Calendar';
import Collapsible from 'components/Common/Collapsible';
import { MOBILE_PROP_DETAILS } from 'cypress_constants';

const RequestShowingFields = (props) => {
  const {
    formData,
    formHandlers,
    errors,
    momentFormat,
    timeOptions,
    dateOptions,
    tourFields,
  } = props;
  const constructDateLabel = (date) => (
    <>
      <div className="font-size--14">{momentFormat.month(date)}</div>
      <div className="bt-heading__h1">{momentFormat.calendarDay(date)}</div>
      <div className="font-size--14">{momentFormat.weekDay(date)}</div>
    </>
  );

  /**
   * Renders date options to Radio Components to the DOM
   *
   * @returns {React.Component} Mapped List of Radio Components
   */
  const renderDateOptions = () => dateOptions.map((date, i) => {
    const ariaLabel = `${momentFormat.month(date)}_${momentFormat.calendarDay(date)}_${momentFormat.weekDay(date)}`;
    const isChecked = momentFormat.full(formData.selectDate) === momentFormat.full(date);

    return (
      <Radio
        key={i}
        aria-label={ariaLabel}
        checked={isChecked}
        className={`at-contact-date-opt${i}`}
        dataCY={MOBILE_PROP_DETAILS.SCHEDULE_SHOWING_SELECT_DATE}
        id={`contactAgent__dateOpt${i}`}
        label={constructDateLabel(date)}
        name="selectDate"
        onChange={() => formHandlers.selectDate(date)}
        value={momentFormat.full(date)}
      />
    );
  });

  const scheduleShowingClasses = cx('request-showing__checkbox mb-15 grid grid--center', {
    disabled: tourFields.isVideoTour.value,
  });

  const scheduleVirtualShowingClasses = cx('request-showing__checkbox mb-15 grid grid--center', {
    disabled: tourFields.isVideoTour.value,
  });

  return (
    <div>
      <Grid gutters alignItems="center" className="my-16">
        <Cell noGrow>
          <Checkbox
            id="contactAgent__ScheduleShowing"
            dataCY={MOBILE_PROP_DETAILS.SCHEDULE_SHOWING_CHECKBOX}
            label={config.disableVideoTour ? 'Schedule a Showing' : 'Tour in Person'}
            labelProps={{
              className: scheduleShowingClasses,
              'data-cy': MOBILE_PROP_DETAILS.SCHEDULE_SHOWING_CHECKBOX_LABEL,
            }}
            checked={tourFields.isInPersonTour.value}
            onChange={tourFields.isInPersonTour.handler}
            disabled={tourFields.isVideoTour.value}
          />
        </Cell>
        {!config.disableVideoTour && (
          <Cell noGrow>
            <Checkbox
              id="contactAgent__RequestVideoTour"
              dataCY={MOBILE_PROP_DETAILS.VIDEOTOUR_CHECKBOX}
              label="Tour Via Video Chat"
              labelProps={{
                className: scheduleVirtualShowingClasses,
                'data-cy': MOBILE_PROP_DETAILS.VIDEOTOUR_CHECKBOX_LABEL,
              }}
              checked={tourFields.isVideoTour.value}
              onChange={tourFields.isVideoTour.handler}
              disabled={tourFields.isInPersonTour.value}
            />
          </Cell>
        )}
      </Grid>

      {(tourFields.isInPersonTour.value || tourFields.isVideoTour.value) && (
        <>
          <RadioGroup asButtons asInline id="contactAgentDateGroup">
            {renderDateOptions()}
          </RadioGroup>

          <Collapsible
            lazyRender
            noIcon
            className="border-0 mb-16"
            contentInnerClassName="collapsible__content"
            trigger={
              <TextButton className="request-showing__dates-expander" width="full">
                + More Dates
              </TextButton>
            }
            triggerWhenOpen={
              <TextButton className="request-showing__dates-expander" width="full">
                - Fewer Dates
              </TextButton>
            }
          >
            <div className="request-showing__calendar-wrapper">
              <Calendar
                id="contactAgent__datepicker"
                block
                selectDay={formHandlers.selectDate}
                date={formData.selectDate}
              />
            </div>
          </Collapsible>

          {/* Time of day picker */}
          <Grid gutters resetVertical xs="halves" className="mb-16 form__group--radio-buttons">
            {timeOptions.map((option) => (
              <Cell key={option}>
                <Radio
                  label={option}
                  id={`contactAgent__${option}`}
                  dataCY={MOBILE_PROP_DETAILS.SCHEDULE_SHOWING_SELECT_TIME}
                  name="selectTime"
                  checked={formData.selectTime === option}
                  value={option}
                  onChange={formHandlers.selectTime}
                />
              </Cell>
            ))}
            {errors.selectTime && (
              <Cell xs={12}>
                <ErrorMessage message={errors.selectTime} />
              </Cell>
            )}
          </Grid>
        </>
      )}
    </div>
  );
};

RequestShowingFields.propTypes = {
  timeOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  dateOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  momentFormat: PropTypes.object.isRequired,
  tourFields: PropTypes.shape({
    isVideoTour: PropTypes.shape({
      value: PropTypes.bool,
      handler: PropTypes.func,
    }),
    isInPersonTour: PropTypes.shape({
      value: PropTypes.bool,
      handler: PropTypes.func,
    }),
  }),
  // From createForm()
  formData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  formHandlers: PropTypes.object.isRequired,
};

export default RequestShowingFields;

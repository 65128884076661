/* eslint-disable no-confusing-arrow, camelcase, indent */
import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';
import { LOCATION_CHANGED } from 'redux-little-router';
import isRoute from 'utility/isRoute';
import { EMAIL_PREFERENCES } from 'routes';

import { emailPreferences as globalEmailPrefs } from 'models/visitor/reducers/selectors';
import { ConfirmationModal } from './constants';

import {
  submitEmailPreferencesForm,
  failedSubmitEmailPreferencesForm,
  successSubmitEmailPreferencesForm,
  toggleAgentSubscription,
  toggleLenderSubscription,
  toggleEAlertsSubscription,
  clickUnsubscribeAll,
  clickCancelUnsubscribeEAlertsModal,
  clickConfirmUnsubscribeEAlertsModal,
  clickCancelUnsubscribeAllModal,
  clickConfirmUnsubscribeAllModal,
  clickEditSavedSearchesUnsubscribeEAlertsModal,
  autoDismissEmailPrefsSuccessModal,
  clickCloseEmailPrefsSuccessModal,
  clickRetryInEmailPrefsFailureModal,
  clickBackInEmailPrefsFailureModal,
} from './actions';

const isSubmitting = createReducer(
  {
    [submitEmailPreferencesForm]: () => true,
    [clickConfirmUnsubscribeAllModal]: () => true,
    [clickRetryInEmailPrefsFailureModal]: () => true,
    [failedSubmitEmailPreferencesForm]: () => false,
    [successSubmitEmailPreferencesForm]: () => false,
  },
  false
);

/** Store default state as a constant so it can be compared against. */
const defaultFormState = {};
const form = createReducer(
  {
    [toggleAgentSubscription]: (state, isSubscribed) => ({
      ...state,
      agent: isSubscribed,
    }),
    [toggleLenderSubscription]: (state, isSubscribed) => ({
      ...state,
      lender: isSubscribed,
    }),

    // Only allow this click to immediately toggle the form state if
    // subscribing. Unsubscribing causes a confirmation to be shown.
    [toggleEAlertsSubscription]: (state, isSubscribed) =>
      isSubscribed
        ? {
            ...state,
            eAlerts: true,
          }
        : state,

    // Confirming is what actually disables this form element
    [clickConfirmUnsubscribeEAlertsModal]: state => ({
      ...state,
      eAlerts: false,
    }),
    [clickConfirmUnsubscribeAllModal]: () => ({
      agent: false,
      lender: false,
      eAlerts: false,
    }),
    [successSubmitEmailPreferencesForm]: () => defaultFormState,

    /**
     * This is another take on the problem of resetting state pertaining only to
     * a single route. Saving a default state and then ref. checking to avoid
     * unnecessary object allocation. This means that anytime you want to reset
     * state, you must use `defaultFormState`.
     */
    [LOCATION_CHANGED]: (state, payload) => {
      if (!isEmailPrefsRoute({ type: LOCATION_CHANGED, payload }) && state !== defaultFormState) {
        return defaultFormState;
      }

      return state;
    },
  },
  defaultFormState
);

// I think you can use null as a state in combineReducers, but not undefined...
const confirmationModal = createReducer(
  {
    // Showing the modals when performing the action being confirmed
    [clickUnsubscribeAll]: () => ConfirmationModal.UnsubscribeAll,
    [toggleEAlertsSubscription]: (state, isSubscribed) =>
      !isSubscribed ? ConfirmationModal.UnsubscribeEAlerts : state,

    // Ensure the modal is closed if the route changes (screen is no longer visible)
    [LOCATION_CHANGED]: (state, payload) =>
      !isEmailPrefsRoute({ type: LOCATION_CHANGED, payload }) ? null : state,

    // Close the modal when an option is clicked
    [clickCancelUnsubscribeEAlertsModal]: () => null,
    [clickConfirmUnsubscribeEAlertsModal]: () => null,
    [clickEditSavedSearchesUnsubscribeEAlertsModal]: () => null,
    [clickCancelUnsubscribeAllModal]: () => null,
    [clickConfirmUnsubscribeAllModal]: () => null,
  },
  null
);

/**
 * The `responseModal` state is passed down through the form component to the
 * ResponseModal as props.
 */
const defaultRespModalState = {
  isOpen: false,
  isSuccess: false,
  successText: 'Your preferences have been saved.',
};

function closeModal(state) {
  return {
    ...state,
    isOpen: false,
  };
}
const responseModal = createReducer(
  {
    // Opening the modal
    [failedSubmitEmailPreferencesForm]: state => ({
      ...state,
      isOpen: true,
      isSuccess: false,
    }),
    [successSubmitEmailPreferencesForm]: state => ({
      ...state,
      isOpen: true,
      isSuccess: true,
    }),

    // Closing the modal
    [autoDismissEmailPrefsSuccessModal]: closeModal,
    [clickCloseEmailPrefsSuccessModal]: closeModal,
    [clickRetryInEmailPrefsFailureModal]: closeModal,
    [clickBackInEmailPrefsFailureModal]: closeModal,
  },
  defaultRespModalState
);

export default combineReducers({
  isSubmitting,
  form,
  confirmationModal,
  responseModal,
});

// Selectors
export const local_isSubmittingEmailPrefs = x => x.isSubmitting;
export const local_getFormState = x => x.form;

/**
 * @returns {string | null} The ID of the active modal, or undefined if neither
 * is open
 */
export const local_getActiveConfModal = x => x.confirmationModal;

/** @returns {{isOpen, isSuccess, successText}} */
export const local_getResponseModal = x => x.responseModal;

// This bit should be defined where this reducer is installed to our state tree.
const select = selector => state => selector(state.accountView.emailPreferences);

const isEmailPrefsRoute = isRoute(EMAIL_PREFERENCES);
export const isSubmittingEmailPrefs = select(local_isSubmittingEmailPrefs);

export const activeConfirmationModal = select(local_getActiveConfModal);

export const getResponseModal = select(local_getResponseModal);
const getLocalEmailPrefsState = select(local_getFormState);

/**
 * Select the current state of the form. `undefined` only when the user's preferences
 * have not yet been loaded.
 *
 * @returns {undefined | { agent: boolean, lender: boolean, eAlerts: boolean }}
 */
export const getEmailPrefsFormState = state => {
  const saved = globalEmailPrefs(state);

  // Not yet loaded
  if (saved === undefined) {
    return undefined;
  }

  // Allow form state (dynamic) to shadow saved
  return {
    agent: !(saved.optOutAgent || saved.optOutGlobal),
    lender: !(saved.optOutLender || saved.optOutGlobal),
    eAlerts: !(saved.optOutEAlerts || saved.optOutGlobal),
    ...getLocalEmailPrefsState(state),
  };
};

import React from 'react';
import ContactAgentForm from 'components/MobileContactAgent/ContactAgent';
import SettingsCard from 'screens/Account/SettingsCard';
import withData from './withData';

const ConnectedContactAgentForm = withData(ContactAgentForm);

const ContactAgent = () => (
  <SettingsCard title="Your Agent">
    <ConnectedContactAgentForm
      hideRequestShowing
      hideNameInput
      hidePhoneInput
      hideEmailInput
      commentsLabel="How can I help?"
      bodyInitialText=""
      hideBorderBottom
      widget
    />
  </SettingsCard>
);

export default ContactAgent;

/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Firebase from 'utility/firebase';
import qs from 'querystring';
import BottomPrompt from './Layouts/BottomPrompt';

const MobileAppDownloadHeader = (props) => {
  const { pathname, bannerEnabled, queryParams } = props;

  /** Session Key for Hide/Show */
  const sessionKey = 'hide-app-banner';
  /** Paths Allowed to Show Mobile App Download */
  const allowPath = ['/results-gallery', '/homes', '/listing'];

  /**
   * See if the banner is hidden on load due to being closed.
   * @return {Bool}
   */
  const canShowBanner = () => {
    const sessionValue = sessionStorage.getItem(sessionKey);

    if (sessionValue) {
      return false;
    }

    if (!bannerEnabled) {
      return false;
    }

    for (const path of allowPath) {
      if (pathname.search(path) !== -1) {
        return true;
      }
    }

    return false;
  };

  /**
   * Hide the mobile app header for the session.
   * @return {Void}
   */
  const onCloseBanner = () => {
    sessionStorage.setItem(sessionKey, true);
    setShowBanner(false);
  };

  /**
   * Generates Firebase URL for App Link
   * @return {String}
   */
  const firebaseUrl = () => {
    const utmParams = {
      utm_campaign: 'App Invite',
      utm_source: 'App Invite Banner',
      utm_content: 'HomeSearchNow CTA',
      utm_medium: 'web',
    };

    const query = {
      ...queryParams,
      ...utmParams,
    };

    const params = {
      // App Path + Website Route + Query String Params
      link: `https://www.homesearchnow.com${pathname}?${qs.encode(query)}`,
    };

    return Firebase.createUrl(params);
  };

  const [showBanner, setShowBanner] = useState(canShowBanner());
  const url = firebaseUrl();

  useEffect(() => setShowBanner(canShowBanner()), [pathname, bannerEnabled]);

  return !showBanner ? null : <BottomPrompt url={url} onCloseBanner={onCloseBanner} />;
};

MobileAppDownloadHeader.propTypes = {
  pathname: PropTypes.string.isRequired,
  bannerEnabled: PropTypes.bool,
  queryParams: PropTypes.object,
};

export default MobileAppDownloadHeader;

/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-act';
import { visitor } from 'BoomTown';

export const clickSaveSearch = createAction(
  'No Results > Click save search',
  () => {},
  () => ({ visitor })
);
export const logNoResults = createAction('No Results > Logged in GTM');

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { strip, decorate } from 'utility/string';
import emptyFunction from 'utility/emptyFunction';
import { Input } from 'coreComponents';
import getCursorPos from 'utility/getCursorPos';

class PriceInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      /** @type string The clean number, but as a string */
      value: this.valueToState(this.props.value),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: this.valueToState(nextProps.value),
      });
    }
  }

  setInputRef = c => {
    this.props.inputRef(c);
    this._input = c;
  };

  getCursorPos(newVal, oldVal, selectionStart) {
    return getCursorPos(newVal, oldVal, selectionStart, '$ '.length);
  }

  valueToState = value => (value !== this.props.defaultValue ? String(value) : '');

  handleChange = e => {
    const el = e.target;

    // The new value is straight from the input, so it isn't always decorated properly.
    // ie. '1', '$ 1000' etc
    const value = el.value;

    // Max of selectionStart and selectionEnd is taken for the patch of pixel and other mobile device caret bug
    const currentCaretPosition = Math.max(el.selectionStart, el.selectionEnd);
    const pos = this.getCursorPos(value, this.state.value, currentCaretPosition);

    // Because we decorate this string, we _always_ have to manage cursor position
    setTimeout(() => this._input.setSelectionRange(pos, pos));

    this.setState({
      value: strip(value),
    });
  };

  /**
   * @returns {Number}
   */
  parseValue = () => {
    const parsed = parseInt(this.state.value, 10);
    if (isNaN(parsed)) {
      return this.props.defaultValue;
    }
    return parsed;
  };

  /**
   * Trigger a blur event on the input when Enter is pressed, which commits the
   * change to the input.
   * @param {KeyboardEvent} e
   */
  handleKeyDown = e => {
    this.props.onKeyDown(e);
    if (e.key === 'Enter') {
      e.target.blur();
      e.preventDefault();
    }
  };

  handleBlur = e => {
    this.props.onBlur(e);
    this.props.onPriceChange(this.parseValue());
  };

  // The regex pattern here triggers the numpad on ios
  render() {
    return (
      <Input
        type="text"
        pattern="[0-9]*"
        inputMode="numeric"
        id={this.props.id}
        name={this.props.name}
        tabIndex={this.props.tabIndex}
        helperTextProps={{ children: this.props.placeholder }}
        className={`at-${this.props.id} bt-off-canvas__price-range-input`}
        title={`Please enter a ${this.props.placeholder}`}
        onChange={this.handleChange}
        onFocus={this.props.onFocus}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        value={decorate(this.state.value)}
        ref={this.setInputRef}
        onClick={this.props.onClick}
        dataCY={this.props.dataCY}
        style={this.props.style}
      />
    );
  }
}

PriceInput.displayName = 'PriceInput';
PriceInput.propTypes = {
  /** @type {(number|null) => void} */
  onPriceChange: PropTypes.func.isRequired,
  /** @type {number} Initial committed value */
  value: PropTypes.number,
  /** @type {number} A fallback value to represent empty */
  defaultValue: PropTypes.number,

  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,

  inputRef: PropTypes.func,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  tabIndex: PropTypes.number,
  onClick: PropTypes.func,
  dataCY: PropTypes.string,
  style: PropTypes.object
};
PriceInput.defaultProps = {
  defaultValue: null,
  inputRef: emptyFunction,
  onBlur: emptyFunction,
  onFocus: emptyFunction,
  onKeyDown: emptyFunction,
  style: {},
};

export default PriceInput;

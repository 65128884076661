import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ITags from 'btPropTypes/tags';
import { SecondaryButton } from 'coreComponents';
import FAIcon from 'components/core/FAIcon';
import { SEARCH_BAR } from 'cypress_constants';

export default class SearchTag extends Component {
  /**
   * On click, call the `onClick` prop with both the ClickEvent and the prop
   * of the tag object.
   * @param  {ClickEvent} e
   */
  handleClick = e => {
    if (this.props.onClick) {
      this.props.onClick(e, {
        prop: this.props.prop,
        value: this.props.value,
      });
    }
  };

  render() {
    const { displayName, prop } = this.props;
    const hasCloseIcon = this.props.onClick !== undefined;
    const text = displayName || prop;
    const closeIcon = hasCloseIcon ? <FAIcon icon="times-circle" type="solid" /> : null;

    return (
      <li>
        <SecondaryButton dataCY={SEARCH_BAR.SEARCH_TAG} size="small" onClick={this.handleClick} className="btn--tag">
          {text} {closeIcon}
        </SecondaryButton>
      </li>
    );
  }
}

SearchTag.propTypes = {
  onClick: PropTypes.func,
  ...ITags,
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

// Code that determines if a URL should be `push()`ed has been shamelessly
// stolen from 'redux-little-router'. We currently have > 6 places in our code
// where we do this kind of thing (and not as thoroughly) so TODO: Make use of
// this logic, either by directly using this component or factoring it out.

const LEFT_MOUSE_BUTTON = 0;

function isNotLeftClick(e) {
  return Boolean(e.button && e.button !== LEFT_MOUSE_BUTTON);
}

function hasModifier(e) {
  return Boolean(e.shiftKey || e.altKey || e.metaKey || e.ctrlKey);
}

/**
 * ATTENTION: GTM has issues with this function (CNS-6125, CNS-5063) where
 * Just Links default GTM trigger messes up preventDefault(). If you're debugging code
 * and got to here, make sure to checkout recent GTM changes and related triggers.
 *
 *  When responding to a click, if this returns true then we do not want to
 * `preventDefault()` and invoke the `onClick` callback.
 *
 * @param {MouseEvent} e
 */
function shouldIgnoreClick(e) {
  return hasModifier(e) || isNotLeftClick(e) || e.defaultPrevented;
}

/**
 * Renders an anchor tag, but with a click handler proxy that only calls
 * `preventDefault()` (preventing actual navigation) and invokes the callback
 * if the interaction is a "normal" click/tap. Otherwise, the event is left
 * alone for the browser to respond to normally (for things like long tap
 * actions, open in new tab, etc.)
 */
export default class SPALink extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    href: PropTypes.string,
    dataCY: PropTypes.string,
  };

  static defaultProps = {
    onClick: () => {},
  };

  handleClick = e => {
    if (shouldIgnoreClick(e)) {
      return;
    }
    e.preventDefault();
    this.props.onClick(e);
  };

  render() {
    const { dataCY, ...rest } = this.props;
    return <a {...rest} data-cy={dataCY} onClick={this.handleClick} />;
  }
}

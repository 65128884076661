/* eslint-disable import/imports-first */
import { fork, select, takeEvery, cancel, all } from 'redux-saga/effects';
import { isMobile } from 'selectors/browser';
import { changeMobileViewport } from 'actions/browserActions';

import visitorEffects from 'services/visitor/visitorSagas';
import bodyAttrEffects from 'services/bodyAttr/bodyAttrSagas';
import modalEffects from 'models/modals/effects';

import mobileAccount from 'screens/Account/Mobile/effects';
import editAccount from 'screens/Account/Forms/EditAccount/effects';
import emailPrefs from 'screens/Account/Forms/EditEmailPrefs/effects';
import textPrefs from 'screens/Account/Forms/EditTextPrefs/effects';
import editSavedSearches from 'screens/Account/Forms/EditSavedSearches/effects';
import changePassword from 'screens/Account/Forms/ChangePassword/effects';
import desktopAccountContactAgentEffects from 'screens/Account/Desktop/ContactAgent/effects';
import mobileMenu from 'screens/MobileMenu/effects';
import resultsMap from 'screens/ResultsMap/effects';

import listingCardSaga from 'components/Card/effects';
import desktopMap from 'backbone/Views/Listings/map/effects';
import desktopDetailsPage from 'backbone/Views/Listings/single/effects';
import homepagePropertySearch from 'components/HomepageSearch/Consumer/effects';
import propertySearch from 'components/BallerBox/effects';
import desktopSearchMenu from 'components/SearchMenu/effects';
import desktopSearchBar from 'components/SearchBar/effects';
import desktopTagList from 'components/Common/Tags/effects';
import oldMobileResultsHeader from 'components/MobileResultsHeader/Current/effects';
import mobileResultsHeader from 'components/Common/MobileResultsHeader/effects';
import homeVal from 'components/HomepageHero/HomeValFinder/effects';
import qualifyingQuestionsSaga from './qualifyingQuestions';
import mobileDetailsPage from './detailsPage';

import offCanvas from './offCanvas';
import contactAgentForm from './contactAgentForm';
import mobileSearchMenu from './mobileSearchMenu';
import mobileSaveSearch from './mobileSaveSearch';
import mobileGallery from './gallery';
import logActionEffects from './logActions';
import sendToGTM from './logGTM';
import finalizeOptimizeState from './optimize';
import desktopSaveSearch from './desktopSaveSearch';
import nearbySearch from './nearbySearch';

/** Sagas (watchers) that should be running when Mobile is active. */
const xpSagas = [
  mobileDetailsPage,
  contactAgentForm,
  mobileSearchMenu,
  mobileGallery,
  mobileSaveSearch,
  mobileAccount,
];

export default function* rootSaga() {
  let xpTasks = [];

  // Initial load
  const isEnabledByServer = yield select(isMobile);
  if (isEnabledByServer) {
    // `fork`ing a saga yields the task which can be canceled `all` would have
    // just started it and blocked
    for (const s of xpSagas) {
      xpTasks.push(yield fork(s));
    }
  }

  // Things that can change whether these forked processes are running
  yield takeEvery(changeMobileViewport, syncXPTasks);

  function* syncXPTasks() {
    const isEnabled = yield select(isMobile);
    const areRunning = Boolean(xpTasks.length);

    if (isEnabled !== areRunning) {
      if (isEnabled) {
        for (const s of xpSagas) {
          xpTasks.push(yield fork(s));
        }
      } else {
        yield cancel(...xpTasks);
        xpTasks = [];
      }
    }
  }

  yield all([
    // Global
    ...logActionEffects,
    ...sendToGTM,
    ...finalizeOptimizeState,
    ...bodyAttrEffects,

    // Models
    ...modalEffects,

    // Components
    listingCardSaga,

    // Feature specific
    ...qualifyingQuestionsSaga,
    ...desktopAccountContactAgentEffects,
    ...visitorEffects,
    ...editAccount,
    ...emailPrefs,
    ...textPrefs,
    ...editSavedSearches,
    ...changePassword,
    ...mobileMenu,
    ...homepagePropertySearch,
    ...propertySearch,
    ...mobileResultsHeader,
    ...resultsMap,
    ...homeVal,
    ...nearbySearch,

    // Desktop
    ...desktopSearchBar,
    ...desktopSearchMenu,
    ...desktopTagList,
    ...desktopSaveSearch,
    ...desktopDetailsPage,
    ...desktopMap,

    // That xl phone view
    ...offCanvas,
    ...oldMobileResultsHeader,
  ]);
}

import { delay } from 'redux-saga';
import { race, call, put, takeEvery, take } from 'redux-saga/effects';
import { visitor } from 'BoomTown';
import {
  submitPasswordChange,
  receivePasswordChangeFailure,
  receivePasswordChangeSuccess,
  clickCloseChangePasswordSuccessModal,
  autoDismissChangePasswordSuccessModal,
} from './actions';

export default [takeEvery(submitPasswordChange, changePassword)];

function* changePassword(action) {
  const { password, newPassword } = action.payload;
  try {
    yield call([visitor, visitor.updatePasswordPromise], {
      Password: password,
      NewPassword: newPassword,
    });
  } catch (e) {
    const { status, responseText } = e;
    yield put(receivePasswordChangeFailure({ statusCode: status, responseText }));
    return;
  }
  yield put(receivePasswordChangeSuccess());
  yield race([call(dismissAfterTimeout), take(clickCloseChangePasswordSuccessModal)]);
}

function* dismissAfterTimeout() {
  yield call(delay, 2000);
  yield put(autoDismissChangePasswordSuccessModal());
}

import React from 'react';
import { Link } from 'redux-little-router';
import { config, tenant } from 'BoomTown';
import { Grid, Cell } from 'components/core/Grid';
import FAIcon from 'components/core/FAIcon';
import { MOBILE_NAVBAR } from 'cypress_constants';

const MobileSiteHeader = () => {
  const logoUrl = config.inner_logo_uri;
  const legalName = tenant.legalName;

  return (
    <div className="bt-header__mobile py-4 px-12 ">
      <Grid alignItems="center" justifyContent="between">
        <Cell>
          <Link href="/" className="at-header-logo" data-cy={MOBILE_NAVBAR.MAIN_LOGO}>
            <img className="bt-inner-logo" src={logoUrl} alt={legalName} />
          </Link>
        </Cell>
        <Cell xs={2} className="text-xs--right">
          <Link href="/menu/" className="at-menu-btn">
            <FAIcon icon="bars" type="solid" dataCY="mobile-navbar__hamburger-menu" />
          </Link>
        </Cell>
      </Grid>
    </div>
  );
};

export default MobileSiteHeader;

import React, { useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * Item
 * Formats and renders a given result
 * @return {ReactElement}
 */
const Item = React.forwardRef((props, ref) => {
  const { active = false, item, handleItemSelect, handleSetActiveIndex } = props;

  const itemRef = useRef(ref);

  /**
   *
   * @param {*} event
   */
  const onClick = (event) => {
    event.preventDefault();
    handleItemSelect(item);
  };

  /**
   *
   */
  const onMouseEnter = () => {
    handleSetActiveIndex(item.index);
  };

  return (
    <li
      className={active ? 'active at-suggestion-item' : 'at-suggestion-item'}
      onMouseEnter={onMouseEnter}
      ref={itemRef}
    >
      <a onClick={onClick} className="autocomplete-item">
        {item.FullName}
      </a>
    </li>
  );
});

Item.propTypes = {
  active: PropTypes.bool,
  item: PropTypes.shape({
    FullName: PropTypes.string,
    ListingCount: PropTypes.number,
    Name: PropTypes.string,
    PerfectMatch: PropTypes.boolean,
    PhoneticName: PropTypes.string,
    QSModifier: PropTypes.string,
    Score: PropTypes.number,
    Type: PropTypes.string,
    index: PropTypes.number,
  }).isRequired,
  handleItemSelect: PropTypes.func.isRequired,
  handleSetActiveIndex: PropTypes.func.isRequired,
};

Item.displayName = 'AutocompleteResultItem';

export default Item;

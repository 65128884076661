import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import * as r from 'routes';
import ButtonGroup from 'components/core/Buttons/ButtonGroup';
import FAIcon from 'components/core/FAIcon';
import SPALink from 'components/Common/SPALink';
import { SecondaryButton } from 'coreComponents';

function ViewToggleButton({ id, isActive, icon, label, className, ...rest }) {
  return (
    <SPALink
      {...rest}
      role="button"
      className={cx('btn-small', `bt-view-toggle__show-${id}`, {
        'uk-active': isActive,
      })}
    >
      <SecondaryButton className={cx('btn--size-small', 'btn-group__item-btn', className)}>
        <FAIcon icon={icon} type="solid" /> {label}
      </SecondaryButton>
    </SPALink>
  );
}

ViewToggleButton.propTypes = {
  id: PropTypes.string,
  isActive: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

function ResultsViewToggle({
  onClick = function noop() {},
  routeName = '',
}) {
  return (
    <ButtonGroup label="Results View Switcher" className="bt-view-toggle ml-4">
      <ViewToggleButton
        className="btn-group__item-btn--first"
        id="map"
        href={`/${r.RESULTS_MAP}/`}
        title="Map View"
        label="Map"
        icon="map-marker-alt"
        isActive={routeName === r.RESULTS_MAP}
        onClick={() => onClick(r.RESULTS_MAP)}
      />
      <ViewToggleButton
        className="btn-group__item-btn--last"
        id="gallery"
        href={`/${r.RESULTS_GALLERY}/`}
        title="Gallery View"
        label="Gallery"
        icon="th"
        isActive={routeName === r.RESULTS_GALLERY}
        onClick={() => onClick(r.RESULTS_GALLERY)}
      />
    </ButtonGroup>
  );
}

ResultsViewToggle.propTypes = {
  onClick: PropTypes.func,
  routeName: PropTypes.string,
};

export default ResultsViewToggle;

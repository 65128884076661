import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/**
 * Card
 * A Card UI that is used a content container
 * @todo Possibly add more "Card" sub-components for rendering certain UI feature
 *
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.muted Prevents clicks on the card and sets opacity to half
 * @param {any} props.children
 */
const Card = ({ className, muted, dataCY, ...rest }) => (
  <div
    data-cy={dataCY}
    className={
      cx(
        className,
        'card',
        { 'card--muted': muted }
      )
    }
    {...rest}
  />
);
Card.propTypes = {
  className: PropTypes.any,
  dataCY: PropTypes.string,
  muted: PropTypes.bool,
  children: PropTypes.node,
};
export default Card;

/**
 * Card Body
 * Used as a child of a Card component instance and provides proper padding for Card content
 *
 * @param {Object} props
 * @param {string} props.className
 * @param {any} props.children
 */
export const CardBody = ({ className, children }) => (
  <div
    className={
      cx(
        className,
        'card__body',
      )
    }
  >
    {children}
  </div>
);
CardBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

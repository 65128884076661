/* eslint-disable no-param-reassign, no-empty-function */
import { config, search, listings as listingsColl } from 'BoomTown';
import _ from 'underscore';
import isRestrictedAgentSub from 'utility/isRestrictedAgentSubdomain';
import {
  push as routerPush,
  replace as routerReplace,
  //   Location as TLocation,
} from 'redux-little-router';

import { select, put, call } from 'redux-saga/effects';
import * as r from 'routes';
import { getRouteID } from 'selectors/router';
import { isDesktopMapEnabled } from 'selectors/optimize';
import { stringify } from 'querystring';
import * as mpA from 'services/mapParams/actions';
import * as mpS from 'services/mapParams/reducer';

/**
 * Common navigation logic for adding/removing search terms and viewing a results page.
 * If you find yourself needing to go to a different page, or change the querystring you
 * probably want your own effect.
 *
 * @param {Object} nextLocation
 * @param {'/results-gallery'|'/results-map/'|'/results/'} [nextLocation.pathname=undefined] A results path
 * @param {object} nextLocation.query optional
 * @param {string} nextLocation.hash optional
 * @param {'push' | 'replace'} method
 */
export function* go(nextLocation, method = 'push') {
  const currentRouteID = yield select(getRouteID);
  const isCurrentRouteResults = [r.RESULTS_GALLERY, r.RESULTS_MAP].includes(
    currentRouteID
  );

  if (nextLocation.pathname === undefined) {
    nextLocation.pathname = isCurrentRouteResults ? `/${currentRouteID}/` : '/results-gallery/';
  }

  // Could be moved to middleware
  // CNS-5828, CNS-2454: Searches should be redirected on non-IDX approved subdomains
  if (isRestrictedAgentSub(config)) {
    const query = stringify(nextLocation.query);
    window.location.assign(`https://www${config.cookieDomain}${nextLocation.pathname}?${query}`);
    return;
  }

  let resultsRouteFromMap = false;

  // When desktop maps are worked this can be moved into that domain
  // Map parameters are added if you navigate back to map page
  if (nextLocation.pathname === `/${r.RESULTS_MAP}/`) {
    const lastMapParams = yield select(mpS.get);

    // Avoid work / actions when we are just moving from map to map
    if (!_.isEmpty(lastMapParams)) {
      nextLocation.query = {
        ...nextLocation.query,
        ...lastMapParams,
      };
      yield put(mpA.clear());
    }
  } else if (currentRouteID === r.RESULTS_MAP) {
    const mapParamKeys = ['swlat', 'swlng', 'nelat', 'nelng', 'midlat', 'midlng', 'zoom'];

    const lastMapParams = {};
    mapParamKeys.forEach(key => (lastMapParams[key] = nextLocation.query[key]));
    yield put(mpA.set(lastMapParams));

    mapParamKeys.forEach(key => delete nextLocation.query[key]);

    const isNewDesktopMap = yield select(isDesktopMapEnabled);

    // We only care if we're coming from the Map route to another results route
    resultsRouteFromMap = isNewDesktopMap && [`/${r.RESULTS_GALLERY}/`].includes(
      nextLocation.pathname
    );
  }

  const routerMethod = method === 'replace' ? routerReplace : routerPush;

  // CNS-7080
  // If rid exists within the query, we need to remove it from the search.
  if (nextLocation.query) {
    yield filterRIDSearch(nextLocation.query);
  }

  yield put(routerMethod(nextLocation));

  // CNS-6105
  // Ensure that the listings collection is hydrated for server-side loads on desktop
  // This can be removed when the gallery is transitioned to relying on Redux rather than
  // the listings collection
  if (resultsRouteFromMap) {
    yield call([listingsColl, listingsColl.searchChange], search);
  }
}

/**
 * Strip the `rid` value from the search if a new param is applied/changed, unless that param exists
 * in the array of allowed keys.
 *
 * @param {Object} [nextSearch={}]
 * @returns {Object}
 */
function* filterRIDSearch(nextSearch = {}) {
  let removeRid = false;

  // Keys that are allowed to change with rid existing
  const ridAllowedKeys = [
    'pageindex',
    'pagecount'
  ];

  // Current Search for Comparison
  const currentSearch = yield call([search, search.toJSON]);

  // If next search contains rid
  if (nextSearch.rid) {
    for (const key in nextSearch) {
      if (!ridAllowedKeys.includes(key)) {
        if (!currentSearch[key] || (currentSearch[key] !== nextSearch[key])) {
          removeRid = true;
        }
      }
    }
  }

  if (removeRid) {
    delete nextSearch.rid;
  }

  return nextSearch;
}

/**
 * Add terms to the user's search and navigate them to a results page.
 *
 * @param {Object} newParams For params that allow multiple values we'll add all given values
 * @param {'push' | 'replace'} method
 */
export function* add(newParams, method = 'push') {
  const currentSearch = yield call([search, search.toJSON]);

  // Sorting is not supported for the favorites views
  delete currentSearch.favs;

  const nextQuery = yield call([search, search.addReducer], currentSearch, newParams);

  yield go({ query: nextQuery }, method);
}

/**
 * Remove terms from a user's search and navigate them to a results page.
 *
 * @param {Object} paramsToRemove For params that allow multiple values we'll
 * remove any given values. If null is given we'll remove all values.
 * @param {'push' | 'replace'} method
 */
export function* remove(paramsToRemove, method = 'push') {
  const currentSearch = yield call([search, search.toJSON]);

  const nextQuery = yield call([search, search.removeReducer], currentSearch, paramsToRemove);
  yield go({ query: nextQuery }, method);
}

// TODO?
export function* replace() {}

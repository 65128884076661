import { createReducer } from 'redux-act';
import { config } from 'BoomTown';
import { sliceFactory } from 'reducers/util';
import { SearchConverter } from 'models/search/services';

// Actions
import { initializeState } from 'actions/bootstrapActions';
import {
  open as legacyOffCanvasOpen,
  close as legacyOffCanvasClose,
} from 'actions/OffCanvasActions';
import { clickSearchMenuLink } from 'screens/MobileMenu/actions';
import {
  clickSearchInResultsHeader,
  clickNewSearchInResultsHeader,
} from 'actions/MobileGalleryActions';
import {
  clickSearch as legacyGalleryClickSearch,
  clickNewSearch as legacyGallerClickNewSearch,
} from 'actions/GalleryActions';

// search.pending Case Reducers
import filterReducers from './cases/filterReducers';
import searchBoxReducers from './cases/searchBoxReducers';
import searchTagReducers from './cases/searchTagReducers';
import locationReducers from './cases/locationReducers';

import { defaultSearch, PENDING_BRANCH } from '../../constants';

/**
 * Populate the pending search branch by using current value of the committed search branch and the
 * current mapBounds state. `mapCommittedToPending` will handle mapping the coordinates in mapBounds
 * to the correct `mapbounds` obj into a `pending` search obj
 *
 * @param {Object} state The local pending search branch state
 * @param {any} payload The current action's payload
 * @param {Object} parentState The branch's parent state object
 */
export const populateWithCurrentSearch = (state, payload, parentState) => {
  let mapBounds = {};
  if (config.useListMapResultsSync && parentState.mapBounds) {
    mapBounds = parentState.mapBounds;
  }

  return ({
    ...SearchConverter.mapCommittedToPending({
      ...parentState.committed,
      ...mapBounds,
    }),
  });
};

/**
 * Populate the pending search branch by using the defaultSearch constant
 */
export const populateWithDefaultSearch = () => ({
  ...SearchConverter.mapCommittedToPending(defaultSearch)
});

/**
 * This set of case reducers for the `search.pending` branch are not broken out into their own
 * slice reducer and combined with the other branches' reducers via `combineReducers` like we
 * normally would do. The reason being: we need access to other branches in the parent `search`
 * state object (ie `search.committed`) for setting certain values inside the `pending` branch.
 * So for each matching action, we use the `sliceFactory` util fn that accepts the name of
 * the branch of state we'd like to update and the case reducer fn used to update that branch. This
 * util fn protects the context of the state change while still providing sibling state in scope
 * for our slice reducer.
 */
export default createReducer({
  [initializeState]: sliceFactory(
    PENDING_BRANCH,
    (state, { pendingSearch }) => {
      // If we have a stored pending search lets use it
      if (pendingSearch != null) {
        return { ...state, ...pendingSearch };
      }

      return state;
    }
  ),

  /**
   * OPEN EXISTING SEARCH
   */
  [clickSearchInResultsHeader]: sliceFactory(PENDING_BRANCH, populateWithCurrentSearch), // Mobile Results
  [legacyGalleryClickSearch]: sliceFactory(PENDING_BRANCH, populateWithCurrentSearch), // Legacy
  [legacyOffCanvasOpen]: sliceFactory(PENDING_BRANCH, populateWithCurrentSearch), // Legacy

  /**
   * START NEW SEARCH
   */
  [clickNewSearchInResultsHeader]: sliceFactory(PENDING_BRANCH, populateWithDefaultSearch), // Mobile Gallery
  [clickSearchMenuLink]: sliceFactory(PENDING_BRANCH, populateWithDefaultSearch), // Mobile Menu
  [legacyGallerClickNewSearch]: sliceFactory(PENDING_BRANCH, populateWithDefaultSearch), // Legacy

  /**
   * State Changes From Filter Controls
   */
  ...filterReducers,

  /**
   * State Changes From the Search Box UI
   */
  ...searchBoxReducers,

  /**
   * State Changes From the Search Tags UI
   */
  ...searchTagReducers,

  /**
   * State Changes related to legacy "Nearby Search"
   */
  ...locationReducers,

  /**
   * CLOSE THE OFFCANVAS SEARCH
   *
   * Only used on the legacy OffCanvas search component and can be removed once that component is
   * finally removed
   */
  [legacyOffCanvasClose]: sliceFactory(PENDING_BRANCH, () => ({})),
});

import { CONTACT_AGENT } from 'routes';
import { takeLatest, takeEvery, all } from 'redux-saga/effects';
import goBackIfNotFirstRoute from 'sagas/routerTasks/goBackIfNotFirstRoute';
import isRoute from 'utility/isRoute';
import * as mobileContactAgentModalActions from 'actions/MobileContactAgentActions';
import {
  handleSubmitContactForm,
  handleAPIResponse,
  maybeFetchNextAgentForContact,
} from 'components/MobileContactAgent/effects';

export default function* contactAgentFormSaga() {
  yield all([
    takeLatest(isRoute(CONTACT_AGENT), maybeFetchNextAgentForContact),
    takeLatest(
      // action
      mobileContactAgentModalActions.submitContactForm,
      // side-effect fn
      handleSubmitContactForm,
      // args passed to side-effect fn
      {
        responseAction: mobileContactAgentModalActions.receiveContactFormRes,
        registerVisitor: true
      }
    ),
    takeEvery(
      // action
      mobileContactAgentModalActions.receiveContactFormRes,
      // side-effect fn
      handleAPIResponse,
      // args passed to side-effect fn
      {
        clickCloseModalAction: mobileContactAgentModalActions.closeContactFormSuccessModal,
        autoCloseModalAction: mobileContactAgentModalActions.autoCloseContactFormSuccessModal,
      }
    ),
    takeEvery(
      [
        mobileContactAgentModalActions.autoCloseContactFormSuccessModal,
        mobileContactAgentModalActions.closeContactFormSuccessModal,
        mobileContactAgentModalActions.clickClose
      ],
      goBackIfNotFirstRoute
    ),
  ]);
}

import PropTypes from 'prop-types';
import React, { Component, Children } from 'react';
import cx from 'classnames';

/**
 * A trigger component for Dropdown. Will use its immediate child as the trigger.
 */
class DropdownTrigger extends Component {
  render() {
    if (!Children.count(this.props.children)) {
      return null;
    }
    const child = Children.only(this.props.children);

    return React.cloneElement(child, Object.assign({
      className: cx({
        [this.props.className]: !!this.props.className,
        [child.props.className]: !!child.props.className
      }),
      // Also honor the child's onClick handler
      onClick: (e) => {
        this.props.onClick(e);
        if (child.props.onClick) {
          child.props.onClick(e);
        }
      }
    }, Object.prototype.hasOwnProperty.call(this.props, 'isOpen') ? {
      isOpen: this.props.isOpen
    } : {}));
  }
}

DropdownTrigger.displayName = 'DropdownTrigger';
DropdownTrigger.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.element.isRequired,
  className: PropTypes.string
};

export default DropdownTrigger;

/* global google */
import { all, call, take, race, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { push as routerPush } from 'redux-little-router';
import { config } from 'BoomTown';

// Selectors
import { getGeolocation } from 'services/geolocation/reducer';

// Tasks
import { beginWatchingLocation } from 'services/geolocation/effects';
import * as search from 'sagas/routerTasks/search';
import handleNearbyButtonTap from 'consumerApp/views/Map/effects/handleNearbyButtonTap';

// Actions
import {
  receiveCurrentLocation,
  startLocationRequest,
  cancelLocationRequest
} from 'services/geolocation/actions';
import * as CNSAPPMapActions from 'consumerApp/views/Map/actions';
import * as actionBarActions from '../ActionBar/actions';
import { onDrawEnd, exitDrawingMode } from './PolygonOverlay/actions';
import { receiveResults, errorResults } from './actions';
import { getMapType, getIsNearbyActive } from '../reducer';

/**
 * A root saga for effects that involve the Google map instance.
 *
 * @param {google.maps.Map} map
 */
export default function* mapSaga(map) {
  yield all([
    // Nearby Handling
    takeLatest(
      actionBarActions.onNearbyClick,
      function* focusUsersLocation() {
        const isNearbyActive = yield select(getIsNearbyActive);

        // Don't start the location watcher if we're in the HomeSearchNOW App
        if (config.isMobileApp && !config.disableLocationAppMessage) {
          if (isNearbyActive) {
            yield put(startLocationRequest());
          }

          yield call(handleNearbyButtonTap, isNearbyActive);
        } else {
          // Track the user's location after the first nearby click. Pass in `isNearbyActive` as
          // an extra failsafe to make sure the watcher isn't cancelled inadvertently.
          yield call(beginWatchingLocation, isNearbyActive);
        }

        if (isNearbyActive) {
          // Sync. wait for coords
          const coords = yield call(getCoords);
          yield call(zoomMapToUsersLocation, coords, map);
        } else {
          yield put(cancelLocationRequest());
        }
      }
    ),

    // Pan to the user's location
    // Only called on Native App when `latlonrad` search param is added, and is separate from the
    // normal "Nearby Search" functionality for the map that is usually triggered by the
    // `onNearbyClick` action.
    takeLatest(
      CNSAPPMapActions.panToUserForNearbySearch,
      function* panToUsersCoords({ payload: coords }) {
        yield call([map, map.panTo], { lat: coords.lat, lng: coords.lng });
      }
    ),

    // Layer Handling
    takeEvery(
      actionBarActions.onMapTypeClick,
      function* toggleMapLayer() {
        /** @type {google.maps.MapTypeId} */
        const nextType = yield select(getMapType);
        yield call([map, map.setMapTypeId], nextType);
      }
    ),

    // Polygon / Drawing Handlers
    takeLatest(
      actionBarActions.onPolygonClick,
      function* disableMapInteractions() {
        yield setMapInteractions(false, map);
      }
    ),

    takeLatest(
      exitDrawingMode,
      function* handleDrawingModeExit() {
        yield call(renableMapInteractions, map);
      }
    ),

    takeEvery(
      onDrawEnd,
      /**
       * @generator
       * @function applyPolygonToUrl
       *
       * Handle applying the `polygon` parameter to the current search.
       *
       * @param {ReduxAction} action
       * @param {{path: PolygonPath}} action.payload
       *
       * @returns void
       */
      function* applyPolygonToUrl({ payload }) {
        if (!payload.path) {
          return;
        }

        const polygon = window.btoa(JSON.stringify(payload.path));

        /*
        CNSAPP-678: The mapBounds are pushed directly to the query string, so going to the route
        of calling `search.add()` (using Backbone Search) which will inadvertently strip the
        mapBounds from the query string. This sets `mapBounds` in our `search` state (Redux)
        to `null`, which causes `getSearchQuery()` in the `syncMobileMapResults()` task to
        also return `null` preventing a new search :whew:. Instead, handle pushing the polygon
        to the query directly and persist the previous mapBounds for this initial search.
        */
        if (config.isMobileApp) {
          yield put(routerPush({ query: { polygon } }, { persistQuery: true }));
        } else {
          yield search.add({ polygon });
        }

        // Wait until we either receive results or an error before exiting Drawing Mode.
        yield race([
          take(receiveResults),
          take(errorResults),
        ]);
        yield put(exitDrawingMode());
      }
    ),

    takeEvery(
      actionBarActions.onClearPolygonClick,
      /**
       * @generator
       * @function handleClearPolygonClick
       *
       * Handle removing the `polygon` parameter from the current search
       *
       * @returns void
       */
      function* handleClearPolygonClick() {
        // Remove the `polygon` param from the URL
        if (config.isMobileApp) {
          yield put(routerPush({ query: { polygon: undefined } }, { persistQuery: true }));
        } else {
          yield search.remove({ polygon: null });
        }

        yield call(renableMapInteractions, map);
      }
    ),

    // Map Zoom Handlers
    takeEvery(
      actionBarActions.onZoomInClick,
      function* handleZoomInClick() {
        const currentZoom = yield call([map, map.getZoom]);
        yield call([map, map.setZoom], currentZoom + 1);
      }
    ),

    takeEvery(
      actionBarActions.onZoomOutClick,
      function* handleZoomOutClick() {
        const currentZoom = yield call([map, map.getZoom]);
        yield call([map, map.setZoom], currentZoom - 1);
      }
    ),
  ]);
}

function* renableMapInteractions(map) {
  yield setMapInteractions(true, map);
}

export function* zoomMapToUsersLocation(coords, map) {
  yield call([map, map.setZoom], 16);
  yield call([map, map.panTo], { lat: coords.lat, lng: coords.lng });
}

export function* getCoords() {
  const coords = yield select(getGeolocation);
  if (coords) {
    yield put(receiveCurrentLocation(coords));
    return coords;
  }

  const recvAction = yield take(receiveCurrentLocation);
  return recvAction.payload;
}

function* setMapInteractions(isFeatureEnabled, map) {
  yield call([map, map.setOptions], {
    draggable: isFeatureEnabled,
    draggableCursor: isFeatureEnabled ? undefined : 'crosshair',
    scrollwheel: isFeatureEnabled,
    disableDoubleClickZoom: !isFeatureEnabled,
  });
}

import React from 'react';
import PropTypes from 'prop-types';
import { breakpoints } from 'constants/mediaQueries';
import BreakPoint from './BreakPoint';

/**
 * Create a BreakPoint component for a specific form factor.
 * @param {Number} min The minimum number of pixels for this break point, inclusive.
 * @param {Number} max The maximum number of pixels for this break point, inclusive.
 * @param {String} displayName
 * @return {Component}
 */
function createBreakpoint(min, max, displayName) {
  function BreakPointComponent(props) {
    const orientation = props.orientation ? ` and (orientation: ${props.orientation})` : '';
    const rangeQuery = (() => {
      if (min && max && props.range) {
        const queryVal = props.range ? `(min-width: ${min}px) and (max-width: ${max}px)` : '';
        return queryVal;
      }
      const queryVal = props.smaller ? `(max-width: ${min - 1}px)` : `(min-width: ${min}px)`;
      return queryVal;
    })();
    return <BreakPoint mediaQuery={`${rangeQuery}${orientation}`}>{props.children}</BreakPoint>;
  }

  BreakPointComponent.displayName = displayName || 'CustomBreakPoint';
  BreakPointComponent.propTypes = {
    smaller: PropTypes.bool,
    larger: PropTypes.bool,
    range: PropTypes.bool,
    orientation: PropTypes.oneOf(['portrait', 'landscape']),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    validateTypes,
  };

  return BreakPointComponent;
}

/**
 * Custom prop validator to check that more than one of the media query types
 * are not specified.
 * @param  {Object} props
 * @param  {String} propName
 * @param  {String} componentName [description]
 * @return {Error|undefined}
 */
function validateTypes(props, propName, componentName) {
  const queryTypes = ['larger', 'smaller', 'range'];
  // Ensure that at most one of these is selected. Default to 'larger', according to the code.
  const suppliedTypes = queryTypes.filter(type => props[type]);
  if (suppliedTypes.length > 1) {
    return new Error(
      `Invalid media query types passed to ${componentName}. Expected at most one of 'smaller',
      'larger', or 'range'. Got ${suppliedTypes.map(t => `'${t}'`).join(', ')}.`);
  }

  return undefined;
}

export const Mobile = createBreakpoint(
  breakpoints.small, // 480
  breakpoints.smallMax, // 767
  'Mobile'
);
export const Tablet = createBreakpoint(
  breakpoints.medium, // 768
  breakpoints.mediumMax, // 959
  'Tablet'
);
export const Laptop = createBreakpoint(
  breakpoints.large, // 960
  breakpoints.largeMax, // 1219
  'Laptop'
);
export const Desktop = createBreakpoint(
  breakpoints.xlarge, // 1220
  null,
  'Desktop'
);

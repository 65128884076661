import $ from 'jquery';
import _ from 'underscore';
import { push } from 'redux-little-router';
import UIkit from 'uikit';
import {
  config,
  tenant,
  visitorDetails,
  visitor,
  app,
  utility,
  SellerValuation,
  events as eventsModel,
  search as searchModel,
} from 'BoomTown';

import BaseModalView from 'legacy/Base/view.modal';
import * as NameParse from 'utility/parse-names';
import * as routerUtils from 'legacy/Routing/routerUtils';
import { getCurrentListingID } from 'selectors/listings';
import { signinFormData } from 'constants/registrationForms';
import * as BackboneActions from 'actions/BackboneActions';

import registerTemplate from 'templates/modals/register/base.hbs';
import { REGISTRATION_FORM } from 'cypress_constants';

/**
 * @typedef {object} SocialResponse
 * @property {string} key
 * @property {string} id
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} email
 * @property {error?} string
 * @property {'google' | 'fb'} src
 */

/**
 * This is the first step of the registration / login modal
 * as well as the `/login` page.
 *
 * `id` is intentionally being omitted and must be passed in
 * Backbone.view will apply any options at constructor time and
 * will set up this.el based on this.id if one is not provided
 * We currently pivot functionality based on this.id = 'static-register-form'
 *
 * @see Backbone.view._ensureElement
 */

// Static FB Single App variables
const FB_LOGIN_REQUEST = 'fblogin-clicked';
export const FB_LOGIN_RESPONSE = 'fblogin-response';
const FB_USER_INFO_RESPONSE = 'fblogin-user-info';
const GET_PARENT_URL_PATH = 'get_parent_url_path';
// const G_LOGIN_REQUEST = 'googlelogin';
const G_USER_INFO_RESPONSE = 'googlelogin-response';
const G_FAILED_INFO_RESPONSE = 'google-failed-response';

export default class RegisterView extends BaseModalView {
  get events() {
    return {
      'click .js-register-button': 'register',
      'click .js-signin-button': 'login',
      'click .js-forgot-button': 'forgot',
      'click .js-optin-tab .js-opt-me-in': 'optIn',
      'click .js-optin-tab .js-no-thanks': 'noThanks',
      'click .toggle': 'toggleClicked',
      'click .js-next-step-two': 'nextStepTwo',
    };
  }

  // CNS-1284: register success callback hook, can be used to override final page reload
  postRegAction = null;

  inProgress = false;

  bindToDOM() {}

  mount() {}

  update() {}

  viewWillUnmount() {
    // don't think this ever really called
    $(document)
      .off('click', '.js-user-password-reset', this.userPasswordResetClick)
      .off('click', '.js-user-register', this.userRegisterClick)
      .off('click', '.js-user-signin', this.userSignInClick);
  }

  initialize() {
    this.template = registerTemplate;

    window.addEventListener('message', (ev) => { return this.receiveMessage(ev); }, false);

    const expiredToken = new URLSearchParams(window.location.search).get('resend');

    this.data = {
      ...visitorDetails,
      allowFBLogin: tenant.allowFBLogin && typeof config.overrideHideLender === 'undefined',
      authGatewaySrc: config.fbAuthenticatorUrl
        ? `${config.fbAuthenticatorUrl}?domain=${document.location.origin}`
        : '',
      showLenderCheckbox: config.overrideHideLender,
      lenderChecked: tenant.lenderChecked,
      doubleOptIn: tenant.doubleOptIn,
      showAgents: tenant.showAgentDDL === '1' && !window.bt.agent,
      isOneStep: tenant.doubleOptIn,
      /* CNS-8296 feature flag for new password reset */
      isNewPasswordReset: config.passwordReset,
      activeView: this.id === 'static-register-form' ? 'login' : 'register',
      tenantInBC: tenant.state === 'BC',
      contactFormDisclaimer: false,
      cySelectors: {
        continueWithEmail: REGISTRATION_FORM.CONTINUE_WITH_EMAIL,
        logInButton: REGISTRATION_FORM.LOG_IN_BUTTON,
        stepOneEmail: REGISTRATION_FORM.STEP_ONE_EMAIL,
      },
      logoUrl: config.inner_logo_uri,
    };

    // CNS-5855 Contact Form Disclaimer
    try {
      this.data.contactFormDisclaimer = window.bt.config.disclaimer;
    } catch {
      this.data.contactFormDisclaimer = '';
    }

    // Renders to DOM
    super.initialize();

    // Bind our login/register/recover pass switcher
    this._registerSwitcher();
    this.bindAgentList();

    if (this.id === 'user-action-modal') {
      this.delegateEvents();

      this.listenTo(eventsModel, 'registerModal', this.tracking);
      this.listenTo(eventsModel, 'registerModal', (params = {}) => {
        // When opening this modal, store the close button state so that it can
        // be forwarded on to the complete register modal view.
        this.showCloseBtn = Boolean(params.showCloseBtn);
        this.fire();
      });
    }

    $(document).on('click', '.js-user-password-reset', (e) => { return this.userPasswordResetClick(e); });
    $(document).on('click', '.js-user-register', (e) => { return this.userRegisterClick(e); });
    $(document).on('click', '.js-user-signin', (e) => { return this.userSignInClick(e); });

    if (this.id === 'static-register-form') {
      // TODO: clean this with the new UI
      setTimeout(() => {
        // manually add formtype for GA tracking
        $('#user-action-modal, #complete-register-modal').data('urlparam', signinFormData.urlParam);
      }, 500);
      if (expiredToken) {
        setTimeout(() => {
          this.$('.js-regform-nav')
            .data('switcher')
            .show(2);
          const parent = this.$('.top-level-error');
          this.message(parent, 'Your password link has expired.', 'error');
        }, '500');
      }
    }
  }

  userPasswordResetClick(event) {
    event.preventDefault();
    this.$('.js-regform-nav')
      .data('switcher')
      .show(2);
  }

  userRegisterClick(event) {
    event.preventDefault();
    this.$('.js-regform-nav')
      .data('switcher')
      .show(0);
  }

  userSignInClick(event) {
    event.preventDefault();
    this.$('.js-regform-nav')
      .data('switcher')
      .show(1);
  }

  bindAgentList() {
    // show/hide Agent Dropdownlist
    if (tenant.showAgentDDL === '1') {
      const agentsObj = {};
      const s = $('#preferred-agent');

      utility.jsonp(`${config.apiUrl}/lc/1/agents/`, (agents) => {
        // filter to only ShowOnSite agents
        agentsObj.Agents = _.filter(agents.Result, (agent) => { return agent.ShowOnSite; });

        $.each(agentsObj.Agents, (index, value) => {
          return $('<option />', {
            value: value._ID,
            text: `${value.FirstName} ${value.LastName}`,
          }).appendTo(s);
        });

        agentsObj.showAgents = true;
      });
    }
  }

  /**
   * Verify that we want to respond to these messages
   *
   * @param {Event} ev A post message event
   */
  isValidOrigin(ev) {
    // Using anchor tag hack because IE11 does not support URL function
    const parser = document.createElement('a');
    parser.href = config.fbAuthenticatorUrl;
    return ev.origin === `${parser.protocol}//${parser.hostname}`;
  }

  receiveMessage(ev) {
    if (!this.isValidOrigin(ev)) return;

    /** @type {SocialResponse} */
    let parsedResponse = {};
    try {
      parsedResponse = JSON.parse(ev.data);
    } catch (err) {
      // console.warn("Couldn't parse message", ev.data);
      return;
    }

    switch (parsedResponse.key) {
      case GET_PARENT_URL_PATH:
        const currentUrl = {
          origin: window.location.origin,
          path: window.location.pathname,
          query: utility.parseQueryString(window.location.search),
        };
        ev.source.postMessage(currentUrl, ev.origin);
        break;
      case FB_LOGIN_REQUEST:
        break;

      case FB_LOGIN_RESPONSE:
        break;

      case FB_USER_INFO_RESPONSE:
        parsedResponse.src = 'fb';
        this.connectSocialAccount(parsedResponse);
        break;

      case G_USER_INFO_RESPONSE:
        parsedResponse.src = 'google';
        this.connectSocialAccount(parsedResponse);
        break;

      case G_FAILED_INFO_RESPONSE:
        // console.warn(parsedResponse);
        break;

      default:
        break;
    }

    /* eslint-disable global-require */
    const { dispatch } = require('store').default;
    // log all the responses, filter what we need in GTM
    dispatch(BackboneActions.getResponseFromIframe(parsedResponse));
  }

  /**
   *
   * @param {SocialResponse} json
   */
  connectSocialAccount(json) {
    /* eslint-disable */

    const url =
      config.apiUrl + '/lc/1/leads/getbyemail?emailaddress=' + encodeURIComponent(json.email);

    const error = () => console.log('getbyemail API call error');

    const success = (data, status, xhr) => {
      // assign current visitorID
      json.visitorID = visitor.id;
      // email exists
      if (data.Status.Code === 200 && data.Result) {
        this.model.set('FirstName', data.Result.FirstName);
        this.model.set('LastName', data.Result.LastName);
        this.model.set('IsRegistered', true);
        this.model.set('Username', data.Result.Username);
        this.model.set('_ID', data.Result._ID);
        const newVisitorID = data.Result._ID;
        const phoneNumber = data.Result.BestPhone;

        if (SellerValuation != null) {
          SellerValuation.valuation.trigger('FBConnectLogin');
        }

        // not sure if I need it yet, takes a lot of time to do this request
        // remove cache and cookie in that order
        // $.post 'http://'+ location.host + '/expire/visitor:' + window.bt.visitor.id, (data) =>
        utility.cookie('BoomTownData', '', { expires: -1, path: '/' });
        utility.cookie('BoomTownAuth', newVisitorID);

        // assign new visitorID
        json.visitorID = newVisitorID;

        // login user if he has phone number, if not, display a second form
        if (phoneNumber) {
          UIkit.modal(`#${this.id}`).hide();
          // CNS-1284: Seller doesn't reload, so look for the postRegAction hook and pass xhr info along
          if (typeof this.postRegAction === 'function') {
            this.postRegAction(data, status, xhr);
          } else {
            $('html').addClass('bt-loading');

            /* eslint-disable global-require */
            const { dispatch } = require('store').default;

            dispatch(BackboneActions.loginUsingSocial());
            if (this.id === 'static-registration-form' || window.location.pathname === '/login/') {
              window.location = '/account';
            } else {
              if (window.location.search.includes('fb_register')) {
                const queryString = utility.cleanQueryString(
                  window.location.search,
                  searchModel.socialLoginParams
                );

                if (queryString.length) {
                  window.location.search = `${queryString}`;
                } else {
                  window.location.replace(`${window.location.origin}${window.location.pathname}`);
                }
                return this;
              }
              window.location.reload();
            }
          }
        } else {
          app.completeRegForm(false, json, true, this.showCloseBtn || false);
        }
      } else {
        app.completeRegForm(false, json, true, this.showCloseBtn || false);
      }

      return this;
    };

    return utility.jsonp(url, success, error, this);
    /* eslint-enable */
  }

  nextStepTwo() {
    const parent = $('.js-register-form');
    const user = { email: parent.find('input[name="email"]').val() };
    if (utility.validateParsley(parent[0])) {
      parent
        .find('.js-alert-messages-container')
        .addClass('uk-hidden')
        .html('');
      app.completeRegForm(true, user, false, this.showCloseBtn || false);
    }
  }

  tracking() {
    // eslint-disable-next-line
    const state = require('store').default.getState();
    let listingID = getCurrentListingID(state);
    if (listingID == null) {
      listingID = null;
    }

    if (tenant.allowFBLogin === '1') {
      visitor.logAction(listingID, 'ViewedFacebookForm');
    } else {
      visitor.logAction(listingID, 'ViewedPhoneValid');
    }
  }

  login() {
    const parent = this.$('.js-sign-in-form');
    const button = parent.find('.js-signin-button');

    // clear out whatever might have been set by another form
    this.model.set({
      Email: '',
      Password: '',
    });

    const formdata = {
      Email: parent.find('input[name="username"]').val(),
      Password: parent.find('input[name="password"]').val(),
      FormVersion: 'Login',
    };

    const success = () => {
      this.clearErrors();

      // BTGA4: CNS-8653
      if (typeof window.BoomTownGA4 !== 'undefined') {
        window.BoomTownGA4.eventFormLogin();
      }

      if (this.id === 'static-registration-form' || window.location.pathname === '/login/') {
        const qs = utility.parseQueryString();

        if (qs.success != null && qs.success === 'notifications') {
          window.location = '/notifications';
        } else {
          window.location = '/account';
        }
      } else if (location.pathname) {
        window.location.reload();
      }
    };

    // eslint-disable-next-line
    const error = (xhr, status, error) => {
      this.done(parent);
      button.removeAttr('disabled');
      button.text('Sign In');
      switch (xhr.status) {
        case 400:
          // eslint-disable-next-line
          error = xhr.responseText;
          break;
        default:
          break;
      }
      return this.message(parent, error, 'error');
    };

    if (utility.validateParsley(parent[0])) {
      button.attr('disabled', 'disabled');
      button.text('Logging In');
      this.clearErrors();
      this.model.login(formdata, success, error);
    }
  }

  forgot() {
    const parent = this.$('.js-forgot-form');
    const button = parent.find('.js-forgot-button');
    this.model.set('Email', '');
    this.model.forgot = true;
    const formdata = { Email: parent.find('input[name="email"]').val() };

    this.model.forgot = false;

    const success = () => {
      button.text('Sent!');
      // store email in Sign in form
      $('.js-sign-in-form input[name="username"]').val(parent.find('input[name="email"]').val());
      parent.find('.filled').removeClass('filled');
      /* CNS-8296 feature flag for new password reset */
      const isNewPasswordReset = config.passwordReset;
      if (isNewPasswordReset) {
        this.clearTopLevelMessages();
        return this.message(parent, 'Your password reset link has been sent. Please check your email to reset your password.', 'success');
      }
      return this.message(parent, 'Your password has been sent.', 'success');
    };

    // eslint-disable-next-line
    const error = (xhr, status, error) => {
      button.removeAttr('disabled');
      this.done(parent);
      button.text('Send Password');
      switch (xhr.status) {
        case 404:
          // eslint-disable-next-line
          error = 'No account exists for this email address.';
          break;
        default:
          break;
      }

      // This code is backwards compatible with the old system.
      try {
        const response = JSON.parse(xhr);
        if (response) {
          if (response.message === 'EXISTING_TOKEN') {
            error = 'You already have an active password reset link. Please use the link found in your email or wait until later to reset again.';
          } else if (response.message === 'NO_VISITOR') {
            error = 'No account exists for this email address.';
          } else {
            error = 'There was an error resetting your password. Please try again or contact support.';
          }
        }
      } catch (e) {
        console.log('Invalid Response');
      }

      return this.message(parent, error, 'error');
    };

    if (utility.validateParsley(parent[0])) {
      button.text('Sending...');
      button.attr('disabled', 'disabled');
      this.model.sendPassword(formdata, success, error);
    }
  }

  optIn(e) {
    this.updateEmailPrefs(e, false, 'Sending...');
  }

  noThanks(e) {
    this.updateEmailPrefs(e, true);
  }

  updateEmailPrefs(e, optOutGlobal, message) {
    e.preventDefault();
    const parent = this.$('.js-opt-in');
    const prefs = {
      OptOutGlobal: optOutGlobal,
      OptOutAgent: optOutGlobal,
      OptOutLender: optOutGlobal,
      OptOutEAlerts: optOutGlobal,
    };

    if (message) {
      this.working(parent, message);
    }

    const success = () => { return location.reload(true); };

    // eslint-disable-next-line
    const error = (xhr, status, error) => {
      this.done(parent);
      this.message(parent, error, 'error');
    };

    this.model.updatePreferences(prefs, success, error);
  }

  register() {
    let firstName;
    let lastName;
    $('.js-register-button').attr('disabled', 'true');

    const parent = this.$('.js-register-form');
    this.model.set({
      FirstName: '',
      LastName: '',
      Email: '',
      Phone: '',
      Password: '',
    });

    const fullName = parent.find('input[name="fullname"]').val();

    // skip NameParse if single char, Parsley will detect single char.
    if (fullName.trim().length > 1) {
      const tempName = NameParse.parse(fullName);
      ({ firstName, lastName } = tempName);

      // edge case, when a visitor enters 1 char into the firstname,
      // we need to swap initial <=> firstname
      if (firstName === '' && tempName.initials) {
        firstName = tempName.initials;
      }
    }

    // default form data
    const formdata = {
      FirstName: firstName,
      LastName: lastName,
      Email: parent.find('input[name="email"]').val(),
      Phone: parent.find('input[name="phone"]').val(),
      Password: parent.find('input[name="phone"]').val(),
      FormVersion: 'Phone Valid',
      ActionID: 52,
    };

    if (routerUtils.isDetailsPath()) {
      // eslint-disable-next-line
      const state = require('store').default.getState();
      formdata.ListingID = getCurrentListingID(state);
    }

    const lender = parent.find('input[name="mortgageOK"]');
    if (lender.length > 0) {
      formdata.MortgageOK = lender.prop('checked');
    }

    if (window.bt.agent != null ? window.bt.agent.id : undefined) {
      this.clearErrors();
      /**
       * The following is not needed; the server does this for us. TODO: Figure
       * out what `clearErrors()` is doing here and get rid of it.
       */
      // formdata.Agent = window.bt.agent.id;
    } else if (tenant.showAgentDDL === '1') {
      formdata.Agent = $('#preferred-agent').val();
    }

    const success = () => {
      // We are going to hard reload the page at the end of this
      // so we aren't as concerned with updating the visitor model
      // with the latest info
      eventsModel.trigger('count', 'Successfully registered user');

      const trackingParams = {
        form: $('#user-action-modal').data('urlparam'),
        contacttype: visitor.updateContactType('lead'),
        formtype: 'email',
        step: null,
      };

      // GTM support
      if (window.dataLayer) {
        trackingParams.event = 'registration-form-completed';
        window.dataLayer.push(trackingParams);
      }

      this.clearErrors();
      if (tenant.doubleOptIn) {
        this.done(parent);
        this.$('.js-regform-nav')
          .data('switcher')
          .show(3);
        this.$('.js-firstname').text(this.model.get('FirstName'));
      } else {
        const conversionCookie = config.conversionCookieName || 'ConversionType';
        eventsModel.trigger('count', conversionCookie);

        let url = location.pathname + location.search;

        // remove &regformdisplay after registering
        if (url.indexOf(`&${config.registrationQueryStringName}`) > -1) {
          url = url.replace(`&${config.registrationQueryStringName}`, '');
        }

        // in case http://casinagroup.com?regformdisplay
        if (url.indexOf(`?${config.registrationQueryStringName}`) > -1) {
          url = url.replace(`?${config.registrationQueryStringName}`, '');
        }

        // add custom url params depending on the form
        const urlParam = $('#user-action-modal').data('urlparam');

        let conversionParam = config.conversionQueryStringName;

        if (urlParam) {
          conversionParam += `=${urlParam}`;
        }

        if (url.indexOf('?') > -1) {
          url = `${url}&${conversionParam}`;
        } else {
          url = `${url}?${conversionParam}`;
        }

        // TODO: consider just sending them to the url, and not pushingHistory
        // window.location.href = url;
        // eslint-disable-next-line
        require('store').default.dispatch(push(url));
        location.reload();
      }
    };

    // eslint-disable-next-line
    const error = (xhr, status, error) => {
      this.done(parent);
      this.message(parent, error, 'error');
      $('.js-register-button').removeAttr('disabled');
    };

    if (utility.validateParsley(parent[0])) {
      this.working(parent, 'Registering...');
      this.clearErrors();
      eventsModel.trigger('count', 'Register User');

      if (!this.inProgress) {
        this.model.register(formdata, success, error);
        this.inProgress = true;
      }
    } else {
      $('.js-register-button').removeAttr('disabled');
    }
  }

  clearErrors() {
    this.$('.parsley-errors-list').removeClass('filled');
  }

  /**
   * Renders an alert message to the form
   *
   * @param {Object} parent jQuery Element Object
   * @param {string} text The text to render in the alert message
   * @param {'success'|'warning'|'danger'} type The type of alert to render
   */
  message(parent, text, type) {
    const block = parent.find('.js-alert-messages-container');
    if (block.length === 0) {
      return;
    }
    block
      .removeClass('uk-hidden alert-error alert-success alert-warning')
      .addClass(`alert-${type}`)
      .html(text);
  }

  clearTopLevelMessages() {
    const block = this.$('.top-level-error').find('.js-alert-messages-container');
    if (block.length === 0) {
      return;
    }
    block.hide();
  }

  working(parent, text) {
    const button = $('.js-register-button');
    const original = button.text();
    button
      .attr('disabled', 'disabled')
      .data('original', original)
      .html(text);
  }

  done(parent, text) {
    this.inProgress = false;
    const button = $('.js-register-button');
    const original = button.data('original');
    if (!text) {
      // eslint-disable-next-line
      text = original;
    }
    button
      .removeAttr('disabled')
      .removeData('original')
      .html(text);
  }

  _registerSwitcher() {
    const uikitTab = this.$el.find('[data-uk-tab]');
    UIkit.tab(uikitTab, UIkit.Utils.options(uikitTab.attr('data-uk-tab')));
  }
}

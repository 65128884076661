import { createAction } from 'redux-act';

const ballerboxAction = (title, ...args) => createAction(`BallerBox > ${title}`, ...args);

/**
 * Nearby Search
 */
export const selectNearbySearch = ballerboxAction('Select Nearby Search');


/**
 * Ballerbox Submissions
 */
export const emptySubmitClick = ballerboxAction('Empty submit');
export const selectSuggestion = ballerboxAction('Select a suggestion');

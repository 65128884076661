/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React from 'react';

export default class ContactFormDisclaimer extends React.PureComponent {
  render() {
    let disclaimer = '';

    try {
      disclaimer = window.bt.config.disclaimer;
    } catch {
      return null;
    }

    // Pre Formatted. Line cannot be broken as a result. Do not try to make 'pretty'.
    return (
      <div>
        <label className="bt-disclaimer-contact-form"><input type="hidden" id="leadid_tcpa_disclosure" /><strong>Disclaimer:</strong> <span dangerouslySetInnerHTML={{ __html: disclaimer }} /></label>
      </div>
    );
  }
}

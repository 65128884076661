import { sliceFactory } from 'reducers/util';
import { saleTypeReducer } from 'reducers/saleTypes';
import * as OffCanvasActions from 'actions/OffCanvasActions';
import { SearchConverter } from 'models/search/services';
import { PENDING_BRANCH } from 'models/search/constants';

/**
 * Reducer factory for search terms that are represented as arrays.
 *
 * @param {string} stateKey The pending search property name that is being handled
 * @param {string} elem The key to toggle in the array if it is fixed. If not supplied will be
 *                      assumed to be the action payload.
 *
 * @returns {Function} A reducer function
 */
const getToggleArrayReducer = (stateKey, elem) => (state, payload) => {
  const elemInArray = elem || payload;
  const arr = state[stateKey];
  const i = arr.indexOf(elemInArray);

  return {
    ...state,
    [stateKey]: i >= 0 ? [...arr.slice(0, i), ...arr.slice(i + 1)] : [...arr, elemInArray],
  };
};

/**
 * Reducer factory for search terms that can be updated generically
 *
 * @param {string} stateKey The pending search property name that is being handled
 * @param {boolean} shouldToggle If the value should be togglable
 *
 * @returns {Function} A reducer function
 */
const updateGenericSearchProp = (stateKey, shouldToggle) => (state, payload) => {
  let condition = payload === 'any';

  // When a RadioList is used, the value needs to deselect if the same value is selected twice
  if (shouldToggle) {
    condition = payload === state[stateKey] || payload === 'any';
  }

  return {
    ...state,
    [stateKey]: condition ? SearchConverter.getDefault(stateKey) : payload,
  };
};

export default {

  [OffCanvasActions.updatePendingSearchFilters]: sliceFactory(
    PENDING_BRANCH,
    state => {
      // if rid is null, return state
      // if rid exists, set to null
      if (state.rid) {
        return {
          ...state,
          rid: null
        };
      }
      return state;
    }
  ),

  /* MIN BEDS */
  [OffCanvasActions.updateMinBeds]: sliceFactory(
    PENDING_BRANCH,
    /**
     * @param {Object} state The global state tree
     * @param {number} payload Min. beds
     *
     * @returns {Object} The updated `pending` state object
     */
    (state, payload) => ({
      ...state,
      minbeds: payload !== state.minbeds ? payload : 0,
    })
  ),

  /* MIN BATHS */
  [OffCanvasActions.updateMinBaths]: sliceFactory(
    PENDING_BRANCH,
    /**
     * @param {Object} state The global state tree
     * @param {number} payload Min. baths (integer || float)
     *
     * @returns {Object} The updated state object
     */
    (state, payload) => ({
      ...state,
      minbaths: payload % 1 !== 0 ? payload : 0,
      minfullbaths: payload % 1 === 0 ? payload : 0,
      minhalfbaths: 0, // Never setting this as a search param (for now)
    })
  ),

  /* PRICE */
  [OffCanvasActions.updatePrice]: sliceFactory(
    PENDING_BRANCH,
    /**
     * @param {Object} state The global state tree
     * @param {string} payload The incoming price value
     *
     * @returns {Object} The updated state object
     */
    (state, payload) => {
      let { minprice, maxprice } = { ...state, ...payload };
      const parsedMinprice = parseInt(minprice, 10);
      const parsedMaxprice = parseInt(maxprice, 10);

      minprice = isNaN(parsedMinprice) ? null : parsedMinprice;
      maxprice = isNaN(parsedMaxprice) ? null : parsedMaxprice;

      // If they mixed up which one is bigger we flip flop 'em
      if (minprice !== null && maxprice !== null && minprice > maxprice) {
        [minprice, maxprice] = [maxprice, minprice];
      }

      return {
        ...state,
        minprice,
        maxprice,
      };
    }
  ),

  /* PROPERTY TYPE */
  [OffCanvasActions.togglePropertyType]: sliceFactory(
    PENDING_BRANCH,
    getToggleArrayReducer('proptypes')
  ),

  /* SALE TYPE */
  [OffCanvasActions.toggleSaleType]: sliceFactory(
    PENDING_BRANCH,
    saleTypeReducer
  ),

  /**
   * LISTING STATUS
   *
   * Pretty sure this isn't actually used in any UI anywhere. It seems like we just use the
   * SALE TYPE route above, so this might be able to be removed.
   */
  [OffCanvasActions.toggleStatus]: sliceFactory(
    PENDING_BRANCH,
    getToggleArrayReducer('status')
  ),

  /* HAS PHOTOS */
  [OffCanvasActions.toggleHasPhotos]: sliceFactory(
    PENDING_BRANCH,
    state => ({
      ...state,
      photo: !state.photo,
    })
  ),

  /* VIRTUAL TOURS */
  [OffCanvasActions.toggleVirtualTours]: sliceFactory(
    PENDING_BRANCH,
    state => ({
      ...state,
      tours: !state.tours,
    })
  ),

  /* ACRES */
  [OffCanvasActions.updateMinAcres]: sliceFactory(
    PENDING_BRANCH,
    updateGenericSearchProp('minacres')
  ),
  [OffCanvasActions.updateMaxAcres]: sliceFactory(
    PENDING_BRANCH,
    updateGenericSearchProp('maxacres')
  ),

  /* SQFT */
  [OffCanvasActions.updateMinSqft]: sliceFactory(
    PENDING_BRANCH,
    updateGenericSearchProp('minsqft')
  ),
  [OffCanvasActions.updateMaxSqft]: sliceFactory(
    PENDING_BRANCH,
    updateGenericSearchProp('maxsqft')
  ),

  /* STORIES */
  // CNS-5569: We use this action for both desktop and mobile, where mobile is a RadioList that
  // needs to deselect if the same value is tapped twice.
  [OffCanvasActions.updateMinStories]: sliceFactory(
    PENDING_BRANCH,
    updateGenericSearchProp('minstories', true)
  ),
  [OffCanvasActions.updateMaxStories]: sliceFactory(
    PENDING_BRANCH,
    updateGenericSearchProp('maxstories')
  ),

  /* YEAR BUILT */
  [OffCanvasActions.updateMinYear]: sliceFactory(
    PENDING_BRANCH,
    updateGenericSearchProp('minyear')
  ),
  [OffCanvasActions.updateMaxYear]: sliceFactory(
    PENDING_BRANCH,
    updateGenericSearchProp('maxyear')
  ),

  /* GARAGES */
  // CNS-5569: We use this action for both desktop and mobile, where mobile is a RadioList that
  // needs to deselect if the same value is tapped twice.
  [OffCanvasActions.updateMinGarages]: sliceFactory(
    PENDING_BRANCH,
    updateGenericSearchProp('mingarages', true)
  ),
  [OffCanvasActions.updateMaxGarages]: sliceFactory(
    PENDING_BRANCH,
    updateGenericSearchProp('maxgarages', true)
  ),

  /* MAX DAYS LISTED (DAYS ON SITE) */
  [OffCanvasActions.updateMaxDaysListed]: sliceFactory(
    PENDING_BRANCH,
    updateGenericSearchProp('maxdayslisted')
  ),

  /* PRICE REDUCED */
  // The payload for this one is handled differently. Comes in as `{field: string, value: string}`
  // and is how the SelectMenu component passes params to its `onChange` prop. updateMaxDaysListed
  // is dispatched by the same component but we do some parsing in `AdditionalFilters` component
  // via `handleDaysOnSiteChange()`
  [OffCanvasActions.updatePriceReducedDate]: sliceFactory(
    PENDING_BRANCH,
    (state, value) => ({
      ...state,
      pricereduced: value === 'any' ? SearchConverter.getDefault('pricereduced') : value,
    })
  ),

  /* OPEN HOUSE */
  [OffCanvasActions.toggleHasOpenHouse]: sliceFactory(
    PENDING_BRANCH,
    getToggleArrayReducer('features', 'OH')
  ),

  /* FEATURES */
  [OffCanvasActions.toggleFeature]: sliceFactory(
    PENDING_BRANCH,
    getToggleArrayReducer('features')
  ),

};

/**
 * This file is a duplicate of the existing parse-names.js file minus references to logOffensiveWord
 *  and it's dispatching that event to our Redux store for GTM. Since we are not using the store or
 *  resolving that module, it's easier to migrate this file here where we can make future iterations
 *  as we expand upon our forms and rework our existing forms.
 */
import offensiveWords from './offensivewords';

/**
 * (_This module looks to have been taken from `joshfraser/JavaScript-Name-
 * Parser.` All I've done here is convert it to ES6 module syntax, since it's
 * going to be built with Webpack._)
 */

// split full names into the following parts:
// - prefix / salutation  (Mr., Mrs., etc)
// - given name / first name
// - middle initials
// - surname / last name
// - suffix (II, Phd, Jr, etc)
export function parse(fullastName) {
  var nameParts = [];
  var lastName = '';
  var firstName = '';
  var initials = '';
  var word = null;
  var j = 0;
  var i = 0;

  // split into words
  // completely ignore any words in parentheses
  nameParts = fullastName.trim()
    .split(' ')
    .filter(namePart => namePart.indexOf('(') === -1);

  var numWords = nameParts.length;
  // is the first word a title? (Mr. Mrs, etc)
  var salutation = isSalutation(nameParts[0]);
  var suffix = isSuffix(nameParts[numWords - 1]);
  // set the range for the middle part of the name (trim prefixes & suffixes)
  var start = (salutation) ? 1 : 0;
  var end = (suffix) ? numWords - 1 : numWords;

  word = nameParts[start];
  // if we start off with an initial, we'll call it the first name
  if (isInitial(word)) {
    // if so, do a look-ahead to see if they go by their middle name
    // for ex: "R. Jason Smith" => "Jason Smith" & "R." is stored as an initial
    // but "R. J. Smith" => "R. Smith" and "J." is stored as an initial
    if (isInitial(nameParts[start + 1])) {
      firstName += ` ${word.toUpperCase()}`;
    } else {
      initials += ` ${word.toUpperCase()}`;
    }
  } else {
    firstName += ` ${fixCase(word)}`;
  }

  // concat the first name
  for (i = start + 1; i < (end - 1); i++) {
    word = nameParts[i];
    // move on to parsing the last name if we find an indicator of a compound last name (Von, Van, etc)
    // we do not check earlier to allow for rare cases where an indicator is actually the first name (like "Von Fabella")
    if (isCompoundLastName(word)) {
      break;
    }

    if (isInitial(word)) {
      initials += ` ${word.toUpperCase()}`;
    } else {
      firstName += ` ${fixCase(word)}`;
    }
  }

  // check that we have more than 1 word in our string
  if ((end - start) > 1) {
    // concat the last name
    for (j = i; j < end; j++) {
      lastName += ` ${fixCase(nameParts[j])}`;
    }
  }

  // return the various parts in an array
  return {
    salutation: salutation || '',
    firstName: firstName.trim(),
    initials: initials.trim(),
    lastName: lastName.trim(),
    suffix: suffix || ''
  };
}

export function removeIgnoredChars(word) {
  // ignore periods
  return word.replace('.', '');
}

// detect and format standard salutations
// I'm only considering english honorifics for now & not words like
export function isSalutation(word) {
  const lowerWord = removeIgnoredChars(word).toLowerCase();

  // returns normalized values
  if (lowerWord === 'mr' || lowerWord === 'master' || lowerWord === 'mister') {
    return 'Mr.';
  } else if (lowerWord === 'mrs') {
    return 'Mrs.';
  } else if (lowerWord === 'miss' || lowerWord === 'ms') {
    return 'Ms.';
  } else if (lowerWord === 'dr') {
    return 'Dr.';
  } else if (lowerWord === 'rev') {
    return 'Rev.';
  } else if (lowerWord === 'fr') {
    return 'Fr.';
  }

  return false;
}

//  detect and format common suffixes
export function isSuffix(word) {
  const lowerWord = removeIgnoredChars(word).toLowerCase();
  // these are some common suffixes - what am I missing?
  var suffixArray = [
    'I', 'II', 'III', 'IV', 'V', 'Senior', 'Junior', 'Jr', 'Sr', 'PhD', 'APR',
    'RPh', 'PE', 'MD', 'MA', 'DMD', 'CME', 'BVM', 'CFRE', 'CLU', 'CPA', 'CSC',
    'CSJ', 'DC', 'DD', 'DDS', 'DO', 'DVM', 'Esq', 'JD', 'LLD', 'OD',
    'OSB', 'PC', 'Ret', 'RGS', 'RN', 'RNC', 'SHCJ', 'SJ', 'SNJM', 'SSMO',
    'USA', 'USAF', 'USAFR', 'USAR', 'USCG', 'USMC', 'USMCR', 'USN', 'USNR'
  ];

  const suffixIndex = suffixArray.map(suffix => suffix.toLowerCase()).indexOf(lowerWord);

  if (suffixIndex >= 0) {
    return suffixArray[suffixIndex];
  }

  return false;
}

// detect compound last names like "Von Fange"
export function isCompoundLastName(word) {
  // these are some common prefixes that identify a compound last names - what am I missing?
  var words = [
    'vere', 'von', 'van', 'de', 'del', 'della', 'di', 'da', 'pietro',
    'vanden', 'du', 'st.', 'st', 'la', 'lo', 'ter'
  ];
  return words.indexOf(word.toLowerCase()) >= 0;
}

// single letter, possibly followed by a period
export function isInitial(word) {
  return removeIgnoredChars(word).length === 1;
}

// Full name validation
// Validates more than one word
// CNS-4717: Expanded Regex to accomodate less common accents, carons, ect
// @see https://stackoverflow.com/questions/20690499/concrete-javascript-regex-for-accented-characters-diacritics
export function isFullName(name) {
  const names = String(name)            // Coerce string
    .trim()                             // Trim whitespace
    .replace(/ +(?= )/g, '')            // Remove multiple spaces
    .split(' ');

  // run every word through the filter
  const isValid = names.every(n => !isOffensiveWord(n));

  // continue parsing
  names
    .filter(n => !isSuffix(n))          // Filter out suffixes
    .filter(n => !isSalutation(n));     // Filter out salutations

  const good = names
    .filter(n => regexNameMatch(n))     // Filter out words that don't match regex
    .length;

  const bad = names
    .filter(n => !regexNameMatch(n))    // Filter out words that match regex
    .length;

  return good >= 2 && bad === 0 && isValid;        // If we have 2 or more good names and 0 bad names
}

/**
 * Validate individual name fields
 *
 * @param {string} name
 * @returns bool
 */
export function isValidName(name) {
  // eslint-disable-next-line
  name = name
    .trim()
    .replace(/ +(?= )/g, '')
    .split(' ');

  return name.every((n) => {
    if (regexNameMatch(n)) {
      // Don't even attempt to test for offensive words if the name fails regex check
      return !isOffensiveWord(n);
    }
    return false;
  });
}

/**
 * Match a name against a simple Regex
 *
 * @param {string} n
 * @returns bool
 */
export function regexNameMatch(n) {
  return /^[-'.A-Za-z\u00C0-\u017F]+$/.test(n);
}

/**
 * Checks a string against a list of offensive words
 *
 * @param {string} word
 * @returns bool
 */
export function isOffensiveWord(word) {
  if (offensiveWords.indexOf(word.toLowerCase()) > -1) {
    return true;
  }
  return false;
}

// detect mixed case words like "McDonald"
// returns false if the string is all one case
export function isCamelCase(word) {
  const ucReg = /[A-Z]+/;
  const lcReg = /[a-z]+/;
  return ucReg.exec(word) && lcReg.exec(word);
}

// ucfirst words split by dashes or periods
// ucfirst all upper/lower strings, but leave camelcase words alone
export function fixCase(word) {
  // uppercase words split by dashes, like "Kimura-Fay"
  word = safeUcFirst('-', word); // eslint-disable-line no-param-reassign
  // uppercase words split by periods, like "J.P."
  word = safeUcFirst('.', word); // eslint-disable-line no-param-reassign
  return word;
}

// helper for fixCase
// uppercase words split by the separator (ex. dashes or periods)
export function safeUcFirst(separator, word) {
  return word.split(separator).map((thisWord) => {
    if (isCamelCase(thisWord)) {
      return thisWord;
    }
    return thisWord.substr(0, 1).toUpperCase() + thisWord.substr(1).toLowerCase();
  }).join(separator);
}

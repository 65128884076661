import { takeEvery, call, select, put } from 'redux-saga/effects';
import { push } from 'redux-little-router';
import { add, go } from 'sagas/routerTasks/search';
import { search } from 'BoomTown';
import * as r from 'routes';
import { getRouteID } from 'selectors/router';

import * as a from './actions';

export default [
  takeEvery(a.clickSortOption, function* goToSortedURL(action) {
    yield add({ sort: action.payload });
  }),

  // This could be written better, but you can't fix the whole world all at once
  takeEvery(a.clickResultsViewType, function* handleResultViewChange({ payload: wantedRouteID }) {
    const currentRouteID = yield select(getRouteID);
    const isFavSearch = yield select(state => state.router.query.favs === '1');

    if ([r.FAVORITES, r.COMPARE].includes(currentRouteID) || isFavSearch) {
      // Maps gets the extra favs param added
      if (wantedRouteID === r.RESULTS_MAP) {
        return yield put(
          push({ pathname: `/${r.RESULTS_MAP}/`, query: { favs: '1' } }, { persistQuery: true })
        );
      }

      if (wantedRouteID === r.RESULTS_GALLERY) {
        return yield put(push({ pathname: `/${r.FAVORITES}/` }, { persistQuery: true }));
      }
    }

    // Here we strip the map params depending on the route
    const currentQuery = yield call([search, search.toJSON]);
    return yield go({ pathname: `/${wantedRouteID}/`, query: currentQuery });
  }),
];

/* eslint-disable no-confusing-arrow */
import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';

import * as ballerboxActions from 'components/BallerBox/actions';
import * as geoActions from 'services/geolocation/actions';
import * as backboneActions from 'actions/BackboneActions';
import * as OffCanvasActions from 'actions/OffCanvasActions';
import * as mSearchMenuActions from 'screens/MobileSearchMenu/actions';

import * as actionBarActions from './ActionBar/actions';
import * as mapActions from './Map/actions';
import * as polygonActions from './Map/PolygonOverlay/actions';
import * as resultsListActions from './Desktop/ResultsList/actions';

import { MAP_TYPES } from './constants';

import map from './Map/reducer';
import toast from './Toast/reducer';
import pagination from './Desktop/ResultsPagination/reducer';

export const activeListingIDReducer = createReducer(
  {
    [mapActions.onPinClicked]: (state, { id }) => id,
    [mapActions.dismissShelfOnTouch]: () => null,
    [mapActions.dismissShelfOnDrag]: () => null,
    [actionBarActions.onPolygonClick]: () => null,
    [actionBarActions.onClearPolygonClick]: () => null,
    [backboneActions.searchChange]: () => null,
    [mSearchMenuActions.applySearch]: () => null,
  },
  null
);

export const hoverListingIDReducer = createReducer(
  {
    [resultsListActions.onMouseEnterListingCard]: (state, id) => id,
    [resultsListActions.onMouseLeaveListingCard]: () => null,
  },
  null
);

export const isNearbyActiveReducer = createReducer(
  {
    [actionBarActions.onNearbyClick]: isActive => isActive !== true,
    [OffCanvasActions.selectNearbySearch]: () => true,
    [ballerboxActions.selectNearbySearch]: () => true,
    [geoActions.receiveError]: () => false,
    [geoActions.cancelLocationRequest]: () => false
  },
  false
);

export const shouldPanToCurrentLocationReducer = createReducer(
  {
    [mapActions.hasPannedToCurrentLocation]: () => false,
    [geoActions.receiveError]: () => false,
    [geoActions.cancelLocationRequest]: () => false,
    [OffCanvasActions.selectNearbySearch]: () => true,
    [ballerboxActions.selectNearbySearch]: () => true,

    /**
     * @note This is really just for integration with the HSN App and this whole case reducer
     * can be removed when the App no longer depends on our webviews.
     */
    [geoActions.receiveCurrentLocation]: (state, { userAction }) => {
      // `userAction` does not have a default value, so if the type is 'boolean' that means it's
      // not 'undefined' and we can use it.
      if (typeof userAction === 'boolean') {
        return userAction;
      }

      return state;
    }
  },
  false
);

export const isInDrawingModeReducer = createReducer(
  {
    [actionBarActions.onPolygonClick]: () => true,
    [actionBarActions.onClearPolygonClick]: () => false,
    [polygonActions.exitDrawingMode]: () => false,
  },
  false
);

/** @see google.maps.MapTypeId */
export const mapTypeIDReducer = createReducer(
  {
    [actionBarActions.onMapTypeClick]: id =>
      id === MAP_TYPES.ROADMAP ? MAP_TYPES.SATELLITE : MAP_TYPES.ROADMAP,
  },
  MAP_TYPES.ROADMAP
);

export const isFetchingListingsReducer = createReducer(
  {
    [mapActions.fetchListings]: () => true,
    [mapActions.receiveResults]: () => false,
    [mapActions.receiveNewResultsPage]: () => false,
  },
  true
);

// Export our fully combined `resultsMap` reducer
export default combineReducers({
  map,
  toast,
  pagination,
  activeListingID: activeListingIDReducer,
  hoverListingID: hoverListingIDReducer,
  isNearbyActive: isNearbyActiveReducer,
  shouldPanToCurrentLocation: shouldPanToCurrentLocationReducer,
  isInDrawingMode: isInDrawingModeReducer,
  mapTypeID: mapTypeIDReducer,
  isFetchingListings: isFetchingListingsReducer,
});

//
// Selectors
//

// Localize our selectors
export const createSelector = selector => (state, ...args) =>
  selector(state.resultsMap, ...args);

/**
 * Map a ListingSnapshot to a preview card
 */
export const getListingCardProps = createSelector(
  (localState, id) => localState.map.listings.filter(l => l._ID === id)[0]
);

/**
 * Retrieve the active listing ID from the store.
 * The activeListingID correlates with the current MapPin selected.
 */
export const getActiveListingID = createSelector(localState => localState.activeListingID);
export const getHoverListingID = createSelector(localState => localState.hoverListingID);

export const getIsNearbyActive = createSelector(localState => localState.isNearbyActive);
export const getIsInDrawingMode = createSelector(localState => localState.isInDrawingMode);
export const shouldPanToCurrentLocation = createSelector(
  localState => localState.shouldPanToCurrentLocation
);

export const getMapType = createSelector(localState => localState.mapTypeID);
export const getIsMapTypeSat = createSelector(
  localState => localState.mapTypeID === MAP_TYPES.SATELLITE
);

export const getMap = createSelector(localState => localState.map);
export const getMapListings = createSelector(localState => localState.map.listings);
export const getIsFetchingListings = createSelector(localState => localState.isFetchingListings);

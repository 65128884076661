/**
 * Converts properties of an object to lowercase.
 * @function propsToLower
 * @param {Object} obj The object containing properties to be converted
 * @returns {Object}
 */
const propsToLower = (obj) => {
  const newObj = {};
  const keys = Object.keys(obj);

  for (const key of keys) {
    const lowerCaseKey = key.toLowerCase();
    newObj[lowerCaseKey] = obj[key];
  }

  return newObj;
};

export default propsToLower;

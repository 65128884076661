import React from 'react';
import PropTypes from 'prop-types';
import MobileAgentAvatar from 'components/Common/Agent/Mobile/MobileAgentAvatar';
import Phone from 'components/DetailsPage/AgentLenderWrapper/Phone';
import { config } from 'BoomTown';

/** Using `style` for this since it's constant. */
const logoPlaceholder = (
  <div
    style={{
      height: '115px',
      backgroundImage: `url(${config.inner_logo_uri})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      margin: '0 20px',
    }}
  />
);

/**
 * An agent (/broker?) avatar with accompanying name and title (e.g.
 * "Associate", Agent")
 *
 * @todo Consider adding a CSS transition between these states
 */
const MobileAgentInfo = ({ agentInfo: { agent, state }, isMobileDisplayed, hideBorderBottom }) => {
  // Fetching
  if (state === 'invalid') {
    return (
      <div>
        <MobileAgentAvatar />
        <div className="mt-12">
          <div className="skeleton-text skeleton-text--name">&nbsp;</div>
          <div className="skeleton-text skeleton-text--title">&nbsp;</div>
        </div>
      </div>
    );
  }

  // Unable to fetch agent, or agent fetched was falsy
  if (state === 'failed' || (state === 'valid' && !agent)) {
    return logoPlaceholder;
  }

  if (agent) {
    return (
      <div>
        <MobileAgentAvatar agent={agent} className="mb-12" />
        <div className="bt-heading__h4">{agent.FullName}</div>
        <div className="bt-text--muted">{agent.Title || 'Associate'}</div>
        <div className="bt-text--muted">
          {
            agent.DRENumber ?
              <span>CA DRE #{agent.DRENumber}</span>
              : ''
          }
        </div>
        <div className="bt-listing__agent__info__phone">
          {
            Boolean(agent.OfficePhone) && (
              <Phone
                number={agent.OfficePhone}
                type="Office"
              />
            )
          }
          {
            (isMobileDisplayed && Boolean(agent.MobilePhone)) && (
              <Phone
                number={agent.MobilePhone}
                type="Cell"
              />
            )
          }
        </div>
        {!hideBorderBottom && <hr />}
      </div>
    );
  }

  return null;
};

MobileAgentInfo.propTypes = {
  agentInfo: PropTypes.shape({
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    FullName: PropTypes.string,
    Title: PropTypes.string,
    Slug: PropTypes.string,
    OfficePhone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    MobilePhone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    CompanyName: PropTypes.string,
    LogoUrl: PropTypes.string,
    Address1: PropTypes.string,
    Street: PropTypes.string,
    City: PropTypes.string,
    State: PropTypes.string,
    PostalCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    DRENumber: PropTypes.string
  }),
  isMobileDisplayed: PropTypes.bool,
  hideBorderBottom: PropTypes.bool,
};

MobileAgentInfo.defaultProps = {
  hideBorderBottom: false,
};

export default MobileAgentInfo;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MobileScreen, CloseButton } from 'components/Common/MobileScreen';
import { PrimaryButton } from 'coreComponents';
import { MOBILE_ACCOUNT, MOBILE_ACCOUNT_EDIT } from 'cypress_constants';
import { isSubmitInProgress } from '../../Forms/EditAccount/reducer';
import * as editAccountActions from '../../Forms/EditAccount/actions';
import ConnectedEditAccountForm from '../../Forms/EditAccount';

const MobileEditAccount = ({ onCloseClick, isSubmitting }) => (
  <MobileScreen
    title="Edit Contact Info"
    dataCY={MOBILE_ACCOUNT_EDIT.SCREEN_HEADER}
    headerLeft={<CloseButton onClose={onCloseClick} />}
    footer={
      <PrimaryButton
        type="submit"
        className="at-edit-account-info-submit-btn"
        dataCY={MOBILE_ACCOUNT.EDIT_CONTACT_INFO.SAVE_BUTTON}
        width="full"
        form="edit-account-info"
        showSpinner={isSubmitting}
      >
        Save Contact Changes
      </PrimaryButton>
    }
  >
    <ConnectedEditAccountForm isMobileScreen />
  </MobileScreen>
);

MobileEditAccount.propTypes = {
  onCloseClick: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default connect(
  state => ({
    isSubmitting: isSubmitInProgress(state),
  }),
  { onCloseClick: editAccountActions.clickCloseEditAccount }
)(MobileEditAccount);

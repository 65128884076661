import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { removeNonHTMLProps } from 'components/Common/FormElements/inputUtils';
import Pill from 'components/core/Pill';
/**
 * Note: The 'id' isn't made more unique, so it is up to you currently
 * not to re-use components or re-use id.s on the page
 *
 * CNS-6735 - added the conditional isNewTag that will display the "NEW" tag after the input and
 * before the span
 */
const CheckboxRadioControl = ({ id, label, dataCY = '', isNewTag, labelClassName, inline, returnRef, ...rest }) => {
  const labelClasses = cx((!isNewTag) ? 'form__check' : 'form__check--flex', labelClassName, {
    'form__check--inline': inline
  });


  return (
    <label className={labelClasses} htmlFor={id}>
      <input ref={returnRef} {...removeNonHTMLProps(rest)} id={id} data-cy={dataCY} />
      {isNewTag && (
        <div><Pill className="mr-4" bgColor="red">NEW</Pill></div>
      )}
      <span> {label}</span>
    </label>
  );
};

CheckboxRadioControl.propTypes = {
  id: PropTypes.string.isRequired,
  dataCY: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  labelClassName: PropTypes.string,
  returnRef: PropTypes.func,
  inline: PropTypes.bool,
  isNewTag: PropTypes.bool,
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired
};

export default CheckboxRadioControl;

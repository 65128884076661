import { takeEvery } from 'redux-saga/effects';
import { remove } from 'sagas/routerTasks/search';

import * as a from './actions';

export default [
  takeEvery(a.tagClick, function* handleTagClick({ payload }) {
    const { prop, value } = payload;

    yield remove({ [prop]: value });
  }),
];

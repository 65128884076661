// Desktop / Account
import { takeLatest, takeEvery, select } from 'redux-saga/effects';
import { ACCOUNT } from 'routes';
import isRoute from 'utility/isRoute';
import { isMobile as isMobileSelector } from 'selectors/browser';
import {
  handleSubmitContactForm,
  handleAPIResponse,
  maybeFetchNextAgentForContact,
} from 'components/MobileContactAgent/effects';
import * as desktopContactAgentActions from './actions';

export default [
  takeLatest(isRoute(ACCOUNT), function* () {
    const isMobile = yield select(isMobileSelector);
    if (!isMobile) {
      yield maybeFetchNextAgentForContact();
    }
  }),
  takeLatest(
    desktopContactAgentActions.submitContactForm, // action
    handleSubmitContactForm, // Task
    // Action to call for contact form response, passed as a param to our Task
    { responseAction: desktopContactAgentActions.receiveContactFormRes },
  ),
  takeEvery(
    desktopContactAgentActions.receiveContactFormRes,
    handleAPIResponse, // Task
    // Actions for dispatch to pass to our our Task
    {
      clickCloseModalAction: desktopContactAgentActions.closeContactFormSuccessModal,
      autoCloseModalAction: desktopContactAgentActions.autoCloseContactFormSuccessModal,
    },
  ),
];

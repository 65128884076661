import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import FAIcon from 'components/core/FAIcon';

export default function FavoriteButton(props) {
  const {
    listingID,
    stopPropagation,
    titleAddress,
    isFavorite,
    handleClick,
    dataCY,
    dataGTM,
  } = props;

  const handleFavoriteClick = (e) => {
    e.preventDefault();

    if (stopPropagation) {
      e.stopPropagation();
    }

    if (handleClick) {
      handleClick(listingID);
    }
  };

  let titleText = `Add ${titleAddress} to your favorites`;
  if (isFavorite) {
    titleText = `Remove ${titleAddress} from your favorites`;
  }

  return (
    <button
      type="button"
      aria-label={titleText}
      className="bt-listing__favorite-container"
      onClick={handleFavoriteClick}
      data-cy={dataCY}
      data-gtm={dataGTM}
    >
      <FavoriteIcon isFavorite={isFavorite} className="bt-listing__favorite-button" />
    </button>
  );
}

FavoriteButton.propTypes = {
  titleAddress: PropTypes.string.isRequired,
  listingID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isFavorite: PropTypes.bool,
  stopPropagation: PropTypes.bool,
  handleClick: PropTypes.func,
  dataCY: PropTypes.string,
  dataGTM: PropTypes.string,
};

export function FavoriteIcon(props) {
  const { isFavorite, className, dataCY } = props;

  // Derived from props on mount. Used to determine whether the button should be active but without
  // the "active" animation that normally occurs.
  const [isFavoriteOnMount, setIsFavoriteOnMount] = useState(() => isFavorite);

  /**
   * We don't want the `paused` modifier to remain if the property has been unfavorited. If
   * the user favorites the property again, we want the animation to play and it can't do that
   * with the `paused` modifier active.
   */
  useEffect(() => {
    if (!isFavorite) {
      setIsFavoriteOnMount((prevState) => {
        if (!prevState) {
          return prevState;
        }

        return false;
      });
    }
  }, [isFavorite]);

  const classes = cx('favorite-icon', className, {
    'favorite-icon--active': isFavorite,
    'favorite-icon--paused': isFavoriteOnMount,
  });

  const iconType = isFavorite ? 'solid' : 'regular';
  return (
    <div className={classes} data-cy={dataCY}>
      <FAIcon icon="heart" type={iconType} className="favorite-icon__heart" />
    </div>
  );
}

FavoriteIcon.propTypes = {
  isFavorite: PropTypes.bool,
  className: PropTypes.string,
  dataCY: PropTypes.string,
};
